import {useCallback, useMemo, useState} from 'react'

export const useNumberState = (initialValue: number) => {
  const [value, setValue] = useState(initialValue)

  const increment = useCallback(() => {
    setValue((value) => value + 1)
  }, [])

  const decrement = useCallback(() => {
    setValue((value) => value - 1)
  }, [])

  return useMemo(() => ({value, setValue, increment, decrement}), [decrement, increment, value])
}
