import clsx from 'clsx'
import {useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {TableSelectioncheckbox} from '../../../../components/tables/TableSelectionCheckbox'
import {Paper} from '../../../../components/utils/Paper'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {useDay} from '../../hooks/useDay'
import {useIsGSSSPP} from '../../hooks/useIsGSSSPP'
// import {PortaTicketStatusActivityColumn} from '../tables/PortaTicketStatusActivityColumn'

export interface TicketCardProps {
  data: TicketPortalModel
  className?: string
  loading?: boolean
  tabCode?: string
  productCategoryCode?: string
  onSelectHandler?: (code: string) => void
  selected: string[]
}

export const TicketCard = ({
  data,
  className,
  tabCode,
  productCategoryCode,
  onSelectHandler,
  selected,
}: TicketCardProps) => {
  const history = useHistory()
  const {getDayThreeLetters} = useDay()

  const {isGCCSPP} = useIsGSSSPP({productCategoryCode})

  const day = useMemo(() => {
    return getDayThreeLetters(data.startedAt, data.endedAt)
  }, [data.endedAt, data.startedAt, getDayThreeLetters])

  const linkTo = useMemo(() => {
    return `/product-category/${productCategoryCode}/${tabCode}/ticket/${data.code}`
  }, [data.code, productCategoryCode, tabCode])

  const isSeleted = useMemo(() => {
    if (selected && selected.length)
      return selected.find((item) => item === data.code) ? true : false
    return false
  }, [data.code, selected])

  return (
    <Paper
      className={clsx(
        'p-5 d-flex flex-column justify-content-between position-relative',
        className
      )}
      rounded
    >
      <div className='card-circles'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>

      <div
        className='p-3 position-relative'
        style={{
          border: `1px solid ${dayColor(day)}`,
          borderRadius: '5px',
          backgroundColor: dayColor(day, 0.05),
        }}
      >
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column '>
            <div className='fs-2 ff-admm-bold text-white text-uppercase d-flex gap-3'>
              <div className='d-flex flex-column justify-content-center'>
                {onSelectHandler && (
                  <TableSelectioncheckbox
                    disabled={false}
                    checked={isSeleted}
                    onChange={() => onSelectHandler(data.code)}
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                )}
              </div>
              <div>{!isGCCSPP && day === 'Thu' ? 'Pitlane walk' : data.productName}</div>
            </div>
          </div>
        </div>

        <div
          className='fs-1 mt-2 fw-bolder text-uppercase'
          style={{
            color: dayColor(day),
          }}
        >
          {day}
        </div>

        {tabCode === 'shared' && (
          <>
            {data.share?.type && (
              <>
                <div className='fs-5 mt-2 text-uppercase text-white'>{`Shared With: ${data.share.name}`}</div>
                <div className='fs-5 mt-2 text-uppercase d-flex gap-1 text-white'>
                  <div>By:</div>
                  {data.share.type === 'whatsapp' ? (
                    <div className='d-flex align-items-center gap-1'>
                      <MetronicIconButton
                        variant='text'
                        color='white'
                        disabled
                        size='sm'
                        iconType='Communication'
                        iconName='Call'
                        className='h-auto w-auto'
                      />

                      <div className='fs-5 text-uppercase'>{`${
                        data?.share?.mobile ? data?.share.mobile : ''
                      }`}</div>
                    </div>
                  ) : data.share.type === 'email' ? (
                    <div className='d-flex align-items-center gap-1'>
                      <MetronicIconButton
                        variant='text'
                        color='white'
                        size='sm'
                        disabled
                        iconType='Communication'
                        iconName='Mail'
                        className='h-auto w-auto'
                      />

                      <div className='fs-5 text-uppercase'>{`${
                        data.share?.email ? data.share.email : ''
                      }`}</div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        )}

        {day && day !== 'Thu' && data.seatRow && data.seatRow && (
          <div className='fs-5 mt-2 text-uppercase text-white d-flex gap-4'>
            <div> {`Section: ${data?.locationSlug}`}</div>
            <div> {`Row: ${data.seatRow}`}</div>
            <div> {`Seat: ${data.seatNo}`}</div>
          </div>
        )}

        {/* {tabCode !== 'active' && (
          <div className='mt-2 d-flex justify-content-between'>
            <PortaTicketStatusActivityColumn className='fs-5' data={data} />
            <Link className='text-white fs-5' to={linkTo}>
              {data.bookingReservationNo || data.bookingCode}
            </Link>
          </div>
        )} */}

        <div>
          <Button
            fullWidth
            type='reset'
            className='ff-admm-bold text-white mt-3 rounded-0 py-2'
            onClick={() => history.push(linkTo)}
            style={{
              fontSize: '15px',
              backgroundColor: dayColor(day, 0.08),
            }}
          >
            Click to show more
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export const dayColor = (day: string, opacity?: number) => {
  switch (day) {
    case 'Sun':
      return `rgba(156 , 51,  159 , ${opacity ? opacity : 1})`
    case 'Sat':
      return `rgba(239, 126, 17 , ${opacity ? opacity : 1})`
    case 'Thu':
      return `rgba(55, 146, 203 , ${opacity ? opacity : 1})`
    case 'Fri':
      return `rgba(178, 21, 99 , ${opacity ? opacity : 1})`
    case 'Wed':
      return `rgba(102, 103, 123 , ${opacity ? opacity : 1})`
    default:
      return 'dark'
  }
}
