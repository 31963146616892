import {useCallback, useMemo} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {useDebounce} from '../../hooks/useDebounce'

export interface UseSearchableInputFilterOptions<T = any> {
  onFilter: (filter: FilterModel) => void
  filterMixins?: FilterModel
  searchResult?: GlobalSearchModel<T> | null
  delay?: number
  increments?: number
  searchKey?: string
}

export const useSearchableInputFilter = <T>({
  filterMixins,
  searchKey = 'search',
  onFilter,
  searchResult,
  delay = 500,
  increments = 50,
}: UseSearchableInputFilterOptions<T>) => {
  const debouncedSearch = useDebounce(delay)
  const debouncedScroll = useDebounce(delay)

  const getFilterMixins = useCallback(
    (filter: FilterModel) => {
      return {
        ...filterMixins,
        ...filter,
        filters: {
          ...filterMixins?.filters,
          ...filter?.filters,
        },
      }
    },
    [filterMixins]
  )

  const onScrollBottom = useCallback(() => {
    if (searchResult) {
      const {total, limit, data} = searchResult
      if (limit < total && data) {
        debouncedScroll(() => {
          const filters = getFilterMixins({
            limit: limit + increments,
            page: 1,
          })
          onFilter(filters)
        })
      }
    }
  }, [searchResult, debouncedScroll, getFilterMixins, increments, onFilter])

  const onSearch = useCallback(
    (search: string) => {
      const limit = searchResult?.limit === undefined ? increments : searchResult.limit
      debouncedSearch(() => {
        const filters = getFilterMixins({
          filters: {
            [searchKey]: search,
          },
          limit,
          page: 1,
        })
        onFilter(filters)
      })
    },
    [debouncedSearch, getFilterMixins, increments, onFilter, searchKey, searchResult?.limit]
  )

  return useMemo(
    () => ({
      onScrollBottom,
      onSearch,
    }),
    [onScrollBottom, onSearch]
  )
}
