import {toAbsoluteUrl} from '../_metronic/helpers'

export interface ImageConfig {
  src: string
  alt: string
}

export const MainDrawerMenuHeaderLogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB•N•TECH',
}

export const ADMMMenuHeaderLogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/yas-marina.png'),
  alt: 'YAS',
}

export const ADMMCustomerPortalMenuHeaderLogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/yas-marina-circuit-logo.png'),
  alt: 'YAS',
}

export const GPCALogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/gpca.png'),
  alt: 'GPCA',
}

export const EvaInfoPageLogo: ImageConfig = {
  src: toAbsoluteUrl('/favicon-512x512.png'),
  alt: 'WEB·N·TECH',
}

export const PoweredBy: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/Powered-By-WNT.png'),
  alt: 'Powered by WNT',
}

export const Logo404: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const LogoComingSoon: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const EvaBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/dubai-hd.png'),
  alt: 'WEB·N·TECH',
}

export const EvaHeaderLogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const AuthBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/yas-marina-circuit.png'),
  alt: 'Background',
}

export const AuthFormLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/yas-marina.png'),
  alt: 'ADMM',
}

export const CustomerPortalAuthFormLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/yas-marina.png'),
  alt: 'ADMM',
}
export const CustomerRegistrationFormLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const CustomerPortalAuthBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/customer-portal.jpg'),
  alt: 'CUSTOMER PORATL',
}

export const DigitalWaiverAuthBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/digital-waiver-auth-bg.jpg'),
  alt: 'DIGITAL WAIVER',
}

export const DigitalWaiverAuthBackgroundMobile: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/digital-waiver-auth-bg-mobile.jpg'),
  alt: 'DIGITAL WAIVER',
}

export const CustomerPortalAuthBackgroundMobile: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/customer-portal-mobile.jpg'),
  alt: 'CUSTOMER PORATL',
}

export const DefaultFNBProductImage: ImageConfig = {
  src: toAbsoluteUrl('/media/logos/ymc-logo.png'),
  alt: 'YMC',
}

export const DigitalMenuSplashScreenImage: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/f1-etihad-airways-logo.png'),
  alt: 'Etihad Airways',
}
