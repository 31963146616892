import {BaseTableColumnOptions} from '../TableColumn'

export abstract class ColumnStyle {
  public static CODE: BaseTableColumnOptions<unknown>['cellStyle'] = {
    minWidth: 6,
  }

  public static NAME: BaseTableColumnOptions<unknown>['cellStyle'] = {
    minWidth: 12,
  }

  public static DESCRIPTION: BaseTableColumnOptions<unknown>['cellStyle'] = {
    minWidth: 12,
  }

  public static PERSON_FULL_NAME: BaseTableColumnOptions<unknown>['cellStyle'] = {
    noWrap: true,
  }

  public static SLUG: BaseTableColumnOptions<unknown>['cellStyle'] = {
    noWrap: true,
  }

  public static DATE: BaseTableColumnOptions<unknown>['cellStyle'] = {
    noWrap: true,
  }

  public static LOCATION: BaseTableColumnOptions<unknown>['cellStyle'] = {
    minWidth: 10,
  }
}
