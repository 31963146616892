import {useState} from 'react'
import {Drawer} from './Drawer'

export type UseDrawerRefHookReturn = [Drawer | null, (drawer: Drawer | null) => void]

export const useDrawerRef = (): UseDrawerRefHookReturn => {
  const [drawerInstance, setDrawerInstance] = useState<Drawer | null>(null)

  return [drawerInstance, setDrawerInstance]
}
