import {useEffect, useMemo, useRef} from 'react'
import {Helmet} from 'react-helmet'
import {Button} from '../../../components/inputs/Button'
import {Spinner} from '../../../components/utils/Spinner'
import {DeviceUtils} from '../../../utils/DeviceUtils'

interface PortalWhatsAppPdfProps {
  pdf: any
  isLoading: boolean
  isDownloadLoading: boolean
  downloadPdfFile: () => void
  error: string | null
}

export const PortalWhatsAppPdf = ({
  pdf,
  isLoading,
  isDownloadLoading,
  downloadPdfFile,
  error,
}: PortalWhatsAppPdfProps) => {
  const objectRef = useRef<HTMLObjectElement>(null)

  useEffect(() => {
    if (objectRef.current) {
      objectRef.current.setAttribute('referrerpolicy', 'no-referrer')
    }
  }, [])
  const isIphone = useMemo(() => {
    return DeviceUtils.isiPhone()
  }, [])

  useEffect(() => {
    if (isIphone) {
      downloadPdfFile()
    }
  }, [downloadPdfFile, isIphone])

  return (
    <>
      <Helmet>
        <title>Yas Marina Circuit</title>
        <link rel='icon' href='/portal.ico' />
      </Helmet>
      {!isIphone ? (
        !isLoading && !error ? (
          <div
            style={{
              height: '100vh',
              width: '100%',
            }}
          >
            <object
              data={`${pdf}`}
              width='100%'
              height='100%'
              type='application/pdf'
              ref={objectRef}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <div className='position-absolute top-50 start-50 translate-middle text-center  w-100 px-3'>
                <p className='text-white fs-6'>Sorry, your browser doesn't support PDFs!</p>

                <Button
                  className='d-flex align-items-center gap-3 text-center text-white m-auto'
                  style={{
                    backgroundColor: '#0a393f',
                  }}
                  disabled={isDownloadLoading}
                  onClick={downloadPdfFile}
                >
                  Download Instead
                  {isDownloadLoading && <Spinner size='lg' className='ms-3 text-primary' />}
                </Button>
              </div>
            </object>
          </div>
        ) : isLoading && !error ? (
          <div className='text-center position-absolute top-50 start-50 translate-middle'>
            <Spinner size='lg' className='text-primary' />
          </div>
        ) : (
          <div className='fs-4 text-danger position-absolute top-50 start-50 translate-middle'>
            {error}
          </div>
        )
      ) : (
        <div className='text-center position-absolute top-50 start-50 translate-middle'>
          <Spinner size='lg' className='text-primary' />
        </div>
      )}
    </>
  )
}


