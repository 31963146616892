import clsx from 'clsx'
import {useMemo} from 'react'
import styles from './ColorLegend.module.scss'

export interface ColorLegendItem {
  color: string
  label: string
  key: string
  value: number
}
export interface ColorLegendProps {
  legends: ColorLegendItem[]
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void
  className?: string
  classes?: {
    label?: string
  }
  onClick?: (item: ColorLegendItem) => void
  hasTotal?: number
}

export const ColorLegend = ({
  legends,
  onMouseMove,
  onMouseLeave,
  className,
  classes,
  onClick,
  hasTotal,
}: ColorLegendProps) => {
  if (hasTotal) {
    legends.unshift({
      color: '#757575',
      key: 'Total Tickets',
      label: 'Total Tickets',
      value: hasTotal,
    })
  }
  const colors = useMemo(() => {
    return legends.map((legend) => (
      <div
        key={legend.color}
        className={clsx(styles.item, 'mb-1')}
        onMouseMove={(e) => onMouseMove?.(e, legend.key)}
        onMouseLeave={(e) => onMouseLeave?.(e, legend.key)}
        onClick={() => onClick?.(legend)}
        style={{cursor: onClick ? 'pointer' : undefined}}
      >
        <div
          style={{
            backgroundColor: legend.color,
            width: '22px',
            minWidth: '15px',
            height: '12px',
            border: 'none',
            borderRadius: '2px',
            // display: legend.label === 'Total Tickets' ? 'none' : '',
          }}
          className={styles.colorBox}
        ></div>
        <div
          style={{
            fontSize: '15px',
            color: '#00acde',
          }}
          className={clsx(classes?.label, '')}
        >
          {legend.value}
        </div>
        <div
          style={{
            fontSize: '15px',
          }}
          className={clsx(classes?.label, 'mx-1')}
        >
          {legend.label}
        </div>
      </div>
    ))
  }, [classes?.label, legends, onClick, onMouseLeave, onMouseMove])

  return <div className={className}>{colors}</div>
}
