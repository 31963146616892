import {useEffect, useRef, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export interface RatingInputStarProps {
  /** Fill percent from 0 - 1 */
  fill: number
  id: number
  height?: number
  width?: number
  className?: string
  onClick: (value: number) => void
}

export const RatingInputStar = ({
  fill,
  id,
  onClick,
  height = 25,
  width = 25,
  className,
}: RatingInputStarProps) => {
  const [svg, setSvgRef] = useState<SVGElement | null>(null)
  const svgId = useRef(uuidv4())

  useEffect(() => {
    if (svg) {
      const linearGradientEl = document.createElementNS(SVG_NS, 'linearGradient')
      linearGradientEl.setAttribute('id', svgId.current)

      const stop1 = document.createElementNS(SVG_NS, 'stop')
      const stop2 = document.createElementNS(SVG_NS, 'stop')

      stop1.setAttribute('offset', `${fill * 100}%`)
      stop1.setAttribute('stop-color', `#3699ff`)

      stop1.setAttribute('offset', `${fill * 100}%`)
      stop2.setAttribute('stop-color', `#505256`)

      linearGradientEl.appendChild(stop1)
      linearGradientEl.appendChild(stop2)

      svg.prepend(linearGradientEl)
      svg
        .querySelector('[fill]:not(.permanent):not(g)')
        ?.setAttribute('fill', `url(#${svgId.current})`)

      return () => {
        svg.removeChild(linearGradientEl)
      }
    }
  }, [svg, fill])

  return (
    <SVG
      height={height}
      width={width}
      className={className}
      innerRef={setSvgRef}
      src={toAbsoluteUrl('/media/icons/duotone/General/Star.svg')}
      onClick={() => onClick(id)}
    />
  )
}

const SVG_NS = 'http://www.w3.org/2000/svg'
