import {useMemo, ReactNode} from 'react'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useHistory} from 'react-router'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useLocation} from 'react-router'
interface TicketAuthParams {
  children?: ReactNode
  qrCodemessage: string
}

export const TicketAuth = ({children, qrCodemessage}: TicketAuthParams) => {
  const history = useHistory()
  const currentUser = useRootStateSelector((state) => state.eva?.user)
  const {pathname} = useLocation()
  const qrCodeStartImage = useMemo(() => {
    if (!currentUser) {
      return (
        <div className='text-center d-flex justify-content-center flex-column align-items-center h-100 '>
          <div
            className='animation-fade-in'
            onClick={() =>
              history.push('/login', {
                from: pathname,
              })
            }
          >
            <span className='svg-icon svg-icon-dark-25'>
              <SVG
                src={toAbsoluteUrl('/media/icons/duotone/Shopping/Barcode-read.svg')}
                className=' w-100px h-100px'
              />
            </span>
            <p className='text-center mt-5 w-75 m-auto'>{qrCodemessage}</p>
          </div>
        </div>
      )
    }
  }, [currentUser, history, pathname, qrCodemessage])

  return (
    <>
      {qrCodeStartImage}
      {currentUser && children}
    </>
  )
}
