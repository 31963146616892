import {ReactNode, useMemo} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {MetronicIconPath, MetronicIcon} from '../../../../../app/components/inputs/MetronicIcon'
import {checkIsActive, KTSVG} from '../../../../helpers'
import {useLayout} from '../../../core'

export interface AsideMenuItemWithSubProps {
  to: string
  title: string
  icon?: string | MetronicIconPath
  hasBullet?: boolean
  children: ReactNode
}

const AsideMenuItemWithSub = ({
  children,
  to,
  title,
  icon,
  hasBullet,
}: AsideMenuItemWithSubProps) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  const menuIcon = useMemo(() => {
    if (icon) {
      if (typeof icon === 'string') {
        return <KTSVG path={icon} className='svg-icon-3x' />
      }
      return <MetronicIcon iconType={icon.iconType} iconName={icon.iconName} size='lg' />
    }
  }, [icon])

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && <span className='menu-icon'>{menuIcon}</span>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
