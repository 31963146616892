import {useCallback, useEffect, useMemo, useState} from 'react'
import {useOnChange} from '../../../../../../components/hooks/useOnChange'
import {TableActionModalProps} from '../../../../../../components/hooks/useTableActionModal'
import {Button} from '../../../../../../components/inputs/Button'
import {FilterSearchableSelectInput} from '../../../../../../components/inputs/SearchableSelect/FilterSearchableSelectInput'
import {SweetAlert} from '../../../../../../components/modals/SweetAlert'
import {HorizontalDivider} from '../../../../../../components/utils/HorizontalDivider'
import {selectItemMapper} from '../../../../../../utils/idExtractor'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {LocationModel} from '../../../../../../models/acs/LocationModel'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {GetLocationBoatList} from '../../../../../default/acs/redux/AcsCRUD'
import {useRouteMatch} from 'react-router-dom'
import {GetEventByCode} from '../../../../../default/svc/redux/SvcCRUD'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {BookingDetailModel} from '../../../../../../models/customer-portal/BookingDetailModel'
import {MetronicIconButton} from '../../../../../../components/inputs/MetronicIconButton'

interface RouteMatch {
  eventCode?: string
}
export interface AssignBoatModalPortalProps extends TableActionModalProps {
  product?: BookingDetailModel | null
  onSubmit: (product: BookingDetailModel, data: LocationModel) => void
}

export const AssignBoatModalPortal = ({
  isOpen,
  onClose,
  product,
  onSubmit,
}: AssignBoatModalPortalProps) => {
  const match = useRouteMatch<RouteMatch>()
  const {eventCode} = match.params
  const [event, setEvent] = useState<EventModel>()

  const [value, setValue] = useState<LocationModel | null>(null)
  const [searchResult, setSearchResult] = useState<GlobalSearchModel<LocationModel>>()
  const {pushError} = useAlerts()

  const resetEventData = useCallback(
    async (eventCode: string) => {
      try {
        const {data} = await GetEventByCode(eventCode)
        setEvent(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  const handleOnFilter = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetLocationBoatList({
          ...filter,
          filters: {
            ...filter?.filters,
            type: ['boat'],
            venue: event?.venue?.code,
            boatEvent: eventCode,
          },
        })
        setSearchResult(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [event?.venue?.code, pushError, eventCode]
  )

  const handleSubmit = useCallback(() => {
    if (value && product) {
      onSubmit(product, value)
    }
  }, [value, product, onSubmit])

  const handleSetValue = useCallback((value: LocationModel | null) => {
    setValue(value)
  }, [])

  useOnChange(isOpen, () => {
    if (!isOpen) {
      setValue(null)
    }
  })

  useEffect(() => {
    if (eventCode) {
      resetEventData(eventCode)
    }
  }, [eventCode, resetEventData])

  const isValid = useMemo(() => {
    return Boolean(value)
  }, [value])

  return (
    <SweetAlert
      customClass={{htmlContainer: 'overflow-visible'}}
      onClose={onClose}
      open={isOpen}
      showConfirmButton={false}
    >
      <div className='swal2-corners'>
        <div className='text-end me-2'>
          <MetronicIconButton
            variant='text'
            size='md'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onClose}
          />
        </div>
      </div>
      <div className='position-relative'>
        <div className='text-start'>
          <div className='justify-content-between mb-5'>
            <div className='fs-3 fw-bold'>ASSIGN BOAT</div>
            <HorizontalDivider />
          </div>
          <div className='mb-5'>
            <FilterSearchableSelectInput
              value={value}
              searchResult={searchResult}
              onFilter={handleOnFilter}
              label=''
              placeholder='Select Boat'
              itemMapper={selectItemMapper}
              onChange={handleSetValue}
            />
          </div>
          <div className='d-flex justify-content-end'>
            <Button
              disabled={!isValid}
              className=''
              variant='primary'
              size='sm'
              onClick={handleSubmit}
            >
              ASSIGN
            </Button>
          </div>
        </div>
      </div>
    </SweetAlert>
  )
}
