import {useCallback, useState} from 'react'
import {useOnMount} from '../hooks/useOnMount'
import {MetronicIconButton} from '../inputs/MetronicIconButton'
import Markdown from 'markdown-to-jsx'

export interface AlertCreateOptions {
  message: string
  variant?: AlertVariant
  timeout?: number
}

export interface AlertData extends AlertCreateOptions {
  key: string
}

export type AlertVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'warning'
  | 'success'
  | 'info'
  | 'light'
  | 'dark'

export interface AlertProps {
  children: string
  alertKey: string
  variant?: AlertVariant
  timeout?: number
  onClose?: (key: string) => void
}

export const Alert = ({children, variant = 'dark', timeout, alertKey, onClose}: AlertProps) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const handleClose = useCallback(() => {
    onClose && onClose(alertKey)
    if (timer) {
      clearTimeout(timer)
    }
  }, [onClose, alertKey, timer])

  useOnMount(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        handleClose()
      }, timeout)
      setTimer(timer)
    }
  })

  return (
    <div className={`alert alert-${variant}`} role='alert'>
      <MetronicIconButton
        variant='text'
        iconType='Navigation'
        iconName='Close'
        size='sm'
        onClick={handleClose}
        tooltip='Close'
      />
      <Markdown>{String(children || '')}</Markdown>
    </div>
  )
}
