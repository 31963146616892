import clsx from 'clsx'
import {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {useLayoutHelpers} from '../useLayoutHelpers'

export interface NavDrawerMenuProps {
  item: NavigationSectionMenuItem
  isChild?: boolean
  type?: string
}

export const NavDrawerMenuItem = ({item, isChild, type}: NavDrawerMenuProps) => {
  const {childrenIsActive, itemHasSubMenu} = useLayoutHelpers()
  const hasSubMenus = useMemo(() => itemHasSubMenu(item), [item, itemHasSubMenu])

  const content = useMemo(() => {
    const isActive = childrenIsActive([item])
    const content = (
      <>
        {!hasSubMenus && isChild && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        <span
          className={clsx(
            'menu-title',
            {
              'text-primary': isActive,
              'ff-admm-bold text-uppercase': type === 'portal',
            },
            !isActive && item.status && `text-${item.status}`
          )}
        >
          {item.title}
        </span>
        {hasSubMenus && <span className='menu-arrow'></span>}
      </>
    )
    if (item.to) {
      return (
        <Link
          to={item.to}
          className={clsx('menu-link', type === 'portal' ? 'py-4' : 'py-3')}
          style={{
            fontSize: type === 'portal' ? '20px' : '',
          }}
        >
          {item.icon && typeof item.icon === 'string' && (
            <img width='60px' alt={item.icon} src={item.icon} />
          )}
          {content}
        </Link>
      )
    }
    return <div className='menu-link py-3'>{content}</div>
  }, [childrenIsActive, hasSubMenus, isChild, item, type])

  return (
    <div
      className={clsx('menu-item', {
        'menu-accordion': hasSubMenus,
      })}
      data-kt-menu-trigger='click'
    >
      {content}
      {hasSubMenus && (
        <div className='menu-sub menu-sub-accordion pt-3'>
          {item.subMenus?.map((item, i) => {
            return <NavDrawerMenuItem key={i} item={item} isChild />
          })}
        </div>
      )}
    </div>
  )
}
