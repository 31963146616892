import socketIOClient from 'socket.io-client'
import {getUrlData, LAYOUT_TYPE, WSS_URL} from '../../../config/env'
import {useRootStateSelector} from './useRootStateSelector'
import {useEffect, useMemo} from 'react'

export interface UseWebSocketsOptions<T> {
  socketName?: string
  callback: (data: T) => void
}

/**
 * @deprecated please use `useWebSocket` instead.
 */
export const useWebSockets = <T>({callback, socketName}: UseWebSocketsOptions<T>) => {
  const tokens = useRootStateSelector((state) => ({
    default: state?.auth?.token,
    outlet: state?.outlet?.auth?.token,
    customer: state.customerPortal?.auth?.token,
  }))

  const jwt = useMemo(() => {
    switch (LAYOUT_TYPE) {
      case 'customer-delegate':
      case 'customer-portal':
        return tokens.customer
      case 'outlet':
        return tokens.outlet
      default:
        return tokens.default
    }
  }, [tokens])

  useEffect(() => {
    if (WSS_URL && socketName) {
      const {organizationCode, outletCode} = getUrlData()
      const socket = socketIOClient(WSS_URL, {
        transports: ['websocket', 'polling'],
        auth: {
          token: jwt,
          organizationCode,
          outletCode,
        },
      })

      socket.on('connect_error', (err: any) => {
        if (err.message === 'denied') {
          socket?.disconnect()
        }
      })

      if (socketName) {
        socket.on(socketName, (data: T) => {
          callback(data)
        })
      }
      return () => {
        socket?.disconnect()
      }
    }
  }, [callback, socketName, jwt])
}
