import {ApiFormValidationError} from '../../models/ApiErrorModel'

export class ApiValidationException extends Error {
  constructor(private response: ApiFormValidationError) {
    super(response.error)
  }

  private getTitle = () => {
    return this.response.error
  }

  private getErrorListMarkdown = () => {
    let list = ``
    Object.values(this.response.data).forEach((value) => {
      if (value.msg) {
        list += `- ${value.msg}\n`
      }
    })
    return list
  }

  private getTitleMarkdown = () => {
    if (Object.values(this.response.data).length > 0) {
      return `**${this.getTitle()}**`
    }
    return this.getTitle()
  }

  public toMarkdown = () => {
    return `${this.getTitleMarkdown()}\n\n${this.getErrorListMarkdown()}`
  }
}
