import {useCallback, useState} from 'react'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {QRCodeInput} from '../../../components/inputs/QRCode/QRCodeInput'
import {OrDivider} from '../../../components/utils/OrDivider'
import {TextInputWithButton} from './TextInputWithButton'

export interface ScanOrTypeQrProps {
  onSubmit: (value: string) => void
  placeholder: string
  scanDelay?: number
  disabled?: boolean
}

export const ScanOrTypeQr = ({onSubmit, disabled, placeholder, scanDelay}: ScanOrTypeQrProps) => {
  const [textValue, setTextValue] = useState('')

  const handleButtonSubmit = useCallback(() => {
    onSubmit(textValue)
  }, [onSubmit, textValue])

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value)
  }, [])

  return (
    <>
      <QRCodeInput
        scanDelay={scanDelay}
        className='w-100 border border-success'
        onChange={onSubmit}
        disabled={disabled}
      />
      <OrDivider />
      <label className='form-label d-flex align-items-center'>
        <MetronicIcon iconType='Shopping' iconName='Barcode-read' />
        <span>Enter QR Code</span>
      </label>
      <div className='d-flex'>
        <TextInputWithButton
          disabled={disabled}
          onButtonClick={handleButtonSubmit}
          onChange={handleTextChange}
          buttonLabel='Apply'
          placeholder={placeholder}
        />
      </div>
    </>
  )
}
