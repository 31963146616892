import {ReactNode} from 'react'
import {createPortal} from 'react-dom'
import {useLayoutWaiverData} from '../LayoutWaiverProvider'

export const HEADER_CONTROLS_CONTAINER_PARENT_ID = 'header_controls_container_parent'

export interface HeaderControlsProps {
  children?: ReactNode
}

export const HeaderControls = ({children}: HeaderControlsProps) => {
  const {headerControls} = useLayoutWaiverData()

  if (!headerControls) {
    return null
  }

  return createPortal(children, headerControls)
}
