import {useCallback} from 'react'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {ReservationPortalModel} from '../../../../../models/ems/ReservationMedel'

export interface ReservationActionsProps {
  selected: TableRowId[]
  reservations: ReservationPortalModel[]
  onRelease?: (reservation: ReservationPortalModel[]) => void
  loading?: boolean
}

export const ReservationTableSelectionActions = ({
  onRelease,
  reservations,
  selected,
  loading,
}: ReservationActionsProps) => {
  const idExtractor = useCallback((item: ReservationPortalModel) => item.code, [])

  return (
    <>
      <TableSelectionAction
        disabled={loading}
        variant='danger'
        onClick={onRelease}
        idExtractor={idExtractor}
        items={reservations}
        selected={selected}
        filter={ReservationIsReleased}
      >
        Release
      </TableSelectionAction>
    </>
  )
}

export const ReservationIsReleased = (reservation: ReservationPortalModel) => {
  return !['released'].includes(reservation.status)
}
