import {useCallback, useMemo, useRef} from 'react'
import clsx from 'clsx'
import {v4 as uuidv4} from 'uuid'
import {SelectInputItem} from './SelectInput'
import {RadioInput} from './RadioInput'
import {InputLabel} from './InputLabel'

export interface RadioGroupInputProps {
  className?: string
  value: string
  items: SelectInputItem[]
  onChange: (value: string) => void
  disabled?: boolean
  label: string
  labelClassName?: string
}

export const RadioGroupInput = ({
  items,
  value,
  className,
  disabled,
  onChange,
  label,
  labelClassName,
}: RadioGroupInputProps) => {
  const radioName = useRef(uuidv4())

  const handlePermissionChange = useCallback(
    (value: string) => () => {
      onChange(value)
    },
    [onChange]
  )

  const inputs = useMemo(() => {
    return items.map((item) => {
      return (
        <RadioInput
          key={item.value}
          label={item.label}
          onChange={handlePermissionChange(item.value)}
          checked={value === item.value}
          name={radioName.current}
          disabled={disabled}
          description={item.description}
        />
      )
    }, [])
  }, [disabled, handlePermissionChange, items, value])

  return (
    <div className={clsx('py-5', className)}>
      <InputLabel className={labelClassName ? labelClassName : '' }>{label}</InputLabel>
      <div>{inputs}</div>
    </div>
  )
}
