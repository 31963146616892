import {FormikContextType, useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {CustomerModel} from '../../../../../../../models/CustomerModel'
import {
  getCustomerPayload,
  initialValues,
  useFormDrawerCustomerData,
} from '../../../../../hooks/useFormDrawerCustomerData'
import {useAlerts} from '../../../../../../../components/alerts/useAlerts'
import {useDispatch} from 'react-redux'
import {CustomerForm, roleFormValidationSchema, CustomerFormValues} from '../../forms/CustomerForm'
import {PostCustomer} from '../../../../../redux/CustomerPortalCRUD'
import * as RxCustPort from '../../../../../redux/CustomerPortalRedux'
import {Button} from '../../../../../../../components/inputs/Button'
import {useOnChange} from '../../../../../../../components/hooks/useOnChange'
import {LoadingSpinner} from '../../../../../../../components/utils/LoadingSpinner'

export interface BookingWizardCreateBulkCustomerStepValues {
  customers?: CustomerModel[] | null
}

interface BookingWizardCreateBulkConsolidatedCustomerStepProps<
  T extends BookingWizardCreateBulkCustomerStepValues
> {
  formik: FormikContextType<T>
  handleCreateCustomer: () => void
}

export const BookingWizardCreateBulkConsolidatedCustomerStep = <
  T extends BookingWizardCreateBulkCustomerStepValues
>({
  formik,
  handleCreateCustomer,
}: BookingWizardCreateBulkConsolidatedCustomerStepProps<T>) => {
  const formDrawer = useFormDrawerCustomerData()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isExistedCustomer, setIsExistedCustomer] = useState<CustomerModel>()
  const {pushError} = useAlerts()
  const dispatch = useDispatch()

  const createNewCustomer = useCallback(
    async (values: CustomerFormValues) => {
      try {
        const payload = getCustomerPayload({values})
        const {data} = await PostCustomer(payload)
        if (data) {
          formik.setFieldValue('customers', [data])
          handleCreateCustomer()
        }

        dispatch(RxCustPort.actions.customers.createSuccess())
      } catch (e) {
        dispatch(RxCustPort.actions.customers.createFailed())
        throw e
      }
    },
    [dispatch, formik, handleCreateCustomer]
  )

  const customerFormik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setIsLoading(true)
        await createNewCustomer(values)
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
        setIsLoading(false)
      }
    },
  })

  const disabledFields = useMemo(() => {
    return {
      type: true,
    }
  }, [])

  useEffect(() => {
    customerFormik.setFieldValue('type', 'customer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useOnChange(formik.values.customers, () => {
    if (formik.values?.customers?.length) {
      const customerList = formik.values?.customers.map((customer) => {
        return {
          customer: customer,
          products: [],
          isSkip: false,
          isFulfill: false,
          isSelected: false,
        }
      })
      if (customerList && customerList.length) {
        customerList[0].isSelected = true
        formik.setFieldValue('bookingResults', customerList)
      }
    }
  })

  return (
    <div>
      <form
        className='form-drawer-container drawer-container'
        noValidate
        onSubmit={customerFormik.handleSubmit}
      >
        <CustomerForm
          countries={formDrawer.countries}
          formik={customerFormik}
          setIsExistedCustomer={setIsExistedCustomer}
          isExistedCustomer={isExistedCustomer}
          disabledFields={disabledFields}
        />
        <div className='w-100 text-end'>
          <Button
            variant='primary'
            className='btn'
            disabled={customerFormik.isSubmitting || !customerFormik.isValid || isLoading}
            type='submit'
          >
            <LoadingSpinner spinnerOnly loading={isLoading} />
            Create & Next
          </Button>
        </div>
      </form>
    </div>
  )
}
