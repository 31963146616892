import {useCustomerAuth} from './useCustomerAuth'
import {useCallback, useEffect, useState} from 'react'
import {useWebSocket} from '../../../components/utils/WebSocketProvider'
import {GetWidgets} from '../redux/CustomerPortalCRUD'
import {WidgetModel} from '../../../models/customer-portal/WidgetModel'

export interface UseEventDashboardWidgetDataOptions {
  widgets: (keyof WidgetModel)[]
  eventCode?: string
}

export const useEventDashboardWidgetData = ({
  eventCode,
  widgets,
}: UseEventDashboardWidgetDataOptions) => {
  const [widgetData, setWidgetData] = useState<WidgetModel>({})
  const {socket} = useWebSocket()
  const auth = useCustomerAuth()

  const resetWidgetData = useCallback(async () => {
    if (eventCode) {
      const {data} = await GetWidgets(widgets, eventCode)
      setWidgetData(data)
    }
  }, [eventCode, widgets])

  useEffect(() => {
    if (eventCode && auth) {
      const handlers = widgets.map((widget) => {
        const event = `${widget}:${auth.getUser().code}:${eventCode}`

        return {
          event,
          callback: (data: {data: WidgetModel[keyof WidgetModel]}) => {
            setWidgetData((widgetData) => ({...widgetData, [widget]: data.data}))
          },
        }
      })

      handlers.forEach((handler) => {
        socket?.on(handler.event, handler.callback)
      })

      return () => {
        handlers.forEach((handler) => {
          socket?.off(handler.event, handler.callback)
        })
      }
    }
  }, [auth, eventCode, socket, widgets])

  useEffect(() => {
    resetWidgetData()
  }, [resetWidgetData])

  return {data: widgetData}
}
