import clsx from 'clsx'
import {ReactNode} from 'react'
import {MetronicIcon} from '../inputs/MetronicIcon'

export interface TableColumnIconProps {
  onClick: () => void
  iconType?: string
  iconName?: string
  label?: string | ReactNode
  className?: string
}

export const TableColumnIcon = ({
  iconName,
  iconType,
  label,
  onClick,
  className,
}: TableColumnIconProps) => {
  return (
    <span
      className={clsx('text-white fw-bolder text-uppercase p-0 w-auto', className)}
      onClick={onClick}
      role='button'
    >
      {label}
      {iconType && iconName && (
        <MetronicIcon color='white' iconType={iconType} iconName={iconName} />
      )}
    </span>
  )
}
