import {TreeNodeItem} from './TreeSelect'
import {TreeSelectNode} from './TreeSelectNode'

export class TreeSelectNodeCollection {
  constructor(public nodes: TreeSelectNode[] = []) {}

  private static recursivelyCreateNodes(
    nodes: TreeNodeItem[],
    valueExtractor?: (node: TreeNodeItem) => boolean
  ): TreeSelectNode[] {
    return nodes.map((node) => {
      return new TreeSelectNode(
        node.value,
        node.label,
        (valueExtractor && valueExtractor(node)) || false,
        (node.items && TreeSelectNodeCollection.recursivelyCreateNodes(node.items)) || []
      )
    })
  }

  private static resetParentStatusRecursively(
    value: boolean,
    parent: TreeSelectNode,
    collection: TreeSelectNodeCollection
  ) {
    if (value && parent.hasAllChildrenSelected()) {
      parent.setValueRecursively(true)
    } else {
      parent.setValue(false)
    }
    const grandParent = collection.getParent(parent)
    if (grandParent) {
      TreeSelectNodeCollection.resetParentStatusRecursively(value, grandParent, collection)
    }
  }

  private getParent(node: TreeSelectNode): TreeSelectNode | undefined {
    let foundParent: TreeSelectNode | undefined = undefined

    TreeSelectNode.recursiveFind(this.nodes, (currentNode, parent) => {
      const match = node.id === currentNode.id
      if (match && parent) {
        foundParent = parent
      }
      return match
    })

    return foundParent
  }

  private static getApiValues(nodes: TreeSelectNode[]): string[] {
    let values: string[] = []

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      if (node.isIndeterminate()) {
        values.push(...TreeSelectNodeCollection.getApiValues(node.children))
      } else if (node.selected) {
        values.push(node.id)
      }
    }

    return values
  }

  public getApiValues(): string[] {
    return TreeSelectNodeCollection.getApiValues(this.nodes)
  }

  public setNodeValue(id: string, value: boolean) {
    const found = TreeSelectNode.recursiveFind(this.nodes, (node) => node.id === id)
    if (found) {
      found.setValueRecursively(value)
      const parent = this.getParent(found)
      if (parent) {
        TreeSelectNodeCollection.resetParentStatusRecursively(value, parent, this)
      }
    }
  }

  public setValuesRecursively(ids: string[], value: boolean) {
    TreeSelectNode.recursiveEach(this.nodes, (node) => {
      if (ids.includes(node.id)) {
        node.setValueRecursively(value)
      }
    })
  }

  public setValues(ids: string[], value: boolean) {
    TreeSelectNode.recursiveEach(this.nodes, (node) => {
      if (ids.includes(node.id)) {
        node.setValue(value)
      }
    })
  }

  public addItemsRecursively(items: TreeNodeItem[]) {
    this.nodes.push(...TreeSelectNodeCollection.recursivelyCreateNodes(items))
  }

  public clone() {
    return new TreeSelectNodeCollection(this.nodes.map((node) => node.clone()))
  }
}
