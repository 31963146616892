import {ManageRowAction} from '../../../../../components/tables/actions/ManageRowAction'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {UserModel} from '../../../../../models/customer-portal/UserModel'

export interface UserTableRowActionsProps {
  data: UserModel
  onDelete?: (data: UserModel) => void
  onEdit?: (data: UserModel) => void
  onActivate?: (data: UserModel) => void
  onReset?: (data: UserModel) => void
  onSetSmartPin?: (data: UserModel) => void
  isLoading?: boolean
}

export const UserTableRowActions = ({
  data,
  onDelete,
  onEdit,
  onActivate,
  onReset,
  onSetSmartPin,
  isLoading,
}: UserTableRowActionsProps) => {
  return (
    <>
      <RowAction
        data={data}
        iconType='Devices'
        iconName='Keyboard'
        variant='success'
        tooltip='Set Smart Pin'
        onClick={onSetSmartPin}
        loading={isLoading}
      />
      {canBeReset(data) &&<RowAction
        data={data}
        iconType='Code'
        iconName='Lock-overturning'
        variant='info'
        tooltip='Reset user mfa'
        onClick={onReset}
        loading={isLoading}
      />}
      {canBeActivated(data) && (
        <RowAction
          data={data}
          iconType='Communication'
          iconName='Sending mail'
          variant='success'
          tooltip='Activate'
          onClick={onActivate}
          loading={isLoading}
        />
      )}

      <ManageRowAction data={data} onEdit={onEdit} />

      <RowAction
        loading={isLoading}
        data={data}
        iconType='General'
        iconName='Trash'
        variant='danger'
        tooltip='Delete'
        onClick={onDelete}
      />
    </>
  )
}

export const canBeActivated = (data: UserModel) => {
  if (data.status === 'active') {
    return false
  }
  return true
}
export const canBeReset = (data: UserModel) => {
  if (data.status === 'active') {
    return true
  }
  return false
}
