import {useCallback, useMemo, useState} from 'react'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useDispatch} from 'react-redux'
import {actions} from '../redux/CustomerPortalRedux'
import {FilterModel} from '../../../models/FilterModel'
import {TicketModel} from '../../../models/ems/TicketModel'
import {GetBookingByCode, GetBookingDetail, GetTicketByCode} from '../redux/CustomerPortalCRUD'
import {BookingDetailModel} from '../../../models/customer-portal/BookingDetailModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {BookingModel} from '../../../models/ems/BookingModel'

interface usePortaDataBookingDetailProps {
  eventCode?: string
  ticketCode?: string
  bookingCode?: string
}

export const usePortaDataBookingDetail = ({
  eventCode,
  ticketCode,
  bookingCode,
}: usePortaDataBookingDetailProps) => {
  const dispatch = useDispatch()
  const [currentTicket, setCurrentTicket] = useState<TicketModel>()
  const [currentBooking, setCurrentBooking] = useState<BookingModel>()
  const [currentBookingsDetails, setCurrentBookingsDetails] =
    useState<GlobalSearchModel<BookingDetailModel>>()
  const searchDebounce = useDebounce(400)

  const getBookingDetail = useCallback(async () => {
    const {data} = await GetBookingDetail({
      filters: {
        search: '',
        booking: bookingCode,
      },
    })
    if (data) {
      setCurrentBookingsDetails(data)
    }
  }, [bookingCode])

  const getTicketByCode = useCallback(async () => {
    if (ticketCode) {
      const {data} = await GetTicketByCode(ticketCode)
      if (data) {
        setCurrentTicket(data)
      }
    }
  }, [ticketCode])

  const refreshBookingTable = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.bookings.search())
    })
  }, [dispatch, searchDebounce])

  const bookingInitialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['draft', 'confirmed', 'fulfilled', 'active'],
      },
      groupBy: '',
    }
    if (eventCode && filters.filters) {
      filters.filters.event = eventCode
    }

    return filters
  }, [eventCode])

  const refreshCurrentBookingDetails = useCallback(async () => {
    if (bookingCode) {
      const {data} = await GetBookingByCode(bookingCode)
      setCurrentBooking(data)
      getBookingDetail()
    }
  }, [bookingCode, getBookingDetail])

  return useMemo(
    () => ({
      refreshBookingTable,
      bookingInitialFilters,
      currentTicket,
      getBookingDetail,
      currentBookingsDetails,
      getTicketByCode,
      refreshCurrentBookingDetails,
      currentBooking,
      setCurrentBookingsDetails,
    }),
    [
      refreshBookingTable,
      bookingInitialFilters,
      currentTicket,
      getBookingDetail,
      currentBookingsDetails,
      getTicketByCode,
      refreshCurrentBookingDetails,
      currentBooking,
      setCurrentBookingsDetails,
    ]
  )
}
