import {useCallback, useEffect, useRef, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import Webcam from 'react-webcam'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {Spinner} from '../../../../components/utils/Spinner'
import {BlobUtils} from '../../../../utils/BlobUtils'

export interface LicenseCaptureModalProps {
  onSubmit: (image: ImageInputValue) => void
  isOpen: boolean
  onClose?: () => void
  isLoading?: boolean
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
}

export const LicenseCaptureModal = ({
  onSubmit,
  onClose,
  isOpen,
  isLoading,
}: LicenseCaptureModalProps) => {
  const [image, setImage] = useState<ImageInputValue>()
  const webcamRef = useRef<Webcam>(null)
  const timer = useDebounce(500)
  const getScreenshot = useCallback(() => {
    const src = webcamRef.current?.getScreenshot()
    if (src) {
      const blob = BlobUtils.convertBase64ToBlob(src)
      const image = new ImageInputValue(blob, new Date().toISOString())
      return image
    }
  }, [])

  const handleCaptureAndAddMore = useCallback(() => {
    const image = getScreenshot()
    if (image) {
      setImage(image)
      timer(() => onSubmit(image))
    }
  }, [getScreenshot, onSubmit, timer])

  useEffect(() => {
    if (!isOpen) setImage(undefined)
  }, [isOpen])

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className='bg-transparent p-5'
      id='kt_mega_menu_modal'
      aria-hidden='true'
      tabIndex='-1'
      size='xl'
      centered
      contentClassName='shadow-none p-5'
      style={{
        zIndex: 1000000,
      }}
    >
      <div className='d-flex flex-column'>
        <div className='d-flex mb-3 overflow-auto'>
          {image && (
            <div style={{height: '80px'}} key={image.id}>
              <img style={{height: '100%'}} src={image.url} alt={image.filename} />
            </div>
          )}
        </div>
        <div>
          <Webcam
            className='w-100'
            videoConstraints={videoConstraints}
            screenshotFormat='image/jpeg'
            ref={webcamRef}
          />
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button variant='primary' onClick={handleCaptureAndAddMore} disabled={isLoading}>
            Capture
            {isLoading && <Spinner className='ms-3' size='lg' />}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
