import { useCallback, useState } from "react"
import { useLoadingState } from "../../../../../../components/hooks/useLoadingState"
import { LocationModel } from "../../../../../../models/acs/LocationModel"
import { ProductModel } from "../../../../../../models/ems/ProductModel"
import { GlobalSearchModel } from "../../../../../../models/GlobalSearchModel"
import { useAlerts } from "../../../../../../components/alerts/useAlerts"
import { FilterModel } from "../../../../../../models/FilterModel"
import { GetPersonalLocationsByProductCode, GetProducts, GetVoucherTemplateList } from "../../../../redux/CustomerPortalCRUD"
import { VoucherModel } from "../../../../../../models/svc/VoucherModel"

export interface UseReservationPortalDataParams {
    eventCode?: string | null
    productCode?: string | null
  }
  
export const useReservationPortalData = ({eventCode, productCode}: UseReservationPortalDataParams) => {
    const {setIsLoading, isLoading} = useLoadingState()
    const [locationSearchResults, setLocationSearchResults] =
      useState<LocationModel[]>()
    const [productSearchResults, setProductSearchResults] =
      useState<GlobalSearchModel<ProductModel>>()
    const [products, setProducts] = useState<ProductModel[]>([])
    const [voucherSearchResults, setVoucherSearchResults] =
    useState<GlobalSearchModel<VoucherModel>>()
    const {pushError} = useAlerts()
  
    const getLocations = useCallback(
      async () => {
        try {
          if(productCode){
            const {data} = await GetPersonalLocationsByProductCode(productCode)
          setLocationSearchResults(data)}
        } catch (e: any) {
          pushError(e)
        }
      },
      [productCode, pushError]
    )
  
    const searchProducts = useCallback(
      async (filter?: FilterModel) => {
        try {
          const {data} = await GetProducts({
            ...filter,
            filters: {
              ...filter?.filters,
              event: eventCode || undefined,
            },
          })
          setProductSearchResults({
            data: data.data,
            limit: 1000,
            page: 1,
            total: data.data.length,
          })
        } catch (e: any) {
          pushError(e)
        }
      },
      [eventCode, pushError]
    )
  
    const refreshProductsList = useCallback(
      async (filter?: FilterModel) => {
        if (eventCode) {
          const doneLoading = setIsLoading('products')
          const newProdFilters = {
            ...filter,
            filters: {
              ...filter?.filters,
              event: eventCode
            },
          }
          try {
            const {data} = await GetProducts(newProdFilters)
            setProducts(data.data)
            setProductSearchResults({
              data: data.data,
              limit: 1000,
              page: 1,
              total: data.data.length,
            })
          } catch (e:any) {
            pushError(e)
          } finally {
            doneLoading()
          }
        }
      },
      [eventCode, pushError, setIsLoading]
    )

    const searchVouchers = useCallback(
      async (filter?: FilterModel) => {
        try {
          const {data} = await GetVoucherTemplateList({
            ...filter,
            filters: {
              ...filter?.filters,
              event: eventCode,
            },
          })
          setVoucherSearchResults(data)
        } catch (err: any) {
          setVoucherSearchResults(undefined)
          pushError(err)
        }
      },
      [eventCode, pushError]
    )

    return {
      products,
      isLoading,
      refreshProductsList,
      getLocations,
      locationSearchResults,
      searchProducts,
      productSearchResults,
      searchVouchers,
      voucherSearchResults,
    }
  }
  