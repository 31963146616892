import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {FilterModel} from '../../../models/FilterModel'
import {BookingTable} from '../../customer-delegate/components/tables/BookingTable/BookingTable'
import {
  BookingPortalFormCreate,
  // BookingPortalFormEdit,
} from '../../customer-delegate/components/DrawerForm/BookingPortalFormDrawer'
// import {GetBookingByCode} from '../redux/CustomerPortalCRUD'
// import {BookingModel} from '../../../models/ems/BookingModel'
import {CustomerModel} from '../../../models/CustomerModel'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {EventModel} from '../../../models/ems/EventModel'
import {TableRowId} from '../../../components/tables/TableRow'
import {actions} from '../redux/CustomerDelegateRedux'
import {AddDelegateModal, AddDelegateValues} from '../components/modals/AddDelegateModal'
import {useModalState} from '../../../components/modals/useModalState'
import {useFormik} from 'formik'
import {useAlerts} from '../../../components/alerts/useAlerts'

interface BookingPageProps {
  customer?: CustomerModel
  event?: EventModel
}

export const BookingsPortalPage = ({customer, event}: BookingPageProps) => {
  const bookings = useRootStateSelector((state) => state.customerDelegates.bookings)
  const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const searchDebounce = useDebounce(200)
  const {setFilter, filters} = useEntityFilter('customer-delegate-booking')
  const dispatch = useDispatch()
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const {
    hide: hideSelectionModal,
    isOpen: isSelectionOpen,
    open: openSelectionModal,
  } = useModalState()
  const {pushError} = useAlerts()

  const formik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    onSubmit: async ({...values}, {setSubmitting, setFieldValue}) => {
      try {
        setSubmitting(true)
        // onSubmit && (await onSubmit({row, column, ...values}))
        hideSelectionModal()
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['draft', 'confirmed', 'fulfilled', 'active'],
      },
    }
    return filters
  }, [])

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.bookings.delegatesSearch())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter({
        ...filter,
        filters: {},
        // groupBy: 'status',
      })
      refresh()
    },
    [setFilter, refresh]
  )

  const handleEdit = useCallback(
    async (booking) => {
      openSelectionModal()
    },
    [openSelectionModal]
  )

  const memoizedFilters = useMemo(() => {
    return filters || {}
  }, [filters])

  return (
    <>
      {!customer && <PageTitle>Bookings</PageTitle>}

      <BookingTable
        expandedGroups={expandedGroups}
        onExpandedGroupChange={setExpandedGroups}
        filters={memoizedFilters}
        onRefresh={refresh}
        onEdit={handleEdit}
        onNewBookingClick={drawerCreate?.show}
        className='d-none d-md-block'
        data={bookings}
        onFilter={handleFilter}
        event={event}
        customer={customer}
        initialFilters={initialFilters}
      />

      <BookingPortalFormCreate
        customer={customer}
        event={event}
        drawerRef={setDrawerCreate}
        onChange={refresh}
      />

      <AddDelegateModal
        open={isSelectionOpen}
        disableSubmit={formik.isSubmitting}
        onSubmit={formik.handleSubmit}
        onHide={hideSelectionModal}
        formik={formik}
      />
    </>
  )
}

const EMPTY_INITIAL_VALUES: AddDelegateValues = {
  name: '',
  email: '',
  designation: '',
}
