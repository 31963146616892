import {CUSTOMER_TICKET_MAX_SHARE} from '../../../../../../config/env'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {isTicketCancel} from '../../../hooks/useTicketTableActions'

export interface TicketTableSelectionActionsProps {
  items: TicketPortalModel[]
  onCancel?: (data: TicketPortalModel[]) => void
  selected: TableRowId[]
  disabled?: boolean
}

export const TicketTableSelectionActions = ({
  items,
  onCancel,
  selected,
  disabled,
}: TicketTableSelectionActionsProps) => {
  return (
    <>
      <TableSelectionAction
        idExtractor={idExtractor}
        onClick={onCancel}
        selected={selected}
        items={items}
        variant='primary'
        filter={isTicketCancel}
        limitCount={CUSTOMER_TICKET_MAX_SHARE}
        disabled={disabled}
      >
        Cancel Tickets
      </TableSelectionAction>
    </>
  )
}
