import clsx from 'clsx'
import {DragEvent, useCallback, useMemo} from 'react'
import {MetronicIcon} from '../../MetronicIcon'
import {BaseFileInputValue} from '../BaseFileInputValue'
import {FileInputValueCollection} from '../FileInputValueCollection'
import {DragDropFileInputDragAreaThumbnail} from './DragDropFileInputDropAreaThumbnail'

export interface DragDropFileInputDropAreaProps<T extends BaseFileInputValue> {
  id: string
  placeholderText: string
  placeholderArabicText?: string
  value: FileInputValueCollection<T>
  fileFactory: (file: File) => T
  onChange: (file: FileInputValueCollection<T>) => void
  onClickImageInside?: () => void
  limit?: number
  className?: string
  thumbnailClassName?: string
  icon?: {
    iconName: string
    iconType: string
  }
  iconClassname?: string
  disabled?: boolean
  placeholderClassName?: string
}

export const DragDropFileInputDropArea = <T extends BaseFileInputValue>({
  id,
  placeholderText,
  placeholderArabicText: placeholderSecondText,
  value,
  fileFactory,
  onChange,
  limit = 1,
  className,
  thumbnailClassName,
  iconClassname,
  disabled,
  onClickImageInside,
  placeholderClassName,
  icon,
}: DragDropFileInputDropAreaProps<T>) => {
  const handleDrop = useCallback(
    (e: DragEvent<HTMLLabelElement>) => {
      e.stopPropagation()
      e.preventDefault()
      const fileList = e.dataTransfer?.files
      if (fileList) {
        const newValue = value.clone()
        newValue.addFileList(fileList, fileFactory, limit)
        onChange(newValue)
      }
    },
    [value, fileFactory, onChange, limit]
  )

  const handleDragOver = useCallback((e: DragEvent<HTMLLabelElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const handleRemove = useCallback(
    (file: BaseFileInputValue) => {
      const newValue = value.clone()
      newValue.remove(file.id)
      onChange && onChange(newValue)
    },
    [onChange, value]
  )

  const thumbnails = useMemo(() => {
    if (!value.hasValue()) {
      return (
        <div className={clsx('text-center', thumbnailClassName)}>
          <div>
            <MetronicIcon
              classes={{
                svg: iconClassname,
              }}
              size='xl'
              iconType={icon ? icon.iconType : 'Files'}
              iconName={icon ? icon.iconName : 'Uploaded-file'}
            />
          </div>
          <div>
            <p className={clsx('text-start mb-0 fw-bolder fs-3', placeholderClassName)}>
              {placeholderText}
            </p>
            {placeholderSecondText && (
              <p className={clsx('text-start mb-0 fw-bolder fs-3', placeholderClassName)}>
                {placeholderSecondText}
              </p>
            )}
          </div>
        </div>
      )
    }
    return value
      .getFileArray()
      .map((file) => (
        <DragDropFileInputDragAreaThumbnail
          disabled={disabled}
          onClose={handleRemove}
          key={file.id}
          file={file}
          onClick={onClickImageInside}
        />
      ))
  }, [
    value,
    thumbnailClassName,
    iconClassname,
    icon,
    placeholderClassName,
    placeholderText,
    placeholderSecondText,
    disabled,
    handleRemove,
    onClickImageInside,
  ])

  return (
    <label
      role='button'
      htmlFor={id}
      className={clsx(
        'drag-drop-file-input-drop-area',
        {
          'drag-drop-file-input-drop-area--empty': !value.hasValue(),
          'drag-drop-file-input-drop-area--has-value': value.hasValue(),
        },
        className
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {thumbnails}
    </label>
  )
}
