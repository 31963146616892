import currency from 'currency.js'
import {VAT} from '../../config/env'
import {OrderModel} from '../models/fnb/OrderModel'
import {PriceTag} from './PriceTag'
import {VatExclusivePriceTag} from './VatExclusivePriceTag'
import {VatInclusivePriceTag} from './VatInclusivePriceTag'

export interface CartAttributes {
  count: number
  price: PriceTag
  label: string
}

export interface CartObject {
  count: number
  rawPrice: number
  vatInclusive: boolean
  label: string
  vat: number
}

export class Cart {
  public constructor(values?: Record<string, CartAttributes | CartObject>) {
    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        if ('price' in value) {
          this.addItem(key, value.label, value.price, value.count)
        } else {
          if (value.vatInclusive) {
            const price = new VatInclusivePriceTag(value.rawPrice, 'AED', value.vat)
            this.addItem(key, value.label, price, value.count)
          } else {
            const price = new VatExclusivePriceTag(value.rawPrice, 'AED', value.vat)
            this.addItem(key, value.label, price, value.count)
          }
        }
      })
    }
  }

  public static fromOrderModel = (order: OrderModel) => {
    const cart = new Cart()
    order.orderItems?.forEach((order) => {
      if (order.product) {
        const price = new VatExclusivePriceTag(order.product.price, 'AED', VAT)
        cart.addItem(order.code, order.product.name, price, order.qty)
      }
    })
    return cart
  }

  private values = new Map<string, CartAttributes>()

  public addItem = (key: string, label: string, price: PriceTag, count: number = 1) => {
    this.values.set(key, {label, price, count})
  }

  public removeItem = (key: string) => {
    this.values.delete(key)
  }

  public getItem = (key: string) => {
    return this.values.get(key)
  }

  public addItemIfNotExist = (key: string, label: string, price: PriceTag, count?: number) => {
    if (!this.hasItem(key)) {
      this.addItem(key, label, price, count)
    }
  }

  public incrementCount = (key: string) => {
    const item = this.values.get(key)

    if (item) {
      item.count++
    }
  }

  public decrementCount = (key: string) => {
    const item = this.values.get(key)

    if (item && item.count > 0) {
      item.count--
    }
  }

  public setCount = (key: string, count: number) => {
    const item = this.values.get(key)

    if (item) {
      item.count = count
    }
  }

  public hasItem = (key: string) => {
    return this.values.has(key)
  }

  public forEach = (callback: (key: string, value: CartAttributes) => void) => {
    return this.values.forEach((value, key) => {
      callback(key, value)
    })
  }

  public getItemCount = () => {
    let count = 0

    this.values.forEach((item) => {
      count += item.count
    })

    return count
  }

  public getTotalVatInclusive = () => {
    let total = currency(0)
    this.values.forEach((item) => {
      total = total.add(item.price.getVatInclusivePrice(item.count))
    })
    return currency(total).value
  }

  public getSubTotalVatInclusive = () => {
    let total = currency(0)
    this.values.forEach((item) => {
      total = currency(total).add(item.price.getVatExclusivePrice(item.count))
    })
    return total.value
  }

  public getVatPrice = () => {
    let total = currency(0)
    this.values.forEach((item) => {
      total = currency(total).add(item.price.getVatPrice()).multiply(item.count)
    })
    return total.value
  }

  public toObject = (): Record<string, CartObject> => {
    const object: Record<string, CartObject> = {}
    this.values.forEach((value, key) => {
      object[key] = {
        vatInclusive: value.price instanceof VatInclusivePriceTag,
        rawPrice: value.price.getRawPrice(),
        vat: value.price.getVatPercent(),
        count: value.count,
        label: value.label,
      }
    })
    return object
  }

  public clone = () => {
    return new Cart(this.toObject())
  }
}
