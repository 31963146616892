import {useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {CustomerModel} from '../../../../models/CustomerModel'
import {roleFormValidationSchema, CustomerFormValues} from './CustomerFields'
import {
  getPortalCustomerPayload,
  initialValues,
  useFormDrawerCustomerData,
} from '../../hooks/useFormDrawerCustomerData'
import { PutPortalCustomer} from '../../redux/CustomerPortalCRUD'
import * as RxCustPort from '../../redux/CustomerPortalRedux'
import {CustomerFields} from './CustomerFields'
import {Button} from '../../../../components/inputs/Button'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {Spinner} from '../../../../components/utils/Spinner'
import {useHistory} from 'react-router-dom'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
interface CustomerFormPageEditProps {
  customerToEdit?: CustomerModel
}

export const CustomerFormPageEdit = ({customerToEdit}: CustomerFormPageEditProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const {pushError} = useAlerts()
  const formDrawer = useFormDrawerCustomerData()
  const updateCustomer = useCallback(
    async (values: CustomerFormValues) => {
      // const payload = getCustomerPayload({values, editing: true})
      const payload = getPortalCustomerPayload({values})
      if (customerToEdit) {
        try {
          const {data} = await PutPortalCustomer(payload, customerToEdit.code)
          if (data) {
            dispatch(RxCustPort.actions.customers.updateSuccess())
            history.replace('/customer')
          }
        } catch (e) {
          dispatch(RxCustPort.actions.customers.updateFailed())
          throw e
        }
      }
    },
    [customerToEdit, dispatch, history]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true)
      try {
        await updateCustomer(values)
      } catch (e: any) {
        pushError(e)
      } finally {
        setIsLoading(false)
        setSubmitting(false)
      }
    },
  })

  useOnChange(customerToEdit, () => {
    if (customerToEdit) {
      const values = formDrawer.mapCustomerDataToFormValues(customerToEdit)
      formik.setValues(values)
    }
  })
  return (
    <div className='position-relative rs-table-bg'>
      <div className='table-cut-corner-polygon rs-gradient'></div>
      <form onSubmit={formik.handleSubmit} className='p-10 rs-top position-relative'>
        <div className='d-flex gap-2 align-items-center position-relative rs-stepper mb-10'>
          <div className='bg-primary p-2'>
            <MetronicIcon iconName='User' iconType='General' size='md' className='svg-icon' />
          </div>
          <h4 className='text-white'>Edit Customer</h4>
        </div>
        <CustomerFields countries={formDrawer.countries} formik={formik} editing />
        <div className='container-fluid px-0 '>
          <div className='mt-5 d-flex justify-content-end'>
            <Button
              className='btn btn-ligh-primary align-items-center position-relative'
              variant='text'
              type='button'
              onClick={() => history.replace('/customer')}
            >
              Cancel
            </Button>

            <Button
              className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-auto'
              variant='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              type='submit'
            >
              <Spinner hide={!isLoading} /> Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
