import {useCallback, useMemo} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useQueryParams} from '../../../components/hooks/useQueryParams'
import {OutletOrderType} from '../../../models/fnb/OutletModel'
import {FnbQrCodeGenerator} from '../../../utils/Qr/FnbQrGenerator'

interface LocationOrTicketCode {
  locationCode?: string
  ticketCode?: string
}

interface OutletMenuParams extends LocationOrTicketCode {
  orderType?: string
}

interface PathParams {
  locationCode?: string
  outletCode?: string
  ticketCode?: string
  orderCode?: string
}

export interface CheckoutPageState {
  outletCode?: string
  locationCode?: string
  ticketCode?: string
  orderType?: string
}

export const usePageNavigation = () => {
  const history = useHistory()
  const pathParams = useParams<PathParams>()
  const queryParams = useQueryParams()

  const getBaseOutletUrl = useCallback(
    ({
      locationCode = pathParams.locationCode,
      ticketCode = pathParams.ticketCode,
    }: LocationOrTicketCode = {}) => {
      if (locationCode) {
        return `/locations/${locationCode}`
      } else if (ticketCode) {
        return `/tickets/${ticketCode}`
      }
    },
    [pathParams]
  )

  const getQueryParamsOrderType = useCallback(() => {
    const orderType = queryParams.get('orderType')
    switch (orderType) {
      case 'delivery':
      case 'dine-in':
      case 'pickup':
      case 'takeaway':
        return orderType
      default:
        return 'delivery'
    }
  }, [queryParams])

  const getOrderType = useCallback(
    (orderType?: string) => {
      const finalOrderType = orderType || getQueryParamsOrderType()
      return finalOrderType || 'delivery'
    },
    [getQueryParamsOrderType]
  )

  const goToOutletMenu = useCallback(
    (outletCode: string, params: OutletMenuParams = {}) => {
      const url = `${getBaseOutletUrl(params)}/outlets/${outletCode}?orderType=${getOrderType(
        params.orderType
      )}`
      history.push(url)
    },
    [getBaseOutletUrl, getOrderType, history]
  )

  const goToLocationSelect = useCallback(
    (tab?: string) => {
      let url = `/location-select`
      if (tab) {
        url += `?tab=${tab}`
      }
      history.push(url)
    },
    [history]
  )

  const goToOutlets = useCallback(
    (params?: {locationCode?: string; ticketCode?: string; tab?: OutletOrderType | 'orders'}) => {
      let url = `${getBaseOutletUrl(params)}/outlets`
      if (params?.tab) {
        url += `?tab=${params?.tab}`
      }
      history.push(url)
    },
    [getBaseOutletUrl, history]
  )

  const goToCheckout = useCallback(() => {
    history.push(
      `${getBaseOutletUrl()}/outlets/${pathParams.outletCode}/basket?orderType=${getOrderType()}`
    )
  }, [getBaseOutletUrl, getOrderType, history, pathParams.outletCode])

  const goToOrderByCode = useCallback(
    (orderCode: string, data?: CheckoutPageState) => {
      history.push(`/orders/${orderCode}`, data)
    },
    [history]
  )

  const goToAllOrderList = useCallback(() => {
    history.push(`/orders`)
  }, [history])

  const goToMainMenu = useCallback(() => {
    history.push(`/`)
  }, [history])

  const goBack = useMemo(() => {
    if (history.action !== 'POP') {
      return () => {
        history.goBack()
      }
    }
  }, [history])

  const goToOutletTableReservation = useCallback(
    (outletCode: string, params: LocationOrTicketCode = {}) => {
      history.push(`${getBaseOutletUrl(params)}/outlets/${outletCode}/reservation`)
    },
    [getBaseOutletUrl, history]
  )

  const goToDraftedOrderCheckoutPage = useCallback(
    (orderCode: string | undefined = pathParams.orderCode, data?: CheckoutPageState) => {
      if (!orderCode) {
        throw new Error('Please specify order code.')
      }
      history.push(`/orders/${orderCode}/checkout`, data)
    },
    [history, pathParams.orderCode]
  )

  const goToFnbQrCodeData = useCallback(
    (qrCode: FnbQrCodeGenerator) => {
      if (qrCode.data.outletCode) {
        goToOutletMenu(qrCode.data.outletCode, {
          locationCode: qrCode.data.locationCode,
          orderType: qrCode.data.tableNumber ? 'dine-in' : 'delivery',
        })
      } else {
        goToOutlets({
          locationCode: qrCode.data.locationCode,
        })
      }
    },
    [goToOutletMenu, goToOutlets]
  )

  return useMemo(
    () => ({
      goToFnbQrCodeData,
      goToOutlets,
      goToLocationSelect,
      goToOutletMenu,
      goToCheckout,
      goToOrderByCode,
      goToAllOrderList,
      goToMainMenu,
      goBack,
      goToOutletTableReservation,
      getQueryParamsOrderType,
      goToDraftedOrderCheckoutPage,
    }),
    [
      goToFnbQrCodeData,
      goToOutlets,
      goToLocationSelect,
      goToOutletMenu,
      goToCheckout,
      goToOrderByCode,
      goToAllOrderList,
      goToMainMenu,
      goBack,
      goToOutletTableReservation,
      getQueryParamsOrderType,
      goToDraftedOrderCheckoutPage,
    ]
  )
}
