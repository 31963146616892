import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {ProductCategoryModel} from '../../../models/ems/ProductCategoryModel'
import {FilterModel} from '../../../models/FilterModel'

import {GetCustomerProductCatergory} from './DigitalWaiverCRUD'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IOutletState {
  topTabs?: ProductCategoryModel[]
}

const initialAuthState: IOutletState = {}

// ACTIONS TYPES
export const actionTypes = {
  topTabs: {
    execute: '[Digital Waiver] TOPTABS EXECUTE',
    success: '[Digital Waiver] TOPTABS SUCCESS',
    failed: '[Digital Waiver] TOPTABS FAILED',
  },
}

export const reducer = persistReducer(
  {storage, key: 'tabs', whitelist: []},
  (state: IOutletState = initialAuthState, action: ActionWithPayload<IOutletState>) => {
    switch (action.type) {
      case actionTypes.topTabs.success: {
        const topTabs = action.payload?.topTabs
        return {
          ...state,
          topTabs,
        }
      }

      default:
        return state
    }
  }
)

// AFTER EFFECT
export function* saga() {
  yield takeLatest([actionTypes.topTabs.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['digital-waiver-product-catergories-tabs']
      )
      const {data} = yield GetCustomerProductCatergory({...filter})
      yield put(actions.topTabs.searchSuccess(data))
    } catch (e) {
      yield put(actions.topTabs.searchFailed())
    }
  })
}

// ACTIONS
export const actions = {
  topTabs: {
    search: () => ({type: actionTypes.topTabs.execute}),
    searchSuccess: (data: ProductCategoryModel[]) => ({
      type: actionTypes.topTabs.success,
      payload: {topTabs: data},
    }),
    searchFailed: () => ({type: actionTypes.topTabs.failed}),
  },
}
