import {Badge} from '../../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../../components/inputs/Button'
import {ReservationModelStatus} from '../../../../../../models/fnb/ReservationModel'

export interface ReservationStatusColumnProps {
  data: {
    status: ReservationModelStatus
  }
}

export const ReservationStatusColumn = ({data}: ReservationStatusColumnProps) => {
  return (
    <Badge className='text-uppercase' variant={getBadge(data.status)}>
      {data.status}
    </Badge>
  )
}

const getBadge = (status: ReservationModelStatus): ColorVariant => {
  switch (status) {
    case 'cancelled':
      return 'danger'
    case 'pending':
      return 'dark'
    case 'pre-approved':
      return 'primary'
    default:
      return 'dark'
  }
}
