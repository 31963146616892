import {useCallback} from 'react'
import {useId} from '../../hooks/useId'
import {SelectInputItem} from '../SelectInput'

export interface MultiSelectInputItemProps extends SelectInputItem {
  onClick: (value: string) => void
  className?: string
  checked?: boolean
}

export const MultiSelectInputItem = ({
  label,
  value,
  onClick,
  className,
  checked,
}: MultiSelectInputItemProps) => {
  const id = useId()

  const handleChange = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return (
    <li className={className}>
      <div className='form-check'>
        <input
          checked={checked}
          id={id}
          className='form-check-input'
          type='checkbox'
          onChange={handleChange}
        />
        <label htmlFor={id} className='form-check-label'>
          {label}
        </label>
      </div>
    </li>
  )
}
