import {AxiosError} from 'axios'
import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {ApiFormValidationError} from '../../models/ApiErrorModel'
import {actions} from '../../modules/default/system/redux/SystemRedux'
import {BlobUtils} from '../../utils/BlobUtils'
import {AlertCreateOptions, AlertData} from './Alert'
import {ApiValidationException} from './ApiValidationException'

export const useAlerts = () => {
  const alertState = useSelector<RootState, AlertData[]>((state) => state.system.alerts)
  const dispatch = useDispatch()

  const push = useCallback(
    (options: AlertCreateOptions) => {
      const action = actions.pushAlert(options)
      dispatch(action)
      return action.payload?.key as string
    },
    [dispatch]
  )

  const getValidationErrorMessage = useCallback((error: ApiFormValidationError): string => {
    const formError = new ApiValidationException(error)
    return formError.toMarkdown()
  }, [])

  const getMessageFromGenericError = useCallback(
    (error: Partial<ApiFormValidationError>) => {
      if (error.data) {
        return getValidationErrorMessage(error as ApiFormValidationError)
      } else if (error.error) {
        return error.error
      }
    },
    [getValidationErrorMessage]
  )

  const getErrorMessage = useCallback(
    async (e: Error | AxiosError<ApiFormValidationError | Blob>) => {
      let message = e.message
      if ('response' in e) {
        if (e.response?.data instanceof Blob) {
          try {
            const data = await BlobUtils.blobToJson<ApiFormValidationError>(e.response.data)
            const message = getMessageFromGenericError(data)
            if (message) {
              return message
            }
          } catch (e) {
            console.error(e)
          }
        } else if (e.response) {
          const message = getMessageFromGenericError(e.response.data)
          if (message) {
            return message
          }
        }
      }
      return message
    },
    [getMessageFromGenericError]
  )

  const pushError = useCallback(
    async (e: Error | AxiosError<ApiFormValidationError | Blob>) => {
      const message = await getErrorMessage(e)
      push({
        message,
        timeout: 5000,
        variant: 'danger',
      })
    },
    [getErrorMessage, push]
  )

  const close = useCallback(
    (key: string) => {
      dispatch(actions.removeAlert(key))
    },
    [dispatch]
  )

  const alerts = useMemo((): AlertData[] => {
    return alertState
  }, [alertState])

  const clear = useCallback(() => {
    alerts.forEach((alert) => {
      close(alert.key)
    })
  }, [alerts, close])

  return {
    push,
    alerts,
    close,
    pushError,
    clear,
    getErrorMessage,
  }
}
