import {Route, Switch} from 'react-router-dom'
import {OutletAuthPage} from '../../modules/outlet/pages/manager/OutletAuthPage'

export const PublicRoutes = () => {
  return (
    <Switch>
      <Route path='/auth' component={OutletAuthPage} />
    </Switch>
  )
}
