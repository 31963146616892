import {useMemo} from 'react'
import {PageTitle, PageTitleProps} from '../../../_metronic/layout/core'

export interface AsyncPageTitleProps extends PageTitleProps {
  loading?: boolean
  children?: string
}

export const AsyncPageTitle = ({loading, children, ...props}: AsyncPageTitleProps) => {
  const title = useMemo(() => {
    if (loading) {
      return 'Loading...'
    }
    return children
  }, [children, loading])

  return <PageTitle {...props}>{title}</PageTitle>
}
