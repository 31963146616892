import {ReactNode} from 'react'
import {OutletModel} from '../../models/fnb/OutletModel'
import {useAuthRole} from '../hooks/useAuthRole'
import {Link} from './Link'

export interface OutletLinkProps {
  outlet?: OutletModel
  className?: string
  children: ReactNode
}

/**
 * @deprecated Outlets are now inside event/activity
 * */
export const OutletLink = ({className, outlet, children}: OutletLinkProps) => {
  const auth = useAuthRole()

  if (!auth?.canReadFeature('FNBOUTLETS')) {
    return <>{children}</>
  }

  if (outlet) {
    return (
      <Link className={className} to={`/fnb/outlet/${outlet.code}/overview`}>
        {children}
      </Link>
    )
  }
  return null
}
