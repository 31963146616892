import {useCallback, useMemo} from 'react'
import {useRootStateSelector} from './useRootStateSelector'

export const useOutletAuth = () => {
  const user = useRootStateSelector((state) => state.outlet?.auth?.user)

  const getUser = useCallback(() => {
    if (!user) {
      throw new Error('User is not logged in')
    }
    return user
  }, [user])

  const getUserType = useCallback(() => {
    return getUser().type
  }, [getUser])

  const isFirstLogIn = useCallback(() => {
    const user = getUser()
    return user.isFirstLogin
  }, [getUser])

  const data = useMemo(() => {
    if (user) {
      return {isFirstLogIn, getUser, getUserType}
    }
  }, [getUser, isFirstLogIn, user, getUserType])

  return data
}
