import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {HasCode} from '../../../utils/idExtractor'
import {Plural} from '../../../utils/Plural'
import {SwalUtils} from '../../../utils/SwalUtils'
import {CancelBooking} from '../redux/CustomerDelegateCRUD'

export interface UseBookingDataUtilsOptions {
  onRefresh?: () => void
  onRefreshCallback?: () => void
}

export const useBookingDataUtils = ({
  onRefresh,
  onRefreshCallback,
}: UseBookingDataUtilsOptions = {}) => {
  const {pushError, push} = useAlerts()
  const {setIsLoading, isKeyLoading, isLoading} = useLoadingState()

  const cancel = useCallback(
    async (codes: string[]) => {
      await SwalUtils.asyncSpecifyReason(
        async (reason) => {
          const doneLoading = setIsLoading(codes)
          try {
            await CancelBooking(codes, reason)
            push({
              message: `${pluralizeBooking(codes)} successfully cancelled.`,
              timeout: 5000,
              variant: 'success',
            })
            onRefresh && onRefresh()
            onRefreshCallback && onRefreshCallback()
          } catch (e) {
            pushError(e)
          } finally {
            doneLoading()
          }
        },
        {
          titleText: 'Cancel Booking',
          text: 'Please specify a reason.',
          confirmButtonText: 'Cancel!',
          icon: 'question',
          cancelButtonText: "Don't Cancel!",
        }
      )
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleCancelBulk = useCallback(
    (data: HasCode[]) => {
      cancel(data.map((bookings) => bookings.code))
    },
    [cancel]
  )

  const handleCancelSingle = useCallback(
    (data: HasCode) => {
      cancel([data.code])
    },
    [cancel]
  )

  const isBookingLoading = useCallback(
    (booking: HasCode) => {
      return isKeyLoading(booking.code)
    },
    [isKeyLoading]
  )

  return useMemo(
    () => ({
      cancelSingle: handleCancelSingle,
      cancelBulk: handleCancelBulk,
      isBookingLoading,
      isLoading,
    }),
    [handleCancelSingle, handleCancelBulk, isBookingLoading, isLoading]
  )
}

const pluralizeBooking = (array: unknown[]) => {
  return Plural.pluralize(array.length, 'Booking', 'Bookings')
}
