import clsx from 'clsx'
import moment from 'moment'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useBreakpoint} from '../../hooks/useBreakpoint'
import {chunk} from 'lodash'

import {Button} from '../Button'
import {KTSVG} from '../../../../_metronic/helpers'
export interface WeekdayTabsInputWithPaginationProps {
  onChange: (value: Date) => void
  value: Date | null
  min: Date
  max: Date
}

export const WeekdayTabsInputWithPagination = ({
  onChange,
  value,
  min,
  max,
}: WeekdayTabsInputWithPaginationProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => down('sm'), [down])
  const [selectedChunk, setSelectedChunk] = useState<number>(0)
  const [chunks, setChunks] = useState<Date[][]>([[]])

  useEffect(() => {
    const daysBetween = getDaysBetweenDates(min, max)
    setChunks(chunk(daysBetween, 7))
  }, [max, min])

  const tabs = useMemo(() => {
    const tabs: Date[] = []
    if (chunks && chunks?.length > 0) {
      for (let i = 0; i < chunks[selectedChunk].length; i++) {
        tabs.push(chunks[selectedChunk][i])
      }
    }

    return tabs
  }, [chunks, selectedChunk])

  const handleDecrease = useCallback(() => {
    if (selectedChunk > 0) {
      if (chunks && selectedChunk && value) {
        if (!chunks[selectedChunk].includes(value)) {
          onChange(chunks[selectedChunk - 1][0])
        }
      }
      setSelectedChunk(selectedChunk - 1)
    }
  }, [chunks, onChange, selectedChunk, value])

  const handleIncrease = useCallback(() => {
    if (selectedChunk < chunks[selectedChunk].length && value) {
      if (!chunks[selectedChunk].includes(value)) {
        onChange(chunks[selectedChunk + 1][0])
      }
      setSelectedChunk(selectedChunk + 1)
    }
  }, [chunks, onChange, selectedChunk, value])

  return (
    <div className='d-flex'>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{
          width: isMobile ? '30px' : '50px',
        }}
      >
        <Button
          className='bg-transparent p-0'
          onClick={handleDecrease}
          disabled={selectedChunk === 0}
        >
          {selectedChunk !== 0 && (
            <KTSVG
              path={'/media/icons/duotone/Navigation/Angle-left.svg'}
              className={clsx(
                isMobile ? 'svg-icon-3x' : 'svg-icon-5x',
                selectedChunk === 0 ? 'svg-icon-muted' : 'svg-icon-primary'
              )}
            />
          )}
        </Button>
      </div>
      <div
        style={{
          width: 'calc(100% - 105px)',
        }}
      >
        <div
          style={{
            overflow: 'auto',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        >
          {tabs.map((tab, index, array) => {
            const isFirst = index === 0
            const isLast = index === array.length - 1
            const isActive = value ? moment(tab).format('LL') === moment(value).format('LL') : false
            return (
              <div
                key={tab.toDateString()}
                style={{
                  width: isMobile ? '100px' : '170px',
                  display: 'inline-block',
                }}
              >
                <button
                  className={clsx(
                    'btn',
                    'w-100',
                    'rounded-0',
                    'border-top',
                    'border-bottom',
                    'border-primary',
                    'border-end',
                    'text-uppercase',
                    'fs-2',
                    'px-5',
                    {
                      'rounded-start': isFirst,
                      'rounded-end': isLast,
                      'border-start': isFirst,
                      'btn-primary': isActive,
                    }
                  )}
                  onClick={() => onChange(tab)}
                >
                  <div>{moment(tab).format(isMobile ? 'ddd' : 'dddd')}</div>
                  <div>{`${moment(tab).format('DD')}  ${moment(tab).format('MMM')}`}</div>
                </button>
              </div>
            )
          })}
        </div>
      </div>
      <div
        className='ms-2 d-flex justify-content-center align-items-center'
        style={{
          width: isMobile ? '30px' : '50px',
        }}
      >
        <Button
          className='bg-transparent p-0'
          onClick={handleIncrease}
          disabled={selectedChunk === chunks.length - 1}
        >
          {selectedChunk !== chunks.length - 1 && (
            <KTSVG
              path={'/media/icons/duotone/Navigation/Angle-right.svg'}
              className={clsx(
                isMobile ? 'svg-icon-3x' : 'svg-icon-5x',
                selectedChunk === chunks.length - 1 ? 'svg-icon-muted' : 'svg-icon-primary'
              )}
            />
          )}
        </Button>
      </div>
    </div>
  )
}

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const now = moment(startDate).clone()
  let dates = []
  while (now.isSameOrBefore(moment(endDate))) {
    dates.push(now.toDate())
    now.add(1, 'days')
  }
  return dates
}
