import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {FormikContextType, useFormik} from 'formik'
import * as _redux from '../redux/CustomerPortalRedux'
import {AuthLogin, validateHash} from '../redux/CustomerPortalCRUD'
import {AxiosError} from 'axios'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {DigitInput} from '../../../components/inputs/DigitInput'
import {QrCode} from '../../../components/utils/QrCode'
import {Button} from '../../../components/inputs/Button'
import {LoginFields} from './forms/LoginFields'
import {useFormikLogin} from '../hooks/useFormikLogin'
import {LoginHeader} from './LoginHeader'
import {PasswordField} from './forms/PasswordField'
import { useAuthState } from './forms/AuthStateContext'

export interface FormikProps {
  username: string
  password: string
  otpValue: string
  accountCode: string
  userType: string
}

const initialValues: FormikProps = {
  username: '',
  password: '',
  otpValue: '',
  accountCode: '',
  userType: '001',
}

export interface LoginProps {
  setOTPState?: (isOTP: boolean) => void
}

export function Login({setOTPState}: LoginProps) {
  const otpInputRef = useRef<HTMLInputElement | null>(null)
  const [mfaLink, setMfaLink] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [hash, setHash] = useState<string>()
  const match = useRouteMatch<{hash?: string}>()
  const history = useHistory()
  const {push} = useAlerts()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [showPasswordField, setShowPasswordField] = useState(false)
  const {setUserType, setAccountCode,} = useAuthState()

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      username: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Username is required'),
      password: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(50, 'Maximum 50 characters')
        .when('continueClicked', {
          is: true,
          then: Yup.string().required('Password is required'),
        }),
      accountCode: Yup.string().when('userType', {
        is: '002',
        then: Yup.string().required('Account code is required'),
      }),
    })
  }, [])

  const validateHashFromUrl = useCallback(
    async (hash: string) => {
      try {
        await validateHash(hash)
        push({
          message: `Please enter details.`,
          timeout: 10000,
          variant: 'success',
        })
        setHash(hash)
      } catch (e) {
        push({
          message: `Invalid url. Please contact support.`,
          timeout: 10000,
          variant: 'danger',
        })
        history.replace('/auth/login')
      }
    },
    [history, push]
  )

  useEffect(() => {
    if (match.params.hash) {
      validateHashFromUrl(match.params.hash)
    }
  }, [match.params.hash, validateHashFromUrl])

  const formikSubmit = useFormikLogin({
    setMfaLink,
    setAuthToken,
    otpInputRef,
  })

  const formik: FormikContextType<FormikProps> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, {setStatus}) => {
      if (!showPasswordField) {
        setShowPasswordField(true)
        setUserType(values.userType);
        if (values.accountCode) setAccountCode(values.accountCode)
        return
      }

      setLoading(true)
      setStatus('')
      if (values.otpValue && authToken) {
        formikSubmit.sendRequestByOTP(values.otpValue, formik, authToken)
      } else if (mfaLink) {
        setMfaLink(undefined)
        otpInputRef.current?.focus()
      } else if (formik.values.password) {
        try {
          if (hash) {
            await formikSubmit.sendRequestByHash(
              values.username,
              values.password,
              hash,
              values.accountCode ? values.accountCode : undefined
            )
          } else {
            const {data, status} = await AuthLogin(
              values.username,
              values.password,
              values.accountCode ? values.accountCode : undefined

            )
            if (status === 202) {
              setOTPState?.(true)
              setAuthToken(data.token)
              otpInputRef.current?.focus()
            } else {
              dispatch(_redux.actions.auth.login(data.token))
            }
          }
        } catch (e: any) {
          const responseError: AxiosError<unknown> = e
          if (responseError.response?.status) {
            if (responseError.response.status === 403) {
              setStatus('Please check your email to enable your 2FA authentication.')
            } else {
              setStatus('Invalid username or password.')
            }
          } else {
            setStatus('Something went wrong. Please try again later.')
          }
        }
      }
      setLoading(false)
    },
  })

  const handleOtpChange = useCallback(
    (value: string) => {
      formik.setFieldValue('otpValue', value)
    },
    [formik]
  )

  const loginFields = useMemo(() => {
    if (!authToken && !showPasswordField) {
      return <LoginFields formik={formik} />
    }
  }, [authToken, showPasswordField, formik])

  const passwordField = useMemo(() => {
    if (showPasswordField && !authToken && formik.values.username) {
      return <PasswordField formik={formik} />
    }
  }, [showPasswordField, authToken, formik])

  const otpFields = useMemo(() => {
    const isShown = Boolean(authToken && !mfaLink)
    return (
      <div className={clsx('m-5', {'d-none': !isShown})}>
        <DigitInput
          ref={otpInputRef}
          className='mt-5'
          value={formik.values.otpValue}
          onChange={handleOtpChange}
          length={DIGIT_LENGTH}
        />
      </div>
    )
  }, [authToken, formik.values.otpValue, handleOtpChange, mfaLink])

  const mfaQrCode = useMemo(() => {
    if (mfaLink) {
      return (
        <div className='d-flex justify-content-center mb-5'>
          <div className='p-3 d-inline-block bg-white'>
            <a href={mfaLink}>
              <QrCode value={mfaLink} />
            </a>
          </div>
        </div>
      )
    }
  }, [mfaLink])

  const handleBack = useCallback(() => {
    formik.resetForm()
    setOTPState?.(false)
    setMfaLink(undefined)
    setAuthToken(undefined)
    setShowPasswordField(false)
  }, [formik, setOTPState])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form card'
    >
      <LoginHeader authToken={authToken} mfaLink={mfaLink} />

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold' style={{textAlign: 'center'}}>
            {formik.status}
          </div>
        </div>
      )}
      {loginFields}
      {passwordField}
      {otpFields}
      {mfaQrCode}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={
            !(
              formik.values.username &&
              ((formik.values.userType === '001' && formik.values.username.length >= 10) ||
                (formik.values.userType === '002' &&
                  formik.values.username.length >= 10 &&
                  formik.values.accountCode))
            )
          }
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {(authToken || mfaLink || showPasswordField) && (
          <Button
            type='button'
            className='w-100'
            onClick={handleBack}
            disabled={formik.isSubmitting}
          >
            Back
          </Button>
        )}
      </div>
    </form>
  )
}

const DIGIT_LENGTH = 6
