import type {FormikContextType} from 'formik'
import {useCallback} from 'react'
import * as Yup from 'yup'
import {
  DrawerFormContainer,
  DrawerFormContainerProps,
} from '../../../../../components/Drawer/DrawerFormContainer'
import {DatePickerInput, TextAreaInput, TextInput} from '../../../../../components/inputs'
import {SelectInput, SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {
  ReservationModel,
  ReservationModelCreateParams,
  ReservationModelStatus,
} from '../../../../../models/outlet/ReservationModel'
import {DateUtil} from '../../../../../utils/DateUtil'
import {NumberInput} from '../../../../../components/inputs/NumberInput/NumberInput'
import {TimePickerInput} from '../../../../../components/inputs/TimePickerInput'
import {Time} from '../../../../../utils/Time'
import {Validators} from '../../../../../utils/Validators'
import {useFormikNumberInputHelpers} from '../../../../../components/inputs/NumberInput/useFormikNumberInputHelpers'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {useFormikMobileNumberInputHelpers} from '../../../../../components/inputs/useFormikMobileNumberInputHelpers'
import {MobileNumberParser} from '../../../../../utils/MobileNumberParser'

export interface ReservationFormValues {
  name: string
  mobile: string
  date: Date | null
  pax: number
  status: string
  time: Time
  tableNo: string
  reason: string
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  mobile: Yup.mixed().test(
    'is-valid-mobile',
    'Please enter a valid mobile number.',
    (value: string) => {
      return new MobileNumberParser(value || '').isValidMobileNumber()
    }
  ),
  date: Yup.date().required(),
  status: Yup.string().required(),
  tableNo: Yup.number().test('required', 'Table number is required.', (value, context) => {
    const parent: ReservationFormValues = context.parent
    if (parent.status === 'pre-approved') {
      return Validators.WHOLE_NUMBER_REGEX.test(String(value) || '')
    }
    return true
  }),
  reason: Yup.string().test('required', 'Cancellation reason is required.', (value, context) => {
    const parent: ReservationFormValues = context.parent
    if (parent.status === 'cancelled') {
      return Boolean(value)
    }
    return true
  }),
})

export interface ReservationFormProps extends Omit<DrawerFormContainerProps, 'isSubmitDisabled'> {
  formik: FormikContextType<ReservationFormValues>
  disabledFields?: Record<string, boolean>
}

export const ReservationForm = ({formik, disabledFields, ...formProps}: ReservationFormProps) => {
  const handleDateChange = useCallback(
    (value: Date | null) => {
      formik.setFieldValue('date', value)
    },
    [formik]
  )

  const handleTimeChange = useCallback(
    (value: Time) => {
      formik.setFieldValue('time', value)
    },
    [formik]
  )

  const paxHandler = useFormikNumberInputHelpers(formik, 'pax')

  const tableNoHandler = useFormikNumberInputHelpers(formik, 'tableNo')

  const mobileNumberChangeHandler = useFormikMobileNumberInputHelpers(formik)

  return (
    <DrawerFormContainer
      isSubmitDisabled={formik.isSubmitting || !formik.isValid}
      onSubmit={formik.handleSubmit}
      {...formProps}
    >
      <TextInput label='Name' placeholder='Enter Name' {...formik.getFieldProps('name')} />
      <MobileNumberInput
        label='Mobile Number'
        placeholder='Enter Mobile Number'
        {...mobileNumberChangeHandler.getFieldProps('mobile')}
      />
      <DatePickerInput label='Date' onChange={handleDateChange} value={formik.values.date} />
      <TimePickerInput onChange={handleTimeChange} value={formik.values.time} />
      <NumberInput label='Pax' min={1} onChange={paxHandler.onChange} value={paxHandler.value} />
      <SelectInput label='Status' items={RESERVATION_STATUS} {...formik.getFieldProps('status')} />
      {formik.values.status === 'pre-approved' && (
        <NumberInput
          label='Table No'
          hideButtons
          onChange={tableNoHandler.onChange}
          value={tableNoHandler.value}
        />
      )}
      {formik.values.status === 'cancelled' && (
        <TextAreaInput label='Reason' {...formik.getFieldProps('reason')} />
      )}
    </DrawerFormContainer>
  )
}

const RESERVATION_STATUS: SelectInputItem[] = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Pre-Approved',
    value: 'pre-approved',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
]

export const getPayload = (values: ReservationFormValues): ReservationModelCreateParams => {
  if (!values.date) {
    throw new Error('Invalid form values.')
  }
  values.time.applyToDate(values.date)
  const dateString = DateUtil.convertDateToApiString(values.date)
  return {
    date: dateString,
    isAgree: true,
    mobile: values.mobile,
    name: values.name,
    pax: values.pax,
    status: values.status as ReservationModelStatus,
    reason: values.status === 'cancelled' ? values.reason : null,
    tableNo: values.status === 'pre-approved' ? parseInt(values.tableNo) : null,
  }
}

export const mapDataToFormValues = (data: ReservationModel): ReservationFormValues => {
  const date = DateUtil.getDateFromApiString(data.date)
  return {
    date,
    mobile: data.mobile,
    name: data.name,
    pax: data.pax,
    status: data.status,
    time: Time.fromDate(date),
    reason: data.reason || '',
    tableNo: data.tableNo ? String(data.tableNo) : '',
  }
}

export const EMPTY_INITIAL_VALUES: ReservationFormValues = {
  date: new Date(),
  pax: 1,
  mobile: '',
  name: '',
  status: 'pending',
  time: Time.fromDate(new Date()),
  reason: '',
  tableNo: '',
}
