import moment from 'moment'
import {useCallback, useMemo} from 'react'
import {BarChart} from '../../../../../../components/charts/BarChart/BarChart'
import {GateCounterPerProductWidgetModel} from '../../../../../../models/ems/WidgetModel'

export interface OasisCounterBarChartProps {
  datum: GateCounterPerProductWidgetModel[]
}

export const OasisCounterBarChart = ({datum}: OasisCounterBarChartProps) => {
  const chartData = useMemo((): ChartData[] => {
    return datum.reduce<ChartData[]>((acc, item) => {
      item.graphItems
        .filter((d) => d.total > 0)
        .forEach((graphItem) => {
          if (graphItem.total) {
            acc.push({
              date: moment(item.date).toDate(),
              name: graphItem.productCategoryOasisName,
              total: graphItem.total,
            })
          }
        })

      return acc
    }, [])
  }, [datum])

  const renderTooltip = useCallback(
    (key: string) => {
      const chartItem = chartData.find((item) => getName(item) === key)
      if (chartItem) {
        return (
          <div className='bg-light-primary p-3 border border-primary rounded text-white'>
            <div>
              {chartItem.name}: {chartItem.total}
            </div>
          </div>
        )
      }
    },
    [chartData]
  )

  return (
    <div
      className='h-100 w-100 ps-10'
      style={{
        paddingBottom: '100px',
      }}
    >
      <div className='mb-5 text-white fs-1 fw-bolder text-uppercase d-flex justify-content-center'>
        {datum[0]?.oasisViewName}
      </div>
      <BarChart
        data={chartData}
        xAccessor={getName}
        yAccessor={getCount}
        renderTooltip={renderTooltip}
      />
    </div>
  )
}

const getName = (datum: ChartData) => datum.name
const getCount = (datum: ChartData) => datum.total

interface ChartData {
  date: Date
  name: string
  total: number
}
