import clsx from 'clsx'
import {useMemo} from 'react'

type HTMLTextAreaInputProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>

export interface TextAreaInputProps extends HTMLTextAreaInputProps {
  label: string
  errorMessage?: string
  isTouched?: boolean
  noMargin?: boolean
}

export const TextAreaInput = ({
  className,
  label,
  isTouched,
  errorMessage,
  noMargin,
  ...inputProps
}: TextAreaInputProps) => {
  const validationClassName = useMemo(() => {
    if (!isTouched) {
      return null
    }
    return errorMessage ? 'is-invalid' : 'is-valid'
  }, [isTouched, errorMessage])

  return (
    <div className={clsx({'mb-5': !noMargin})}>
      <label className='form-label'>{label}</label>
      <textarea
        className={clsx('form-control form-control-solid min-h-100px', validationClassName)}
        {...inputProps}
      />
      {isTouched && errorMessage && <p className='text-danger'>{errorMessage}</p>}
    </div>
  )
}
