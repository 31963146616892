import { useCallback, useEffect, useMemo, useState } from "react"
import { TableActionModalProps } from "../../../../../components/hooks/useTableActionModal"
import { DigitInput } from "../../../../../components/inputs/DigitInput"
import { useOnChange } from "../../../../../components/hooks/useOnChange"
import { SweetAlert } from "../../../../../components/modals/SweetAlert"
import { HorizontalDivider } from "../../../../../components/utils/HorizontalDivider"
import { Button } from "../../../../../components/inputs/Button"

export interface SetSmartPinModalProps extends TableActionModalProps {
    onSubmit: (smartPin: string) => void
    isAdmmPortal?: boolean
  }
  
  export const SetSmartPinModal = ({isOpen, onClose, onSubmit, isAdmmPortal}: SetSmartPinModalProps) => {
    const [value, setValue] = useState<string>('')
    const [otpInputRef, setOtpInputRef] = useState<HTMLInputElement | null>(null)
    const handleSubmit = useCallback(() => {
      if (value) {
        onSubmit(value)
      }
    }, [value, onSubmit])
  
    const handleOtpChange = useCallback((value: string) => {
      setValue(value)
    }, [])
  
    const otpFields = useMemo(() => {
      return (
        <div className='mb-5'>
          <DigitInput
            ref={setOtpInputRef}
            className='mt-5'
            value={value !== undefined || value !== null ? value : ''}
            onChange={handleOtpChange}
            length={OTP_LENGTH}
          />
        </div>
      )
    }, [handleOtpChange, value])
  
    useOnChange(isOpen, () => {
      if (!isOpen) {
        setValue('')
      }
    })
  
    useEffect(() => {
      if (isOpen) {
        otpInputRef?.focus()
      }
    }, [isOpen, otpInputRef, value])
  
    const isValid = useMemo(() => {
      return Boolean(value)
    }, [value])
  
    return (
      <SweetAlert
        open={isOpen}
        onClose={onClose}
        showConfirmButton={false}
        customClass={{htmlContainer: 'overflow-visible'}}
      >
        {isAdmmPortal && <div className="swal2-corners"></div>}
        <div className="text-start" style={{ position: isAdmmPortal ? 'relative' : undefined, zIndex: isAdmmPortal ? 2 : 0 }}> 
          <div>
            <h3>Set Smart Pin</h3>
            <HorizontalDivider />
            {otpFields}
          </div>
          <div className='d-flex justify-content-end'>
            <Button className='me-3' onClick={onClose}>
              Back
            </Button>
            <Button disabled={!isValid} variant='primary' onClick={handleSubmit}>
              SET
            </Button>
          </div>
        </div>
      </SweetAlert>
    )
  }
  
  const OTP_LENGTH = 6