import clsx from 'clsx'
import {CUSTOMER_TICKET_MAX_SHARE} from '../../../../../../config/env'
import {ButtonSize} from '../../../../../components/inputs/Button'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {Spinner} from '../../../../../components/utils/Spinner'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {isTicketCancel, isTicketDownloadable} from '../../../hooks/useTicketTableActions'
import {CATEGORY_FEATURE_CODES, CustomerCategory} from '../../../../../utils/RBAC/CustomerCategory'
import {useRootStateSelector} from '../../../../../components/hooks/useRootStateSelector'
import {alreadySharedStatus} from './TicketTableRowActions'
import {useMemo} from 'react'

export interface TicketTableSelectionActionsProps {
  items: TicketPortalModel[]
  onCancel?: (data: TicketPortalModel[]) => void
  onDownload?: (data: TicketPortalModel[]) => void
  onShareByEmail?: (data: TicketPortalModel[]) => void
  onShareByWhatsApp?: (data: TicketPortalModel[]) => void
  onShareViaEmail?: (data: TicketPortalModel[]) => void
  selected: TableRowId[]
  isLoading?: boolean
  size?: ButtonSize
  className?: string
  disabled?: boolean
}

export const TicketTableSelectionActions = ({
  items,
  onCancel,
  onDownload,
  onShareByEmail,
  onShareByWhatsApp,
  onShareViaEmail,
  selected,
  isLoading,
  size,
  className,
}: TicketTableSelectionActionsProps) => {
  // const downloadableItems = useMemo(() => {
  //   return items.slice(0, 50)
  // }, [items])
  const auth = useRootStateSelector((state) => state.customerPortal.auth)
  const customerCategory = new CustomerCategory(auth?.user)

  const filterRecallItems = useMemo(() => {
    const filterItems = selected.filter((code) => {
      const found = items.find((item) => code === item.code)
      if (
        found &&
        found.status === 'active' &&
        !alreadySharedStatus.includes(found.displayStatus)
      ) {
        return code
      }
    })
    return filterItems
  }, [selected])

  return (
    <>
      {onCancel && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onCancel}
          selected={filterRecallItems}
          items={items}
          variant='primary'
          filter={isTicketCancel}
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Recall Tickets
        </TableSelectionAction>
      )}

      {onDownload && customerCategory.canManageShare(CATEGORY_FEATURE_CODES.PDF) && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onDownload}
          selected={selected}
          items={items}
          variant='primary'
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          filter={isTicketDownloadable}
          className={clsx('text-white', className)}
          size={size}
        >
          {isLoading && <Spinner className='me-5' size='lg' />} Download Tickets
        </TableSelectionAction>
      )}

      {onShareByEmail && customerCategory.canManageShare(CATEGORY_FEATURE_CODES.EMAIL) && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareByEmail}
          selected={selected}
          items={items}
          variant='primary'
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Share by email
        </TableSelectionAction>
      )}

      {onShareByWhatsApp && customerCategory.canManageShare(CATEGORY_FEATURE_CODES.WHATSAPP) && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareByWhatsApp}
          selected={selected}
          items={items}
          variant='primary'
          filter={isTicketDownloadable}
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Share by whatsApp
        </TableSelectionAction>
      )}

      {onShareViaEmail && customerCategory.canManageShare(CATEGORY_FEATURE_CODES.EMAIL) && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareViaEmail}
          selected={selected}
          items={items}
          variant='primary'
          filter={isTicketDownloadable}
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
        >
          Share By Email
        </TableSelectionAction>
      )}
    </>
  )
}
export const isActive = (data: TicketPortalModel[]) => {
  data.forEach((item) => {
    if (item.status === 'active' && alreadySharedStatus.includes(item.displayStatus)) {
      return true
    }
    return false
  })
}
