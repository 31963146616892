import {useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment'
import {RowAction} from '../../../../components/tables/actions/RowAction'
import {Table} from '../../../../components/tables/Table'
import {TableRowId} from '../../../../components/tables/TableRow'
import {OrderModel, OrderModelStatus} from '../../../../models/fnb/OrderModel'
import {DateUtil} from '../../../../utils/DateUtil'
import {idExtractor} from '../../../../utils/idExtractor'
import {useOrderClock} from '../../hooks/useOrderTimer'
import {FohOrderListTableRowExpansion} from './FohOrderListTableRowExpansion'
import {TableColumnOptions} from '../../../../components/tables/TableColumn'

export interface FohOrderListTableProps {
  data?: OrderModel[]
}

export const FohOrderListTable = ({data}: FohOrderListTableProps) => {
  const tableData = useMemo(() => data || [], [data])
  const {checkStatus} = useOrderClock()
  const history = useHistory()
  const [expanded, setExpanded] = useState<TableRowId[]>([])

  const getOrderStyle = useCallback(
    (order: OrderModel) => {
      const PENDING_ORDER_STATUS: OrderModelStatus[] = ['preparing', 'pending', 'confirmed']
      if (PENDING_ORDER_STATUS.includes(order.status)) {
        return clsx({
          'text-danger': checkStatus(DateUtil.getDateFromApiString(order.createdAt)) === 'late',
          'text-warning': checkStatus(DateUtil.getDateFromApiString(order.createdAt)) === 'warning',
        })
      } else {
        return 'text-muted'
      }
    },
    [checkStatus]
  )

  const columns = useMemo(() => {
    const columns: TableColumnOptions<OrderModel>[] = [
      {
        field: 'code',
        label: 'Order #',
        render: ({data}) => <span className={getOrderStyle(data)}>{data.code}</span>,
      },
      {
        field: 'name',
        label: 'Customer Name',
        render: ({data}) => <span className={getOrderStyle(data)}>{data.name}</span>,
      },
      {
        field: 'orderType',
        label: 'Order Type',
        render: ({data}) => (
          <span className={clsx(getOrderStyle(data), 'text-uppercase')}>{data.orderType}</span>
        ),
      },
      {
        field: 'tableNo',
        label: 'Table',
        render: ({data}) => (
          <span className={clsx(getOrderStyle(data), 'text-uppercase')}>{data.tableNo}</span>
        ),
      },
      {
        field: 'status',
        label: 'Status',
        render: ({data}) => (
          <span className={clsx(getOrderStyle(data), 'text-uppercase')}>{data.status}</span>
        ),
      },
      {
        field: 'isPaid',
        label: 'Paid?',
        type: 'boolean',
      },
      {
        field: 'createdAt',
        label: 'Time',
        render: ({data}) => (
          <span className={clsx(getOrderStyle(data), 'text-uppercase text-center')}>
            {moment(data.createdAt).fromNow(true)}
          </span>
        ),
      },
    ]
    return columns
  }, [getOrderStyle])

  const handleEdit = useCallback(
    (data: OrderModel) => {
      history.push(`/orders/${data.code}`)
    },
    [history]
  )

  const rowAction = useCallback(
    (data: OrderModel) => {
      if (!data.isPaid && data.status !== 'cancelled') {
        return (
          <RowAction
            iconType='General'
            iconName='Edit'
            onClick={handleEdit}
            data={data}
            variant='primary'
            tooltip='Edit'
          />
        )
      }
    },
    [handleEdit]
  )

  return (
    <Table
      data={tableData}
      columns={columns}
      idExtractor={idExtractor}
      actions={rowAction}
      expansion={FohOrderListTableRowExpansion}
      onExpandChange={setExpanded}
      expandedRows={expanded}
    />
  )
}
