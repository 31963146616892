import {FormikContextType} from 'formik'
import {useCallback} from 'react'

export interface UseFormikStringInputHelpersProps {
  errorMessage: string
  isTouched: boolean
  onBlur: () => void
  onChange: (value: string) => void
  value: string
}

export const useFormikStringInputHelpers = (formik: FormikContextType<any>) => {
  const getProps = useCallback(
    (name: string): UseFormikStringInputHelpersProps => {
      return {
        onBlur: () => {
          formik.setFieldTouched(name)
        },
        onChange: (value) => {
          formik.setFieldValue(name, value)
        },
        value: formik.values[name],
        errorMessage: formik.errors[name] as string,
        isTouched: formik.touched[name] as boolean,
      }
    },
    [formik]
  )

  return {
    getProps,
  }
}
