export class QrCodeDataParser {
  constructor(private qrCodeData: string) {}

  public isCode = () => {
    return QR_CODE_REGEX.test(this.qrCodeData)
  }

  public getTicketCode = () => {
    if (this.isCode()) {
      return this.qrCodeData
    }
    const url = new URL(this.qrCodeData)
    const qrcode = new URLSearchParams(url.search).get('q')
    if (qrcode) {
      return qrcode
    } else {
      throw new Error('Unknown QR Code.')
    }
  }

  public getVoucherCode = () => {
    return this.getTicketCode()
  }
}

const QR_CODE_REGEX = /^([A-Z0-9]){3,}$/
