import clsx from 'clsx'
import {Fragment, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {HorizontalDivider} from '../../../utils/HorizontalDivider'
import {useLayoutHelpers} from '../../DefaultLayout/useLayoutHelpers'

export interface NavButtonMenuProps {
  items: NavigationSectionMenuItem[]
  isChild?: boolean
}

export const NavButtonMenu = ({items, isChild}: NavButtonMenuProps) => {
  const {childrenIsActive, itemHasSubMenu, isRouteActive} = useLayoutHelpers()

  const content = useMemo(() => {
    const menuGroup = items.reduce<MenuGroup[]>((acc, item) => {
      const group = acc.find((group) => group.group === item.group)
      if (group) {
        group.items.push(item)
      } else {
        acc.push({
          items: [item],
          group: item.group,
        })
      }
      return acc
    }, [])

    return menuGroup.map((group, i) => {
      return (
        <Fragment key={`${group.group}${i}`}>
          {i > 0 && <HorizontalDivider />}
          {group.items.map((item, i) => {
            const hasSubMenu = itemHasSubMenu(item)
            const isActive = isRouteActive(item.to) || childrenIsActive([item])

            const arrow = hasSubMenu ? <span className='menu-arrow'></span> : null

            const titleClassName = clsx(
              'menu-title',
              {
                'text-primary': isActive,
              },
              !isActive && item.status && `text-${item.status}`
            )

            const content = item.to ? (
              <Link to={item.to} className='menu-link px-3'>
                <span className={titleClassName}>{item.title}</span>
                {arrow}
              </Link>
            ) : (
              <div className='menu-link px-3'>
                <span className={titleClassName}>{item.title}</span>
                {arrow}
              </div>
            )

            return (
              <Fragment key={i}>
                {hasSubMenu ? (
                  <div
                    className='menu-item px-3'
                    data-kt-menu-trigger='hover'
                    data-kt-menu-placement='auto'
                  >
                    {content}
                    {itemHasSubMenu(item) && item.subMenus && (
                      <NavButtonMenu items={item.subMenus} isChild />
                    )}
                  </div>
                ) : (
                  <div className='menu-item px-3'>{content}</div>
                )}
              </Fragment>
            )
          })}
        </Fragment>
      )
    })
  }, [childrenIsActive, isRouteActive, itemHasSubMenu, items])

  if (isChild) {
    return <div className='menu-sub menu-sub-dropdown w-175px py-4'>{content}</div>
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
      data-kt-menu='true'
    >
      {content}
    </div>
  )
}

interface MenuGroup {
  group?: string
  items: NavigationSectionMenuItem[]
}
