import clsx from 'clsx'
import {forwardRef, MouseEvent, TouchEvent, useCallback, useRef} from 'react'
import {useOnUnmount} from '../../../hooks/useOnUnmount'
import {useRefFork} from '../../../hooks/useRefFork'
import {useResizeObserver} from '../../../hooks/useResizeObserver'
import {PaneDirection} from '../Pane/Pane'
import styles from './Splitter.module.scss'

type HTMLDivElementProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>

export interface SplitterProps extends HTMLDivElementProps {
  className?: string
  direction: PaneDirection
  onUnmount?: (el: HTMLDivElement) => void
}

export const Splitter = forwardRef<HTMLDivElement, SplitterProps>(
  ({direction, className, onUnmount, ...divProps}, forwardedRef) => {
    const ref = useRefFork<HTMLDivElement | null>(null, [forwardedRef])
    const dragIndicatorRef = useRef<HTMLDivElement | null>(null)

    const moveSplitterHandle = useCallback((y: number) => {
      if (dragIndicatorRef.current) {
        dragIndicatorRef.current.style.top = `${y}px`
      }
    }, [])

    useResizeObserver(ref.current, (entry) => {
      const heightTop = dragIndicatorRef.current?.style.top
      let offsetTop: number
      if (heightTop) {
        offsetTop = Math.min(entry.contentRect.height, parseInt(heightTop))
      } else {
        offsetTop = entry.contentRect.height / 2
      }
      moveSplitterHandle(offsetTop)
    })

    const handlePointerMove = useCallback(
      (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
        if (dragIndicatorRef.current && ref.current) {
          const pointerY = 'pageY' in e ? e.pageY : e.touches[0].pageY
          const y = Math.min(
            Math.max(pointerY - e.currentTarget.offsetTop, 0),
            e.currentTarget.offsetHeight
          )
          moveSplitterHandle(y)
        }
      },
      [moveSplitterHandle, ref]
    )

    useOnUnmount(() => ref.current && onUnmount?.(ref.current))

    return (
      <div
        ref={ref}
        {...divProps}
        className={clsx(
          'bg-secondary rounded',
          styles.root,
          {
            [styles.horizontal]: direction === 'horizontal',
            [styles.vertical]: direction === 'vertical',
            'mx-2': direction === 'horizontal',
            'my-2': direction === 'vertical',
          },
          className
        )}
        onTouchMove={handlePointerMove}
        onMouseMove={handlePointerMove}
      >
        <div ref={dragIndicatorRef} className={clsx(styles.dragIndicator, 'bg-secondary')}>
          <div className={clsx(styles.dragIndicatorDot, 'bg-white')}></div>
          <div className={clsx(styles.dragIndicatorDot, 'bg-white')}></div>
          <div className={clsx(styles.dragIndicatorDot, 'bg-white')}></div>
        </div>
      </div>
    )
  }
)
