import {Ref, ReactNode, useImperativeHandle} from 'react'
import {ZoomProps} from '@visx/zoom/lib/Zoom'
import {localPoint} from '@visx/event'

export type ZoomChildrenParam = Parameters<ZoomProps<SVGSVGElement>['children']>[0]

export interface ZoomAreaProps {
  children: ReactNode
  zoom: ZoomChildrenParam
  height: number
  width: number
  zoomRef?: Ref<ZoomChildrenParam>
}

export const ZoomArea = ({children, zoom, height, width, zoomRef}: ZoomAreaProps) => {
  useImperativeHandle(zoomRef, () => zoom)

  return (
    <svg
      ref={zoom.containerRef}
      style={{
        position: 'relative',
        height,
        width,
        cursor: zoom.isDragging ? 'grabbing' : 'grab',
        touchAction: 'none',
      }}
    >
      <rect
        width={width}
        height={height}
        rx={14}
        fill='transparent'
        onTouchStart={zoom.dragStart}
        onTouchMove={zoom.dragMove}
        onTouchEnd={zoom.dragEnd}
        onMouseDown={zoom.dragStart}
        onMouseMove={zoom.dragMove}
        onMouseUp={zoom.dragEnd}
        onMouseLeave={() => {
          if (zoom.isDragging) zoom.dragEnd()
        }}
        onDoubleClick={(event) => {
          const point = localPoint(event) || {x: 0, y: 0}
          zoom.scale({scaleX: 1.1, scaleY: 1.1, point})
        }}
      />
      <g transform={zoom.toString()}>{children}</g>
    </svg>
  )
}
