import {useMemo, useCallback} from 'react'
import {DateUtil} from '../../../utils/DateUtil'

export const useDay = () => {
  const getDayThreeLetters = useCallback((start: Date | string, end: Date | string) => {
    const startedAt = DateUtil.formatToDayUTC04ThreeLetters(start)
    const endedAt = DateUtil.formatToDayUTC04ThreeLetters(end)
    return startedAt === endedAt ? startedAt : `${startedAt} - ${endedAt}`
  }, [])

  const getDayFulllName = useCallback((start: Date | string, end: Date | string) => {
    const startedAt = DateUtil.formatToDayUTC04FullName(start)
    const endedAt = DateUtil.formatToDayUTC04FullName(end)
    return startedAt === endedAt ? startedAt : `${startedAt} - ${endedAt}`
  }, [])

  return useMemo(
    () => ({
      getDayThreeLetters,
      getDayFulllName,
    }),
    [getDayThreeLetters, getDayFulllName]
  )
}
