import {useCallback, useState} from 'react'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {FilterModel} from '../../../../models/FilterModel'
import {CustomerTable} from '../../components/tables/CustomerTable/CustomerTable'
import {useDispatch} from 'react-redux'
import {actions} from '../../redux/CustomerPortalRedux'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {CustomerWithParentModel} from '../../../../models/CustomerModel'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {CustomerFormPageEdit} from './CustomerFormPageEdit'
import CustomerDetails from '../CustomerDetails'
import CustomerFormPageCreate from './CustomerFormPageCreate'

import {ImportCustomers} from '../../components/ImportCustomers/ImportCustomers'

const CustomerPage = () => {
  const dispatch = useDispatch()

  const [customerToEdit, setCustomerToEdit] = useState<CustomerWithParentModel>()
  const customers = useRootStateSelector((state) => state.customerPortal.customers)
  const {pushError} = useAlerts()
  const {setFilter} = useEntityFilter('customer-portal-customer')
  const match = useRouteMatch()
  const history = useHistory()
  const refreshData = useCallback(() => {
    dispatch(actions.customers.search())
  }, [dispatch])

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshData()
    },
    [refreshData, setFilter]
  )

  const handleOnEditCustomer = useCallback(
    async (customer) => {
      try {
        if (customer) {
          setCustomerToEdit(customer)
          history.push(`${match.path}/update/${customer.code}`)
        }
      } catch (e: any) {
        pushError(e)
      }
    },
    [history, match.path, pushError]
  )

  const handleOnCreateCustomer = useCallback(() => {
    history.push(`${match.path}/create`)
  }, [history, match.path])

  const handleImportCustomers = useCallback(() => {
    history.push(`${match.path}/import`)
  }, [history, match.path])

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/update/${customerToEdit?.code}`}>
          <Toolbar title='Update Customers' />
          <CustomerFormPageEdit customerToEdit={customerToEdit} />
        </Route>

        <Route exact path={`${match.path}/create`}>
          <Toolbar title='Create Customers' />
          <CustomerFormPageCreate />
        </Route>
        <Route exact path={`${match.path}/import`}>
          <Toolbar title='Import Customers' />
          <ImportCustomers />
        </Route>
        <Route path={`${match.path}/:customerCode`}>
          <CustomerDetails />
        </Route>
        <Route exact path={`${match.path}`}>
          <Toolbar title='Customers' />
          <CustomerTable
            onFilter={onFilterHandler}
            onDeleteSuccess={refreshData}
            onEdit={handleOnEditCustomer}
            onImportCustomers={handleImportCustomers}
            data={customers}
            onNewCustomerClick={handleOnCreateCustomer}
            className='rs-table-bg'
            customWrapper='rs-top'
            cornersClassName='table-cut-corner-polygon rs-gradient'
            paginationClassName='px-10 pb-10 rs-pagination'
          />
        </Route>
      </Switch>
    </>
  )
}

export {CustomerPage}
