import clsx from 'clsx'
import {BookingDetailModel} from '../../../../models/customer-portal/BookingDetailModel'

export const BookingProductStatusColumn = ({data}: {data: BookingDetailModel}) => {
  return <span className={getBadge(data)}>{data.bookingProductStatus}</span>
}

const getBadge = (product: BookingDetailModel) => {
  let baseClass = 'badge text-uppercase'

  if (product) {
    switch (product.bookingProductStatus) {
      case 'printed':
        return clsx(baseClass, 'badge-light-primary')
      case 'confirmed':
        return clsx(baseClass, 'badge-light-info')
      case 'sent':
        return clsx(baseClass, 'badge-light-success')
      case 'disabled':
        return clsx(baseClass, 'badge-light-danger')
      case 'pending':
        return clsx(baseClass, 'badge-light-warning')
      case 'cancelled':
        return clsx(baseClass, 'badge-light-danger')

      default:
        return clsx(baseClass, 'badge-light-dark')
    }
  }
}
