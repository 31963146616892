import {ReactNode, useMemo} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {DEFAULT_LAYOUT_MENUS} from '../../../config/drawer'
import {useAuthRole} from '../../components/hooks/useAuthRole'
import {HeaderControls} from '../../components/layouts/DefaultLayout/Header/HeaderControls'
import {Layout} from '../../components/layouts/DefaultLayout/Layout'
import {AuthPage} from '../../modules/default/auth'
import {AuthHeaderThumbnail} from '../../modules/default/auth/components/AuthHeaderThumbnail'
import {useAuthMenus} from '../../modules/default/common/hooks/useAuthMenus'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import { AuthStateProvider } from '../../modules/customer-portal/components/forms/AuthStateContext'

export const Routes = () => {
  const auth = useAuthRole()
  const menus = useAuthMenus(DEFAULT_LAYOUT_MENUS)

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <Layout menus={menus}>
        <HeaderControls>
          <AuthHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </Layout>
    )
  }, [auth, menus])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }
    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthPage />
        </Route>
      )
    }
    return <Redirect from='/auth' to='/' />
  }, [auth])

  return (
    <AuthStateProvider>
    <Switch>
      <Route path='/logout' component={Logout} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
    </AuthStateProvider>
  )
}
