import {ReactNode, useMemo} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {MetronicIconPath, MetronicIcon} from '../../../../../app/components/inputs/MetronicIcon'
import {checkIsActive, KTSVG} from '../../../../helpers'
import {useLayout} from '../../../core'

export interface AsideMenuItemProps {
  to: string
  title: string
  icon?: string | MetronicIconPath
  fontIcon?: string
  hasBullet?: boolean
  children?: ReactNode
}

const AsideMenuItem = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}: AsideMenuItemProps) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  const menuIcon = useMemo(() => {
    if (icon) {
      if (typeof icon === 'string') {
        return <KTSVG path={icon} className='svg-icon-3x' />
      }
      return <MetronicIcon iconType={icon.iconType} iconName={icon.iconName} size='lg' />
    }
  }, [icon])

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && <span className='menu-icon'>{menuIcon}</span>}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
