import { useColorScalePreset } from "../../../components/charts/hooks/useColorScalePreset"

export interface UseColorScalePresetProps {
    domain: (string | number)[]
  }
  
  export const usePortalColorScalePreset = ({domain}: UseColorScalePresetProps) => {
    return useColorScalePreset({domain, colors})
  }
  
  //ADMM COLORS
  const colors = [
    // '#00acde',
    // '#2b65f9',
    // '#b65ec2',
    '#0096de',
    '#a5373d'
  ]