import {FormikContextType, useFormik} from 'formik'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {useEffect, useState} from 'react'
import {FormTranslationTabValue} from '../../../../../../components/utils/FormTranslationTabList'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {useCustomerFormData} from '../hooks/useCustomerFormData'
import {Button} from '../../../../../../components/inputs/Button'
import {CustomerForm, getPortalCustomerPayload} from '../forms/CustomerForm'
import {EMPTY_INITIAL_VALUES, validationSchema} from '../forms/CustomerFormFields'
import {PostPortalCustomer} from '../../../../redux/CustomerPortalCRUD'

export interface ReservationWizardCustomerStepFormValues {
  customer: CustomerModel | null
}

interface ReservationWizardCreateCustomerStepProps<
  T extends ReservationWizardCustomerStepFormValues
> {
  formik: FormikContextType<T>
  handleCreateCustomer?: () => void
}

export const ReservationWizardCreateCustomerStep = <
  T extends ReservationWizardCustomerStepFormValues
>({
  formik,
  handleCreateCustomer,
}: ReservationWizardCreateCustomerStepProps<T>) => {
  const [translationTab, setTranslationTab] = useState<FormTranslationTabValue>('')
  const {categorySearchResults, countries, searchCategories, resetCountries} = useCustomerFormData()
  const {pushError} = useAlerts()
  useEffect(() => {
    resetCountries()
  }, [resetCountries])

  const customerFormik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        const payload = getPortalCustomerPayload({values})
        const {data} = await PostPortalCustomer(payload)
        if (data) {
          formik.setFieldValue('customer', data)
          handleCreateCustomer?.()
          resetForm()
        }
      } catch (e: any) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div>
      <form
        className='form-drawer-container drawer-container'
        noValidate
        onSubmit={customerFormik.handleSubmit}
      >
        <CustomerForm
          onSearchCategories={searchCategories}
          categorySearchResults={categorySearchResults}
          countries={countries}
          formik={customerFormik}
          lang={translationTab}
          onLangChange={setTranslationTab}
        />
        <div className='w-100 text-end'>
          <Button
            className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-auto'
            disabled={customerFormik.isSubmitting || !customerFormik.isValid}
            type='submit'
          >
            Create & Next
          </Button>
        </div>
      </form>
    </div>
  )
}
