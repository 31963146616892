import clsx from 'clsx'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useResizeObserver} from '../hooks/useResizeObserver'
import {ColorVariant} from '../inputs/Button'
import {ActivityTimelineItem, ActivityTimelineItemAttributes} from './TimelineItem'

export interface ActivityTimelineProps {
  className?: string
  items: ActivityTimelineItemAttributes[]
  stage: number
  width?: string
  variant: ColorVariant
}

export const ActivityTimeline = ({items, className, stage, variant}: ActivityTimelineProps) => {
  const itemContainerRef = useRef<HTMLDivElement>(null)
  const [lineData, setLineData] = useState<{width: string; start: string} | null>(null)
  const [grayLineData, setGrayLineData] = useState<{width: string; start: string} | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const timelineItems = useMemo(() => {
    return items.map((item, index) => {
      const itemStage = index + 1
      const active = itemStage <= stage
      return (
        <ActivityTimelineItem
          key={`${item.label}${index}`}
          variant={variant}
          active={active}
          isLastItem={index === items.length - 1}
          {...item}
        />
      )
    })
  }, [items, stage, variant])

  const resetLineData = useCallback(() => {
    const containerEl = itemContainerRef.current
    if (containerEl) {
      const dots = containerEl.querySelectorAll('.activity-timeline-item-dot')
      const firstItem = dots.item(0)
      const targetItem = dots.item(Math.min(stage - 1, dots.length - 1))
      const lastItem = dots.item(dots.length - 1)
      if (firstItem && targetItem && lastItem) {
        const parentBounds = containerEl.getBoundingClientRect()
        const firstItemBounds = firstItem.getBoundingClientRect()
        const targetItemBounds = targetItem.getBoundingClientRect()
        const lastItemBounds = lastItem.getBoundingClientRect()

        const distance = targetItemBounds.x - firstItemBounds.x
        const leftPadding = firstItemBounds.left - parentBounds.left + firstItemBounds.width / 2
        const grayLineDistance = lastItemBounds.x - firstItemBounds.x

        setLineData({
          start: `${leftPadding}px`,
          width: `${distance}px`,
        })
        setGrayLineData({
          start: `${leftPadding}px`,
          width: `${grayLineDistance}px`,
        })
      }
    }
  }, [stage])

  useResizeObserver(containerRef.current, () => {
    resetLineData()
  })

  useEffect(() => {
    resetLineData()
  }, [resetLineData, stage])

  return (
    <div ref={containerRef} className={clsx('activity-timeline', className)}>
      <div ref={itemContainerRef} className='activity-timeline__item-container'>
        {grayLineData && items.length > 0 && (
          <div
            style={{
              width: grayLineData.width,
              left: grayLineData.start,
            }}
            className='activity-timeline__line'
          />
        )}
        {lineData && stage > 1 && (
          <div
            style={{
              width: lineData.width,
              left: lineData.start,
            }}
            className={clsx('activity-timeline__line', `border-${variant}`)}
          />
        )}
        {timelineItems}
      </div>
    </div>
  )
}
