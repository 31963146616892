import {getUrlData} from '../../../../config/env'
import {useWebSockets} from '../../../components/hooks/useWebSockets'
import {OrderModel} from '../../../models/fnb/OrderModel'

export interface FohWebSocketData {
  data: OrderModel[]
}

export const useFohWebSockets = (callback: (data: FohWebSocketData) => void) => {
  useWebSockets<FohWebSocketData>({
    socketName: `${getUrlData().outletCode?.toUpperCase()}:ORDERS:FOH`,
    callback,
  })
}
