import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {WidgetF09Model} from '../../../../../../models/fnb/WidgetModel'
import {OutletHourlyRevenueProps} from './OutletHourlyRevenue'
import {formatMoment, getMoment} from './utils'

export interface OutletHourlyRevenueTooltipProps {
  datum?: WidgetF09Model
}

export const OutletHourlyRevenueTooltip = ({datum}: OutletHourlyRevenueProps) => {
  const {key} = useBarChartTooltipContext()

  const data = useMemo(() => {
    return datum?.find((d) => d.time === key)
  }, [datum, key])

  const children = useMemo(() => {
    if (data) {
      return `**Time:** ${formatMoment(getMoment(data.time))}

**Total:** ${data.total}

**Quantity:** ${data.quantity}
`
    }
  }, [data])

  if (!data) {
    return null
  }

  return <MarkdownTooltip>{children}</MarkdownTooltip>
}
