import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {VoucherTableFlat} from './VoucherTableFlat'

export interface VoucherTableGroupedData {
  email: string
  voucher: VoucherModel[]
}

export interface VoucherTableGroupedRowProps {
  data: VoucherTableGroupedData
  onRefresh: () => void
  onRefreshCallback?: () => void
  columns: TableColumnOptions<VoucherModel>[]
}

export const VoucherTableGroupedRow = ({
  onRefresh,
  data,
  columns,
  onRefreshCallback,
}: VoucherTableGroupedRowProps) => {
  return (
    <VoucherTableFlat
      columns={columns}
      onRefresh={onRefresh}
      onRefreshCallback={onRefreshCallback}
      data={data.voucher}
    />
  )
}
