import {ReactNode, useMemo} from 'react'

export interface RefCodeColumnProps {
  refCode?: string
}

export const RefCodeColumn = ({refCode}: RefCodeColumnProps) => {
  const refCodes = useMemo(() => {
    const nodes: ReactNode[] = []

    refCode?.split(',').forEach((code, i) => {
      if (code) {
        nodes.push(
          <span key={`${code}${i}`} className='me-1 mb-1 badge badge-light-dark text-uppercase'>
            {code}
          </span>
        )
      }
    }, [])

    return nodes
  }, [refCode])
  return <>{refCodes}</>
}
