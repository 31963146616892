import {useCallback, useEffect, useMemo, useState} from 'react'
import {SeatMapValue} from '../SeatMapInput/SeatMapValue'
import {SelectTreeOptionGroupItem} from '../SelectTreeNative/SelectTreeOptionGroup'
import {TreeSelectNode} from '../TreeSelect/TreeSelectNode'
import {MetronicIconButton} from '../MetronicIconButton'
import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'
import {TreeSelectLastLevel} from '../TreeSelectLastLevel/TreeSelectLastLevel'
import {Button} from '../Button'
import {SeatMapSelectionModalInput} from '../SeatMapInput/SeatMapSelectionModalInput'
import {SeatMapBoxLegends, SeatMapBoxLegendsProps} from '../SeatMapInput/SeatMapBoxLegends'
import {
  ACTIVE_COLOR,
  DANGER_COLOR,
  DEFAULT_COLOR,
  PURPLE_COLOR,
} from '../SeatMapInput/hooks/useSeatHelper'
import {useAlerts} from '../../alerts/useAlerts'
import {useModalState} from '../../modals/useModalState'
import {useSeatMapPortalReservationState} from '../../../modules/customer-portal/hooks/useSeatMapPortalReservationState'
import {GetAvailableSeatMaps, GetSeatMapAvailabilityByProduct} from '../../../modules/customer-portal/redux/CustomerPortalCRUD'
import {GetLocationByCode} from '../../../modules/default/acs/redux/AcsCRUD'
import { usePrevious } from '../../hooks/usePrevious'
import { ProductLocationModel } from '../../../models/ems/ReservationModel'
import { DateUtil } from '../../../utils/DateUtil'
import moment from 'moment'
import { FormikContextType, useFormik } from 'formik'
import { ReservationFormValues } from '../../../modules/customer-portal/components/wizards/ReservationWizard/ReservationWizard'

export interface ReservationSeatInputItemAttributes extends SelectTreeOptionGroupItem {
  items?: ReservationSeatInputItemAttributes[]
  label: string
  value: string
}

export interface ReservationSeatInputItemValue {
  value: string // Location Code
  label: string
  id: number | string
  seatMaps: SeatMapValue | null
  productCode: string
  qty: number
}
export interface PortalReservationSeatInputItemProps <T extends ReservationFormValues> {
  className?: string
  value: ReservationSeatInputItemValue
  onChange: (value: ReservationSeatInputItemValue) => void
  items: ReservationSeatInputItemAttributes[]
  selectedItems: string[]
  placeholder: string
  label: string
  onRemove: (value: ReservationSeatInputItemValue) => void
  eventCode?: string | null
  isLocationDisabled?: (locationCode: any) => boolean
  productCode: string
  productName?: string
  customerCode?: string
  qty: number
  initialValues: ReservationSeatInputItemValue
  selectedSeat?: ProductLocationModel
  open: boolean
  product: ProductLocationModel
  formik: FormikContextType<T>
  
}

export const PortalReservationSeatInputItem = <T extends ReservationFormValues>({
  items,
  onChange,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
  eventCode,
  isLocationDisabled,
  productCode,
  productName,
  customerCode,
  qty,
  initialValues,
  selectedSeat,
  open,
  product,
  formik,
}: PortalReservationSeatInputItemProps<T>) => {
  const {
    isOpen: isSelectionModalOpen,
    hide: hideSelectionModal,
    open: openSelectionModal,
  } = useModalState()
  const [locationCode, setLocationCode] = useState<string>()
  const previousOpen = usePrevious(open)
  const [_productCode, setProductCode] = useState<string>(productCode)
  const {pushError} = useAlerts()
  const [selected, setSelected] = useState<SeatMapValue>()
  const [totalSelected, setTotalSelected] = useState(0)

  const {
    columns,
    rows,
    extra,
    disabled,
    active,
    hidden,
    isRightToLeft,
    isBottomToTop,
    seatMapSpacingX,
    isLoading,
    occupied,
    setOccupied,
    sharedDisabled,
    seatsDays,
    setSeatsDays,
    setAvailableSeates,
    resetState: resetSeatMapState,
  } = useSeatMapPortalReservationState()


  const seatFormik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      handleSelectionSubmit()
    },
  })


  const handleSelectionSubmit = useCallback(async () => {
    if (locationCode) {
      const {data} = await GetLocationByCode(locationCode)
      onChange({
        ...value,
        value: locationCode,
        seatMaps: selected || null,
        label: data.name,
        productCode: _productCode,
        qty: totalSelected || 0,
      })
      hideSelectionModal()
    }
  }, [_productCode, hideSelectionModal, locationCode, onChange, selected, totalSelected, value])
  
const otherSeats = useMemo(() => {
  if (formik.values.products && formik.values.products.length && product) {
    const found = formik.values.products.filter((item) => {
      if (
        item.data?.startedAt &&
        item.data?.endedAt &&
        item.seatMap &&
        product.startedAt &&
        product.endedAt
      ) {
        return (
          item.data?.code !== product.code &&
          item.locationCode === locationCode &&
          (dateIsBetween(
            DateUtil.getDateFromApiString(product.startedAt),
            DateUtil.getDateFromApiString(item.data?.startedAt),
            DateUtil.getDateFromApiString(item.data?.endedAt)
          ) ||
            dateIsBetween(
              DateUtil.getDateFromApiString(product.endedAt),
              DateUtil.getDateFromApiString(item.data?.startedAt),
              DateUtil.getDateFromApiString(item.data?.endedAt)
            ) ||
            dateIsBetween(
              DateUtil.getDateFromApiString(item.data?.startedAt),
              DateUtil.getDateFromApiString(product.startedAt),
              DateUtil.getDateFromApiString(product.endedAt)
            ) ||
            dateIsBetween(
              DateUtil.getDateFromApiString(item.data?.endedAt),
              DateUtil.getDateFromApiString(product.startedAt),
              DateUtil.getDateFromApiString(product.endedAt)
            ))
        )
      }
      return false;
    })
    if (found) return found
  }
  return undefined
}, [formik.values.products, product, locationCode])


  const occupiedSeats = useMemo(() => {
    return occupied
  }, [occupied])

  const disabledSeats = useMemo(() => {
    if (customerCode) {
      let activeSeats = new SeatMapValue()
      if (extra) {
        activeSeats = activeSeats.union(extra)
      }
      if (active) {
        activeSeats = activeSeats.union(active)
      }
      if (occupied) {
        activeSeats = activeSeats.union(occupied)
      }
      return new SeatMapValue(rows, columns).difference(activeSeats)
    } else {
      return disabled
    }
  }, [active, columns, customerCode, disabled, extra, occupied, rows])

  const sharedSeats = useMemo(() => {
    if (otherSeats) {
      const newOtherSeats = otherSeats.map((item) => {
        return item.seatMap
      })
      let final = sharedDisabled
      newOtherSeats.forEach((item) => {
        if (item !== null) {
          final = final.union(item)
        }
      })
      return final
    }
  
    return sharedDisabled
  }, [sharedDisabled, otherSeats])

  const handleReserveSeat = useCallback(
    async (locationCode, eventCode) => {
      try {
        const {data} = await GetLocationByCode(locationCode)
        resetSeatMapState(data.seatMap)
        if (!data.seatMap) {
          throw new Error('Location has no seat map!')
        }
        const {
          data: {availableSeats},
          // } = await GetAvailablReservationSeatMap(locationCode, eventCode)
        } = await GetSeatMapAvailabilityByProduct(locationCode, eventCode, _productCode)
        setAvailableSeates(new SeatMapValue(availableSeats))
      } catch (e: any) {
        pushError(e)
      } finally {
      
        if (productCode && customerCode) {
          try {
            const {
              data: {availableSeats, days, usedSeats},
            } = await GetAvailableSeatMaps(
              locationCode,
              productCode,
              eventCode,
              customerCode
            )

            setOccupied(new SeatMapValue(usedSeats))

            if (days && Object.keys(days).length > 0) {
              Object.keys(days).forEach((item) => {
                days[item] = new SeatMapValue(days[item])
              })

              setSeatsDays(days)
            } else setSeatsDays(null)
            setAvailableSeates(new SeatMapValue(availableSeats))
          } catch (err: any) {
            pushError(err)
          }
        }
      }

      openSelectionModal()
    },
    [_productCode, customerCode, openSelectionModal, productCode, pushError, resetSeatMapState, setAvailableSeates, setOccupied, setSeatsDays]
  )

  const handleUpdateSeat = useCallback(
    async (locationCode, eventCode) => {
      try {
        setLocationCode(locationCode)
        if (value.seatMaps) {
          setSelected(value.seatMaps)
        }
        handleReserveSeat(locationCode, eventCode)
        setProductCode(value.productCode)
      } catch (e: any) {
        pushError(e)
      }
    },
    [handleReserveSeat, pushError, value.productCode, value.seatMaps]
  )

  const handleSelectionModalClose = useCallback(() => {
    hideSelectionModal()
  }, [hideSelectionModal])

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const getTreeSelectItems = useCallback(
    (items: ReservationSeatInputItemAttributes[]) => {
      return items.map((item) => {
        const isSelected = selectedItems.includes(item.value)

        const treeItem: ReservationSeatInputItemAttributes = {
          label: item.label,
          value: item.value,
          disabled: isSelected || item.disabled || false,
        }
        if (item.items) {
          treeItem.items = getTreeSelectItems(item.items)
        }

        return treeItem
      })
    },
    [selectedItems]
  )


  const Locationvalue = useMemo(() => {
    if (locationCode) {
      return [locationCode]
    }
    return []
  }, [locationCode])

  const handleParentLocationChange = useCallback((newValues: string[]) => {
    setLocationCode(newValues[0])
  }, [])

  const treeSelectItems = useMemo((): ReservationSeatInputItemAttributes[] => {
    return getTreeSelectItems(items)
  }, [items, getTreeSelectItems])

  const isDisabled = useCallback(
    (item: TreeSelectNode) => {
      if (isLocationDisabled) {
        return isLocationDisabled(item.id)
      }
      return false
    },
    [isLocationDisabled]
  )

  const handleOnSelectChange = useCallback(
    (selected: SeatMapValue) => {
      setSelected(selected.difference(occupiedSeats))
      setTotalSelected(selected.getValueCount)
    },
    [occupiedSeats]
  )

  const getModalTitle = useCallback(() => {
    return productName || ''
  }, [productName])

  useEffect(() => {
    if (previousOpen !== open) {
      if (open && selectedSeat && locationCode) {
        seatFormik.setValues(initialValues)
      } else seatFormik.setValues(EMPTY_FORM_VALUES)
      resetSeatMapState()
    }
  }, [formik, initialValues, locationCode, open, previousOpen, resetSeatMapState, seatFormik, selectedSeat])

  return (
    <div className={clsx('product-input-item', className)}>
      <div className='d-flex justify-content-between'>
        <label className='form-label mt-2'>{label}</label>
        {!value.label && (
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary'
            onClick={handleRemove}
          >
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </button>
        )}
      </div>

      <div className='d-flex'>
        <div className='flex-grow-1'>
          <label className='mt-3'>{value.label}</label>
        </div>
        <div className='flex-end me-1'>
          {value.label && (
            <MetronicIconButton
              iconType='Home'
              iconName='Armchair'
              type='button'
              tooltip='Update Seat'
              onClick={() => handleUpdateSeat(value.value, eventCode)}
              color='info'
            />
          )}
        </div>
        {value.label && (
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary'
            onClick={handleRemove}
          >
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </button>
        )}
      </div>

      {!value.seatMaps && (
        <div className='product-input-item-input-container__select-input'>
          <TreeSelectLastLevel
            label='Select Location'
            radioName='parentLocation'
            values={Locationvalue}
            items={treeSelectItems}
            onChange={handleParentLocationChange}
            isLastLevelSelect
            disabled={isDisabled}
          />
        </div>
      )}

      {locationCode && !value.value && (
        <div className='pt-2 flex-grow-1'>
          <Button
            type='button'
            variant='primary'
            size='sm'
            uppercase={false}
            onClick={() => handleReserveSeat(locationCode, eventCode)}
          >
            Reserve Seats
          </Button>
        </div>
      )}

      <SeatMapSelectionModalInput
        modalTitle={getModalTitle}
        spacingX={seatMapSpacingX}
        occupied={occupiedSeats}
        extra={extra}
        // locationItems={locationItems}
        locationCode={seatFormik.values.value}
        // onLocationChange={handleLocationChange}
        onSubmit={seatFormik.handleSubmit}
        count={qty}
        disabled={disabledSeats}
        sharedDisabled={sharedSeats}
        columns={columns}
        rows={rows}
        loading={isLoading}
        disableSubmit={seatFormik.isSubmitting || !seatFormik.isValid}
        disableSelection={seatFormik.isSubmitting}
        onChange={handleOnSelectChange}
        value={seatFormik.values.seatMaps ? seatFormik.values.seatMaps : selected}
        open={isSelectionModalOpen}
        hidden={hidden}
        onHide={handleSelectionModalClose}
        isRightToLeft={isRightToLeft}
        isBottomToTop={isBottomToTop}
        legends={<SeatMapBoxLegends data={LEGENDS} />}
        multipleLocation
        seatsDays={seatsDays}
        isCircledDaysBox={seatsDays ? true : false}
        isShowNoneFilledDays={seatsDays ? false : false}
        isPortal
      />
    </div>
  )
}

const LEGENDS: SeatMapBoxLegendsProps[] = [
  {text: 'Available', color: DEFAULT_COLOR, width: 20, height: 20},
  {text: 'Selected', color: ACTIVE_COLOR, width: 20, height: 20},
  {text: 'Reserved', color: DANGER_COLOR, width: 20, height: 20},
  {text: 'Allocated', color: PURPLE_COLOR, width: 20, height: 20},
]


export const dateIsBetween = (date: Date, start: Date, end: Date) => {
  return moment(date).isBetween(start, end, undefined, '[]')
}

const EMPTY_FORM_VALUES: ReservationSeatInputItemValue = {
  value: '', // Location Code
  label: '',
  id: '',
  seatMaps: null,
  productCode: '',
  qty: 0
}