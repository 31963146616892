import {useMemo, useState} from 'react'
import {Helmet} from 'react-helmet'
import {
  Redirect,
  Route,
  Switch,
  // Link
} from 'react-router-dom'
// import {
//   CustomerPortalAuthBackground,
//   CustomerPortalAuthBackgroundMobile,
// } from '../../../../config/logos'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import FirstLoginPasswordResetForm from '../components/FirstLoginPasswordReset'
import {ForgotPassword} from '../components/forms/ForgotPassword'
import {Login} from '../components/Login'
// import {Login} from '../components/Login'
import {MyPassLogin} from '../components/MyPassLogin'
import {useCustomerAuth} from '../hooks/useCustomerAuth'

export const AuthPage = () => {
  const {down} = useBreakpoint()
  const auth = useCustomerAuth()
  const [isOTP, setIsOTP] = useState<boolean>(false)

  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])
  const isIpad = useMemo(() => {
    return down('md')
  }, [down])

  // const image = useMemo(() => {
  //   if (isMobile) {
  //     return CustomerPortalAuthBackgroundMobile.src
  //   }
  //   return CustomerPortalAuthBackground.src
  // }, [isMobile])

  return (
    <div className='reseller-container'>
      <div className='d-flex rs-main-bg flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'>
        <Helmet>
          <title>Abu Dhabi Grand Prix</title>
          <link rel='icon' href='/portal.ico' />
        </Helmet>
        <div className='container-fluid min-h-100vh d-flex align-items-center justify-content-center'>
          <div className={isMobile ? 'row w-100' : 'row w-75'}>
            {/* Left Side */}
            <div className='col-lg-6 order-lg-1 order-1 d-flex flex-center flex-column p-0 my-5 py-5'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/saas/admm-customer-portal-2024.png')}
                className='img-fluid w-250px ms-5'
              />
              <div style={{textAlign: 'center'}}>
                {!isOTP && (
                  <>
                    <h1 className='text-white fw-bolder mb-12'>ABU DHABI GRAND PRIX</h1>
                    <h6 className='fw-bolder mb-5'>TICKET DISTRIBUTION PORTAL</h6>
                    <p
                      className='fw-bolder mb-5 fs-7 mx-2'
                      style={{maxWidth: '100%', width: isMobile ? '340px' : '450px'}}
                    >
                      Welcome to the 16th edition of the Formula 1® Etihad Airways Abu Dhabi Grand
                      Prix – you’re one step closer to the most exciting event of the year! To
                      access your tickets, please log in using the credentials shared with you via
                      email. The email would have been sent from noreply@abudhabigp.com. Please note
                      that you will be prompted to change the password after logging in. If needed,
                      simply click 'Reset Password' to regain access to your account.
                    </p>
                  </>
                )}
                {isOTP && (
                  <>
                    <p className='fw-bolder w-450px w-100 mb-8 fs-7'>
                      To obtain your OTP, please use your preferred Two-Factor Authentication (2FA)
                      App. In case you do not have a 2FA App, we suggest using Microsoft
                      Authenticator or Google Authenticator. You can download one for free from your
                      system's app store.
                    </p>
                  </>
                )}
              </div>
              {!isMobile && !isIpad && (
                <img
                  alt='Partner Logos'
                  src={toAbsoluteUrl('/media/saas/portal-partners-logo-2024.png')}
                  className='mt-3 img-fluid'
                  style={{width: isMobile ? '310px' : '400px'}}
                />
              )}
            </div>

            {/* Right Side */}
            <div className='col-lg-5 order-lg-2 order-2 d-flex align-items-center justify-content-lg-start justify-content-center'>
              <div className='w-sm-500px w-400px opacity-75 rounded shadow-sm p-2 p-lg-4 form-selector'>
                {auth?.isFirstLogin() && <Redirect to='/auth/password-reset' />}
                {auth && !auth.isFirstLogin() && <Redirect to='/' />}
                <Switch>
                  <Route path='/auth/login/:hash'>
                    {process.env.REACT_APP_WEBNTECH_LOGIN &&
                    process.env.REACT_APP_WEBNTECH_LOGIN === 'FALSE' ? (
                      <MyPassLogin />
                    ) : (
                      <div className='bg-light-primary p-10'>
                        <Login setOTPState={setIsOTP} />
                      </div>
                    )}
                  </Route>
                  <Route path='/auth/login'>
                    {process.env.REACT_APP_WEBNTECH_LOGIN &&
                    process.env.REACT_APP_WEBNTECH_LOGIN === 'FALSE' ? (
                      <MyPassLogin />
                    ) : (
                      <div className='bg-light-primary p-5'>
                        <Login setOTPState={setIsOTP} />
                      </div>
                    )}
                  </Route>
                  <Route path='/auth/password-reset'>
                    <div className='bg-light-primary p-10'>
                      <FirstLoginPasswordResetForm />
                    </div>
                  </Route>
                  <Route path='/auth/forgot-password'>
                    <div className='bg-light-primary p-10'>
                      <ForgotPassword />
                    </div>
                  </Route>
                  <Redirect from='/auth' exact={true} to='/auth/login' />
                  <Redirect to='/auth/login' />
                </Switch>

                <div style={{textAlign: 'center'}}>
                  <label className='fw-bolder w-290px w-100 mt-3 fs-7'>
                    Experiencing login difficulties?{' '}
                    <a
                      href='https://www.yasmarinacircuit.com/en/contact-us'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Click here{' '}
                    </a>
                    to contact us by email or call us directly at: +971 (0)2 497 9000
                  </label>
                </div>

                {(isMobile || isIpad) && (
                  <div className='d-flex justify-content-center'>
                    <img
                      alt='Partner Logos'
                      src={toAbsoluteUrl('/media/saas/portal-partners-logo-2024.png')}
                      className='my-10 img-fluid'
                      style={{width: '310px'}}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* <img alt={PoweredBy.alt} src={PoweredBy.src} className='h-30px mt-10' />  */}
          </div>
        </div>
      </div>
    </div>
  )
}
