import {Modal} from '../modals/Modal'
import {SocialDistancingForm, SocialDistancingFormProps} from './SocialDistancingForm'

export interface SocialDistancingModalFormProps extends SocialDistancingFormProps {
  onHide: () => void
  open: boolean
}

export const SocialDistancingModalForm = ({
  onHide,
  open,
  ...formProps
}: SocialDistancingModalFormProps) => {
  return (
    <Modal open={open} onHide={onHide}>
      <SocialDistancingForm {...formProps} />
    </Modal>
  )
}
