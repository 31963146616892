import clsx from 'clsx'
import {ReactNode, useState} from 'react'
import {DigitalMenuSplashScreenImage} from '../../../../config/logos'
import {useOnMount} from '../../../components/hooks/useOnMount'

export interface SplashScreenProps {
  children?: ReactNode
}

export const DigitalMenuSplashScreen = ({children}: SplashScreenProps) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useOnMount(() => {
    setTimeout(() => {
      setShowSplashScreen(false)
    }, 2500)
  })

  if (showSplashScreen) {
    return (
      <div className='container vh-100'>
        <div className='h-100 d-flex flex-column justify-content-between'>
          <div className='d-flex flex-grow-1 flex-column align-items-center justify-content-center'>
            <div className='d-flex flex-column align-items-center'>
              <img
                alt={DigitalMenuSplashScreenImage.alt}
                src={DigitalMenuSplashScreenImage.src}
                className='img-fluid w-75 mb-20 splash-screen-fade-in-scroll'
              />
              <h1 className='text-uppercase mt-10 ff-f1-bold text-center splash-screen-fade-in-scroll'>
                <span className='d-block'>Yas Eats</span>
                <span className='d-block'>Food &amp; Beverage</span>
              </h1>
            </div>
          </div>
          <div>
            <div className='text-muted text-center fs-8 px-5'>
              <p>
                The F1 FORMULA 1 logo, F1 logo, FORMULA 1, F1, FIA FORMULA ONE WORLD CHAMPIONSHIP,
                GRAND PRIX, ABU DHABI GRAND PRIX and related marks are trademarks of Formula One
                Licensing BV, a Formula 1 company.
              </p>
              <p>All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <div className={clsx({'splash-screen-children-show': !showSplashScreen})}>{children}</div>
}
