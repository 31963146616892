import {useCallback, useEffect} from 'react'
import {useQueryParams} from '../../../components/hooks/useQueryParams'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {FnbQrCodeGenerator} from '../../../utils/Qr/FnbQrGenerator'
import {Button} from '../components/Button'
import {usePageNavigation} from '../hooks/usePageNavigation'

export const MainMenuPage = () => {
  const {goToLocationSelect, goToAllOrderList, goToOutletMenu, goToOutlets, goToFnbQrCodeData} =
    usePageNavigation()
  const queryParams = useQueryParams()
  const getClickHandler = useCallback(
    (value: string) => () => {
      if (value !== 'orders') {
        goToLocationSelect(value as 'qr' | 'list')
      } else {
        goToAllOrderList()
      }
    },
    [goToAllOrderList, goToLocationSelect]
  )
  useEffect(() => {
    try {
      const qrCode = queryParams.get('q')

      if (qrCode) {
        const qrData = FnbQrCodeGenerator.fromQrCode(qrCode)
        goToFnbQrCodeData(qrData)
      }
    } catch (e) {
      // Supress invalid qr code data.
    }
  }, [goToFnbQrCodeData, goToOutletMenu, goToOutlets, queryParams])

  return (
    <div className='py-5 container'>
      <h1 className='fs-2 mb-10 ff-f1-regular'>Share Location</h1>
      <h2 className='fs-1'>Choose your option</h2>
      <p className='fw-light text-muted mb-10'>
        Choose your preferred way to share your location and view the available outlets.
      </p>
      <div className='d-flex flex-column'>
        <Button className='mb-3' variant='success' big light border onClick={getClickHandler('qr')}>
          <MetronicIcon iconType='Shopping' iconName='Barcode-read' size='lg' /> Scan QR Code
        </Button>
        <Button
          className='mb-3'
          variant='success'
          big
          light
          border
          onClick={getClickHandler('list')}
        >
          <MetronicIcon iconType='Map' iconName='Marker1' size='lg' />
          Select Location
        </Button>
        <Button
          className='mb-3'
          variant='success'
          big
          light
          border
          onClick={getClickHandler('orders')}
        >
          <MetronicIcon iconType='Text' iconName='Bullet-list' size='lg' />
          View Orders
        </Button>
      </div>
    </div>
  )
}
