export type SeatMapValueObject = Record<string, number[]>

export class SeatMapValue {
  private seatMap: Partial<SeatMapValueObject> = {}
  constructor(rows: number, columns: number)
  constructor(seatMap: Partial<SeatMapValueObject>)
  constructor()
  constructor(seatMap?: Partial<SeatMapValueObject> | number, columns?: number) {
    if (typeof seatMap === 'number' && columns) {
      this.generateSeatMaps(seatMap, columns)
    } else if (typeof seatMap === 'object') {
      this.seatMap = seatMap
    }
  }

  public static getRowLetter = (letterNumber: number): string => {
    let pow = Math.floor(letterNumber / 26)
    const mod = letterNumber % 26
    const result = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z')
    return pow ? SeatMapValue.getRowLetter(pow) + result : result
  }

  public toggleValue = (rowLetter: string, column: number) => {
    const rowValues = this.seatMap[rowLetter]
    if (rowValues) {
      const columnIndex = rowValues.indexOf(column)
      if (columnIndex >= 0) {
        this.removeByIndex(rowLetter, columnIndex)
      } else {
        this.addValueUNSAFE(rowLetter, column)
      }
    } else {
      this.seatMap[rowLetter] = [column]
    }
  }

  public clone = () => {
    const newSeatMap = {...this.seatMap}

    Object.entries(newSeatMap).forEach(([key, value]) => {
      if (value) {
        newSeatMap[key] = [...value]
      }
    })

    return new SeatMapValue(newSeatMap)
  }

  public isValueExist = (key: string, value: number) => {
    const row = this.seatMap[key]
    if (row?.includes(value)) {
      return true
    }
    return false
  }

  public getSeatMapObject = () => {
    return this.seatMap as SeatMapValueObject
  }

  private setSeatMapObject = (value: SeatMapValueObject) => {
    this.seatMap = value
  }

  public getSeatMapArray = () => {
    const seatArray: string[] = []

    Object.entries(this.getSeatMapObject()).forEach(([row, columns]) => {
      columns.forEach((column) => seatArray.push(`${row}${column}`))
    })

    return seatArray
  }

  public getValueCount = () => {
    let count = 0
    Object.values(this.getSeatMapObject()).forEach((rows) => {
      if (rows) {
        count += rows.length
      }
    })
    return count
  }

  public intersect = (target: SeatMapValue) => {
    const intersection = new SeatMapValue()
    const setAEntries = Object.entries(this.getSeatMapObject())

    setAEntries.forEach(([row, setAColumns]) => {
      if (setAColumns) {
        setAColumns.forEach((setAColumn) => {
          if (target.isValueExist(row, setAColumn)) {
            intersection.addValue(row, setAColumn)
          }
        })
      }
    })

    return intersection
  }

  public union = (target: SeatMapValue) => {
    const union = this.clone()
    Object.entries(target.getSeatMapObject()).forEach(([row, columns]) => {
      if (columns) {
        columns.forEach((column) => {
          union.addValue(row, column)
        })
      }
    })
    return union
  }

  public difference = (target: SeatMapValue) => {
    const difference = this.clone()
    Object.entries(target.getSeatMapObject()).forEach(([row, columns]) => {
      if (columns) {
        columns.forEach((column) => {
          difference.removeValue(row, column)
        })
      }
    })
    return difference
  }

  public hasAnyValues = () => {
    return Object.keys(this.getSeatMapObject()).length > 0
  }

  public addValue = (rowLetter: string, value: number) => {
    const rowValues = this.seatMap[rowLetter]
    if (rowValues && !rowValues.includes(value)) {
      rowValues.push(value)
    } else if (!rowValues) {
      this.seatMap[rowLetter] = [value]
    }
  }

  public removeValue(rowLetter: string, value: number) {
    const rowValues = this.seatMap[rowLetter]
    if (rowValues) {
      const columnIndex = rowValues.indexOf(value)
      this.removeByIndex(rowLetter, columnIndex)
    }
  }

  public applyOccupiedDistancing(distance: number) {
    const socialDistancing = new SeatMapValue()
    const seatmap = this.getSeatMapObject()
    Object.entries(seatmap).forEach(([row, columns]) => {
      columns.forEach((column) => {
        for (let i = 0; i < distance; i++) {
          socialDistancing.addValue(row, column + i + 1)
          socialDistancing.addValue(row, column - (i + 1))
        }
      })
    })
    this.seatMap = this.union(socialDistancing).getSeatMapObject()
  }

  public applySocialDistancing(rows: number, columns: number, spacing: number, perSeats: number) {
    let currentSpacingCounter = 0
    let currentDisabledCounter = 0
    const disabledSocialDistancing = new SeatMapValue()

    for (let i = 0; i < rows; i++) {
      const rowLetter = SeatMapValue.getRowLetter(i + 1)
      for (let j = 0; j < columns; j++) {
        if (currentSpacingCounter >= perSeats) {
          if (currentDisabledCounter <= spacing) {
            disabledSocialDistancing.addValue(rowLetter, j + 1)
            currentDisabledCounter++
          }
        } else {
          currentSpacingCounter++
        }
        if (currentDisabledCounter >= spacing) {
          currentDisabledCounter = 0
          currentSpacingCounter = 0
        }
      }
    }
    const seatMap = this.union(disabledSocialDistancing).getSeatMapObject()
    this.setSeatMapObject(seatMap)
  }

  private generateSeatMaps = (rows: number, columns: number) => {
    for (let i = 0; i < rows; i++) {
      const rowLetter = SeatMapValue.getRowLetter(i + 1)
      for (let j = 0; j < columns; j++) {
        this.addValueUNSAFE(rowLetter, j + 1)
      }
    }
  }

  private removeByIndex(rowLetter: string, columnIndex: number) {
    if (columnIndex >= 0) {
      const rowValues = this.seatMap[rowLetter]
      if (rowValues) {
        rowValues.splice(columnIndex, 1)
        if (rowValues.length === 0) {
          delete this.seatMap[rowLetter]
        }
      }
    }
  }

  private addValueUNSAFE = (rowLetter: string, value: number) => {
    const rowValues = this.seatMap[rowLetter]
    if (rowValues) {
      rowValues.push(value)
    } else {
      this.seatMap[rowLetter] = [value]
    }
  }
}
