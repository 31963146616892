import {FormikContextType} from 'formik'
import {useEffect, useMemo, useRef} from 'react'
import {FormikProps} from '../Login'
import {PasswordInput} from '../../../../components/inputs/PasswordInput'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'

interface PasswordFieldProps {
  formik: FormikContextType<FormikProps>
}
export const PasswordField = ({formik}: PasswordFieldProps) => {
  const passwordInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }
  }, [])

  return useMemo(() => {
    return (
      <>
      <div className='my-10'>
       <div className='d-flex align-items-center'>
          <MetronicIcon
            iconType='General'
            iconName='User'
            color='primary'
            size='md'
            className='border rounded p-3 square-icon'
          />

          <div className='d-flex flex-column mt-1 ms-4'>
            <div className='d-flex flex-column'>
              <label className='form-label fs-6 fw-bolder text-white'>Username </label>
              <label className='form-label fs-6 text-primary'>{formik.values.username}</label>
            </div>
          </div>
        </div>

        {formik.values.accountCode && (
          <div className='d-flex align-items-center mt-5'>
            <MetronicIcon
              iconType='General'
              iconName='Lock'
              color='danger'
              size='md'
              className='border rounded p-3 square-icon'
            />

            <div className='d-flex flex-column mt-1 ms-4'>
              <div className='d-flex flex-column'>
                <label className='form-label fs-6 fw-bolder text-white'>Account Code </label>
                <label
                  style={{letterSpacing: '15px'}}
                  className='form-label fs-6 text-danger text-uppercase'
                >
                  {formik.values.accountCode}
                </label>
              </div>
            </div>
          </div>
        )}

      </div>

        <PasswordInput
          ref={passwordInputRef}
          label='Password'
          forgotPasswordLink='/auth/forgot-password'
          placeholder=' Enter Password'
          errorMessage={formik.errors.password}
          isTouched={formik.touched.password}
          {...formik.getFieldProps('password')}
        />
      </>
    )
  }, [formik])
}
