import clsx from 'clsx'
import moment from 'moment'
import {Badge} from '../../../../components/badge/Badge'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'

interface Props<T> {
  goTo: (data: T) => void
  data: T
  title: string
  location?: string
  description?: string
  isDate?: boolean
  isRoute?: boolean
}

interface HasTimeStamp {
  startedAt?: string
  endedAt?: string
  code: string
}

const NavigationCell = <T extends HasTimeStamp>({
  goTo,
  data,
  title,
  location,
  description,
  isDate = false,
  isRoute,
}: Props<T>) => {
  return (
    <div role='button' onClick={() => goTo(data)}>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          {isDate && (
            <Badge className='mb-3' variant='primary'>
              {getTime(data)}
            </Badge>
          )}
          <h4 className={clsx(isRoute ? 'text-primary' : '')}>{title}</h4>
          {description && <p className={clsx('text-secondary mt-2 mb-0')}>{description}</p>}
          {location && (
            <p className='mb-3 d-inline-flex align-contents-center'>
              <MetronicIcon className='me-1 svg-icon-primary' iconType='Map' iconName='Marker1' />
              {location}
            </p>
          )}
        </div>
        <MetronicIcon iconType='Navigation' iconName='Angle-right' size='lg' />
      </div>
    </div>
  )
}

export default NavigationCell

const getTime = <T extends HasTimeStamp>(data: T) => {
  return `${moment(data.startedAt).format('LT')} - ${moment(data.endedAt).format('LT')}`
}
