import {ChangeEvent, useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {useInfiniteScrollContainer} from '../../../../components/hooks/useInfiniteScrollContainer'
import {TextInput} from '../../../../components/inputs'
import {CustomerModel} from '../../../../models/CustomerModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {CustomerList} from '../../components/CustomerList/CustomerList'
import {GetCustomerList} from '../../redux/EvaCRUD'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'
import {useOnMount} from '../../../../components/hooks/useOnMount'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {EVENT_CODE} from '../../../../../config/env'

export const AttendeesPage = () => {
  const history = useHistory()
  const {pushError} = useAlerts()
  const searchDebounce = useDebounce(500)
  const {managePromise} = usePromiseManager()
  const [search, setSearch] = useState('')
  const [customerSearchResult, setCustomerSearchResult] =
    useState<GlobalSearchModel<CustomerModel>>()

  const currentUser = useRootStateSelector((state) => state.eva?.user)
  const refreshSearch = useCallback(
    async (filters) => {
      try {
        const {data} = await managePromise(
          'search',
          GetCustomerList({
            ...filters,
            filters: {
              ...filters.filters,
              currentUser: currentUser?.data.code,
            },
            eventCode: EVENT_CODE,
          })
        )
        setCustomerSearchResult(data)
        return data.data
      } catch (e) {
        if (e instanceof CancelledPromiseError) {
          // Ignore cancelled promises
        } else {
          pushError(e)
        }
      }
    },
    [currentUser?.data.code, managePromise, pushError]
  )

  const {handleOnScroll, items, setAdvancedFilters, refresh} = useInfiniteScrollContainer<
    HTMLDivElement,
    CustomerModel
  >({
    total: customerSearchResult?.total,
    onFetch: refreshSearch,
  })

  const handleItemClick = useCallback(
    (user: CustomerModel) => {
      history.push(`/attendees/${user.code}`, {
        from: '/attendees',
      })
    },
    [history]
  )

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      searchDebounce(() => {
        setAdvancedFilters({
          search: value,
          currentUser: currentUser?.data.code,
        })
      })
      setSearch(value)
    },
    [currentUser?.data.code, searchDebounce, setAdvancedFilters]
  )

  useOnMount(() => {
    refresh()
  })

  return (
    <div className='h-100 d-flex flex-column'>
      <TextInput
        startAdornment={
          <span className='input-group-text'>
            <MetronicIcon iconType='General' iconName='Search' size='md' />
            Search
          </span>
        }
        className='rounded-0 border'
        noMargin
        id='search-input'
        value={search}
        onChange={handleSearchChange}
      />
      <CustomerList
        className='overflow-auto p-5'
        customers={items}
        onClick={handleItemClick}
        onScroll={handleOnScroll}
      />
    </div>
  )
}
