import currency from 'currency.js'
import {PriceTag} from './PriceTag'

export class VatInclusivePriceTag extends PriceTag {
  public getVatInclusivePrice(multiplier: number = 1) {
    return currency(this.rawPrice).multiply(multiplier).value
  }
  public getVatExclusivePrice(multiplier: number = 1) {
    return currency(currency(this.rawPrice).subtract(this.getVatPrice())).multiply(multiplier).value
  }
  public getVatPrice(multiplier: number = 1) {
    return currency(
      currency(this.rawPrice).subtract(
        currency(this.rawPrice).divide(currency(1 + this.percentVat))
      )
    ).multiply(multiplier).value
  }
}
