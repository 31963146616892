import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {CustomerModel} from '../../../../models/CustomerModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {FilterModel} from '../../../../models/FilterModel'
import {FromProp} from '../../components/tables/BookingProductTable/CustomerProductBookingDetailTable'
import {VoucherTable} from '../../components/tables/VoucherTable/VoucherTable'
import {VoucherCardGrid} from '../../components/VoucherCard.tsx/VoucherCardGrid'
import {actions} from '../../redux/CustomerPortalRedux'
interface VoucherPageProps {
  event?: EventModel
  bookingProductCode?: string
  toPath?: FromProp
  customer?: CustomerModel
}
export const VouchersPage = ({event, bookingProductCode, toPath, customer}: VoucherPageProps) => {
  const vouchers = useRootStateSelector((state) => state.customerPortal.vouchers)

  const {setFilter} = useEntityFilter('customer-portal-voucher')
  const dispatch = useDispatch()
  const searchDebounce = useDebounce(200)

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.vouchers.search())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      const newFilter = {...filter}
      setFilter(newFilter)
      refresh()
    },
    [setFilter, refresh]
  )

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active'],
        eventCode: event?.code,
        bookingProductCode,
        customer: customer?.code,
      },
    }
    return filters
  }, [bookingProductCode, customer?.code, event?.code])

  return (
    <>
      <VoucherCardGrid
        className='d-md-none'
        onFilter={handleFilter}
        data={vouchers}
        onRefresh={refresh}
      />
      <VoucherTable
        className='d-none d-md-block'
        data={vouchers}
        onFilter={handleFilter}
        onRefresh={refresh}
        initialFilters={initialFilters}
      />
    </>
  )
}
