import clsx from 'clsx'
import {CSSProperties, forwardRef, ReactNode, useMemo} from 'react'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export interface TextInputProps extends InputProps {
  label?: ReactNode
  errorMessage?: string
  isShowErrorMessage?: boolean
  errorMessageClassName?: string
  isTouched?: boolean
  noMargin?: boolean
  fullWidth?: boolean
  inputClassName?: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  inputWrapperStyle?: CSSProperties
  inputStyle?: CSSProperties
  inputWrapperClassName?: string
  labelClassName?: string
  isReq?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      label,
      id,
      errorMessage,
      isShowErrorMessage = true,
      isTouched,
      noMargin,
      fullWidth,
      inputClassName,
      startAdornment,
      endAdornment,
      inputWrapperStyle,
      inputWrapperClassName,
      labelClassName,
      errorMessageClassName,
      inputStyle,
      isReq,
      ...inputProps
    },
    ref
  ) => {
    const validationClassName = useMemo(() => {
      if (!isTouched) {
        return null
      }
      return errorMessage ? 'is-invalid' : 'is-valid'
    }, [errorMessage, isTouched])

    const endAdornmentNode = useMemo(() => {
      if (typeof endAdornment === 'string') {
        return (
          <div className='input-group-append'>
            <span className='input-group-text' id='basic-addon2'>
              {endAdornment}
            </span>
          </div>
        )
      }
      return (
        <div className='input-group-append d-flex flex-column justify-content-center pe-2'>
          {endAdornment}
        </div>
      )
    }, [endAdornment])

    const startAdornmentNode = useMemo(() => {
      if (typeof startAdornment === 'string') {
        return (
          <div className='input-group-append'>
            <span className='input-group-text' id='basic-addon2'>
              {startAdornment}
            </span>
          </div>
        )
      }
      return <div className='input-group-append'>{startAdornment}</div>
    }, [startAdornment])

    return (
      <div
        className={clsx(
          {
            'mb-3': !noMargin,
            'w-100': fullWidth,
          },
          className
        )}
      >
        <div>
          {label && (
            <label className={clsx('form-label w-100', labelClassName)} htmlFor={id}>
              {label} {isReq && <span style={{color: 'red'}}>*</span>}
            </label>
          )}
        </div>
        <div
          className={clsx(
            {
              'input-group input-group-solid': Boolean(startAdornment || endAdornment),
            },
            inputWrapperClassName
          )}
          style={inputWrapperStyle}
        >
          {startAdornmentNode}
          <input
            className={clsx('form-control form-control-solid', validationClassName, inputClassName)}
            id={id}
            ref={ref}
            {...inputProps}
            style={inputStyle}
          />
          {endAdornmentNode}
        </div>
        {isShowErrorMessage && isTouched && errorMessage && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>
                {errorMessage}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
)
