export class TreeSelectNode {
  constructor(
    public readonly id: string,
    public readonly label: string,
    public selected: boolean,
    public readonly children: TreeSelectNode[]
  ) {}

  public static recursiveEach(
    nodes: TreeSelectNode[],
    callback: (node: TreeSelectNode, parent: TreeSelectNode | null) => void,
    parent: TreeSelectNode | null = null
  ) {
    nodes.forEach((node) => {
      callback(node, parent)
      if (node.children) {
        TreeSelectNode.recursiveEach(node.children, callback, node)
      }
    })
  }

  public static recursiveFind(
    nodes: TreeSelectNode[],
    callback: (node: TreeSelectNode, parent: TreeSelectNode | null) => boolean,
    parent: TreeSelectNode | null = null
  ): TreeSelectNode | undefined {
    let found: TreeSelectNode | undefined = undefined
    nodes.some((node): boolean => {
      const match = callback(node, parent)
      if (match) {
        found = node
        return match
      }

      if (node.children) {
        found = TreeSelectNode.recursiveFind(node.children, callback, node)
        if (found) {
          return true
        }
      }
      return false
    })
    return found
  }

  public isIndeterminate() {
    return !this.selected && this.hasChildrenSelected()
  }

  public setValueRecursively(value: boolean) {
    this.selected = value
    TreeSelectNode.recursiveEach(this.children, (node) => {
      node.setValueRecursively(value)
    })
  }

  public setValue(value: boolean) {
    this.selected = value
  }

  public hasChildrenSelected() {
    const found = TreeSelectNode.recursiveFind(this.children, (node) => {
      return node.selected === true
    })
    return Boolean(found)
  }

  public hasAllChildrenSelected() {
    const found = TreeSelectNode.recursiveFind(this.children, (node) => {
      return node.selected === false
    })
    return !Boolean(found)
  }

  public clone(): TreeSelectNode {
    return new TreeSelectNode(
      this.id,
      this.label,
      this.selected,
      this.children.map((node) => node.clone())
    )
  }
}
