import {Button} from '../../../../components/inputs/Button'
import {SweetAlert} from '../../../../components/modals/SweetAlert'

export interface YasPaymentSuccessModalProps {
  open: boolean
  onClose: () => void
  text?: string
}

export const YasPaymentSuccessModal = ({onClose, open, text}: YasPaymentSuccessModalProps) => {
  return (
    <SweetAlert open={open} onClose={onClose} icon='success' showConfirmButton={false}>
      <h1>Successful</h1>
      <p>{text}</p>
      <Button className='rounded-0' onClick={onClose} variant='success'>
        OK
      </Button>
    </SweetAlert>
  )
}
