import {Link} from 'react-router-dom'
import {CustomerModel} from '../../../../models/CustomerModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {FromProp} from '../../../customer-delegate/components/tables/BookingProductTable/CustomerProductBookingDetailTable'

interface TicketLocationCodeColumnProps {
  data: TicketPortalModel
  event?: EventModel
  customer?: CustomerModel
  toPath?: FromProp
}
export const TicketLocationCodeColumn = ({
  data,
  event,
  toPath,
  customer,
}: TicketLocationCodeColumnProps) => {
  if (event) {
    return (
      <Link
        className='min-w-80px'
        to={`/events/${event.code}/${toPath}/customer/${data.customerCode}/booking/${data.bookingCode}/booking-product/${data.bookingProductCode}/ticket/${data.code}/seat`}
      >
        {data.locationName}
      </Link>
    )
  }

  if (customer) {
    return (
      <Link
        className='min-w-80px'
        to={`/customer/${data.customerCode}/booking/${data.bookingCode}/booking-product/${data.bookingProductCode}/ticket/${data.code}/seat`}
      >
        {data.locationName}
      </Link>
    )
  }

  return <p>{data.locationName}</p>
}
