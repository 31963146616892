import clsx from 'clsx'
import {useCallback} from 'react'
import {SelectInputItem} from '../../../components/inputs/SelectInput'

export interface TabProps extends SelectInputItem {
  className?: string
  onClick: (value: string) => void
  isActive?: boolean
}

export const Tab = ({label, value, onClick, className, isActive}: TabProps) => {
  const getClassName = useCallback(
    (isActive?: boolean) => {
      const baseClassName = 'nav-link h-100 m-0 px-5 text-active-primary text-nowrap rounded-0'
      return clsx(baseClassName, {'bg-primary text-white': isActive}, className)
    },
    [className]
  )

  const handleClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return (
    <li className='nav-item h-100'>
      <button type='button' role='tab' className={getClassName(isActive)} onClick={handleClick}>
        {label}
      </button>
    </li>
  )
}
