import {useCallback, useEffect, useRef, useState} from 'react'
import {CHAT_SOCKET_NAME, ORGANIZATION_CODE} from '../../../../config/env'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {useWebSocket} from '../../../components/utils/WebSocketProvider'
import {SocketChatModel} from '../../../models/eva/ChatModel'
import {DateUtil} from '../../../utils/DateUtil'
import {MarkUserChatsAsRead} from '../redux/EvaCRUD'

export interface UseChatOptions {
  targetUserCode?: string
  onMessageReceive?: (data: SocketChatModel) => void
  autoRead?: boolean
}

export const useChat = ({targetUserCode, onMessageReceive, autoRead}: UseChatOptions) => {
  const [chatHistory, setChatHistory] = useState<SocketChatModel[]>([])
  const currentUser = useRootStateSelector((state) => state.eva.user)
  const markChatAsReadDebounce = useDebounce(1000)
  const messageReceiveFuncRef = useRef(onMessageReceive)
  const {socket, isConnected} = useWebSocket()

  const appendChatData = useCallback((data: SocketChatModel[]) => {
    setChatHistory((chatHistory) => {
      return [...chatHistory, ...data]
    })
  }, [])

  const prependChatData = useCallback((data: SocketChatModel[]) => {
    setChatHistory((chatHistory) => {
      return [...data, ...chatHistory]
    })
  }, [])

  const sendChat = useCallback(
    (message: string) => {
      const {CHAT_SOCKET_NAME, ORGANIZATION_CODE} = getChatEnvironment()
      if (currentUser && targetUserCode) {
        const chatData: SocketChatModel = {
          message,
          userName: currentUser.data.name,
          senderCode: currentUser.data.code,
          receiverCode: targetUserCode,
          time: DateUtil.convertDateToApiString(new Date()),
          organizationCode: ORGANIZATION_CODE,
        }
        prependChatData([chatData])
        socket?.emit(CHAT_SOCKET_NAME, chatData)
      }
    },
    [currentUser, prependChatData, socket, targetUserCode]
  )

  const handleReceiveChat = useCallback(
    (chat: SocketChatModel) => {
      if (autoRead) {
        markChatAsReadDebounce(() => {
          MarkUserChatsAsRead(chat.receiverCode, chat.senderCode)
        })
      }
      prependChatData([chat])
      messageReceiveFuncRef.current?.(chat)
    },
    [autoRead, markChatAsReadDebounce, prependChatData]
  )

  useEffect(() => {
    setChatHistory([])
  }, [targetUserCode])

  useEffect(() => {
    if (CHAT_SOCKET_NAME && currentUser) {
      const eventName = `${CHAT_SOCKET_NAME}:${currentUser.data.code}`
      socket?.on(eventName, handleReceiveChat)
      return () => {
        socket?.off(eventName, handleReceiveChat)
      }
    }
  }, [currentUser, handleReceiveChat, socket])

  useEffect(() => {
    messageReceiveFuncRef.current = onMessageReceive
  }, [onMessageReceive])

  return {sendChat, chatHistory, appendChatData, prependChatData, isConnected}
}

const getChatEnvironment = () => {
  if (!CHAT_SOCKET_NAME) {
    throw new Error('CHAT_SOCKET_NAME is not defined')
  }
  if (!ORGANIZATION_CODE) {
    throw new Error('ORGANIZTION_CODE is not defined')
  }
  return {CHAT_SOCKET_NAME, ORGANIZATION_CODE}
}
