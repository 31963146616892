import type {FormikContextType} from 'formik'
import {useMemo, useCallback, useState, ChangeEvent, useEffect} from 'react'
import * as Yup from 'yup'
import {
  DrawerFormContainerProps,
  DrawerFormContainer,
} from '../../../../../components/Drawer/DrawerFormContainer'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ProductInput} from '../../../../../components/inputs/ProductInput/ProductInput'
import {
  ProductInputItemValue,
  ProductInputItemAttributes,
} from '../../../../../components/inputs/ProductInput/ProductInputItem'
import {ListCountInputItemValue} from '../../../../../components/inputs/VoucherInput/ListCountInputItem'
import {FilterSearchableSelectInput} from '../../../../../components/inputs/SearchableSelect/FilterSearchableSelectInput'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {
  ListCountInput,
  ListCountInputRefValue,
} from '../../../../../components/inputs/VoucherInput/ListCountInput'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ProductModel} from '../../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {selectItemMapper} from '../../../../../utils/idExtractor'
import {
  GetCustomerListFlatten,
  GetEvents,
  GetProductsBookingByEvent,
  GetVoucherTemplateList,
} from '../../../redux/CustomerDelegateCRUD'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {SelectTreeOptionGroup} from '../../../../../components/inputs/SelectTreeNative/SelectTreeOptionGroup'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {Button} from '../../../../../components/inputs/Button'

export interface BookingPortalFormValues {
  eventCode: string
  customer: CustomerModel | null
  products: ProductInputItemValue[]
  vouchers: ListCountInputItemValue<VoucherModel>[]
}

export const roleFormValidationSchema = Yup.object().shape({
  customer: Yup.object().required(),
  products: Yup.array().test(
    'has-atleast-one',
    'Please add atlease one product',
    (value, parent) => {
      if (
        (value && value.length > 0) ||
        (parent.parent.vouchers.length > 0 &&
          !(parent.parent.vouchers.length === 1 && parent.parent.vouchers[0].value === undefined))
      ) {
        return true
      } else return false
    }
  ),
  vouchers: Yup.array().test(
    'has-atleast-one',
    'Please add atlease one voucher',
    (value, parent) => {
      if (
        (value && value.length > 0) ||
        (parent.parent.products.length > 0 &&
          !(parent.parent.products.length === 1 && parent.parent.products[0].value === undefined))
      ) {
        return true
      } else return false
    }
  ),
})

export interface BookingPortalFormProps extends Omit<DrawerFormContainerProps, 'isSubmitDisabled'> {
  formik: FormikContextType<BookingPortalFormValues>
  disabledFields?: Record<string, boolean>
  event?: EventModel
  customer?: CustomerModel
  isOpen: boolean
}

export const BookingPortalForm = ({
  formik,
  event,
  isOpen,
  customer,
  disabledFields,
  ...formProps
}: BookingPortalFormProps) => {
  const [products, setProducts] = useState<ProductModel[]>([])
  const [voucherSearchResults, setVoucherSearchResults] =
    useState<GlobalSearchModel<VoucherModel>>()
  const [customers, setCustomers] = useState<GlobalSearchModel<CustomerModel>>()
  const [allEvents, setAllEvents] = useState<EventModel[]>([])
  const {pushError} = useAlerts()
  const [voucherInputRef, setVoucherInputRef] = useState<ListCountInputRefValue | null>(null)

  const getAllEvents = useCallback(async () => {
    const {data} = await GetEvents()
    setAllEvents(data.data)
  }, [])

  useOnChange(event, () => {
    if (event) formik.setFieldValue('eventCode', event.code)
  })

  const eventCode = useMemo(
    () => formik.values.eventCode || event?.code,
    [event?.code, formik.values.eventCode]
  )

  const getProducts = useCallback(async () => {
    if (eventCode) {
      try {
        const {data} = await GetProductsBookingByEvent(eventCode)
        if (data) setProducts(data)
      } catch (err) {
        setProducts([])
        pushError(err)
      }
    }
  }, [eventCode, pushError])

  const getVouchers = useCallback(
    async (filter?: FilterModel) => {
      if (eventCode) {
        try {
          const {data} = await GetVoucherTemplateList({
            ...filter,
            filters: {
              ...filter?.filters,
              event: eventCode,
            },
          })
          setVoucherSearchResults(data)
        } catch (err) {
          pushError(err)
        }
      }
    },
    [eventCode, pushError]
  )

  useEffect(() => {
    if (formik.values.eventCode && isOpen) {
      getProducts()
      getVouchers()
    }
  }, [formik.values.eventCode, getProducts, getVouchers, isOpen])

  const getCustomers = useCallback(
    async (filters?: FilterModel) => {
      try {
        const {data} = await GetCustomerListFlatten({
          filters: {
            status: ['active', 'pending'],
            search: filters?.filters?.search,
            type: ['customer'],
          },
        })
        if (Array.isArray(data.data)) setCustomers(data as GlobalSearchModel<CustomerModel>)
      } catch (err) {
        pushError(err)
      }
    },
    [pushError]
  )

  useOnChange(customer, async () => {
    if (customer) {
      formik.setFieldValue('customer', {
        label: customer.name,
        value: customer.code,
      })
    }
  })

  useOnChange(isOpen, () => {
    if (isOpen) {
      if (!event) getAllEvents()
    } else {
      setAllEvents([])
      setCustomers(undefined)
      setProducts([])
      setVoucherSearchResults(undefined)
    }
  })

  const productInputItems = useMemo((): ProductInputItemAttributes[] => {
    return products.map((product) => {
      return {
        label: product.name,
        value: product.code,
        type: 'product',
      }
    })
  }, [products])

  const eventInputItems = useMemo((): SelectInputItem[] => {
    return allEvents.map((event) => {
      return {
        label: event.name,
        value: event.code,
      }
    })
  }, [allEvents])

  const handleOnChangeProduct = useCallback(
    (newValues: ProductInputItemValue[]) => {
      formik.setFieldValue('products', newValues)
    },
    [formik]
  )

  const handleOnChangeVoucher = useCallback(
    (newValues: ListCountInputItemValue<VoucherModel>[]) => {
      formik.setFieldValue('vouchers', newValues)
    },
    [formik]
  )

  const handleCustomerChange = useCallback(
    (value: CustomerModel | null) => {
      formik.setFieldValue('customer', value)
    },
    [formik]
  )

  const handleEventCodeOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value
      formik.setFieldValue('eventCode', value)
      formik.setFieldValue('products', [])
      formik.setFieldValue('vouchers', [])
    },
    [formik]
  )
  const hasProductsAvailable = useMemo(() => {
    return products.length > 0
  }, [products])

  const hasVoucherAvailable = useMemo(() => {
    return voucherSearchResults && voucherSearchResults?.data.length > 0
  }, [voucherSearchResults])

  const isCustomerDisabled = useMemo(() => {
    return customer?.code !== null && customer?.code !== undefined
  }, [customer?.code])

  return (
    <DrawerFormContainer
      isSubmitDisabled={formik.isSubmitting || !formik.isValid}
      onSubmit={formik.handleSubmit}
      {...formProps}
    >
      {!event && (
        <SelectTreeOptionGroup
          label='Event'
          placeholder='Select an Activity or an Event'
          items={[{label: 'Events', items: eventInputItems}]}
          disabled={disabledFields?.eventCode}
          {...formik.getFieldProps('eventCode')}
          onChange={handleEventCodeOnChange}
        />
      )}

      <FilterSearchableSelectInput
        label='Customer'
        placeholder='Select Customer'
        itemMapper={selectItemMapper}
        searchResult={customers}
        onFilter={getCustomers}
        disabled={isCustomerDisabled}
        value={formik.values.customer}
        onChange={handleCustomerChange}
      />
      <ProductInput
        disabled={!hasProductsAvailable}
        items={productInputItems}
        label='Product'
        onChange={handleOnChangeProduct}
        values={formik.values.products}
      />
      <div className='mt-5'>
        <ListCountInput
          selectPlaceholder='Select a voucher'
          searchResult={voucherSearchResults}
          label='Voucher'
          onChange={handleOnChangeVoucher}
          values={formik.values.vouchers}
          onSearch={getVouchers}
          itemMapper={selectItemMapper}
          listRef={setVoucherInputRef}
        />
        <Button
          size='sm'
          variant='primary'
          type='button'
          onClick={voucherInputRef?.addEmptyItem}
          disabled={voucherInputRef?.hasBlankItem || !hasVoucherAvailable}
          uppercase={false}
        >
          Add Voucher
        </Button>
      </div>
    </DrawerFormContainer>
  )
}
