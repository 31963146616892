import {ColorVariant} from '../../../../components/inputs/Button'

export interface OrderSummaryItemAttributes {
  name: string
  count: number
  price: number
}
export interface OrderSummaryItemProps extends OrderSummaryItemAttributes {
  variant: ColorVariant
}

export const OrderSummaryItem = ({name, count, price, variant}: OrderSummaryItemProps) => {
  return (
    <tr>
      <td>
        <span className={`text-${variant} me-3 fw-bolder text-nowrap`}>{count}×</span>
      </td>
      <td className='w-100'>
        <span>{name}</span>
      </td>
      <td>
        <span className='text-nowrap'>{price}</span>
      </td>
    </tr>
  )
}
