export const ActiveWaiverDescription = () => {
  return (
    <div className='text-white'>
      <p>
        Welcome to the E-Ticket Distribution & Waiver Portal for Yas Marina Circuit - Home of the
        Formula 1® Etihad Airways Abu Dhabi Grand Prix. Here, you can view & manage your active
        reservations, complete and submit your waiver electronically, and share tickets with
        respective guests.
      </p>
      <div>
        <ul>
          <li>
            Can’t view your ticket? Enter your Ticket ID Number & Ticket Reservation Number in the
            box below
          </li>
        </ul>
      </div>

      <p className='ff-admm-bold'>IMPORTANT INFORMATION:</p>

      <div>
        <ul>
          <li>
            If you have booked the experience for someone else, please share tickets with respective
            participants using  this portal.
          </li>
          <li>
            All participants must complete waiver submissions at least 72 hours prior to arrival.
          </li>
          <li>Monitor your email regarding the waiver approval / rejected updates.</li>
          <li>
            For DriveYas, Drivers between 19 & 25 years of age: you must hold a valid driver’s
            license for 2 or more years prior to the date of your experience (Provisional License
            dates are not Included)
          </li>
          <li>
            Age group below 21 must assign their waiver to guardian from the waiver submission page.
          </li>
        </ul>
      </div>
    </div>
  )
}
