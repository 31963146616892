import {useCallback, useMemo} from 'react'
import {GlobalSearchModel, GroupedSearchModel} from '../../../models/GlobalSearchModel'
import {difference, remove} from 'lodash'

interface hasCode {
  code: string
}
interface useWaiverCardUtilsProps<T1 extends hasCode> {
  data?: GlobalSearchModel<T1> | GroupedSearchModel<T1>
  selected: string[]
  setSelected?: (values: string[]) => void
}
export const useWaiverCardUtils = <T1 extends hasCode>({
  data,
  selected,
  setSelected,
}: useWaiverCardUtilsProps<T1>) => {
  const dataLength = useMemo(() => {
    if (data)
      if (Array.isArray(data.data)) {
        return data.data.length
      } else {
        return Object.values(data.data).reduce((acc, group) => {
          acc += group.length
          return acc
        }, 0)
      }
  }, [data])

  const selectionAllState = useMemo(() => {
    if (dataLength) {
      if (dataLength !== undefined && selected.length === dataLength) {
        return true
      }
      return null
    }
    if (selected === undefined) {
      return false
    }
    return false
  }, [selected, dataLength])

  const tableSelectionItems = useMemo(() => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      }
      return Object.values(data.data).reduce<T1[]>((acc, group) => {
        acc.push(...group)
        return acc
      }, [])
    }
    return []
  }, [data])

  const onSelectHandler = useCallback(
    (id: string) => {
      const index = selected.indexOf(id)
      const newValue = [...selected]
      if (index >= 0) {
        newValue.splice(index, 1)
        setSelected && setSelected(newValue)
      } else {
        newValue.push(id)
        setSelected && setSelected(newValue)
      }
    },
    [selected, setSelected]
  )

  const onSelectGroupHandler = useCallback(
    (key: string, cardsData: T1[]) => {
      if (cardsData) {
        const result = cardsData.map((item) => item.code)
        const newValue = [...selected]
        const allExist = difference(result, selected).length === 0
        if (allExist) {
          remove(newValue, function (el) {
            return result.indexOf(el) >= 0
          })
          setSelected && setSelected(newValue)
        } else {
          if (data && !Array.isArray(data.data)) {
            const groupedSelected = Object.keys(data.data).find((item) => {
              const keyValue = item.match(BATCH_ID_REGEX) ? item.match(BATCH_ID_REGEX)?.[1] : item
              return keyValue === key
            })
            if (groupedSelected) {
              const result = data.data[groupedSelected]

              result.forEach((item) => {
                const index = selected.indexOf(item.code)
                if (index >= 0) {
                  newValue.splice(index, 1)
                  newValue.push(item.code)
                } else {
                  newValue.push(item.code)
                }
              })
              setSelected && setSelected(newValue)
            }
          }
        }
      }
    },
    [data, selected, setSelected]
  )

  const handleSelectAll = useCallback(() => {
    if (data)
      if (selectionAllState === true) {
        setSelected && setSelected([])
      } else if (Array.isArray(data.data)) {
        setSelected && setSelected(data.data.map((item) => item.code))
      } else if (!Array.isArray(data.data)) {
        const groupedSelected = Object.values(data.data).reduce<string[]>((acc, group) => {
          group.forEach((item) => acc.push(item.code))

          return acc
        }, [])

        setSelected && setSelected(groupedSelected)
      }
  }, [data, selectionAllState, setSelected])

  return useMemo(
    () => ({
      dataLength,
      selectionAllState,
      tableSelectionItems,
      onSelectHandler,
      onSelectGroupHandler,
      handleSelectAll,
    }),
    [
      dataLength,
      onSelectGroupHandler,
      onSelectHandler,
      selectionAllState,
      tableSelectionItems,
      handleSelectAll,
    ]
  )
}

const BATCH_ID_REGEX = /(.+) \(.+\)/
