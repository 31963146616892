import {useTooltip, useTooltipInPortal} from '@visx/tooltip'
import {useCallback, useMemo, useRef} from 'react'
import {useDefaultColorScalePreset} from '../hooks/useDefaultColorScalePreset'
import {BarChartDatum} from './BarChartDatum'

export interface UseBarChartHelpersOptions {
  datum: BarChartDatum[]
}

export const useBarChartHelpers = ({datum}: UseBarChartHelpersOptions) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const domain = useMemo(() => datum.map((d) => d.key), [datum])

  const getBarColor = useDefaultColorScalePreset({domain})

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  const handleBarSegmentMouseMove = useCallback(
    (datum: BarChartDatum) => {
      showTooltip({
        tooltipData: String(datum.key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  return {
    handleBarSegmentMouseMove,
    handleContainerMouseMove,
    getBarColor,
    containerRef,
    TooltipInPortal,
    tooltipData,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
  }
}
