import {useCallback} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import {DetailHeaderTabProps} from './DetailHeaderTab'

export const useTabItemHelper = () => {
  const location = useLocation()
  const checkItemIsActive = useCallback(
    (item: DetailHeaderTabProps): boolean => {
      if (item.active) {
        return true
      }

      if (item.link) {
        const isActive = Boolean(
          matchPath(location.pathname, {
            path: item.link.to,
            exact: item.link.exact,
          })
        )
        if (isActive) {
          return true
        }
      }
      if (item.items) {
        return item.items.some((item) => checkItemIsActive(item))
      }
      return false
    },
    [location.pathname]
  )
  return {checkItemIsActive}
}
