import axios from 'axios'
import {FilterModel} from '../../../models/FilterModel'
import {AuthModel} from '../../../models/fnb/AuthModel'
import {OrderModel, OrderModelCreateParams, OrderModelStatus} from '../../../models/fnb/OrderModel'
import {OutletModel, OutletModelCreateParams} from '../../../models/fnb/OutletModel'
import {
  OutletProductCategoryModel,
  OutletProductCategoryModelCreateParams,
} from '../../../models/outlet/ProductCategoryModel'
import {ProductModel} from '../../../models/fnb/ProductModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {UserModel} from '../../../models/UserModel'
import {OutletProductModelCreateFormDataParams} from '../../../models/outlet/ProductModel'
import {OutletUserModelCreateParams} from '../../../models/outlet/UserModel'
import {
  ReservationModel,
  ReservationModelCreateParams,
  ReservationModelStatus,
} from '../../../models/outlet/ReservationModel'
import {PaymentModelCreateParams} from '../../../models/outlet/PaymentModel'
import {OutletLogModel} from '../../../models/fnb/OutletLogModel'
import {BatchOrderModel} from '../../../models/fnb/BatchOrderModel'

export const GetOutletAuthUsers = () => axios.get<UserModel[]>('/fnb-auth/users')
export const VerifyOutletAuthToken = () =>
  axios.get<{user: AuthModel; token: string}>('/fnb-auth/verify-token')
export const OutletAuthLogin = (username: string, pinOrPassword: string) =>
  axios.post<{user: AuthModel; token: string}>('/fnb-auth/login', {
    username,
    password: pinOrPassword,
  })
export const ResetAuthCredentials = (options: {password?: string; pin?: string}) =>
  axios.post<{user: AuthModel; token: string}>('/fnb-auth/reset', options)
export const RequestForgottenPassword = (email: string) =>
  axios.post(`/fnb-auth/forgot-password`, {email})

export const GetProductCategories = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<OutletProductCategoryModel>>('/fnb-product-category/list', filter)
export const GetProductCategoryByCode = (code: string) => axios.get(`/fnb-product-category/${code}`)
export const PostProductCategory = (values: OutletProductCategoryModelCreateParams) =>
  axios.post('/fnb-product-category', values)
export const PutProductCategory = (code: string, values: OutletProductCategoryModelCreateParams) =>
  axios.put(`/fnb-product-category/${code}`, values)
export const DeleteProductCategories = (codes: string[]) =>
  axios.delete(`/fnb-product-category`, {data: {codes}})

export const GetProductByCode = (code: string) => axios.get<ProductModel>(`/fnb-product/${code}`)
export const GetProducts = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>('/fnb-product/list', filters)
export const PostProduct = (values: OutletProductModelCreateFormDataParams) =>
  axios.post('/fnb-product', values.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutProduct = (code: string, values: OutletProductModelCreateFormDataParams) =>
  axios.put(`/fnb-product/${code}`, values.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const DeleteProducts = (codes: string[]) => axios.delete('/fnb-product', {data: {codes}})

export const GetUserByCode = (code: string) => axios.get(`/fnb-user/${code}`)
export const PutUser = (code: string, values: OutletUserModelCreateParams) =>
  axios.put(`/fnb-user/${code}`, values)
export const DeleteUsers = (codes: string[]) => axios.delete(`/fnb-user`, {data: {codes}})
export const GetUsers = (filter?: FilterModel) => axios.post(`/fnb-user/list`, filter)
export const CreateUser = (value: OutletUserModelCreateParams) => axios.post(`/fnb-user`, value)
export const ResetUserCredentials = (code: string) => axios.post(`/fnb-user/reset`, {code})

export const GetOrderList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<OrderModel>>(`/outlet-order/list`, filter)
export const GetOrders = (filter?: FilterModel) =>
  axios.post<OrderModel[]>(`/outlet-order/get`, filter)
export const GetOrderByCode = (orderCode: string) =>
  axios.get<OrderModel>(`/outlet-order/${orderCode}`)
export const UpdateOrderStatus = (orderCode: string, value: {status: OrderModelStatus}) =>
  axios.put(`/outlet-order/status/${orderCode}`, value)
export const CreateNewOrder = (values: OrderModelCreateParams) =>
  axios.post<OrderModel>(`/outlet-order`, values)
export const PutOrder = (code: string, values: OrderModelCreateParams) =>
  axios.put(`/outlet-order/${code}`, values)
export const PrintOrderBill = (orderCode: string) =>
  axios.get<Blob>(`/outlet-order/print-bill/${orderCode}`, {
    responseType: 'blob',
  })

export const ValidateCurrentOutlet = () => axios.get(`/outlet/validate`)
export const GetCurrentOutlet = () => axios.get<OutletModel>(`/outlet`)
export const GetCurrentOutletMenu = () => axios.get('/outlet/menu')
export const UpdateCurrentOutlet = (values: OutletModelCreateParams) =>
  axios.post(`/outlet/update`, values)

export const SearchTableReservations = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationModel>>(`/table-reservation/list`, filter)
export const CreateTableReservation = (payload: ReservationModelCreateParams) =>
  axios.post<unknown>(`/table-reservation`, payload)
export const UpdateTableReservation = (
  reservationCode: string,
  payload: ReservationModelCreateParams
) => axios.put<unknown>(`/table-reservation/${reservationCode}`, payload)
export const UpdateTableReservationStatus = (
  reservationCode: string,
  params: {status: ReservationModelStatus; reason?: string; tableNo?: number}
) => axios.post(`/table-reservation/${reservationCode}/status`, params)
export const GetReservationByCode = (reservationCode: string) =>
  axios.get(`/table-reservation/${reservationCode}`)

export const PayOrder = (params: PaymentModelCreateParams) => axios.post('/payment', params)

export const SearchOutletLogs = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<OutletLogModel>>(`/log/outlet`, filters)

export const GetOrderBatches = () => axios.get<BatchOrderModel[]>(`/outlet-order/batch`)
export const UpdateOrderBatchStatus = (batchCode: string, status: OrderModelStatus) =>
  axios.put(`/outlet-order/batch/status/${batchCode}`, {
    status,
  })
export const SendOrderSMSConfirmation = (orderCode: string, mobileNumber: string) =>
  axios.post(`/outlet-order/sms`, {
    mobile: mobileNumber,
    orderCode,
  })
