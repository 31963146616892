import axios, {AxiosError, AxiosResponse, ResponseType} from 'axios'
import {LocationModel} from '../../../models/acs/LocationModel'
import {AuthWaiverModel, CustomerUserModel} from '../../../models/digital-waiver/AuthModel'
import {EventModel} from '../../../models/ems/EventModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {FilterModel} from '../../../models/FilterModel'
import {ISOCountryModel} from '../../../models/ISOCountryModel'
import {TicketWaiverModel} from '../../../models/digital-waiver/TicketWaiverModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../models/GlobalSearchModel'
import {ProductCategoryModel} from '../../../models/ems/ProductCategoryModel'
import {TabProps} from '../pages/DigitalWaiverPage'
import {MYPASS_URL, ORGANIZATION_CODE} from '../../../../config/env'
import {MyPassSignUpPayload} from '../hooks/useFormMyPassSignupData'
import {TicketSubmitFormValues} from '../components/waiverForms/TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'
import {InitialValuesProps} from '../components/waiverForms/AddTicketByIdForm'
import {DigitalWaiverModel} from '../../../models/ems/DigitalWaiverModel'

const WAIVER_AUHT_BASE_PATH = '/portal-auth'
const WAIVER_BASE_PATH = '/digital-waiver'
const CUSTOMER_BASE_PATH = '/customer'
const LOCATION_BASE_PATH = '/location'
const EVENT_BASE_PATH = '/event'
const PRODUCT_BASE_PATH = '/product'

export interface EmailTypeParams {
  email: string
  type: string
}

export interface WalletParams {
  walletCode: string
  eventCode: string
  responseType?: ResponseType
}

export interface CustomerCodeParams {
  customerCode: string
}

export interface DelegateTicketProps {
  name?: string
  email?: string
  ticketCodes: string[]
}

interface ShareTicketModel {
  links: [{link: string; name: string}]
}

export interface MyPassParams {
  state: string
  code: string
  id_token: string
}

interface UpdateWaiverTicketProps {
  payload: FormData
  dwCode: string
}

// LOGIN
export const AuthLogin = (username: string, password: string) =>
  axios.post<AuthWaiverModel>(`${WAIVER_AUHT_BASE_PATH}/login`, {username, password})
export const RequestForgottenPassword = (email: string) =>
  axios.post<AuthWaiverModel>(`${WAIVER_AUHT_BASE_PATH}/forgot-password`, {email})
export const VerifyCustomerAuthToken = () =>
  axios.get<AuthWaiverModel>(`${WAIVER_AUHT_BASE_PATH}/verify-token`)
export const ResetPassword = (password: string) =>
  axios.post(`${WAIVER_AUHT_BASE_PATH}/reset`, {password})
export const validateHash = (hash: string) =>
  axios.post(`${WAIVER_AUHT_BASE_PATH}/validate-hash`, {hash})

export const validateMfa = (mfa: string, authToken: string) =>
  axios.post(
    `${WAIVER_AUHT_BASE_PATH}/validate-mfa`,
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

export const loginWithHash = (username: string, password: string, hash: string) =>
  axios.post(`${WAIVER_AUHT_BASE_PATH}/login-hash`, {username, password, hash})

interface GetCustomerByCodeProps {
  code: string
  waiverType: string
}

export const GetCustomerByCode = ({code, waiverType}: GetCustomerByCodeProps) =>
  axios.get<CustomerUserModel>(`${CUSTOMER_BASE_PATH}/${code}/${waiverType}`)

export const GetCustomerProductCatergory = (filter: FilterModel) =>
  axios.post<ProductCategoryModel[]>(`${CUSTOMER_BASE_PATH}/product-categories`, filter)

export const GetTicketProductCategoryTabs = (filter: FilterModel) =>
  axios.post<TabProps[]>(`${WAIVER_BASE_PATH}/delegate/tabs/`, {...filter.filters})

export const GetTicketProductTabs = (filter: FilterModel) =>
  axios.post<TabProps[]>(`${WAIVER_BASE_PATH}/delegate/tabs/`, {...filter.filters})

// WAIVER TICKETS

export const GetTicketList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketWaiverModel>>(`${WAIVER_BASE_PATH}/delegate/tickets`, filter)

export const GetDelegatedTicketList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketWaiverModel>>(
    `${WAIVER_BASE_PATH}/delegate/delegated-tickets`,
    filter
  )

export const GetTicketByCode = (code: string) =>
  axios.get<TicketWaiverModel>(`${WAIVER_BASE_PATH}/delegate/${code}`)

export const GetGuardianTicketByCode = (code: string) =>
  axios.get<TicketWaiverModel>(`${WAIVER_BASE_PATH}/guardian-ticket/${code}`)

export const GetExpiredTickets = (filter?: FilterModel) =>
  axios.post<GroupedSearchModel<TicketWaiverModel>>(`/tickets/expired`, filter)

export const DelegateTicket = (payload: DelegateTicketProps) =>
  axios.post<ShareTicketModel>(`${WAIVER_BASE_PATH}/delegate`, payload)

export const AddTicketByID = (payload: InitialValuesProps) =>
  axios.post(`${WAIVER_BASE_PATH}/delegate/create-by-ticket-id`, payload)

export const SignWaiverTicket = (payload: FormData) =>
  axios.post<TicketSubmitFormValues>(`${WAIVER_BASE_PATH}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const updateWaiverTicket = ({payload, dwCode}: UpdateWaiverTicketProps) =>
  axios.put<TicketSubmitFormValues>(`${WAIVER_BASE_PATH}/${dwCode}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const updateGuardianWaiver = ({payload, dwCode}: UpdateWaiverTicketProps) =>
  axios.put<TicketSubmitFormValues>(`${WAIVER_BASE_PATH}/guardian/${dwCode}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const SignQuickWaiverTicket = (payload: FormData) =>
  axios.post<TicketSubmitFormValues>(`${WAIVER_BASE_PATH}/create-quick-waiver`, payload, {
    headers: {
      'x-organization': ORGANIZATION_CODE,
      'Content-Type': 'multipart/form-data',
    },
  })

export const GetWaiverByCode = (code: string) =>
  axios.get<DigitalWaiverModel>(`${WAIVER_BASE_PATH}/${code}`)

export const DownloadDigitalWaiverPdf = (code: string) =>
  axios.get<Blob>(`${WAIVER_BASE_PATH}/download-pdf/${code}`, {responseType: 'blob'})

export const GetTicketByBatchId = (batchId: string) =>
  axios.post(`${WAIVER_BASE_PATH}/quick-waiver`, {batchId})

export const GetGuardianRejection = (code: string, reason: string) =>
  axios.post(`${WAIVER_BASE_PATH}/guardian-reject/${code}`, {reason})

export const GetEvents = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`${EVENT_BASE_PATH}/list`, filter)

export const GetProductsBySlug = (filter?: FilterModel) =>
  axios.post<ProductModel[]>(`${CUSTOMER_BASE_PATH}${PRODUCT_BASE_PATH}`, filter)

export const GetLocationsBySlug = (filter?: FilterModel) =>
  axios.post<LocationModel[]>(`${CUSTOMER_BASE_PATH}${LOCATION_BASE_PATH}`, filter)

export const GetISOCountries = () => axios.get<ISOCountryModel[]>(`${WAIVER_BASE_PATH}/country`)

// MY PASS
export const MyPassAuth = async (response: MyPassParams) => {
  return await axios.create().post(`${MYPASS_URL}/auth`, response)
}

export const MyPassExistEmail = async (email: string) => {
  return await axios
    .create()
    .get(`${process.env.REACT_APP_MYPASS_EXIST_EMAIL_VALIDATION_URL}=${email}`)
}

export const MyPassSignup = async (response: MyPassSignUpPayload, pagestate: any) => {
  return await axios
    .create()
    .post(
      `${process.env.REACT_APP_IFRAME_SIGNUP}=${MYPASS_URL}/auth&pagestate=${pagestate}`,
      response
    )
}

export const GetCountries = async () => await axios.get(`/country`)

export const GetLastSignedWaiver = async (
  ticketCode: string
): Promise<AxiosResponse<TicketWaiverModel | null>> =>
  await axios
    .get<TicketWaiverModel>(`/digital-waiver/last-signed/${ticketCode}`)
    .catch((e: AxiosError) => {
      if (e.response?.status === 400) {
        const response: AxiosResponse<TicketWaiverModel | null> = e.response
        response.data = null
        return response
      }
      throw e
    })

export const CheckCustomerWaiverValidation = (ticketCode: string, email: string) =>
  axios.post(`${WAIVER_BASE_PATH}/check-customer`, {ticketCode, email})
