import {ReactNode, useCallback, useMemo, useState} from 'react'
import {RatingInputStar} from './RatingInputStar'

export interface RatingInputProps {
  count: number
  onChange: (questionCode: string, value: number) => void
  value?: number | null
  rating?: number
  questionCode?: string
  height?: number
  width?: number
  className?: string
}
export const RatingInput = ({
  count,
  onChange,
  value,
  questionCode,
  rating = 0,
  ...props
}: RatingInputProps) => {
  const [starsValues, setStarsValues] = useState<number[]>(new Array(count).fill(0))
  const handleClickOnStar = useCallback(
    (value) => {
      let newArray: number[] = []
      for (let i = 0; i < count; i++) {
        if (i < value) newArray.push(1)
        else newArray.push(0)
      }
      setStarsValues(newArray)
      if (questionCode) onChange(questionCode, value)
    },
    [count, onChange, questionCode]
  )
  const stars = useMemo(() => {
    const nodes: ReactNode[] = []
    for (let i = 0; i < count; i++) {
      nodes.push(
        <RatingInputStar
          fill={starsValues[i]}
          key={i}
          id={i + 1}
          onClick={handleClickOnStar}
          {...props}
        />
      )
    }

    return nodes
  }, [count, handleClickOnStar, props, starsValues])

  return <div>{stars}</div>
}
