import clsx from 'clsx'
import {useCallback} from 'react'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'

export interface MenuProductAttributes {
  title: string
  description?: string
  price: number
  currency: string
  image: ImageInputValue
  value: string
}

export interface MenuProductProps extends MenuProductAttributes {
  className?: string
  onClick?: (value: string) => void
}

export const MenuProduct = ({
  currency,
  price,
  image,
  description,
  title,
  className,
  onClick,
  value,
}: MenuProductProps) => {
  const handleOnClick = useCallback(() => {
    onClick && onClick(value)
  }, [onClick, value])

  return (
    <button onClick={handleOnClick} className={clsx('menu-product', className)}>
      <div className='product-thumbnail'>
        <img className='img-thumbnail' src={image.url} alt={image.filename} />
      </div>
      <div className='description-container'>
        <div>
          <h3 className='text-muted'>{title}</h3>
          {description && <p className='description'>{description}</p>}
        </div>
        <span className='fw-bolder text-muted'>
          {currency} {price}
        </span>
      </div>
    </button>
  )
}
