import clsx from 'clsx'
import {useCallback} from 'react'
import {
  SeatMapEditorInput,
  SeatMapEditorInputProps,
} from '../../../../components/inputs/SeatMapInput/SeatMapEditorInput'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'

export const SeatMapSelectionPortalInput = ({
  className,
  onSelectedChange,
  disabled,
  selected,
  shared,
  occupied,
  ...props
}: SeatMapEditorInputProps) => {
  const handleSelectSeates = useCallback(
    (value: SeatMapValue) => {
      let finalValue = value
      if (onSelectedChange) {
        if (disabled) {
          finalValue = finalValue.difference(disabled)
        }
        if (occupied) {
          finalValue = finalValue.difference(occupied)
        }
        if (shared) {
          finalValue = finalValue.difference(shared)
        }

        onSelectedChange(finalValue)
      }
    },
    [disabled, occupied, onSelectedChange, shared]
  )

  return (
    <>
      <div className={clsx('seat-map-selection-input', className)}>
        <SeatMapEditorInput
          className='h-100'
          selected={selected}
          onSelectedChange={onSelectedChange && handleSelectSeates}
          disabled={disabled}
          occupied={occupied}
          shared={shared}
          disableRowClick
          maxSelected={1}
          isPortal={true}
          {...props}
        />
      </div>
    </>
  )
}
