import {FormikContextType} from 'formik'
import {useMemo} from 'react'
import {TicketSubmitFormValues} from '../TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'

interface AutoSignCheckBoxProps {
  formik: FormikContextType<TicketSubmitFormValues>
  disabled?: boolean
  productCategorySlug?: string
}

export const AutoSignCheckBox = ({
  formik,
  disabled = false,
  productCategorySlug,
}: AutoSignCheckBoxProps) => {
  const isOpenYas = useMemo(() => {
    return (
      productCategorySlug &&
      ['open yas', 'openyas', 'openYas'].includes(productCategorySlug.toLocaleLowerCase())
    )
  }, [productCategorySlug])

  return (
    <>
      <div className='d-flex gap-3'>
        <div>
          <input
            style={{
              cursor: 'pointer',
            }}
            disabled={disabled}
            className='form-check-input me-2 bg-primary-dw-darker border border-1 border-primary'
            type='checkbox'
            checked={formik.values.autoSign}
            {...formik.getFieldProps('autoSign')}
          />
        </div>
        <div className='flex-grow-1'>
          {isOpenYas ? (
            <p className='text-white'>
              By clicking here, you hereby agree for auto waiver submissions of similar future
              experiences for the next two years.{' '}
            </p>
          ) : (
            <p className='text-white'>
              <span className='fw-bolder'>IMPORTANT:</span> If you have multiple experiences
              reserved for the same date, only one waiver per person is required to participate in
              each experience. Be sure to check the Auto Sign box to link the waiver to each of your
              tickets. <br /> If you are not participating in all experiences on a respective date,
              please ensure to share tickets using this portal from the homepage. This waiver is a
              legal document, please do not submit waiver on someone’s behalf.
            </p>
          )}
        </div>
      </div>
    </>
  )
}
