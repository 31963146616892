import {useCallback} from 'react'
import {AvatarInitials} from '../../utils/AvatarInitials'

export interface UserListInputItemValue {
  name: string
  initials: string
  value: string
}

export interface UserListInputItemProps {
  value: UserListInputItemValue
  onClick: (value: UserListInputItemValue) => void
}

export const UserListInputItem = ({value, onClick}: UserListInputItemProps) => {
  const handleOnClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return (
    <li className='user-list-input-list-item'>
      <button onClick={handleOnClick} className='user-list-input-list-item__button'>
        <AvatarInitials className='user-list-input-list-item-avatar'>
          {value.initials}
        </AvatarInitials>
        <p className='fs-6'>{value.name}</p>
      </button>
    </li>
  )
}
