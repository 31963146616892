import moment from 'moment'
import {useCallback, useMemo} from 'react'
import {useClock} from '../../../components/hooks/useClock'

export const useOrderClock = () => {
  const {time, stop} = useClock(10000)

  const checkStatus = useCallback(
    (date: Date) => {
      const secondsSinceOrder = moment(time).diff(date, 'seconds')
      if (secondsSinceOrder > FIVE_MINUTES_IN_SECONDS) {
        return 'late'
      } else if (secondsSinceOrder > THREE_MINUTES_IN_SECONDS) {
        return 'warning'
      }
      return 'new'
    },
    [time]
  )

  return useMemo(() => ({checkStatus, time, stop}), [checkStatus, stop, time])
}

const FIVE_MINUTES_IN_SECONDS = 60 * 5
const THREE_MINUTES_IN_SECONDS = 60 * 3
