import {useMemo, useState, useCallback, ReactNode, Fragment} from 'react'
import {SearchableInputItem} from './SearchableInputItem'
import {SelectInputItem} from '../SelectInput'
import {useDebounce} from '../../hooks/useDebounce'
import {useOnChange} from '../../hooks/useOnChange'

const SCROLL_REPOPULATE_PERCENT_THRESHOLD = 0.8

export interface SearchableInputListClasses<T> {
  listItem?: string | ((value: T) => string | undefined)
}

export interface SearchableInputListProps<T> {
  items: T[]
  onItemClick: (item: T) => void
  searchInputPlaceholder?: string
  onSearchInputFocus: () => void
  onSearchInputBlur: () => void
  searchInputId: string
  searchInputRef?: (input: HTMLInputElement | null) => void
  onSearch?: (search: string) => void
  delay?: number
  onScrollBottom?: () => void
  filter?: (item: T) => boolean
  classes?: SearchableInputListClasses<T>
  itemMapper: (item: T) => SelectInputItem
  renderItem?: (item: T) => ReactNode
}

export const SearchableInputList = <T,>({
  items,
  onItemClick,
  searchInputPlaceholder,
  onSearchInputFocus,
  onSearchInputBlur,
  searchInputId,
  searchInputRef,
  onSearch,
  delay = 200,
  onScrollBottom,
  classes,
  filter,
  itemMapper,
  renderItem,
}: SearchableInputListProps<T>) => {
  const [listLength, setListLength] = useState(50)
  const [searchValue, setSearchValue] = useState('')
  const [displayedSearchValue, setDisplayedSearchValue] = useState('')
  const debounced = useDebounce(delay)

  const filteredItems = useMemo(() => {
    const currentSearch = searchValue.toLowerCase()
    return items.filter((item) => {
      if (filter) {
        return filter(item)
      }
      const {label: itemLabel} = itemMapper(item)
      return itemLabel.toLowerCase().includes(currentSearch)
    })
  }, [filter, itemMapper, items, searchValue])

  const paginatedItems = useMemo(() => {
    return filteredItems.slice(0, listLength)
  }, [filteredItems, listLength])

  const selectFieldItems = useMemo(() => {
    const selectFieldNodes = paginatedItems.map((item) => {
      const {label, value} = itemMapper(item)
      const key = `${label}${value}`
      const className = classes?.listItem
      if (renderItem) {
        return <Fragment key={key}>{renderItem(item)}</Fragment>
      }
      return (
        <SearchableInputItem
          className={typeof className === 'function' ? className(item) : className}
          key={key}
          label={label}
          onClick={() => onItemClick(item)}
          value={item}
        />
      )
    })

    return selectFieldNodes
  }, [classes?.listItem, itemMapper, onItemClick, paginatedItems, renderItem])

  const addAmountToList = useCallback(
    (amount) => {
      if (listLength < items.length) {
        setListLength(listLength + amount)
      }
    },
    [listLength, items]
  )

  const handleOnScroll = useCallback(
    (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const {scrollTop, clientHeight, scrollHeight} = e.target as HTMLUListElement
      const percentScrolled = scrollTop / (scrollHeight - clientHeight)
      if (percentScrolled > SCROLL_REPOPULATE_PERCENT_THRESHOLD) {
        addAmountToList(50)
        onScrollBottom && onScrollBottom()
      }
    },
    [addAmountToList, onScrollBottom]
  )

  const handleInputChange = useCallback(
    (e) => {
      const {value} = e.target
      setDisplayedSearchValue(value)
      debounced(() => {
        setSearchValue(value)
      })
    },
    [debounced]
  )

  useOnChange(displayedSearchValue, () => {
    onSearch && onSearch(displayedSearchValue)
  })

  return (
    <div className='searchable-select-input-list-content'>
      <div className='searchable-select-input-list-content__search-container'>
        <input
          ref={searchInputRef}
          className='search-input form-control'
          id={searchInputId}
          onChange={handleInputChange}
          placeholder={searchInputPlaceholder}
          onFocus={onSearchInputFocus}
          onBlur={onSearchInputBlur}
          value={displayedSearchValue}
        />
      </div>
      <ul
        onScroll={handleOnScroll}
        className='searchable-select-input-list-content__list-container'
      >
        {selectFieldItems}
      </ul>
    </div>
  )
}
