import React, {useRef, useEffect, useMemo} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../assets/ts/components'
import {AsideMenu} from '../common/AsideMenu'
import {useOutletAuth} from '../../../../../app/components/hooks/useOutletAuth'
import {NavigationSection} from '../../../../../config/drawer'

type Props = {
  asideMenuCSSClasses: string[]
}

export const AsideMenuOutletManager: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  const auth = useOutletAuth()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const menu = useMemo(() => {
    const user = auth?.getUser()

    const sections: NavigationSection[] = [
      {
        title: user?.outlet?.name,
        menus: [
          {
            title: 'Overview',
            to: '/overview',
          },
          {
            title: 'Product Categories',
            to: '/p-category',
          },
          {
            title: 'Products',
            to: '/product',
          },
          {
            title: 'User',
            to: '/user',
          },
          {
            title: 'Orders',
            to: '/order',
          },
          {
            title: 'Logs',
            to: '/outlet-logs',
          },
          {
            title: 'Table reservations',
            to: '/table-reservation',
          },
        ],
      },
    ]

    return sections
  }, [auth])

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        <AsideMenu sections={menu} />
      </div>
    </div>
  )
}
