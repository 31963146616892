import {CustomerUserModel} from '../../models/customer-portal/AuthModel'

export type CustomerCategoryFeatureCode = 'WHATSAPP' | 'EMAIL' | 'MOBILEETICKET' | 'PDF'

export const CATEGORY_FEATURE_CODES: Record<string, CustomerCategoryFeatureCode> = {
  WHATSAPP: 'WHATSAPP',
  EMAIL: 'EMAIL',
  MOBILE_E_TICKET: 'MOBILEETICKET',
  PDF: 'PDF',
}
export class CustomerCategory {
  constructor(private user?: CustomerUserModel) {}

  public canManageShare = (feature: CustomerCategoryFeatureCode): boolean => {
    const hasRight = this.user?.customerCategory?.customerCategoryFeatures?.find(
      (item) => item.code === feature
    )
    return hasRight ? true : false
  }
}
