export interface AsideMenuTitleProps {
  children: string
}

export const AsideMenuTitle = ({children}: AsideMenuTitleProps) => {
  return (
    <div className='menu-item'>
      <div className='menu-content pt-8 pb-2'>
        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{children}</span>
      </div>
    </div>
  )
}
