import {useMemo} from 'react'
import {debounce} from 'lodash'

export const useDebounce = (delay: number) => {
  const debounced = useMemo(() => {
    return debounce((func: () => void) => {
      func()
    }, delay)
  }, [delay])

  return debounced
}
