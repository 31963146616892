import csv from 'papaparse'

export interface CsvParserOptions {
  hasHeaders?: boolean
  removeEmptyRows?: boolean
}
export class CsvParser {
  private options: CsvParserOptions
  constructor(private data: string[][], options: CsvParserOptions = {}) {
    this.options = options
    if (options.removeEmptyRows) {
      this.data = this.data.filter((row) => {
        return Boolean(row.join(''))
      })
    }
  }

  public static fromFile = (file: File, options?: CsvParserOptions) => {
    return new Promise<CsvParser>((resolve) => {
      const reader = new FileReader()

      reader.addEventListener('load', (e) => {
        const unparsedData = e.target?.result
        if (typeof unparsedData === 'string') {
          const data = csv.parse<string[]>(unparsedData)
          resolve(new CsvParser(data.data, options))
        }
      })

      reader.readAsBinaryString(file)
    })
  }

  public get hasHeaders() {
    return this.options.hasHeaders
  }

  public setHasHeaders = (hasHeaders: boolean) => {
    this.options.hasHeaders = hasHeaders
  }

  public getData = () => {
    return this.data
  }

  public getHeaders = (): string[] | null => {
    if (this.options?.hasHeaders) {
      return this.getData()[0] || null
    }
    return null
  }

  public getBody = (): string[][] => {
    if (this.options?.hasHeaders) {
      return this.getData().slice(1)
    }
    return this.getData()
  }
}
