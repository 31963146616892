import {
  CreateCustomerImport,
  ListCustomerImport,
  ProcessCustomerImport,
} from '../../redux/CustomerPortalCRUD'
import {CustomerMappingTableModal} from './CustomerMappingTableModal'
import {CustomerImportTable} from './CustomerImportTable'
import {ImportSectionBaseActions} from './ImportSectionBaseActions'
import {useSectionHandlers} from './useSectionHandlers'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {CUSTOMER_PORTAL_CSV_TEMPLATE_URL} from '../../../../../config/env'

export const ImportCustomers = () => {
  const {
    finalizeImport,
    handleImport,
    handleImportClick,
    handleTableSearch,
    hasImported,
    modalState,
    tableSearchResult,
    isLoading,
  } = useSectionHandlers({
    name: 'Customers',
    onImport: CreateCustomerImport,
    onImportSearch: ListCustomerImport,
    processImport: ProcessCustomerImport,
  })
  const downloadCSVTemplate = CUSTOMER_PORTAL_CSV_TEMPLATE_URL
  return (
    <>
      <div className='row mb-3 g-3'>
        <div className='d-flex justify-content-end'>
          <a href={`${downloadCSVTemplate}`} download>
            <Button variant='text' className='btn-primary' onClick={() => {}}>
              <MetronicIcon
                className='svg-icon-success svg-icon-1hx'
                iconType='Navigation'
                iconName='Down-2'
              />
              Download Template
            </Button>
          </a>
        </div>
        <div className='col-xs-12'>
          <ImportSectionBaseActions
            isLoading={isLoading}
            hasImported={hasImported}
            name='Customers'
            onFinalizeImport={finalizeImport}
            onImportClick={handleImportClick}
          />
        </div>
      </div>
      {hasImported && <CustomerImportTable onFilter={handleTableSearch} data={tableSearchResult} />}
      <CustomerMappingTableModal
        onSubmit={handleImport}
        onClose={modalState.hide}
        open={modalState.isOpen}
      />
    </>
  )
}
