import {saveAs} from 'file-saver'

export abstract class BlobUtils {
  public static downloadBlob = (blob: Blob, filename?: string) => {
    saveAs(blob, filename)
  }

  public static blobToJson = async <T>(blob: Blob): Promise<T> => {
    return JSON.parse(await blob.text()) as T
  }

  public static blobToBase64 = (blob: Blob) => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        if (typeof base64data === 'string') {
          resolve(base64data)
        }
      }
    })
  }

  public static base64ToBlob = async (base64: string) => {
    const res = await fetch(base64)
    return res.blob()
  }

  // You need to allow popup in the browser.
  public static printBlobInNewTab = (blob: Blob) => {
    const url = URL.createObjectURL(blob)
    const printWindow = window.open(url)
    if (printWindow) {
      printWindow.onafterprint = function () {
        printWindow.close()
      }
      setTimeout(() => {
        printWindow.print()
      }, 100)
    }
  }

  public static convertBase64ToBlob = (base64String: string) => {
    // Split into two parts
    const parts = base64String.split(';base64,')

    // Hold the content type
    const imageType = parts[0].split(':')[1]

    // Decode Base64 string
    const decodedData = window.atob(parts[1])

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], {type: imageType})
  }
}
