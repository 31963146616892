import {useCallback, useEffect, useMemo, useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {BlobUtils} from '../../../utils/BlobUtils'
import {DeviceUtils} from '../../../utils/DeviceUtils'
import {PortalWhatsAppPdf} from '../components/PortalWhatsAppPdf'
import {WhatsAppLinkDownloadTicket} from '../redux/CustomerPortalCRUD'

interface RouteParams {
  hash: string
}

export const PortalDownloadTicketByWhatsApp = () => {
  const [pdf, setPdf] = useState<string>()
  const {pushError} = useAlerts()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const {
    params: {hash},
  } = useRouteMatch<RouteParams>()

  const getPdfFile = useCallback(async () => {
    if (hash && hash !== 'undefined') {
      try {
        const {data} = await WhatsAppLinkDownloadTicket(hash)
        if (data instanceof Blob) {
          const blobToUrl = URL.createObjectURL(data) 
          setPdf(blobToUrl)
        } else {
          throw new Error('Invalid file format')
        }
      } catch (err: any) {
        pushError(err)
        setError('Please try again later!')
      } finally {
        setIsLoading(false)
      }
    }
  }, [hash, pushError])

  const isIphone = useMemo(() => {
    return DeviceUtils.isiPhone()
  }, [])

  const downloadPdfFile = useCallback(async () => {
    if (hash && hash !== 'undefined') {
      try {
        setIsDownloadLoading(true)
        const {data, headers} = await WhatsAppLinkDownloadTicket(hash)
        if (data instanceof Blob) {
          let name = headers['content-disposition']
          name = name
            .replace('attachment;', '')
            .replace('filename=', '')
            .split('(')[0]
            .replaceAll('"', '')
            .trim()
          BlobUtils.downloadBlob(data, name)
        } else {
          throw new Error('Invalid file format')
        }
      } catch (err: any) {
        pushError(err.message || 'An error occurred')
      } finally {
        setIsDownloadLoading(false)
      }
    }
  }, [pushError, hash])

  useEffect(() => {
    if (!isIphone) {
      getPdfFile()
    }
  }, [getPdfFile, isIphone])

  return (
    <PortalWhatsAppPdf
      isLoading={isLoading}
      isDownloadLoading={isDownloadLoading}
      error={error}
      downloadPdfFile={downloadPdfFile}
      pdf={pdf}
    />
  )
}
