import {ReactNode, useCallback, useMemo} from 'react'
import {Spinner} from '../utils/Spinner'
import {MetronicIcon} from './MetronicIcon'

export interface PinInputProps {
  onChange: (value: string) => void
  value: string
  onSubmit?: () => void
  loading?: boolean
  title?: string
  header?: ReactNode
  disableSubmit?: boolean
  password?: boolean
}

export const PinInput = ({
  header,
  onChange,
  value,
  onSubmit,
  loading,
  title,
  disableSubmit,
  password,
}: PinInputProps) => {
  const getNumberClickHandler = useCallback(
    (number: string) => () => {
      onChange(value + number)
    },
    [onChange, value]
  )

  const removeLastCharacter = useCallback(() => {
    const newValue = value.substring(0, value.length - 1)
    onChange(newValue)
  }, [onChange, value])

  const displayedValue = useMemo(() => {
    if (password) {
      return new Array(value.length).fill(PASSWORD_DOT).join('')
    }
    return value
  }, [password, value])

  return (
    <div className='pin-input'>
      {header}
      {title && (
        <div className='pin-input-row'>
          <span className='pin-input__title'>{title}</span>
        </div>
      )}
      <div className='pin-input-row'>
        <div className='pin-input__display'>{displayedValue}</div>
      </div>
      <div className='pin-input-row'>
        <button type='button' onClick={getNumberClickHandler('1')} className='pin-input__one'>
          1
        </button>
        <button type='button' onClick={getNumberClickHandler('2')} className='pin-input__two'>
          2
        </button>
        <button type='button' onClick={getNumberClickHandler('3')} className='pin-input__three'>
          3
        </button>
      </div>
      <div className='pin-input-row'>
        <button type='button' onClick={getNumberClickHandler('4')} className='pin-input__four'>
          4
        </button>
        <button type='button' onClick={getNumberClickHandler('5')} className='pin-input__five'>
          5
        </button>
        <button type='button' onClick={getNumberClickHandler('6')} className='pin-input__six'>
          6
        </button>
      </div>
      <div className='pin-input-row'>
        <button type='button' onClick={getNumberClickHandler('7')} className='pin-input__seven'>
          7
        </button>
        <button type='button' onClick={getNumberClickHandler('8')} className='pin-input__eight'>
          8
        </button>
        <button type='button' onClick={getNumberClickHandler('9')} className='pin-input__nine'>
          9
        </button>
      </div>
      <div className='pin-input-row'>
        <button type='button' onClick={removeLastCharacter} className='pin-input__back'>
          <MetronicIcon className='m-0' size='md' iconType='Navigation' iconName='Arrow-left' />
        </button>
        <button type='button' onClick={getNumberClickHandler('0')} className='pin-input__zero'>
          0
        </button>
        {onSubmit && (
          <button
            type='button'
            onClick={onSubmit}
            disabled={disableSubmit || loading}
            className='pin-input__check'
          >
            {loading ? (
              <Spinner />
            ) : (
              <MetronicIcon className='m-0' size='md' iconType='Navigation' iconName='Check' />
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export const PASSWORD_DOT = '●'
