import clsx from 'clsx'
import {useMemo} from 'react'
import styles from './ColorLegend.module.scss'

export interface ColorLegendItem {
  color: string
  label: string
  key: string
}

export interface ColorLegendProps {
  legends: ColorLegendItem[]
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => void
  className?: string
  classes?: {
    label?: string
  }
  onClick?: (item: ColorLegendItem) => void
}

export const ColorLegend = ({
  legends,
  onMouseMove,
  onMouseLeave,
  className,
  classes,
  onClick,
}: ColorLegendProps) => {
  const colors = useMemo(() => {
    return legends.map((legend) => (
      <div
        key={legend.color}
        className={clsx(styles.item, 'mb-1')}
        onMouseMove={(e) => onMouseMove?.(e, legend.key)}
        onMouseLeave={(e) => onMouseLeave?.(e, legend.key)}
        onClick={() => onClick?.(legend)}
        style={{cursor: onClick ? 'pointer' : undefined}}
      >
        <div
          style={{backgroundColor: legend.color, width: '15px', minWidth: '15px', height: '15px'}}
          className={styles.colorBox}
        ></div>
        <div
          style={{
            fontSize: '10px',
          }}
          className={classes?.label}
        >
          {legend.label}
        </div>
      </div>
    ))
  }, [classes?.label, legends, onClick, onMouseLeave, onMouseMove])

  return <div className={className}>{colors}</div>
}
