import {useCallback, useEffect, useRef, useState} from 'react'
import {Button} from 'react-bootstrap'
import Webcam from 'react-webcam'
import {TableActionModalProps} from '../../../../components/hooks/useTableActionModal'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {SweetAlert} from '../../../../components/modals/SweetAlert'
import {BlobUtils} from '../../../../utils/BlobUtils'

export interface CustomerTakePhotoModalProps extends TableActionModalProps {
  onSubmit: (photo: ImageInputValue) => void
}

const videoConstraints = {
  width: 720,
  height: 720,
  facingMode: 'environment',
}

export const CustomerTakePhotoModal = ({
  onClose,
  isOpen,
  onSubmit,
}: CustomerTakePhotoModalProps) => {
  const [image, setImage] = useState<ImageInputValue>()
  const webcamRef = useRef<Webcam>(null)

  const getScreenshot = useCallback(() => {
    const src = webcamRef.current?.getScreenshot()
    if (src) {
      const blob = BlobUtils.convertBase64ToBlob(src)
      const screenshot = new ImageInputValue(blob, new Date().toISOString())
      setImage(screenshot)
    }
  }, [])

  const handleSubmit = useCallback(() => {
    if (image) {
      onSubmit(image)
    }
  }, [image, onSubmit])
  const handleRetake = useCallback(() => {
    if (image) {
      setImage(undefined)
    }
  }, [image])

  useEffect(() => {
    if (!isOpen) {
      setImage(undefined)
    }
  }, [isOpen])

  return (
    <SweetAlert open={isOpen} onClose={onClose} showConfirmButton={false}>
      <div className='swal2-corners'></div>
      <div className='d-flex flex-column position-relative p-10'>
        <div className='d-flex mb-3 overflow-auto'>
          {image && (
            <div className='w-100'>
              <img style={{height: '100%'}} src={image.url} alt={image.filename} />
            </div>
          )}
        </div>
        {!image && (
          <div>
            <Webcam
              className='w-100'
              videoConstraints={videoConstraints}
              screenshotFormat='image/jpeg'
              ref={webcamRef}
            />
          </div>
        )}

        <div className='d-flex justify-content-end mt-3'>
          <MetronicIconButton
            className='mx-3'
            disabled={image === undefined}
            variant='danger'
            onClick={handleRetake}
            iconType='General'
            iconName='Trash'
          />
          <Button variant='primary' onClick={getScreenshot} disabled={image !== undefined}>
            Capture
          </Button>
          <MetronicIconButton
            className='ms-3'
            disabled={image === undefined}
            variant='success'
            onClick={handleSubmit}
            iconType='Navigation'
            iconName='Check'
          />
        </div>
      </div>
    </SweetAlert>
  )
}
