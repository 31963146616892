import {Link} from 'react-router-dom'
import {
  BookingDetailModel,
  BookingModelProductStatus,
} from '../../../../../models/customer-portal/BookingDetailModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {FromProp} from './CustomerProductBookingDetailTable'

interface Props {
  data: BookingDetailModel
  toPath?: FromProp
  customer?: CustomerModel
}

export const BookingProductCodeColumn = ({data, toPath, customer}: Props) => {
  if (customer && data && bookingHasTickets(data.bookingProductStatus)) {
    if (data.bookingProductType === 'product')
      return (
        <Link
          to={`/customer/${data.customerCode}/booking/${data.bookingCode}/booking-product/${data.bookingProductCode}/ticket`}
        >
          {data.code}
        </Link>
      )

    if (data.bookingProductType === 'voucher')
      return (
        <Link
          to={`/customer/${data.customerCode}/booking/${data.bookingCode}/booking-product/${data.bookingProductCode}/voucher`}
        >
          {data.code}
        </Link>
      )
  }
  if (data && bookingHasTickets(data.bookingProductStatus)) {
    if (data.bookingProductType === 'product')
      return (
        <Link
          to={{
            pathname: `/events/${data.eventCode}/${toPath}/customer/${data.customerCode}/booking/${data.bookingCode}/booking-product/${data.bookingProductCode}/ticket`,
          }}
        >
          {data.code}
        </Link>
      )

    if (data.bookingProductType === 'voucher')
      return (
        <Link
          to={{
            pathname: `/events/${data.eventCode}/${toPath}/customer/${data.customerCode}/booking/${data.bookingCode}/booking-product/${data.bookingProductCode}/voucher`,
          }}
        >
          {data.code}
        </Link>
      )
  }
  return <span>{data.code}</span>
}

const bookingHasTickets = (bookingStatus: BookingModelProductStatus) => {
  return ['confirmed', 'queued', 'printed', 'sent'].includes(bookingStatus)
}
