import clsx from 'clsx'
import {useMemo} from 'react'
import {HeaderUserMenu} from '../../../../_metronic/partials'
import {AvatarInitials} from '../../../components/utils/AvatarInitials'
import {useWaiverAuth} from '../hooks/useWaiverAuth'

export const AuthWaiverHeaderThumbnail = () => {
  const auth = useWaiverAuth()
  const user = useMemo(() => auth?.getUser(), [auth])

  if (!user) {
    return null
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0 h-100'>
      <div
        className={clsx('d-flex align-items-center ms-1 ms-lg-3')}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol bg-opacity-10 symbol-30px symbol-md-40px')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <AvatarInitials className='fs-2'>{user.avatarCode}</AvatarInitials>
        </div>
        <HeaderUserMenu email={user.email} name={user.name} isAdmin={user.isAdmin} />
      </div>
    </div>
  )
}
