import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {
  CustomerModel,
  CustomerModelCreateFormData,
  CustomerModelCreateParams,
} from '../../../../models/CustomerModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ImportModel} from '../../../../models/svc/ImportModel'
import {VoucherModel, VoucherModelCreateParams} from '../../../../models/svc/VoucherModel'
import {
  CreateVoucher,
  DeleteCustomer,
  GetCustomers,
  PostCustomer,
  PutCustomer,
  SearchImports,
  SearchVouchers,
  UpdateVoucher,
} from './SvcCRUD'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

interface IPagesState {
  customers?: GlobalSearchModel<CustomerModel>
  vouchers?: GlobalSearchModel<VoucherModel>
  imports?: GlobalSearchModel<ImportModel>
}

const initialAuthState: IPagesState = {
  customers: undefined,
  vouchers: undefined,
  imports: undefined,
}

// ACTION TYPES
const actionTypes = {
  vouchers: {
    search: {
      execute: '[SVC] SEARCH VOUCHER',
      success: '[SVC] SEARCH VOUCHER SUCCESS',
      failed: '[SVC] SEARCH VOUCHER FAILED',
    },
    create: {
      execute: '[SVC] CREATE VOUCHER',
      success: '[SVC] CREATE VOUCHER SUCCESS',
      failed: '[SVC] CREATE VOUCHER FAILED',
    },
    update: {
      execute: '[SVC] UPDATE VOUCHER',
      success: '[SVC] UPDATE VOUCHER SUCCESS',
      failed: '[SVC] UPDATE VOUCHER FAILED',
    },
    delete: {
      execute: '[SVC] DELETE VOUCHER',
      success: '[SVC] DELETE VOUCHER SUCCESS',
      failed: '[SVC] DELETE VOUCHER FAILED',
    },
  },
  customers: {
    search: {
      execute: '[SVC] SEARCH CUSTOMER',
      success: '[SVC] SEARCH CUSTOMER SUCCESS',
      failed: '[SVC] SEARCH CUSTOMER FAILED',
    },
    create: {
      execute: '[SVC] CREATE CUSTOMER',
      success: '[SVC] CREATE CUSTOMER SUCCESS',
      failed: '[SVC] CREATE CUSTOMER FAILED',
    },
    update: {
      execute: '[SVC] UPDATE CUSTOMER',
      success: '[SVC] UPDATE CUSTOMER SUCCESS',
      failed: '[SVC] UPDATE CUSTOMER FAILED',
    },
    delete: {
      execute: '[SVC] DELETE CUSTOMER',
      success: '[SVC] DELETE CUSTOMER SUCCESS',
      failed: '[SVC] DELETE CUSTOMER FAILED',
    },
  },
  imports: {
    search: {
      execute: '[SVC] SEARCH IMPORT',
      success: '[SVC] SEARCH IMPORT SUCCESS',
      failed: '[SVC] SEARCH IMPORT FAILED',
    },
  },
}

// REDUCERS
export const reducer = persistReducer(
  {storage, key: 'webapp-master', whitelist: []},
  (state: IPagesState = initialAuthState, action: ActionWithPayload<IPagesState>) => {
    switch (action.type) {
      case actionTypes.customers.search.success: {
        const customers = action.payload?.customers
        return {...state, customers}
      }
      case actionTypes.vouchers.search.success: {
        const vouchers = action.payload?.vouchers
        return {...state, vouchers}
      }
      case actionTypes.imports.search.success: {
        const imports = action.payload?.imports
        return {...state, imports}
      }
      default:
        return state
    }
  }
)

// ACTIONS
export const actions = {
  customers: {
    search: () => ({type: actionTypes.customers.search.execute}),
    searchSuccess: (data: GlobalSearchModel<VoucherModelCreateParams>) => ({
      type: actionTypes.customers.search.success,
      payload: {customers: data},
    }),
    searchFailed: () => ({type: actionTypes.customers.search.failed}),

    create: (payload: CustomerModelCreateParams) => ({
      type: actionTypes.customers.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.customers.create.success}),
    createFailed: () => ({type: actionTypes.customers.create.failed}),

    update: (payload: CustomerModelCreateParams) => ({
      type: actionTypes.customers.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.customers.update.success}),
    updateFailed: () => ({type: actionTypes.customers.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.customers.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.customers.delete.success}),
    deleteFailed: () => ({type: actionTypes.customers.delete.failed}),
  },
  vouchers: {
    search: () => ({type: actionTypes.vouchers.search.execute}),
    searchSuccess: (data: GlobalSearchModel<VoucherModelCreateParams>) => ({
      type: actionTypes.vouchers.search.success,
      payload: {vouchers: data},
    }),
    searchFailed: () => ({type: actionTypes.vouchers.search.failed}),

    create: (payload: VoucherModelCreateParams) => ({
      type: actionTypes.vouchers.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.vouchers.create.success}),
    createFailed: () => ({type: actionTypes.vouchers.create.failed}),

    update: (payload: VoucherModelCreateParams) => ({
      type: actionTypes.vouchers.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.vouchers.update.success}),
    updateFailed: () => ({type: actionTypes.vouchers.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.vouchers.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.vouchers.delete.success}),
    deleteFailed: () => ({type: actionTypes.vouchers.delete.failed}),
  },
  imports: {
    search: () => ({type: actionTypes.imports.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ImportModel>) => ({
      type: actionTypes.imports.search.success,
      payload: {imports: data},
    }),
    searchFailed: () => ({type: actionTypes.imports.search.failed}),
  },
}

// AFTER EFFECTS
export function* saga() {
  // CUSTOMER
  yield takeLatest(
    [
      actionTypes.customers.create.success,
      actionTypes.customers.update.success,
      actionTypes.customers.delete.success,
    ],
    function* afterEffectSaga() {
      yield put(actions.customers.search())
    }
  )

  yield takeLatest(actionTypes.customers.search.execute, function* afterEffectSaga() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['customer'])
      const {data} = yield GetCustomers(filter)
      yield put(actions.customers.searchSuccess(data))
    } catch (e) {
      yield put(actions.customers.searchFailed())
    }
  })

  yield takeLatest(
    actionTypes.customers.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<CustomerModelCreateFormData>>) {
      try {
        yield PostCustomer(action.payload)
        yield put(actions.customers.createSuccess())
      } catch (e) {
        yield put(actions.customers.createFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.customers.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{customer: CustomerModelCreateFormData; code: string}>>
    ) {
      try {
        yield PutCustomer(action.payload.customer, action.payload.code)
        yield put(actions.customers.updateSuccess())
      } catch (e) {
        yield put(actions.customers.updateFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.customers.delete.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<string | string[]>>) {
      try {
        yield DeleteCustomer(action.payload)
        yield put(actions.customers.deleteSuccess())
      } catch (e) {
        yield put(actions.customers.deleteFailed())
      }
    }
  )

  // VOUCHER
  yield takeLatest(
    [
      actionTypes.vouchers.create.success,
      actionTypes.vouchers.update.success,
      actionTypes.vouchers.delete.success,
    ],
    function* afterEffectSaga() {
      yield put(actions.vouchers.search())
    }
  )

  yield takeLatest(actionTypes.vouchers.search.execute, function* afterEffectSaga() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['voucher'])
      const {data} = yield SearchVouchers(filter)
      yield put(actions.vouchers.searchSuccess(data))
    } catch (e) {
      yield put(actions.vouchers.searchFailed())
    }
  })

  yield takeLatest(
    actionTypes.vouchers.create.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<VoucherModelCreateParams>>) {
      try {
        yield CreateVoucher(action.payload)
        yield put(actions.vouchers.createSuccess())
      } catch (e) {
        yield put(actions.vouchers.createFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.vouchers.update.execute,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{voucher: VoucherModelCreateParams; code: string}>>
    ) {
      try {
        yield UpdateVoucher(action.payload.code, action.payload.voucher)
        yield put(actions.vouchers.updateSuccess())
      } catch (e) {
        yield put(actions.vouchers.updateFailed())
      }
    }
  )

  yield takeLatest(
    actionTypes.vouchers.delete.execute,
    function* afterEffectSaga(action: Required<ActionWithPayload<string[]>>) {
      try {
        yield DeleteCustomer(action.payload)
        yield put(actions.vouchers.deleteSuccess())
      } catch (e) {
        yield put(actions.vouchers.deleteFailed())
      }
    }
  )

  // IMPORTS
  yield takeLatest(actionTypes.imports.search.execute, function* afterEffectSaga() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['svc-import'])
      const {data} = yield SearchImports(filter)
      yield put(actions.imports.searchSuccess(data))
    } catch (e) {
      yield put(actions.imports.searchFailed())
    }
  })
}
