import {useEffect, useMemo, useState, useCallback} from 'react'

export const useClock = (update: number = 1000) => {
  const [time, setTime] = useState(new Date())
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date())
    }, update)
    setTimer(interval)
    return () => {
      clearInterval(interval)
    }
  }, [update])

  const stop = useCallback(() => {
    if (timer) {
      clearInterval(timer)
    }
  }, [timer])

  return useMemo(
    () => ({
      time,
      stop,
    }),
    [stop, time]
  )
}
