import {useRef, useEffect} from 'react'

/**
 * A hook that will only be called once during a component's lifecycle.
 * */
export const useOnMount = (callback: Function) => {
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      callback()
    }
  }, [callback])
}
