import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {BarChart} from '../../../../../../components/charts/BarChart/BarChart'
import {SelectInput} from '../../../../../../components/inputs'
import {SelectInputItem} from '../../../../../../components/inputs/SelectInput'
import {WidgetF10Model} from '../../../../../../models/fnb/WidgetModel'

export interface OutletsChartProps {
  datum: Record<string, WidgetF10Model[]>
}

export const OutletChart = ({datum}: OutletsChartProps) => {
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const locationsItems = useMemo<SelectInputItem[]>(() => {
    const locations: SelectInputItem[] = []
    Object.keys(datum).forEach((key) => {
      const secondBracket = key.indexOf(')')
      const code = key
      const name = secondBracket ? key.slice(secondBracket + 1, key.length) : key

      locations.push({
        value: code,
        label: name,
      })
    })
    if (locations && locations.length > 0) {
      if (locations[0].value) setSelectedLocation(locations[0].value)
      return locations
    }
    return []
  }, [datum])

  const handleChangeLocation = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setSelectedLocation(value)
  }, [])

  const chartData = useMemo((): OultletChartData[] => {
    const chartValues: OultletChartData[] = []
    if (selectedLocation && datum[selectedLocation]) {
      datum[selectedLocation].forEach((value) => {
        chartValues.push({
          name: value.outletName,
          total: value.total,
        })
      })
      return chartValues
    }
    return []
  }, [datum, selectedLocation])

  const renderTooltip = useCallback(
    (key: string) => {
      const chartItem = chartData.find((item) => getName(item) === key)
      if (chartItem) {
        return (
          <div className='bg-light-primary p-3 border border-primary rounded text-white'>
            <div>
              {chartItem.name}: {chartItem.total}
            </div>
          </div>
        )
      }
    },
    [chartData]
  )

  return (
    <>
      <div className='d-flex justify-content-end mb-5'>
        <SelectInput
          label='Locations'
          selectStyle={{
            border: '1px solid #198996',
            backgroundColor: '#1989963d',
            color: 'white',
          }}
          value={selectedLocation}
          placeholder='Select a location'
          items={locationsItems}
          onChange={handleChangeLocation}
        />
      </div>
      <div
        className='w-100 pb-10'
        style={{
          height: '450px',
        }}
      >
        <BarChart
          yLabel='Outlets'
          xLabel='Earnings'
          data={chartData}
          xAccessor={getName}
          yAccessor={getCount}
          renderTooltip={renderTooltip}
          isHideXLabel={true}
        />
      </div>
    </>
  )
}

const getName = (datum: OultletChartData) => datum.name
const getCount = (datum: OultletChartData) => datum.total

export interface OultletChartData {
  name: string
  total: number
}
