import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {VoucherCard} from './VoucherCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {FilterInputContainer} from '../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import clsx from 'clsx'
import {MultiSelectToggleFilterInputMobile} from '../tables/AdvancedFilter/MultiSelectToggleFilterInputMobile'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {CardCollapseRow} from '../CardCollapseRow'
import {CollapsedProps} from '../TicketCard/TicketCardGrid'
import {Button} from '../../../../components/inputs/Button'
import {useVoucherTableActions} from '../../hooks/useVoucherTableActions'
import {VoucherTableSelectionActions} from '../tables/VoucherTable/VoucherTableSelectionActions'
import {EmailVoucherModal, InitialValuesProps} from '../modals/EmailVoucherModal'
import {WhatsAppVoucherModal} from '../modals/WhatsAppVoucherModal'
import {WhatsAppInitialVoucherValuesProps} from '../modals/WhatsAppVoucherModal'
import {usePortalCardUtils} from '../../hooks/usePortalCardUtils'
export interface VoucherCardGridProps {
  data?: GlobalSearchModel<VoucherModel> | GroupedSearchModel<VoucherModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  className?: string
  initialFilters?: FilterModel
  filters?: FilterModel
  setFilter?: (filter: FilterModel) => void
  tabCode?: string
  voucherTabCode?: string
}

export const VoucherCardGrid = ({
  data,
  onFilter,
  onRefresh,
  className,
  initialFilters,
  filters,
  setFilter,
  tabCode,
  voucherTabCode,
}: VoucherCardGridProps) => {
  const [collapsedItem, setCollapsedItem] = useState<CollapsedProps>()
  const [filterShown, setFilterShown] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [selectedVouchers, setSelectedVouchers] = useState<VoucherModel[]>()
  const [openEmailModel, setOpenEmailModel] = useState(false)
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)

  const {setPageNumber, setPageSize} = useFilterState(onFilter, {
    initialFilters,
    filters,
  })

  const {
    selectionAllState,
    tableSelectionItems,
    onSelectGroupHandler,
    onSelectHandler,
    handleSelectAll,
  } = usePortalCardUtils<VoucherModel>({
    data,
    selected,
    setSelected,
  })

  const clearSelected = useCallback(() => {
    setSelected([])
  }, [])

  const {isLoading, handleShareByWhatsAppBulk, handleShareByEmailBulk} = useVoucherTableActions({
    onRefresh,
    clearSelected,
  })

  const toggleFilter = useCallback(() => {
    setFilterShown(!filterShown)
  }, [filterShown])

  const toggleCollapse = useCallback(
    (key: string) => {
      const value = collapsedItem && collapsedItem.key === key ? !collapsedItem.value : true
      setCollapsedItem({
        key: key,
        value,
      })
    },
    [collapsedItem]
  )

  const handleFilterStatus = useCallback(
    (value) => {
      onFilter?.({
        filters: {...filters?.filters, status: value},
      })
    },
    [filters?.filters, onFilter]
  )

  const statusActivity = useMemo(() => {
    if (filters && filters.filters && Array.isArray(filters.filters.status)) {
      return filters.filters.status
    }
    return []
  }, [filters])

  const cards = useMemo(() => {
    if (data?.data) {
      return (
        <div className={clsx('row')}>
          {Array.isArray(data.data)
            ? data.data &&
              data.data.length > 0 &&
              data.data.map((voucher) => {
                return (
                  <div key={voucher.code} className='mb-5 col-12'>
                    <VoucherCard
                      className='h-100'
                      data={voucher}
                      tabCode={tabCode}
                      voucherTabCode={voucherTabCode}
                      onSelectHandler={onSelectHandler}
                      selected={selected}
                    />
                  </div>
                )
              })
            : Object.entries(data.data).map(([voucherKey, vouchers], index, keys) => {
                const isLast = index === keys.length - 1
                return (
                  <>
                    <div className='col-12'>
                      <CardCollapseRow
                        collapseKey={voucherKey}
                        keyValue={voucherKey}
                        collapsedItem={collapsedItem}
                        onClick={(key) => toggleCollapse(key)}
                        onSelectGroupHandler={(key) => onSelectGroupHandler(key, vouchers)}
                        selected={selected}
                        items={vouchers}
                      />
                    </div>
                    {collapsedItem?.value && collapsedItem.key === voucherKey && (
                      <div>
                        {vouchers &&
                          vouchers.length > 0 &&
                          vouchers.map((voucher) => {
                            return (
                              <div key={voucher.code} className='mb-3 col-12'>
                                <VoucherCard
                                  className='h-100'
                                  data={voucher}
                                  tabCode={tabCode}
                                  voucherTabCode={voucherTabCode}
                                  onSelectHandler={onSelectHandler}
                                  selected={selected}
                                />
                              </div>
                            )
                          })}
                      </div>
                    )}
                    {!isLast && (
                      <div className='col-12 px-4'>
                        <HorizontalDivider className='bg-secondary' spacing={1} />
                      </div>
                    )}
                  </>
                )
              })}
        </div>
      )
    }
    return null
  }, [
    data,
    tabCode,
    voucherTabCode,
    onSelectHandler,
    selected,
    collapsedItem,
    toggleCollapse,
    onSelectGroupHandler,
  ])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilter?.({
        filters: {...filters?.filters, search: e.target.value},
      })
    },
    [filters?.filters, setFilter]
  )

  const searchAction = useMemo(() => {
    return (
      <div className='w-100'>
        <div className='table-left-toolbar'>
          <TextInput
            className='filter-table-search-input'
            inputClassName='rounded-0'
            inputWrapperClassName='bg-primary-cp-dark rounded-0'
            noMargin
            placeholder='Search'
            value={filters?.filters?.search?.toString()}
            onChange={handleSearchChange}
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='General' iconName='Search' />
            }
          />
        </div>
      </div>
    )
  }, [filters?.filters?.search, handleSearchChange])

  const handleOnShareWhatsApp = useCallback((data: VoucherModel | VoucherModel[]) => {
    if (!Array.isArray(data)) setSelectedVouchers([data])
    else setSelectedVouchers(data)
    setOpenWhatsAppModel(true)
  }, [])

  const handleOnShareByEmail = useCallback((data: VoucherModel | VoucherModel[]) => {
    if (data) {
      if (!Array.isArray(data)) setSelectedVouchers([data])
      else setSelectedVouchers(data)
    }
    setOpenEmailModel(true)
  }, [])

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (selectedVouchers) {
        const response = await handleShareByEmailBulk(values, selectedVouchers)
        if (response) return response
      }
    },
    [handleShareByEmailBulk, selectedVouchers]
  )

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialVoucherValuesProps) => {
      if (selectedVouchers) {
        const response = await handleShareByWhatsAppBulk(values, selectedVouchers)
        if (response) return response
      }
    },
    [selectedVouchers, handleShareByWhatsAppBulk]
  )

  const advancedFiltersNode = useMemo(() => {
    return (
      <FilterInputContainer>
        <div className='mt-3'>
          <MultiSelectToggleFilterInputMobile
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='Text' iconName='Align-justify' />
            }
            items={VOUCHER_STATUS}
            label='Activity Status'
            values={statusActivity}
            setValues={handleFilterStatus}
            isLabelUpperCase
            inputClassName='search-cp-field'
          />
        </div>
      </FilterInputContainer>
    )
  }, [handleFilterStatus, statusActivity])

  return (
    <div className={className}>
      <div className='d-flex justify-content-end align-items-start gap-3 flex-grow-1 pt-4'>
        <div className='flex-grow-1'>{searchAction}</div>
        <div
          className={clsx(
            'px-4 cursor-pointer d-flex justify-content-between filter-cp-button',
            filterShown ? 'mobile-active' : 'mobile-not-active'
          )}
          onClick={toggleFilter}
        >
          <div className='mt-3'>
            <span className='fs-5 fw-bold text-white ff-admm-bold text-uppercase'>Filter</span>
          </div>
          <div>
            <MetronicIconButton
              iconType='Text'
              iconName='Filter'
              size='md'
              color='white'
              tooltip='Toggle filter'
              className='bg-transparent'
            />
          </div>
        </div>
      </div>

      {filterShown && (
        <div className='p-4 filters-box-cp mh-250px overflow-auto'>{advancedFiltersNode}</div>
      )}

      {selected && selected.length > 0 && (
        <div
          className={clsx('mt-2', filterShown && 'mt-5')}
        >{`SELECTED RECORDS COUNT: ${selected.length}`}</div>
      )}

      <div
        className={clsx(
          'mb-5',
          filterShown ? 'mt-5' : selected && selected.length > 0 ? 'mt-5' : 'mt-2'
        )}
      >
        <Button
          size='sm'
          type='button'
          className={clsx('btn-select-all-cp text-white rounded-0', selectionAllState && 'active')}
          onClick={handleSelectAll}
        >
          Select All
        </Button>
      </div>

      <div
        className={clsx('d-flex gap-3 flex-wrap filters-box-cp', selected.length > 0 && 'p-3 mb-5')}
      >
        <VoucherTableSelectionActions
          items={tableSelectionItems}
          selected={selected}
          onShareByEmail={handleOnShareByEmail}
          onShareByWhatsApp={handleOnShareWhatsApp}
          // onDownload={handleDownloadBulk}
          isLoading={isLoading}
          size='sm'
          // className='btn-dark-primary-cp rounded-0'
        />
      </div>

      <div>{cards}</div>
      <div>
        <PaginationHelper
          currentPageNumber={filters && filters.page ? filters.page : 1}
          currentPageSize={filters && filters.limit ? filters.limit : 10}
          onChangePageNumber={setPageNumber}
          onChangePageSize={setPageSize}
          total={data?.total}
        />
      </div>
      {selectedVouchers && (
        <EmailVoucherModal
          data={selectedVouchers}
          setOpen={setOpenEmailModel}
          open={openEmailModel}
          onSubmit={handleSendEmail}
        />
      )}
      {selectedVouchers && (
        <WhatsAppVoucherModal
          data={selectedVouchers}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
        />
      )}
    </div>
  )
}

const VOUCHER_STATUS: SelectInputItem[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Shared',
    value: 'shared',
  },
  {
    label: 'Sent',
    value: 'sent',
  },
  {
    label: 'Used',
    value: 'used',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
  {
    label: 'Unassigned',
    value: 'unassigned',
  },
]
