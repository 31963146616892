import clsx from 'clsx'
import {
  CircuitMapDatumKey,
  MapLabelOptions,
} from '../../../../../../components/charts/YasMarinaCircuitChart/F1CircuitHeatMapChart'
import {useBooleanState} from '../../../../../../components/hooks/useBooleanState'
import {MetronicIcon} from '../../../../../../components/inputs/MetronicIcon'
import {Collapse} from 'react-bootstrap'
import styles from './YasMarinaCircuitChart.module.scss'
import {GateCounterWidgetModel} from '../../../../../../models/ems/WidgetModel'

export interface YasMarinaCircuitChartLabelProps {
  labelKey: CircuitMapDatumKey
  datum: GateCounterWidgetModel
}

export const YasMarinaCircuitChartLabel = ({labelKey, datum}: YasMarinaCircuitChartLabelProps) => {
  const {enableState: setHover, disableState: unsetHover, state: isHovered} = useBooleanState(false)
  const options = mapLabelOptions[labelKey]
  const isLeft = options?.placement.includes('left')

  return (
    <div
      onMouseEnter={setHover}
      onMouseLeave={unsetHover}
      style={{zIndex: isHovered ? 100 : undefined}}
      className={clsx(styles.label, 'border border-primary rounded d-flex', {
        'flex-row-reverse': isLeft,
      })}
    >
      <div>
        <MetronicIcon
          className='m-n1'
          iconType='Navigation'
          iconName={isLeft ? 'Angle-right' : 'Angle-left'}
          color='white'
          size='md'
        />
      </div>
      <div className={clsx('px-3')}>
        <p className={clsx('m-0 text-white fw-bold fs-3 d-flex align-items-center')}>
          {datum.total}{' '}
          {isHovered && (
            <span className='fw-light fs-9 mx-3 text-nowrap text-muted'>
              {datum.gridViewSubTitle}
            </span>
          )}
        </p>
        <Collapse dimension='height' in={isHovered}>
          <div className='pb-3'>
            <div>
              <div style={{maxWidth: '200px'}} className='fw-bolder fs-5'>
                {datum.gridViewTitle}
              </div>
            </div>
            <div className='text-nowrap'>
              <span className='fw-bold'>Checked In:</span> {datum.checkedIn}
            </div>
            <div className='text-nowrap'>
              <span className='fw-bold'>Checked Out:</span> {datum.checkedOut}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

interface MapLabelOptionsWithTitle extends MapLabelOptions {
  title: string
}

// export const mapLabelOptions: Record<CircuitMapDatumKey, MapLabelOptionsWithTitle> = {
//   south_oasis: {
//     placement: 'left',
//     offset: [0, 0],
//     title: 'South Oasis',
//   },
//   main_oasis: {
//     placement: 'right',
//     offset: [-30, -60],
//     title: 'Main Oasis',
//   },
//   north_oasis: {
//     placement: 'bottom',
//     offset: [0, 0],
//     title: 'North Oasis',
//   },
//   west_oasis: {
//     placement: 'top-end',
//     offset: [-20, 10],
//     title: 'West Oasis',
//   },
//   abu_dhabi_hill: {
//     placement: 'right-end',
//     offset: [-50, -50],
//     title: 'Abu Dhabi Hill',
//   },
//   north_straight: {
//     placement: 'left',
//     offset: [0, 20],
//     title: 'North Straight',
//   },
//   north_straight_hospitality: {
//     placement: 'top',
//     offset: [0, 20],
//     title: 'North Straight Hospitality',
//   },
//   abu_dhabi_hill_oasis: {
//     placement: 'left-start',
//     offset: [0, 0],
//     title: 'Abu Dhabi Hill Oasis',
//   },
//   marina_grandstand: {
//     placement: 'left',
//     offset: [0, 0],
//     title: 'Marina Grandstand',
//   },
//   south_grandstand: {
//     placement: 'bottom',
//     offset: [0, 0],
//     title: 'South Grandstand',
//   },
//   main_grandstand: {
//     placement: 'right-end',
//     offset: [-10, 30],
//     title: 'Main Grandstand',
//   },
//   north_grandstand: {
//     placement: 'right-start',
//     offset: [0, 0],
//     title: 'North Grandstand',
//   },
//   west_grandstand: {
//     placement: 'top',
//     offset: [0, 30],
//     title: 'West Grandstand',
//   },
//   hq: {
//     placement: 'top',
//     offset: [0, 0],
//     title: 'HQ',
//   },
//   w_hotel: {
//     placement: 'left-start',
//     offset: [-50, 0],
//     title: 'W Hotel',
//   },
//   yas_yacht: {
//     placement: 'right',
//     offset: [0, 10],
//     title: 'Yas Yacht',
//   },
//   yas_central: {
//     placement: 'bottom',
//     offset: [0, 0],
//     title: 'Yas Central',
//   },
//   the_deck_nine: {
//     placement: 'right',
//     offset: [0, 10],
//     title: 'The Deck @ Nine',
//   },
//   luna_lounge: {
//     placement: 'bottom',
//     offset: [30, 0],
//     title: 'Luna Lounge',
//   },
//   the_turns_at_west: {
//     placement: 'left',
//     offset: [0, 0],
//     title: 'The Turns @ West',
//   },
//   main_pit_building: {
//     placement: 'right-end',
//     offset: [50, -50],
//     title: 'Main Pit building',
//   },
// }

export const mapLabelOptions: Record<CircuitMapDatumKey, MapLabelOptionsWithTitle> = {
  south_oasis: {
    placement: 'left',
    offset: [0, 0],
    title: 'South Oasis',
  },
  main_oasis: {
    placement: 'left',
    offset: [0, 0],
    title: 'Main Oasis',
  },
  north_oasis: {
    placement: 'right',
    offset: [0, 10],
    title: 'North Oasis',
  },
  west_oasis: {
    placement: 'right',
    offset: [0, 10],
    title: 'West Oasis',
  },
  abu_dhabi_hill_grandstand: {
    placement: 'left',
    offset: [0, 10],
    title: 'Abu Dhabi Hill',
  },
  north_straight_temp: {
    placement: 'left',
    offset: [10, 5],
    title: 'North Straight',
  },
  north_straight_hospitality: {
    placement: 'left',
    offset: [0, 10],
    title: 'North Straight Hospitality',
  },
  abu_dhabi_hill_oasis: {
    placement: 'left',
    offset: [5, 5],
    title: 'Abu Dhabi Hill Oasis',
  },
  marina_grandstand: {
    placement: 'right',
    offset: [25, 0],
    title: 'Marina Grandstand',
  },
  south_grandstand: {
    placement: 'left',
    offset: [0, 0],
    title: 'South Grandstand',
  },
  main_grandstand: {
    placement: 'right',
    offset: [0, 10],
    title: 'Main Grandstand',
  },
  north_grandstand: {
    placement: 'right',
    offset: [0, 10],
    title: 'North Grandstand',
  },
  west_grandstand: {
    placement: 'left',
    offset: [0, 10],
    title: 'West Grandstand',
  },
  hq: {
    placement: 'right',
    offset: [0, 5],
    title: 'HQ',
  },
  w_hotel: {
    placement: 'left',
    offset: [0, 10],
    title: 'W Hotel',
  },
  yas_yacht: {
    placement: 'right',
    offset: [0, 5],
    title: 'Yas Yacht',
  },
  yas_central: {
    placement: 'right',
    offset: [10, 10],
    title: 'Yas Central',
  },
  the_deck_at_nine: {
    placement: 'right',
    offset: [0, 5],
    title: 'The Deck @ Nine',
  },
  luna_lounge: {
    placement: 'right',
    offset: [0, 10],
    title: 'Luna Lounge',
  },
  the_turns_at_west: {
    placement: 'left',
    offset: [0, 0],
    title: 'The Turns @ West',
  },
  main_pit_building: {
    placement: 'right',
    offset: [0, 5],
    title: 'Main Pit building',
  },
  abu_dhabi_hill_deck: {
    placement: 'right',
    offset: [5, 5],
    title: 'Deck at Two',
  },
  abu_dhabi_hill_terrace: {
    placement: 'right',
    offset: [-5, 5],
    title: 'Horizon 360',
  },
  west_straight_temp: {
    placement: 'left',
    offset: [0, 7],
    title: 'West Straight Grandstand',
  },
  marina_oasis: {
    placement: 'left',
    offset: [0, 0],
    title: 'Marina Oasis',
  },
}
