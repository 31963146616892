import {useCallback, useMemo, useState} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {BatchOrderModel} from '../../../models/fnb/BatchOrderModel'
import {GetOrderBatches, UpdateOrderBatchStatus} from '../redux/OutletCRUD'

export const useKdsDashboardData = () => {
  const [orders, setOrders] = useState<BatchOrderModel[]>([])
  const {pushError, push} = useAlerts()

  const refresh = useCallback(async () => {
    const {data} = await GetOrderBatches()
    setOrders(data)
  }, [])

  const prepareOrder = useCallback(
    async (order: BatchOrderModel) => {
      try {
        await UpdateOrderBatchStatus(order.batchCode, 'preparing')
        push({
          message: `Order #${order.code} marked as preparing.`,
          timeout: 5000,
          variant: 'success',
        })
      } catch (e) {
        pushError(e)
      }
    },
    [push, pushError]
  )

  const completeOrder = useCallback(
    async (order: BatchOrderModel) => {
      try {
        await UpdateOrderBatchStatus(order.batchCode, 'completed')
        push({
          message: `Order #${order.code} marked as completed.`,
          timeout: 5000,
          variant: 'success',
        })
      } catch (e) {
        pushError(e)
      }
    },
    [push, pushError]
  )

  useOnMount(() => {
    refresh()
  })

  return useMemo(
    () => ({orders, prepareOrder, completeOrder, setOrders, refresh}),
    [completeOrder, orders, prepareOrder, refresh]
  )
}
