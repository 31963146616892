import clsx from 'clsx'
import {ReactNode} from 'react'
import {Paper} from '../../../app/components/utils/Paper'

export interface SidebarProps {
  className?: string
  children?: ReactNode
}

export const Sidebar = ({className, children}: SidebarProps) => {
  return <Paper className={clsx('sidebar', className)}>{children}</Paper>
}
