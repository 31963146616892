import clsx from 'clsx'
import {ReactNode, useMemo} from 'react'
import {useBreakpoint} from '../../../../../components/hooks/useBreakpoint'

interface FieldWithLabelProps {
  arabicLabel: string
  englishLabel: string
  children: ReactNode
}
export const FieldWithLabelBox = ({arabicLabel, englishLabel, children}: FieldWithLabelProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])
  return (
    <div
      className='w-100 d-flex flex-wrap border border-1 border-primary rounded'
      style={{
        height: isMobile ? 'auto' : '60px',
      }}
    >
      <div
        className={clsx(
          'bg-primary-dw-light fw-bolder p-3',
          isMobile ? 'rounded-top' : 'rounded-start'
        )}
        style={{
          width: isMobile ? '100%' : '150px',
          height: isMobile ? '58px' : '58px',
        }}
      >
        <p className='mb-0 w-100'>{englishLabel}</p>
        <p className='mb-0 w-100'>{arabicLabel}</p>
      </div>

      <div
        className={clsx('bg-primary-dw-darker', isMobile ? 'rounded-bottom' : 'rounded-end')}
        style={{
          height: isMobile ? '60px' : '100%',
          width: isMobile ? '100%' : 'calc(100% - 150px)',
        }}
      >
        {children}
      </div>
    </div>
  )
}
