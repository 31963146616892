import axios from 'axios'
import {CustomerModel} from '../../../../models/CustomerModel'
import {ISOCountryModel} from '../../../../models/ISOCountryModel'

export const VerifyRegistrationHash = (hash: string) =>
  axios.post<Partial<CustomerModel>>('/registration/verify-hash', {
    hash,
  })

export const VerifyRSVPHash = (hash: string) =>
  axios.post('/registration/verify-rsvp-hash', {
    hash,
  })
export const UpdateRegistration = (formData: FormData) =>
  axios.put('/registration/update', formData)

export const GetRegistrationCoutries = () => axios.get<ISOCountryModel[]>('/registration/country')
