import {useCallback, useEffect, useMemo, useState} from 'react'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {PollModel} from '../../../models/eva/PollModel'
import {useHistory} from 'react-router'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {GetPolls} from '../redux/EvaCRUD'
import {EVENT_CODE} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {TicketAuth} from '../components/TicketAuth/TicketAuth'
import NavigationCell from '../components/NavigationCell/NavigationCell'
const PollsPage = () => {
  const [allPolls, setAllPolls] = useState<PollModel[]>([])
  const history = useHistory()
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const {pushError} = useAlerts()
  const refreshPollsList = useCallback(
    async (ticket: string) => {
      try {
        if (EVENT_CODE && ticket) {
          const {data} = await GetPolls({code: EVENT_CODE, ticketCode: ticket})
          setAllPolls(data)
        }
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  useEffect(() => {
    if (ticketCode) refreshPollsList(ticketCode)
  }, [ticketCode, refreshPollsList])

  const handleGoToRating = useCallback(
    (poll: PollModel) => {
      history.push(`/polls/${poll.code}`, {
        from: '/polls',
        selectedPoll: poll,
      })
    },
    [history]
  )

  const pollsMemo = useMemo(() => {
    return (
      allPolls &&
      allPolls.length > 0 &&
      allPolls.map((poll, index, array) => {
        const isLast = index === array.length - 1
        return (
          <div key={poll.code}>
            <NavigationCell
              goTo={handleGoToRating}
              data={poll}
              title={poll.name}
              description={poll.description}
            />
            {!isLast && (
              <div className='my-4'>
                <HorizontalDivider />
              </div>
            )}
          </div>
        )
      })
    )
  }, [allPolls, handleGoToRating])
  return (
    <div className='p-5 h-100'>
      <TicketAuth qrCodemessage='To participate polls, click here to scan your badge'>
        {pollsMemo}
      </TicketAuth>
    </div>
  )
}

export {PollsPage}
