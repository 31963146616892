import {useCallback, useEffect, useMemo, useState} from 'react'
import {DragDropFileInput} from '../../../components/inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileType} from '../../../components/inputs/FileInput/FileInput'
import {FileInputValue} from '../../../components/inputs/FileInput/FileInputValue'
import {FileInputValueCollection} from '../../../components/inputs/FileInput/FileInputValueCollection'
import {useFormik} from 'formik'
import {Button} from '../../../components/inputs/Button'
import {
  EMPTY_INITIAL_VALUES,
  mapPokenToFormValues,
  useContentManager,
  validationSchema,
} from '../hooks/useContentManager'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {GetPokenByCode, UpdatePoken} from '../redux/PokenCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {EVENT_CODE} from '../../../../config/env'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {useParams, useRouteMatch} from 'react-router-dom'
import {Spinner} from '../../../components/utils/Spinner'
import {isEqual} from 'lodash'
import {LinkBreadCrumb, LinkBreadPokenCrumbToolbar} from '../components/LinkBreadPokenCrumbToolbar'
import {useRouteData} from '../../../components/utils/RouteWithData'
import {PokenModel} from '../../../models/acs/PokenModel'

export const ContentManagerPage = () => {
  const {pokenCode} = useParams<{pokenCode: string}>()
  const {getPayload} = useContentManager()
  const [poken, setPoken] = useState<PokenModel>()
  const {staticUrls} = useAppConfig()
  const match = useRouteMatch()
  const {pushError, push} = useAlerts()
  const pageData = useRouteData<{breadcrumbs?: LinkBreadCrumb[]} | null>()
  const getThePoken = useCallback(async () => {
    try {
      if (pokenCode) {
        const {data} = await GetPokenByCode(pokenCode)
        setPoken(data)
      }
    } catch (err) {
      pushError(err)
    }
  }, [pokenCode, pushError])

  useEffect(() => {
    getThePoken()
  }, [getThePoken])

  const initialValues = useMemo(() => {
    if (poken) {
      return mapPokenToFormValues(staticUrls.public, poken)
    }
    return EMPTY_INITIAL_VALUES
  }, [poken, staticUrls.public])

  useOnChange(initialValues, () => {
    formik.setValues(initialValues)
  })

  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        if (EVENT_CODE && poken && poken.gate) {
          setSubmitting(true)
          const payload = getPayload(values, {
            eventCode: EVENT_CODE,
            gateCode: poken.gate.code,
            editedData: poken,
          })
          if (payload) {
            const {data} = await UpdatePoken(poken.code, payload)
            if (data)
              push({
                message: `Successfully updated`,
                variant: 'success',
                timeout: 5000,
              })
          }
        }
      } catch (err) {
        pushError(err)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleUploadGallery = useCallback(
    async (files: FileInputValueCollection<FileInputValue>) => {
      const gallery = files.getFileArray()
      formik.setFieldValue('gallery', gallery)
    },
    [formik]
  )

  const handleUploadPDFs = useCallback(
    async (files: FileInputValueCollection<FileInputValue>) => {
      const file = files.getFileArray()
      formik.setFieldValue('pdfs', file)
    },
    [formik]
  )

  const handleUploadLogo = useCallback(
    async (files: FileInputValueCollection<FileInputValue>) => {
      const image = files.getFirst()
      formik.setFieldValue('logo', image)
    },
    [formik]
  )

  const videosImagesInputValue = useMemo(() => {
    const galleryCollection = new FileInputValueCollection<FileInputValue>()
    formik.values.gallery?.forEach((file) => {
      galleryCollection.add(file)
    })
    return galleryCollection
  }, [formik.values.gallery])

  const filesInputValue = useMemo(() => {
    const fileCollection = new FileInputValueCollection<FileInputValue>()

    formik.values.pdfs?.forEach((file) => {
      fileCollection.add(file)
    })

    return fileCollection
  }, [formik.values.pdfs])

  const logoInputValue = useMemo(() => {
    const logoFile = new FileInputValueCollection<FileInputValue>()
    if (formik.values.logo) {
      logoFile.add(formik.values.logo)
    }
    return logoFile
  }, [formik.values.logo])

  const fileFactory = useCallback((file: File) => {
    return new FileInputValue(file)
  }, [])

  const isFormikChanged = useMemo(() => {
    return isEqual(initialValues, formik.values)
  }, [formik.values, initialValues])

  const linkBreadCrumbs = useMemo(() => {
    const breadcrumbs: LinkBreadCrumb[] = []
    if (pageData?.data?.breadcrumbs) {
      breadcrumbs.push(...pageData.data.breadcrumbs)
    } else {
      breadcrumbs.push({
        label: 'Pokens',
        link: '/pokens',
      })
    }

    if (pokenCode && poken) {
      breadcrumbs.push({
        label: poken.name,
        link: match.url,
      })
    }

    return breadcrumbs
  }, [match.url, pageData, poken, pokenCode])

  return (
    <>
      <Toolbar title='Content Manager'></Toolbar>
      <LinkBreadPokenCrumbToolbar
        title={`${poken?.name} : Poken`}
        breadcrumbs={linkBreadCrumbs}
        poken={poken}
      ></LinkBreadPokenCrumbToolbar>
      {poken ? (
        <>
          <div className='card '>
            <div className='mt-5 px-5 py-2'>
              <p className='px-5 text-dark'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make
              </p>

              <div className='mt-5 px-5 text-secondary'>
                <DragDropFileInput
                  accept={FileType.VIDEO_AND_IMAGE}
                  label='Upload Videos and Images Files'
                  limit={100}
                  placeholder='Click upload or drag and drop videos and Images files'
                  value={videosImagesInputValue}
                  onChange={handleUploadGallery}
                  fileFactory={fileFactory}
                />
              </div>

              <div className='mt-5 px-5 text-secondary'>
                <DragDropFileInput
                  accept={FileType.PDF}
                  label='Upload PDFs Files'
                  limit={100}
                  placeholder='Click upload or drag and drop PDFs files'
                  value={filesInputValue}
                  onChange={handleUploadPDFs}
                  fileFactory={fileFactory}
                />
              </div>

              <div className='mt-5 px-5 text-secondary'>
                <DragDropFileInput
                  accept={FileType.IMAGE}
                  label='Upload Image Files'
                  limit={1}
                  placeholder='Click upload or drag and drop image file'
                  value={logoInputValue}
                  onChange={handleUploadLogo}
                  fileFactory={fileFactory}
                />
              </div>

              <div className='px-5 text-center'>
                <Button
                  type='submit'
                  variant='primary'
                  className='my-5'
                  disabled={isFormikChanged}
                  onClick={() => formik.handleSubmit()}
                >
                  Upload contents {formik.isSubmitting && <Spinner />}
                </Button>
              </div>
            </div>
          </div>
          <div>
            <p className='my-5 text-primary'>{`Stand Code: ${poken?.code}`}</p>
          </div>
        </>
      ) : null}
    </>
  )
}
