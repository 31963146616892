import {useMemo} from 'react'
import {PortalSearchModel} from '../../../../../../models/customer-portal/PortalSearchModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {VoucherModel} from '../../../../../../models/svc/VoucherModel'
import {VoucherGroupModel} from '../../../../../../models/customer-portal/VoucherModel'
import {VoucherTableFlat} from './VoucherTableFlat'
import {VoucherTableGrouped} from './VoucherTableGrouped'
import {FilterInputContainer} from '../../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {MultiSelectToggleFilterInput} from '../../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {FilterType} from '../../../../../../components/tables/constants/FilterType'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {TableColumnOptions} from '../../../../../../components/tables/TableColumn'
import {ColumnStyle} from '../../../../../../components/tables/constants/ColumnStyle'
import {EventLink} from '../../../../../../components/utils/EventLink'
import {TimeSlotFormatter} from '../../../../../default/ems/components/tables/column-formatters/TimeSlotFormatter'
import {PortalShareStatusColumn} from '../../../../../customer-delegate/components/tables/PortalShareStatusColumn'
export interface VoucherTableProps {
  onFilter: (filter: FilterModel) => void
  data?: PortalSearchModel<VoucherGroupModel> | GlobalSearchModel<VoucherModel>
  customer?: CustomerModel
  onRefresh: () => void
  className?: string
  initialFilters?: FilterModel
  onRefreshCallback?: () => void
}

export const VoucherTable = ({
  onFilter,
  data,
  onRefresh,
  initialFilters,
  onRefreshCallback,
}: VoucherTableProps) => {
  const tableDataFlat = useMemo((): VoucherModel[] | VoucherGroupModel => {
    if (data) {
      if (Array.isArray(data.data)) return data.data
      else {
        return Object.entries(data.data).reduce<VoucherModel[]>((acc, [group, vouchers]) => {
          vouchers.forEach((voucher) => {
            acc.push(voucher)
          })
          return acc
        }, [])
      }
    }
    return []
  }, [data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<VoucherModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'event',
        label: 'Event',
        sortable: true,
        hideable: true,
        render: ({data}) => <EventLink event={data.event}>{data.event?.name}</EventLink>,
      },
      {
        field: 'startedAt',
        label: 'Start',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter className='text-uppercase'>{data.startedAt}</TimeSlotFormatter>
        ),
      },
      {
        field: 'endedAt',
        label: 'End',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter className='text-uppercase'>{data.endedAt}</TimeSlotFormatter>
        ),
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalShareStatusColumn data={data} />,
      },
    ]
    return columns
  }, [])

  return (
    <>
      {Array.isArray(tableDataFlat) ? (
        <VoucherTableFlat
          onFilter={onFilter}
          onRefreshCallback={onRefreshCallback}
          onRefresh={onRefresh}
          data={tableDataFlat}
          total={data?.total}
          columns={columns}
          initialFilters={initialFilters}
          advancedFilters={
            <FilterInputContainer>
              <MultiSelectToggleFilterInput
                field='status'
                items={FilterType.TICKET_VOUCHER_PORTAL_STATUS}
                label='Status'
              />
            </FilterInputContainer>
          }
        />
      ) : (
        <VoucherTableGrouped
          onRefreshCallback={onRefreshCallback}
          onFilter={onFilter}
          onRefresh={onRefresh}
          data={tableDataFlat}
          total={data?.total}
          columnsFlat={columns}
          initialFilters={initialFilters}
          advancedFilters={
            <FilterInputContainer>
              <MultiSelectToggleFilterInput
                field='status'
                items={FilterType.TICKET_VOUCHER_PORTAL_STATUS}
                label='Status'
              />
            </FilterInputContainer>
          }
        />
      )}
    </>
  )
}
