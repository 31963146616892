import {useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
import {useTicketTableActions} from '../../hooks/useTicketTableActions'
import {DelegatedForm, EmailSchema, initialValues} from '../waiverForms/DelegatedForm'
import {InitialValuesProps} from '../modals/WaiverDelegateModal'
import {DigitalWaiverCardActions} from './DigitalWaiverCardActions'
import {DigitalWaiverCardDetails} from './DigitalWaiverCardDetails'
import {DigitalWaiverCardFrontDetails} from './DigitalWaiverCardFrontDetails'
import moment from 'moment'
import {useBooleanState} from '../../../../components/hooks/useBooleanState'
import {WaiverStatusType} from '../../pages/DigitalWaiverTicketPage'
export interface DigitalWaiverCardProps {
  data: TicketWaiverModel
  loading?: boolean
  tabCode?: string
  waiverStatus?: WaiverStatusType
  isExpiredTicket?: boolean
  defaultImage?: string
  onDelegate?: (data: TicketWaiverModel) => void
  onSign?: (data: TicketWaiverModel) => void
  onDownload?: (data: TicketWaiverModel) => void
  onEdit?: (data: TicketWaiverModel) => void
  onRefresh: () => void
}

export const DigitalWaiverCard = ({
  data,
  tabCode,
  waiverStatus,
  isExpiredTicket = false,
  onSign,
  onDownload,
  onEdit,
  defaultImage,
  onRefresh,
}: DigitalWaiverCardProps) => {
  const {
    enableState: setHovered,
    disableState: setUnhovered,
    state: isHovered,
  } = useBooleanState(false)

  const [rotation, setRotation] = useState<string>()
  const [isDelegating, setIsDelegating] = useState<boolean>(false)
  const [isInfo, setIsInfo] = useState<boolean>(false)
  const {staticUrls} = useAppConfig()
  const {pushError} = useAlerts()

  const defaultProductImage = useMemo(() => {
    if (defaultImage) return defaultImage
    else return '/media/products/waiver-card.png'
  }, [defaultImage])

  const productImage = useMemo(() => {
    if (data.productName && staticUrls.public) {
      if (data.productName.toLocaleLowerCase().includes('romeo'))
        return `${staticUrls.public}/alfa-romeo.png`
      else if (data.productName.toLocaleLowerCase().includes('aston'))
        return `${staticUrls.public}/aston-martin.png`
      else if (data.productName.toLocaleLowerCase().includes('caterham'))
        return `${staticUrls.public}/caterham.png`
      else if (data.productName.toLocaleLowerCase().includes('drag'))
        return `${staticUrls.public}/drag.png`
      else if (data.productName.toLocaleLowerCase().includes('ferrari 458'))
        return `${staticUrls.public}/ferrari-458.png`
      else if (data.productName.toLocaleLowerCase().includes('formula yas'))
        return `${staticUrls.public}/formula-yas.png`
      else if (data.productName.toLocaleLowerCase().includes('polaris'))
        return `${staticUrls.public}/polaris.png`
      else if (data.productName.toLocaleLowerCase().includes('radical'))
        return `${staticUrls.public}/radical.png`
      else if (data.productName.toLocaleLowerCase().includes('taxi'))
        return `${staticUrls.public}/taxi.png`
      else if (data.productName.toLocaleLowerCase().includes('taycan'))
        return `${staticUrls.public}/taycan.png`
      else if (data.productName.toLocaleLowerCase().includes('car track day'))
        return `${staticUrls.public}/car-track-day.png`
      else if (data.productName.toLocaleLowerCase().includes('yas drift'))
        return `${staticUrls.public}/yas-drift.png`
    }
    return null
  }, [data.productName, staticUrls.public])

  useEffect(() => {
    if (isDelegating || isInfo) setRotation('rotateY(-180deg)')
    else setRotation(undefined)
  }, [isDelegating, isInfo])

  const {handleDelegateBulk} = useTicketTableActions({onRefresh})

  const image = useMemo(() => {
    if (staticUrls.public) {
      const diff = moment(new Date()).isAfter(data.endedAt)
      return (
        <div
          className='position-relative overflow-hidden'
          style={{
            height: '135px',
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
          }}
        >
          <img
            height='100%'
            width='100%'
            src={
              diff
                ? '/media/products/waiver-expired-card.png'
                : productImage || '/media/products/waiver-card.png'
            }
            alt='product'
            onError={({currentTarget}) => {
              currentTarget.src = defaultProductImage
              currentTarget.onerror = null
            }}
            style={{
              objectFit: 'cover',
            }}
          />
          {waiverStatus === 'previous' && (
            <div
              id='dfafdas'
              className='position-absolute top-0 start-0 w-100 h-100'
              style={{
                backgroundColor: `${getWaiverStatusVariant({data, tabCode, transparent: '65'})}`,
              }}
            ></div>
          )}
        </div>
      )
    }

    return (
      <div
        className='position-relative overflow-hidden'
        style={{
          height: '135px',
        }}
      >
        <img width='100%' src={defaultProductImage} alt='product' />
      </div>
    )
  }, [data, defaultProductImage, productImage, staticUrls.public, tabCode, waiverStatus])

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (data) {
        const response = await handleDelegateBulk(values, [data])
        if (response) {
          setIsDelegating(false)
          setRotation(undefined)
          onRefresh()
        }
      }
    },
    [data, handleDelegateBulk, onRefresh]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: EmailSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        handleSendEmail(values)
        resetForm()
      } catch (err) {
        pushError(err)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleOnDelegate = useCallback(() => {
    if (!isDelegating) {
      setIsDelegating(true)
      setIsInfo(false)
    } else {
      setIsDelegating(false)
      formik.resetForm()
    }
  }, [formik, isDelegating])

  const handleOnView = useCallback(() => {
    if (!isInfo) {
      setIsInfo(true)
      setIsDelegating(false)
    } else {
      setIsInfo(false)
    }
  }, [isInfo])

  const cardBox = useMemo(() => {
    return (
      <div className='px-3' onMouseOver={setHovered} onMouseLeave={setUnhovered}>
        <div className='card flip-card bg-brown rounded overflow-hidden'>
          <div
            className='flip-card-inner'
            style={{
              transform: rotation,
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              minHeight: '255px',
            }}
          >
            <div className='flip-card-inner-front d-flex flex-column justify-content-between'>
              <div className='rounded-0 shadow-lg h-100 d-flex flex-column'>
                <div className='w-100'>{image}</div>
                <DigitalWaiverCardFrontDetails
                  isHovered={isHovered}
                  data={data}
                  tabCode={tabCode}
                />
              </div>
            </div>
            <div className='flip-card-inner-back d-flex flex-column bg-brown'>
              {data.productName && (
                <p
                  className='text-start ff-admm-bold text-white fs-3 mb-0 px-5 pt-2 pb-3'
                  style={{
                    backgroundColor: getWaiverStatusVariant({data, tabCode}),
                  }}
                >
                  {data.productName}
                </p>
              )}

              {isInfo && (
                <div className='mt-5 px-4 h-100 overflow-auto'>
                  <DigitalWaiverCardDetails data={data} tabCode={tabCode} />
                </div>
              )}

              {isDelegating && (
                <div className='p-4'>
                  <form className='form' onSubmit={formik.handleSubmit}>
                    <DelegatedForm data={data} tabCode={tabCode} formik={formik} />
                  </form>
                </div>
              )}
            </div>
          </div>
          <div
            className='px-4 pb-3 pt-2'
            style={{
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
            }}
          >
            <DigitalWaiverCardActions
              data={data}
              onSign={onSign}
              onEdit={onEdit}
              onShare={formik.handleSubmit}
              onDownload={onDownload}
              onDelegate={handleOnDelegate}
              onView={handleOnView}
              tabCode={tabCode}
              rotation={rotation}
              isDelegating={isDelegating}
              isInfo={isInfo}
              isExpiredTicket={isExpiredTicket}
            />
          </div>
        </div>
      </div>
    )
  }, [
    setHovered,
    setUnhovered,
    rotation,
    image,
    isHovered,
    data,
    tabCode,
    isInfo,
    isDelegating,
    formik,
    onSign,
    onEdit,
    onDownload,
    handleOnDelegate,
    handleOnView,
    isExpiredTicket,
  ])

  return cardBox
}

interface getWaiverStatusVariantProps {
  data: TicketWaiverModel
  tabCode?: string
  transparent?: string
}
export const getWaiverStatusVariant = ({
  data,
  tabCode,
  transparent,
}: getWaiverStatusVariantProps) => {
  let status = ''
  const diff = moment(new Date()).isAfter(data.endedAt)
  if (!transparent) {
    transparent = 'ff'
  }
  if (diff) {
    if (!transparent || transparent === 'ff') status = 'expired'
    else status = 'expiredWithTint'
  } else {
    if (tabCode === 'available') {
      if (data.dwStatus) status = data.dwStatus
      else if (data.statusPortal) status = data.statusPortal
    }

    if (tabCode === 'shared') status = data.delegateStatus
  }
  switch (status) {
    case 'pending':
      return `#B4654A${transparent}`
    case 'awaiting-guardian':
      return `#b66d2b${transparent}`
    case 'approved':
      return `#0D7B40${transparent}`
    case 'submitted':
      return `#198996${transparent}`
    case 'rejected':
      return `#A50000${transparent}`
    case 'expired':
      return `#776767${transparent}`
    case 'review':
      return `#0d617b${transparent}`
    case 'expiredWithTint':
      return `#240C0C${transparent}`
    default:
      return `#D09700${transparent}`
  }
}
