import {ReactNode, useCallback} from 'react'
import {Group} from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {Bar} from '@visx/shape'
import {scaleBand, scaleLinear} from '@visx/scale'
import {defaultStyles} from '@visx/tooltip'
import {GridRows} from '@visx/grid'
import {AxisBottom, AxisLeft} from '@visx/axis'
import {BarChartDatum} from './BarChartDatum'
import {useBarChartHelpers} from './useBarChartHelpers'
import {TooltipContextProvider} from '../TooltipContext/TooltipContextProvider'
export type {BarChartDatum} from './BarChartDatum'

export interface VerticalBarChartProps {
  datum: BarChartDatum[]
  tooltip?: ReactNode
  yLabel?: string
  xLabel?: string
  isHideXLabel?: boolean
}

export const VerticalBarChart = ({
  datum,
  tooltip,
  xLabel,
  yLabel,
  isHideXLabel = false,
}: VerticalBarChartProps) => {
  const {
    TooltipInPortal,
    containerRef,
    getBarColor,
    handleBarSegmentMouseMove,
    handleContainerMouseMove,
    hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
  } = useBarChartHelpers({datum})

  const tickFormatter = useCallback(
    (key: string, index: number) => {
      return !isHideXLabel ? datum[index]?.label || '' : ''
    },
    [datum, isHideXLabel]
  )

  return (
    <div
      className={'h-100 w-100 d-flex'}
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      onMouseOut={hideTooltip}
    >
      <ParentSize>
        {({width, height}) => {
          const xMax = width - width * 0.1
          const yMax = height
          const xScale = scaleBand<string>({
            range: [0, xMax],
            round: true,
            domain: datum.map((d) => d.key),
            padding: 0.4,
          })
          const yScale = scaleLinear<number>({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...datum.map((d) => d.value))],
          })
          const numTicks = xMax < 500 ? 5 : 10
          return (
            <svg
              className='overflow-visible'
              style={{paddingLeft: `${width * 0.1}px`}}
              width={width}
              height={height}
            >
              <GridRows
                strokeDasharray='2,2'
                stroke='#A0A0A0'
                scale={yScale}
                width={xMax}
                height={yMax}
                numTicks={numTicks}
              />
              {yLabel && (
                <text
                  x={xMax / 2}
                  y={yMax + 35}
                  fill='white'
                  fontSize={12}
                  fontWeight='bold'
                  textAnchor='middle'
                >
                  {yLabel}
                </text>
              )}
              {xLabel && (
                <text x={-5} y={-10} fill='white' fontSize={12} fontWeight='bold' textAnchor='end'>
                  {xLabel}
                </text>
              )}
              <AxisLeft
                scale={yScale}
                numTicks={numTicks}
                tickFormat={yScale.tickFormat(1)}
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 11,
                  textAnchor: 'end',
                })}
              />
              <AxisBottom
                top={yMax}
                tickFormat={tickFormatter}
                scale={xScale}
                axisClassName=''
                stroke={'white'}
                numTicks={datum.length}
                tickStroke={'white'}
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 11,
                  textAnchor: 'middle',
                })}
              />
              <Group>
                {datum.map((d) => {
                  const barWidth = xScale.bandwidth()
                  const barHeight = yMax - (yScale(d.value) ?? 0)
                  const barX = xScale(d.key)
                  const barY = yMax - barHeight
                  return (
                    <Bar
                      onMouseMove={() => handleBarSegmentMouseMove(d)}
                      key={d.key}
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill={getBarColor(d.key)}
                    />
                  )
                })}
              </Group>
            </svg>
          )
        }}
      </ParentSize>
      <TooltipContextProvider value={{key: tooltipData}}>
        {tooltipOpen && tooltip && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={defaultStyles}>
            {tooltip}
          </TooltipInPortal>
        )}
      </TooltipContextProvider>
    </div>
  )
}
