import {useCallback, useEffect, useRef} from 'react'

export const useEventHandler = <T extends (...args: any[]) => ReturnType<T>>(handler: T) => {
  const handlerRef = useRef(handler)

  const handlerCallback = useCallback((...args: Parameters<T>) => handlerRef.current(...args), [])

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  return handlerCallback
}
