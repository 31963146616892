import clsx from 'clsx'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {NavButton} from './NavButton'

export interface NavHeaderProps {
  menus: NavigationSectionMenuItem[]
  type?: string
  className?: string
}

export const NavHeader = ({menus, type, className}: NavHeaderProps) => {
  return (
    <div className='d-flex overflow-auto h-100 w-100'>
      {menus.map((item, i) => (
        <NavButton
          type={type}
          className={clsx(
            'text-nowrap h-100',
            className,
            type === 'portal' && 'customer-portal-nav-button'
          )}
          key={i}
          item={item}
        />
      ))}
    </div>
  )
}
