import {useCallback, useMemo, useState} from 'react'

export const useRadioStates = () => {
  const [activeValue, setActiveValue] = useState<string | null>(null)

  const isValueActive = useCallback(
    (key: string) => {
      return activeValue === key
    },
    [activeValue]
  )

  const activateValue = useCallback((key: string) => {
    setActiveValue(key)
  }, [])

  const deactivateValue = useCallback((key?: string) => {
    setActiveValue((activeValue) => {
      if (!key || key === activeValue) {
        return null
      }
      return activeValue
    })
  }, [])

  const getDeactivateHandler = useCallback(
    (key: string) => {
      return () => {
        deactivateValue(key)
      }
    },
    [deactivateValue]
  )

  const getActivateHandler = useCallback(
    (key: string) => {
      return () => {
        activateValue(key)
      }
    },
    [activateValue]
  )

  return useMemo(
    () => ({
      isValueActive,
      activateValue,
      deactivateValue,
      getActivateHandler,
      getDeactivateHandler,
    }),
    [isValueActive, activateValue, deactivateValue, getActivateHandler, getDeactivateHandler]
  )
}
