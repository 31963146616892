import clsx from 'clsx'
import {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {MetronicIcon} from '../../../inputs/MetronicIcon'
import {useLayoutHelpers} from '../useLayoutHelpers'
import {NavButtonMenu} from './NavButtonMenu'
import {Button} from '../../../inputs/Button'

import {usePoratlTabs} from '../../../../modules/customer-portal/hooks/usePortalTabs'
export interface NavButtonProps {
  item: NavigationSectionMenuItem
  className?: string
  type?: string
}

export const NavButton = ({item, className, type}: NavButtonProps) => {
  const {childrenIsActive, isRouteActive} = useLayoutHelpers()
  const isActive = useMemo(
    () => isRouteActive(item.to) || childrenIsActive([item]),
    [childrenIsActive, isRouteActive, item]
  )

  const {downloadFromTab} = usePoratlTabs()
  const buttonClassNames = useMemo(() => {
    if (type && type === 'portal') {
      return clsx(
        'btn btn-text rotate rounded-0 text-uppercase',
        {
          'bg-primary text-black': isActive && !item.isSub,
          'text-primary': isActive && item.isSub,
        },
        className
      )
    }

    if (type && type === 'waiver') {
      return clsx(
        'btn btn-text rotate rounded-0 text-uppercase',
        {
          'bg-primary text-black': isActive && !item.isSub,
          'text-primary': isActive && item.isSub,
        },
        className
      )
    }

    return clsx(
      'btn btn-text rotate rounded-0',
      {
        'bg-light text-primary': isActive,
      },
      className
    )
  }, [className, isActive, item.isSub, type])

  const content = useMemo(() => {
    if (item.subMenus) {
      return (
        <>
          <button
            type='button'
            className={clsx(buttonClassNames)}
            data-kt-menu-trigger='hover'
            data-kt-menu-placement={item.direction === 'right' ? 'right-start' : 'bottom-start'}
          >
            {item.title}

            <MetronicIcon
              className='rotate-180 ms-3 me-0'
              iconType='Navigation'
              iconName='Angle-down'
            />
          </button>
          <NavButtonMenu items={item.subMenus} />
        </>
      )
    }
    if (item.to) {
      return (
        <Link to={item.to}>
          <button
            type='button'
            className={clsx('d-flex flex-column justify-content-center', buttonClassNames)}
          >
            {item.icon && typeof item.icon === 'string' && (
              <img width='60px' className='mb-2' alt={item.icon} src={item.icon} />
            )}
            <p
              className={clsx(
                'mb-0 position-relative',
                {
                  'second-nav-botton': item.isSub,
                  'btn-active': item.isSub && isActive,
                }
                // {
                //   'border border-start-0 border-end-0 border-top-0 border-bottom-3 ': item.isSub,
                // },
                // {
                //   'border-primary': isActive && item.isSub,
                // },
                // {
                //   'border-dark': !isActive && item.isSub,
                // }
              )}
            >
              {item.title}
            </p>
          </button>
        </Link>
      )
    }

    if (item.toExact) {
      return (
        // <a href={item.toExact} download target='_blank'>
        //   <button
        //     type='button'
        //     className={clsx('d-flex flex-column justify-content-center', buttonClassNames)}
        //   >
        //     {item.icon && typeof item.icon === 'string' && (
        //       <img width='60px' className='mb-2' alt={item.icon} src={item.icon} />
        //     )}
        //     <p
        //       className={clsx('mb-0 position-relative', {
        //         'second-nav-botton': item.isSub,
        //         'btn-active': item.isSub && isActive,
        //       })}
        //     >
        //       {item.title}
        //     </p>
        //   </button>

        // </a>
        <Button
          variant='text'
          className={clsx('d-flex flex-column justify-content-center', buttonClassNames)}
          onClick={downloadFromTab}
          uppercase={false}
        >
          {/* <MetronicIcon
          className='svg-icon-success svg-icon-1hx'
          iconType='Navigation'
          iconName='Down-2'
        /> */}
          {item.title}
        </Button>
      )
    }
  }, [buttonClassNames, downloadFromTab, isActive, item.direction, item.icon, item.isSub, item.subMenus, item.title, item.to, item.toExact])

  return <div>{content}</div>
}
