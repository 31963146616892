import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

const ContactSupport: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Application Support</PageTitle>
    </>
  )
}
export {ContactSupport}
