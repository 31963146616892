import {ChangeEvent, useCallback, useEffect, useMemo, useRef} from 'react'
import {TreeSelectNode} from '../TreeSelect/TreeSelectNode'

export interface TreeNodeCheckboxLastLevelProps {
  node: TreeSelectNode
  onChange: (newValue: boolean) => void
  onBlur?: () => void
  /**
   * If `radioName` is provided, the input will use a
   * radio button instead of a checkbox.
   * */
  radioName?: string
  removeParentRadioBtn?: boolean
  disabled?: (item: TreeSelectNode) => boolean
}

export const TreeNodeCheckboxLastLevel = ({
  node,
  onChange,
  radioName,
  removeParentRadioBtn = false,
  onBlur,
  disabled,
}: TreeNodeCheckboxLastLevelProps) => {
  const ref = useRef<HTMLInputElement>(null)

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onChange(true)
      } else {
        onChange(false)
      }
    },
    [onChange]
  )

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = node.isIndeterminate()
    }
  }, [node])

  const isInputDisabled = useMemo(() => {
    if (disabled) {
      return disabled(node)
    }
    return false
  }, [disabled, node])

  return (
    <div style={{display: 'inline-block', whiteSpace: 'nowrap'}}>
      {removeParentRadioBtn && !node.selected ? (
        <label className='form-check-label'>{node.label}</label>
      ) : (
        <label className='form-check-label'>
          <input
            ref={ref}
            style={{
              border: '1px solid gray',
              cursor: 'pointer',
            }}
            className='form-check-input me-2'
            type={radioName ? 'radio' : 'checkbox'}
            checked={node.selected}
            name={radioName}
            onChange={handleOnChange}
            onBlur={onBlur}
            disabled={isInputDisabled}
          />
          {node.label}
        </label>
      )}
    </div>
  )
}
