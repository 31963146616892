import {TableSelectionAction} from '../../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../../components/tables/TableRow'
import {VoucherModel} from '../../../../../../models/svc/VoucherModel'
import {idExtractor} from '../../../../../../utils/idExtractor'
import {isVoucherShareable} from '../../../../hooks/useVoucherTableActions'
export interface VoucherTableSelectionActionsProps {
  onDisable: (vouchers: VoucherModel[]) => void
  items: VoucherModel[]
  selected: TableRowId[]
  disabled?: boolean
}

export const VoucherTableSelectionActions = ({
  items,
  onDisable,
  selected,
  disabled,
}: VoucherTableSelectionActionsProps) => {
  return (
    <>
      <TableSelectionAction
        idExtractor={idExtractor}
        onClick={onDisable}
        selected={selected}
        items={items}
        filter={isVoucherShareable}
        variant='primary'
        disabled={disabled}
      >
        Disable Vouchers
      </TableSelectionAction>
    </>
  )
}
