import {useCallback, useMemo} from 'react'
import {FilterTable} from '../../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../../components/tables/TableColumn'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {BookingDetailModel} from '../../../../../../models/customer-portal/BookingDetailModel'
import {DateUtil} from '../../../../../../utils/DateUtil'
import {CustomerBookingTableActions} from '../../../tables/BookingProductTable/CustomerBookingDetailTableActions'
import {FilterModel} from '../../../../../../models/FilterModel'
import {useTableOptions} from '../../../../../../components/tables/useTableOptions'
import {ColumnStyle} from '../../../../../../components/tables/constants/ColumnStyle'
import {Link} from 'react-router-dom'
import {BookingProductTypeColumn} from '../../../../../default/ems/components/tables/column-formatters/BookingProductTypeColumn'
import {ProductTicketTypeColumn} from '../../../../../default/ems/components/tables/column-formatters/ProductTicketTypeColumn'
import {SeatMapPortalSelectionModalForm} from '../../../../../../components/forms/SeatMapPortalSelectionModalForm'
import {NonSeatedTimeslotDateModalForm} from '../../../../../default/svc/components/tables/CustomerProductBookingDetailTable/modals/NonSeatedTimeslotDateModalForm'
import moment from 'moment'
import {BookingProductStatusColumn} from '../../../tables/BookingProductStatusColumn'
import {useProductBookingDetailsTableHelpers} from '../../../../hooks/useProductBookingDetailsTableHelpers'

export interface ProductBookingDetailsTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<BookingDetailModel>
  onRefresh?: () => void
  linkFactory?: (data: BookingDetailModel) => string | void | (() => void)
  removedColumns?: string[]
  customerCode?: string
}

export const ProductBookingDetailsTable = ({
  data,
  onFilter,
  onRefresh,
  linkFactory,
  removedColumns,
  customerCode,
}: ProductBookingDetailsTableProps) => {
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'product-booking-detail',
  })

  const {
    fulfillNonSeatedProduct,
    fulfillTicket,
    getModalProps,
    isItemLoading,
    setModalData,
    modalData,
    handleFulfillTimeslotSubmit,
  } = useProductBookingDetailsTableHelpers({onRefresh})

  const handleOnAssignSeats = useCallback(
    (data: BookingDetailModel) => {
      setModalData({
        data,
        modalType: 'assign-seated',
      })
    },
    [setModalData]
  )

  const idExtractor = useCallback((data: BookingDetailModel) => {
    return data.code + data.bookingProductCode + data.customerCode
  }, [])

  const rowActions = useCallback(
    (data: BookingDetailModel) => (
      <CustomerBookingTableActions
        data={data}
        onAssignSeats={handleOnAssignSeats}
        onFulfillNonSeatedProduct={fulfillNonSeatedProduct}
        loading={isItemLoading(data)}
      />
    ),
    [fulfillNonSeatedProduct, handleOnAssignSeats, isItemLoading]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<BookingDetailModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        render: ({data}) => {
          if (data.bookingProductStatus === 'pending') {
            return <>{data.code}</>
          }
          if (linkFactory) {
            const link = linkFactory(data)
            if (typeof link === 'function') {
              return (
                <span className='text-primary' role='button' onClick={link}>
                  {data.code}
                </span>
              )
            }
            if (link) {
              return <Link to={link}>{data.code}</Link>
            }
          }
          return <>{data.code}</>
        },
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'description',
        label: 'Description',
        cellStyle: ColumnStyle.DESCRIPTION,
        hideable: true,
      },
      {
        field: 'productCategory',
        label: 'Category',
        sortable: true,
        hideable: true,
        dataExtract: (product) => product?.productCategoryName,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        type: 'date',
        dataExtract: (product) => DateUtil.getDateFromApiString(product.startedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        type: 'date',
        dataExtract: (product) => DateUtil.getDateFromApiString(product.endedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'type',
        label: 'Ticket Type',
        sortable: true,
        hideable: true,
        render: ProductTicketTypeColumn,
      },
      {
        field: 'isSeated',
        label: 'Seated',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isSeated,
      },
      {
        field: 'booking',
        label: 'Qty',
        sortable: false,
        hideable: true,
        type: 'number',
        dataExtract: (product) => product.bookingProductQty,
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: BookingProductStatusColumn,
      },
      {
        field: 'bookingProductType',
        label: 'Product Type',
        sortable: true,
        hideable: true,
        render: BookingProductTypeColumn,
      },
    ]
    if (removedColumns) {
      return columns.filter((column) => !removedColumns.includes(column.field))
    }
    return columns
  }, [linkFactory, removedColumns])

  const activeProduct = useMemo(() => {
    if (modalData.data && 'code' in modalData.data) {
      return modalData.data
    }
  }, [modalData.data])

  const activeProductStart = useMemo(() => {
    const today = moment().startOf('day')
    if (modalData.data && 'code' in modalData.data) {
      const startedAt = DateUtil.getDateFromApiString(modalData.data.startedAt)
      return moment.max(today, moment(startedAt)).toDate()
    }
    return today.toDate()
  }, [modalData.data])

  const activeProductEnd = useMemo(() => {
    if (modalData.data && 'code' in modalData.data) {
      return DateUtil.getDateFromApiString(modalData.data.endedAt)
    }
  }, [modalData.data])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={hiddenColumns}
        onHiddenColumnsChange={setHiddenColumns}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columns}
        totalItems={data?.total}
        actions={rowActions}
      />
      <SeatMapPortalSelectionModalForm
        onSubmit={fulfillTicket}
        product={activeProduct}
        customerCode={customerCode}
        submitButtonClassName='btn-cut-conrner pe-20 btn btn-primary'
        open={getModalProps('assign-seated').isOpen}
        onHide={() => getModalProps('assign-seated').onClose()}
        isCustom
      />
      <NonSeatedTimeslotDateModalForm
        onSubmit={handleFulfillTimeslotSubmit}
        qty={activeProduct?.bookingProductQty}
        maxDate={activeProductEnd}
        minDate={activeProductStart}
        isPortal={true}
        {...getModalProps('assign-non-seated-timeslots')}
      />
    </>
  )
}
