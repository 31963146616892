import clsx from 'clsx'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {TreeSelectNode} from '../TreeSelect/TreeSelectNode'
import {TreeSelectNodeCollection} from '../TreeSelect/TreeSelectNodeCollection'
import {TreeNodeLastLevel} from './TreeNodeLastLevel'

export interface TreeNodeItem {
  label: string
  value: string
  disabled?: boolean
  items?: TreeNodeItem[]
}

export interface TreeNodeProps {
  className?: string
  items: TreeNodeItem[]
  values: string[]
  onChange: (newValues: string[]) => void
  radioName?: string
  label: string
  hasNone?: boolean
  onBlur?: () => void
  isLastLevelSelect?: boolean
  disabled?: (item: TreeSelectNode) => boolean
}

export const TreeSelectLastLevel = ({
  label,
  items,
  values,
  onChange,
  radioName,
  hasNone,
  className,
  onBlur,
  disabled,
  isLastLevelSelect = false,
}: TreeNodeProps) => {
  const [itemTree, setItemTree] = useState<TreeSelectNodeCollection | null>(null)
  const [valueTree, setValueTree] = useState<TreeSelectNodeCollection | null>(null)

  const handleChange = useCallback(
    (tree: TreeSelectNodeCollection, node: TreeSelectNode) => {
      const values = tree.getApiValues()
      if (radioName) {
        onChange([node.id])
      } else {
        onChange(values)
      }
    },
    [onChange, radioName]
  )

  useEffect(() => {
    if (itemTree) {
      if (radioName) {
        const tree = itemTree.clone()
        tree.setValues(values, true)
        setValueTree(tree)
      } else {
        const tree = itemTree.clone()
        tree.setValuesRecursively(values, true)
        setValueTree(tree)
      }
    }
  }, [itemTree, values, radioName])

  useEffect(() => {
    const itemsCopy = [...items]
    if (radioName && hasNone) {
      itemsCopy.unshift({value: '', label: 'None'})
    }
    const tree = new TreeSelectNodeCollection()
    tree.addItemsRecursively(itemsCopy)
    setItemTree(tree)
  }, [items, hasNone, radioName])

  const rootNodes = useMemo(() => {
    if (valueTree) {
      return valueTree.nodes.map((node) => {
        return (
          <TreeNodeLastLevel
            level={0}
            tree={valueTree}
            disabled={disabled}
            onChange={handleChange}
            key={node.id}
            node={node}
            radioName={radioName}
            onBlur={onBlur}
            isLastLevelSelect={isLastLevelSelect}
          />
        )
      })
    }
    return null
  }, [valueTree, disabled, handleChange, radioName, onBlur, isLastLevelSelect])

  return (
    <div className={clsx('mb-5 text-start', className)}>
      <label className='form-label'>{label}</label>
      <div
        className='form-control form-control-solid'
        style={{
          overflowX: 'auto',
          minHeight: '10rem',
          maxHeight: '20rem',
        }}
      >
        {rootNodes}
      </div>
    </div>
  )
}
