import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {MetronicIcon} from '../inputs/MetronicIcon'
import {DetailHeaderTabDropDownTab} from './DetailHeaderTabDropDown'
import {useTabItemHelper} from './useTabItemHelper'
import { MenuDirectionType } from '../../../config/drawer'

export interface DetailHeaderTabProps {
  onClick?: () => void
  link?: {
    to: string
    exact?: boolean
  }
  active?: boolean
  label: string
  items?: DetailHeaderTabProps[]
  transitory?: boolean
  direction?: MenuDirectionType | null
}

export const DetailHeaderTab = (props: DetailHeaderTabProps) => {
  const {link, label, onClick, items, direction, transitory} = props
  const {checkItemIsActive} = useTabItemHelper()
  const getClassName = useCallback((isActive?: boolean) => {
    const baseClassName = 'nav-link text-nowrap text-active-primary me-6'
    return clsx(baseClassName, {active: isActive})
  }, [])

  const tabNode = useMemo(() => {
    const isActive = checkItemIsActive(props)
    const shouldShow = !transitory || (transitory && isActive)

    if (!shouldShow) {
      return null
    }

    if (link) {
      return (
        <Link onClick={onClick} className={getClassName(isActive)} to={transitory ? '#' : link.to}>
          {label}
        </Link>
      )
    }
    if (items) {
      return (
        <>
          <div
            role='button'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement={direction === 'right' ? 'right-start' : 'bottom-start'}
            className={getClassName(isActive)}
            onClick={onClick}
          >
            {label}
            <MetronicIcon className='m-0 ms-3' iconType='Navigation' iconName='Angle-down' />
          </div>
          <DetailHeaderTabDropDownTab items={items} />
        </>
      )
    }
    return (
      <div role='button' className={getClassName(isActive)} onClick={onClick}>
        {label}
      </div>
    )
  }, [checkItemIsActive, direction, getClassName, items, label, link, onClick, props, transitory])

  if (!tabNode) {
    return null
  }

  return <li className='nav-item'>{tabNode}</li>
}
