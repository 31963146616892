import {useCallback} from 'react'
import * as yup from 'yup'
import {CardPaymentMethod, CardPaymentMethodFormValues} from './CardPaymentMethod'
import {ChoosePaymentMethod, PaymentMethod} from './ChoosePaymentMethod'
import {YasPaymentMethod} from './YasPaymentMethod'
import {FormikContextType} from 'formik'
import {Cart} from '../../../../../utils/Cart'

export interface PaymentMethodFormValues extends CardPaymentMethodFormValues {
  yasPay: string
  paymentMethod: string
  cardHolder: string
}

export interface CheckoutProps<FormValues extends PaymentMethodFormValues> {
  onBack: () => void
  formik: FormikContextType<FormValues>
  onPay: () => void
  cart: Cart
  disableScan?: boolean
  disableSubmit?: boolean
  payfortFormRef?: React.Ref<HTMLFormElement>
}

export const checkoutFormValidator = yup.object().shape({
  customerEmail: yup.string().when('paymentMethod', {
    is: 'card',
    then: (schema) =>
      schema.email('Please enter a valid email address').required('Please enter an email address.'),
  }),
  cardNumber: yup.string().when('paymentMethod', {
    is: 'card',
    then: (schema) =>
      schema
        .length(16, 'Please enter a valid card number')
        .required('Please enter your card number'),
  }),
  cvv: yup.string().when('paymentMethod', {
    is: 'card',
    then: (schema) =>
      schema
        .length(3, 'Please enter a valid CVV')
        .required('Please enter the three digit number on the back of your card.'),
  }),
  cardExpiryMonth: yup.string().when('paymentMethod', {
    is: 'card',
    then: (schema) => schema.required('Please enter your card expiry month.'),
  }),
  cardExpiryYear: yup.string().when('paymentMethod', {
    is: 'card',
    then: (schema) => schema.required('Please enter your card expiry year.'),
  }),
  cardHolder: yup.string().when('paymentMethod', {
    is: 'card',
    then: (schema) => schema.required("Please enter the card holder's name"),
  }),
  yasPay: yup.string().when('paymentMethod', {
    is: 'yas-pay',
    then: (schema) => schema.required('Please enter your yas pay code.'),
  }),
})

export const Checkout = <T extends PaymentMethodFormValues>({
  onBack,
  formik,
  onPay,
  cart,
  disableScan,
  payfortFormRef,
  disableSubmit,
}: CheckoutProps<T>) => {
  const resetPaymentMethod = useCallback(() => {
    formik.setFieldValue('paymentMethod', '')
  }, [formik])

  const handleSetPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => {
      formik.setFieldValue('paymentMethod', paymentMethod)
    },
    [formik]
  )

  switch (formik.values.paymentMethod) {
    case 'card':
      return (
        <CardPaymentMethod
          disableSubmit={disableSubmit}
          onPay={onPay}
          formik={formik}
          onBack={resetPaymentMethod}
          formRef={payfortFormRef}
        />
      )
    case 'yas-pay':
      return (
        <YasPaymentMethod
          onPay={onPay}
          formik={formik}
          onBack={resetPaymentMethod}
          disableScan={disableScan}
        />
      )
    default:
      return <ChoosePaymentMethod cart={cart} onChoose={handleSetPaymentMethod} onBack={onBack} />
  }
}
