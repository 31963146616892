import {Link} from 'react-router-dom'
import {CustomerModel} from '../../../../models/CustomerModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'

interface TicketCodeColumnForBookingProps {
  data: TicketPortalModel
  event?: EventModel
  customer?: CustomerModel
}
export const TicketCodeColumnForBooking = ({
  data,
  event,
  customer,
}: TicketCodeColumnForBookingProps) => {
  if (event) {
    return (
      <Link
        className='min-w-80px'
        to={`/event/${event.code}/tickets/customer/${data.customerCode}/booking/${data.bookingCode}/booking-detail`}
      >
        {data.bookingCode}
      </Link>
    )
  }
  if (customer)
    return (
      <Link
        className='min-w-80px'
        to={`/customer/${data.customerCode}/booking/${data.bookingCode}/booking-detail`}
      >
        {data.code}
      </Link>
    )

  return <span>{data.code}</span>
}
