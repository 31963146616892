import {useCallback} from 'react'
import {HasCode} from '../../../utils/idExtractor'
import {PushNotification} from '../../../utils/PushNotification'
import {GetPublicVapidKey, SendPushSubscription} from '../redux/EvaCRUD'

export const usePushNotification = () => {
  const subscribeToPushNotifications = useCallback(async (user: HasCode) => {
    try {
      await PushNotification.askUserPermission()
      const {data} = await GetPublicVapidKey()
      const subscription = await PushNotification.getNotificationSubscription(data.vapidPublicKey)
      await SendPushSubscription(user.code, subscription)
    } catch (e) {
      console.error(e)
      // User denied permission or push not supported.
    }
  }, [])

  return {subscribeToPushNotifications}
}
