import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useSafeStateUpdate} from '../../../../../components/hooks/useSafeStateUpdate'
import {
  EMPTY_INITIAL_VALUES,
  getPayload,
  validationSchema,
  ReservationFormValues,
  ReservationForm,
} from './ReservationForm'
import {CreateTableReservation} from '../../../redux/OutletCRUD'
import {actions} from '../../../redux/OutletRedux'

export interface ReservationCreateDrawerProps {
  drawerRef?: (drawer: Drawer | null) => void
  onClose?: () => void
}

export const ReservationCreateDrawer = ({drawerRef, onClose}: ReservationCreateDrawerProps) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [drawer, setDrawer] = useDrawerRef()
  const {pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()
  const dispatch = useDispatch()

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      setDrawer(drawer)
      drawerRef && drawerRef(drawer)
    },
    [setDrawer, drawerRef]
  )

  const updateOutlet = useCallback(
    async (values: ReservationFormValues) => {
      const payload = getPayload(values)
      try {
        setIsFormLoading(true)
        await CreateTableReservation(payload)
        dispatch(actions.reservations.updateSuccess())
        drawer?.hide()
      } catch (e) {
        pushError(e)
        dispatch(actions.reservations.updateFailed())
      } finally {
        safeUpdate(() => {
          setIsFormLoading(false)
        })
      }
    },
    [pushError, dispatch, drawer, safeUpdate]
  )

  const formik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    onSubmit: updateOutlet,
    validationSchema: validationSchema,
    validateOnMount: true,
  })

  const drawerCloseCleanup = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  return (
    <Drawer ref={handleDrawerRef} onClose={drawerCloseCleanup}>
      <ReservationForm
        isLoading={isFormLoading}
        formik={formik}
        title='Create Table Reservation'
        onCloseButtonClick={drawer?.hide}
        onSubmit={formik.handleSubmit}
      />
    </Drawer>
  )
}
