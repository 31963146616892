import {ChangeEvent, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  SelectTreeOptionGroup,
  SelectTreeOptionGroupItem,
} from '../SelectTreeNative/SelectTreeOptionGroup'

export type ProductInputItemType = 'bundle' | 'product'

export interface ProductInputItemAttributes extends SelectTreeOptionGroupItem {
  type?: ProductInputItemType
  items?: ProductInputItemAttributes[]
  price?: number
}

export interface ProductInputItemValue {
  count: number
  value: string
  id: number | string
  type?: ProductInputItemType
  price?: number
  name?: string
}

export interface ProductInputItemProps {
  className?: string
  value: ProductInputItemValue
  onChange: (value: ProductInputItemValue) => void
  items: ProductInputItemAttributes[]
  selectedItems: string[]
  placeholder: string
  label: string
  onRemove: (value: ProductInputItemValue) => void
}

export const ProductInputItem = ({
  items,
  onChange,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
}: ProductInputItemProps) => {
  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: ProductInputItemValue = {...value, count: Number(count)}
      onChange(newValue)
    },
    [onChange, value]
  )

  const recursiveSearch = useCallback(
    (value: string, items: ProductInputItemAttributes[]): ProductInputItemAttributes | null => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.value === value) {
          return item
        }
        if (item.items) {
          const foundChild = recursiveSearch(value, item.items)
          if (foundChild) {
            return foundChild
          }
        }
      }
      return null
    },
    []
  )

  const handleSelectionChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectionValue = e.target.value
      const item = recursiveSearch(selectionValue, items)
      const newValue: ProductInputItemValue = {
        ...value,
        value: selectionValue,
        type: item?.type,
        price: item?.price,
        name: item?.label,
      }
      onChange(newValue)
    },
    [value, onChange, items, recursiveSearch]
  )

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const checkIsSelected = useCallback(
    (value: string) => {
      return selectedItems.includes(value)
    },
    [selectedItems]
  )

  const getTreeSelectItems = useCallback(
    (items: SelectTreeOptionGroupItem[]) => {
      return items.map((item) => {
        const treeItem: SelectTreeOptionGroupItem = {
          label: item.label,
          value: item.value,
          disabled: item.disabled || (item.value && checkIsSelected(item.value)) || false,
        }
        if (item.items) {
          treeItem.items = getTreeSelectItems(item.items)
        }
        return treeItem
      })
    },
    [checkIsSelected]
  )

  const treeSelectItems = useMemo((): SelectTreeOptionGroupItem[] => {
    return getTreeSelectItems(items)
  }, [items, getTreeSelectItems])

  return (
    <div className={clsx('product-input-item', className)}>
      <label className='form-label'>{label}</label>
      <div className='product-input-item-input-container'>
        <SelectTreeOptionGroup
          noMargin
          className='product-input-item-input-container__select-input'
          onChange={handleSelectionChange}
          placeholder={placeholder}
          value={value.value}
          items={treeSelectItems}
        />
        <input
          onChange={handleCountChange}
          className='product-input-item-input-container__number-input form-control form-control-solid'
          type='number'
          disabled={value.value === ''}
          value={value.count}
          min={0}
        />
        <button
          type='button'
          className='btn btn-sm btn-icon btn-active-light-primary'
          onClick={handleRemove}
        >
          <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
        </button>
      </div>
    </div>
  )
}
