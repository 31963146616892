import {useCallback, useMemo, useState} from 'react'
import {FormikContextType} from 'formik'
import {BookingWizardCustomerStep} from './steps/BookingWizardCustomerStep'
import {BookingWizardFinalizeStep} from './steps/BookingWizardFinalizeStep'
import {WizardControlProvider} from '../../../../../components/forms/Wizard/WizardControlProvider'
import {useWizardFormikHelpers} from '../../../../../components/forms/Wizard/useEventWizardHelpers'
import {WizardControls, WizardStep} from '../../../../../components/forms/Wizard/WizardControls'
import {WizardSteppers} from '../../../../../components/forms/Wizard/WizardSteppers'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {BookingWizardEventStep} from './steps/BookingWizardEventStep'
import {BookingWizardCreateCustomerStep} from './steps/BookingWizardCreateCustomerStep'
import {BookingFormValues} from '../../../../../models/booking-wizard/BookingWizard'

import {BookingWizardFinalEditStep} from './steps/BookingWizardFinalEditStep'
import {BookingWizardProductVoucherStep} from './steps/BookingWizardProductVoucherStep'

export interface BookingWizardProps {
  formik: FormikContextType<BookingFormValues>
  disabledFields?: Partial<Record<keyof BookingFormValues, boolean>>
  hiddenFields?: Partial<Record<keyof BookingFormValues, boolean>>
  step: number
  onStepChange: (step: number) => void
  event?: EventModel | null
  customer?: CustomerModel
  isEdit?: boolean
  onSubmit?: () => void
}

export const BookingWizard = ({
  formik,
  hiddenFields,
  disabledFields,
  step: currentStep,
  onStepChange,
  event,
  customer,
  isEdit,
  onSubmit,
}: BookingWizardProps) => {
  const [noCustomer, setNoCustomer] = useState<boolean>(true)
  const {getStepState} = useWizardFormikHelpers({
    formik,
    currentStep,
    noErrors: currentStep === 3,
  })

  useOnChange(event, () => {
    formik.setFieldValue('eventCode', event?.code)
  })

  const isStepperShown = useCallback(
    (stepperFields: (keyof BookingFormValues)[]) => {
      if (!hiddenFields) {
        return true
      }
      const shouldShow = !stepperFields.every((field) => !!hiddenFields[field])
      return shouldShow
    },
    [hiddenFields]
  )

  const steps = useMemo((): WizardStep[] => {
    if (customer) {
      return [
        {
          title: 'Event',
          description: 'Search Event',
          state: getStepState(0, ['eventcode']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['eventCode'],
          hidden: !isStepperShown(['eventCode']),
        },
        {
          title: 'Event',
          description: 'Search Event',
          state: getStepState(1, ['eventcode']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['eventCode'],
          hidden: true,
        },
        {
          title: 'Product/Voucher',
          description: 'select product & voucher',
          state: getStepState(2, ['products', 'vouchers']),
          icon: {
            iconType: 'Communication',
            iconName: 'Ticket',
          },
          fields: ['products', 'vouchers'],
          hidden: !isStepperShown(['products', 'vouchers']),
        },
        {
          title: 'Finalize',
          description: 'Fulfill booking products',
          state: getStepState(3, []),
          icon: {
            iconType: 'Communication',
            iconName: 'Flag-pole',
          },
        },
      ]
    } else if (event)
      return [
        {
          title: 'Search Customer',
          description: 'Search for customer',
          state: getStepState(0, ['customer']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['customer'],
          hidden: !isStepperShown(['customer']),
        },
        {
          title: 'New Customer',
          description: 'Create customer',
          state: getStepState(1, ['customer']),
          icon: {
            iconType: 'Communication',
            iconName: 'Add-user',
          },
          fields: ['customer'],
          hidden: Boolean(isEdit),
        },
        {
          title: 'Products',
          description: 'Select product & add-on',
          state: getStepState(2, ['products', 'vouchers']),
          icon: {
            iconType: 'Communication',
            iconName: 'Ticket',
          },
          fields: ['products', 'vouchers'],
          hidden: !isStepperShown(['products', 'vouchers']),
        },
        {
          title: 'Finalize',
          description: 'Fulfill booking products',
          state: getStepState(3, ['customersSeats']),
          hidden: !isStepperShown(['customersSeats']),
          fields: ['customersSeats'],
          icon: {
            iconType: 'Communication',
            iconName: 'Flag-pole',
          },
        },
      ]
    return []
  }, [customer, event, getStepState, isEdit, isStepperShown])

  const handleCustomerStepChange = useCallback(
    (step: number) => {
      formik.setFieldValue('customer', '')
      onStepChange(step)
    },
    [formik, onStepChange]
  )

  const handleNoCustonmers = useCallback((is: boolean) => {
    if (is) {
      setNoCustomer(false)
    } else {
      setNoCustomer(true)
    }
  }, [])

  const handleCreateCustomer = useCallback(() => {
    onStepChange(2)
  }, [onStepChange])

  const stepPage = useMemo(() => {
    switch (currentStep) {
      case 0:
        if (customer) {
          return <BookingWizardEventStep formik={formik} disabledFields={disabledFields} />
        } else {
          return (
            <BookingWizardCustomerStep
              formik={formik}
              disabledFields={disabledFields}
              event={event}
              isEdit={isEdit}
              onStepChange={handleCustomerStepChange}
              onNoCustonmers={isEdit ? handleNoCustonmers : undefined}
            />
          )
        }
      case 1:
        return (
          <BookingWizardCreateCustomerStep
            formik={formik}
            handleCreateCustomer={handleCreateCustomer}
          />
        )
      case 2:
        return <BookingWizardProductVoucherStep formik={formik} event={event} />
      case 3:
        return isEdit ? (
          <BookingWizardFinalEditStep formik={formik} />
        ) : (
          <BookingWizardFinalizeStep formik={formik} />
        )
    }
  }, [
    currentStep,
    customer,
    disabledFields,
    event,
    formik,
    handleCreateCustomer,
    handleCustomerStepChange,
    handleNoCustonmers,
    isEdit,
  ])

  const handeleStepChange = useCallback(
    (page: number, type) => {
      if (page === 1 && formik.values.customer) {
        if (type === 'increament') {
          onStepChange(page + 1)
        }

        if (type === 'decreament') {
          onStepChange(page - 1)
        }
      } else onStepChange(page)
    },
    [formik.values.customer, onStepChange]
  )

  return (
    <WizardControlProvider
      currentPage={currentStep}
      onPageChange={handeleStepChange}
      steps={steps}
      isHasPrevious={isEdit ? (currentStep === 3 || currentStep === 0 ? false : true) : undefined}
    >
      <div className='row'>
        <div className='col-12 position-relative d-flex justify-content-start px-16'>
          <WizardSteppers currentStep={currentStep} steps={steps} className='rs-stepper' />
        </div>
        <div className='col-12 position-relative mt-5 rs-top'>{stepPage}</div>
        <div className='col-12 position-relative rs-top px-10'>
          <WizardControls
            formik={formik}
            steps={steps}
            submitLabel='Done'
            nextLable={
              isEdit
                ? currentStep === 2
                  ? isEdit
                    ? 'Edit Booking'
                    : 'Book now'
                  : 'Next'
                : undefined
            }
            noErrors={isEdit ? currentStep === 3 : undefined}
            isHasNext={currentStep === 0 ? noCustomer : currentStep === 1 ? false : true}
            onSubmit={onSubmit}
            nextClassName='ps-10 pe-20 position-relative'
            cornerNextClassName='btn-cut-conrner'
            isPreviousButton={isEdit ? (currentStep === 3 ? false : true) : undefined}
          />
        </div>
      </div>
      {/* </div> */}
    </WizardControlProvider>
  )
}
