import {ReactNode, useMemo} from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {useCustomerAuth} from '../../modules/customer-portal/hooks/useCustomerAuth'
import {AuthPage} from '../../modules/customer-portal/pages/AuthPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import {
  PORTAL_USER_DEFAULT_LAYOUT_MENUS,
  PORTA_DEFAULT_LAYOUT_MENUS,
} from '../../../config/portalDrawer'
import {ResellerLayout} from '../../components/layouts/DefaultLayout/ResellerLayout'
import {HeaderControls} from '../../components/layouts/DefaultLayout/Header/HeaderControls'
import {AuthPortalHeaderThumbnail} from '../../modules/customer-portal/components/AuthPortalHeaderThumbnail'
import {ADMMCustomerPortalMenuHeaderLogo} from '../../../config/logos'
import {AuthStateProvider} from '../../modules/customer-portal/components/forms/AuthStateContext'
import {PortalDownloadTicketByWhatsApp} from '../../modules/customer-portal/pages/PortalDownloadTicketByWhatsApp'

export const Routes = () => {
  const auth = useCustomerAuth()
  const user = auth?.getUser()
  const {pathname} = useLocation()

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <ResellerLayout
        menus={
          user?.type !== 'user' ? PORTA_DEFAULT_LAYOUT_MENUS : PORTAL_USER_DEFAULT_LAYOUT_MENUS
        }
        logo={ADMMCustomerPortalMenuHeaderLogo}
      >
        <HeaderControls>
          <AuthPortalHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </ResellerLayout>
    )
  }, [auth, user])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthPage />
        </Route>
      )
    }

    if (pathname.includes('/ticket')) {
      return <Redirect from='/auth' to={pathname} />
    }

    return <Redirect from='/auth' to='/' />
  }, [auth, pathname])

  return (
    <AuthStateProvider>
      <Switch>
        <Route path='/ticket/:hash'>
          <PortalDownloadTicketByWhatsApp />
        </Route>

        <Route path='/logout' component={Logout} />
        <Route path='/auth/login/:hash' component={AuthPage} />
        <Route path='/auth/login' component={AuthPage} />
        <Route path='/error/404'>
          <FullPage404 link='/' />
        </Route>
        <Route path='/error/403'>
          <FullPage403 link='https://webntech.ae' />
        </Route>
        {unauthenticatedRoutes}
        {authenticatedRoutes}
      </Switch>
    </AuthStateProvider>
  )
}
