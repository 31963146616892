import {AxiosResponse} from 'axios'
import {useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {CsvModalSubmitFunction} from '../../../../components/forms/CsvMapModal'
import {useLoadingState} from '../../../../components/hooks/useLoadingState'
import {useModalState} from '../../../../components/modals/useModalState'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'

export interface UseSectionHandlersOptions<T> {
  processImport: () => Promise<unknown>
  onImportSearch: (filters?: FilterModel) => Promise<AxiosResponse<GlobalSearchModel<T>>>
  onImport: (
    mapping: string[],
    file: File,
    hasHeaders?: boolean
  ) => Promise<AxiosResponse<GlobalSearchModel<T>>>
  name: string
}

export const useSectionHandlers = <T>({
  processImport,
  onImportSearch,
  onImport,
  name,
}: UseSectionHandlersOptions<T>) => {
  const {pushError, push} = useAlerts()
  const modalState = useModalState()
  const {setIsLoading, isLoading} = useLoadingState()
  const [tableSearchResult, setTableSearchResult] = useState<GlobalSearchModel<T>>()
  const [hasImported, setHasImported] = useState(false)
  const history = useHistory()
  const finalizeImport = useCallback(async () => {
    const doneLoading = setIsLoading('finalize')
    try {
      await processImport()
      setHasImported(false)
      push({
        timeout: 10000,
        variant: 'success',
        message: `Imported ${name.toLocaleLowerCase()} has been created.`,
      })
      history.push('/customer')
    } catch (e) {
      pushError(e)
    } finally {
      doneLoading()
    }
  }, [history, name, processImport, push, pushError, setIsLoading])

  const handleTableSearch = useCallback(
    async (filters?: FilterModel) => {
      const {data} = await onImportSearch(filters)
      setTableSearchResult(data)
    },
    [onImportSearch]
  )

  const handleImportClick = useCallback(() => {
    modalState.open()
  }, [modalState])

  const handleImport: CsvModalSubmitFunction = useCallback(
    async (mapping, csv, file) => {
      const doneLoading = setIsLoading('customer-import')
      try {
        const csvFile = file.getFirst()?.getFile()
        if (!csvFile) {
          throw new Error('CSV file is not uploaded.')
        }
        const {data} = await onImport(mapping.toArray(), csvFile, csv.hasHeaders)
        setTableSearchResult(data)
        setHasImported(true)
        push({
          timeout: 10000,
          variant: 'success',
          message: `${name} has been uploaded, click "Finalize Import" to create uploaded ${name.toLocaleLowerCase()}.`,
        })
      } catch (e) {
        pushError(e)
      } finally {
        modalState.hide()
        doneLoading()
      }
    },
    [setIsLoading, onImport, push, name, pushError, modalState]
  )

  return useMemo(
    () => ({
      finalizeImport,
      handleTableSearch,
      handleImportClick,
      handleImport,
      tableSearchResult,
      modalState,
      hasImported,
      isLoading,
    }),
    [
      finalizeImport,
      handleImport,
      handleImportClick,
      handleTableSearch,
      hasImported,
      modalState,
      tableSearchResult,
      isLoading,
    ]
  )
}
