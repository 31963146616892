import {lazy, Suspense, useMemo} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FohLayout} from '../../../_metronic/layout/foh/FohLayout'
import {OutletManagerLayout} from '../../../_metronic/layout/kds/OutletManagerLayout'
import {FallbackView} from '../../../_metronic/partials'
import {useOutletAuth} from '../../components/hooks/useOutletAuth'
import {useCurrentOutlet} from '../../modules/outlet/hooks/useCurrentOutlet'
import {FohDashboardPage} from '../../modules/outlet/pages/foh/FohDashboardPage'
import {FohEditOrderPage} from '../../modules/outlet/pages/foh/FohEditOrderPage'
import {FohNewOrderPage} from '../../modules/outlet/pages/foh/FohNewOrderPage'
import {FohOrderListPage} from '../../modules/outlet/pages/foh/FohOrderListPage'
import {KdsDashboardPage} from '../../modules/outlet/pages/kds/KdsDashboardPage'
import {OutletReservationPage} from '../../modules/outlet/pages/manager/OutletReservationPage'

export const PrivateRoutes = () => {
  const OutletDetailPage = lazy(() => import('../../modules/outlet/pages/manager/OutletDetailPage'))
  const ProductPage = lazy(() => import('../../modules/outlet/pages/manager/OutletProductPage'))
  const ProductCategoryPage = lazy(
    () => import('../../modules/outlet/pages/manager/OutletProductCategoryPage')
  )
  const UserPage = lazy(() => import('../../modules/outlet/pages/manager/OutletUserPage'))
  const OrderPage = lazy(() => import('../../modules/outlet/pages/manager/OutletOrderPage'))
  const LogsPage = lazy(() => import('../../modules/outlet/pages/manager/OutletLogsPage'))

  const auth = useOutletAuth()
  const userType = useMemo(() => auth?.getUserType(), [auth])
  const outlet = useCurrentOutlet()

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogIn()) {
    return <Redirect to='/auth/password-reset' />
  }

  if (userType === 'manager') {
    return (
      <OutletManagerLayout>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/overview' component={OutletDetailPage} />
            <Route path='/product' component={ProductPage} />
            <Route path='/p-category' component={ProductCategoryPage} />
            <Route path='/user' component={UserPage} />
            <Route path='/order' component={OrderPage} />
            <Route path='/outlet-logs' component={LogsPage} />
            <Route path='/table-reservation' component={OutletReservationPage} />
            <Redirect to='/overview' />
          </Switch>
        </Suspense>
      </OutletManagerLayout>
    )
  } else if (userType === 'foh') {
    return (
      <FohLayout outlet={outlet}>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/overview' component={FohDashboardPage} />
            <Route path='/orders/new' component={FohNewOrderPage} />
            <Route path='/orders/:orderCode' component={FohEditOrderPage} />
            <Route path='/orders' component={FohOrderListPage} />
            <Redirect to='/overview' />
          </Switch>
        </Suspense>
      </FohLayout>
    )
  } else if (userType === 'kds') {
    return (
      <FohLayout outlet={outlet}>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/' component={KdsDashboardPage} />
          </Switch>
        </Suspense>
      </FohLayout>
    )
  }

  return null
}
