import {ReactNode, useCallback, useMemo, useState} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {VoucherGroupModel} from '../../../../../models/customer-portal/VoucherModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {VoucherTableGroupedData, VoucherTableGroupedRow} from './VoucherTableGroupedRow'

export interface VouchreTableGroupedProps {
  data?: VoucherGroupModel
  total?: number
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  advancedFilters: ReactNode
  initialFilters?: FilterModel
  columnsFlat: TableColumnOptions<VoucherModel>[]
  onRefreshCallback?: () => void
}

export const VoucherTableGrouped = ({
  onFilter,
  onRefresh,
  data,
  total,
  advancedFilters,
  onRefreshCallback,
  columnsFlat,
}: VouchreTableGroupedProps) => {
  const [expanded, setExpanded] = useState<TableRowId[]>([])

  const tableData = useMemo((): VoucherTableGroupedData[] => {
    if (data) {
      return Object.entries(data).reduce<VoucherTableGroupedData[]>((acc, [email, voucher]) => {
        acc.push({email, voucher})
        return acc
      }, [])
    }
    return []
  }, [data])

  const Expansion = useCallback(
    (props: {data: VoucherTableGroupedData}) => {
      return (
        <VoucherTableGroupedRow
          columns={columnsFlat}
          onRefreshCallback={onRefreshCallback}
          onRefresh={onRefresh}
          {...props}
        />
      )
    },
    [columnsFlat, onRefresh, onRefreshCallback]
  )

  return (
    <FilterTable
      idExtractor={emailExtractor}
      columns={columns}
      expansion={Expansion}
      onExpandChange={setExpanded}
      expandedRows={expanded}
      onFilter={onFilter}
      totalItems={total}
      data={tableData}
      advancedFilters={advancedFilters}
    />
  )
}

const columns: TableColumnOptions<VoucherTableGroupedData>[] = [
  {
    field: 'email',
    label: 'Email',
  },
]

const emailExtractor = (data: VoucherTableGroupedData) => data.email
