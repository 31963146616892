import {Link} from 'react-router-dom'

export interface HeaderUserMenuProps {
  email: string
  name: string
  isAdmin?: boolean
}

const HeaderUserMenu = ({email, name, isAdmin}: HeaderUserMenuProps) => {
  // const options = useMemo(() => {
  //   if (LAYOUT_TYPE !== 'customer-portal') {
  //     return (
  //       <>
  //         <div className='menu-item px-5'>
  //           <Link to='#' className='menu-link px-5'>
  //             My Profile
  //           </Link>
  //         </div>

  //         <div className='menu-item px-5 my-1'>
  //           <Link to='#' className='menu-link px-5'>
  //             Settings
  //           </Link>
  //         </div>

  //         <div className='separator my-2'></div>
  //       </>
  //     )
  //   }
  // }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-5'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{name}</div>
            <span className='fw-bold text-muted fs-7'>{email}</span>
            {isAdmin && (
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 mw-50px mt-2'>
                ADMIN
              </span>
            )}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
