import clsx from 'clsx'
import {ReactNode} from 'react'
import {Spinner, SpinnerProps} from './Spinner'

export interface LoadingSpinnerProps {
  children?: ReactNode
  loading: boolean
  className?: string
  spinnerOnly?: boolean
  size?: SpinnerProps['size']
  fullWidth?: boolean
}

export const LoadingSpinner = ({
  children,
  spinnerOnly,
  loading,
  className,
  size,
  fullWidth,
}: LoadingSpinnerProps) => {
  if (loading) {
    return (
      <span
        className={clsx(
          'indicator-progress',
          'd-inline-flex align-items-center justify-content-center',
          {
            'd-flex w-100': fullWidth,
            'd-inline-flex': !fullWidth,
          },
          className
        )}
      >
        {!spinnerOnly && 'Please wait...'}
        <Spinner size={size} className={clsx({'ms-1': !spinnerOnly})} />
      </span>
    )
  }
  return <>{children}</>
}
