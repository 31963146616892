import moment from 'moment'

export class DateRange {
  private start: Date | null = null
  private end: Date | null = null

  constructor(start: Date | null = null, end: Date | null = null) {
    this.setStart(start)
    this.setEnd(end)
  }

  public setStart(date: Date | null) {
    this.start = date
    if (this.end && date && this.end < date) {
      this.setEnd(new Date(date))
    }
  }

  public setEnd(date: Date | null) {
    this.end = date
    if (this.start && date && this.start > date) {
      this.setStart(new Date(date))
    }
  }

  public getStartClone() {
    return this.start && new Date(this.start)
  }

  public getEndClone() {
    return this.end && new Date(this.end)
  }

  public getStart() {
    return this.start
  }

  public getEnd() {
    return this.end
  }

  public getStartOrFail() {
    const start = this.getStartClone()
    if (!start) {
      throw new Error('Start is null')
    }
    return start
  }

  public getEndOrFail() {
    const end = this.getEndClone()
    if (!end) {
      throw new Error('Start is null')
    }
    return end
  }

  public clone() {
    const start = this.getStartClone()
    const end = this.getEndClone()
    return new DateRange(start, end)
  }

  public hasValues(): boolean {
    return Boolean(this.start && this.end)
  }

  public inside(that: DateRange): boolean {
    const startA = this.getStart()
    const endA = this.getEnd()

    const startB = that.getStart()
    const endB = that.getEnd()

    if (startA && endA && startB && endB) {
      return startA >= startB && endA <= endB && startB <= startA && endB >= endA
    }
    return false
  }

  public forEachDayBetweenInclusive(callback: (date: Date) => void) {
    const start = this.getStart()
    const end = this.getEnd()

    if (start && end) {
      for (
        let currentDay = moment(start).startOf('day');
        currentDay.isSameOrBefore(end);
        currentDay.add(1, 'day')
      ) {
        callback(currentDay.toDate())
      }
    }
  }
}
