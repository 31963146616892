import {FormikContextType} from 'formik'
import {
  BookingWizardBulkProductVenueStepFormValues,
  BulkConsolidatedFormValues,
} from '../../../../../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {EventModel} from '../../../../../../../models/ems/EventModel'
import {useBookingFormData} from '../../hook/useBookingFormData'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../../setup'
import {BookingWizardSharedConsolidatedProductVenueStep} from '../../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedConsolidatedProductVenueStep'

export interface BookingWizardBulkConsolidatedProductVenueStepProps<
  T extends BookingWizardBulkProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | null
}

export const BookingWizardBulkConsolidatedProductVenueStep = <
  T extends BookingWizardBulkProductVenueStepFormValues
>({
  formik,
  event,
}: BookingWizardBulkConsolidatedProductVenueStepProps<T>) => {
  const {productSearchResults, refreshProductsList, searchVouchers, voucherSearchResults} =
    useBookingFormData({
      eventCode: event?.code || formik.values.eventCode,
    })
  const bookingBulkConsolidatedForm: BulkConsolidatedFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingBulkConsolidatedForm
  ) as BulkConsolidatedFormValues

  return (
    <BookingWizardSharedConsolidatedProductVenueStep
      formik={formik}
      bookingBulkForm={bookingBulkConsolidatedForm}
      searchVouchers={searchVouchers}
      refreshProductsList={refreshProductsList}
      productSearchResults={productSearchResults}
      voucherSearchResults={voucherSearchResults}
      isPortal={true}
    />
  )
}
