import clsx from 'clsx'
import {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {MetronicIcon} from '../../../inputs/MetronicIcon'
import {useLayoutHelpers} from '../../DefaultLayout/useLayoutHelpers'
import {NavButtonMenu} from './NavButtonMenu'

export interface NavButtonProps {
  item: NavigationSectionMenuItem
  className?: string
  subMenu?: boolean
}

export const NavButton = ({item, className, subMenu = false}: NavButtonProps) => {
  const {childrenIsActive, isRouteActive} = useLayoutHelpers()
  const isActive = useMemo(
    () => isRouteActive(item.to) || childrenIsActive([item]),
    [childrenIsActive, isRouteActive, item]
  )

  const buttonClassNames = useMemo(() => {
    return clsx(
      'btn btn-text rotate rounded-0 text-uppercase',
      {
        'bg-primary text-white d-flex flex-row justify-content-center': isActive && !item.isSub,
        'p-0 w-100 d-block text-center': item.isSub,
      },
      className
    )
  }, [className, isActive, item.isSub])

  const content = useMemo(() => {
    if (item.subMenus) {
      return (
        <>
          <button
            type='button'
            className={clsx(buttonClassNames)}
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='bottom-start'
          >
            {item.title}

            <MetronicIcon
              className='rotate-180 ms-3 me-0'
              iconType='Navigation'
              iconName='Angle-down'
            />
          </button>
          <NavButtonMenu items={item.subMenus} />
        </>
      )
    }
    if (item.to) {
      return (
        <Link to={item.to}>
          <button type='button' className={clsx(buttonClassNames)}>
            {item.icon && typeof item.icon === 'string' && (
              <img width='30px' className='me-2' alt={item.icon} src={item.icon} />
            )}
            <p
              className={clsx(
                'mb-0 position-relative h-100 d-flex align-items-center justify-content-center',
                {
                  'second-nav-botton-dw px-5 py-2': item.isSub,
                  'btn-active': item.isSub && isActive,
                }
              )}
            >
              {item.title}
            </p>
          </button>
        </Link>
      )
    }
  }, [buttonClassNames, isActive, item.icon, item.isSub, item.subMenus, item.title, item.to])

  return (
    <div
      style={{
        width: subMenu ? '50%' : '',
      }}
    >
      {content}
    </div>
  )
}
