import {ReactNode} from 'react'

interface FieldsContaiverWithBorderProps {
  children: ReactNode
  legend: string
}

export const FieldsContainerWithBorderAndLabel = ({
  children,
  legend,
}: FieldsContaiverWithBorderProps) => {
  return (
    <div className='container-fluid border border-1 border-primary rounded position-relative p-1 p-md-5 mb-10'>
      <p
        className='text-uppercase fw-bolder text-white fs-2 position-absolute px-3 mb-0 w-250px text-center'
        style={{
          left: '40px',
          top: '-16px',
          backgroundColor: '#0b0d0f',
        }}
      >
        {legend}
      </p>
      <div className='row p-5'>{children}</div>
    </div>
  )
}
