import clsx from 'clsx'
import {Fragment, useMemo} from 'react'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {MenuProduct, MenuProductAttributes} from './MenuProduct'

export interface MenuProductCategoryProps {
  className?: string
  products: MenuProductAttributes[]
  name: string
  id?: string
  onClick?: (value: string) => void
}
export const MenuProductCategory = ({
  name,
  products,
  className,
  onClick,
  id,
}: MenuProductCategoryProps) => {
  const productNodes = useMemo(() => {
    return products.map((product, index) => {
      const isLast = index === products.length - 1
      return (
        <Fragment key={product.value}>
          <MenuProduct onClick={onClick} {...product} />
          {!isLast && <HorizontalDivider spacing={3} />}
        </Fragment>
      )
    })
  }, [onClick, products])

  return (
    <section id={id} className={clsx('menu-product-category', className)}>
      <h2>{name}</h2>
      {productNodes}
    </section>
  )
}
