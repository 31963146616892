import {useDispatch} from 'react-redux'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {UserTable} from '../components/tables/UserTable/UserTable'
import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'
import {useCallback, useState} from 'react'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {FilterModel} from '../../../models/FilterModel'
import { GetUserByCode } from '../redux/CustomerPortalCRUD'
import UserFormCreate from '../components/DrawerForm/UserFormDrawer/UserFormCreate'
import { actions } from '../redux/CustomerPortalRedux'
import { UserModel } from '../../../models/customer-portal/UserModel'
import UserFormEdit from '../components/DrawerForm/UserFormDrawer/UserFromEdit'

const UserPage = () => {
  const dispatch = useDispatch()
  const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const [userToEdit, setUserToEdit] = useState<UserModel>()
  const [drawerEdit, setDrawerEdit] = useDrawerRef()
  const users = useRootStateSelector((state) => state.customerPortal.users)
  const {pushError} = useAlerts()
  const {setFilter} = useEntityFilter('customer-portal-user')
  
  const refreshData = useCallback(() => { 
      dispatch(actions.users.search())
  }, [dispatch])

  const onFilterHandler = useCallback(
    (filter: FilterModel) => { 
      setFilter(filter)
      refreshData()
    },
    [refreshData, setFilter]
  )

  const handleOnEditUser = useCallback(
    async (event) => {
      try {
          const {data} = await GetUserByCode(event.code)
          setUserToEdit(data)  
        if (drawerEdit) {
            drawerEdit.show()
        }
      } catch (e: any) {
        pushError(e)
      }
    },
    [drawerEdit, pushError]
  )

  return (
    <>
      <Toolbar title='Users'></Toolbar>

      <UserTable
        onFilter={onFilterHandler}
        onDelete={refreshData}
        onEdit={handleOnEditUser}
        data={users}
        onNewUserClick={drawerCreate?.show}
        className='rs-table-bg'
        customWrapper='rs-top'
        cornersClassName='table-cut-corner-polygon rs-gradient'
        paginationClassName='px-10 pb-10 rs-pagination'
      />
      <UserFormEdit
        userToEdit={userToEdit}
        drawerRef={setDrawerEdit}
        onChange={refreshData}
      />
      <UserFormCreate drawerRef={setDrawerCreate} onChange={refreshData} />
    </>
  )
}

export {UserPage}
