import clsx from 'clsx'
import {ReservationPortalModel} from '../../../../../models/ems/ReservationMedel'

export const ReservationStatusColumn = ({data}: {data: ReservationPortalModel}) => {
  return <span className={getBadge(data)}>{data.status}</span>
}

const getBadge = (status: ReservationPortalModel) => {
  let baseClass = 'badge text-uppercase'

  switch (status.status) {
    case 'active':
      return clsx(baseClass, 'badge-light-primary')
    case 'closed':
      return clsx(baseClass, 'badge-light-danger')
    case 'released':
      return clsx(baseClass, 'badge-light-warning')
    default:
      return clsx(baseClass, 'badge-light-dark')
  }
}
