import clsx from 'clsx'
import {useMemo} from 'react'
import {RenderTooltipProps} from '../../../../../../components/charts/LineChart/LineChart'
import {ScannerTimelineChartData} from './ScannerTimelineChart'

interface ScannerTimelineChartTooltipProps extends RenderTooltipProps<ScannerTimelineChartData> {
  colors: string[]
  data: [string, ScannerTimelineChartData[]][]
}

export const ScannerTimelineChartTooltip = ({
  tooltipData,
  colors,
  data,
}: ScannerTimelineChartTooltipProps) => {
  const hoveredData = useMemo(() => {
    if (tooltipData) {
      const total = tooltipData?.nearestDatum?.datum.total
      if (total !== undefined) {
        return Object.entries(tooltipData.datumByKey).reduce<typeof tooltipData.datumByKey>(
          (acc, [key, value]) => {
            if (value.datum.total === total) {
              acc[key] = value
            }
            return acc
          },
          {}
        )
      }
    }
  }, [tooltipData])

  return (
    <div className='bg-light-primary border rounded p-4'>
      {hoveredData &&
        Object.entries(hoveredData).map(([key, value], index, array) => {
          const isLast = index === array.length - 1
          const color = colors[data.findIndex((item) => item[0] === key)]
          return (
            <div
              className={clsx({
                'mb-3': !isLast,
              })}
              key={key}
            >
              <div className='d-flex align-items-center'>
                <div
                  className='me-3 p-1 border border-dark d-inline-flex align-items-center justify-content-center'
                  style={{height: '1.5em', minWidth: '50px', backgroundColor: color}}
                >
                  {value.datum.total}
                </div>
                <span>{key}</span>
              </div>
            </div>
          )
        })}
    </div>
  )
}
