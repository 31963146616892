import {FormikContextType} from 'formik'
import {TextInput} from '../../../../../components/inputs'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'

export interface RegistrationCustomerInformationValues {
  // title: string
  name: string
  // lastName: string
  email: string
  designation: string
  customerOrganization: string
}

export interface RegistrationCustomerInformationProps<
  T extends RegistrationCustomerInformationValues
> {
  formik: FormikContextType<T>
}

export const RegistrationCustomerInformation = <T extends RegistrationCustomerInformationValues>({
  formik,
}: RegistrationCustomerInformationProps<T>) => {
  const textInputHelpers = useFormikTextInputHelpers(formik)
  return (
    <div className='row'>
      {/* <div className='col-12'>
        <TextInput label='Title' readOnly {...textInputHelpers.getProps('title')} />
      </div> */}
      <div className='col-12'>
        <TextInput label='Name' readOnly {...textInputHelpers.getProps('name')} />
      </div>
      {/* <div className='col-12'>
        <TextInput label='Last Name' readOnly {...textInputHelpers.getProps('lastName')} />
      </div> */}
      <div className='col-12'>
        <TextInput label='Email' readOnly {...textInputHelpers.getProps('email')} />
      </div>
      <div className='col-12 col-md-6'>
        <TextInput label='Designation' readOnly {...textInputHelpers.getProps('designation')} />
      </div>
      <div className='col-12 col-md-6'>
        <TextInput
          label='Organization'
          readOnly
          {...textInputHelpers.getProps('customerOrganization')}
        />
      </div>
    </div>
  )
}

export const STEP_CUSTOMER_KEYS: Array<keyof RegistrationCustomerInformationValues> = [
  'designation',
  'email',
  'name',
  // 'lastName',
  'customerOrganization',
  // 'title',
]
