import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {actions} from '../redux/DigitalMenuRedux'

export const useCustomerProfile = () => {
  const customerName = useRootStateSelector((state) => state.digitalMenu.profile.fullName)
  const mobileNumber = useRootStateSelector((state) => state.digitalMenu.profile.mobileNumber)
  const dispatch = useDispatch()

  const setMobileNumber = useCallback(
    (mobileNumber: string) => {
      dispatch(actions.profile.setMobileNumber(mobileNumber))
    },
    [dispatch]
  )

  const setCustomerName = useCallback(
    (customerName: string) => {
      dispatch(actions.profile.setCustomerName(customerName))
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      customerName,
      mobileNumber,
      setMobileNumber,
      setCustomerName,
    }),
    [customerName, mobileNumber, setCustomerName, setMobileNumber]
  )
}
