import {useCallback} from 'react'
import {Result} from '@zxing/library'
import {QrReaderVideo} from './QRReaderVideo'

export interface QRCodeInputProps {
  onChange: (value: string) => void
  className?: string
  scanDelay?: number
  disabled?: boolean
  loading?: boolean
}

export const QRCodeInput = ({disabled, onChange, className, scanDelay}: QRCodeInputProps) => {
  const handleResult = useCallback(
    (result: Result) => {
      !disabled && onChange(result.getText())
    },
    [disabled, onChange]
  )

  return (
    <QrReaderVideo
      className={className}
      constraints={contraints}
      onResult={handleResult}
      scanDelay={scanDelay}
      disabled={disabled}
    />
  )
}

const contraints = {facingMode: 'user'}
