import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useLocation, useParams, useRouteMatch} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {useModalState} from '../../../components/modals/useModalState'
import {ExceptionFactory} from '../../../exceptions/ExceptionFactory'
import {VoucherCreditException} from '../../../exceptions/VoucherCreditException'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {Cart} from '../../../utils/Cart'
import {
  Checkout,
  checkoutFormValidator,
  PaymentMethodFormValues,
} from '../components/forms/Checkout'
import {NotEnoughBalanceModal} from '../components/modals/NotEnoughBalanceModal'
import {YasPaymentSuccessModal} from '../components/modals/YasPaymentSuccessModal'
import {useCart} from '../hooks/useCart'
import {CheckoutPageState, usePageNavigation} from '../hooks/usePageNavigation'
import {GetOrderByCode, GetPayfortParams, PayByVoucher} from '../redux/DigitalMenuCRUD'
import {useDispatch} from 'react-redux'
import {actions} from '../redux/DigitalMenuRedux'

export const DraftedOrderCheckoutPage = () => {
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const payfortFormRef = useRef<HTMLFormElement | null>(null)
  const {goToOrderByCode, goBack} = usePageNavigation()
  const {orderCode} = useParams<{orderCode: string}>()
  const [order, setOrder] = useState<OrderModel>()
  const {pushError, push} = useAlerts()
  const {state} = useLocation<CheckoutPageState | undefined>()
  const {clearCart} = useCart(state?.outletCode)
  const [voucherBalance, setVoucherBalance] = useState(0)
  const [disableScan, setDisableScan] = useState(false)
  const match = useRouteMatch<{orderCode: string}>()
  const notEnoughModal = useModalState()
  const voucherPaymentSuccessModal = useModalState()
  const cardPaymentSuccessModal = useModalState()
  const dispatch = useDispatch()

  const returnUrl = useMemo(() => {
    return `${window.location.origin}/orders/${match.params.orderCode}/payfort-confirmation`
  }, [match.params.orderCode])

  const handleBack = useCallback(() => {
    if (goBack) {
      goBack()
    } else {
      goToOrderByCode(orderCode, state)
    }
  }, [goBack, goToOrderByCode, orderCode, state])

  const payByVoucher = useCallback(
    async (order: OrderModel, voucherCode: string) => {
      setDisableScan(true)
      try {
        await PayByVoucher({
          amount: order.total,
          orderCode: order.code,
          type: 'voucher',
          voucherCode: voucherCode,
        })
        voucherPaymentSuccessModal.open()
        clearCart()
      } catch (e) {
        const error = ExceptionFactory.fromError(e)
        if (error instanceof VoucherCreditException) {
          setVoucherBalance(error.balance)
          notEnoughModal.open()
        } else {
          pushError(e)
        }
      } finally {
        setDisableScan(false)
      }
    },
    [voucherPaymentSuccessModal, notEnoughModal, pushError, clearCart]
  )

  const formik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    onSubmit: async (values) => {
      dispatch(actions.profile.setCustomerEmail(values.customerEmail))

      if (order) {
        if (values.paymentMethod === 'yas-pay') {
          await payByVoucher(order, values.yasPay)
        } else {
          const {data} = await GetPayfortParams(order.code, returnUrl)
          setIsFormDisabled(true)
          formik.setValues({
            ...values,
            payfortReturnUrl: data.returnUrl,
            payfortAccessCode: data.accessCode,
            payfortMerchantIdentifier: data.merchantIdentifier,
            payfortMerchantReference: data.merchantReference,
            payfortExpiryDate: `${values.cardExpiryYear}${values.cardExpiryMonth}`,
            payfortCardNumber: values.cardNumber,
            payfortCardSecurityCode: values.cvv,
            payfortSignature: data.signature,
          })
          payfortFormRef.current?.submit()
        }
      }
    },
    validationSchema: checkoutFormValidator,
  })

  const cart = useMemo(() => {
    if (order) {
      return Cart.fromOrderModel(order)
    }
    return new Cart()
  }, [order])

  const handlePaymentCancel = useCallback(() => {
    notEnoughModal.hide()
    formik.setFieldValue('paymentMethod', '')
  }, [formik, notEnoughModal])

  const handlePaymentSuccessClose = useCallback(() => {
    voucherPaymentSuccessModal.hide()
    cardPaymentSuccessModal.hide()
    goToOrderByCode(orderCode, state)
  }, [voucherPaymentSuccessModal, cardPaymentSuccessModal, goToOrderByCode, orderCode, state])

  useOnChange(orderCode, async () => {
    try {
      const {data} = await GetOrderByCode(orderCode)
      setOrder(data)
    } catch (e) {
      pushError(e)
    }
  })

  useEffect(() => {
    if (order?.isPaid) {
      push({message: 'Order already paid.', timeout: 5000, variant: 'primary'})
      goToOrderByCode(orderCode)
    }
  }, [goToOrderByCode, order?.isPaid, orderCode, push])

  return (
    <>
      <Checkout
        payfortFormRef={payfortFormRef}
        disableScan={disableScan}
        disableSubmit={isFormDisabled}
        cart={cart}
        formik={formik}
        onPay={formik.handleSubmit}
        onBack={handleBack}
      />
      <NotEnoughBalanceModal
        onCancel={handlePaymentCancel}
        onUseAnother={notEnoughModal.hide}
        open={notEnoughModal.isOpen}
        onClose={notEnoughModal.hide}
        balance={voucherBalance}
        currency='AED'
      />
      <YasPaymentSuccessModal
        open={voucherPaymentSuccessModal.isOpen}
        onClose={handlePaymentSuccessClose}
        text='The amount has been withdrawn from your Yas Pay account'
      />
      <YasPaymentSuccessModal
        open={cardPaymentSuccessModal.isOpen}
        onClose={handlePaymentSuccessClose}
        text='Order successfully paid with a credit card.'
      />
    </>
  )
}

const EMPTY_INITIAL_VALUES: PaymentMethodFormValues = {
  cardExpiryMonth: '',
  cardExpiryYear: '',
  cardNumber: '',
  cvv: '',
  paymentMethod: '',
  yasPay: '',
  cardHolder: '',
  customerEmail: '',
  payfortReturnUrl: '',
  payfortAccessCode: '',
  payfortCardNumber: '',
  payfortCardSecurityCode: '',
  payfortExpiryDate: '',
  payfortMerchantIdentifier: '',
  payfortMerchantReference: '',
  payfortSignature: '',
}
