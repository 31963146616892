import {useCallback, useEffect, useMemo, useState} from 'react'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {useWebSocket} from '../../../components/utils/WebSocketProvider'
import {TopicModel} from '../../../models/eva/TopicModel'
import {FileModelWithOrderAndNote} from '../../default/eva/components/TopicPresentation'
import {
  GetTopicCurrentSlide,
  SetTopicCurrentSlide,
  SetTopicPresentationState,
} from '../redux/EvaCRUD'

export interface PresentationSocketData {
  presentationSlide?: FileModelWithOrderAndNote
  isPresentationStarted?: false
}

export interface UsePresentationWebSocketsParams {
  topic?: TopicModel
}

export const usePresentationWebSockets = ({topic}: UsePresentationWebSocketsParams) => {
  const userCode = useRootStateSelector((state) => state.eva.user?.data?.code)
  const ticketCode = useRootStateSelector((state) => state.eva.user?.ticketCode)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [isPresentationPlaying, setIsPresentationPlaying] = useState<boolean>()
  const {socket} = useWebSocket()

  const presentations = useMemo(() => {
    if (topic?.presentationFiles) {
      return topic.presentationFiles.sort((a, b) => {
        return a.order - b.order
      })
    }
    return []
  }, [topic?.presentationFiles])

  const setPageIndexOfPresentationByOrderNumber = useCallback(
    (order: number) => {
      const pageIndex = presentations.findIndex((item) => item.order === order)
      if (pageIndex >= 0) {
        setCurrentPageIndex(pageIndex)
      }
    },
    [presentations]
  )

  const socketHandler = useCallback(
    (data: PresentationSocketData) => {
      if (data.presentationSlide) {
        setIsPresentationPlaying(true)
        setPageIndexOfPresentationByOrderNumber(data.presentationSlide.order)
      }
      if (typeof data.isPresentationStarted === 'boolean') {
        setIsPresentationPlaying(data.isPresentationStarted)
      }
    },
    [setPageIndexOfPresentationByOrderNumber]
  )

  const refreshTopicSlide = useCallback(
    async (topic: TopicModel) => {
      const {data} = await GetTopicCurrentSlide(topic.code)
      const slideNumber = data.topic?.currentPresentationSlide
      if (slideNumber !== undefined) {
        setPageIndexOfPresentationByOrderNumber(slideNumber)
      } else {
        setCurrentPageIndex(0)
      }
    },
    [setPageIndexOfPresentationByOrderNumber]
  )

  const startPresentation = useCallback(async () => {
    if (topic && ticketCode) {
      await refreshTopicSlide(topic)
      if (!isPresentationPlaying) {
        await SetTopicPresentationState(topic.code, true, ticketCode)
      }
      setIsPresentationPlaying(true)
    }
  }, [isPresentationPlaying, refreshTopicSlide, ticketCode, topic])

  const endPresentation = useCallback(async () => {
    if (topic && ticketCode && topic.isPresentationStarted) {
      await SetTopicPresentationState(topic.code, false, ticketCode)
      setIsPresentationPlaying(false)
    }
  }, [ticketCode, topic])

  const updateCurrentPage = useCallback(
    async (pageIndex: number) => {
      const presentation = presentations[pageIndex]
      if (topic && ticketCode && presentation) {
        await SetTopicCurrentSlide(topic.code, presentation.order, ticketCode)
        setCurrentPageIndex(pageIndex)
      }
    },
    [presentations, ticketCode, topic]
  )

  const isSpeaker = useMemo(() => {
    if (userCode && topic?.speakers) {
      return topic.speakers.some((speaker) => speaker.code === userCode)
    }
    return false
  }, [topic?.speakers, userCode])

  const hasPresentations = useMemo(
    () => Boolean(topic?.presentationFiles?.length),
    [topic?.presentationFiles.length]
  )

  useEffect(() => {
    if (topic && topic.isPresentationStarted !== undefined) {
      setIsPresentationPlaying(topic.isPresentationStarted)
    }
  }, [topic])

  useEffect(() => {
    if (topic) {
      const eventName = `PRESENTATION:${topic.code}`
      socket?.on(eventName, socketHandler)
      return () => {
        socket?.off(eventName, socketHandler)
      }
    }
  }, [socket, socketHandler, topic])

  return {
    isSpeaker,
    startPresentation,
    endPresentation,
    currentPageIndex,
    isPresentationPlaying,
    updateCurrentPage,
    hasPresentations,
    presentations,
    refreshTopicSlide,
  }
}
