import draftToHtml from 'draftjs-to-html'
import {useMemo} from 'react'

export interface DraftJsRendererProps {
  children?: string | null
}

export const DraftJsRenderer = ({children}: DraftJsRendererProps) => {
  const html = useMemo(() => {
    if (children) {
      try {
        return draftToHtml(JSON.parse(children), undefined, undefined, (entity, text) => {
          if (entity.type === 'IMAGE') {
            return `<img style="max-width: 100%; height: ${
              entity.data.height ? `${entity.data.height}px` : undefined
            } width: ${entity.data.width ? `${entity.data.width}px` : undefined};" src="${
              entity.data.src
            }">`
          }
        })
      } catch (e) {
        return null
      }
    }
  }, [children])

  if (html) {
    return <div dangerouslySetInnerHTML={{__html: html}}></div>
  }
  return null
}
