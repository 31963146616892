import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login, loginWithHash, validateHash, validateMfa} from '../redux/AuthCRUD'
import {AxiosError} from 'axios'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {DigitInput} from '../../../../components/inputs/DigitInput'
import {QrCode} from '../../../../components/utils/QrCode'
import {Button} from '../../../../components/inputs/Button'
import {PasswordInput} from '../../../../components/inputs/PasswordInput'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
  otpValue: '',
}

export function Login() {
  const otpInputRef = useRef<HTMLInputElement | null>(null)
  const [mfaLink, setMfaLink] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [hash, setHash] = useState<string>()
  const match = useRouteMatch<{hash?: string}>()
  const history = useHistory()
  const {push} = useAlerts()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setFieldValue}) => {
      setLoading(true)
      setStatus('')
      if (values.otpValue && authToken) {
        try {
          const {data} = await validateMfa(values.otpValue, authToken)
          dispatch(auth.actions.login(data.token))
        } catch (e) {
          setFieldValue('otpValue', '')
          setStatus('Invalid OTP')
        }
      } else if (mfaLink) {
        setMfaLink(undefined)
        otpInputRef.current?.focus()
      } else {
        try {
          if (hash) {
            const {data, status} = await loginWithHash(values.username, values.password, hash)
            setMfaLink(data.qrCode)
            if (status === 202) {
              setAuthToken(data.token)
              otpInputRef.current?.focus()
            } else {
              dispatch(auth.actions.login(data.token))
            }
          } else {
            const {data, status} = await login(values.username, values.password)
            if (status === 202) {
              setAuthToken(data.token)
              otpInputRef.current?.focus()
            } else {
              dispatch(auth.actions.login(data.token))
            }
          }
        } catch (e) {
          const responseError: AxiosError<unknown> = e
          if (responseError.response?.status) {
            if (responseError.response.status === 403) {
              setStatus('Please check your email to enable your 2FA authentication.')
            } else {
              setStatus('Invalid username or password.')
            }
          } else {
            setStatus('Something went wrong. Please try again later.')
          }
        }
      }
      setLoading(false)
    },
  })

  const validateHashFromUrl = useCallback(
    async (hash: string) => {
      try {
        await validateHash(hash)
        push({
          message: `Please enter your username and password.`,
          timeout: 10000,
          variant: 'success',
        })
        setHash(hash)
      } catch (e) {
        push({
          message: `Invalid url. Please contact support.`,
          timeout: 10000,
          variant: 'danger',
        })
        history.replace('/auth/login')
      }
    },
    [history, push]
  )

  const handleOtpChange = useCallback(
    (value: string) => {
      formik.setFieldValue('otpValue', value)
    },
    [formik]
  )

  useEffect(() => {
    if (match.params.hash) {
      validateHashFromUrl(match.params.hash)
    }
  }, [match.params.hash, validateHashFromUrl])

  const heading = useMemo(() => {
    if (mfaLink) {
      return (
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Scan QR Code</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            <p>Scan the QR code with your preferred 2FA app.</p>
            <p>
              If you are on your mobile phone, tap <a href={mfaLink}>here</a>.
            </p>
          </div>
        </div>
      )
    }
    if (authToken) {
      return (
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Enter OTP</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Use your preferred 2FA app to get your OTP
          </div>
        </div>
      )
    }
    return (
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
        <div className='text-gray-400 fw-bold fs-4'>Enter username and password to login</div>
      </div>
    )
  }, [authToken, mfaLink])

  const loginFields = useMemo(() => {
    if (!authToken) {
      return (
        <>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
            <input
              placeholder='Email or Mobile'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          <PasswordInput
            label='Password'
            forgotPasswordLink='/auth/forgot-password'
            placeholder='Password'
            errorMessage={formik.errors.password}
            isTouched={formik.touched.password}
            {...formik.getFieldProps('password')}
          />
        </>
      )
    }
  }, [formik, authToken])

  const otpFields = useMemo(() => {
    const isShown = Boolean(authToken && !mfaLink)
    return (
      <div className={clsx('m-5', {'d-none': !isShown})}>
        <DigitInput
          ref={otpInputRef}
          className='mt-5'
          value={formik.values.otpValue}
          onChange={handleOtpChange}
          length={OTP_LENGTH}
        />
      </div>
    )
  }, [authToken, formik.values.otpValue, handleOtpChange, mfaLink])

  const mfaQrCode = useMemo(() => {
    if (mfaLink) {
      return (
        <div className='d-flex justify-content-center mb-5'>
          <div className='p-3 d-inline-block bg-white'>
            <a href={mfaLink}>
              <QrCode value={mfaLink} />
            </a>
          </div>
        </div>
      )
    }
  }, [mfaLink])

  const handleBack = useCallback(() => {
    formik.resetForm()
    setMfaLink(undefined)
    setAuthToken(undefined)
  }, [formik])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {heading}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {loginFields}
      {otpFields}
      {mfaQrCode}
      <div className='text-center'>
        <button
          type='submit'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {(authToken || mfaLink) && (
          <Button
            type='button'
            className='w-100'
            onClick={handleBack}
            disabled={formik.isSubmitting}
          >
            Back
          </Button>
        )}
      </div>
    </form>
  )
}

const OTP_LENGTH = 6
