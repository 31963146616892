import {useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {OrderModel, OrderModelStatus} from '../../../models/fnb/OrderModel'
import {BlobUtils} from '../../../utils/BlobUtils'
import {CreateOrderFormValues, getPayload} from '../components/forms/CreateOrderForm'
import {CreateNewOrder, GetOrderByCode, PrintOrderBill, PutOrder} from '../redux/OutletCRUD'

export const useOutletOrderFormHandlers = (orderCode?: string) => {
  const history = useHistory()
  const {pushError, push} = useAlerts()
  const [order, setOrder] = useState<OrderModel>()

  const resetFormData = useCallback(async () => {
    if (orderCode) {
      const {data} = await GetOrderByCode(orderCode)
      setOrder(data)
      return data
    }
  }, [orderCode])

  const handleBack = useCallback(() => {
    history.push('/')
  }, [history])

  const getPaymentPayload = useCallback(
    (values: CreateOrderFormValues, variables: {status: OrderModelStatus}) => {
      const payload = getPayload(values, variables)
      if (order) {
        if (order.orderItems) {
          order.orderItems.forEach((orderItem) => {
            if (orderItem.product) {
              const foundItem = payload.products.find(
                (product) =>
                  product.code === orderItem.product?.code && orderItem.status !== 'cancelled'
              )
              if (!foundItem) {
                payload.products.push({
                  code: orderItem.product.code,
                  notes: orderItem.notes,
                  qty: 0,
                })
              }
            }
          })
        }
      }
      return payload
    },
    [order]
  )

  const handlePayLater = useCallback(
    async (values: CreateOrderFormValues) => {
      const payload = getPaymentPayload(values, {status: 'confirmed'})
      try {
        if (order) {
          await PutOrder(order.code, payload)
          push({message: 'Order successfully updated.', timeout: 5000, variant: 'success'})
        } else {
          await CreateNewOrder(payload)
          push({message: 'Order successfully created.', timeout: 5000, variant: 'success'})
        }
        history.push(`/`)
      } catch (e) {
        pushError(e)
      }
    },
    [getPaymentPayload, history, order, push, pushError]
  )

  const updateOrder = useCallback(
    async (values: CreateOrderFormValues) => {
      const payload = getPaymentPayload(values, {status: 'draft'})
      if (order) {
        await PutOrder(order.code, payload)
        resetFormData()
      }
    },
    [getPaymentPayload, order, resetFormData]
  )

  const handlePay = useCallback(
    async (values: CreateOrderFormValues) => {
      try {
        if (order) {
          updateOrder(values)
          push({message: 'Order successfully updated.', timeout: 5000, variant: 'success'})
        } else {
          const payload = getPaymentPayload(values, {status: 'draft'})
          const {data} = await CreateNewOrder(payload)
          push({message: 'Order successfully created.', timeout: 5000, variant: 'success'})
          history.push(`/orders/${data.code}`, {checkout: true})
        }
      } catch (e) {
        pushError(e)
      }
    },
    [getPaymentPayload, history, order, push, pushError, updateOrder]
  )

  const handlePrint = useCallback(async () => {
    if (order?.code) {
      const {data} = await PrintOrderBill(order.code)
      BlobUtils.downloadBlob(data, `${Date.now()}.pdf`)
    }
  }, [order?.code])

  useOnChange(orderCode, () => {
    resetFormData()
  })

  return useMemo(
    () => ({
      handleBack,
      handlePay,
      handlePrint,
      handlePayLater,
      order,
      updateOrder,
      refreshOrder: resetFormData,
    }),
    [handleBack, handlePay, handlePayLater, handlePrint, order, resetFormData, updateOrder]
  )
}
