import {useCustomerDelegatesAuth} from '../../modules/customer-delegate/hooks/useCustomerAuth'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useMemo} from 'react'
import {DelegatesEventDetail} from '../../modules/customer-delegate/pages/DelegatesEventDetails'
import CustomerDetails from '../../modules/customer-delegate/pages/CustomerDetails'
import {CustomerPage} from '../../modules/customer-delegate/pages/CustomerPage'
import {ForgotPassword} from '../../modules/customer-delegate/components/forms/ForgotPassword'
import {EVENT_CODE} from '../../../config/env'

export const PrivateRoutes = () => {
  const auth = useCustomerDelegatesAuth()
  const redirectRoutes = useMemo(() => {
    let link = '/error/404'
    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }
  return (
    <Switch>
      <Route path='/events/:eventCode'>
        <DelegatesEventDetail />
      </Route>
      <Route path='/customer/:customerCode' component={CustomerDetails} />
      <Route path='/customer' component={CustomerPage} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      {EVENT_CODE && <Redirect to={`/events/${EVENT_CODE}/bookings`} />}
      {redirectRoutes}
    </Switch>
  )
}
