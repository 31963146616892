import {useCallback} from 'react'
import {HorizontalDivider} from '../../../../../components/utils/HorizontalDivider'
import {PaymentSummary} from '../../../../outlet/components/PaymentSummary'
import {ButtonList, ButtonListItem} from '../../ButtonList'
import {ConfirmPageLayout} from '../../../layout/ConfirmPageLayout'
import {Cart} from '../../../../../utils/Cart'
import {OrderSummaryCart} from '../../OrderSummary/OrderSummaryCart'

export type PaymentMethod = 'yas-pay' | 'card'

export interface ChoosePaymentMethodProps {
  onBack: () => void
  onChoose: (paymentMethod: PaymentMethod) => void
  cart: Cart
}

export const ChoosePaymentMethod = ({onBack, onChoose, cart}: ChoosePaymentMethodProps) => {
  const handlePaymentMethodClick = useCallback(
    (value: string) => {
      onChoose(value as PaymentMethod)
    },
    [onChoose]
  )

  return (
    <ConfirmPageLayout onBackClick={onBack} headerLabel='Checkout'>
      <p className='fw-bold'>Choose a payment method</p>
      <ButtonList className='mb-8' items={PAYMENT_METHODS} onClick={handlePaymentMethodClick} />
      <HorizontalDivider spacing={6} />
      <h2>Payment Details</h2>
      <OrderSummaryCart variant='success' cart={cart} />
      <HorizontalDivider spacing={6} />
      <PaymentSummary className='mb-6' cart={cart} />
      <HorizontalDivider />
    </ConfirmPageLayout>
  )
}

const PAYMENT_METHODS: ButtonListItem[] = [
  {label: 'Debit/Credit Card', value: 'card', light: true, border: true},
  {label: 'YasEats CashCard', value: 'yas-pay', light: true, border: true},
]
