import {SweetAlertOptions} from 'sweetalert2'
import {Validators} from './Validators'

export abstract class SwalMixins {
  public static baseMixin: SweetAlertOptions = {
    customClass: {
      confirmButton: 'btn btn-flush me-5',
      cancelButton: 'btn btn-danger',
      validationMessage: 'bg-transparent text-muted',
    },
    buttonsStyling: false,
  }

  public static askOrderNotes: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Add Order Notes',
    input: 'textarea',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
  }

  public static askEmail: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Please specify an email.',
    input: 'email',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    inputValidator: (value) =>
      (!Validators.isEmail(value) && 'Please specify a valid email.') || null,
  }

  public static specifyReasonMixin: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Please specify a reason.',
    input: 'textarea',
    inputAttributes: {
      autocapitalize: 'off',
    },
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    inputValidator: (value) => (!value && 'Please specify a reason.') || null,
  }

  public static cancelItemMixin: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, cancel this!',
    cancelButtonText: 'No, go back!',
  }

  public static deleteItemMixin: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete this!',
    cancelButtonText: 'No, go back!',
  }

  public static confirmMixin: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Are you sure?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Yes, do it!',
    cancelButtonText: 'No, go back!',
  }

  public static askApprove: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
    },
    title: 'Do you want to approve?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Yes, do it!',
    cancelButtonText: 'No, go back!',
  }

  public static failedMixin: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
      confirmButton: 'btn btn-primary',
    },
    icon: 'error',
    confirmButtonText: 'OK',
  }

  public static confirmReloadMixin: SweetAlertOptions = {
    ...SwalMixins.baseMixin,
    customClass: {
      ...SwalMixins.baseMixin.customClass,
      confirmButton: 'btn btn-primary',
    },
    title: 'Application Update',
    text: 'Please reload to get the latest update.',
    icon: 'info',
    confirmButtonText: 'Reload Now!',
    showCancelButton: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
    allowEnterKey: false,
    html: '<div class="swal2-corners"></div>',
  }
}
