import clsx from 'clsx'
import {useMemo} from 'react'

import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {getWaiverStatusVariant} from '../DigitalWaiverCard/DigitalWaiverCard'

export interface DigitalWaiverStatusColumnProps {
  data: TicketWaiverModel
  className?: string
  tabCode?: string
}

export const DigitalWaiverStatusColumn = ({
  data,
  className,
  tabCode,
}: DigitalWaiverStatusColumnProps) => {
  const variant = useMemo(() => {
    return getWaiverStatusVariant({data, tabCode})
  }, [data, tabCode])

  return (
    <span
      style={{
        color: variant,
      }}
      className={clsx(`text-capitalize`, className)}
    >
      {data.dwStatus}
    </span>
  )
}
