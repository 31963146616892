import {FormikContextType} from 'formik'
import {useCallback, useMemo} from 'react'

export const useFormikNumberInputHelpers = <Values extends Record<string, any>>(
  formik: FormikContextType<Values>,
  name: string
) => {
  const onChange = useCallback(
    (value: number) => {
      formik.setFieldValue(name, value)
    },
    [formik, name]
  )

  const value = useMemo(() => {
    return formik.values[name] as number
  }, [formik.values, name])

  return {
    value,
    onChange,
  }
}
