import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {ScanOrTypeQr} from '../../ScanOrTypeQr'
import {ConfirmPageLayout} from '../../../layout/ConfirmPageLayout'
import type {PaymentMethodFormValues} from '.'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {QrCodeDataParser} from '../../../../../utils/Qr/QrCodeDataParser'

export interface YasPaymentMethodProps<T extends PaymentMethodFormValues> {
  onBack: () => void
  formik: FormikContextType<T>
  onPay: () => void | Promise<void>
  disableScan?: boolean
}

export const YasPaymentMethod = <T extends PaymentMethodFormValues>({
  onBack,
  onPay,
  formik,
  disableScan,
}: YasPaymentMethodProps<T>) => {
  const {pushError} = useAlerts()
  const handleScan = useCallback(
    async (value: string) => {
      try {
        const qr = new QrCodeDataParser(value)
        formik.setFieldValue('yasPay', qr.getVoucherCode())
        await onPay()
      } catch (e) {
        pushError(e)
      }
    },
    [formik, onPay, pushError]
  )

  return (
    <>
      <ConfirmPageLayout onBackClick={onBack} headerLabel='Payment Method'>
        <h2>Scan Yas Pay QR Code</h2>
        <p>Scan the QR code on your YasEats cash card</p>
        <ScanOrTypeQr
          disabled={formik.isSubmitting || disableScan}
          placeholder='Please type QR Code'
          scanDelay={1000}
          onSubmit={handleScan}
        />
      </ConfirmPageLayout>
    </>
  )
}
