import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {AvatarImageInputProps} from '../AvatarImageInput/AvatarImageInput'

export type UseFormikImageInputHelpersProps = Pick<AvatarImageInputProps, 'value' | 'onChange'>

export const useFormikImageInputHelpers = (formik: FormikContextType<any>) => {
  const getProps = useCallback(
    (name: string): UseFormikImageInputHelpersProps => {
      return {
        value: formik.values[name],
        onChange: (value) => {
          formik.setFieldValue(name, value)
        },
      }
    },
    [formik]
  )

  return {
    getProps,
  }
}
