import clsx from 'clsx'
import {useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {MetronicIcon} from '../inputs/MetronicIcon'
import type {DetailHeaderTabProps} from './DetailHeaderTab'
import {useTabItemHelper} from './useTabItemHelper'

export interface DetailHeaderTabDropDownTabProps {
  items: DetailHeaderTabProps[]
  isChild?: boolean
}

export const DetailHeaderTabDropDownTab = ({items, isChild}: DetailHeaderTabDropDownTabProps) => {
  const {checkItemIsActive} = useTabItemHelper()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const content = useMemo(() => {
    return items.map((item, i) => {
      const isActive = checkItemIsActive(item)
      const subMenu = item.items?.length ? (
        <DetailHeaderTabDropDownTab isChild items={item.items} />
      ) : null

      if (item.link) {
        const transitory = item.transitory || false
        const shouldShow = !transitory || (transitory && isActive)

        if (!shouldShow) {
          return null
        }

        return (
          <div
            key={i}
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='auto'
            className='menu-item px-3'
          >
            <Link onClick={item.onClick} to={item.link.to} className='menu-link px-3'>
              <div className={clsx('menu-title', {'text-primary': isActive})}>{item.label}</div>
              {subMenu && <MetronicIcon iconType='Navigation' iconName='Angle-right' />}
            </Link>
            {subMenu}
          </div>
        )
      }

      return (
        <div
          key={i}
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='auto'
          className='menu-item px-3'
        >
          <div role='button' onClick={item.onClick} className='menu-link px-3'>
            <div className={clsx('menu-title', {'text-primary': isActive})}>{item.label}</div>
            {subMenu && <MetronicIcon iconType='Navigation' iconName='Angle-right' />}
          </div>
          {subMenu}
        </div>
      )
    })
  }, [checkItemIsActive, items])

  if (isChild) {
    return <div className='menu-sub menu-sub-dropdown w-175px py-4'>{content}</div>
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-4'
        data-kt-menu='true'
      >
        {content}
      </div>
    </>
  )
}
