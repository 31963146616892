import clsx from 'clsx'
import {useMemo} from 'react'
import {ButtonVariant} from '../../../../components/inputs/Button'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {TabItem} from './TabItem'

export interface TabsProps {
  tabs: SelectInputItem[]
  onClick?: (value: string) => void
  useLink?: boolean
  value?: string
  className?: string
  activeVariant?: ButtonVariant
  inactiveVariant?: ButtonVariant
}

export const Tabs = ({
  tabs,
  activeVariant,
  inactiveVariant,
  className,
  onClick,
  value,
  useLink,
}: TabsProps) => {
  const tabItems = useMemo(() => {
    return tabs.map((tab, i) => {
      const isLast = i === tabs.length - 1
      return (
        <TabItem
          useLink={useLink}
          className={clsx({'border-end': !isLast})}
          key={tab.value}
          activeVariant={activeVariant}
          inactiveVariant={inactiveVariant}
          onClick={onClick}
          active={tab.value === value}
          {...tab}
        />
      )
    })
  }, [tabs, useLink, activeVariant, inactiveVariant, onClick, value])

  return <div className={clsx('d-flex overflow-auto', className)}>{tabItems}</div>
}
