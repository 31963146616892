import {Badge} from '../../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../../components/inputs/Button'
import {ProductTicketType} from '../../../../../../models/ems/ProductModel'

export interface ProductTicketTypeColumnProps {
  data: {type: ProductTicketType}
}
export const ProductTicketTypeColumn = ({data}: ProductTicketTypeColumnProps) => {
  return (
    <Badge uppercase variant={getBadgeVariant(data.type)}>
      {data.type}
    </Badge>
  )
}

const getBadgeVariant = (type: ProductTicketType): ColorVariant => {
  if (type === 'card') {
    return 'primary'
  }
  return 'info'
}
