import {useCallback, useMemo, useState} from 'react'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {TableColumnOptions} from '../../../../../../components/tables/TableColumn'
import {ColumnStyle} from '../../../../../../components/tables/constants/ColumnStyle'
import {ProductLocationModel} from '../../../../../../models/ems/ReservationModel'
import {FilterTable} from '../../../../../../components/tables/FilterTable'
import {ReservationSeatInputItemValue} from '../../../../../../components/inputs/ReservationSeatInput/ReservationSeatInputItem'
import {useOnChange} from '../../../../../../components/hooks/useOnChange'
import {v4 as uuidv4} from 'uuid'
import {ReservationProductLocationTableActions} from '../../../../../default/ems/components/wizards/ReservationWizard/components/ReservationProductLocationTableActions'
import {FormikContextType} from 'formik'
// import { ReservationWizardProductVenueStepFormValues } from '../steps/ReservationWizardProductVenueStep'
import {useModalState} from '../../../../../../components/modals/useModalState'
import {PortalAssignSeatsLocationModal} from '../../../../../default/ems/components/wizards/ReservationWizard/modals/PortalAssignSeatsLocationModal'
import {useReservationProductData} from '../../../../../default/ems/components/drawers/ReservationFormDrawer/useReservationProductData'
import {GetProductByCode} from '../../../../redux/CustomerPortalCRUD'
import {ReservationFormValues} from '../ReservationWizard'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {MetronicIcon} from '../../../../../../components/inputs/MetronicIcon'
import {Badge} from '../../../../../../components/badge/Badge'
import {SeatTableColumn} from '../components/SeatTableColumn'

export interface ProductLocationTableProps<T extends ReservationFormValues> {
  formik: FormikContextType<T>
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<ProductLocationModel>
  onRefresh?: () => void
  onDelete?: (data: ProductLocationModel) => void
  onEdit?: (item: any) => void
  eventCode: string | null
  onChangePageNumber?: (pageNumber: number) => void
  onChangePageSize?: (size: number) => void
  currentPageSize?: number
  currentPageNumber?: number
}

export const ProductLocationWizardTable = <T extends ReservationFormValues>({
  data,
  onFilter,
  onDelete,
  onEdit,
  eventCode,
  formik,
  onChangePageNumber,
  onChangePageSize,
  currentPageSize,
  currentPageNumber,
}: ProductLocationTableProps<T>) => {
  const {getModalState, open: openModal, hide: hideModal} = useModalState()
  const [currentLocations, setCurrentLocations] = useState<ReservationSeatInputItemValue[]>([
    {
      value: '',
      id: uuidv4(),
      seatMaps: null,
      label: '',
      productCode: '',
      qty: 0,
    },
  ])
  const [product, setProduct] = useState<ProductLocationModel>()
  const [customer, setCustomer] = useState<CustomerModel | null>(null)
  const [locationsByProduct, setLocationsByProduct] = useState<
    Record<string, ReservationSeatInputItemValue[]>
  >({})
  const [previousProductCode, setPreviousProductCode] = useState<string | null>(null) // Retain
  const [count, setCount] = useState(0)
  // const {hiddenColumns, setHiddenColumns} = useTableOptions({
  //   tableName: 'reservation-product-location-table',
  // })

  const {locationSearchResults, getLocations} = useReservationProductData({
    productCode: product?.code,
  })

  const getSeatAssignmentHandler = useCallback(
    async (data: ProductLocationModel) => {
      openModal()
      setProduct(data)
      if (formik.values.customer) setCustomer(formik.values.customer)
      setCount(data.qty)
    },
    [formik.values.customer, openModal]
  )
  useOnChange(product, () => {
    if (product) {
      if (locationsByProduct[product.code]) {
        setCurrentLocations(locationsByProduct[product.code])
      } else {
        setCurrentLocations([
          {
            value: '',
            id: uuidv4(),
            seatMaps: null,
            label: '',
            productCode: '',
            qty: 0,
          },
        ])
      }
      getLocations()
      setPreviousProductCode(product.code)
    }
  })

  const rowActions = useCallback(
    (data: ProductLocationModel) => (
      <ReservationProductLocationTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    ),
    [getSeatAssignmentHandler, onDelete, onEdit]
  )
  const idExtractor = useCallback((data: ProductLocationModel) => {
    return data.code + uuidv4
  }, [])

  //LOCATIONS
  const handleOnChangeLocation = useCallback(
    (newValues: ReservationSeatInputItemValue[]) => {
      setCurrentLocations(newValues)
      if (product) {
        setLocationsByProduct((prev) => ({
          ...prev,
          [product?.code]: newValues,
        }))
      }
    },
    [product]
  )

  const mapProduct = useCallback(async (code: string) => {
    try {
      const {data} = await GetProductByCode(code)
      return data
    } catch (e) {
      return null
    }
  }, [])

  const handleSeatedProductSave = useCallback(async () => {
    const fetchedProducts = await Promise.all(
      currentLocations.map((location) => mapProduct(location.productCode))
    )
    const updatedProducts = [...formik.values.products]

    currentLocations.forEach((location, index) => {
      const fetchedProduct = fetchedProducts[index]
      // fetchedProduct.children =
      const transformedLocation = {
        id: location.id,
        data: fetchedProduct,
        count: location.qty,
        isSeated: true,
        type: 'product',
        seatMap: location.seatMaps,
        locationCode: location.value,
        isNew: false,
        isFromAddProd: false,
      }

      // const existingIndex = updatedProducts.findIndex(
      //   (p) => p.data?.code === location.productCode && p.locationCode === location.value
      // )
      const existingIndex = updatedProducts.findIndex((p) => p.data?.code === location.productCode)

      if (existingIndex !== -1) {
        // updatedProducts[existingIndex] = transformedLocation // Update the existing product
        updatedProducts[existingIndex] = {
          ...updatedProducts[existingIndex],
          locationCode: location.value,
          seatMap: location.seatMaps,
          isNew: false,
          isSeated: true,
          isFromAddProd: false,
        }
      } else {
        // updatedProducts.push(transformedLocation) // Add new product
        updatedProducts[existingIndex] = {
          ...updatedProducts[existingIndex],
          locationCode: location.value,
          seatMap: location.seatMaps,
          isNew: false,
          isSeated: true,
          isFromAddProd: false,
        }
      }
    })

    // formik.values.products = updatedProducts
    formik.setFieldValue('products', updatedProducts)
    hideModal()
  }, [currentLocations, formik, hideModal, mapProduct])

  const selectedSeats = useMemo(() => {
    if (formik.values.products && formik.values.products.length && product) {
      const found = formik.values.products.find(
        (item) => item.data && item.seatMap && item.data.code === product.code
      )

      if (found && found.data) {
        const foundData = found.data
        return {
          name: foundData.name,
          code: foundData.code,
          qty: foundData.qty,
          isSeated: foundData.isSeated,
          type: foundData.type,
          seatMaps: found.seatMap,
          locationCode: found.locationCode,
          remainQty: found.maxQTY,
          maxQty: found.maxQTY,
          startedAt: foundData.startedAt,
          endedAt: foundData.endedAt,
        } as ProductLocationModel
      }
    }

    return undefined
  }, [product, formik.values.products])
  const tableColumns = useMemo(() => {
    const columns: TableColumnOptions<ProductLocationModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: false,
        render: ({data}) => {
          return <div style={{marginLeft: '16px'}}>{data.code}</div>
        },
      },
      {
        field: 'name',
        label: 'Product Name',
        sortable: false,
        render: ({data}) => {
          return <div style={{marginLeft: '16px'}}>{data.name}</div>
        },
      },
      {
        field: 'qty',
        label: 'Qty',
        sortable: false,
        hideable: true,
        render: ({data}) => {
          return <div style={{marginLeft: '18px'}}>{data.qty}</div>
        },
      },
      {
        field: 'type',
        label: 'Type',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        render: ({data}) => (
          <Badge
            className='text-nowrap'
            uppercase
            variant={data.type === 'product' ? 'info' : 'warning'}
          >
            {data.type}
          </Badge>
        ),
      },
      {
        field: 'isSeated',
        label: 'Seated?',
        sortable: true,

        cellStyle: ColumnStyle.NAME,
        render: ({data}) =>
          data.isSeated ? (
            <MetronicIcon
              className='svg-icon-success svg-icon-1hx'
              iconType='Navigation'
              iconName='Check'
            />
          ) : null,
      },
      {
        field: 'seatMaps',
        label: 'Seats',
        sortable: true,

        render: ({data}) => {
          return <SeatTableColumn customersSeats={data} products={formik.values.products} />
        },
      },
    ]
    return columns
  }, [formik.values.products])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        // hiddenColumns={hiddenColumns}
        // onHiddenColumnsChange={setHiddenColumns}
        data={data?.data}
        currentPageNumber={data?.page}
        columns={tableColumns}
        totalItems={data?.total || 10}
        actions={rowActions}
        currentPageSize={currentPageSize}
        onChangePageNumber={onChangePageNumber}
        onChangePageSize={onChangePageSize}
      />
      {product && (
        <PortalAssignSeatsLocationModal
          onModalClose={hideModal}
          eventCode={eventCode}
          product={product}
          customerCode={customer?.code}
          locations={locationSearchResults || []}
          selectedLocations={currentLocations}
          onAdd={handleOnChangeLocation}
          onSave={handleSeatedProductSave}
          {...getModalState()}
          qty={count}
          formik={formik}
          selectedSeat={selectedSeats}
          initialValues={{
            value: selectedSeats?.locationCode || '',
            label: selectedSeats?.name || '',
            id: selectedSeats ? selectedSeats?.code + uuidv4 : '',
            seatMaps: selectedSeats?.seatMaps || null,
            productCode: selectedSeats?.code || '',
            qty: selectedSeats?.qty || 0,
          }}
        />
      )}
    </>
  )
}
