import {useCallback} from 'react'
import {SweetAlertResult} from 'sweetalert2'
import {SwalMixins} from '../../utils/SwalMixins'
import {SweetAlert} from './SweetAlert'

export interface ReasonModalProps {
  open: boolean
  onClose: (reason?: string) => void
  title?: string
  description?: string
}

export const ReasonModal = ({onClose, open, title, description}: ReasonModalProps) => {
  const handleClose = useCallback(
    (result: SweetAlertResult<string>) => {
      onClose(result.value)
    },
    [onClose]
  )

  return (
    <SweetAlert
      open={open}
      onClose={handleClose}
      {...SwalMixins.specifyReasonMixin}
      titleText={title}
      text={description}
    />
  )
}
