import clsx from 'clsx'
import {useCallback} from 'react'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {PreparingTimeText} from '../PreparingTimeText'

export interface OutletCardProps {
  image: ImageInputValue
  onClick?: () => void
  name: string
  description: string
  preparingTime: number
  className?: string
  onReserveTable?: () => void
}

export const OutletCard = ({
  description,
  image,
  name,
  onClick,
  preparingTime,
  className,
  onReserveTable,
}: OutletCardProps) => {
  const handleClick = useCallback(() => {
    onClick && onClick()
  }, [onClick])

  return (
    <div>
      <button
        onClick={handleClick}
        className={clsx('btn p-0 w-100 text-start bg-transparent', className)}
      >
        <img className='img-fluid mb-2 w-100' src={image.url} alt={image.filename} />
      </button>
      <div className='d-flex justify-content-between align-items-center'>
        <p className='fs-3 fw-bolder text-white m-0'>{name}</p>
        {onReserveTable && (
          <button
            onClick={onReserveTable}
            className='btn btn-sm btn-light-success text-uppercase rounded-0 border border-success text-nowrap'
          >
            Reserve a table
          </button>
        )}
      </div>
      <p className='text-secondary'>{description}</p>
      <PreparingTimeText preparingTime={preparingTime} />
    </div>
  )
}
