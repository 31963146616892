import {useMemo, useState} from 'react'
import clsx from 'clsx'
import {Button} from '../../../../components/inputs/Button'
import {ProductCategoryWidgetModel} from '../../../../models/customer-portal/WidgetModel'
import {ProductCategoryDetails} from './ProductCategoryDetails'
import {SubProductsCard} from './SubProductsCard'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'

interface DashboardCardProps {
  items: ProductCategoryWidgetModel[]
  isFirstColumn?: boolean
}

export const ProdCatDashboardCard = ({items, isFirstColumn = false}: DashboardCardProps) => {
  const [rotation, setRotation] = useState<string>()
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => down('xs'), [down])

  const rotate = useMemo(() => rotation, [rotation])

  const cardBox = useMemo(() => {
    let _isFirstColumn = isFirstColumn ? '882px' : '400px'

    return (
      <div
        style={{
          height: isMobile ? '380px' : _isFirstColumn,
          minHeight: isMobile ? '380px' : '400px',
        }}
      >
        <div className='card flip-card'>
          <div
            className='flip-card-inner'
            style={{
              transform: rotate,
              boxShadow: items.length ? 'rgb(52 92 89 / 73%) 0rem 1rem 1rem -1.2rem' : 'none',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              height: '360px',
            }}
          >
            <div
              className={clsx(
                'diagonal-cut-corner-polygon flip-card-inner-front rs-gradient',
                items.length && 'border border-gray'
              )}
              style={{zIndex: rotation ? 0 : 10}}
            >
              <div className='rounded-0 card shadow-lg h-100 d-flex flex-column justify-content-between'>
                <div className='flex-grow-1'>
                  <div
                    style={{
                      height: '100%',
                      maxHeight: '308px',
                      overflowY: 'auto',
                    }}
                  >
                    <ProductCategoryDetails items={items} />
                  </div>
                </div>
                {items.length ? (
                  <Button
                    className='btn-bg-warning rounded-0 text-dark ff-admm-bold fs-4'
                    fullWidth
                    onClick={rotation ? undefined : () => setRotation('rotateY(180deg)')}
                    style={{
                      height: '50px',
                    }}
                  >
                    View More
                  </Button>
                ) : null}
              </div>
            </div>

            <div
              className='flip-card-inner-back d-flex flex-column justify-content-between rs-gradient border border-gray'
              style={{zIndex: rotation ? 10 : 0}}
            >
              <div
                className='overflow-auto'
                style={{
                  height: '305px',
                }}
              >
                <SubProductsCard items={items} />
              </div>

              <Button
                className='btn-bg-warning rounded-0 text-dark ff-admm-bold fs-4'
                fullWidth
                onClick={rotation ? () => setRotation(undefined) : undefined}
                style={{
                  height: '50px',
                }}
              >
                View Less
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }, [isFirstColumn, isMobile, items, rotate, rotation])

  return cardBox
}
