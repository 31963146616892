export class SeatValue {
  public row: string
  public column: number

  constructor(label: string)
  constructor(seatRow: string, seatColumn: number)
  constructor(seatRowOrLabel: string, seatColumn?: number) {
    if (!seatColumn) {
      const [column, row] = SeatValue.splitSeatLabel(seatRowOrLabel)
      this.row = column
      this.column = row
    } else {
      this.row = seatRowOrLabel
      this.column = seatColumn
    }
  }

  private static splitSeatLabel = (label: string): [string, number] => {
    const columnMatch = label.match(COLUMN_REGEX)
    const rowMatch = label.match(ROW_REGEX)

    const column = columnMatch ? columnMatch[0] : null
    const row = rowMatch ? parseInt(rowMatch[0]) : null

    if (!column || !row) {
      throw new Error(`Invalid Seat Label ${label}`)
    }

    return [column, row]
  }

  private static getLexicalScore = (string: string) => {
    let score = 0

    for (let i = 0; i < string.length; i++) {
      score += string.charCodeAt(i) - 64
    }

    return score
  }

  public compareColumn = (seat: SeatValue) => {
    const columnAValue = this.getColumnValue()
    const columnBValue = seat.getColumnValue()

    return columnAValue - columnBValue
  }

  public compareRow = (seat: SeatValue) => {
    return this.column - seat.column
  }

  private getColumnValue = () => {
    return SeatValue.getLexicalScore(this.row)
  }

  public getValue = () => {
    return `${this.row}${this.column}`
  }
}

const COLUMN_REGEX = /[A-Z]+/
const ROW_REGEX = /\d+/
