import {v4 as uuidv4} from 'uuid'
import {FileModel} from '../../../models/FileModel'

export abstract class BaseFileInputValue {
  public id: string

  constructor(public url: string, public filename: string, id: string = uuidv4()) {
    this.id = id
  }

  public static getUrlFromFileModel(staticUrl: string, file: FileModel) {
    return `${staticUrl}/${file.slug}`
  }

  public abstract toApiValue(): Blob | null
}
