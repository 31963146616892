import {DaysSeatManifestProps} from './../../../hooks/useSeatMapState'
import {useCallback} from 'react'
import {SeatMapValue} from '../SeatMapValue'
import {SeatMapDays} from '../SVGSeatMapInput/SVGSeat'

export interface UseSeatHelperOptions {
  renderHidden?: boolean
  hidden?: SeatMapValue
  occupied?: SeatMapValue
  extra?: SeatMapValue
  shared?: SeatMapValue
  sharedDisabled?: SeatMapValue
  disabled?: SeatMapValue
  value?: SeatMapValue
  seatsDays?: DaysSeatManifestProps | null
  isShowNoneFilledDays?: boolean
  isPortal?: boolean
}

export const useSeatHelper = ({
  disabled,
  extra,
  hidden,
  shared,
  sharedDisabled,
  occupied,
  renderHidden = false,
  value,
  seatsDays,
  isShowNoneFilledDays = true,
  isPortal,
}: UseSeatHelperOptions) => {
  const shouldSeatRender = useCallback(
    (row: string, column: number): boolean => {
      if (hidden?.isValueExist(row, column)) {
        return renderHidden
      }
      return true
    },
    [hidden, renderHidden]
  )

  const isSeatOccupied = useCallback(
    (rowLetter: string, column: number) => {
      if (occupied) {
        return occupied.isValueExist(rowLetter, column)
      }
      return false
    },
    [occupied]
  )

  const isSeatExtra = useCallback(
    (rowLetter: string, column: number) => {
      if (extra) {
        return extra.isValueExist(rowLetter, column)
      }
      return false
    },
    [extra]
  )

  const isSeatShared = useCallback(
    (rowLetter: string, column: number) => {
      if (shared) {
        return shared.isValueExist(rowLetter, column)
      }
      return false
    },
    [shared]
  )

  const isSeatDay = useCallback(
    (rowLetter: string, column: number) => {
      if (seatsDays) {
        const found = Object.keys(seatsDays).filter((item, idx) => {
          return seatsDays[item].isValueExist(rowLetter, column)
        })
        if (found && found.length) return true
        return false
      }
      return false
    },
    [seatsDays]
  )

  const isSeatSharedDisabled = useCallback(
    (rowLetter: string, column: number) => {
      if (sharedDisabled) {
        return sharedDisabled.isValueExist(rowLetter, column)
      }
      return false
    },
    [sharedDisabled]
  )

  const isSeatDisabled = useCallback(
    (rowLetter: string, column: number) => {
      if (disabled) {
        return disabled.isValueExist(rowLetter, column)
      }
      return false
    },
    [disabled]
  )

  const isSeatHidden = useCallback(
    (rowLetter: string, column: number) => {
      if (hidden) {
        return hidden.isValueExist(rowLetter, column)
      }
      return false
    },
    [hidden]
  )

  const getSeatColor = useCallback(
    (row: string, column: number): string => {
      if (value?.isValueExist(row, column)) {
        return !isPortal ? ACTIVE_COLOR : ADMM_ACTIVE_COLOR
      }
      if (isSeatExtra(row, column)) {
        return !isPortal ? WARNING_COLOR : ADMM_WARNING_COLOR
      }
      if (isSeatHidden(row, column)) {
        return !isPortal ? BLACK_COLOR : ADMM_BLACK_COLOR
      }
      if (isSeatDisabled(row, column)) {
        return !isPortal ? DEFAULT_COLOR: DEFAULT_COLOR
      }
      if (isSeatOccupied(row, column)) {
        return !isPortal ? DANGER_COLOR : ADMM_DANGER_COLOR
      }
      if (isSeatShared(row, column)) {
        return !isPortal ? PURPLE_COLOR : ADMM_PURPLE_COLOR
      }
      if (isSeatSharedDisabled(row, column)) {
        return !isPortal ? PURPLE_COLOR : ADMM_PURPLE_COLOR
      }
      return !isPortal ? DEFAULT_COLOR : ADMM_DEFAULT_COLOR
    },
    [
      isPortal,
      isSeatDisabled,
      isSeatExtra,
      isSeatHidden,
      isSeatOccupied,
      isSeatShared,
      isSeatSharedDisabled,
      value,
    ]
  )
  const getCustomColor = useCallback(
    (row: string, column: number): string => {
      if (value?.isValueExist(row, column)) {
        return !isPortal ? ACTIVE_COLOR : ADMM_ACTIVE_COLOR
      }
      if (isSeatExtra(row, column)) {
        return !isPortal ? WARNING_COLOR : ADMM_WARNING_COLOR
      }
      if (isSeatHidden(row, column)) {
        return !isPortal ? BLACK_COLOR : ADMM_BLACK_COLOR
      }
      if (isSeatDisabled(row, column)) {
        return !isPortal ? DANGER_COLOR : ADMM_DANGER_COLOR
      }
      if (isSeatOccupied(row, column)) {
        return !isPortal ? DANGER_COLOR : ADMM_DANGER_COLOR
      }
      if (isSeatShared(row, column)) {
        return !isPortal ? PURPLE_COLOR : ADMM_PURPLE_COLOR
      }
      return !isPortal ? DEFAULT_COLOR : ADMM_DEFAULT_COLOR
    },
    [isPortal, isSeatDisabled, isSeatExtra, isSeatHidden, isSeatOccupied, isSeatShared, value]
  )
  const getSeatBorder = useCallback(
    (row: string, column: number): string => {
      if (isSeatDisabled(row, column)) {
        return !isPortal ? DEFAULT_COLOR : ADMM_DEFAULT_COLOR
      }

      return BLUE_COLOR 
    },
    [isPortal, isSeatDisabled]
  )





  const getSeatStroke = useCallback(
    (row: string, column: number): string => {
      if (isSeatDisabled(row, column)) {
        return DEFAULT_COLOR
      }
      return WHITE_COLOR
    },
    [isSeatDisabled]
  )

  const getSeatDays = useCallback(
    (row: string, column: number) => {
      if (seatsDays && (isShowNoneFilledDays ? true : isSeatDay(row, column))) {
        let seats: SeatMapDays = {}
        Object.keys(seatsDays).forEach((item) => {
          if (isShowNoneFilledDays) {
            seats[item] = {
              color: seatsDays[item]?.isValueExist(row, column)
                ? getSeatDaysColor(item)
                : 'transparent',
              text: item.slice(0, 2).toUpperCase(),
              stroke: getSeatDaysColor(item),
            }
          } else {
            if (seatsDays[item]?.isValueExist(row, column)) {
              seats[item] = {
                color: getSeatDaysColor(item),
                text: item.slice(0, 2).toUpperCase(),
                stroke: getSeatDaysColor(item),
              }
            }
          }
        })

        return seats
      }
    },
    [isSeatDay, isShowNoneFilledDays, seatsDays]
  )

  return {
    shouldSeatRender,
    isSeatOccupied,
    isSeatHidden,
    isSeatDisabled,
    isSeatExtra,
    getSeatColor,
    getSeatBorder,
    getCustomColor,
    getSeatDays,
    getSeatStroke
  }
}

export const ACTIVE_COLOR = '#0d6efd'
export const DEFAULT_COLOR = '#333333'
export const WARNING_COLOR = '#ffa800'
export const DANGER_COLOR = '#f64e60'
export const BLACK_COLOR = '#0b0b0c'
export const PURPLE_COLOR = '#8950fc'
export const WHITE_COLOR = 'white'

//ADMM COLORS
export const BLUE_COLOR = '#174B83'
export const ADMM_ACTIVE_COLOR = '#0b6ec1'
export const ADMM_DEFAULT_COLOR = '#031528'
export const ADMM_WARNING_COLOR = '#ffa800'
export const ADMM_DANGER_COLOR = '#440808'
export const ADMM_BLACK_COLOR = '#0b0b0c'
export const ADMM_PURPLE_COLOR = '#8950fc'


export const SATURDAY_COLOR = '#C98832'
export const SUNDAY_COLOR = '#0082CA'
export const MONDAY_COLOR = 'blue'
export const TUESDAY_COLOR = 'orange'
export const WEDNESDAY_COLOR = 'gray'
export const THURSDAY_COLOR = '#EC098D'
export const FRIDAY_COLOR = '#80BC00'

export const getSeatDaysColor = (key: string): string => {
  if (key.includes('saturday')) {
    return SATURDAY_COLOR
  }
  if (key.includes('sunday')) {
    return SUNDAY_COLOR
  }
  if (key.includes('monday')) {
    return MONDAY_COLOR
  }
  if (key.includes('tuesday')) {
    return TUESDAY_COLOR
  }
  if (key.includes('wednesday')) {
    return WEDNESDAY_COLOR
  }
  if (key.includes('thursday')) {
    return THURSDAY_COLOR
  }
  if (key.includes('friday')) {
    return FRIDAY_COLOR
  }
  return ADMM_DEFAULT_COLOR
}
