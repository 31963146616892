import {useMemo} from 'react'
import {Button} from '../../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'

export interface ReservationTableActionsProps {
  onNewReservationClick?: () => void
}
export const ReservationTableRightActions = ({
  onNewReservationClick,
}: ReservationTableActionsProps) => {
  const addButton = useMemo(() => {
    if (onNewReservationClick) {
      return (
        <Button variant='primary' onClick={onNewReservationClick}>
          <MetronicIcon iconType='Navigation' iconName='Plus' /> New Reservation
        </Button>
      )
    }
    return null
  }, [onNewReservationClick])
  return <>{addButton}</>
}
