import {Fragment, ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {EventModel} from '../../../models/ems/EventModel'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {MetronicIconButton} from '../../../components/inputs/MetronicIconButton'
import {Spinner} from '../../../components/utils/Spinner'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {ToolbarTitle} from '../../../components/layouts/DefaultLayout/ToolbarTitle'

export interface LinkBreadCrumb {
  link?: string
  label: string
}

export interface LinkBreadcrumbsToolbarPortalProps {
  title?: ReactNode
  isLoading?: boolean
  breadcrumbs?: LinkBreadCrumb[]
  children?: ReactNode
  event?: EventModel | null
}

export const LinkBreadcrumbsPortalToolbar = ({
  isLoading,
  breadcrumbs,
  title,
  children,
  event,
}: LinkBreadcrumbsToolbarPortalProps) => {
  if (isLoading) {
    return (
      <Toolbar>
        <div className='h-100 d-flex align-items-center'>
          <Spinner size='lg'></Spinner>
        </div>
      </Toolbar>
    )
  }

  return (
    <Toolbar>
      <div className='d-flex h-100 rs-breadcrumbs'>
        <div className='d-flex flex-column h-100 justify-content-center'>
          <div>
            {typeof title === 'string' ? (
              <div className='d-flex align-items-center'>
                <div className='me-2'>
                  {!event?.isClosed && (
                    <MetronicIconButton
                      className='opacity-75'
                      variant='text'
                      color='success'
                      iconType='Code'
                      iconName='Done-circle'
                      tooltip='ACTIVE'
                      size='sm'
                      style={{
                        display: 'contents',
                      }}
                    />
                  )}
                </div>
                <ToolbarTitle>{title}</ToolbarTitle>
              </div>
            ) : (
              title
            )}
          </div>
          <div>
            {breadcrumbs &&
              breadcrumbs.length > 1 &&
              breadcrumbs.map((crumb, index) => {
                const hasNext = index < breadcrumbs.length - 1
                return (
                  <Fragment key={crumb.link + crumb.label}>
                    {crumb.link && hasNext ? (
                      <>
                        <Link to={crumb.link}>{crumb.label}</Link>
                        {hasNext && (
                          <MetronicIcon
                            iconType='Navigation'
                            iconName='Angle-right'
                            color='primary'
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {crumb.label}
                        {hasNext && (
                          <MetronicIcon
                            iconType='Navigation'
                            iconName='Angle-right'
                            color='primary'
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                )
              })}
          </div>
        </div>
        <div className='flex-grow-1'>{children}</div>
      </div>
    </Toolbar>
  )
}
