export interface TableSelectionCountLabelProps {
  count?: number
  className?: string
}

export const TableSelectionCountLabel = ({count, className}: TableSelectionCountLabelProps) => {
  if (count) {
    return (
      <div className={className}>
        <span>
          Selected records count: <b>{count}</b>
        </span>
      </div>
    )
  }
  return null
}
