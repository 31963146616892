import clsx from 'clsx'
import {ReactNode, useCallback, useEffect, useMemo, useRef} from 'react'
import {createPopper, Instance} from '@popperjs/core'
import styles from './WaiverAddTicketButton.module.scss'
import {useClickAwayListener} from '../../../../components/hooks/useClickAwayListener'
import {RootPortal} from '../../../../components/utils/RootPortal'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBooleanState} from '../../../../components/hooks/useBooleanState'
import {QRCodeModalInput} from '../../../../components/inputs/QRCode/QRCodeInputModal'
import {AddTicketByID} from '../../redux/DigitalWaiverCRUD'
import {useAlerts} from '../../../../components/alerts/useAlerts'
export interface WaiverAddTicketButtonProps {
  children?: ReactNode
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  className?: string
  onRefresh?: () => void
}

export const WaiverAddTicketButton = ({
  children,
  isOpen,
  onClose,
  onOpen,
  className,
  onRefresh,
}: WaiverAddTicketButtonProps) => {
  const popperRef = useRef<Instance | null>(null)
  const {
    disableState: closeModal,
    enableState: openModal,
    state: isModalOpen,
  } = useBooleanState(false)
  const iconButtonRef = useRef<HTMLDivElement>(null)
  const {push, pushError} = useAlerts()

  const handleClickAway = useCallback(() => {
    onClose()
  }, [onClose])

  const handleOpenAdvancedFilters = useCallback(() => {
    onOpen()
    popperRef.current?.update()
  }, [onOpen])

  const ref = useClickAwayListener<HTMLDivElement>({onClickAway: handleClickAway})

  useEffect(() => {
    if (iconButtonRef.current && ref.current) {
      const popper = createPopper(iconButtonRef.current, ref.current, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      })
      popperRef.current = popper
      return () => popper.destroy()
    }
  }, [ref])

  const handleQrScan = useCallback(
    async (value: string) => {
      try {
        const {data} = await AddTicketByID({
          barcode: value,
        })
        if (data) {
          onRefresh?.()
          closeModal()
          push({
            message: 'Ticket successfully Added',
            timeout: 5000,
            variant: 'success',
          })
        }
      } catch (err) {
        pushError(err)
        closeModal()
      }
    },
    [closeModal, onRefresh, push, pushError]
  )

  const ticketByIdButton = useMemo(() => {
    return (
      <div
        ref={iconButtonRef}
        className={clsx(
          'rounded-pill border border-1 border-primary search-dw-field d-flex justify-content-between align-items-center py-2 pe-0 text-nowrap',
          className
        )}
      >
        <p
          className='mb-0 ms-4'
          style={{
            lineHeight: '15px',
          }}
        >
          Add ticket by Id
        </p>
        <div className='d-flex gap-3 me-3'>
          <MetronicIconButton
            size='md'
            color='white'
            iconType='Navigation'
            iconName='Plus'
            className='bg-transparent'
            style={{
              width: '25px',
              height: '25px',
            }}
            onClick={handleOpenAdvancedFilters}
          />
          <MetronicIconButton
            size='md'
            color='white'
            iconType='Shopping'
            iconName='Barcode-read'
            className='bg-transparent'
            onClick={openModal}
            style={{
              width: '25px',
              height: '25px',
            }}
          />
          <QRCodeModalInput
            autoSubmit
            onSubmit={handleQrScan}
            onClose={closeModal}
            open={isModalOpen}
            hideButton
          />
        </div>
      </div>
    )
  }, [className, closeModal, handleOpenAdvancedFilters, handleQrScan, isModalOpen, openModal])

  return (
    <>
      {ticketByIdButton}
      <RootPortal>
        <div
          ref={ref}
          style={{
            width: `${iconButtonRef.current?.clientWidth}px`,
          }}
          className={clsx(
            'bg-light-primary p-5 m-0 rounded',
            {
              'd-none': !isOpen,
            },
            styles.menu
          )}
        >
          <div className={clsx(styles.arrow)} data-popper-arrow></div>
          {children}
        </div>
      </RootPortal>
    </>
  )
}
