import {useCallback, useMemo} from 'react'
import {Button} from '../../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {DateTimeFormatter} from '../../../../../components/utils/formatter/DateTimeFormatter'
import {OutletLink} from '../../../../../components/utils/OutletLink'
import {FilterModel} from '../../../../../models/FilterModel'
import {ReservationModel} from '../../../../../models/fnb/ReservationModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {ReservationStatusColumn} from './column-formatters/ReservationStatusColumn'
import {ReservationTableRowActions} from './column-formatters/ReservationTableRowActions'

export interface BaseReservationTableProps {
  data?: GlobalSearchModel<ReservationModel>
  onFilter: (filter: FilterModel) => void
  onEdit?: (data: ReservationModel) => void
  onApprove?: (data: ReservationModel) => void
  onDeny?: (data: ReservationModel) => void
  tableName: string
  removeColumns?: string[]
  onCreateClick?: () => void
}

export const BaseReservationTable = ({
  onFilter,
  data,
  onEdit,
  onApprove,
  onDeny,
  removeColumns,
  onCreateClick,
}: BaseReservationTableProps) => {
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'fnb-reservation',
  })
  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const rowActions = useCallback(
    (data: ReservationModel) => (
      <ReservationTableRowActions
        data={data}
        onApprove={onApprove}
        onDeny={onDeny}
        onEdit={onEdit}
      />
    ),
    [onApprove, onDeny, onEdit]
  )

  const columns = useMemo(() => {
    const columns: TableColumnOptions<ReservationModel>[] = [
      {
        label: 'Code',
        field: 'code',
        cellStyle: ColumnStyle.CODE,
      },
      {
        label: 'Outlet Name',
        field: 'outlet',
        render: ({data}) => <OutletLink outlet={data.outlet}>{data.outlet?.name}</OutletLink>,
        cellStyle: ColumnStyle.NAME,
        hideable: true,
        sortable: true,
      },
      {
        label: 'Customer Name',
        field: 'name',
        cellStyle: ColumnStyle.PERSON_FULL_NAME,
        hideable: true,
        sortable: true,
      },
      {
        label: 'Mobile',
        field: 'mobile',
        hideable: true,
        sortable: true,
      },
      {
        label: 'Date',
        field: 'date',
        render: ({data}) => (
          <DateTimeFormatter className='text-uppercase'>{data.date}</DateTimeFormatter>
        ),
        hideable: true,
        sortable: true,
      },
      {
        label: 'Pax',
        field: 'pax',
        hideable: true,
        sortable: true,
      },
      {
        label: 'Table #',
        field: 'tableNo',
        hideable: true,
        sortable: true,
      },
      {
        label: 'Reason',
        field: 'reason',
        hideable: true,
        sortable: true,
      },
      {
        label: 'Status',
        field: 'status',
        render: ({data}) => <ReservationStatusColumn data={data} />,
        hideable: true,
        sortable: true,
      },
    ]
    return columns.filter((column) => !removeColumns?.includes(column.field))
  }, [removeColumns])

  return (
    <FilterTable
      rightToolbar={
        onCreateClick && (
          <Button uppercase={false} variant='primary' onClick={onCreateClick}>
            <MetronicIcon iconType='Navigation' iconName='Plus' />
            Create Reservation
          </Button>
        )
      }
      hiddenColumns={hiddenColumns}
      onHiddenColumnsChange={setHiddenColumns}
      actions={rowActions}
      onFilter={onFilter}
      columns={columns}
      data={tableItems}
      totalItems={data?.total || 0}
      idExtractor={idExtractor}
    />
  )
}
