import {useCallback, useMemo} from 'react'
import {Button} from '../Button'
import {SeatMapCanvasInputProps} from './SeatMapCanvasInput'
import {SeatMapSelectionInput} from './SeatMapSelectionInput'
import {SeatMapValue} from './SeatMapValue'
import {TreeNodeItem} from '../TreeSelect/TreeSelect'
import {ResponsiveModal} from '../../modals/ResponsiveModal'
import {MetronicIconButton} from '../MetronicIconButton'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {DateRange} from '../../../utils/DateRange'
import {DateTimePickerInput} from '../datepickers/DateTimePickerInput'
import {SeatMapBoxLegends, SeatMapBoxLegendsProps} from './SeatMapBoxLegends'
import {getSeatDaysColor} from './hooks/useSeatHelper'
import {CustomersProductProps} from '../../../../app/models/booking-wizard/BulkBookingWizard'

export interface SeatMapSelectionModalInputProps extends SeatMapCanvasInputProps {
  open: boolean
  onHide: () => void
  count: number
  onSubmit: () => void
  locationItems?: TreeNodeItem[]
  onLocationChange?: (value: string) => void
  locationCode?: string
  disableSubmit?: boolean
  modalTitle?: string | ((remaining: number) => string)
  loading?: boolean
  disableSelection?: boolean
  dateRange?: DateRange
  minDate?: Date
  maxDate?: Date
  onDateRangeChange?: (dateRange: DateRange) => void
  submitButtonClassName?: string
  isCustom?: boolean
  legends?: JSX.Element
  isCircledDaysBox?: boolean
  isShowNoneFilledDays?: boolean
  multipleLocation?: boolean
  isPortal?: boolean
  product?: CustomersProductProps | null
}

export const SeatMapSelectionModalInput = ({
  className,
  open,
  onHide,
  count,
  value,
  onChange,
  onSubmit,
  disableSubmit,
  modalTitle,
  disableSelection,
  loading = false,
  dateRange,
  onDateRangeChange,
  minDate,
  maxDate,
  submitButtonClassName,
  isCustom,
  legends,
  isCircledDaysBox,
  isShowNoneFilledDays,
  multipleLocation,
  isPortal,
  product,
  ...props
}: SeatMapSelectionModalInputProps) => {
  const remaining = useMemo(() => {
    const selectCount = value?.getValueCount() || 0
    // return count - selectCount
    return Math.abs(count - selectCount)
  }, [value, count])

  const handleOnChange = useCallback(
    (values: SeatMapValue) => {
      if (!disableSelection && !loading) {
        const remaining = count - values.getValueCount()
        if (remaining >= 0) {
          onChange && onChange(values)
        }
      }
    },
    [disableSelection, loading, count, onChange]
  )

  const header = useMemo(() => {
    const title = `Please select seats: ${remaining} remaining.`
    let subtitle: string = ''
    if (modalTitle) {
      if (typeof modalTitle === 'string') {
        subtitle = modalTitle
      } else {
        subtitle = modalTitle(remaining)
      }
    }
    return (
      <div className='w-100 d-flex justify-content-between'>
        <h2>{title}</h2>
        <h2>{product?.productName}</h2>
        <h2>{subtitle}</h2>
      </div>
    )
  }, [modalTitle, remaining, product?.productName])

  const handleDateRangeChange = useCallback(
    (startOrEnd: 'start' | 'end') => (date: Date | null) => {
      if (dateRange && onDateRangeChange) {
        const newDateRange = dateRange.clone()
        if (startOrEnd === 'start') {
          newDateRange.setStart(date)
          if (!newDateRange.getEnd()) {
            newDateRange.setEnd(date)
          }
        } else {
          newDateRange.setEnd(date)
        }
        onDateRangeChange(newDateRange)
      }
    },
    [dateRange, onDateRangeChange]
  )
  const seatsDaysLegends = useMemo<SeatMapBoxLegendsProps[] | null>(() => {
    if (props.seatsDays) {
      return Object.keys(props.seatsDays).map((item) => {
        return {
          text: item,
          color: getSeatDaysColor(item),
        }
      })
    }

    return null
  }, [props.seatsDays])

  return (
    <ResponsiveModal noShowAnimation noPadding size='big' onClose={onHide} open={open}>
      <div className='swal2-corners'></div>
      <div className='h-100 p-5 d-flex flex-column position-relative px-20'>
        <div className='d-flex'>
          <div className='pt-5 flex-grow-1'>{header}</div>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
            className='mt-3'
          />
        </div>
        <div className='flex-grow-1 position-relative'>
          {dateRange && (
            <div
              style={{zIndex: 1}}
              className='d-flex justify-content-end position-absolute top-0 end-0 text-start flex-wrap'
            >
              <DateTimePickerInput
                noClear
                label='Start'
                onChange={handleDateRangeChange('start')}
                value={dateRange?.getStart() || null}
                minDate={minDate}
                maxDate={maxDate}
              />
              <DateTimePickerInput
                noClear
                className='ms-1'
                label='End'
                onChange={handleDateRangeChange('end')}
                value={dateRange?.getEnd() || null}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          )}
          <SeatMapSelectionInput
            selected={value}
            className={className}
            onSelectedChange={handleOnChange}
            maxSelected={count}
            isCircledDaysBox={isCircledDaysBox}
            isShowNoneFilledDays={isShowNoneFilledDays}
            isCustom={isCustom}
            isPortal={isPortal}
            {...props}
          />
        </div>
        <div className='d-flex gap-10 my-5'>
          {legends ? (
            <div>
              <div className='mb-3 text-dark fw-bolder fs-6'>Reservation Status:</div>
              {legends}
            </div>
          ) : null}
          {props.seatsDays && seatsDaysLegends && (
            <div>
              <div className='mb-3 text-dark fw-bolder fs-6'>Booking By Days:</div>
              <SeatMapBoxLegends data={seatsDaysLegends} isCircledDaysBox={isCircledDaysBox} />
            </div>
          )}
        </div>
        <div className='mt-3 text-dark fw-bolder fs-8 text-start'>
          Seats containing coloured circles have been previously booked and distribute.
        </div>
        <div className='pt-2 d-flex flex-row-reverse mb-5'>
          {isPortal ? (
            <div>
              <Button
                className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20'
                onClick={onSubmit}
                style={{marginRight: '10px'}}
                disabled={
                  multipleLocation
                    ? disableSubmit || loading
                    : disableSubmit || loading || remaining !== 0
                }
              >
                <LoadingSpinner loading={loading}>OK</LoadingSpinner>
              </Button>
            </div>
          ) : (
            <Button
              type='button'
              className='px-12'
              disabled={
                multipleLocation
                  ? disableSubmit || loading
                  : disableSubmit || loading || remaining !== 0
              }
              variant='primary'
              onClick={onSubmit}
            >
              <LoadingSpinner loading={loading}>OK</LoadingSpinner>
            </Button>
          )}
        </div>
      </div>
    </ResponsiveModal>
  )
}
