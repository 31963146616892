import type {DetailedHTMLProps, InputHTMLAttributes} from 'react'
import clsx from 'clsx'

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface RadioInputProps extends InputProps {
  label: string
  description?: string
  isInline?: boolean
}

export const RadioInput = ({
  label,
  description,
  checked,
  isInline,
  ...inputProps
}: RadioInputProps) => {
  return (
    <>
      {isInline ? (
        <div className='form-check form-check-solid form-check-inline'>
          <label className='form-check-label text-muted'>
            <input {...inputProps} className='form-check-input' type='radio' />
            {label}
          </label>
        </div>
      ) : (
        <div className={clsx('rs-radio-wrapper border p-6 mb-3', {
          active: checked,
        })}>
          <label
            className={clsx('rs-radio-input-wrapper d-flex align-items-start', {
              active: checked,
            })}
          >
            <input
              {...inputProps}
              className='form-check-input me-2'
              type='radio'
              checked={checked}
            />
            <div>
              <span className='form-check-label fw-bold mb-0 text-white'>{label}</span>
              {description && <p className='mb-0 small rs-radio-input-description'>{description}</p>}
            </div>
          </label>
        </div>
      )}
    </>
  )
}
