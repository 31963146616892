import {useCallback, useMemo} from 'react'
import {Body} from '../../../../../_metronic/layout/foh/Body'
import {PageTitle} from '../../../../../_metronic/layout/foh/PageTitle'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {OrderCard, OrderCardAttributes} from '../../../../components/cards/OrderCard/OrderCard'
import {useLoadingState} from '../../../../components/hooks/useLoadingState'
import {DateUtil} from '../../../../utils/DateUtil'
import {OrderActionSidebar} from '../../components/sidebars/OrderActionSidebar'
import {useCurrentOutletOrders} from '../../hooks/useCurrentOutletOrders'
import {FohWebSocketData, useFohWebSockets} from '../../hooks/useFohWebSockets'
import {useOrderActionSidebarProps} from '../../hooks/useOrderActionSidebarProps'
import {UpdateOrderStatus} from '../../redux/OutletCRUD'

export const FohDashboardPage = () => {
  const {getProps} = useOrderActionSidebarProps()
  const {orders, setOrders, refresh} = useCurrentOutletOrders()
  const {pushError} = useAlerts()
  const {setIsLoading, isKeyLoading} = useLoadingState()

  const handleWebSockets = useCallback(
    (data: FohWebSocketData) => {
      setOrders(data.data)
    },
    [setOrders]
  )

  useFohWebSockets(handleWebSockets)

  const denyOrder = useCallback(
    async (order: OrderCardAttributes) => {
      const doneLoading = setIsLoading(order.orderNo)
      try {
        await UpdateOrderStatus(order.orderNo, {status: 'cancelled'})
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
        refresh()
      }
    },
    [pushError, setIsLoading, refresh]
  )

  const confirmOrder = useCallback(
    async (order: OrderCardAttributes) => {
      const doneLoading = setIsLoading(order.orderNo)
      try {
        await UpdateOrderStatus(order.orderNo, {status: 'confirmed'})
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
        refresh()
      }
    },
    [pushError, setIsLoading, refresh]
  )

  const pendingOrders = useMemo(() => {
    return orders.filter((order) => order.status === 'pending')
  }, [orders])

  const orderGrid = useMemo(() => {
    const cards = pendingOrders.map((order) => {
      const createdAt = DateUtil.getDateFromApiString(order.createdAt)
      const data: OrderCardAttributes = {
        createdAt,
        items:
          order.orderItems?.map((item) => ({
            category: item.product?.fnbProductCategory?.name || '',
            count: item.qty,
            name: item.product?.name || '',
            cancelled: item.status === 'cancelled',
          })) || [],
        orderNo: order.code,
        status: order.status,
      }
      return (
        <div key={order.code} className='col-4'>
          <OrderCard
            loading={isKeyLoading(order.code)}
            onClick={confirmOrder}
            onClose={denyOrder}
            paperVariant='light'
            order={data}
          />
        </div>
      )
    })

    return <div className='row g-5'>{cards}</div>
  }, [confirmOrder, denyOrder, isKeyLoading, pendingOrders])

  return (
    <>
      <Body header={<PageTitle className='text-uppercase'>Orders</PageTitle>}>{orderGrid}</Body>
      <OrderActionSidebar {...getProps()} />
    </>
  )
}
