import {useMemo} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../../core'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {AsideMenuOutletManager} from './AsideMenuOutletManager'

export const AsideOutletManager = () => {
  const {config, classes} = useLayout()
  const {aside} = config

  const asideToggler = useMemo(() => {
    if (aside.minimize) {
      return (
        <div
          id='kt_aside_toggle'
          className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
          data-kt-toggle='true'
          data-kt-toggle-state='active'
          data-kt-toggle-target='body'
          data-kt-toggle-name='aside-minimize'
        >
          <KTSVG
            path={'/media/icons/duotone/Navigation/Angle-double-left.svg'}
            className={'svg-icon-3x rotate-180'}
          />
        </div>
      )
    }
    return null
  }, [aside.minimize])

  const logo = useMemo(() => {
    return (
      <Link to='/dashboard'>
        {/* THEME: LOGO */}
        <img alt='Logo' className='h-20px logo' src={toAbsoluteUrl('/media/saas/admm.png')} />
      </Link>
    )
  }, [])

  const asideMenu = useMemo(() => {
    return (
      <div className='aside-menu flex-column-fluid'>
        <AsideMenuOutletManager asideMenuCSSClasses={classes.asideMenu} />
      </div>
    )
  }, [classes.asideMenu])

  const brandSection = useMemo(() => {
    return (
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {logo}
        {asideToggler}
      </div>
    )
  }, [asideToggler, logo])

  const asideFooter = useMemo(() => {
    return (
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        <Link
          to='/support'
          className='btn btn-custom btn-primary w-100'
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
        >
          <span className='btn-label'>Contact Support</span>
          <span className='btn-icon svg-icon svg-icon-2x'>
            <KTSVG path='/media/icons/duotone/Design/Line.svg' />
          </span>
        </Link>
      </div>
    )
  }, [])

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'310px', '310px': '310px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {brandSection}
      {asideMenu}
      {asideFooter}
    </div>
  )
}
