import {FormikContextType} from 'formik'
import {Button} from 'react-bootstrap'
import {TextInput} from '../../../../components/inputs'
import {useFormikTextInputHelpers} from '../../../../components/inputs/hooks/useFormikTextInputHelper'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {ResponsiveModal} from '../../../../components/modals/ResponsiveModal'
import {LoadingSpinner} from '../../../../components/utils/LoadingSpinner'

export interface AddDelegateValues {
  name: string
  email: string
  designation: string
}
export interface AddDelegateModalProps<T extends AddDelegateValues> {
  onHide: () => void
  onSubmit: () => void
  disableSubmit?: boolean
  loading?: boolean
  open: boolean
  formik: FormikContextType<T>
}

export const AddDelegateModal = <T extends AddDelegateValues>({
  onHide,
  onSubmit,
  disableSubmit,
  loading = false,
  open,
  formik,
}: AddDelegateModalProps<T>) => {
  const textInputHelpers = useFormikTextInputHelpers(formik)

  return (
    <ResponsiveModal noShowAnimation noPadding size='default' onClose={onHide} open={open}>
      <div className='h-100 p-5 d-flex flex-column gap-5'>
        <div className='d-flex'>
          <div className='px-5 pt-5 flex-grow-1'></div>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
          />
        </div>
        <div className='row text-start'>
          <div className='col-12'>
            <TextInput label='Name' {...textInputHelpers.getProps('name')} />
          </div>
          <div className='col-12'>
            <TextInput label='Email' {...textInputHelpers.getProps('email')} />
          </div>
          <div className='col-12'>
            <TextInput label='Designation' {...textInputHelpers.getProps('designation')} />
          </div>
        </div>

        <div className='pt-2 d-flex flex-row-reverse'>
          <Button
            type='button'
            className='px-12'
            disabled={disableSubmit || loading}
            variant='primary'
            onClick={onSubmit}
          >
            <LoadingSpinner loading={loading}>Add Delegate</LoadingSpinner>
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  )
}
