import {TableSelectioncheckbox} from './TableSelectionCheckbox'

export interface TableRowCheckboxProps {
  checked?: boolean
  onSelect: () => void
  disabled?: boolean
}

export const TableRowCheckbox = ({onSelect, checked, disabled}: TableRowCheckboxProps) => {
  if (checked !== undefined) {
    return (
      <td className='table-checkbox-cell'>
        <TableSelectioncheckbox disabled={disabled} checked={checked} onChange={onSelect} />
      </td>
    )
  }
  return null
}
