import {useMemo} from 'react'
import {BookingDetailModel} from '../../../../models/customer-portal/BookingDetailModel'
import {CustomerModel} from '../../../../models/CustomerModel'
import {TicketModel} from '../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {TicketsSeatManifest} from '../../components/SeatTicketManifest/TicketsSeatManifest'
import {
  CustomerProductBookingDetailTable,
  FromProp,
} from '../../components/tables/BookingProductTable/CustomerProductBookingDetailTable'
import {TicketsPage} from '../TicketsPage'
import {VouchersPage} from './VouchersPage'

interface CustomerPortalDetailInnerRoutesProps {
  match: any
  customer?: CustomerModel
  currentTicket?: TicketModel
  bookingCode?: string
  bookingDetails: GlobalSearchModel<BookingDetailModel>
  bookingProductCode?: string
  locationCode?: string
  productCode?: string
  onFilterProductHandler: (filter: FilterModel) => void
  refreshCurrentDetails: () => void
  refreshTable: () => void
  customerCode?: string
  toPath?: FromProp
  cornersClassName?: string
  paginationClassName?: string
}

export const CustomerPortalDetailInnerRoutes = ({
  match,
  customer,
  bookingCode,
  bookingDetails,
  bookingProductCode,
  locationCode,
  productCode,
  onFilterProductHandler,
  refreshCurrentDetails,
  refreshTable,
  customerCode,
  toPath,
  currentTicket,
  cornersClassName,
  paginationClassName,
}: CustomerPortalDetailInnerRoutesProps) => {
  const bookingDetailTable = useMemo(() => {
    return isBookingDetailShow(match) && bookingDetails ? (
      <CustomerProductBookingDetailTable
        toPath={toPath}
        data={bookingDetails}
        onFilter={onFilterProductHandler}
        bookingCode={bookingCode}
        customerCode={customerCode}
        onRefresh={refreshCurrentDetails}
        onRefreshCallback={refreshTable}
        customer={customer}
        cornersClassName={cornersClassName}
        paginationClassName={paginationClassName}
      />
    ) : null
  }, [
    bookingCode,
    bookingDetails,
    customer,
    customerCode,
    match,
    onFilterProductHandler,
    paginationClassName,
    refreshCurrentDetails,
    refreshTable,
    cornersClassName,
    toPath,
  ])

  const ticketTable = useMemo(() => {
    return isTicketTableShow(match) && customer ? (
      <TicketsPage customer={customer} bookingProductCode={bookingProductCode} />
    ) : null
  }, [bookingProductCode, customer, match])

  const voucherTable = useMemo(() => {
    return customer && isVoucherShow(match) ? (
      <VouchersPage customer={customer} bookingProductCode={bookingProductCode} />
    ) : null
  }, [bookingProductCode, customer, match])

  const seats = useMemo(() => {
    return isSeatShow(match) ? (
      <TicketsSeatManifest
        currentTicket={currentTicket}
        locationCode={locationCode}
        productCode={productCode}
      />
    ) : null
  }, [currentTicket, locationCode, match, productCode])

  return (
    <>
      {bookingDetailTable}
      {ticketTable}
      {voucherTable}
      {seats}
    </>
  )
}

export const isTicketShow = (match: any): boolean => {
  return match.path.includes('/:bookingProductCode/ticket')
}

export const isTicketTableShow = (match: any): boolean => {
  return match.path.includes('/:bookingProductCode/ticket') && !match.path.includes('/seat')
}
export const isBookingDetailShow = (match: any): boolean => {
  return match.path.includes('/:bookingCode/booking-detail')
}

export const isVoucherShow = (match: any): boolean => {
  return match.path.includes('/:bookingProductCode/voucher')
}

export const isSeatShow = (match: any): boolean => {
  return match.path.includes('/seat')
}
