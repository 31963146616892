import clsx from 'clsx'
import {forwardRef, ReactNode, Ref} from 'react'
import styles from './Pane.module.scss'

export type PaneDirection = 'horizontal' | 'vertical'

export interface PaneProps {
  className?: string
  children?: ReactNode
  flexBasis?: string
  minimumWidth?: number
  ref?: Ref<HTMLDivElement>
}

export const Pane = forwardRef<HTMLDivElement, PaneProps>(
  ({children, className, flexBasis}, ref) => {
    return (
      <div
        ref={ref}
        style={{
          flexBasis,
        }}
        className={clsx(styles.root, className)}
      >
        {children}
      </div>
    )
  }
)
