import {AsyncCallback, SwalUtils} from '../../../../utils/SwalUtils'
import {Validators} from '../../../../utils/Validators'

export abstract class ReservationSwalUtils extends SwalUtils {
  public static cancelReservation = (callback: AsyncCallback) =>
    SwalUtils.asyncSpecifyReason(callback, {
      title: 'Please tell us why you are cancelling.',
      inputValidator: (value) => (!value ? 'Please enter a reason.' : null),
      cancelButtonText: 'Back',
    })
  public static approveReservation = (callback: AsyncCallback) =>
    SwalUtils.asyncSpecifyReason(callback, {
      title: 'Please enter table number',
      inputValidator: (value) => {
        const isNumber = value && Validators.WHOLE_NUMBER_REGEX.test(value)
        if (!isNumber) {
          return 'Please enter a valid number.'
        }
        if (value.length > 4) {
          return 'Only 4 digits are allowed.'
        }
        return null
      },
      cancelButtonText: 'Back',
    })
}
