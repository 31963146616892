import {ReactNode, useMemo} from 'react'
import {useCustomerDelegatesAuth} from '../../modules/customer-delegate/hooks/useCustomerAuth'
import {Redirect, Route, Switch} from 'react-router-dom'
import {DELEGATE_MENU} from '../../../config/portalDrawer'
import {Layout} from '../../components/layouts/DefaultLayout/Layout'
import {HeaderControls} from '../../components/layouts/DefaultLayout/Header/HeaderControls'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthDelegatesPage} from '../../modules/customer-delegate/pages/AuthDelegatesPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {AuthDelegatesHeaderThumbnail} from '../../modules/customer-delegate/components/AuthDelegatesHeaderThumbnail'
import {RegistrationPage} from '../../modules/customer-delegate/member-registration/pages/RegistrationPage'

export const Routes = () => {
  const auth = useCustomerDelegatesAuth()
  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <Layout menus={DELEGATE_MENU}>
        <HeaderControls>
          <AuthDelegatesHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </Layout>
    )
  }, [auth])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthDelegatesPage />
        </Route>
      )
    }
    return <Redirect from='/auth' to='/' />
  }, [auth])

  return (
    <Switch>
      <Route path='/logout' component={Logout} />
      <Route path='/auth/register' component={RegistrationPage} />
      <Route path='/auth/login/' component={AuthDelegatesPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://webntech.ae' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
  )
}
