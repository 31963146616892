import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {ButtonList} from '../ButtonList'

export interface LocationListSelectProps {
  locations: SelectInputItem[]
  onClick: (value: string) => void
}

export const LocationListSelect = ({locations, onClick}: LocationListSelectProps) => {
  return <ButtonList items={locations} onClick={onClick} />
}
