import React, {forwardRef} from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'

type SpanProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>

export interface KTSVGProps extends SpanProps {
  className?: string
  path: string
  svgClassName?: string
  svgRef?: React.Ref<SVGElement>
}

export const KTSVG = forwardRef<HTMLSpanElement, KTSVGProps>(
  ({className = '', path, svgClassName, svgRef, ...spanProps}, ref) => {
    return (
      <span ref={ref} className={`svg-icon ${className}`} {...spanProps}>
        <SVG innerRef={svgRef} src={toAbsoluteUrl(path)} className={svgClassName} />
      </span>
    )
  }
)
