import {ReactNode, useMemo} from 'react'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {ProductInputItemValue} from '../../../../components/inputs/ProductInput/ProductInputItem'
import {ResponsiveModal} from '../../../../components/modals/ResponsiveModal'
import {TableColumnOptions} from '../../../../components/tables/TableColumn'
import {TableHeader} from '../../../../components/tables/TableHeader'
import {TableRow} from '../../../../components/tables/TableRow'

export interface InvoiceModalProps {
  data: ProductInputItemValue[]
  onHide: () => void
  open: boolean
  children?: ReactNode
  totalPriceBeforeVat: number
  vatPrice: number
  totalPrice: number
}

export interface HasId {
  id: string | number
}

export const InvoiceModal = ({
  data,
  totalPriceBeforeVat,
  children,
  onHide,
  open,
  vatPrice,
  totalPrice,
}: InvoiceModalProps) => {
  const columns = useMemo(() => {
    const columns: TableColumnOptions<ProductInputItemValue>[] = [
      {
        field: 'name',
        label: 'Description',
        sortable: true,
        hideable: true,
        className: 'text-start',
      },
      {
        field: 'count',
        label: 'Qty',
        sortable: true,
        hideable: true,
        className: 'text-center',
      },
      {
        field: 'price',
        label: 'Unit Price',
        sortable: true,
        hideable: true,
        className: 'text-center',
      },
      {
        field: 'netAmount',
        label: 'Net Amount',
        sortable: true,
        hideable: true,
        render: (data) => NetPriceColumn(data),
        className: 'text-center',
      },
    ]

    return columns
  }, [])
  const title = 'Invoice'

  const tableHeaders = useMemo(() => {
    const headers = columns.reduce<ReactNode[]>((acc, column) => {
      acc.push(
        <TableHeader
          key={column.field}
          label={column.label}
          align='center'
          className={column.className}
        />
      )

      return acc
    }, [])
    return <>{headers}</>
  }, [columns])

  const emptyRow = useMemo(() => {
    let colSpan = columns.length

    return (
      <tr>
        <td colSpan={colSpan} className='py-10'>
          No records found
        </td>
      </tr>
    )
  }, [columns.length])

  const tableBody = useMemo(() => {
    if (data?.length) {
      return data.map((data, index) => {
        return <TableRow key={index} hideExpansion id={index} columns={columns} data={data} />
      })
    } else return emptyRow
  }, [columns, data, emptyRow])

  const generatePdf = () => {
    //pass invoice data
    //call download invoice api
  }

  return (
    <ResponsiveModal noShowAnimation noPadding size='auto' onClose={onHide} open={open}>
      <div className='h-100 p-5 d-flex flex-column '>
        <div className='d-flex text-start mb-5'>
          <h2 className='pt-2 flex-grow-1'>{title}</h2>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
          />
        </div>
        <div id='pdf' className='card card-flush'>
          <div className='card-body pt-0'>
            <div className='row g-5'>
              <div className='col'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder text-muted'>{tableHeaders}</tr>
                    </thead>
                    <tbody>
                      {tableBody}
                      <tr>
                        <td className='flex-grow-1 text-start'>Total price before Vat</td>
                        <td></td>
                        <td></td>
                        <td className='text-center custom-table-row'>{totalPriceBeforeVat}</td>
                      </tr>
                      <tr>
                        <td className='flex-grow-1 text-start'>VAT @ 5%</td>
                        <td></td>
                        <td></td>
                        <td className='text-center custom-table-row'>{vatPrice}</td>
                      </tr>
                      <tr>
                        <td className='flex-grow-1 text-start'>TOTAL</td>
                        <td></td>
                        <td></td>
                        <td className='text-center custom-table-row'>{totalPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between'>
          <Button
            uppercase={false}
            type='button'
            variant='link'
            className='mb-5 text-start px-0'
            onClick={() => generatePdf()}
          >
            Download Invoice <MetronicIcon iconType='Files' iconName='Download' />
          </Button>
          <Button
            uppercase={false}
            type='button'
            variant='link'
            className='mb-5 text-end px-0'
            onClick={() => {}}
          >
            Resend Invoice
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  )
}

export const NetPriceColumn = ({data}: {data: ProductInputItemValue}) => {
  const getPrice = useMemo(() => {
    if (data.price) {
      const totalProductPrice = data.count * data.price
      return totalProductPrice
    }
  }, [data.count, data.price])

  return <span>{getPrice}</span>
}
