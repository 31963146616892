import {useMemo} from 'react'
import {EventModel} from '../../../../models/ems/EventModel'
import {PieChartDatum} from '../../../../components/charts/PieChart/PieChartSegment'
import {FilterType} from '../../../../components/tables/constants/FilterType'
import {usePortalColorScalePreset} from '../../hooks/usePortalColorScalePreset'
import {RingChart} from './RingChart'
import {TicketCountUsedRemainingModel} from '../../../../models/customer-portal/WidgetModel'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {TicketCountTooltip} from './TicketCountUsedRemaining/TicketCountTooltip'

export interface DashboardPercentageChartProps {
  data?: TicketCountUsedRemainingModel
  event?: EventModel | null
  label?: string
}

export const DashboardPercentageChart = ({data, event, label}: DashboardPercentageChartProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  const pieChartDatum = useMemo((): PieChartDatum[] => {
    const items: PieChartDatum[] = FilterType.ADMM_TICKET_STATUS.map((item) => ({
      key: item.value,
      label: item.label,
      value: 0,
      countVal: 0,
    }))

    if (data) {
      Object.keys(data).forEach((item) => {
        const index = items.findIndex((_item) => _item.key === item)
        if (item === 'usedPercentage' && index >= 0) {
          items[index].value = data[`usedPercentage`]
          items[index].countVal = data['used']
        } else if (item === 'remainingPercentage' && index >= 0) {
          items[index].value = data[`remainingPercentage`]
          items[index].countVal = data['remaining']
        }
      })
    }
    return items
  }, [data])

  const domain = useMemo(() => pieChartDatum.map((datum) => datum.key), [pieChartDatum])

  const getColor = usePortalColorScalePreset({domain})

  return (
    <div
      // className='col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6'
      style={{
        height: isMobile ? '470px' : '400px',
        minHeight: isMobile ? '470px' : '400px',
      }}
    >
      <div className='bg-primary h-100 d-flex flex-column justify-content-center'>
        <div
          className='diagonal-cut-corner-polygon rs-gradient px-10 py-10'
          style={{
            height: isMobile ? '96%' : '90%',
            width: isMobile ? '95%' : '100%',
          }}
        >
          {label && <div className='ff-f1-bold text-white fs-4 mb-6'>{label}</div>}

          <RingChart
            datum={pieChartDatum}
            tooltip={<TicketCountTooltip data={data} event={event} />}
            getColor={getColor}
          />
        </div>
      </div>
    </div>
  )
}
