import {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {useQueryParam} from '../../../components/hooks/useQueryParam'
import {GetCustomerByTicketCode} from '../redux/EvaCRUD'
import {useDispatch} from 'react-redux'
import {actions} from '../redux/EvaRedux'
import {usePushNotification} from '../hooks/usePushNotification'
export const HomePage = () => {
  const {value: ticketCode} = useQueryParam('q')
  const {pushError} = useAlerts()
  const history = useHistory()
  const dispatch = useDispatch()
  const {subscribeToPushNotifications} = usePushNotification()

  const validateTicket = useCallback(async () => {
    if (ticketCode) {
      try {
        const data = await GetCustomerByTicketCode(ticketCode)
        if (data) {
          dispatch(actions.saveUser(data.data, ticketCode))
          subscribeToPushNotifications(data.data)
        }
        history.replace(`/agendas?q=${ticketCode}`)
      } catch (e) {
        pushError(e)
        history.replace('/403')
      }
    } else {
      history.replace('/agendas')
    }
  }, [dispatch, history, pushError, subscribeToPushNotifications, ticketCode])

  useOnMount(() => {
    validateTicket()
  })

  return null
}
