import {Redirect, Route, Switch} from 'react-router-dom'
import {AuthBackground} from '../../../../../config/logos'
import {useOutletAuth} from '../../../../components/hooks/useOutletAuth'
import {Logo} from '../../../../components/utils/Logo'
import {Paper} from '../../../../components/utils/Paper'
import {ForgotPassword} from '../../components/forms/ForgotPassword'
import {OutletLogin} from '../../components/OutletLogin'
import {OutletUserPasswordAndPinResetForm} from '../../components/PasswordAndPinResetForm'

export function OutletAuthPage() {
  const auth = useOutletAuth()

  if (auth?.isFirstLogIn() === false) {
    return <Redirect to='/' />
  }

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${AuthBackground.src})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        <Logo />
        <Paper className='bg-body opacity-75 rounded shadow-sm mx-auto'>
          <Switch>
            <Route path='/auth/login'>
              <OutletLogin
                classes={{
                  usernameFormClass: 'w-400px p-10 p-lg-15 ',
                  pinFormClass: 'p-10',
                  userNameListFormClass: 'w-400px p-1',
                }}
              />
            </Route>
            <Route path='/auth/password-reset'>
              <OutletUserPasswordAndPinResetForm className='p-10' />
            </Route>
            <Route path='/auth/forgot-password'>
              <div className='p-10'>
                <ForgotPassword />
              </div>
            </Route>
            <Redirect from='/auth' exact to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </Paper>
      </div>
    </div>
  )
}
