import {useCallback} from 'react'
import {
  CircuitMapDatumKey,
  F1CircuitHeatMapChart,
} from '../../../../../../components/charts/YasMarinaCircuitChart/F1CircuitHeatMapChart'
import {GateCounterWidgetModel} from '../../../../../../models/ems/WidgetModel'
import {mapLabelOptions, YasMarinaCircuitChartLabel} from './YasMarinaCircuitChartTooltip'

export interface YasMarinaCircuitChartProps {
  data?: GateCounterWidgetModel[]
  total?: number | undefined
}

export const YasMarinaCircuitChart = ({data, total}: YasMarinaCircuitChartProps) => {
  const renderLabel = useCallback(
    (key: CircuitMapDatumKey) => {
      const code = KEY_MAPPING[key]
      if (code) {
        const datum = data?.find((item) => item.code === code)
        if (datum) {
          return <YasMarinaCircuitChartLabel datum={datum} labelKey={key} />
        }
      }
      return null
    },
    [data]
  )

  return (
    <div className='h-100 w-100 position-relative'>
      {data && (
        <div className='position-absolute bg-light-primary fw-bold top-0 start-0 fst-italic text-white border border border-primary px-3 rounded m-5 d-flex align-items-center'>
          <div style={{fontSize: '3rem'}}>{total}</div>
          <div className='ps-3' style={{lineHeight: '1.1em'}}>
            <div>Total Visitors Today</div>
            <div>YMC &amp; W Hotel</div>
          </div>
        </div>
      )}
      <F1CircuitHeatMapChart labelOptions={mapLabelOptions} renderLabel={renderLabel} />
    </div>
  )
}

const KEY_MAPPING: Partial<Record<CircuitMapDatumKey, string>> = {
  abu_dhabi_hill_oasis: 'ADO',
  main_oasis: 'MNO',
  south_oasis: 'STO',
  west_oasis: 'WTO',
  abu_dhabi_hill_grandstand: 'ADGS',
  abu_dhabi_hill_deck: 'ADD',
  abu_dhabi_hill_terrace: 'ADHS',
  main_grandstand: 'MNG',
  marina_grandstand: 'MRGS',
  marina_oasis: 'MRO',
  north_grandstand: 'NRG',
  north_straight_hospitality: 'NRST',
  north_straight_temp: 'NRTP',
  south_grandstand: 'STG',
  west_grandstand: 'WTG',
  the_turns_at_west: 'WTH',
  the_deck_at_nine: 'TDN',
  west_straight_temp: 'WST',
  hq: 'HQB',
  yas_central: 'YCB',
  luna_lounge: 'LNL',
  yas_yacht: 'YMR',
  w_hotel: 'WHT',
  main_pit_building: 'PLW',
  north_oasis: 'NRO',
}
