import clsx from 'clsx'
import {ReactNode} from 'react'

export interface DetailHeaderContentProps {
  title?: ReactNode
  description?: ReactNode
  left?: ReactNode
  right?: ReactNode
  className?: string
}

export const DetailHeaderContent = ({
  description,
  left,
  right,
  title,
  className,
}: DetailHeaderContentProps) => {
  return (
    <div className={clsx('mb-2 w-100', className)}>
      <div className='container-fluid p-0'>
        <div className='row'>
          <div className='col-6'>
            <div className='d-flex flex-column'>
              {title && (
                <div className='d-flex align-items-center'>
                  <p className='text-gray-800 fs-2 fw-bolder mb-1'>{title}</p>
                </div>
              )}
              {description && (
                <div className='d-flex'>
                  <p className='text-muted fs-6'>{description}</p>
                </div>
              )}
            </div>
            {left}
          </div>
          <div className='col-6'>
            <div className='w-100 justify-content-end d-flex'>{right}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
