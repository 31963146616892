import {ReactNode} from 'react'
import {NavigationSectionMenuItem} from '../../../../config/drawer'
import {Content} from '../../../../_metronic/layout/components/Content'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {ScrollTop} from '../../../../_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {MasterInit} from '../../../../_metronic/layout/MasterInit'
import {Main} from '../../../../_metronic/partials'
import {ADMMMenuHeaderLogo} from '../../../../config/logos'
import {Helmet} from 'react-helmet'
import {WavierHeader} from './Header/WavierHeader'
import {LayoutWaiverProvider} from './LayoutWaiverProvider'
export interface DigitalWaiverLayoutProps {
  children?: ReactNode
  menus: NavigationSectionMenuItem[]
  controls?: ReactNode
}

export const DigitalWaiverLayout = ({children, menus}: DigitalWaiverLayoutProps) => {
  return (
    <PageDataProvider>
      <LayoutWaiverProvider>
        <Helmet>
          <title>Digital Waiver</title>
          <link rel='icon' href='/waiver.ico' />
        </Helmet>
        <div className='d-flex flex-column flex-root digital-waiver-container'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <WavierHeader logo={ADMMMenuHeaderLogo} type='waiver' menus={menus} />
              <div
                id='kt_content'
                className='content d-flex flex-column flex-column-fluid mt-5 mt-sm-5 mt-md-0'
              >
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
              <Footer copywrite='&copy; WEB•N•TECH 2023' />
            </div>
          </div>
        </div>
        <Main />
        <MasterInit />
        <ScrollTop />
      </LayoutWaiverProvider>
    </PageDataProvider>
  )
}
