import {useCallback} from 'react'
import {useColorScale} from './useColorScale'

export interface UseColorScalePresetProps {
  domain: (string | number)[]
  colors: string[]
}

export const useColorScalePreset = ({domain, colors}: UseColorScalePresetProps) => {
  const {colorArray} = useColorScale(domain.length, colors)

  const getColor = useCallback(
    (key: string | number) => {
      const index = domain.indexOf(key)
      if (index >= 0) {
        return colorArray[index]
      }
      return '#000000'
    },
    [colorArray, domain]
  )

  return getColor
}
