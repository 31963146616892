import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {
  PasswordPinResetForm,
  PasswordPinResetFormValues,
} from '../../../components/forms/PasswordPinResetForm'
import {ResetAuthCredentials} from '../redux/OutletCRUD'
import {actions} from '../redux/OutletRedux'

export interface OutletUserPasswordAndPinResetFormProps {
  className?: string
}

export const OutletUserPasswordAndPinResetForm = ({
  className,
}: OutletUserPasswordAndPinResetFormProps) => {
  const {pushError} = useAlerts()
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async (values: PasswordPinResetFormValues) => {
      try {
        const {data} = await ResetAuthCredentials({password: values.newPassword, pin: values.pin})
        dispatch(actions.auth.login(data.token))
      } catch (e) {
        pushError(e)
      }
    },
    [dispatch, pushError]
  )

  return <PasswordPinResetForm className={className} onSubmit={handleSubmit} />
}
