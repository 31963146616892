import {Badge} from '../../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../../components/inputs/Button'
import {TicketModelStatus} from '../../../../../../models/ems/TicketModel'

export interface TicketDisplayStatusColumnProps {
  uppercase?: boolean
  data: {displayStatus: TicketModelStatus}
}

export const TicketDisplayStatusColumn = ({data, uppercase}: TicketDisplayStatusColumnProps) => {
  return (
    <Badge variant={getBadgeVariant(data.displayStatus)} uppercase={uppercase}>
      {data.displayStatus}
    </Badge>
  )
}

const getBadgeVariant = (displayStatus: TicketModelStatus): ColorVariant => {
  switch (displayStatus) {
    case 'confirmed':
      return 'success'
    case 'cancelled':
      return 'danger'
    case 'active':
      return 'primary'
    case 'sent':
      return 'secondary'
    case 'printed':
      return 'info'
    case 'shared':
      return 'warning'
    case 'converted':
      return 'info'
    case 'downloaded':
      return 'success'
    case 'emailed':
      return 'warning'
    case 'closed':
    default:
      return 'dark'
  }
}
