import clsx from 'clsx'
import styles from './ChartPaper.module.scss'

type BaseProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface ChartPaperProps extends BaseProps {}

export const ChartPaper = ({children, className, ...otherProps}: ChartPaperProps) => {
  return (
    <div
      className={clsx(styles.root, 'border border-primary border-1 rounded', className)}
      {...otherProps}
    >
      {children}
    </div>
  )
}
