import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {IAuthState} from '../../modules/default/auth'
import {Auth} from '../../utils/RBAC/Role'

export const useAuthRole = () => {
  const auth = useSelector((state: {auth?: IAuthState}) => {
    return state.auth?.user
  })

  const role = useMemo(() => {
    if (auth) {
      return new Auth(auth)
    }
    return null
  }, [auth])

  return role
}
