import {FormikContextType} from 'formik'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Button} from 'react-bootstrap'
import {DatePickerInput, SelectInput, TextInput} from '../../../../../components/inputs'
import {AvatarImageInput} from '../../../../../components/inputs/AvatarImageInput/AvatarImageInput'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useFormikDatePickerInputHelpers} from '../../../../../components/inputs/hooks/useFormikDatePickerInputHelpers'
import {useFormikImageInputHelpers} from '../../../../../components/inputs/hooks/useFormikImageInputHelper'
import {useFormikStringInputHelpers} from '../../../../../components/inputs/hooks/useFormikStringInputHelpers'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {PasswordInput} from '../../../../../components/inputs/PasswordInput'
import {SearchableSelectInput} from '../../../../../components/inputs/SearchableSelect'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {HorizontalDivider} from '../../../../../components/utils/HorizontalDivider'
import {ISOCountryModel} from '../../../../../models/ISOCountryModel'
import {GetRegistrationCoutries} from '../redux/CustomerRegistrationCRUD'

export interface RegistrationCustomerInformationValues {
  name: string
  passportPhoto: ImageInputValue | null
  email: string
  designation: string
  customerOrganization: string
  mobile: string
  gender: string
  nationality: ISOCountryModel | null
  residence: ISOCountryModel | null
  dob: Date | null
  newPassword: string
  confirmPassword: string
}

export interface RegistrationCustomerInformationProps<
  T extends RegistrationCustomerInformationValues
> {
  formik: FormikContextType<T>
}

export const RegistrationCustomerInfoMin = <T extends RegistrationCustomerInformationValues>({
  formik,
}: RegistrationCustomerInformationProps<T>) => {
  const textInputHelpers = useFormikTextInputHelpers(formik)
  const imageInputHelpers = useFormikImageInputHelpers(formik)
  const stringInputHelpers = useFormikStringInputHelpers(formik)
  const dateInputHelpers = useFormikDatePickerInputHelpers(formik)
  const [countryList, setCountryList] = useState<ISOCountryModel[]>([])

  const resetCountryList = useCallback(async () => {
    const {data} = await GetRegistrationCoutries()
    setCountryList(data)
  }, [])

  const avatarInputRef = useRef<HTMLInputElement>(null)

  const handleResetPhotoClick = useCallback(() => {
    formik.setFieldValue('passportPhoto', null)
  }, [formik])

  const handleAvatarInputChange = useCallback(
    (image: ImageInputValue | null) => {
      formik.setFieldValue('passportPhoto', image)
    },
    [formik]
  )

  const handleUploadPhotoClick = useCallback(() => {
    avatarInputRef.current?.click()
  }, [])

  const handleNationalityChange = useCallback(
    (key: string) => (value: ISOCountryModel | null) => {
      formik.setFieldValue(key, value)
    },
    [formik]
  )

  const handleNationalityBlur = useCallback(
    (key: string) => () => {
      formik.setFieldTouched(key)
    },
    [formik]
  )

  useEffect(() => {
    resetCountryList()
  }, [resetCountryList])

  return (
    <div className='row'>
      <div className='col-12'>
        <TextInput label='Name' {...textInputHelpers.getProps('name')} />
      </div>
      <div className='col-12'>
        <TextInput label='Email' {...textInputHelpers.getProps('email')} />
      </div>
      <div className='col-12 col-md-6'>
        <TextInput label='Designation' {...textInputHelpers.getProps('designation')} />
      </div>
      <div className='col-12 col-md-6'>
        <TextInput label='Organization' {...textInputHelpers.getProps('customerOrganization')} />
      </div>

      <div className='col-12'>
        <AvatarImageInput
          inputRef={avatarInputRef}
          height={500}
          width={500}
          className='mb-3'
          label='Upload personal photo'
          {...imageInputHelpers.getProps('passportPhoto')}
          onChange={handleAvatarInputChange}
        />
        <div className='d-flex justify-content-end'>
          <Button type='button' className='me-1' variant='danger' onClick={handleResetPhotoClick}>
            Clear
          </Button>
          <Button type='button' variant='primary' onClick={handleUploadPhotoClick}>
            Upload
          </Button>
        </div>
      </div>
      <div className='col-12'>
        <MobileNumberInput
          label='Mobile number'
          helperMessage='Ex: +971551234567'
          {...stringInputHelpers.getProps('mobile')}
        />
      </div>
      <div className='col-12'>
        <SelectInput
          placeholder='Select Gender'
          label='Gender'
          items={GENDER_SELECT_ITEMS}
          {...formik.getFieldProps('gender')}
        />
      </div>
      <div className='col-12'>
        <SearchableSelectInput
          id='nationality'
          items={countryList}
          itemMapper={countryMapper}
          label='Nationality'
          placeholder='Select Nationality'
          value={formik.values.nationality}
          onChange={handleNationalityChange('nationality')}
          onBlur={handleNationalityBlur('nationality')}
        />
      </div>
      <div className='col-12'>
        <SearchableSelectInput
          id='residence'
          items={countryList}
          itemMapper={countryMapper}
          label='Country of Residence'
          placeholder='Select Country of Residence'
          value={formik.values.residence}
          onChange={handleNationalityChange('residence')}
          onBlur={handleNationalityBlur('residence')}
        />
      </div>
      <div className='col-12'>
        <DatePickerInput
          label='Date of birth'
          maxDate={TODAY}
          {...dateInputHelpers.getProps('dob')}
        />
      </div>
      <HorizontalDivider className='mb-5' />
      <div className='col-12'>
        <PasswordInput
          isTouched={formik.touched.newPassword as boolean}
          errorMessage={formik.errors.newPassword as string}
          fullWidth
          label='New Password'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
        />
      </div>
      <div className='col-12'>
        <PasswordInput
          isTouched={formik.touched.confirmPassword as boolean}
          errorMessage={formik.errors.confirmPassword as string}
          fullWidth
          label='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
        />
      </div>
    </div>
  )
}

export const STEP_CUSTOMER_KEYS: Array<keyof RegistrationCustomerInformationValues> = [
  'designation',
  'email',
  'name',
  'passportPhoto',
  'customerOrganization',
  'dob',
]

const TODAY = new Date()

const GENDER_SELECT_ITEMS: SelectInputItem[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
]

const countryMapper = (item: ISOCountryModel) => ({label: item.name, value: item.code})

//put all fields for validation
export const STEP_DETAILS_KEYS: Array<keyof RegistrationCustomerInformationValues> = ['email']
