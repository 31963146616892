import {createContext, useCallback, useContext, useMemo} from 'react'
import {match, Route, RouteProps, useRouteMatch} from 'react-router-dom'

export interface RouteWithDataProps extends RouteProps {
  data?: any
}

export const RouteWithData = ({data, ...routeParams}: RouteWithDataProps) => {
  const parent = useContext(RouteWithDataContext)
  const match = useRouteMatch()

  const getRecursively = useCallback(
    <R,>(getter: (data: any) => R | undefined): R | undefined => {
      if (data) {
        const value = getter(data)
        if (value === undefined && parent) {
          return parent.getRecursively(getter)
        }
        return value
      }
    },
    [data, parent]
  )

  const value = useMemo(
    (): RouteWithDataContextValue<unknown> => ({
      parent,
      data,
      match,
      getRecursively,
    }),
    [data, getRecursively, match, parent]
  )

  return (
    <RouteWithDataContext.Provider value={value}>
      <Route {...routeParams} />
    </RouteWithDataContext.Provider>
  )
}

interface RouteWithDataContextValue<T = unknown, RouteMatch = {}> {
  parent: RouteWithDataContextValue<T> | null
  data: T
  match: match<RouteMatch>
  getRecursively: <R>(getter: (data: T) => R | undefined) => R | undefined
}

const RouteWithDataContext = createContext<RouteWithDataContextValue<unknown> | null>(null)

export const useRouteData = <T,>() => {
  const context = useContext(RouteWithDataContext)

  return context as RouteWithDataContextValue<T> | null
}
