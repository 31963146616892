export interface SeatMapBoxLegendsProps {
  text: string
  color: string
  width?: number
  height?: number
}

interface DataProps {
  data: SeatMapBoxLegendsProps[]
  isCircledDaysBox?: boolean
}

export const SeatMapBoxLegends = ({data, isCircledDaysBox}: DataProps) => {
  return (
    <div className='d-flex flex-column gap-3'>
      {data &&
        data.length &&
        data.map((item, idx) => {
          return item ? (
            <div key={item.text || idx} className='d-flex gap-3'>
              <div
                style={{
                  width: item.width || '20px',
                  height: item.height || '20px',
                  backgroundColor: item.color,
                  borderRadius: isCircledDaysBox ? '50%' : '0%',
                }}
              ></div>
              <p className='mb-0'>{item.text.charAt(0).toUpperCase() + item.text.slice(1)}</p>
            </div>
          ) : null
        })}
    </div>
  )
}
