import clsx from 'clsx'
import {ReactNode} from 'react'
import {ColorVariant} from '../inputs/Button'

export interface BadgeProps {
  uppercase?: boolean
  variant: ColorVariant
  children?: ReactNode
  className?: string
}

export const Badge = ({children, variant, uppercase, className}: BadgeProps) => {
  return (
    <span
      className={clsx({'text-uppercase': uppercase}, 'badge', `badge-light-${variant}`, className)}
    >
      {children}
    </span>
  )
}
