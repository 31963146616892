import {ReactNode, useMemo} from 'react'
import {createPortal} from 'react-dom'

export interface RootPortalProps {
  children?: ReactNode
}

export const RootPortal = ({children}: RootPortalProps) => {
  const container = useMemo(() => {
    return document.getElementById('root')
  }, [])

  if (!container) {
    return null
  }

  return createPortal(children, container)
}
