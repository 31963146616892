import {useFormik} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {UpdateRegistration} from '../redux/CustomerRegistrationCRUD'
import * as yup from 'yup'
import {
  RegistrationCustomerInfoMin,
  RegistrationCustomerInformationValues,
  STEP_DETAILS_KEYS,
} from './RegistrationCustomerInfoMin'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {Button} from '../../../../../components/inputs/Button'
import {DateUtil} from '../../../../../utils/DateUtil'
import {ISOCountryModel} from '../../../../../models/ISOCountryModel'
import {MemberCompanyForm, MemberCompanyValues, STEP_COMPANY_KEYS} from './MemberCompanyForm'
import {pick} from 'lodash'

enum FormStep {
  COMPANY = 'COMPANY',
  DETAILS = 'DETAILS',
}

const StepOrder = [FormStep.COMPANY, FormStep.DETAILS]

export interface NewRegistrationWizardFormValues
  extends MemberCompanyValues,
    RegistrationCustomerInformationValues {}

export interface RegistrationFormValues {
  name: string
  passportPhoto: ImageInputValue | null
  email: string
  designation: string
  customerOrganization: string
  mobile: string
  gender: string
  nationality: ISOCountryModel | null
  residence: ISOCountryModel | null
  dob: Date | null
  newPassword: string
  confirmPassword: string
}

export const RegistrationMinimal = () => {
  const {pushError} = useAlerts()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [currentStep, setCurrentStep] = useState(FormStep.COMPANY)
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      try {
        const payload = getPayload(values)
        await UpdateRegistration(payload) // update to send paymend gateway api
        setHasSubmitted(true)
      } catch (e) {
        pushError(e)
      }
    },
    validationSchema,
    validateOnMount: true,
  })

  const stepForm = useMemo(() => {
    switch (currentStep) {
      case FormStep.COMPANY: {
        return <MemberCompanyForm formik={formik} />
      }
      case FormStep.DETAILS: {
        return <RegistrationCustomerInfoMin formik={formik} />
      }
    }
  }, [currentStep, formik])

  const handleNextClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) + 1]
    })
  }, [])

  const handlePreviousClick = useCallback(() => {
    setCurrentStep((currentStep) => {
      return StepOrder[StepOrder.indexOf(currentStep) - 1]
    })
  }, [])

  const stepHasErrors = useCallback(
    (step: FormStep): boolean => {
      let errors = formik.errors
      switch (step) {
        case FormStep.COMPANY: {
          errors = pick(formik.errors, STEP_COMPANY_KEYS)
          break
        }
        case FormStep.DETAILS: {
          errors = pick(formik.errors, STEP_DETAILS_KEYS)
          break
        }
      }
      return Object.values(errors).some((value) => Boolean(value))
    },
    [formik.errors]
  )

  const companyFormHasValues = useMemo(() => {
    const values = pick(formik.values, STEP_COMPANY_KEYS)
    return Object.values(values).some((value) => Boolean(value))
  }, [formik.values])

  const actions = useMemo(() => {
    const currentStepIndex = StepOrder.indexOf(currentStep)
    const hasPrevious = currentStepIndex > 0
    const hasNext = currentStepIndex < StepOrder.length - 1
    return (
      <>
        <div className='row mb-3'>
          <div className='col col-xs-12 col-md'></div>
          {hasNext && (
            <div className='col col-xs-12'>
              <Button
                className='w-100'
                variant='primary'
                type='button'
                disabled={stepHasErrors(currentStep)}
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          )}
        </div>
        <div className='row g-3'>
          {hasPrevious && (
            <div className='col-xs-12 col-md-6'>
              <Button className='w-100' variant='info' type='button' onClick={handlePreviousClick}>
                Previous
              </Button>
            </div>
          )}
          {!hasNext && (
            <div className='col-xs-12 col-md-6'>
              <Button
                className='w-100'
                variant='primary'
                type='submit'
                disabled={!companyFormHasValues || !formik.isValid || formik.isSubmitting}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </>
    )
  }, [
    currentStep,
    stepHasErrors,
    handleNextClick,
    handlePreviousClick,
    companyFormHasValues,
    formik.isValid,
    formik.isSubmitting,
  ])

  const formTitle = useMemo(() => {
    if (!hasSubmitted) {
      let message: string = ''
      switch (currentStep) {
        case FormStep.COMPANY: {
          message = 'GPCA Member Registration'
          break
        }
        case FormStep.DETAILS: {
          message = 'Registration Form'
          break
        }
      }
      return <h1 className='text-dark mb-3'>{message}</h1>
    }
    return (
      <>
        <p className='text-gray-400 fw-bold fs-4'>Thank you for completing your registration</p>
        <p className='text-gray-400 fw-bold fs-4'>You will receive a confirmation email shortly</p>
      </>
    )
  }, [currentStep, hasSubmitted])

  const debouncedValidate = useDebounce(500)

  useOnChange(formik.values, () => {
    debouncedValidate(() => {
      formik.validateForm()
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>{formTitle}</div>
      {!hasSubmitted && (
        <>
          {stepForm}
          {actions}
        </>
      )}
    </form>
  )
}

// const getFormData = (
//   staticUrl: string,
//   newPassword: string,
//   confirmPassword: string,
//   customer: Partial<CustomerModel>
// ): RegistrationFormValues => {
//   const customerValues: RegistrationFormValues = {
//     name: customer.name || INITIAL_VALUES.name,
//     designation: customer.designation || INITIAL_VALUES.designation,
//     customerOrganization: customer.customerOrganization || INITIAL_VALUES.customerOrganization,
//     email: customer.email || INITIAL_VALUES.email,
//     passportPhoto: customer.photo
//       ? new ImageInputValue(staticUrl, customer.photo)
//       : INITIAL_VALUES.passportPhoto,
//     mobile: customer.mobile || INITIAL_VALUES.mobile,
//     gender: customer.gender || INITIAL_VALUES.gender,
//     nationality: customer.nationality
//       ? {code: customer.nationality, name: customer.nationality}
//       : INITIAL_VALUES.nationality,
//     residence: customer.residence
//       ? {code: customer.residence, name: customer.residence}
//       : INITIAL_VALUES.residence,
//     dob: customer.dob ? DateUtil.getDateFromApiString(customer.dob) : INITIAL_VALUES.dob,
//     newPassword: newPassword || INITIAL_VALUES.newPassword,
//     confirmPassword: confirmPassword || INITIAL_VALUES.confirmPassword,
//   }

//   return customerValues
// }

const getPayload = (customer: RegistrationFormValues) => {
  const formData = new FormData()
  const photo = customer.passportPhoto?.toApiValue()

  formData.append('name', customer.name)
  formData.append('email', customer.email)
  formData.append('designation', customer.designation)
  formData.append('customerOrganization', customer.customerOrganization)
  formData.append('mobile', customer.mobile)
  formData.append('gender', customer.gender)
  formData.append('nationality', customer.nationality?.code || '')
  formData.append('residence', customer.residence?.code || '')
  customer.dob && formData.append('dob', DateUtil.convertDateToApiString(customer.dob))
  formData.append('password', customer.confirmPassword)
  photo && formData.append('documents', photo, 'photo.png')
  photo && formData.append('photo', photo, 'photo.png')

  return formData
}

const INITIAL_VALUES: NewRegistrationWizardFormValues = {
  email: '',
  name: '',
  designation: '',
  customerOrganization: '',
  passportPhoto: null,
  mobile: '',
  gender: '',
  nationality: null,
  residence: null,
  dob: null,
  confirmPassword: '',
  newPassword: '',
  companyName: null,
}

const validationSchema = yup.object().shape({
  companyName: yup
    .object()
    .typeError('Please enter your company.')
    .required('Please enter your company.'),

  email: yup.string().email('Please enter a valid email'),
  name: yup.string(),
  customerOrganization: yup.string(),
  passportPhoto: yup
    .object()
    .required('Please submit your photo')
    .typeError('Please submit your photo'),
  newPassword: yup
    .string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .test('is-password-match', 'Password does not match', (value, context) => {
      const formValues = context.parent as PasswordResetFormValues
      if (formValues.newPassword) {
        return formValues.newPassword === value
      }
      return false
    }),
})

export interface PasswordResetFormValues {
  newPassword: string
  confirmPassword: string
}
