import {useMemo} from 'react'
import {ProductGridItem, ProductGridItemAttributes} from './ProductGridItem'

export interface ProductGridProps {
  products: ProductGridItemAttributes[]
  onClick: (product: ProductGridItemAttributes) => void
}

export const ProductGrid = ({products, onClick}: ProductGridProps) => {
  const images = useMemo(() => {
    return products.map((product) => {
      return (
        <div key={product.value} className='col-xs-12 col-sm-4 col-xl-3'>
          <div className='p-3 border bg-light h-100'>
            <ProductGridItem onClick={onClick} {...product} />
          </div>
        </div>
      )
    })
  }, [onClick, products])

  return <div className='row g-3'>{images}</div>
}
