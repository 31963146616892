import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {ProductVoucherModel} from '../BookingWizardTables/ProductVoucherTable'
import {Button} from '../../inputs/Button'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {SweetAlert} from '../../modals/SweetAlert'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {ProductVoucherModalType} from '../../../models/booking-wizard/BookingWizard'
import clsx from 'clsx'

interface EditProductVoucherModalProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onEdit: (type: ProductVoucherModalType, value: ProductVoucherModel) => void
  data: ProductVoucherModel
  isPortal?: boolean
  isShowRemaining?: boolean
}

export const EditProductVoucherModal = ({
  modalType,
  onModalClose,
  onEdit,
  data,
  isPortal,
}: EditProductVoucherModalProps) => {
  const [value, setValue] = useState<ProductVoucherModel>(data)
  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let count = e.target.value
      if (Number(count) === 0) {
        count = '1'
      }
      const newValue: ProductVoucherModel = {...data, qty: Number(count)}
      if (isPortal) {
        setValue(newValue)
      } else {
        setValue(newValue)
      }
    },
    [data, isPortal]
  )

  const maxRemaing = useMemo(() => {
    return data.remainQty + data.qty
  }, [data])

  const handleEdit = useCallback(() => {
    if (modalType === 'product' && value) onEdit(modalType, value)
    if (modalType === 'voucher' && value) onEdit(modalType, value)
  }, [modalType, onEdit, value])

  const updatedData = useMemo(() => {
    return {...data, qty: value.qty}
  }, [data, value])

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      {isPortal && <div className='swal2-corners'></div>}
      <div className={clsx('h-100 d-flex flex-column', isPortal && 'position-relative p-10')}>
        <div className='d-flex flex-column flex-grow-1 text-start'>
          <div className='position-absolute top-0 end-0'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
          <h2 className='text-start mb-5'>{`Edit ${data.name || ''}`}</h2>
          <label className='text-start mb-5'>Quantity</label>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            value={updatedData.qty}
            min={1}
          />
          {isPortal && (
            <>
              {maxRemaing - Number(value.qty) > 0 ? (
                <div className='d-flex flex-row justify-content-end fs-7'>
                  {`Remaining Quantity: ${maxRemaing - Number(value.qty)}`}
                  {/* {`Remaining Quantity: ${Number(updatedData.remainQty) - updatedData.qty || 0}`} */}
                </div>
              ) : (
                <div className='d-flex flex-row justify-content-end fs-7 text-danger'>
                  {`Remaining Quantity: ${maxRemaing - Number(value.qty)}`}
                </div>
              )}
            </>
          )}
          {/* {isPortal && maxRemaing - Number(value.qty) > 0 ? (
            <div className='d-flex flex-row justify-content-end fs-7'>
              {`Remaining Quantity: ${maxRemaing - Number(value.qty)}`}
            </div>
          ) : (
            <div className='d-flex flex-row justify-content-end fs-7'>
              <span>{`Remaining Quantity: 0`}</span>
            </div>
          )} */}
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          {isPortal ? (
            <Button
              className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-100'
              disabled={Math.sign(maxRemaing - Number(value.qty)) === -1 ? true : false}
              onClick={handleEdit}
            >
              <LoadingSpinner loading={false}>Save</LoadingSpinner>
            </Button>
          ) : (
            <>
              <Button variant='primary' onClick={handleEdit}>
                <LoadingSpinner loading={false}>Save</LoadingSpinner>
              </Button>
              <Button className='me-1' onClick={onModalClose}>
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </SweetAlert>
  )
}
