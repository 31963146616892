import {useMemo} from 'react'
import {CustomerModel} from '../../../models/CustomerModel'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {DetailHeaderContent} from '../../../components/DetailHeader/DetailHeaderContent'
import {DataButton} from '../../../components/inputs/DataButton'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {ReservationPortalModel} from '../../../models/ems/ReservationMedel'
import {ReservationIsReleased} from './tables/ReservationTable/ReservationTableSelectionActions'
import {ReservationStatusColumn} from './tables/ReservationTable/ReservationStatusColumn'
import {useReservationData} from '../hooks/useReservationData'

export interface ReservationDetailsHeaderProps {
  customer?: CustomerModel | null
  reservation?: ReservationPortalModel | null
  onRefresh?: () => void
  onRefreshCallback?: () => void
}

export const ReservationDetailsHeader = ({
  customer,
  onRefresh,
  onRefreshCallback,
  reservation,
}: ReservationDetailsHeaderProps) => {
  const {staticUrls} = useAppConfig()
  const {releaseSingle, isReservationLoading} = useReservationData({onRefresh, onRefreshCallback})
  const avatar = useMemo(() => {
    if (customer?.photo) {
      const imageValue = new ImageInputValue(staticUrls.public, customer.photo)
      return imageValue.url
    }
  }, [customer?.photo, staticUrls.public])

  return (
    <>
      <DetailHeaderContent
        left={
          <div className='d-flex flex-row'>
            {avatar && (
              <div className='d-flex drag-drop-file-input-drop-area-thumbnail'>
                <img src={avatar} alt='' className=' h-100' />
              </div>
            )}

            <div className='d-flex flex-column px-2'>
              <div className='d-flex align-items-center'>
                <p className='text-gray-800 fs-2 fw-bolder mb-1'>{customer?.name}</p>
              </div>
              <div className='d-flex flex-wrap'>
                <div className='d-flex flex-column me-3 mt-3'>
                  {customer?.mobile && customer.mobile.length > 0 && (
                    <div className='d-flex flex-wrap'>
                      <span className='d-flex align-items-center'>
                        <MetronicIcon iconType='Devices' iconName='Phone' size='svg-icon-4' />
                        {customer.mobile}
                      </span>
                    </div>
                  )}
                  {customer?.email && customer.email.length > 0 && (
                    <div className='d-flex flex-wrap'>
                      <span className='d-flex align-items-center text-muted'>
                        <MetronicIcon iconType='Communication' iconName='Mail' size='svg-icon-4' />
                        {customer.email}
                      </span>
                    </div>
                  )}
                </div>
                <div className='d-flex flex-column mt-3'>
                  {reservation && (
                    <>
                      <div className='d-flex flex-wrap'>
                        <span className='me-1 fw-bolder'>Reservation:</span>
                        <span className='me-1'>{reservation.code}</span>
                      </div>
                      <div className='d-flex flex-wrap'>
                        <span className='me-1 fw-bolder'>Status:</span>
                        <ReservationStatusColumn data={reservation} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        right={
          <div className='d-flex my-4 flex-wrap'>
            {reservation && (
              <>
                {ReservationIsReleased(reservation) && (
                  <DataButton
                    disabled={isReservationLoading(reservation)}
                    onClick={releaseSingle}
                    data={reservation}
                    className='min-w-125px'
                    variant='text'
                    color='danger'
                  >
                    <MetronicIcon iconType='Code' iconName='Stop' />
                    Release
                  </DataButton>
                )}
              </>
            )}
          </div>
        }
      />
    </>
  )
}
