import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {
  APP_CONFIG_SITE_SETTINGS_CODE,
  APP_CONFIG_SITE_URL_CODE,
  LAYOUT_TYPE,
  ORGANIZATION_CODE,
  STATIC_PRIVATE_FILE_URL,
  STATIC_PUBLIC_FILE_URL,
} from '../../../../config/env'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {actions} from '../redux/AppConfigRedux'

export const useAppConfig = () => {
  const {config} = useRootStateSelector((state) => state.config)
  const organizationCode = useRootStateSelector((state) => {
    switch (LAYOUT_TYPE) {
      case 'default': {
        return state.auth?.user?.organization?.code
      }
      case 'customer-delegate':
      case 'customer-portal':
        return state.customerPortal.auth?.user?.organization?.code
      case 'poken':
        return state.poken.auth?.user?.organization?.code
      case 'eva': {
        return ORGANIZATION_CODE
      }
      case 'outlet': {
        return state.customerPortal.auth?.user?.organization?.code
      }
    }
  })

  const dispatch = useDispatch()

  const refreshAppConfig = useCallback(() => {
    dispatch(actions.getAppConfig())
  }, [dispatch])

  const staticUrls = useMemo(() => {
    const urlConfig = config?.find((item) => item.code === APP_CONFIG_SITE_URL_CODE)
    let fallbackPrivateUrl = STATIC_PRIVATE_FILE_URL
    let fallbackPublicUrl = STATIC_PUBLIC_FILE_URL
    if (organizationCode) {
      if (fallbackPrivateUrl) {
        fallbackPrivateUrl = `${fallbackPrivateUrl}/${organizationCode}`
      }
      if (fallbackPublicUrl) {
        fallbackPublicUrl = `${fallbackPublicUrl}/${organizationCode}`
      }
    }
    const privateUrl = urlConfig?.data?.STATIC_PRIVATE_FILE_URL || fallbackPrivateUrl
    const publicUrl = urlConfig?.data?.STATIC_PUBLIC_FILE_URL || fallbackPublicUrl

    if (!privateUrl) {
      throw new Error('STATIC_PRIVATE_URL is not defined')
    }

    if (!publicUrl) {
      throw new Error('STATIC_PUBLIC_URL is not defined')
    }

    return {
      private: privateUrl,
      public: publicUrl,
    }
  }, [config, organizationCode])

  const isSiteRegistrationEnabled = useMemo(() => {
    const siteSettings = config?.find((item) => item.code === APP_CONFIG_SITE_SETTINGS_CODE)
    return siteSettings?.data.ENABLE_REGISTRATION || false
  }, [config])

  return {
    staticUrls,
    isSiteRegistrationEnabled,
    refreshAppConfig,
    organizationCode,
  }
}
