import {useMemo} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {ProductCategoryModel} from '../../../models/ems/ProductCategoryModel'

interface useIsGSSSPPProps {
  productCategoryCode?: string
}
export const useIsGSSSPP = ({productCategoryCode}: useIsGSSSPPProps) => {
  const menuesRDX: ProductCategoryModel[] = useSelector<RootState>(
    (state) => state.customerPortalTabs.topTabs,
    shallowEqual
  ) as ProductCategoryModel[]

  const isGCCSPP = useMemo(() => {
    if (menuesRDX) {
      const found = menuesRDX.find(
        (item) =>
          productCategoryCode &&
          productCategoryCode === item.code &&
          item.slug &&
          [
            'GC',
            'GCC',
            'SPP',
            'GC & SPP',
            'GCC & SPP',
            'Super Park Pass',
            'super park pass',
            'SUPER PARK PASS',
            'Golden Circle',
            'Golden circle',
            'golden circle',
            'Golden Circle Concert',
            'GOLDEN CIRCLE',
            'GOLDEN CIRCLE CONCERT',
          ].includes(item.slug)
      )
      if (found) return true
      else return false
    }
    return false
  }, [menuesRDX, productCategoryCode])

  const isSPP = useMemo(() => {
    if (menuesRDX) {
      const found = menuesRDX.find(
        (item) =>
          productCategoryCode &&
          productCategoryCode === item.code &&
          item.slug &&
          ['SPP', 'Super Park Pass', 'super park pass', 'SUPER PARK PASS'].includes(item.slug)
      )
      if (found) return true
      else return false
    }
    return false
  }, [menuesRDX, productCategoryCode])

  return useMemo(
    () => ({
      isGCCSPP,
      isSPP,
    }),
    [isGCCSPP, isSPP]
  )
}
