import {FormikContextType} from 'formik'
import {useBookingFormData} from '../hook/useBookingFormData'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../setup'
import {
  BookingFormValues,
  BookingWizardCustomerStepFormValues,
} from '../../../../../../models/booking-wizard/BookingWizard'
import {BookingWizardSharedCustomerStep} from '../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedCustomerStep'

export interface BookingWizardCustomerStepProps<T extends BookingWizardCustomerStepFormValues> {
  formik: FormikContextType<T>
  event?: EventModel | null
  isEdit?: boolean
  disabledFields?: Partial<Record<keyof BookingWizardCustomerStepFormValues, boolean>>
  onNoCustonmers?: (is: boolean) => void
  onStepChange?: (step: number) => void
}

export const BookingWizardCustomerStep = <T extends BookingWizardCustomerStepFormValues>({
  formik,
  event,
  isEdit,
  onNoCustonmers,
  onStepChange,
}: BookingWizardCustomerStepProps<T>) => {
  const bookingForm: BookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingForm
  ) as BookingFormValues

  const {getCustomers, customers} = useBookingFormData({})

  return (
    <>
      <BookingWizardSharedCustomerStep
        formik={formik}
        bookingForm={bookingForm}
        searchCustomers={getCustomers}
        customers={customers}
        onNoCustonmers={onNoCustonmers}
        onStepChange={onStepChange}
        isEdit={isEdit}
      />
    </>
  )
}
