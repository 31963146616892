import {useCallback} from 'react'
import {VAT} from '../../../../../config/env'
import {OrderInput} from '../../../outlet/components/inputs/OrderInput/OrderInput'
import {PaymentSummary} from '../../../outlet/components/PaymentSummary'
import {ConfirmPageLayout} from '../../layout/ConfirmPageLayout'
import {useCart} from '../../hooks/useCart'
import {useParams} from 'react-router-dom'
import {OutletModel} from '../../../../models/fnb/OutletModel'
import {SwalUtils} from '../../../../utils/SwalUtils'
import {FormikContextType} from 'formik'
import type {CheckoutFormValues} from '.'

export interface BasketContentsProps {
  onConfirm: () => void
  onBack: () => void
  formik: FormikContextType<CheckoutFormValues>
  outlet?: OutletModel
}

interface PathParams {
  outletCode: string
}

export const BasketContents = ({onBack, onConfirm, formik, outlet}: BasketContentsProps) => {
  const pathParams = useParams<PathParams>()
  const {cart, setCart} = useCart(pathParams.outletCode)

  const handleOrderNotes = useCallback(async () => {
    const result = await SwalUtils.askOrderNotes({
      customClass: {
        cancelButton: 'btn btn-success rounded-0 m-1',
        confirmButton: 'btn border border-success rounded-0 m-1',
      },
      inputValue: formik.values.notes,
    })
    if (result.isConfirmed) {
      formik.setFieldValue('notes', result.value)
    }
  }, [formik])

  return (
    <ConfirmPageLayout
      onBackClick={onBack}
      onButtonClick={onConfirm}
      disableButton={cart.getItemCount() === 0}
      buttonLabel='Delivery Information'
      headerLabel='Basket'
    >
      <h2 className='fw-bolder text-uppercase fs-1'>Order Summary</h2>
      <p className='text-muted'>{outlet?.name}</p>
      <OrderInput variant='success' onChange={setCart} value={cart} />
      <PaymentSummary className='mb-6' cart={cart} vatPercent={VAT} />
      <button className='btn text-success p-0' onClick={handleOrderNotes}>
        Order Notes
      </button>
    </ConfirmPageLayout>
  )
}
