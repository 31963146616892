import {useCallback, useEffect, useMemo, useState} from 'react'
import {GetPoken} from '../redux/PokenCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {Button} from '../../../components/inputs/Button'
import {useHistory} from 'react-router-dom'
import {PokenModel} from '../../../models/acs/PokenModel'

export const PokensPage = () => {
  const [poken, setPoken] = useState<PokenModel[]>()
  const history = useHistory()
  const {pushError} = useAlerts()

  const getThePoken = useCallback(async () => {
    try {
      const {data} = await GetPoken({filters: {}})
      setPoken(data?.data)
    } catch (err) {
      pushError(err)
    }
  }, [pushError])

  useEffect(() => {
    getThePoken()
  }, [getThePoken])

  const pokenItems = useMemo(() => {
    if (poken && poken.length > 0) {
      return poken.map((item) => {
        return (
          <Button fullWidth onClick={() => history.push(`/pokens/${item.code}`)}>
            {item.name}
          </Button>
        )
      })
    }
    return null
  }, [history, poken])

  return (
    <>
      <Toolbar title='Poken'></Toolbar>
      {pokenItems && (
        <div className='mt-5 px-5 py-2 d-flex gap-5 mw-500px m-auto'>
          <div className='card w-100 p-5'>{pokenItems}</div>
        </div>
      )}
    </>
  )
}
