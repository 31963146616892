import {RowAction, RowActionProps} from './RowAction'

export interface ScanQRRowActionProps<T>
  extends Omit<RowActionProps<T>, 'iconType' | 'iconName' | 'variant' | 'tooltip'> {}

export const ScanQRRowAction = <T,>(props: ScanQRRowActionProps<T>) => {
  return (
    <RowAction
      iconType='Shopping'
      iconName='Barcode-read'
      variant='dark'
      tooltip='QR Code'
      {...props}
    />
  )
}
