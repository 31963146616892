import {ReactNode, useCallback, useMemo} from 'react'
import {Drawer} from '../Drawer/Drawer'
import {useDrawerRef} from '../Drawer/useDrawerRef'
import {DrawerFormContainer} from '../Drawer/DrawerFormContainer'
import {CheckboxInput} from '../inputs'
import {BaseTableColumnOptions} from './TableColumn'

export interface TableHiddenColumnDrawerMenuProps<T> {
  columns: BaseTableColumnOptions<T>[]
  onChange: (column: string) => void
  hidden: string[]
  title?: string
  drawerRef?: (drawer: Drawer | null) => void
}

export const TableHiddenColumnDrawerMenu = <T,>({
  columns,
  title = 'Table Options',
  onChange,
  hidden,
  drawerRef,
}: TableHiddenColumnDrawerMenuProps<T>) => {
  const [drawer, setDrawer] = useDrawerRef()

  const handleDrawerRef = useCallback(
    (drawer: Drawer | null) => {
      drawerRef && drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  const getChangeHandler = useCallback(
    (column: string) => () => {
      onChange(column)
    },
    [onChange]
  )

  const isColumnChecked = useCallback(
    (column: string) => {
      return !hidden.includes(column)
    },
    [hidden]
  )

  const columnCheckboxes = useMemo(() => {
    const checkboxes: ReactNode[] = []

    columns.forEach((column) => {
      if (column && column.hideable) {
        checkboxes.push(
          <CheckboxInput
            noMargin
            className='mb-3'
            key={column.field}
            id={column.field}
            label={column.label}
            checked={isColumnChecked(column.field)}
            onChange={getChangeHandler(column.field)}
          />
        )
      }
    })

    return checkboxes
  }, [columns, getChangeHandler, isColumnChecked])

  return (
    <Drawer ref={handleDrawerRef}>
      <DrawerFormContainer
        title={title}
        submitButtonLabel='Close'
        onCloseButtonClick={drawer?.hide}
        onSubmitButtonClick={drawer?.hide}
        submitButtonType='button'
      >
        <h5 key='label' className='my-4 text-uppercase text-primary'>
          Column Visibility
        </h5>
        <div className='table-drawer-options-checkbox-container'>{columnCheckboxes}</div>
      </DrawerFormContainer>
    </Drawer>
  )
}
