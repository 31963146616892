/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLayout} from '../core'

interface FooterProps {
  copywrite?: string
}
const Footer = ({copywrite}: FooterProps) => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark'>
          {!copywrite && (
            <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          )}
          <a className='text-gray-800' href='https://webntech.ae' target='_blank' rel='noreferrer'>
            {copywrite ? copywrite : 'WEB·N·TECH | WNT Solutions LLC'}
          </a>
        </div>
        <div className='text-dark'>
          <span className='text-muted me-2'>BUILD {process.env.REACT_APP_BUILD}</span>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
