import {useCallback, useState} from 'react'

export interface TableActionModalData<Type extends string, Data> {
  modalType?: Type
  data?: Data | Data[]
}

export interface TableActionModalProps {
  isOpen: boolean
  onClose: () => void
}

export const useTableActionModal = <Type extends string, Data>() => {
  const [modalData, setModalData] = useState<TableActionModalData<Type, Data>>({})

  const clearModalData = useCallback(() => {
    setModalData({})
  }, [])

  const getModalProps = useCallback(
    (modalType: Type): TableActionModalProps => {
      return {
        isOpen: modalData.modalType === modalType,
        onClose: clearModalData,
      }
    },
    [clearModalData, modalData.modalType]
  )

  return {modalData, setModalData, clearModalData, getModalProps}
}
