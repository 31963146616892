import {createContext, useContext} from 'react'
import {UseFilterStateData} from '../hooks/useFilterState'

export const TableFilterStateContext = createContext<UseFilterStateData | null>(null)

export const useTableFilterState = () => {
  const state = useContext(TableFilterStateContext)

  if (!state) {
    throw new Error('Table Filter State Provider is not defined.')
  }

  return state
}
