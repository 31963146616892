import {ReactNode, useCallback, useMemo, useRef} from 'react'
import colors from 'chroma-js'
import styles from './BarChart.module.scss'
import clsx from 'clsx'
import {Group} from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {Bar} from '@visx/shape'
import {scaleBand, scaleLinear} from '@visx/scale'
import {GridRows} from '@visx/grid'
import {AxisBottom, AxisLeft} from '@visx/axis'
import {defaultStyles, useTooltip, useTooltipInPortal} from '@visx/tooltip'

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'initial',
  boxShadow: 'none',
}

export interface BarChartProps<T extends object> {
  data: T[]
  xAccessor: (data: T) => string
  yAccessor: (data: T) => number
  renderTooltip?: (key: string) => ReactNode
  yLabel?: string
  xLabel?: string
  isHideXLabel?: boolean
}

export const BarChart = <T extends object>({
  data,
  xAccessor,
  yAccessor,
  renderTooltip,
  xLabel,
  yLabel,
  isHideXLabel = false,
}: BarChartProps<T>) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const gradientColorMiddle = useMemo(() => {
    const primaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--bs-primary')
      .trim()
    return primaryColor
  }, [])

  const gradientColorEnd = useMemo(() => {
    return colors(gradientColorMiddle).darken(2).hex()
  }, [gradientColorMiddle])

  const gradientColorStart = useMemo(() => {
    return colors(gradientColorMiddle).brighten(2).hex()
  }, [gradientColorMiddle])

  const keys = useMemo(() => {
    return data.map((datum) => xAccessor(datum))
  }, [data, xAccessor])

  const handleBarSegmentMouseMove = useCallback(
    (datum: T) => {
      showTooltip({
        tooltipData: String(xAccessor(datum)),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip, xAccessor]
  )

  const tooltip = useMemo(() => {
    if (tooltipData && renderTooltip) {
      return renderTooltip(tooltipData)
    }
  }, [renderTooltip, tooltipData])

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  return (
    <div
      className={clsx(styles.root, 'pb-20')}
      onMouseMove={handleContainerMouseMove}
      onMouseOut={hideTooltip}
      ref={containerRef}
    >
      <ParentSize>
        {({width, height}) => {
          const xMax = width
          const yMax = height
          const xScale = scaleBand<string>({
            range: [0, xMax],
            round: true,
            domain: keys,
            padding: 0.4,
          })
          const yScale = scaleLinear<number>({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...data.map((d) => yAccessor(d)))],
          })
          return (
            <svg className='overflow-visible' width={width} height={height}>
              <linearGradient id='bar-chart-gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                <stop offset='0%' stopColor={gradientColorStart} />
                <stop offset='50%' stopColor={gradientColorMiddle} />
                <stop offset='100%' stopColor={gradientColorEnd} />
              </linearGradient>
              <GridRows stroke='#1d2729' scale={yScale} width={xMax} height={yMax} />
              {yLabel && (
                <text
                  x={xMax / 2}
                  y={yMax + 35}
                  fill='white'
                  fontSize={12}
                  fontWeight='bold'
                  textAnchor='middle'
                >
                  {yLabel}
                </text>
              )}
              {xLabel && (
                <text x={-5} y={-10} fill='white' fontSize={12} fontWeight='bold' textAnchor='end'>
                  {xLabel}
                </text>
              )}
              <AxisLeft
                scale={yScale}
                stroke='white'
                tickFormat={(value) => {
                  return Number(value) % 1 === 0 ? String(value) : ''
                }}
                tickStroke='transparent'
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 11,
                  textAnchor: 'end',
                  width: width * 0.2,
                })}
              />
              <AxisBottom
                top={yMax}
                scale={xScale}
                stroke='white'
                tickFormat={(value) => {
                  return !isHideXLabel ? (value ? value : '') : ''
                }}
                tickStroke='white'
                numTicks={keys.length}
                tickLabelProps={(label) => {
                  return {
                    fill: 'white',
                    fontSize: 11,
                    // textAnchor: 'middle',
                    lineHeight: '1.3em',
                    verticalAnchor: 'start',
                    // width: width / keys.length,
                    angle: 90,
                  }
                }}
              />
              <Group>
                {data.map((d) => {
                  const barWidth = xScale.bandwidth()
                  const constrainedWidth = Math.min(barWidth, 80)
                  const barHeight = yMax - (yScale(yAccessor(d)) ?? 0)
                  const barX = (xScale(xAccessor(d)) ?? 0) + barWidth / 2 - constrainedWidth / 2
                  const barY = yMax - barHeight
                  return (
                    <Bar
                      onMouseMove={() => handleBarSegmentMouseMove(d)}
                      key={xAccessor(d)}
                      x={barX}
                      y={barY}
                      width={constrainedWidth}
                      height={barHeight}
                    />
                  )
                })}
              </Group>
            </svg>
          )
        }}
      </ParentSize>
      {/* <XYChart
        theme={theme}
        xScale={{type: 'band', paddingInner: 0.5, paddingOuter: 0.1, align: 0}}
        yScale={{type: 'linear'}}
      >
        <linearGradient id='bar-chart-gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor={gradientColorStart} />
          <stop offset='50%' stopColor={gradientColorMiddle} />
          <stop offset='100%' stopColor={gradientColorEnd} />
        </linearGradient>
        <Grid rows columns={false} lineStyle={{opacity: 0.5}} />
        <BarSeries data={data} dataKey='0' xAccessor={xAccessor} yAccessor={yAccessor} />
        <Axis orientation='bottom' numTicks={4} label='Date' />
        <Axis
          orientation='left'
          label='Count'
          stroke='transparent'
          tickStroke='transparent'
          tickFormat={(value) => {
            return Number(value) % 1 === 0 ? value : ''
          }}
        />
        {renderTooltip && <Tooltip renderTooltip={renderTooltip} />}
      </XYChart> */}
      {tooltipOpen && tooltip && (
        <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={tooltipStyles}>
          {tooltip}
        </TooltipInPortal>
      )}
    </div>
  )
}
