import {useCallback, useState} from 'react'

export interface UseTableModalActionsData<T, ModalType = string> {
  modalType?: ModalType
  data?: T
}

export interface UseTableModalActionsDataReturn<T, ModalType = string> {
  modalData: UseTableModalActionsData<T, ModalType>
  setModalData: (data: UseTableModalActionsData<T, ModalType>) => void
  clearModalData: () => void
  getModalProps: (modalType: ModalType) => TableActionModalProps
}

export const useTableModalActions = <T, ModalType = string>(): UseTableModalActionsDataReturn<
  T,
  ModalType
> => {
  const [modalData, setModalData] = useState<UseTableModalActionsData<T, ModalType>>({})

  const clearModalData = useCallback(() => {
    setModalData({})
  }, [])

  const getModalProps = useCallback(
    (modalType: ModalType): TableActionModalProps => {
      return {
        isOpen: modalData.modalType === modalType,
        onClose: clearModalData,
      }
    },
    [clearModalData, modalData.modalType]
  )

  return {
    modalData,
    getModalProps,
    clearModalData,
    setModalData,
  }
}

export interface TableActionModalProps {
  isOpen: boolean
  onClose: () => void
}
