import clsx from 'clsx'
import {useCallback, useMemo, useState} from 'react'
import {ApiTree} from '../../../utils/Tree/ApiTree'
import {MetronicIconButton} from '../MetronicIconButton'
import {TreeNodeItem, TreeSelect} from '../TreeSelect/TreeSelect'
import {SeatMapEditorInput, SeatMapEditorInputProps} from './SeatMapEditorInput'
import {SeatMapValue} from './SeatMapValue'

export interface SeatMapSelectionInputProps extends SeatMapEditorInputProps {
  locationItems?: TreeNodeItem[]
  onLocationChange?: (value: string) => void
  locationCode?: string
  isCustom?: boolean
  isCircledDaysBox?: boolean
  isShowNoneFilledDays?: boolean
}

export const SeatMapSelectionInput = ({
  className,
  locationItems,
  onLocationChange,
  locationCode,
  onSelectedChange,
  disabled,
  occupied,
  isCustom = false,
  sharedDisabled,
  seatsDays,
  isCircledDaysBox,
  isShowNoneFilledDays,
  ...props
}: SeatMapSelectionInputProps) => {
  const [locationMenuShown, setLocationMenuShown] = useState(true)
  const handleLocationChange = useCallback(
    (values: string[]) => {
      const value = values[0] || ''
      onLocationChange && onLocationChange(value)
    },
    [onLocationChange]
  )

  const handleOnSelect = useCallback(
    (value: SeatMapValue) => {
      let finalValue = value
      if (onSelectedChange) {
        if (disabled) {
          finalValue = finalValue.difference(disabled)
        }
        if (occupied) {
          finalValue = finalValue.difference(occupied)
        }

        if (sharedDisabled) {
          finalValue = finalValue.difference(sharedDisabled)
        }
        onSelectedChange(finalValue)
      }
    },
    [disabled, occupied, onSelectedChange, sharedDisabled]
  )

  const value = useMemo(() => {
    if (locationCode) {
      return [locationCode]
    }
    return []
  }, [locationCode])

  const toggleShow = useCallback(() => {
    setLocationMenuShown(!locationMenuShown)
  }, [locationMenuShown])

  const selectedLocationName = useMemo(() => {
    if (locationCode && locationItems) {
      const tree = ApiTree.fromTreeSelectItems(locationItems)
      const found = tree.findByCode(locationCode)
      if (found) {
        return found.name
      }
    }
    return 'Select a location'
  }, [locationCode, locationItems])

  const actions = useMemo(() => {
    if (locationItems) {
      return (
        <MetronicIconButton
          tooltip='Toggle location menu'
          variant='primary'
          iconType='Map'
          iconName='Marker1'
          size='sm'
          onClick={toggleShow}
        />
      )
    }
  }, [locationItems, toggleShow])

  return (
    <div className={clsx('seat-map-selection-input', className)}>
      <SeatMapEditorInput
        className='h-100'
        actions={actions}
        onSelectedChange={onSelectedChange && handleOnSelect}
        disabled={disabled}
        sharedDisabled={sharedDisabled}
        occupied={occupied}
        seatsDays={seatsDays}
        isCircledDaysBox={isCircledDaysBox}
        isShowNoneFilledDays={isShowNoneFilledDays}
        disableRowClick
        {...props}
        isCustom={isCustom}
      />
      <div className='seat-map-selection-controls'>
        {locationMenuShown && locationItems && (
          <TreeSelect
            label={selectedLocationName}
            className='max-w-500px'
            values={value}
            radioName='locationCode'
            items={locationItems}
            onChange={handleLocationChange}
          />
        )}
      </div>
    </div>
  )
}
