import clsx from 'clsx'
import {ReactNode} from 'react'
import {Paper} from '../../../app/components/utils/Paper'

export interface PageTitleProps {
  children?: ReactNode
  className?: string
}

export const PageTitle = ({children, className}: PageTitleProps) => {
  return (
    <Paper className={clsx('foh-header page-title', className)} variant='light'>
      <h1 className='title-text'>{children}</h1>
    </Paper>
  )
}
