import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {BlobUtils} from '../../../utils/BlobUtils'
import {GetOrderBill} from '../redux/DigitalMenuCRUD'

export const PrintBillPage = () => {
  const {orderCode} = useParams<{orderCode: string}>()

  useEffect(() => {
    GetOrderBill(orderCode)
      .then(({data}) => {
        BlobUtils.downloadBlob(data, `${Date.now()}.pdf`)
        window.close()
      })
      .catch(() => {
        window.close()
      })
  }, [orderCode])

  return null
}
