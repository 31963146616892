import clsx from 'clsx'
import {Button} from '../../../../../components/inputs/Button'

type FormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export interface PopupFormContainerProps extends FormProps {
  isSubmitDisabled?: boolean
  isLoading?: boolean
  submitButtonLabel?: string
  cancleButtonLabel?: string
  errorMessage?: string
  isCloseButton?: boolean
  customerValidationMessage?: string | null
  onSubmitButtonClick?: () => void
  onCancelButtonClick?: () => void
  submitButtonType?: 'button' | 'submit' | 'reset'
}

export const PopupFormContainer = ({
  className,
  children,
  isLoading,
  isSubmitDisabled,
  errorMessage,
  submitButtonLabel,
  cancleButtonLabel,
  submitButtonType = 'submit',
  onSubmitButtonClick,
  onCancelButtonClick,
  customerValidationMessage,
  ...formProps
}: PopupFormContainerProps) => {
  return (
    <form
      className={clsx('form-drawer-container drawer-container', className)}
      noValidate
      {...formProps}
    >
      <div className='p-0'>
        {errorMessage && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        {children}
      </div>

      <div className='p-0 text-center mb-20'>
        <Button
          type={submitButtonType}
          className='bg-primary mt-5 text-white fw-bolder'
          disabled={isSubmitDisabled || Boolean(customerValidationMessage)}
          style={{
            borderRadius: '40px',
          }}
        >
          {!isLoading && <span className='indicator-label'>{submitButtonLabel || 'Save'}</span>}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>

        {onCancelButtonClick && (
          <Button
            type='button'
            variant='text'
            className='w-300px mt-5 text-secondary'
            onClick={onCancelButtonClick}
            style={{
              borderRadius: '40px',
            }}
          >
            <span className='indicator-label'>{cancleButtonLabel || 'Cancel'}</span>
          </Button>
        )}
      </div>
    </form>
  )
}
