import {ReactNode} from 'react'
import clsx from 'clsx'

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface DrawerFooterProps extends DivProps {
  children: ReactNode
}

export const DrawerFooter = ({children, className, ...otherProps}: DrawerFooterProps) => {
  return (
    <div className={clsx('drawer-footer', className)} {...otherProps}>
      {children}
    </div>
  )
}
