import {ChangeEvent, useCallback, useMemo} from 'react'
import {VoucherCard} from './VoucherCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {VoucherGroupModel} from '../../../../models/customer-portal/VoucherModel'
import {useVoucherTableActions} from '../../hooks/useVoucherTableActions'
import {PortalSearchModel} from '../../../../models/customer-portal/PortalSearchModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../../models/svc/VoucherModel'

export interface VoucherCardGridProps {
  data?: PortalSearchModel<VoucherGroupModel> | GlobalSearchModel<VoucherModel>
  onFilter: (filter: FilterModel) => void
  className?: string
  initialFilters?: FilterModel
  onRefresh?: () => void
  onRefreshCallback?: () => void
}

export const VoucherCardGrid = ({
  data,
  onFilter,
  className,
  initialFilters,
  onRefresh,
  onRefreshCallback,
}: VoucherCardGridProps) => {
  const {setSearch, search, pageNumber, pageSize, setPageNumber, setPageSize} = useFilterState(
    onFilter,
    {
      initialFilters,
    }
  )
  const {handleDisableSingle, isKeyLoading} = useVoucherTableActions({onRefresh, onRefreshCallback})

  const cards = useMemo(() => {
    if (data?.data) {
      return (
        <div className='row'>
          {Array.isArray(data.data)
            ? data.data &&
              data.data.length > 0 &&
              data.data.map((voucher) => {
                return (
                  <div key={voucher.code} className='mb-3 col-xs-12 col-sm-6'>
                    <VoucherCard
                      onDisable={handleDisableSingle}
                      className='h-100'
                      data={voucher}
                      loading={isKeyLoading(voucher.code)}
                    />
                  </div>
                )
              })
            : Object.entries(data.data).map(([, vouchers]) => {
                return (
                  vouchers &&
                  vouchers.length > 0 &&
                  vouchers.map((voucher) => {
                    return (
                      <div key={voucher.code} className='mb-3 col-xs-12 col-sm-6'>
                        <VoucherCard
                          onDisable={handleDisableSingle}
                          className='h-100'
                          data={voucher}
                          loading={isKeyLoading(voucher.code)}
                        />
                      </div>
                    )
                  })
                )
              })}
        </div>
      )
    }
    return null
  }, [data, handleDisableSingle, isKeyLoading])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  return (
    <div className={className}>
      <TextInput
        className='mb-3'
        noMargin
        placeholder='Search'
        value={search}
        onChange={handleSearchChange}
      />
      <div>{cards}</div>
      <PaginationHelper
        currentPageNumber={pageNumber}
        currentPageSize={pageSize}
        onChangePageNumber={setPageNumber}
        onChangePageSize={setPageSize}
        total={data?.total}
      />
    </div>
  )
}
