import {defaultStyles, useTooltip, useTooltipInPortal} from '@visx/tooltip'
import {useCallback, useMemo, useRef} from 'react'
import {
  ColorLegend,
  ColorLegendItem,
} from '../../../../../../components/charts/ColorLegend/ColorLegend'
import {useDefaultColorScalePreset} from '../../../../../../components/charts/hooks/useDefaultColorScalePreset'
import {
  BarChartDatum,
  VerticalBarChart,
} from '../../../../../../components/charts/HorizontalBarChart/VerticalBarChart'
import {TooltipContextProvider} from '../../../../../../components/charts/TooltipContext/TooltipContextProvider'
import {WidgetF11Model} from '../../../../../../models/fnb/WidgetModel'
import {ProductCategoryRevenueTooltip} from './ProductCategoryRevenueTooltip'

export interface ProductCategoryRevenueProps {
  datum?: WidgetF11Model[]
}

export const ProductCategoryRevenue = ({datum}: ProductCategoryRevenueProps) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })
  const chartDatum = useMemo((): BarChartDatum[] => {
    if (datum) {
      return datum
        .filter((item) => item.total > 0)
        .map((d) => ({
          key: d.fnbProductCategoryName,
          label: d.fnbProductCategoryName,
          value: d.total,
        }))
    }
    return []
  }, [datum])

  const domain = useMemo(
    () => (datum ? datum.map((datum) => datum.fnbProductCategoryName) : []),
    [datum]
  )
  const getColor = useDefaultColorScalePreset({domain})
  const legends = useMemo((): ColorLegendItem[] => {
    if (datum) {
      return datum.map((datum) => ({
        color: getColor(datum.fnbProductCategoryName),
        label: datum.fnbProductCategoryName,
        key: String(datum.fnbProductCategoryName),
      }))
    }
    return []
  }, [datum, getColor])

  const handleLegendMouseMove = useCallback(
    (e: unknown, key: string | number) => {
      showTooltip({
        tooltipData: String(key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  return (
    <div style={{height: '400px'}}>
      <div className='d-flex gap-10 flex-grow-1 flex-wrap flex-md-nowrap h-100 w-100'>
        <div className='flex-grow-1 h-100 order-xs-2 order-xs-2 order-md-1'>
          <VerticalBarChart
            datum={chartDatum}
            yLabel='Category'
            xLabel='Earnings'
            isHideXLabel
            tooltip={<ProductCategoryRevenueTooltip datum={datum} />}
          />
        </div>
        <div
          ref={containerRef}
          onMouseMove={handleContainerMouseMove}
          onMouseOut={hideTooltip}
          className='h-100 overflow-hidden h-100 order-xs-1 order-xs-1 order-md-2'
        >
          <div className='overflow-auto h-100 mt-xs-10 mt-sm-10 mt-md-0'>
            <ColorLegend
              className='d-flex gap-2 flex-xs-row flex-sm-row flex-md-column'
              legends={legends}
              onMouseLeave={hideTooltip}
              onMouseMove={handleLegendMouseMove}
            />
          </div>
        </div>
      </div>

      <TooltipContextProvider value={{key: tooltipData}}>
        {tooltipOpen && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={defaultStyles}>
            <ProductCategoryRevenueTooltip datum={datum} />
          </TooltipInPortal>
        )}
      </TooltipContextProvider>
    </div>
  )
}
