import {
  MerchandiseUserModel,
  MerchandiseUserModelCreateParams,
} from './../../../../models/merchandise/MerchandiseUserModel'
import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {FilterModel} from '../../../../models/FilterModel'
import {
  MerchandiseOutletModel,
  MerchandiseOutletModelCreateParams,
} from '../../../../models/merchandise/MerchandiseOutletModel'
import {
  MerchandiseProductCategoryModel,
  MerchandiseProductCategoryModelCreateParams,
} from '../../../../models/merchandise/MerchandiseProductCategoryModel'
import {
  MerchandiseProductModel,
  MerchandiseProductModelCreateParams,
} from '../../../../models/merchandise/MerchandiseProductModel'

import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {
  GetOutlets,
  GetProductCategories,
  GetProducts,
  GetOrders,
  GetUsers,
  GetOutletLogs,
} from './MerchandiseCRUD'
import {
  MerchandiseOrderModel,
  MerchandiseOrderModelCreateParams,
} from '../../../../models/merchandise/MerchandiseOrderModel'
import {MerchandiseLogsModel} from '../../../../models/merchandise/MerchandiseLogsModel'

interface ActionWithPayload<T> extends Action {
  payload: T
}

interface IPagesState {
  outlets?: GlobalSearchModel<MerchandiseOutletModel>
  products?: GlobalSearchModel<MerchandiseProductModel>
  productCategories?: GlobalSearchModel<MerchandiseProductCategoryModel>
  orders?: GlobalSearchModel<MerchandiseOrderModel>
  users?: GlobalSearchModel<MerchandiseUserModel>
  outletLogs?: GlobalSearchModel<MerchandiseLogsModel>
}

const initialAuthState: IPagesState = {
  outlets: undefined,
  products: undefined,
  productCategories: undefined,
  orders: undefined,
  users: undefined,
  outletLogs: undefined,
}

export const reducer = persistReducer(
  {storage, key: 'webapp-merchandise', whitelist: []},
  (state: IPagesState = initialAuthState, action: Partial<ActionWithPayload<IPagesState>>) => {
    switch (action.type) {
      case actionTypes.outlets.search.success: {
        const outlets = action.payload?.outlets
        return {...state, outlets}
      }
      case actionTypes.products.search.success: {
        const products = action.payload?.products
        return {...state, products}
      }
      case actionTypes.productCategories.search.success: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }

      case actionTypes.orders.search.success: {
        const orders = action.payload?.orders
        return {...state, orders}
      }

      case actionTypes.users.search.success: {
        const users = action.payload?.users
        return {...state, users}
      }

      case actionTypes.outletLogs.search.success: {
        const outletLogs = action.payload?.outletLogs
        return {...state, outletLogs}
      }

      default:
        return state
    }
  }
)

export function* saga() {
  yield takeLatest(
    [
      actionTypes.outlets.search.execute,
      actionTypes.outlets.create.success,
      actionTypes.outlets.update.success,
      actionTypes.outlets.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-outlet']
        )
        const {data} = yield GetOutlets(filter)
        yield put(actions.outlets.searchSuccess(data))
      } catch (e) {
        yield put(actions.outlets.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.productCategories.search.execute,
      actionTypes.productCategories.create.success,
      actionTypes.productCategories.update.success,
      actionTypes.productCategories.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-product-category']
        )
        const {data} = yield GetProductCategories(filter)
        yield put(actions.productCategories.searchSuccess(data))
      } catch (e) {
        yield put(actions.productCategories.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.products.search.execute,
      actionTypes.products.create.success,
      actionTypes.products.update.success,
      actionTypes.products.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-product']
        )
        const {data} = yield GetProducts(filter)
        yield put(actions.products.searchSuccess(data))
      } catch (e) {
        yield put(actions.products.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.orders.search.execute,
      actionTypes.orders.create.success,
      actionTypes.orders.update.success,
      actionTypes.orders.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-order']
        )
        const {data} = yield GetOrders(filter)
        yield put(actions.orders.searchSuccess(data))
      } catch (e) {
        yield put(actions.orders.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.users.search.execute,
      actionTypes.users.create.success,
      actionTypes.users.update.success,
      actionTypes.users.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-user']
        )
        const {data} = yield GetUsers(filter)
        yield put(actions.users.searchSuccess(data))
      } catch (e) {
        yield put(actions.users.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.outletLogs.search.execute,
      actionTypes.outletLogs.create.success,
      actionTypes.outletLogs.update.success,
      actionTypes.outletLogs.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['merchandise-outlet-log']
        )
        const {data} = yield GetOutletLogs(filter)
        yield put(actions.outletLogs.searchSuccess(data))
      } catch (e) {
        yield put(actions.outletLogs.searchFailed())
      }
    }
  )
}

export const actions = {
  outlets: {
    search: () => ({type: actionTypes.outlets.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseOutletModel>) => ({
      type: actionTypes.outlets.search.success,
      payload: {outlets: data},
    }),
    searchFailed: () => ({type: actionTypes.outlets.search.failed}),

    create: (payload: MerchandiseOutletModelCreateParams) => ({
      type: actionTypes.outlets.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.outlets.create.success}),
    createFailed: () => ({type: actionTypes.outlets.create.failed}),

    update: (payload: MerchandiseOutletModelCreateParams) => ({
      type: actionTypes.outlets.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.outlets.update.success}),
    updateFailed: () => ({type: actionTypes.outlets.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.outlets.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.outlets.delete.success}),
    deleteFailed: () => ({type: actionTypes.outlets.delete.failed}),
  },
  products: {
    search: () => ({type: actionTypes.products.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseProductModel>) => ({
      type: actionTypes.products.search.success,
      payload: {products: data},
    }),
    searchFailed: () => ({type: actionTypes.products.search.failed}),

    create: (payload: MerchandiseProductModelCreateParams) => ({
      type: actionTypes.products.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.products.create.success}),
    createFailed: () => ({type: actionTypes.products.create.failed}),

    update: (payload: MerchandiseProductModelCreateParams) => ({
      type: actionTypes.products.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.products.update.success}),
    updateFailed: () => ({type: actionTypes.products.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.products.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.products.delete.success}),
    deleteFailed: () => ({type: actionTypes.products.delete.failed}),
  },
  productCategories: {
    search: () => ({type: actionTypes.productCategories.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseProductCategoryModel>) => ({
      type: actionTypes.productCategories.search.success,
      payload: {productCategories: data},
    }),
    searchFailed: () => ({type: actionTypes.productCategories.search.failed}),

    create: (payload: MerchandiseProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.productCategories.create.success}),
    createFailed: () => ({type: actionTypes.productCategories.create.failed}),

    update: (payload: MerchandiseProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.productCategories.update.success}),
    updateFailed: () => ({type: actionTypes.productCategories.update.failed}),

    delete: (codes: string[]) => ({
      type: actionTypes.productCategories.delete.execute,
      payload: codes,
    }),
    deleteSuccess: () => ({type: actionTypes.productCategories.delete.success}),
    deleteFailed: () => ({type: actionTypes.productCategories.delete.failed}),
  },
  orders: {
    search: () => ({type: actionTypes.orders.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseOrderModel>) => ({
      type: actionTypes.orders.search.success,
      payload: {orders: data},
    }),
    searchFailed: () => ({type: actionTypes.orders.search.failed}),

    create: (payload: MerchandiseOrderModelCreateParams) => ({
      type: actionTypes.orders.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.orders.create.success}),
    createFailed: () => ({type: actionTypes.orders.create.failed}),

    update: (payload: MerchandiseOrderModelCreateParams) => ({
      type: actionTypes.orders.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.orders.update.success}),
    updateFailed: () => ({type: actionTypes.orders.update.failed}),

    delete: (codes: string[]) => ({
      type: actionTypes.orders.delete.execute,
      payload: codes,
    }),
    deleteSuccess: () => ({type: actionTypes.orders.delete.success}),
    deleteFailed: () => ({type: actionTypes.orders.delete.failed}),
  },

  users: {
    search: () => ({type: actionTypes.users.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseUserModel>) => ({
      type: actionTypes.users.search.success,
      payload: {users: data},
    }),
    searchFailed: () => ({type: actionTypes.users.search.failed}),

    create: (payload: MerchandiseUserModelCreateParams) => ({
      type: actionTypes.users.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.users.create.success}),
    createFailed: () => ({type: actionTypes.users.create.failed}),

    update: (payload: MerchandiseUserModelCreateParams) => ({
      type: actionTypes.users.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.users.update.success}),
    updateFailed: () => ({type: actionTypes.users.update.failed}),

    delete: (codes: string[]) => ({
      type: actionTypes.users.delete.execute,
      payload: codes,
    }),
    deleteSuccess: () => ({type: actionTypes.users.delete.success}),
    deleteFailed: () => ({type: actionTypes.users.delete.failed}),
  },

  outletLogs: {
    search: () => ({type: actionTypes.outletLogs.search.execute}),
    searchSuccess: (data: GlobalSearchModel<MerchandiseLogsModel>) => ({
      type: actionTypes.outletLogs.search.success,
      payload: {outletLogs: data},
    }),
    searchFailed: () => ({type: actionTypes.outletLogs.search.failed}),
  },
}

const actionTypes = {
  outlets: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET',
      success: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE OUTLET',
      success: '[MERCHANDISE] CREATE MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE OUTLET FAILED',
    },
    update: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE OUTLET',
      success: '[MERCHANDISE] UPDATE MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE OUTLET FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE OUTLET',
      success: '[MERCHANDISE] DELETE MERCHANDISE OUTLET SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE OUTLET FAILED',
    },
  },
  products: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT',
      success: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT FAILED',
    },
  },
  productCategories: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE PRODUCT CATEGORY FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE PRODUCT CATEGORY FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE PRODUCT CATEGORY FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT CATEGORY',
      success: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT CATEGORY SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE PRODUCT CATEGORY FAILED',
    },
  },

  orders: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE ORDER',
      success: '[MERCHANDISE] SEARCH MERCHANDISE ORDER SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE ORDER FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE ORDER',
      success: '[MERCHANDISE] CREATE MERCHANDISE ORDER SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE ORDER FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE MERCHANDISE ORDER',
      success: '[MERCHANDISE] UPDATE MERCHANDISE ORDER SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE ORDER FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE ORDER',
      success: '[MERCHANDISE] DELETE MERCHANDISE ORDER SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE ORDER FAILED',
    },
  },

  users: {
    search: {
      execute: '[MERCHANDISE] SEARCH MERCHANDISE USERS',
      success: '[MERCHANDISE] SEARCH MERCHANDISE USERS SUCCESS',
      failed: '[MERCHANDISE] SEARCH MERCHANDISE USERS FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE MERCHANDISE USERS',
      success: '[MERCHANDISE] CREATE MERCHANDISE USERS SUCCESS',
      failed: '[MERCHANDISE] CREATE MERCHANDISE USERS FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE MERCHANDISE USERS',
      success: '[MERCHANDISE] UPDATE MERCHANDISE USERS SUCCESS',
      failed: '[MERCHANDISE] UPDATE MERCHANDISE USERS FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE MERCHANDISE USERS',
      success: '[MERCHANDISE] DELETE MERCHANDISE USERS SUCCESS',
      failed: '[MERCHANDISE] DELETE MERCHANDISE USERS FAILED',
    },
  },
  outletLogs: {
    search: {
      execute: '[MERCHANDISE] SEARCH OUTLET LOG',
      success: '[MERCHANDISE] SEARCH OUTLET LOG SUCCESS',
      failed: '[MERCHANDISE] SEARCH OUTLET LOG FAILED',
    },
    create: {
      execute: '[MERCHANDISE] CREATE OUTLET LOG',
      success: '[MERCHANDISE] CREATE OUTLET LOG SUCCESS',
      failed: '[MERCHANDISE] CREATE OUTLET LOG FAILED',
    },
    update: {
      execute: '[MERCHANDISE] UPDATE OUTLET LOG',
      success: '[MERCHANDISE] UPDATE OUTLET LOG SUCCESS',
      failed: '[MERCHANDISE] UPDATE OUTLET LOG FAILED',
    },
    delete: {
      execute: '[MERCHANDISE] DELETE OUTLET LOG',
      success: '[MERCHANDISE] DELETE OUTLET LOG SUCCESS',
      failed: '[MERCHANDISE] DELETE OUTLET LOG FAILED',
    },
  },
}
