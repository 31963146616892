import {ReactNode, useMemo, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AuthDigitalWaiverPage} from '../../modules/digital-waiver/pages/AuthDigitalWaiverPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthNavigationSectionMenuItem} from '../../../config/portalDrawer'
import {useEntityFilter} from '../../components/hooks/useEntityFilter'

import {AuthWaiverHeaderThumbnail} from '../../modules/digital-waiver/components/AuthWaiverHeaderThumbnail'
import {useWaiverAuth} from '../../modules/digital-waiver/hooks/useWaiverAuth'
import {QuickWaiverPage} from '../../modules/digital-waiver/pages/QuickWaiverPage'
import {HeaderControls} from '../../components/layouts/WaiverLayout/Header/HeaderControls'
import {DigitalWaiverLayout} from '../../components/layouts/WaiverLayout/DigitalWaiverLayout'
import {QuickWaiverThankyouPage} from '../../modules/digital-waiver/pages/QuickWaiverThankyouPage'
import {DigitalWaiverGuardianFormPage} from '../../modules/digital-waiver/pages/DigitalWaiverGuardianFormPage'
import {GuardianRejectPage} from '../../modules/digital-waiver/pages/GuardianRejectPage'
import { AuthStateProvider } from '../../modules/customer-portal/components/forms/AuthStateContext'
export const Routes = () => {
  const auth = useWaiverAuth()

  const {setFilter} = useEntityFilter('digital-waiver-product-catergories-tabs')

  useEffect(() => {
    setFilter({
      filters: {
        ticketStatus: 'confirmed',
      },
    })
  }, [setFilter])

  const menus = useMemo(() => {
    const upper_tabs: AuthNavigationSectionMenuItem[] = [
      {
        title: 'Active Waivers',
        to: '/waiver/current',
        icon: '/media/svg/waiver-icons/tab.svg',
      },
      {
        title: 'Used Waivers',
        to: '/waiver/previous',
        icon: '/media/svg/waiver-icons/tab.svg',
      },
    ]

    return upper_tabs
  }, [])

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <DigitalWaiverLayout menus={menus}>
        <HeaderControls>
          <AuthWaiverHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </DigitalWaiverLayout>
    )
  }, [auth, menus])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthDigitalWaiverPage />
        </Route>
      )
    }
    return <Redirect from='/auth' to='/' />
  }, [auth])

  return (
    <AuthStateProvider>
    <Switch>
      <Route path='/guardian/thank-you' component={GuardianRejectPage} />
      <Route path='/quick-waiver/thank-you' component={QuickWaiverThankyouPage} />
      <Route path='/quick-waiver/:ticketCode' component={QuickWaiverPage} />
      <Route path='/guardian/:ticketCode' component={DigitalWaiverGuardianFormPage} />

      <Route path='/logout' component={Logout} />
      <Route path='/auth/login/:hash' component={AuthDigitalWaiverPage} />
      <Route path='/auth/login' component={AuthDigitalWaiverPage} />

      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://webntech.ae' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
    </AuthStateProvider>
  )
}
