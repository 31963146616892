import clsx from 'clsx'
import {useMemo, useRef} from 'react'
import {useOnChange} from '../../hooks/useOnChange'
import {NumberStepper} from '../../layouts/Stepper/NumberStepper'
import {WizardStep} from './WizardControls'

export interface WizardSteppersProps {
  steps: WizardStep[]
  currentStep: number
  className?: string
}

export const WizardSteppers = ({steps, currentStep, className}: WizardSteppersProps) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const steppers = useMemo(
    () =>
      steps.map((step, index) =>
        step.hidden ? null : (
          <div
            key={index}
            className={clsx(`stepper-${index} m-2 d-md-block`, {
              'd-none': currentStep !== index,
            })}
          >
            <NumberStepper
              className='text-nowrap'
              active={index === currentStep}
              state={step.state}
              value={index}
              title={step.title}
              description={step.description}
              icon={step.icon}
            />
          </div>
        )
      ),
    [currentStep, steps]
  )

  useOnChange(currentStep, () => {
    const element = parentRef.current?.querySelector(`.stepper-${currentStep}`)
    if (element) {
      element.scrollIntoView(false)
    }
  })

  return (
    <div ref={parentRef} className={clsx('d-flex justify-content-center', className)}>
      <div className='d-inline-flex overflow-auto'>{steppers}</div>
    </div>
  )
}
