import {useMemo, useState} from 'react'
import {PortaProductVoucherModel} from '../../../../models/customer-portal/PortalWidgetModal'
import {SubProductsCard} from './SubProductsCard'
import {ProductDetails} from './ProductDetails'

import {Button} from '../../../../components/inputs/Button'
interface DashboardCardProps {
  product: PortaProductVoucherModel
  isVoucher?: boolean
}

export const DashboardCard = ({product, isVoucher}: DashboardCardProps) => {
  const [rotation, setRotation] = useState<string>()

  const rotate = useMemo(() => {
    return rotation
  }, [rotation])

  const cardBox = useMemo(() => {
    const hasChildren = product.children && product.children.length <= 0 ?  false : true
    return (
      <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 px-2 pt-5'>
        <div className='card flip-card'>
          <div
            className='flip-card-inner'
            style={{
              transform: rotate,
              boxShadow: 'rgb(52 92 89 / 73%) 0rem 1rem 1rem -1.2rem',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              height: '344px',
            }}
          >
            <div
              className='top-cut-corner-polygon flip-card-inner-front rs-gradient border border-gray'
              style={{zIndex: rotation ? 0 : 10}}
            >
              <div className='rounded-0 card shadow-lg h-100 d-flex flex-column justify-content-between'>
                <div className='flex-grow-1'>
                  <div
                    style={{
                      height: '100%',
                    }}
                  >
                    <ProductDetails
                      product={product}
                      items={{
                        qty: product.qty,
                        remaining: product.remaining,
                        used: product.used,
                      }}
                      hasChildren={hasChildren}
                    />
                  </div>
                </div>

              {product.children && product.children.length > 0 && (
                <Button
                  className='btn-bg-warning rounded-0 text-dark ff-admm-bold fs-4'
                  fullWidth
                  onClick={rotation ? undefined : () => setRotation('rotateY(180deg)')}
                  style={{
                    height: '50px',
                  }}
                  disabled={!product.children || product.children.length <= 0}
                >
                  View More
                </Button>
              )

              }  
              </div>
            </div>
            <div
              className='flip-card-inner-back d-flex flex-column justify-content-between rs-gradient  border border-gray'
              style={{zIndex: rotation ? 10 : 0}}
            >
              <div
                className='overflow-auto'
                style={{
                  height: '292px',
                }}
              >
                <SubProductsCard product={product.children} />
              </div>

              <Button
                className='btn-bg-warning rounded-0 text-dark ff-admm-bold fs-4'
                fullWidth
                onClick={rotation ? () => setRotation(undefined) : undefined}
                style={{
                  height: '50px',
                }}
              >
                View Less
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }, [product, rotate, rotation])

  return cardBox
}
