import {ReactNode, createContext, useContext, useState} from 'react'
import {EventModel} from '../../../../models/ems/EventModel'

interface BookingStateContextType {
  eventData: EventModel | null
  setEventData: (data: EventModel | null) => void
}

const BookingStateContext = createContext<BookingStateContextType | undefined>(undefined)

export const BookingStateProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [eventData, setEventData] = useState<EventModel | null>(null)
  return (
    <BookingStateContext.Provider value={{eventData, setEventData}}>
      {children}
    </BookingStateContext.Provider>
  )
}

export const useBookingState = () => {
  const context = useContext(BookingStateContext)
  if (!context) {
    throw new Error('useBookingState must be used within a BookingStateprovider')
  }
  return context
}
