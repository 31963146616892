import {useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {LocationModel} from '../../../../../../../models/acs/LocationModel'
import {BookingProductListModel} from '../../../../../../../models/ems/ProductModel'
import {
  TicketModelFulfillBulkParams,
  TicketModelFulfillParams,
} from '../../../../../../../models/ems/TicketModel'
import {GetLocationByCode, GetLocationsByProductCode} from '../../../../../acs/redux/AcsCRUD'
import {ApiTree} from '../../../../../../../utils/Tree/ApiTree'
import {useAlerts} from '../../../../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../../../../components/hooks/useOnChange'
import {usePrevious} from '../../../../../../../components/hooks/usePrevious'
import {useSafeStateUpdate} from '../../../../../../../components/hooks/useSafeStateUpdate'
import {useSeatMapState} from '../../../../../../../components/hooks/useSeatMapState'
import {SeatMapSelectionModalInput} from '../../../../../../../components/inputs/SeatMapInput/SeatMapSelectionModalInput'
import {SeatMapValue} from '../../../../../../../components/inputs/SeatMapInput/SeatMapValue'
import {TreeNodeItem} from '../../../../../../../components/inputs/TreeSelect/TreeSelect'
import {DateRange} from '../../../../../../../utils/DateRange'
import {DateUtil} from '../../../../../../../utils/DateUtil'
import moment from 'moment'
import {BookingDetailModel} from '../../../../../../../models/customer-portal/BookingDetailModel'

export interface SeatMapSelectionModalFormValues {
  selected: SeatMapValue
  locationCode: string
  dateRange?: DateRange
}

export interface SeatMapSelectionModalFormProps {
  initialValues?: SeatMapSelectionModalFormValues
  onSubmit: (values: SeatMapSelectionModalFormValues) => void | Promise<void>
  open?: boolean
  isOpen?: boolean
  onHide?: () => void
  onClose?: () => void
  product?: BookingProductListModel | BookingDetailModel | null
}

export const SeatMapSelectionModalForm = ({
  onSubmit,
  initialValues = EMPTY_FORM_VALUES,
  onHide,
  onClose,
  open,
  isOpen,
  product,
}: SeatMapSelectionModalFormProps) => {
  const [count, setCount] = useState(0)
  const {
    resetOccupiedData,
    seatMapSpacingX,
    occupied,
    extra,
    disabled,
    columns,
    rows,
    hidden,
    isRightToLeft,
    isBottomToTop,
    resetState: resetSeatMapState,
    isLoading,
    setIsLoading,
  } = useSeatMapState()
  const safeUpdate = useSafeStateUpdate()
  const previousInitialValues = usePrevious(initialValues)
  const previousOpen = usePrevious(open || isOpen)
  const {push, pushError} = useAlerts()
  const [locations, setLocations] = useState<LocationModel[]>([])

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await onSubmit(values)
    },
  })

  const handleSeatMapChange = useCallback(
    (value: SeatMapValue) => {
      formik.setFieldValue('selected', value)
    },
    [formik]
  )

  const resetLocationByCode = useCallback(
    async (locationCode: string, eventCode: string) => {
      const doneLoading = setIsLoading(locationCode)
      try {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetSeatMapState(data.seatMap))
        }
      } catch (e) {
        pushError(e)
      } finally {
        if (product) {
          resetOccupiedData(locationCode, eventCode, product?.code)
        }
        doneLoading()
      }
    },
    [setIsLoading, push, safeUpdate, resetSeatMapState, pushError, product, resetOccupiedData]
  )

  const locationItems = useMemo((): TreeNodeItem[] => {
    const locationTree = new ApiTree(locations)
    return locationTree.getTreeSelectItems()
  }, [locations])

  const handleLocationChange = useCallback(
    (locationCode) => {
      formik.setFieldValue('locationCode', locationCode)
      formik.setFieldValue('selected', new SeatMapValue())
      resetSeatMapState()
      if (locationCode && product) {
        resetLocationByCode(locationCode, product.eventCode)
      }
    },
    [formik, product, resetLocationByCode, resetSeatMapState]
  )

  const resetLocationList = useCallback(async () => {
    setLocations([])
    if (product) {
      const {data} = await GetLocationsByProductCode(product.code)
      setLocations(data)
    }
  }, [product])

  useOnChange(product, () => {
    if (product) {
      setCount(product.bookingProductQty || 0)
      resetLocationList()
    }
  })

  useEffect(() => {
    if (previousInitialValues !== initialValues) {
      formik.resetForm()
    }
  }, [formik, initialValues, previousInitialValues])

  useEffect(() => {
    if (previousOpen !== (open || isOpen)) {
      if (open || isOpen) {
        formik.setValues(initialValues)
      } else {
        formik.setValues(EMPTY_FORM_VALUES)
      }
      resetSeatMapState()
    }
  }, [formik, initialValues, open, isOpen, previousOpen, resetSeatMapState])

  const getModalTitle = useCallback(() => {
    return product?.name || ''
  }, [product?.name])

  const handleDateRangeChange = useCallback(
    (value: DateRange) => {
      formik.setFieldValue('dateRange', value)
    },
    [formik]
  )

  const minDate = useMemo(() => {
    const today = moment().startOf('day')
    if (product) {
      const startedAt = DateUtil.getDateFromApiString(product.startedAt)
      return moment.max(moment(startedAt), today).toDate()
    }
    return today.toDate()
  }, [product])

  const maxDate = useMemo(() => {
    if (product) {
      return DateUtil.getDateFromApiString(product.endedAt)
    }
  }, [product])

  const handleSubmit = useCallback(() => {
    if (!formik.isValid && formik.errors.dateRange) {
      push({
        message: formik.errors.dateRange,
        timeout: 5000,
        variant: 'danger',
      })
    }
    formik.submitForm()
  }, [formik, push])
  return (
    <>
      <SeatMapSelectionModalInput
        modalTitle={getModalTitle}
        spacingX={seatMapSpacingX}
        occupied={occupied}
        extra={extra}
        locationItems={locationItems}
        locationCode={formik.values.locationCode}
        onLocationChange={handleLocationChange}
        onSubmit={handleSubmit}
        count={count}
        disabled={disabled}
        onDateRangeChange={handleDateRangeChange}
        dateRange={product?.isTimeslot ? formik.values.dateRange : undefined}
        columns={columns}
        rows={rows}
        loading={isLoading}
        disableSubmit={formik.isSubmitting || !formik.isValid}
        disableSelection={formik.isSubmitting}
        onChange={handleSeatMapChange}
        value={formik.values.selected}
        open={open || isOpen || false}
        hidden={hidden}
        onHide={() => {
          if (onClose) onClose()
          if (onHide) onHide()
        }}
        isRightToLeft={isRightToLeft}
        isBottomToTop={isBottomToTop}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  )
}

const EMPTY_FORM_VALUES: SeatMapSelectionModalFormValues = {
  selected: new SeatMapValue(),
  locationCode: '',
  dateRange: new DateRange(),
}

export const getPayload = (
  values: SeatMapSelectionModalFormValues,
  productCode: string
): TicketModelFulfillParams => {
  const payload: TicketModelFulfillParams = {
    locationCode: values.locationCode,
    seats: values.selected.getSeatMapObject(),
    productCode,
  }

  return payload
}

export const getBulkSeatMapPayload = (
  values: SeatMapSelectionModalFormValues,
  productCode: string,
  customerCode: string,
  startedAt: string,
  endedAt: string,
  bundleCode?: string,
  uid?: string
): TicketModelFulfillBulkParams => {
  const payload: TicketModelFulfillBulkParams = {
    locationCode: values.locationCode,
    seats: values.selected,
    productCode,
    customerCode,
    startedAt,
    endedAt,
    bundleCode,
    uid,
  }

  return payload
}
