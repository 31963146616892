import {useState} from 'react'

const rows = Array(8).fill(1)
const grid = Array(8).fill([...rows])

interface TableGridProps {
  handleSubmit?: (result: any) => void
}

const TableGrid = ({handleSubmit}: TableGridProps) => {
  const [size, setSize] = useState({cols: 0, rows: 0})

  const handleHover = (i: number, j: number) => {
    setSize({cols: j + 1, rows: i + 1})
  }

  const handleSelect = () => {
    if (handleSubmit) handleSubmit(size)
  }

  return (
    <div>
      <div style={{margin: '10px 10px 5px'}}>
        {grid.map((row, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              width: 136,
              justifyContent: 'space-between',
              padding: 1,
              cursor: 'pointer',
            }}
          >
            {row.map((_: any, j: number) => {
              const isSelected = i <= size.rows - 1 && j <= size.cols - 1
              return (
                <div
                  key={`${i}-${j}`}
                  style={
                    [
                      {
                        flex: '0 0 15px',
                        height: '15px',
                        border: !isSelected ? '1px solid rgba(150, 150, 150, 1)' : '#3699ff',
                        background: !isSelected ? 'rgba(200, 200, 200, 0.4)' : '#3699ff',
                      },
                    ][0]
                  }
                  onMouseEnter={() => handleHover(i, j)}
                  onClick={handleSelect}
                ></div>
              )
            })}
          </div>
        ))}
      </div>
      <div style={{margin: 10, fontSize: 12}}>
        Insert table size: {size.cols} x {size.rows}
      </div>
    </div>
  )
}

export default TableGrid
