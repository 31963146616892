import { useDispatch } from "react-redux"
import { useCallback } from "react"
import { useRootStateSelector } from "../../../components/hooks/useRootStateSelector"
import { useEntityFilter } from "../../../components/hooks/useEntityFilter"
import { actions } from "../redux/CustomerPortalRedux"
import { FilterModel } from "../../../models/FilterModel"
import { Toolbar } from "../../../components/layouts/DefaultLayout/Toolbar"
import { LogTable } from "../components/tables/LogTable/LogTable"

export const LogPage = () => {
    const dispatch = useDispatch()

    const logs = useRootStateSelector((state) => state.customerPortal.logs)
    const {setFilter} = useEntityFilter('customer-portal-log')
    
    const refreshData = useCallback(() => { 
        dispatch(actions.logs.search())
    }, [dispatch])
  
    const onFilterHandler = useCallback(
      (filter: FilterModel) => { 
        setFilter(filter)
        refreshData()
      },
      [refreshData, setFilter]
    )
  

  
    return (
      <>
        <Toolbar title='Logs'></Toolbar>
  
        <LogTable
          onFilter={onFilterHandler}
          data={logs}
          className='rs-table-bg'
          customWrapper='rs-top'
          cornersClassName='table-cut-corner-polygon rs-gradient'
          paginationClassName='px-10 pb-10 rs-pagination'
        />
      </>
    )
}