import clsx from 'clsx'
import {useMemo} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {useId} from '../hooks/useId'
import {ColorVariant} from './Button'

export type MetronicIconSize = 'sm' | 'md' | 'lg' | 'xl' | 'default' | 'svg-icon-4' | 'svg-icon-1'

export interface MetronicIconPath {
  iconType: string
  iconName: string
  color?: ColorVariant
}

export interface MetronicIconProps extends MetronicIconPath {
  className?: string
  size?: MetronicIconSize
  svgRef?: React.Ref<SVGElement>
  color?: ColorVariant
  classes?: {
    svg?: string
  }
  tooltip?: string
}

export const MetronicIcon = ({
  className,
  iconName,
  iconType,
  size = 'sm',
  color,
  classes,
  tooltip,
}: MetronicIconProps) => {
  const randomId = useId()

  const sizeClassName = useMemo(() => {
    switch (size) {
      case 'md':
        return `svg-icon-2x`
      case 'lg':
        return `svg-icon-3x`
      case 'xl':
        return `svg-icon-4x`
      case 'sm':
        return `svg-icon-3`
      case 'default':
        return null
      default:
        return size
    }
  }, [size])

  const colorClassName = useMemo(() => {
    if (color) {
      return `svg-icon-${color}`
    }
  }, [color])

  const icon = (
    <KTSVG
      className={clsx(className, sizeClassName, colorClassName)}
      svgClassName={classes?.svg}
      path={`/media/icons/duotone/${iconType}/${iconName}.svg`}
    />
  )

  if (tooltip) {
    return (
      <OverlayTrigger
        placement='auto'
        overlay={<Tooltip id={`${tooltip}${randomId}`}>{tooltip}</Tooltip>}
      >
        {icon}
      </OverlayTrigger>
    )
  }

  return icon
}
