import {useCallback, useMemo, useState} from 'react'
import {FormikContextType} from 'formik'
import {BulkConsolidatedFormValues} from '../../../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ActivityModel} from '../../../../../models/ems/ActivityModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {useWizardFormikHelpers} from '../../../../../components/forms/Wizard/useEventWizardHelpers'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {WizardControls, WizardStep} from '../../../../../components/forms/Wizard/WizardControls'
import {WizardControlProvider} from '../../../../../components/forms/Wizard/WizardControlProvider'
import {WizardSteppers} from '../../../../../components/forms/Wizard/WizardSteppers'
import {BookingWizardEventStep} from './steps/BookingWizardEventStep'
import {BookingWizardBulkConsolidatedCustomerStep} from './steps/bulkConsolidatedBooking/BookingWizardBulkConsolidatedCustomerStep'
import {BookingWizardCreateBulkConsolidatedCustomerStep} from './steps/bulkConsolidatedBooking/BookingWizardCreateBulkConsolidatedCustomerStep'
import {BookingWizardBulkConsolidatedProductVenueStep} from './steps/bulkConsolidatedBooking/BookingWizardBulkConsolidatedProductVenueStep'
import {BookingWizardBulkConsolidatedFinalizeStep} from './steps/bulkConsolidatedBooking/BookingWizardBulkConsolidatedFinalizeStep'
import {BookingWizardBulkConsolidatedFinaleStep} from './steps/bulkConsolidatedBooking/BookingWizardBulkConsolidatedFinaleStep'

export interface BookingBulkConsolidatedWizardProps {
  formik: FormikContextType<BulkConsolidatedFormValues>
  disabledFields?: Partial<Record<keyof BulkConsolidatedFormValues, boolean>>
  hiddenFields?: Partial<Record<keyof BulkConsolidatedFormValues, boolean>>
  step: number
  onStepChange: (step: number) => void
  event?: EventModel | ActivityModel | null
  customer?: CustomerModel
  isEdit?: boolean
  onSubmit?: () => void
  onSkip?: () => void
}

export const BookingBulkConsolidatedWizard = ({
  formik,
  hiddenFields,
  disabledFields,
  step: currentStep,
  onStepChange,
  event,
  customer,
  isEdit,
  onSubmit,
  onSkip,
}: BookingBulkConsolidatedWizardProps) => {
  const {getStepState} = useWizardFormikHelpers({
    formik,
    currentStep,
    noErrors: currentStep === 3,
  })

  const [noCustomer, setNoCustomer] = useState<boolean>(true)

  useOnChange(event, () => {
    formik.setFieldValue('eventCode', event?.code)
  })

  const isStepperShown = useCallback(
    (stepperFields: (keyof BulkConsolidatedFormValues)[]) => {
      if (!hiddenFields) {
        return true
      }
      const shouldShow = !stepperFields.every((field) => !!hiddenFields[field])
      return shouldShow
    },
    [hiddenFields]
  )

  const steps = useMemo((): WizardStep[] => {
    if (customer) {
      return [
        {
          title: 'Event',
          description: 'Search Event',
          state: getStepState(0, ['eventcode']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['eventCode'],
          hidden: !isStepperShown(['eventCode']),
        },
        {
          title: 'Event',
          description: 'Search Event',
          state: getStepState(1, ['eventcode']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['eventCode'],
          hidden: true,
        },
        {
          title: 'Product/Voucher',
          description: 'select product & voucher',
          state: getStepState(2, ['products', 'vouchers']),
          icon: {
            iconType: 'Cooking',
            iconName: 'Cutting board',
          },
          fields: ['product', 'voucher'],
          hidden: !isStepperShown(['products', 'vouchers']),
        },
        {
          title: 'Finalize',
          description: 'Fulfill booking products',
          state: getStepState(3, ['customersSeats']),
          hidden: !isStepperShown(['customersSeats']),
          icon: {
            iconType: 'Communication',
            iconName: 'Flag',
          },
          fields: ['customersSeats'],
        },
      ]
    } else if (event)
      return [
        {
          title: 'Customer',
          description: 'Search customer',
          state: getStepState(0, ['customers']),
          icon: {
            iconType: 'General',
            iconName: 'Search',
          },
          fields: ['customers'],
          hidden: !isStepperShown(['customers']),
        },
        {
          title: 'New Customer',
          description: 'Create customer',
          state: getStepState(1, ['customers']),
          icon: {
            iconType: 'Communication',
            iconName: 'Add-user',
          },
          fields: ['customers'],
          hidden: Boolean(isEdit),
        },
        {
          title: 'Product/Voucher',
          description: 'Select product & voucher',
          state: getStepState(2, ['product', 'voucher']),
          icon: {
            iconType: 'Cooking',
            iconName: 'Cutting board',
          },
          fields: ['product', 'voucher', 'products'],
          hidden: !isStepperShown(['product', 'voucher']),
        },
        {
          title: 'Ticket Fulfillment',
          description: 'Fulfill booking products',
          state: getStepState(3, ['']),
          hidden: !isStepperShown(['customersSeats']),
          fields: ['customersSeats'],
          icon: {
            iconType: 'Communication',
            iconName: 'Ticket',
          },
        },
        {
          title: 'Finalize',
          description: 'Finalize booking',
          state: getStepState(4, ['']),
          hidden: !isStepperShown(['customersSeats']),
          fields: [''],
          icon: {
            iconType: 'Communication',
            iconName: 'Flag',
          },
        },
      ]
    return []
  }, [customer, event, getStepState, isEdit, isStepperShown])

  const handleNoCustonmers = useCallback((is: boolean) => {
    if (is) {
      setNoCustomer(false)
    } else {
      setNoCustomer(true)
    }
  }, [])

  const handleCustomerStepChange = useCallback(
    (step: number) => {
      formik.setFieldValue('customer', '')
      onStepChange(step)
    },
    [formik, onStepChange]
  )

  const handleCreateCustomer = useCallback(() => {
    onStepChange(2)
  }, [onStepChange])

  const stepPage = useMemo(() => {
    switch (currentStep) {
      case 0:
        if (customer) {
          return <BookingWizardEventStep formik={formik} disabledFields={disabledFields} />
        } else {
          return (
            <BookingWizardBulkConsolidatedCustomerStep
              formik={formik}
              disabledFields={disabledFields}
              event={event}
              isEdit={isEdit}
              onNoCustonmers={handleNoCustonmers}
              onStepChange={handleCustomerStepChange}
            />
          )
        }
      case 1:
        return (
          <BookingWizardCreateBulkConsolidatedCustomerStep
            formik={formik}
            handleCreateCustomer={handleCreateCustomer}
          />
        )
      case 2:
        return <BookingWizardBulkConsolidatedProductVenueStep formik={formik} event={event} />
      case 3:
        return <BookingWizardBulkConsolidatedFinalizeStep formik={formik} />
      case 4:
        return <BookingWizardBulkConsolidatedFinaleStep formik={formik} />
    }
  }, [
    currentStep,
    customer,
    disabledFields,
    event,
    formik,
    handleCreateCustomer,
    handleCustomerStepChange,
    handleNoCustonmers,
    isEdit,
  ])

  const handeleStepChange = useCallback(
    (page: number, type) => {
      if (page === 1 && formik.values.customers) {
        if (type === 'increament') {
          onStepChange(page + 1)
        }

        if (type === 'decreament') {
          onStepChange(page - 1)
        }
      } else onStepChange(page)
    },
    [formik.values.customers, onStepChange]
  )

  const hasSkip = useMemo(() => {
    if (currentStep === 2 || currentStep === 3) {
      return true
    }
    return false
  }, [currentStep])

  const hasFullfill = useMemo(() => {
    if (currentStep === 3) {
      return true
    }
    return false
  }, [currentStep])

  const checkIfFinalCustomerIsSkip = useMemo(() => {
    if (formik.values.bookingResults?.length && currentStep === 4) {
      const checkIfLastCustomerIsSkip =
        formik.values.bookingResults[formik.values.bookingResults.length - 1]
      if (checkIfLastCustomerIsSkip.isSkip) {
        return true
      }
    }
    return false
  }, [formik, currentStep])

  const isBookingStarted = useMemo(() => {
    const checkIfStarted = formik.values.bookingResults?.find(
      (customer) => customer.isFulfill || customer.isSkip
    )
    if (checkIfStarted && currentStep === 2) {
      return true
    }
    if (checkIfFinalCustomerIsSkip) {
      return true
    }
    return false
  }, [formik, currentStep, checkIfFinalCustomerIsSkip])

  return (
    <WizardControlProvider
      currentPage={currentStep}
      onPageChange={handeleStepChange}
      steps={steps}
      isHasPrevious={currentStep === 3 || currentStep === 0 ? false : true}
    >
      <div className='row'>
        <div className='col-12 position-relative d-flex justify-content-start px-16'>
          <WizardSteppers currentStep={currentStep} steps={steps} className='rs-stepper' />
        </div>
        <div className='col-12 position-relative mt-5 rs-top'>{stepPage}</div>
        <div className='col-12 position-relative rs-top px-10'>
          <WizardControls
            formik={formik}
            steps={steps}
            submitLabel='Done'
            nextLable={currentStep === 3 ? 'Book & Next' : 'Next'}
            isHasNext={currentStep === 0 ? noCustomer : currentStep === 1 ? false : true}
            isHasPrevious={isBookingStarted}
            onSubmit={currentStep === 4 ? onSubmit : undefined}
            isHasSkip={hasSkip}
            handleOnSkip={onSkip}
            hasFullfill={hasFullfill}
            isPreviousButton={currentStep === 4 ? false : true}
            nextClassName='ps-10 pe-20 position-relative'
            cornerNextClassName='btn-cut-conrner'
            isPortal={true}
          />
        </div>
      </div>
    </WizardControlProvider>
  )
}
