import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {
  OutletLoginForm,
  OutletLoginFormClasses,
  OutletLoginFormValues,
} from '../../../components/forms/OutletFormLogin/OutletLoginForm'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {useSafeStateUpdate} from '../../../components/hooks/useSafeStateUpdate'
import {UserModel} from '../../../models/UserModel'
import {GetOutletAuthUsers, OutletAuthLogin} from '../redux/OutletCRUD'
import {actions} from '../redux/OutletRedux'

export interface OutletLoginProps {
  className?: string
  classes?: OutletLoginFormClasses
}

export const OutletLogin = (props: OutletLoginProps) => {
  const [users, setUsers] = useState<UserModel[] | null>(null)
  const {pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()
  const dispatch = useDispatch()

  const resetUserList = useCallback(async () => {
    try {
      const {data} = await GetOutletAuthUsers()
      safeUpdate(() => {
        setUsers(data)
      })
    } catch (e) {
      pushError(e)
    }
  }, [pushError, safeUpdate])

  useOnMount(() => {
    resetUserList()
  })

  const handleLogin = useCallback(
    async (values: OutletLoginFormValues) => {
      const {data} = await OutletAuthLogin(values.username, values.password)
      dispatch(actions.auth.login(data.token))
    },
    [dispatch]
  )

  return <OutletLoginForm {...props} users={users} onSubmit={handleLogin} />
}
