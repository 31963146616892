import * as Yup from 'yup'
import {replace} from 'lodash'
import {MyPassSignupValues} from '../components/MyPassSignUp'
import {YMC_CLIENT_ID} from '../../../../config/env'

export const initialValues: MyPassSignupValues = {
  email: '',
  firstName: '',
  lastName: '',
  country: '',
  nationality: '',
  mobile: '',
  countryCode: ',',
  policy: false,
  offers: false,
}

export const SIGNUP_SCHEMA = Yup.object().shape({
  email: Yup.string().email().required('Email is required.'),
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(30, 'Maximum 30 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(30, 'Maximum 30 characters')
    .required('Last Name is required'),

  country: Yup.string().notOneOf(['']).required('Country is required'),
  nationality: Yup.string().notOneOf(['']).required('Nationality is required'),
  mobile: Yup.string().min(8, 'Minimum 8 symbols').required('Mobile is required'),
  policy: Yup.bool().oneOf([true], 'Policy and privacy is required'),
})

interface getSignupPayloadProps {
  values: MyPassSignupValues
}

interface FarahUserProps {
  Country: string
  GivenName: string
  Mobile: string
  Surname: string
  DisplayName: string
  SignInNames: [
    {
      Type: 'emailAddress'
      Value: string
    }
  ]
}

interface FarahExtensionPropertiesProps {
  extension_1b562d6df55b487aa607c2d4c0525216_Nationality: string // User Nationality
  extension_1b562d6df55b487aa607c2d4c0525216_NewsOffer: boolean // true/false - Yes I would like to recei
  extension_1b562d6df55b487aa607c2d4c0525216_TNC: boolean // true/false - I acknowledge that I have read
  extension_12848820100c4418925c0ff03b559194_CountryCode: string // User Country code
}

export interface MyPassSignUpPayload {
  user: FarahUserProps
  extensionProperties: FarahExtensionPropertiesProps
  client_id: string
}

export const getMyPassSignupPayload = ({values}: getSignupPayloadProps) => {
  const payload: MyPassSignUpPayload = {
    user: {
      Country: values.country,
      GivenName: values.firstName,
      Surname: values.lastName,
      Mobile: replace(values.mobile, values.countryCode, ''),
      DisplayName: `${values.firstName} ${values.lastName}`,
      SignInNames: [
        {
          Type: 'emailAddress',
          Value: values.email,
        },
      ],
    },
    extensionProperties: {
      extension_1b562d6df55b487aa607c2d4c0525216_Nationality: values.nationality,
      extension_1b562d6df55b487aa607c2d4c0525216_NewsOffer: values.offers,
      extension_1b562d6df55b487aa607c2d4c0525216_TNC: values.policy,
      extension_12848820100c4418925c0ff03b559194_CountryCode: values.countryCode,
    },
    client_id: YMC_CLIENT_ID ? YMC_CLIENT_ID : '26050af9-990b-483a-a4fd-381bf605098c',
  }

  return payload
}
