import {useMemo} from 'react'
import Moment from 'react-moment'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {DetailHeaderContent} from '../../../components/DetailHeader/DetailHeaderContent'
import {DetailHeaderTabList} from '../../../components/DetailHeader/DetailHeaderTabList'
import {AuthDetailHeaderTabProps} from '../../../components/DetailHeader/useAuthTabs'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {EventModel} from '../../../models/ems/EventModel'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {useCustomerAuth} from '../hooks/useCustomerAuth'

export interface PortalEventDetailTabsProps {
  event?: EventModel | null
}

interface RouteProps {
  customerCode?: string
  bookingCode?: string
}

export const PortalEventDetailTabs = ({event}: PortalEventDetailTabsProps) => {
  const auth = useCustomerAuth()
  const {staticUrls} = useAppConfig()
  const location = useLocation()
  const {
    params: {customerCode, bookingCode},
  } = useRouteMatch<RouteProps>()

  const src = useMemo(() => {
    if (event && event.emsFile) {
      const imageValue = new ImageInputValue(staticUrls.public, event.emsFile)
      return imageValue.url
    }
    return null
  }, [event, staticUrls.public])

  const allTabs = useMemo((): AuthDetailHeaderTabProps[] => {
    if (event) {
      const basePath = `/event/${event.code}`
      const tabs: AuthDetailHeaderTabProps[] = [
        {
          label: 'Overview',
          link: {
            to: `${basePath}/overview`,
          },
        },
      ]

      tabs.push({
        label: 'Bookings',
        link: {
          to: `${basePath}/booking`,
          exact: true,
        },
      })

      if (
        location.pathname.includes('booking-detail') &&
        !location.pathname.includes('tickets/customer') &&
        customerCode &&
        bookingCode
      ) {
        tabs.push({
          label: 'Bookings Details',
          link: {
            to: `${basePath}`,
          },
        })
      }

      tabs.push({
        label: 'Tickets',
        link: {
          to: `${basePath}/tickets`,
        },
      })

      if (auth?.getUser().type === 'reseller' ||
      (auth?.getUser().type === 'user' && auth?.getUser().userParent?.type === 'reseller')) {
        tabs.push({
          label: 'Sub-Account',
          link: {
            to: `${basePath}/reservation`,
            exact: true,
          },
        })

        if (location.pathname.includes('/reservation/new')) {
          tabs.push({
            label: 'Create Sub-Account',
            link: {
              to: `${basePath}/reservation/new`,
            },
          })
        }
        if (location.pathname.includes('/reservation/edit')) {
          tabs.push({
            label: 'Edit Reservation',
            link: {
              to: `${basePath}/reservation/edit`,
            },
          })
        }
      }

      tabs.push({
        label: 'Seat Manifest',
        link: {
          to: `${basePath}/seat-manifest`,
          exact: true,
        },
      })

      if (location.pathname.includes('/booking/new') && !location.pathname.includes('/bulk')) {
        tabs.push({
          label: 'Create Booking',
          link: {
            to: `${basePath}/booking/new`,
          },
        })
      }
      if (location.pathname.includes('/booking/edit')) {
        tabs.push({
          label: 'Edit Booking',
          link: {
            to: `${basePath}/booking/edit`,
          },
        })
      }

      // if (
      //   location.pathname.includes('/booking/new') &&
      //   !location.pathname.includes('/booking/new/bulk')
      // ) {
      //   tabs.push({
      //     label: 'Create Booking',
      //     link: {
      //       to: `${basePath}/booking/new`,
      //     },
      //   })
      // }
      // if (
      //   location.pathname.includes('/booking/edit') &&
      //   !location.pathname.includes('/booking/edit/bulk')
      // ) {
      //   tabs.push({
      //     label: 'Edit Booking',
      //     link: {
      //       to: `${basePath}/booking/edit`,

      //     },
      //   })
      // }

      if (location.pathname.includes('/booking/new/bulk')) {
        tabs.push({
          label: 'Create Bulk Booking',
          link: {
            to: `${basePath}/booking/new/bulk`,
            exact: true,
          },
        })
      }

      return tabs
    }
    return []
  }, [auth, bookingCode, customerCode, event, location.pathname])

  const headerContent = useMemo(() => {
    if (event) {
      return (
        <DetailHeaderContent
          description={event?.description}
          left={
            <>
              <div className='d-flex flex-wrap mb-2 pe-2'>
                <span className='d-flex align-items-center me-5'>
                  <KTSVG path='/media/icons/duotone/Map/Marker1.svg' className='svg-icon-4 me-1' />
                  {event?.venue?.name}
                </span>
              </div>
              <div className='d-flex flex-wrap mb-4 pe-2'>
                <span className='d-flex align-items-center text-muted me-5 mb-2'>
                  <KTSVG
                    path='/media/icons/duotone/Interface/Calendar.svg'
                    className='svg-icon-4 me-1'
                  />
                  <Moment format='MMMM DD, YYYY'>{event?.startedAt}</Moment>
                  <span className='mx-2'>-</span>
                  <Moment format='MMMM DD, YYYY'>{event?.endedAt}</Moment>
                </span>
              </div>
            </>
          }
          right={
            src && (
              <img
                style={{
                  height: '50px',
                }}
                className='img-fluid'
                src={src}
                alt={event?.name}
              />
            )
          }
        />
      )
    }
    return null
  }, [event, src])

  return (
    <>
      {headerContent}
      {/* <Paper  rounded> */}
      <div id='kt_header' className='px-3 mb-6'>
        <DetailHeaderTabList tabs={allTabs} />
      </div>
      {/* </Paper> */}
    </>
  )
}
