import {useCallback, useMemo, useState} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useSafeStateUpdate} from '../../../../components/hooks/useSafeStateUpdate'
import {useSeatMapState} from '../../../../components/hooks/useSeatMapState'
import {FilterSearchableSelectInput} from '../../../../components/inputs/SearchableSelect/FilterSearchableSelectInput'
import {
  ADMM_DANGER_COLOR,
  ADMM_DEFAULT_COLOR,
  getSeatDaysColor,
} from '../../../../components/inputs/SeatMapInput/hooks/useSeatHelper'
import {
  SeatMapBoxLegends,
  SeatMapBoxLegendsProps,
} from '../../../../components/inputs/SeatMapInput/SeatMapBoxLegends'
import {SeatMapSelectionInput} from '../../../../components/inputs/SeatMapInput/SeatMapSelectionInput'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {TreeSelect} from '../../../../components/inputs/TreeSelect/TreeSelect'

import {LocationModel} from '../../../../models/acs/LocationModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../models/FilterModel'
import {ApiTree} from '../../../../utils/Tree/ApiTree'
import {GetLocationByCode, GetLocationsByProductCode} from '../../../default/acs/redux/AcsCRUD'
import {useBookingFormData} from '../../components/wizards/BookingWizard/hook/useBookingFormData'
import {useCustomerAuth} from '../../hooks/useCustomerAuth'
import {GetAvailableSeatMaps} from '../../redux/CustomerPortalCRUD'

export interface PortalEventSeatMapProps {
  event?: EventModel | null
}

export const PortalEventSeatMap = ({event}: PortalEventSeatMapProps) => {
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(null)
  const [locations, setLocations] = useState<LocationModel[]>()
  const {
    resetState,
    columns,
    rows,
    extra,
    // disabled,
    hidden,
    isRightToLeft,
    isBottomToTop,
    seatMapSpacingX,
    seatsDays,
    active,
    occupied,
    setIsLoading,
    setAvailableSeates,
    setSeatsDays,
    setOccupied,
  } = useSeatMapState()
  const {push, pushError} = useAlerts()
  const auth = useCustomerAuth()
  const safeUpdate = useSafeStateUpdate()

  const {productSearchResults, refreshProductsList} = useBookingFormData({
    eventCode: event?.code,
  })

  const resetLocationList = useCallback(async () => {
    setLocations([])
    if (selectedProduct) {
      try {
        const {data} = await GetLocationsByProductCode(selectedProduct.code)
        if (data) setLocations(data)
      } catch (err: any) {
        pushError(err)
      }
    }
  }, [pushError, selectedProduct])

  useOnChange(selectedProduct, () => {
    if (selectedProduct) {
      resetLocationList()
    }
  })

  const locationItems = useMemo(() => {
    if (locations) {
      const locationTree = new ApiTree(locations)
      return locationTree.getTreeSelectItems()
    }
    return []
  }, [locations])

  const inputValues = useMemo(() => {
    return {selectedLocation, selectedProduct}
  }, [selectedProduct, selectedLocation])

  const handleProductChange = useCallback((value) => {
    setSelectedProduct(value)
  }, [])

  const resetLocationByCode = useCallback(
    async (locationCode: string, eventCode: string) => {
      const doneLoading = setIsLoading(locationCode)
      try {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetState(data.seatMap))
        }
      } catch (e) {
        pushError(e)
      } finally {
        if (selectedProduct && auth) {
          try {
            const {data: availableLocations} = await GetLocationsByProductCode(
              selectedProduct?.code
            )
            const found = availableLocations?.find((l) => l.code === locationCode)
            if (found) {
              const {
                data: {availableSeats, days, usedSeats},
              } = await GetAvailableSeatMaps(
                locationCode,
                selectedProduct?.code,
                eventCode,
                auth.getUser().code
              )
              setOccupied(new SeatMapValue(usedSeats))

              if (days && Object.keys(days).length > 0) {
                Object.keys(days).forEach((item) => {
                  days[item] = new SeatMapValue(days[item])
                })

                setSeatsDays(days)
              } else setSeatsDays(null)
              setAvailableSeates(new SeatMapValue(availableSeats))
            } else {
              resetState()
              setSeatsDays(null)
              setAvailableSeates(new SeatMapValue())
            }
          } catch (err: any) {
            pushError(err)
          }
        }
      }
      doneLoading()
    },
    [
      setIsLoading,
      push,
      safeUpdate,
      resetState,
      pushError,
      selectedProduct,
      auth,
      setOccupied,
      setSeatsDays,
      setAvailableSeates,
    ]
  )

  useOnChange(inputValues, async () => {
    // resetState()
    if (selectedLocation && selectedProduct && event) {
      resetLocationByCode(selectedLocation, event.code)
    }
  })

  const handleOnFilter = useCallback(
    (filter: FilterModel) => {
      const newFilter = {
        ...filter,
        filters: {
          ...filter?.filters,
          showHidden: true,
          isSeated: true,
        },
      }
      refreshProductsList(newFilter)
    },
    [refreshProductsList]
  )

  const occupiedSeats = useMemo(() => {
    return occupied
  }, [occupied])

  const disabledSeats = useMemo(() => {
    let activeSeats = new SeatMapValue()
    if (extra) {
      activeSeats = activeSeats.union(extra)
    }
    if (active) {
      activeSeats = activeSeats.union(active)
    }
    if (occupied) {
      activeSeats = activeSeats.union(occupied)
    }
    return new SeatMapValue(rows, columns).difference(activeSeats)
  }, [active, columns, extra, occupied, rows])

  const seatsDaysLegends = useMemo<SeatMapBoxLegendsProps[] | null>(() => {
    if (seatsDays) {
      return Object.keys(seatsDays).map((item) => {
        return {
          text: item,
          color: getSeatDaysColor(item),
        }
      })
    }

    return null
  }, [seatsDays])

  // const selectedLocationName = useMemo(() => {
  //   if (selectedLocation && locationItems) {
  //     const tree = ApiTree.fromTreeSelectItems(locationItems)
  //     const found = tree.findByCode(selectedLocation)
  //     if (found) {
  //       return found.name
  //     }
  //   }
  //   return 'Select a location'
  // }, [selectedLocation, locationItems])

  const handleLocationChange = useCallback((values: string[]) => {
    const value = values[0] || ''
    setSelectedLocation(value)
  }, [])

  return (
    <div className='d-flex flex-column flex-md-row  gap-3'>
      <div className='d-flex flex-column gap-5'>
        <div className='row'>
          <FilterSearchableSelectInput
            value={selectedProduct}
            itemMapper={itemMapper}
            searchResult={productSearchResults}
            placeholder='Select product'
            onChange={handleProductChange}
            onFilter={handleOnFilter}
            noMargin
            className='min-w-300px'
            label='Product'
          />
        </div>
        <div>
          {locationItems && (
            <TreeSelect
              // label={selectedLocationName}
              label='Location'
              className='max-w-300px'
              values={[selectedLocation]}
              radioName='locationCode'
              items={locationItems}
              onChange={handleLocationChange}
            />
          )}
        </div>
        <div>
          <div>
            <div className='mb-3 fw-bolder fs-6'>Reservation Status:</div>
            <SeatMapBoxLegends data={LEGENDS} />
          </div>
        </div>
        <div className='d-flex flex-row gap-15'>
          {seatsDays && seatsDaysLegends && (
            <div>
              <div className='mb-3 fw-bolder fs-6'>Booking By Days:</div>
              <SeatMapBoxLegends
                data={seatsDaysLegends}
                isCircledDaysBox={seatsDays ? true : false}
              />
            </div>
          )}
        </div>
        <div className='mt-5 fw-bolder fs-8 mx-5'>
          Seats containing coloured circles have been previously booked and distributed.
        </div>
      </div>

      <div
        className='flex-grow-1'
        style={{
          height: '70vh',
          width: '100vw',
        }}
      >
        <SeatMapSelectionInput
          spacingX={seatMapSpacingX}
          onLocationChange={setSelectedLocation}
          columns={columns}
          rows={rows}
          occupied={occupiedSeats}
          extra={extra}
          disabled={disabledSeats}
          hidden={hidden}
          isRightToLeft={isRightToLeft}
          isBottomToTop={isBottomToTop}
          seatsDays={seatsDays}
          isCircledDaysBox={seatsDays ? true : false}
          isShowNoneFilledDays={seatsDays ? false : false}
          isPortal={true}
        />
      </div>
    </div>
  )
}

const itemMapper = (data: ProductModel): SelectInputItem => {
  return {
    label: data.name || '',
    value: data.code || '',
  }
}

const LEGENDS: SeatMapBoxLegendsProps[] = [
  {text: 'Available', color: ADMM_DEFAULT_COLOR, width: 20, height: 20},
  {text: 'Booked', color: ADMM_DANGER_COLOR, width: 20, height: 20},
]
