import {useCallback, useRef, useState} from 'react'
import {FilterModel} from '../../models/FilterModel'
import {useDebounce} from './useDebounce'
import {useLoadingState} from './useLoadingState'

export interface UseInfiniteScrollContainerOptions<T> {
  total?: number
  onFetch?: (filter: FilterModel) => Promise<T[] | void>
  initialFilter?: FilterModel
  bottomToTop?: boolean
}

export const useInfiniteScrollContainer = <ContainerEl extends HTMLElement, T>({
  total,
  onFetch,
  initialFilter = INITIAL_FILTER,
  bottomToTop,
}: UseInfiniteScrollContainerOptions<T>) => {
  const {setIsLoading, isLoading} = useLoadingState()
  const [items, setItems] = useState<T[]>([])
  const initialFilterRef = useRef(initialFilter)
  const [filter, setFilter] = useState<FilterModel>(initialFilterRef.current)
  const scrollDebounce = useDebounce(50)

  const appendData = useCallback((data: T[]) => {
    setItems((previousData) => {
      return [...previousData, ...data]
    })
  }, [])

  const refetch = useCallback(
    async (filter: FilterModel) => {
      setFilter(filter)
      const doneLoading = setIsLoading(String(filter.page || 1))
      onFetch?.(filter)
        .then((data) => {
          if (data) {
            appendData(data)
          }
        })
        ?.finally(() => {
          doneLoading()
        })
    },
    [appendData, onFetch, setIsLoading]
  )

  const handleOnScroll = useCallback(
    (e: React.UIEvent<ContainerEl, UIEvent>) => {
      const {scrollTop, clientHeight, scrollHeight} = e.target as ContainerEl
      let percentScrolled = scrollTop / (scrollHeight - clientHeight)
      if (bottomToTop) {
        percentScrolled = Math.abs(percentScrolled)
      }
      if (percentScrolled > SCROLL_REPOPULATE_PERCENT_THRESHOLD) {
        if (filter.limit && filter.page && !isLoading) {
          if (total === undefined || filter.limit * filter.page < total) {
            const newFilter = {
              ...filter,
              page: filter.page + 1,
              limit: filter.limit,
            }
            refetch(newFilter)
          }
        }
      }
    },
    [bottomToTop, filter, isLoading, refetch, total]
  )

  const resetData = useCallback(() => {
    setItems([])
    setFilter(INITIAL_FILTER)
  }, [])

  const setAdvancedFilters = useCallback(
    (filters: Record<string, any>) => {
      const newFilters = {
        ...initialFilterRef.current,
        filters: filters,
      }
      setItems([])
      refetch(newFilters)
    },
    [refetch]
  )

  const refresh = useCallback(() => {
    const doneLoading = setIsLoading(String(filter.page || 1))
    onFetch?.(filter)
      .then((data) => {
        if (data) {
          setItems(data)
        }
      })
      ?.finally(() => {
        doneLoading()
      })
  }, [filter, onFetch, setIsLoading])

  const handleScrollDebounced = useCallback(
    (e: React.UIEvent<ContainerEl, UIEvent>) => {
      scrollDebounce(() => {
        handleOnScroll(e)
      })
    },
    [handleOnScroll, scrollDebounce]
  )

  return {
    handleOnScroll: handleScrollDebounced,
    resetData,
    items,
    setAdvancedFilters,
    refresh,
  }
}

const SCROLL_REPOPULATE_PERCENT_THRESHOLD = 0.8

const INITIAL_FILTER = {
  limit: 20,
  page: 1,
}
