import {FC, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {FilterModel} from '../../../models/FilterModel'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {EventTable} from '../components/tables/EventTable'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {actions} from '../redux/CustomerPortalRedux'
const PortalEventPage: FC = () => {
  const {setFilter} = useEntityFilter('customer-portal-event')
  const dispatch = useDispatch()

  const refreshData = useCallback(() => {
    return dispatch(actions.events.search())
  }, [dispatch])

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshData()
    },
    [refreshData, setFilter]
  )

  const events = useRootStateSelector(({customerPortal}) => customerPortal.events)
  const removeColumns = ['slug', 'description', 'type', 'isRfid', 'isClosed', 'isDefault']
  return (
    <>
      <Toolbar title='Events'></Toolbar>
      <EventTable onFilter={onFilterHandler} data={events} removeColumns={removeColumns} />
    </>
  )
}

export default PortalEventPage
