import axios from 'axios'
import {UserModel} from '../../../../models/UserModel'

const AUTH_LOGIN_URL = `/auth/login`
const AUTH_VERIFY_TOKEN = `/auth/verify-token`
const AUTH_RESET_PASSWORD = `/auth/reset`
const REQUEST_PASSWORD_URL = `/auth/forgot-password`

export function login(username: string, password: string) {
  return axios.post(AUTH_LOGIN_URL, {username, password})
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  return axios.get<UserModel>(AUTH_VERIFY_TOKEN)
}

export const resetPassword = (newPassword: string) => {
  return axios.post(AUTH_RESET_PASSWORD, {password: newPassword})
}

export const validateHash = (hash: string) => axios.post('/auth/validate-hash', {hash})

export const validateMfa = (mfa: string, authToken: string) =>
  axios.post(
    '/auth/validate-mfa',
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

export const loginWithHash = (username: string, password: string, hash: string) =>
  axios.post('/auth/login-hash', {username, password, hash})
