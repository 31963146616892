import {useCallback} from 'react'
import {Sidebar} from '../../../../../_metronic/layout/foh/Sidebar'
import {BigButton} from '../../../../components/inputs/BigButton'
import {useModalState} from '../../../../components/modals/useModalState'
import {OutletOrderType} from '../../../../models/fnb/OutletModel'
import {OrderTypeSelectModal} from '../modals/OrderTypeSelectModal'

export interface OrderActionSidebarProps {
  orderTypes: OutletOrderType[]
  onNewOrder: (orderType: OutletOrderType) => void
  onViewOrdersClick: () => void
  onDashboardClick: () => void
  disableOrdersButton?: boolean
  disableNewOrderButton?: boolean
  disableDashboardButton?: boolean
}

export const OrderActionSidebar = ({
  onNewOrder,
  onViewOrdersClick,
  onDashboardClick,
  orderTypes,
  disableNewOrderButton,
  disableDashboardButton,
  disableOrdersButton,
}: OrderActionSidebarProps) => {
  const {hide, isOpen, open} = useModalState()

  const handleOrderTypeselect = useCallback(
    (orderType: OutletOrderType) => {
      onNewOrder(orderType)
      hide()
    },
    [hide, onNewOrder]
  )

  const handleNewOrder = useCallback(() => {
    if (orderTypes.length > 1) {
      open()
    } else if (orderTypes[0]) {
      onNewOrder(orderTypes[0])
    }
  }, [onNewOrder, open, orderTypes])

  return (
    <Sidebar className='d-flex flex-column justify-content-between'>
      <div>
        <BigButton
          disabled={disableNewOrderButton}
          onClick={handleNewOrder}
          className='w-100 mb-5 mb-10-lg'
          variant='primary'
        >
          New Order
        </BigButton>
        <BigButton
          disabled={disableOrdersButton}
          onClick={onViewOrdersClick}
          className='w-100 mb-10-lg'
          variant='primary'
        >
          View Orders
        </BigButton>
      </div>
      <BigButton
        disabled={disableDashboardButton}
        onClick={onDashboardClick}
        className='w-100 mb-10-lg'
        variant='primary'
      >
        Dashboard
      </BigButton>
      <OrderTypeSelectModal
        orderTypes={orderTypes}
        open={isOpen}
        onClose={hide}
        onSelect={handleOrderTypeselect}
      />
    </Sidebar>
  )
}
