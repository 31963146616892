import {useMemo} from 'react'
import {
  BarChartDatum,
  HorizontalBarChart,
} from '../../../../../../components/charts/HorizontalBarChart/HorizontalBarChart'
import {WidgetF09Model} from '../../../../../../models/fnb/WidgetModel'
import {OutletHourlyRevenueTooltip} from './OutletHourlyRevenueTooltip'
import {formatMoment, getMoment} from './utils'

export interface OutletHourlyRevenueProps {
  datum?: WidgetF09Model[]
}

export const OutletHourlyRevenue = ({datum}: OutletHourlyRevenueProps) => {
  const chartDatum = useMemo((): BarChartDatum[] => {
    if (datum) {
      return datum.map<BarChartDatum>((d) => {
        const date = getMoment(d.time)
        return {
          key: d.time,
          label: formatMoment(date),
          value: d.total,
        }
      })
    }

    return []
  }, [datum])

  return (
    <HorizontalBarChart
      xLabel='Time'
      yLabel='Sales'
      datum={chartDatum}
      tooltip={<OutletHourlyRevenueTooltip datum={datum} />}
    />
  )
}
