import {ApplicationModel} from '../../models/ApplicationModel'
import {UserModel} from '../../models/UserModel'
import {Application, ApplicationCode, FeatureApplicationMap} from './Application'
import {Feature, FeatureCode} from './Feature'

export class Auth {
  private applications = new Map<string, Application>()

  constructor(private user: UserModel) {
    user.role?.applications?.forEach((application) => {
      this.addApplication(application)
    })
    user.organization?.applications?.forEach((application) => {
      this.addApplication(application)
    })
  }

  public getUser() {
    return this.user
  }

  public isFirstLogin() {
    return this.user.isFirstLogin
  }

  public isAdmin() {
    return this.user.isAdmin
  }

  public addApplication(application: ApplicationModel): void {
    const newApplication = new Application(application)
    this.applications.set(application.code, newApplication)
  }

  public getApplication(applicationCode: ApplicationCode): Application | undefined {
    return this.applications.get(applicationCode)
  }

  public getApplicationOrFail(applicationCode: ApplicationCode): Application {
    const application = this.applications.get(applicationCode)
    if (!application) {
      throw new Error(`Cannot find application ${applicationCode}`)
    }
    return application
  }

  public getFeature(featureCode: FeatureCode) {
    const iterator = this.applications.entries()
    let current = iterator.next()
    while (current.value) {
      const application: Application = current.value[1]
      const feature = application.getFeature(featureCode)
      if (feature) {
        return feature
      }
      current = iterator.next()
    }
  }

  public getFeatureOrFail(featureCode: FeatureCode) {
    const feature = this.getFeature(featureCode)
    if (!feature) {
      throw new Error(`Feature ${featureCode} is not found`)
    }
    return feature
  }

  public getAllApplicationFeature(): Feature[] {
    const allRoleFeatures: Feature[] = []
    this.applications.forEach((application) => {
      allRoleFeatures.push(...application.getAllFeatures())
    })
    return allRoleFeatures
  }

  public canReadFeature(featureCode: FeatureCode): boolean {
    try {
      if (this.isAdmin()) {
        const featureApplicationCode = FeatureApplicationMap[featureCode]
        return Boolean(this.getApplicationOrFail(featureApplicationCode))
      }

      return this.getFeatureOrFail(featureCode).canRead()
    } catch (e) {
      return false
    }
  }

  public canManageFeature(featureCode: FeatureCode): boolean {
    if (this.isAdmin()) {
      return this.canReadFeature(featureCode)
    }
    try {
      return this.getFeatureOrFail(featureCode).canManage()
    } catch (e) {
      return false
    }
  }
}
