import clsx from 'clsx'
import {CUSTOMER_TICKET_MAX_SHARE} from '../../../../../../config/env'
import {ButtonSize} from '../../../../../components/inputs/Button'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {TicketWaiverModel} from '../../../../../models/digital-waiver/TicketWaiverModel'
import {idExtractor} from '../../../../../utils/idExtractor'

export interface TicketTableSelectionActionsProps {
  items: TicketWaiverModel[]
  onDelegate?: (data: TicketWaiverModel[]) => void
  selected: TableRowId[]
  isLoading?: boolean
  size?: ButtonSize
  className?: string
}

export const TicketTableSelectionActions = ({
  items,
  onDelegate,
  selected,
  isLoading,
  size,
  className,
}: TicketTableSelectionActionsProps) => {
  return (
    <>
      {onDelegate && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onDelegate}
          selected={selected}
          items={items}
          variant='primary'
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Delegate
        </TableSelectionAction>
      )}
    </>
  )
}
