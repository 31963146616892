import currency from 'currency.js'

export abstract class PriceTag {
  constructor(
    protected rawPrice: number,
    protected currency: string,
    protected percentVat: number = 0
  ) {}

  public static roundOffMoney(value: number) {
    return currency((value * 100) / 100).value
  }

  public abstract getVatInclusivePrice(multiplier?: number): number

  public abstract getVatExclusivePrice(multiplier?: number): number

  public abstract getVatPrice(multiplier?: number): number

  public getVatPercent() {
    return this.percentVat
  }

  public getRawPrice() {
    return this.rawPrice
  }

  public getCurrency() {
    return this.currency
  }

  public getVatInclusivePriceRoundedOff() {
    return PriceTag.roundOffMoney(this.getVatInclusivePrice())
  }

  public getVatExclusivePriceRoundedOff() {
    return PriceTag.roundOffMoney(this.getVatExclusivePrice())
  }
}
