import clsx from 'clsx'
import {ChangeEvent, CSSProperties, useCallback, useState} from 'react'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {TextInput} from '../../../../../components/inputs'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'
import {HorizontalDivider} from '../../../../../components/utils/HorizontalDivider'
import {Validators} from '../../../../../utils/Validators'
interface FilterSeatNumberFromToProps {
  from?: number
  to?: number
  setSeatNumberFrom: (value: number | undefined) => void
  setSeatNumberTo: (value: number | undefined) => void
  className?: string
  min?: number
  max?: number
  style?: CSSProperties
  inputWrapperClassName?: string
}
export const FilterSeatNumberFromTo = ({
  from,
  setSeatNumberFrom,
  to,
  setSeatNumberTo,
  className,
  min,
  max,
  style,
  inputWrapperClassName,
}: FilterSeatNumberFromToProps) => {
  const [displayedFromValue, setDisplayedFromValue] = useState(String(from))
  const [displayedToValue, setDisplayedToValue] = useState(String(to))

  useOnChange(from, () => {
    if (!from || !isValidNumber(from.toString())) setDisplayedFromValue('')
  })

  useOnChange(to, () => {
    if (!to || !isValidNumber(to.toString())) setDisplayedToValue('')
  })

  const isValidNumber = useCallback(
    (value: string) => {
      const isNumber = Validators.FRACTIONAL_NUMBER_REGEX.test(value)
      if (isNumber) {
        const parsedValue = parseInt(value)
        if (min !== undefined && parsedValue <= min) {
          return false
        }
        if (max !== undefined && parsedValue >= max) {
          return false
        }
        return true
      }
      return false
    },
    [max, min]
  )

  const getValidNumber = useCallback(
    (value: string): number | null => {
      const isNumber = Validators.FRACTIONAL_NUMBER_REGEX.test(value)
      if (isNumber) {
        const parsedValue = parseInt(value)
        if (min !== undefined && parsedValue < min) {
          return min
        }
        if (max !== undefined && parsedValue > max) {
          return max
        }
        return parsedValue
      }
      return null
    },
    [max, min]
  )

  const handleFromOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (isValidNumber(value)) {
        const parsedValue = getValidNumber(value)
        if (parsedValue !== null) {
          setSeatNumberFrom?.(parsedValue)
        }
      } else setSeatNumberFrom?.(isValidNumber(value) ? parseInt(value) : undefined)
      setDisplayedFromValue(value)
    },
    [getValidNumber, isValidNumber, setSeatNumberFrom]
  )

  const handleFromToChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (isValidNumber(value)) {
        const parsedValue = getValidNumber(value)
        if (parsedValue !== null) {
          setSeatNumberTo?.(parsedValue)
        }
      } else setSeatNumberTo?.(isValidNumber(value) ? parseInt(value) : undefined)
      setDisplayedToValue(value)
    },
    [getValidNumber, isValidNumber, setSeatNumberTo]
  )

  return (
    <>
      <label className='text-uppercase form-label'>Seat No</label>
      <div
        className='d-flex flex-row'
        style={{
          gap: '0.4rem',
        }}
      >
        <TextInput
          inputWrapperClassName={inputWrapperClassName}
          label=''
          className={clsx('filter-table-search-input', className)}
          inputWrapperStyle={style}
          noMargin
          type='number'
          placeholder='From'
          value={displayedFromValue}
          min={1}
          onChange={handleFromOnChange}
          endAdornment={
            <MetronicIcon size='md' color='white' iconType='Home' iconName='ArmChair' />
          }
        />

        <HorizontalDivider />

        <TextInput
          label=''
          className={clsx('filter-table-search-input', className)}
          noMargin
          placeholder='To'
          value={displayedToValue}
          min={1}
          type='number'
          onChange={handleFromToChange}
          endAdornment={
            <MetronicIcon size='md' color='white' iconType='Home' iconName='ArmChair' />
          }
          inputWrapperClassName={inputWrapperClassName}
          inputWrapperStyle={style}
        />
      </div>
    </>
  )
}
