import {useCallback,  useState} from 'react'
// import moment from 'moment'
import {GetFandBWidgetData} from '../redux/EmsCRUD'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'
import {WidgetModel} from '../../../../models/fnb/WidgetModel'


export interface useEventFandBDashboardDataOptions {
    widgets?: (keyof WidgetModel)[]
    date: Date | null
    eventCode?: string
  }

export const useEventFnBDashboardWidget = ({
  date,
  eventCode,
  widgets,
}: useEventFandBDashboardDataOptions) => {

    const [widgetData, setWidgetData] = useState<WidgetModel>({})
    const {isLoading: isDashboardLoading, managePromise} = usePromiseManager()
  
  
    const resetWidgetData = useCallback(async () => {
      if (eventCode && date) {
        try {
          const {data} = await managePromise(
            'dashboard',
            GetFandBWidgetData({
              widgets: [...(widgets || [])],
              eventCode,
              date: date.toISOString(),
            })
          )
          setWidgetData(data)
        } catch (e) {
          if (e instanceof CancelledPromiseError) {
            // Ignore cancelled promise.
          }
        }
      }
    }, [eventCode, date, managePromise, widgets])
  
  
    return {
      data: widgetData,
      isLoading: isDashboardLoading,
    
      resetWidgetData,
    }
}