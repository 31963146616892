import {useCallback, useState} from 'react'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {FilterModel} from '../../../models/FilterModel'
import {CustomerTable} from '../../customer-delegate/components/tables/CustomerTable/CustomerTable'
// import {useDispatch} from 'react-redux'
// import {actions} from '../redux/CustomerPortalRedux'
import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {GetCustomerByCode} from '../redux/CustomerDelegateCRUD'
import {
  CustomerFormEdit,
  CustomerFormCreate,
} from '../../customer-delegate/components/DrawerForm/CustomerFormDrawer'
import {CustomerWithParentModel} from '../../../models/CustomerModel'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {useAlerts} from '../../../components/alerts/useAlerts'

const CustomerPage = () => {
  // const dispatch = useDispatch()
  const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const [customerToEdit, setCustomerToEdit] = useState<CustomerWithParentModel>()
  const [drawerEdit, setDrawerEdit] = useDrawerRef()
  const customers = useRootStateSelector((state) => state.customerPortal.customers)
  const {pushError} = useAlerts()
  const {setFilter} = useEntityFilter('customer-portal-customer')

  const refreshData = useCallback(() => {
    // dispatch(actions.customers.search())
  }, [])

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshData()
    },
    [refreshData, setFilter]
  )

  const handleOnEditCustomer = useCallback(
    async (event) => {
      try {
        const {data} = await GetCustomerByCode(event.code)
        setCustomerToEdit(data)
        if (drawerEdit) {
          drawerEdit.show()
        }
      } catch (e) {
        pushError(e)
      }
    },
    [drawerEdit, pushError]
  )

  return (
    <>
      <Toolbar title='Customers'></Toolbar>

      <CustomerTable
        onFilter={onFilterHandler}
        onDeleteSuccess={refreshData}
        onEdit={handleOnEditCustomer}
        data={customers}
        onNewCustomerClick={drawerCreate?.show}
      />

      <CustomerFormEdit
        customerToEdit={customerToEdit}
        drawerRef={setDrawerEdit}
        onChange={refreshData}
      />
      <CustomerFormCreate drawerRef={setDrawerCreate} onChange={refreshData} />
    </>
  )
}

export {CustomerPage}
