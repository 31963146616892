import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useMemo, useState} from 'react'
import {PasswordInput} from '../inputs/PasswordInput'

export interface PasswordPinResetFormValues {
  newPassword: string
  confirmPassword: string
  pin: string
  confirmPin: string
}

export interface PasswordPinResetFormProps {
  onSubmit: (values: PasswordPinResetFormValues) => void | Promise<void>
  className?: string
}

const NUMBER_REGEX = /\d+/

const passwordPinResetFormValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required.'),
  confirmPassword: Yup.string().test(
    'is-password-match',
    'Password does not match',
    (value, context) => {
      const formValues = context.parent as PasswordPinResetFormValues
      if (formValues.newPassword) {
        return formValues.newPassword === value
      }
      return false
    }
  ),
  pin: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(4, 'Maximum 4 characters')
    .required('PIN is required'),
  confirmPin: Yup.string()
    .test('is-pin-match', 'PIN does not match', (value, context) => {
      const formValues = context.parent as PasswordPinResetFormValues
      if (formValues.pin) {
        return formValues.pin === value
      }
      return false
    })
    .test('is-pin-number', 'Use numbers only', (value) => {
      return NUMBER_REGEX.test(value || '')
    }),
})

const INITIAL_VALUES: PasswordPinResetFormValues = {
  confirmPassword: '',
  newPassword: '',
  pin: '',
  confirmPin: '',
}

export const PasswordPinResetForm = ({onSubmit, className}: PasswordPinResetFormProps) => {
  const [hasErrors, setHasErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formik = useFormik<PasswordPinResetFormValues>({
    initialValues: INITIAL_VALUES,
    validationSchema: passwordPinResetFormValidationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await onSubmit(values)
      } catch (e) {
        setHasErrors(true)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const errorMessage = useMemo(() => {
    if (hasErrors) {
      return 'Sorry, looks like there are some errors detected, please try again.'
    }
    return null
  }, [hasErrors])

  return (
    <>
      <form className={className} noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Please reset your password.</h1>
          <div className='text-gray-400 fw-bold fs-4'>Enter a new password.</div>
        </div>
        {!isLoading && errorMessage && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        <PasswordInput
          isTouched={formik.touched.newPassword}
          errorMessage={formik.errors.newPassword}
          fullWidth
          label='New Password'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
        />
        <PasswordInput
          isTouched={formik.touched.confirmPassword}
          errorMessage={formik.errors.confirmPassword}
          fullWidth
          label='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
        />
        <div
          className='d-flex'
          style={{
            width: '25rem',
          }}
        >
          <PasswordInput
            isTouched={formik.touched.pin}
            errorMessage={formik.errors.pin}
            className='me-1 w-50'
            label='New PIN'
            autoComplete='off'
            {...formik.getFieldProps('pin')}
          />
          <PasswordInput
            className='ms-1 w-50'
            isTouched={formik.touched.confirmPin}
            errorMessage={formik.errors.confirmPin}
            type='password'
            label='Confirm PIN'
            autoComplete='off'
            {...formik.getFieldProps('confirmPin')}
          />
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            disabled={isLoading}
            type='submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            {!isLoading && <span className='indicator-label'>Continue</span>}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/logout'>
            <button
              type='button'
              id='kt_logout_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting}
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </>
  )
}
