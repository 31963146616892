import clsx from 'clsx'
import {Button} from './Button'

export interface BasketButtonProps {
  className?: string
  onClick?: () => void
  count: number
  total: number
  currency: string
}
export const BasketButton = ({count, currency, total, className, onClick}: BasketButtonProps) => {
  if (!count) {
    return null
  }

  return (
    <Button
      className={clsx(`py-4 px-8 min-h-75px fs-1`, className)}
      variant='success'
      onClick={onClick}
    >
      <div className='container d-flex justify-content-between align-items-center'>
        <span className='bg-light p-2 min-w-50px'>{count}</span>
        <span>View Basket</span>
        <span>
          {currency} {total}
        </span>
      </div>
    </Button>
  )
}
