import {LayoutType} from './config'

const DEFAULT_LANG = 'en'
const OUTLET_CODE = process.env.REACT_APP_OUTLET_CODE
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY
const REACT_APP_MAP_DEFAULT_LAT_LNG = process.env.REACT_APP_MAP_DEFAULT_LAT_LNG
const GOOGLE_MAP_ID = process.env.REACT_APP_GMAPS_MAP_ID
const ORGANIZATION_CODE = process.env.REACT_APP_ORGANIZATION_CODE
const EVENT_CODE = process.env.REACT_APP_EVENT_CODE
const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL
const YMC_CLIENT_ID = process.env.REACT_APP_YMC_CLIENT_ID
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const REACT_APP_SCANNER_API_URL = process.env.REACT_APP_SCANNER_API_URL
const REACT_APP_PDF_DOWNLOAD_URL = process.env.REACT_APP_PDF_DOWNLOAD_URL
const IFRAME_GOOGLE = process.env.REACT_APP_IFRAME_GOOGLE
const IFRAME_FACEBOOK = process.env.REACT_APP_IFRAME_FACEBOOK
const IFRAME_LOGIN_URL = process.env.REACT_APP_IFRAME_LOGIN
const MYPASS_URL = process.env.REACT_APP_MYPASS_URL
const WIDGET_TIMER = process.env.REACT_APP_WIDGET_TIMER
const IS_QUICK_WAIVER = process.env.REACT_APP_IS_QUICK_WAIVER
const REACT_APP_FNB_VAT = Number(process.env.REACT_APP_FNB_VAT || 0)
const REACT_APP_INCLUSIVE_VAT = Number(process.env.REACT_APP_INCLUSIVE_VAT || 0)
const DEFAULT_LAYOUT_TYPE: LayoutType = 'default'
const WSS_URL = process.env.REACT_APP_WSS_URL
const WSS_EMS_URL = process.env.REACT_APP_EMS_WSS_URL
const QUICK_WAIVER_LINK = process.env.REACT_APP_QUICK_WAIVER_LINK
const MY_PASS_LINK = process.env.REACT_APP_MY_PASS_LINK
const TIMEZONE_OFFSET = process.env.REACT_APP_TIMEZONE_OFFSET
const CUSTOMER_TICKET_MAX_SHARE = process.env.REACT_APP_CUSTOMER_PORTAL_TICKET_SHARE_MAX
  ? parseInt(process.env.REACT_APP_CUSTOMER_PORTAL_TICKET_SHARE_MAX)
  : undefined
const PAYFORT_TOKENIZATION_URL = process.env.REACT_APP_PAYFORT_TOKENIZATION_URL
const AVATAR_WIDTH = process.env.REACT_APP_AVATAR_WIDTH
  ? parseInt(process.env.REACT_APP_AVATAR_WIDTH)
  : 250
const AVATAR_HEIGHT = process.env.REACT_APP_AVATAR_HEIGHT
  ? parseInt(process.env.REACT_APP_AVATAR_HEIGHT)
  : 250

const URL_OUTLET_CODE_REGEX = /(sandbox-|uat-)?([A-Z0-9]+)-([A-Z0-9]+).+/i
const CHAT_SOCKET_NAME = process.env.REACT_APP_CHAT_SOCKET_NAME
const POLL_SOCKET_NAME = process.env.REACT_APP_POLL_SOCKET_NAME
const DEVICE_ID = process.env.DEVICE_ID
const STATIC_PRIVATE_FILE_URL = process.env.REACT_APP_STATIC_PRIVATE_FILE_URL
const STATIC_PUBLIC_FILE_URL = process.env.REACT_APP_STATIC_PUBLIC_FILE_URL
const CUSTOMER_PORTAL_CSV_TEMPLATE_URL = process.env.REACT_APP_CUSTOMER_PORTAL_CSV_TEMPLATE_URL
const CUSTOMER_PORTAL_USER_GUIDE = process.env.REACT_APP_CUSTOMER_PORTAL_USER_GUIDE

if (!STATIC_PRIVATE_FILE_URL) {
  throw new Error('Undefined STATIC_PRIVATE_FILE_URL')
}

if (!STATIC_PUBLIC_FILE_URL) {
  throw new Error('Undefined STATIC_PUBLIC_FILE_URL')
}

if (!REACT_APP_API_URL) {
  throw new Error('Undefined REACT_APP_API_URL')
}
if (!REACT_APP_SCANNER_API_URL) {
  throw new Error('Undefined REACT_APP_SCANNER_API_URL')
}
if (!REACT_APP_PDF_DOWNLOAD_URL) {
  throw new Error('Undefined REACT_APP_PDF_DOWNLOAD_URL')
}

let MAP_DEFAULT_LAT_LNG: [number, number] = [0, 0]

const LAT_LNG_ENV_REGEX = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?/

if (REACT_APP_MAP_DEFAULT_LAT_LNG && LAT_LNG_ENV_REGEX.test(REACT_APP_MAP_DEFAULT_LAT_LNG)) {
  MAP_DEFAULT_LAT_LNG = REACT_APP_MAP_DEFAULT_LAT_LNG.split(',').map((i) => Number(i)) as [
    number,
    number
  ]
}

const LAYOUT_TYPE: LayoutType = process.env.REACT_APP_LAYOUT_TYPE || DEFAULT_LAYOUT_TYPE
const API_URL = REACT_APP_API_URL
const SCANNER_API_URL = REACT_APP_SCANNER_API_URL
const PDF_API_URL = REACT_APP_PDF_DOWNLOAD_URL

if (LAYOUT_TYPE === 'outlet-digital-menu' && !PAYFORT_TOKENIZATION_URL) {
  throw new Error('Undefined PAYFORT_TOKENIZATION_URL')
}

if (LAYOUT_TYPE === 'eva' && !POLL_SOCKET_NAME) {
  throw new Error('Undefined REACT_APP_POLL_SOCKET_NAME')
}

const getUrlData = () => {
  const subdomain = window.location.hostname.split('.').slice(0, -2).join('.')
  let outletCode = OUTLET_CODE
  let organizationCode = ORGANIZATION_CODE
  let eventCode = EVENT_CODE
  let deviceId = DEVICE_ID

  if (LAYOUT_TYPE === 'outlet') {
    const match = subdomain.match(URL_OUTLET_CODE_REGEX)
    if (match) {
      if (!OUTLET_CODE) {
        outletCode = match[3]
      }
      if (!ORGANIZATION_CODE) {
        organizationCode = match[2]
      }
    }
    if (!outletCode) {
      throw new Error('Undefined REACT_APP_OUTLET_CODE')
    }
    if (!organizationCode) {
      throw new Error('Undefined REACT_APP_ORGANIZATION_CODE')
    }
  }

  if (LAYOUT_TYPE === 'outlet-digital-menu') {
    const match = subdomain.match(URL_OUTLET_CODE_REGEX)
    if (match) {
      if (!EVENT_CODE) {
        eventCode = match[2]
      }
    }
    if (!eventCode) {
      throw new Error('Undefined REACT_APP_EVENT_CODE')
    }
  }

  return {outletCode, organizationCode, eventCode, deviceId}
}

const VAT = REACT_APP_FNB_VAT
const INCLUSIVE_VAT = REACT_APP_INCLUSIVE_VAT
const APP_CONFIG_SITE_URL_CODE = 'SITEURL'
const APP_CONFIG_SITE_SETTINGS_CODE = 'SITESETTINGS'
const APP_CONFIG_CODES = [APP_CONFIG_SITE_URL_CODE, APP_CONFIG_SITE_SETTINGS_CODE]

export {
  LAYOUT_TYPE,
  API_URL,
  SCANNER_API_URL,
  PDF_API_URL,
  VAT,
  EVENT_CODE,
  WSS_URL,
  WIDGET_TIMER,
  ORGANIZATION_CODE,
  CUSTOMER_TICKET_MAX_SHARE,
  getUrlData,
  PAYFORT_TOKENIZATION_URL,
  AVATAR_HEIGHT,
  AVATAR_WIDTH,
  CHAT_SOCKET_NAME,
  POLL_SOCKET_NAME,
  APP_CONFIG_SITE_URL_CODE,
  APP_CONFIG_SITE_SETTINGS_CODE,
  APP_CONFIG_CODES,
  STATIC_PUBLIC_FILE_URL,
  STATIC_PRIVATE_FILE_URL,
  GOOGLE_MAPS_API_KEY,
  MAP_DEFAULT_LAT_LNG,
  GOOGLE_MAP_ID,
  REACT_APP_API_URL,
  REACT_APP_SCANNER_API_URL,
  REACT_APP_PDF_DOWNLOAD_URL,
  IFRAME_FACEBOOK,
  IFRAME_GOOGLE,
  IFRAME_LOGIN_URL,
  LOGOUT_URL,
  MYPASS_URL,
  YMC_CLIENT_ID,
  WSS_EMS_URL,
  IS_QUICK_WAIVER,
  QUICK_WAIVER_LINK,
  MY_PASS_LINK,
  DEFAULT_LANG,
  CUSTOMER_PORTAL_CSV_TEMPLATE_URL,
  CUSTOMER_PORTAL_USER_GUIDE,
  TIMEZONE_OFFSET,
  INCLUSIVE_VAT,
}
