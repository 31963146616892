import {TextInput} from '../../../../components/inputs'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {Button} from '../../../../components/inputs/Button'
import {Spinner} from '../../../../components/utils/Spinner'
import {AddTicketByID} from '../../redux/DigitalWaiverCRUD'

export interface InitialValuesProps {
  batchId?: string
  reservationNo?: string
  barcode?: string
}

export const initialValues: InitialValuesProps = {
  batchId: '',
  reservationNo: '',
}

export interface AddTicketByIdFormProps {
  onSubmit?: () => void
}

export const AddTicketByIdForm = ({onSubmit}: AddTicketByIdFormProps) => {
  const {pushError, push} = useAlerts()

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: TicketIdSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await AddTicketByID({
          batchId: values.batchId,
          reservationNo: values.reservationNo,
        })
        push({
          message: 'Ticket successfully Added',
          timeout: 5000,
          variant: 'success',
        })
        formik.resetForm()
        onSubmit?.()
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })
  return (
    <form className='form' onSubmit={formik.handleSubmit}>
      <div className='text-center'>
        <TextInput
          type='text'
          placeholder='Enter Ticket ID'
          inputClassName='rounded-pill fs-6 text-white border border-primary bg-primary-dw-ligh'
          {...formik.getFieldProps('batchId')}
          errorMessage={formik.errors.batchId}
          isShowErrorMessage={false}
          isTouched={formik.touched.batchId}
        />

        <TextInput
          type='text'
          placeholder='Enter Reservation NO'
          inputClassName='rounded-pill fs-6 text-white border border-primary bg-primary-dw-ligh'
          {...formik.getFieldProps('reservationNo')}
          errorMessage={formik.errors.reservationNo}
          isShowErrorMessage={false}
          isTouched={formik.touched.reservationNo}
        />

        <Button className='bg-primary w-100px mt-5 text-white fw-bolder rounded-pill py-2'>
          {formik.isSubmitting ? <Spinner size='lg' /> : 'Add'}
        </Button>
      </div>
    </form>
  )
}

export const TicketIdSchema = Yup.object().shape({
  batchId: Yup.string().required('Ticket Id is required'),
  reservationNo: Yup.string().required('Reservatio nNo is required'),
})
