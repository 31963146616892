import {Badge} from '../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../components/inputs/Button'
import {UserModel} from '../../../../../models/customer-portal/UserModel'

export const UserStatusColumn = ({data}: {data?: UserModel}) => {
  return (
    <>
      <Badge variant={getBadgeVariant(data)} uppercase>
        {data?.status}
      </Badge>
    </>
  )
}

const getBadgeVariant = (user?: UserModel): ColorVariant => {
  switch (user?.status) {
    case 'active':
      return 'success'
    case 'disabled':
      return 'danger'
    case 'pending':
      return 'warning'
    case 'queue':
      return 'dark'
    case 'sent':
      return 'info'
    case 'submitted':
      return 'primary'
    default:
      return 'dark'
  }
}
