import {AxiosError} from 'axios'
import {ApiGenericError} from '../models/ApiErrorModel'
import {VoucherCreditException} from './VoucherCreditException'

export abstract class ExceptionFactory {
  public static fromError(error: Error | AxiosError<ApiGenericError>) {
    if ('response' in error) {
      if (typeof error.response?.data?.data?.currentCredit === 'number') {
        const currentCredit: number = error.response.data.data.currentCredit
        return new VoucherCreditException(currentCredit)
      }
    }

    return error
  }
}
