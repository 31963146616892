import clsx from 'clsx'
import {FormikContextType} from 'formik'
import {useCallback, useMemo, useRef} from 'react'
import {FormikProps} from '../../components/Login'
import {RadioGroupInput} from '../../../../components/inputs/RadioGroupInput'
import {DigitInput} from '../../../../components/inputs/DigitInput'
import {useOnChange} from '../../../../components/hooks/useOnChange'

interface LoginFieldsProps {
  formik: FormikContextType<FormikProps>
}
export const LoginFields = ({formik}: LoginFieldsProps) => {
  const accountCoudeInputRef = useRef<HTMLInputElement | null>(null)

  const handleAccountCodeChange = useCallback(
    (value: string) => {
      formik.setFieldValue('accountCode', value)
    },
    [formik]
  )

  useOnChange(formik.values.userType, () => {
    if (formik.values.userType === '001') formik.setFieldValue('accountCode', '')
  })

  const handleUserTypeChange = useCallback(
    (value: string) => {
      formik.setFieldValue('userType', value)
    },
    [formik]
  )
  const items = useMemo(
    () => [
      {label: 'Admin User', value: '001', description: 'An owner account that performs tasks requiring unrestricted access'},
      {label: 'IAM User', value: '002', description: 'User within an account that performs daily tasks'},
    ],
    []
  )

  return useMemo(() => {
    return (
      <>

        <div className='fv-row mb-10'>
          <div className='mt-1'>
            <RadioGroupInput
              value={formik.values.userType}
              onChange={handleUserTypeChange}
              items={items}
              label='User Type'
              labelClassName='radio-label'
            />
          </div>
          <div className='mb-5'>
            {formik.values.userType === '002' && (
              <>
                <label className='form-label fs-6 fw-bolder text-white'>Account Code</label>
                <DigitInput
                  ref={accountCoudeInputRef}
                  value={formik.values.accountCode}
                  onChange={handleAccountCodeChange}
                  length={DIGIT_LENGTH}
                  isAlphaNumeric
                  shouldAutoFocus={formik.values.userType === '002'}
                />
              </>
            )}
          </div>
          <label className='form-label fs-6 fw-bolder text-white'>Username</label>
          <input
            placeholder='Email address'
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.username && formik.errors.username},
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              }
            )}
            type='text'
            name='username'
            autoComplete='off'
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }, [formik, handleAccountCodeChange, handleUserTypeChange, items])
}

const DIGIT_LENGTH = 6