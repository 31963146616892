import {Redirect, Route, Switch} from 'react-router-dom'
import {BasketPage} from '../../modules/digital-menu/pages/BasketPage'
import {DraftedOrderCheckoutPage} from '../../modules/digital-menu/pages/DraftedOrderCheckoutPage'
import {LocationSelectPage} from '../../modules/digital-menu/pages/LocationSelectPage'
import {MainMenuPage} from '../../modules/digital-menu/pages/MainMenuPage'
import {OrderPage} from '../../modules/digital-menu/pages/OrderPage'
import {OrdersPage} from '../../modules/digital-menu/pages/OrdersPage'
import {OutletPage} from '../../modules/digital-menu/pages/OutletPage'
import {OutletsPage} from '../../modules/digital-menu/pages/OutletsPage'
import {PayfortCheckoutConfirmationPage} from '../../modules/digital-menu/pages/PayfortCheckoutConfirmationPage'
import {PrintBillPage} from '../../modules/digital-menu/pages/PrintBillPage'
import {TableReservationPage} from '../../modules/digital-menu/pages/TableReservationPage'
import {DigitalMenuSplashScreen} from '../../modules/digital-menu/partials/DigitalMenuSplashScreen'

export const Routes = () => {
  return (
    <Switch>
      <Route exact path='/print/:orderCode' component={PrintBillPage} />
      <Route
        exact
        path='/orders/:orderCode/payfort-confirmation'
        component={PayfortCheckoutConfirmationPage}
      />
      <DigitalMenuSplashScreen>
        <Route exact path='/' component={MainMenuPage} />
        <Route exact path='/location-select' component={LocationSelectPage} />
        <Route exact path='/tickets/:ticketCode/outlets' component={OutletsPage} />
        <Route exact path='/tickets/:ticketCode/outlets/:outletCode' component={OutletPage} />
        <Route
          exact
          path='/tickets/:ticketCode/outlets/:outletCode/reservation'
          component={TableReservationPage}
        />
        <Route
          exact
          path='/tickets/:ticketCode/outlets/:outletCode/basket'
          component={BasketPage}
        />

        <Route exact path='/locations/:locationCode/outlets' component={OutletsPage} />
        <Route exact path='/locations/:locationCode/outlets/:outletCode' component={OutletPage} />
        <Route
          exact
          path='/locations/:locationCode/outlets/:outletCode/reservation'
          component={TableReservationPage}
        />
        <Route
          exact
          path='/locations/:locationCode/outlets/:outletCode/basket'
          component={BasketPage}
        />
        <Route exact path='/orders' component={OrdersPage} />
        <Route exact path='/orders/:orderCode' component={OrderPage} />
        <Route exact path='/orders/:orderCode/checkout' component={DraftedOrderCheckoutPage} />
      </DigitalMenuSplashScreen>
      <Redirect to='/' />
    </Switch>
  )
}
