import {useState, useCallback, useMemo, ReactNode} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {FilterModel} from '../../../../../models/FilterModel'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {useVoucherTableActions} from '../../../hooks/useVoucherTableActions'
import {VoucherTableRowActions} from './VoucherTableRowActions'
import {VoucherTableSelectionActions} from './VoucherTableSelectionActions'
import {Table} from '../../../../../components/tables/Table'
interface VoucherTableFlatProps {
  data?: VoucherModel[]
  onFilter?: (filter: FilterModel) => void
  onRefresh: () => void
  className?: string
  initialFilters?: FilterModel
  total?: number
  advancedFilters?: ReactNode
  columns: TableColumnOptions<VoucherModel>[]
  onRefreshCallback?: () => void
}

export const VoucherTableFlat = ({
  data,
  onFilter,
  onRefresh,
  className,
  initialFilters,
  total,
  advancedFilters,
  columns,
  onRefreshCallback,
}: VoucherTableFlatProps) => {
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-voucher-flat',
    defaults: {
      hiddenColumns: ['event', 'startedAt', 'endedAt'],
    },
  })
  const {
    isLoading,
    isKeyLoading,
    handleDisableBulk,
    handleDisableSingle,
  } = useVoucherTableActions({
    onRefresh,
    onRefreshCallback,
  })
  const tableData = useMemo((): VoucherModel[] => {
    if (data) {
      return data
    }
    return []
  }, [data])

  const rowActions = useCallback(
    (data: VoucherModel) => {
      return (
        <VoucherTableRowActions
          data={data}
          loading={isKeyLoading(data.code)}
          onDisable={handleDisableSingle}
        />
      )
    },
    [handleDisableSingle, isKeyLoading]
  )

  const isSelectDisabled = useCallback(
    (rowData: VoucherModel) => {
      const firstSelection = selected[0]
      const foundData = data?.find((voucher) => {
        return voucher.code === firstSelection
      })
      if (foundData) {
        return foundData?.customer?.email !== rowData.customer?.email
      }
      return false
    },
    [data, selected]
  )

  return (
    <>
      {onFilter ? (
        <FilterTable
          hideSelectAll
          advancedFilters={advancedFilters}
          isSelectDisabled={isSelectDisabled}
          className={className}
          actions={rowActions}
          selectionAction={
            <VoucherTableSelectionActions
              items={tableData}
              selected={selected}
              onDisable={handleDisableBulk}
              isLoading={isLoading}
            />
          }
          onHiddenColumnsChange={setHiddenColumns}
          onSelectionChange={setSelected}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={tableData}
          selection={selected}
          totalItems={total}
          idExtractor={idExtractor}
          onFilter={onFilter}
          initialFilters={initialFilters}
        />
      ) : (
        <Table
          className={className}
          actions={rowActions}
          body={
            <>
              <VoucherTableSelectionActions
                items={tableData}
                selected={selected}
                onDisable={handleDisableBulk}
                isLoading={isLoading}
              />
              {advancedFilters}
            </>
          }
          onSelectionChange={setSelected}
          columns={columns}
          data={tableData}
          selection={selected}
          idExtractor={idExtractor}
        />
      )}
    </>
  )
}
