import {Fragment, useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {EVENT_CODE} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {AgendaModel} from '../../../models/eva/AgendaModel'
import {TopicModel} from '../../../models/eva/TopicModel'
import {GetAgendaByDay} from '../redux/EvaCRUD'
import NavigationCell from './NavigationCell/NavigationCell'

export interface AgendaTableProps {
  day?: string
}

export const AgendaTable = ({day}: AgendaTableProps) => {
  const [agendas, setAgendas] = useState<AgendaModel[]>([])
  const {pushError} = useAlerts()
  const history = useHistory()

  const resetAgenda = useCallback(async () => {
    if (day && EVENT_CODE) {
      try {
        const {data} = await GetAgendaByDay(EVENT_CODE, day)
        setAgendas(data)
      } catch (e) {
        pushError(e)
      }
    } else {
      setAgendas([])
    }
  }, [day, pushError])

  const goToTopic = useCallback(
    (topic: TopicModel) => {
      history.push(`/topics/${topic.code}`, {
        from: '/agendas',
      })
    },
    [history]
  )

  useOnChange(day, () => {
    resetAgenda()
  })

  return (
    <>
      {agendas.map((agenda) => (
        <div key={agenda.code} className='rounded p-5 pb-10 mb-5'>
          <h1 className='mb-2'>{agenda.name}</h1>
          {agenda.location && (
            <p className='mb-3 d-inline-flex align-contents-center'>
              <MetronicIcon className='me-1 svg-icon-primary' iconType='Map' iconName='Marker1' />
              {agenda.location.name}
            </p>
          )}
          <p className='mb-8'>{agenda.description}</p>
          {agenda.topics?.map((topic, index, array) => {
            const isLast = index === array.length - 1
            return (
              <Fragment key={topic.code}>
                <NavigationCell
                  goTo={goToTopic}
                  data={topic}
                  title={topic.title}
                  location={topic.location?.name}
                  isDate={true}
                />
                {!isLast && <HorizontalDivider spacing={5} />}
              </Fragment>
            )
          })}
        </div>
      ))}
    </>
  )
}
