import {Redirect, Route, Switch} from 'react-router-dom'
import {ForgotPassword} from '../../modules/poken/components/forms/ForgotPassword'
import {ContentManagerPage} from '../../modules/poken/pages/ContentManagerPage'
import {useMemo} from 'react'
import {usePokenAuth} from '../../modules/poken/hooks/usePokenAuth'
import {PokensPage} from '../../modules/poken/pages/PokensPage'

export const PrivateRoutes = () => {
  const auth = usePokenAuth()

  const redirectRoutes = useMemo(() => {
    let link = '/error/404'
    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }
  return (
    <Switch>
      <Route path='/pokens/:pokenCode' component={ContentManagerPage} />
      <Route path='/pokens' component={PokensPage} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Redirect to='/pokens' />
      {redirectRoutes}
    </Switch>
  )
}
