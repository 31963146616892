import {ManageRowAction} from '../../../../../components/tables/actions/ManageRowAction'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {CustomerModel} from '../../../../../models/CustomerModel'

export interface CustomerTableRowActionsProps {
  data: CustomerModel
  onDelete?: (data: CustomerModel) => void
  onEdit?: (data: CustomerModel) => void
}

export const CustomerTableRowActions = ({data, onDelete, onEdit}: CustomerTableRowActionsProps) => {
  return (
    <>
      <RowAction
        data={data}
        iconType='General'
        iconName='Trash'
        variant='danger'
        tooltip='Delete'
        onClick={onDelete}
      />

      <ManageRowAction data={data} onEdit={onEdit} />
    </>
  )
}
