import {ReservationInputItemValue} from '../../../../../../components/inputs/ReservationInput/ReservationInputItem'
import {ProductModel} from '../../../../../../models/ems/ProductModel'
import {ProductLocationModel} from '../../../../../../models/ems/ReservationModel'

interface SeatTableColumnProps {
  customersSeats: ProductLocationModel
  products: ReservationInputItemValue<ProductModel>[]
}
export const SeatTableColumn = ({customersSeats, products}: SeatTableColumnProps) => {
  return (
    <div className='d-flex gap-1 flex-wrap w-200px'>
      {products
        ?.filter(
          (item) => item.data?.code === customersSeats.code && item.seatMap?.getSeatMapObject()
        )
        .map((item) => {
          const seatMapObject = item.seatMap!.getSeatMapObject()

          return Object.keys(seatMapObject).map((seatKey, index) => {
            const seatsArray = seatMapObject[seatKey]
            const seatsStr = seatsArray.map((seatNumber) => `${seatKey}${seatNumber}`).join(', ')
            return <div key={seatKey + index}>{seatsStr}</div>
          })
        })}
    </div>
  )
}
