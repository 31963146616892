import clsx from 'clsx'
import {useMemo} from 'react'
import analyticsImage from './Analytics.png'
import gridImage from './Grid.png'
import locationImage from './Location.png'
import oasisImage from './Oasis.png'
import fAndBImage from './f-and-b.svg'

export interface DashboardToolbarTabsProps {
  value: DashboardTabValue
  onChange?: (value: DashboardTabValue) => void
  className?: string
}

export const DasboardToolbarTabs = ({value, onChange, className}: DashboardToolbarTabsProps) => {
  const tabs = useMemo(() => {
    const allTabs = [
      {
        image: gridImage,
        label: 'Grid',
        onClick: () => onChange?.('grid'),
        isActive: value === 'grid',
      },
      {
        image: locationImage,
        label: 'Location',
        onClick: () => onChange?.('map'),
        isActive: value === 'map',
      },
      {
        image: oasisImage,
        label: 'Oasis',
        onClick: () => onChange?.('oasis'),
        isActive: value === 'oasis',
      },
      {
        image: analyticsImage,
        label: 'Analytics',
        onClick: () => onChange?.('analytics'),
        isActive: value === 'analytics',
      },
      {
        image: fAndBImage,
        label: 'F&B',
        onClick: () => onChange?.('F&B'),
        isActive: value === 'F&B',
      },
    ]

    return allTabs
  }, [onChange, value])

  return (
    <div className={clsx(className, 'd-flex gap-3 justify-content-center align-items-center')}>
      {tabs.map((tab) => {
        return (
          <div key={tab.label}>
            <button
              onClick={tab.onClick}
              className={clsx(
                'btn w-100 h-100 d-inline-flex align-items-center justify-content-center border border-primary p-2 px-4',
                tab.isActive ? 'bg-primary' : undefined
              )}
            >
              <img style={{height: '25px'}} src={tab.image} alt={tab.label}></img>
              <span className='fs-1 d-none d-md-block fw-bolder text-white ps-3'>{tab.label}</span>
            </button>
          </div>
        )
      })}
    </div>
  )
}

export type DashboardTabValue = 'grid' | 'map' | 'oasis' | 'analytics' | 'F&B'
