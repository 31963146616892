import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {FilterModel} from '../../models/FilterModel'
import {actions} from '../../modules/default/system/redux/SystemRedux'
import {useRootStateSelector} from './useRootStateSelector'

export const useEntityFilter = (entityName: string) => {
  const dispatch = useDispatch()

  const filters = useRootStateSelector((state) => state.system.filters[entityName])

  const clearFilter = useCallback(() => {
    dispatch(actions.setEntityFilter(entityName))
  }, [entityName, dispatch])

  const setFilter = useCallback(
    (filter: FilterModel) => {
      dispatch(actions.setEntityFilter(entityName, filter))
    },
    [entityName, dispatch]
  )

  return {setFilter, clearFilter, filters}
}
