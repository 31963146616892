import clsx from 'clsx'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Button} from '../../../../components/inputs/Button'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {getWaiverStatusVariant} from './DigitalWaiverCard'

interface DigitalWaiverCardProps {
  data: TicketWaiverModel
  onDelegate?: (data: TicketWaiverModel) => void
  onView?: (data: TicketWaiverModel) => void
  onShare?: (e?: React.FormEvent<HTMLFormElement>) => void
  onSign?: (data: TicketWaiverModel) => void
  onDownload?: (data: TicketWaiverModel) => void
  onEdit?: (data: TicketWaiverModel) => void
  tabCode?: string
  rotation?: string
  isDelegating?: boolean
  isInfo?: boolean
  isExpiredTicket?: boolean
}
export const DigitalWaiverCardActions = ({
  data,
  onDelegate,
  onView,
  onShare,
  onSign,
  onDownload,
  onEdit,
  tabCode,
  rotation,
  isDelegating,
  isInfo,
  isExpiredTicket,
}: DigitalWaiverCardProps) => {
  return (
    <div className='d-flex gap-4 text-white justify-content-end'>
      {(!isDelegating || !rotation) && !isExpiredTicket && onSign && canSign(data, tabCode) && (
        <Button
          className='rounded-pill text-black fs-7 mb-1 py-1 ff-admm-bold bg-white btn-linear-gradient-black-white-dw flex-grow-1'
          onClick={() => onSign(data)}
        >
          <KTSVG
            color='white'
            className='svg-icon-4 me-2'
            svgClassName='h-100 w-100'
            path='/media/svg/waiver-icons/edit-waiver.svg'
          />
          Sign waiver
        </Button>
      )}

      {(!isDelegating || !rotation) && !isExpiredTicket && onEdit && canEdit(data, tabCode) && (
        <Button
          className='rounded-pill text-black fs-7 mb-1 py-1 ff-admm-bold bg-white btn-linear-gradient-black-white-dw flex-grow-1'
          onClick={() => onEdit(data)}
        >
          <KTSVG
            color='white'
            className='svg-icon-4 me-2'
            svgClassName='h-100 w-100'
            path='/media/svg/waiver-icons/edit-waiver.svg'
          />
          Edit waiver
        </Button>
      )}

      {(!isDelegating || !rotation) && onDownload && canView(data, tabCode) && (
        <Button
          className='rounded-pill text-black fs-7 mb-1 py-1 ff-admm-bold bg-white btn-linear-gradient-black-white-dw flex-grow-1'
          onClick={() => onDownload(data)}
        >
          <KTSVG
            color='white'
            className='svg-icon-4 me-2'
            svgClassName='h-100 w-100'
            path='/media/svg/waiver-icons/edit-waiver.svg'
          />
          Download Waiver
        </Button>
      )}

      {isDelegating && !isExpiredTicket && rotation && onShare && (
        <Button
          className='rounded-pill text-black fs-7 mb-1 py-1 ff-admm-bold bg-white btn-linear-gradient-black-white-dw flex-grow-1'
          onClick={() => onShare()}
        >
          <KTSVG
            color='white'
            className='svg-icon-7 me-2'
            svgClassName='h-100 w-100'
            path='/media/svg/waiver-icons/share.svg'
          />
          Share Waiver
        </Button>
      )}

      {onView && (
        <Button
          onClick={() => onView(data)}
          style={{
            width: '35px',
            height: '35px',
            transition: 'all 0s',
            background: isInfo ? getWaiverStatusVariant({data, tabCode}) : '',
          }}
          className={clsx(
            'rounded-circle d-flex justify-content-center p-0 d-flex align-items-center justify-content-center',
            {'btn-linear-gradient-black-white-dw': !isInfo}
          )}
        >
          <KTSVG
            color='white'
            className='svg-icon-4 me-0'
            svgClassName='h-100 w-100'
            path='/media/svg/waiver-icons/info.svg'
          />
        </Button>
      )}

      {onDelegate && !isExpiredTicket && (
        <Button
          onClick={() => onDelegate(data)}
          style={{
            width: '35px',
            height: '35px',
            transition: 'all 0s',
            background: isDelegating ? getWaiverStatusVariant({data, tabCode}) : '',
          }}
          className={clsx(
            'rounded-circle d-flex justify-content-center p-0 d-flex align-items-center justify-content-center',
            {'btn-linear-gradient-black-white-dw': !isDelegating}
          )}
        >
          <KTSVG
            color='white'
            className='svg-icon-4 me-0'
            svgClassName='h-100 w-100'
            path='/media/svg/waiver-icons/share.svg'
          />
        </Button>
      )}
    </div>
  )
}

export const canSign = (data: TicketWaiverModel, tabCode?: string) => {
  if (tabCode === 'shared') {
    return ['not-signed'].includes(data.delegateStatus)
  } else if (tabCode === 'available' && data.dwStatus) return ['not-signed'].includes(data.dwStatus)
  else if (!data.dwStatus) return true
  else return false
}

export const canEdit = (data: TicketWaiverModel, tabCode?: string) => {
  if (tabCode === 'shared') {
    return ['rejected', 'submitted', 'pending'].includes(data.delegateStatus)
  } else if (tabCode === 'available' && data.dwStatus)
    return ['rejected', 'submitted', 'pending', 'awaiting-guardian', 'review'].includes(
      data.dwStatus
    )
  else return false
}

export const canView = (data: TicketWaiverModel, tabCode?: string) => {
  if (tabCode === 'shared') {
    return ['approved'].includes(data.delegateStatus)
  } else if (tabCode === 'available' && data.dwStatus) return ['approved'].includes(data.dwStatus)
  else return false
}
