import {DelimitedStringBuilder} from './DelimitedStringBuilder'

export interface FnbQrCodeData {
  eventCode: string
  locationCode: string
  outletCode?: string
  tableNumber?: string
}

export class FnbQrCodeGenerator {
  public data: FnbQrCodeData

  constructor(data: FnbQrCodeData) {
    const hasRequiredData = data.eventCode && data.locationCode
    const hasOutletOrTableNumber = data.outletCode || data.tableNumber

    if (!hasRequiredData || !hasOutletOrTableNumber) {
      throw new Error('Incorrect QR data.')
    }

    this.data = data
  }

  public static fromQrCode = (qr: string) => {
    const delimitedString = new DelimitedStringBuilder({
      value: qr,
    })
    const values = delimitedString.parseValue()

    return new FnbQrCodeGenerator({
      eventCode: values[0],
      locationCode: values[1],
      outletCode: values[2],
      tableNumber: values[3],
    })
  }

  public toQrCode = () => {
    const {eventCode, locationCode, outletCode, tableNumber} = this.data

    const delimitedString = new DelimitedStringBuilder()

    delimitedString
      .append(eventCode)
      .append(locationCode)
      .append(outletCode || '')
      .append(tableNumber || '')

    return delimitedString.getValue()
  }
}
