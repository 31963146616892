import {Redirect, Route, Switch} from 'react-router-dom'
import {CustomerPortalAuthFormLogoHeader, PoweredBy} from '../../../../config/logos'
import FirstLoginPasswordResetForm from '../components/forms/FirstLoginPasswordResetForm'
import {ForgotPassword} from '../components/forms/ForgotPassword'
import {Login} from '../components/Login'
import {usePokenAuth} from '../hooks/usePokenAuth'

export const AuthPage = () => {
  const auth = usePokenAuth()
  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        <img
          alt={CustomerPortalAuthFormLogoHeader.alt}
          src={CustomerPortalAuthFormLogoHeader.src}
          className='img-fluid w-200px w-100 mb-8'
        />
        <h1 className='text-secondary mb-8 fw-bolder'>Poken Technology</h1>
        <div className='w-sm-500px w-300px bg-body opacity-75 rounded shadow-sm p-10 p-lg-15 rounded-0 mx-auto'>
          {auth?.isFirstLogin() && <Redirect to='/auth/password-reset' />}
          {auth && !auth.isFirstLogin() && <Redirect to='/' />}
          <Switch>
            <Route path='/auth/login/:hash'>
              <Login />
            </Route>
            <Route path='/auth/login'>
              <Login />
            </Route>
            <Route path='/auth/password-reset' component={FirstLoginPasswordResetForm} />
            <Route path='/auth/forgot-password'>
              <ForgotPassword />
            </Route>
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        <img alt={PoweredBy.alt} src={PoweredBy.src} className='h-30px mt-12' />
      </div>
    </div>
  )
}
