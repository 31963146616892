import {useState, createContext, useMemo, useContext, ReactNode} from 'react'

const LayoutWaiverContext = createContext<LayoutWaiverContextData | null>(null)

export interface LayoutWaiverContextData {
  toolbar: HTMLElement | null
  headerContent: HTMLElement | null
  headerControls: HTMLElement | null
  setToolbar: (el: HTMLElement | null) => void
  setHeaderContent: (el: HTMLElement | null) => void
  setHeaderControls: (el: HTMLElement | null) => void
}

export interface LayoutWaiverProviderProps {
  children?: ReactNode
}

export const LayoutWaiverProvider = ({children}: LayoutWaiverProviderProps) => {
  const [toolbar, setToolbar] = useState<HTMLElement | null>(null)
  const [headerContent, setHeaderContent] = useState<HTMLElement | null>(null)
  const [headerControls, setHeaderControls] = useState<HTMLElement | null>(null)

  const value = useMemo(
    (): LayoutWaiverContextData => ({
      toolbar,
      headerContent,
      headerControls,
      setToolbar,
      setHeaderContent,
      setHeaderControls,
    }),
    [headerContent, headerControls, toolbar]
  )

  return <LayoutWaiverContext.Provider value={value}>{children}</LayoutWaiverContext.Provider>
}

export const useLayoutWaiverData = () => {
  const data = useContext(LayoutWaiverContext)

  if (!data) {
    throw new Error('No waiver provider.')
  }

  return data
}
