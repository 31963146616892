import {useState, useCallback, useEffect} from 'react'
import {useFormik} from 'formik'
import {TicketSubmitWaiverForm} from './TicketSubmitWaiverForm'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {getWaiverSignPayload, useSubmitTicketData} from '../../../hooks/useSubmitTicketData'
import {TicketWaiverModel} from '../../../../../models/digital-waiver/TicketWaiverModel'
import {updateGuardianWaiver, updateWaiverTicket} from '../../../redux/DigitalWaiverCRUD'
import {useWaiverAuth} from '../../../hooks/useWaiverAuth'
import {useOnChange} from '../../../../../components/hooks/useOnChange'

export interface TicketSubmitWaiverFormEditProps {
  ticket?: TicketWaiverModel | null
  onRefresh?: () => void
  onCallBack?: () => void
  onIsLoadingCallBack?: (is: boolean) => void
  isOpen: boolean
  handleClose?: () => void
  isCloseButton?: boolean
  isGuardianPage?: boolean
  onCancel?: () => void
}

const TicketSubmitWaiverFormEdit = ({
  ticket,
  onRefresh,
  onCallBack,
  onIsLoadingCallBack,
  isOpen = false,
  handleClose,
  isCloseButton = true,
  isGuardianPage = false,
  onCancel,
}: TicketSubmitWaiverFormEditProps) => {
  const [loading, setLoading] = useState(false)

  const {pushError} = useAlerts()
  const {initialNewValues, getWaiver, waiverFormValidationSchema, resetCountries, countries} =
    useSubmitTicketData({
      ticket,
      isGuardianPage,
    })

  const auth = useWaiverAuth()
  const formik = useFormik({
    initialValues: initialNewValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: waiverFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      onIsLoadingCallBack && onIsLoadingCallBack(true)
      try {
        if (ticket) {
          const payload = getWaiverSignPayload({
            values,
            ticketCode: ticket.code,
            delegateCode: ticket.delegateCode ? ticket.delegateCode : undefined,
            ticket,
          })
          if (ticket.dwCode) {
            if (isGuardianPage) {
              await updateGuardianWaiver({payload, dwCode: ticket.dwCode})
            } else {
              await updateWaiverTicket({payload, dwCode: ticket.dwCode})
            }
            handleCloseDrawer()
            onRefresh && onRefresh()
            onCallBack && onCallBack()
          }
        }
      } catch (e) {
        pushError(e)
      } finally {
        setLoading(false)
        onIsLoadingCallBack && onIsLoadingCallBack(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
    handleClose && handleClose()
  }, [formik, handleClose])

  useEffect(() => {
    if (isOpen) {
      resetCountries()
      getWaiver()
    }
  }, [getWaiver, isOpen, resetCountries])

  useOnChange(isOpen, () => {
    if (isOpen && auth?.getUser()) {
      formik.setFieldValue('name', auth.getUser().name)
      formik.setFieldValue('email', auth.getUser().email)
    }
  })

  return initialNewValues ? (
    <TicketSubmitWaiverForm
      isLoading={loading}
      formik={formik}
      isOpen={isOpen}
      isCloseButton={isCloseButton}
      countries={countries}
      ticket={ticket}
      isGuardianPage={isGuardianPage}
      onCancel={onCancel}
    />
  ) : null
}

export default TicketSubmitWaiverFormEdit
