import {ReactNode, useCallback, useMemo, useRef} from 'react'
import {
  PieChartDatum,
  PieChartSegment,
} from '../../../../components/charts/PieChart/PieChartSegment'
import {useTooltip, useTooltipInPortal, defaultStyles} from '@visx/tooltip'
import clsx from 'clsx'
import styles from './RingChart.module.scss'
import {Group} from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import Pie, {PieArcDatum} from '@visx/shape/lib/shapes/Pie'
import {TooltipContextProvider} from '../../../../components/charts/TooltipContext/TooltipContextProvider'
import {ColorLegend, ColorLegendItem} from './BookingCountPerStatus/ColorLegend'

export interface RingChartProps {
  datum: PieChartDatum[]
  tooltip?: ReactNode
  getColor: (key: string) => string
  onDatumClick?: (datum: PieChartDatum) => void
  showNumbersOnSegment?: boolean
}

export const RingChart = ({
  datum,
  tooltip,
  getColor,
  onDatumClick,
  showNumbersOnSegment,
}: RingChartProps) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()

  const getDatumColor = useCallback(
    (datum: PieArcDatum<PieChartDatum>) => getColor(datum.data.key),
    [getColor]
  )
  const totalTickets: number = useMemo(() => {
    let total = 0
    datum.forEach((i) => {
      total += i.countVal ? i.countVal : 0
    })
    return total
  }, [datum])

  const legends = useMemo((): ColorLegendItem[] => {
    return datum.map((datum) => ({
      color: getColor(datum.key),
      label: datum.label,
      value: datum.countVal ? datum.countVal : datum.value,
      key: String(datum.key),
    }))
  }, [datum, getColor])

  const handleLegendMouseMove = useCallback(
    (e: unknown, key: string | number) => {
      showTooltip({
        tooltipData: String(key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  const handlePieSegmentMouseMove = useCallback(
    (e: unknown, datum: PieArcDatum<PieChartDatum>) => {
      showTooltip({
        tooltipData: String(datum.data.key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  const handleLegendClick = useCallback(
    (data: ColorLegendItem) => {
      const foundDatum = datum.find((item) => item.key === data.key)
      if (foundDatum) {
        onDatumClick?.(foundDatum)
      }
    },
    [datum, onDatumClick]
  )

  return (
    <div
      className={clsx('h-100 w-100 d-flex pb-15', styles.root)}
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      onMouseOut={hideTooltip}
      style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between'}}
    >
      <ColorLegend
        className={clsx('ff-f1-regular text-white fs-2', styles.legends)}
        legends={legends}
        onMouseLeave={hideTooltip}
        onMouseMove={handleLegendMouseMove}
        onClick={onDatumClick ? handleLegendClick : undefined}
        hasTotal={totalTickets}
      />
      <ParentSize>
        {({width, height}) => {
          const innerWidth = width
          const innerHeight = height
          const radius = Math.min(innerWidth, innerHeight) / 2
          const centerY = innerHeight / 2
          const centerX = innerWidth / 1.8
          return (
            <svg width={width + 25} height={height}>
              <Group top={centerY} left={centerX}>
                <Pie
                  data={datum}
                  pieValue={pieValueAccessor}
                  outerRadius={radius}
                  innerRadius={radius * 0.5}
                  cornerRadius={3}
                >
                  {(pie) => (
                    <PieChartSegment
                      {...pie}
                      showValue={showNumbersOnSegment}
                      onClickDatum={onDatumClick ? (e) => onDatumClick?.(e.data) : undefined}
                      animate
                      getColor={getDatumColor}
                      onMouseMove={handlePieSegmentMouseMove}
                      isHideText
                    />
                  )}
                </Pie>
              </Group>
            </svg>
          )
        }}
      </ParentSize>
      <TooltipContextProvider value={{key: tooltipData}}>
        {tooltipOpen && (
          <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={defaultStyles}>
            {tooltip}
          </TooltipInPortal>
        )}
      </TooltipContextProvider>
    </div>
  )
}

const pieValueAccessor = (datum: PieChartDatum) => {
  return datum.value
}
