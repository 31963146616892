import {ReactNode, useMemo} from 'react'
import {Alert} from './Alert'
import {useAlerts} from './useAlerts'

export const AlertContainer = () => {
  const {alerts, close} = useAlerts()

  const alertNodes = useMemo(() => {
    const nodes: ReactNode[] = []
    for (let i = alerts.length - 1; i >= 0; i--) {
      const alert = alerts[i]
      nodes.push(
        <Alert
          key={alert.key}
          alertKey={alert.key}
          timeout={alert.timeout}
          variant={alert.variant}
          onClose={close}
        >
          {alert.message}
        </Alert>
      )
    }
    return nodes
  }, [alerts, close])

  return <div className='alert-container'>{alertNodes}</div>
}
