import {ReactNode, useMemo} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {ColorVariant} from '../../../../components/inputs/Button'
import {CustomerModelType} from '../../../../models/CustomerModel'

export interface CustomerTypeBadgeProps {
  customer: {type: CustomerModelType}
  children?: ReactNode
  isPortal?: boolean
}

export const CustomerTypeBadge = ({customer, children, isPortal = false}: CustomerTypeBadgeProps) => {
  const color = useMemo(() => getBadgeColor(customer.type), [customer.type])
  const label = useMemo(() => getBadgeLabel(customer.type), [customer.type])
  return (
    <Badge uppercase variant={color}>
      {isPortal ? label : children}
    </Badge>
  )
}

const getBadgeColor = (type: CustomerModelType): ColorVariant => {
  switch (type) {
    case 'customer':
      return 'primary'
    case 'partner':
      return 'info'
    case 'reseller':
      return 'warning'
    default:
      return 'dark'
  }
}
const getBadgeLabel = (type: CustomerModelType): string => {
  switch (type) {
    case 'customer':
      return 'customer'
    case 'partner':
      return 'partner'
    case 'reseller':
      return 'sub-account'
    default:
      return 'customer'
  }
}
