import clsx from 'clsx'

export interface HorizontalDividerProps {
  spacing?: number
  className?: string
}

export const HorizontalDivider = ({className, spacing = 2}: HorizontalDividerProps) => {
  return <hr className={clsx(`mt-${spacing} mb-${spacing}`, className)} />
}
