import {ReactNode, useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {LayoutSplashScreen} from '../../../../../_metronic/layout/core'
import {VerifyOutletAuthToken} from '../../../outlet/redux/OutletCRUD'
import {actions as outletActions} from '../../../outlet/redux/OutletRedux'
import {actions as customerPortalActions} from '../../../customer-portal/redux/CustomerPortalRedux'
import {getUserByToken} from './AuthCRUD'
import * as auth from './AuthRedux'
import {useOnMount} from '../../../../components/hooks/useOnMount'
import {LAYOUT_TYPE} from '../../../../../config/env'
import {VerifyCustomerAuthToken} from '../../../customer-portal/redux/CustomerPortalCRUD'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'

export interface AuthInitProps {
  children: ReactNode
}

const AuthInit = ({children}: AuthInitProps) => {
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const tokens = useRootStateSelector((state) => ({
    customer: state.customerPortal.auth?.token,
    waiver: state.digitalWaiver.auth?.token,
    outlet: state.outlet.auth?.token,
    default: state.auth.token,
  }))

  const checkOutletToken = useCallback(async () => {
    try {
      const {data: auth} = await VerifyOutletAuthToken()
      dispatch(outletActions.auth.fulfillUser(auth.user))
    } catch (error) {
      dispatch(outletActions.auth.logout())
    } finally {
      setShowSplashScreen(false)
    }
  }, [dispatch])

  const checkDefaultToken = useCallback(async () => {
    try {
      const {data: user} = await getUserByToken()
      dispatch(auth.actions.fulfillUser(user))
    } catch (error) {
      dispatch(auth.actions.logout())
    } finally {
      setShowSplashScreen(false)
    }
  }, [dispatch])

  const checkCustomerToken = useCallback(async () => {
    try {
      const {data: auth} = await VerifyCustomerAuthToken()
      dispatch(customerPortalActions.auth.fulfillUser(auth.user))
    } catch (error) {
      dispatch(customerPortalActions.auth.logout())
    } finally {
      setShowSplashScreen(false)
    }
  }, [dispatch])

  const checkToken = useCallback(async () => {
    if (LAYOUT_TYPE === 'outlet' && tokens.outlet) {
      checkOutletToken()
    } else if (LAYOUT_TYPE === 'customer-portal' && tokens.customer) {
      checkCustomerToken()
    } else if (LAYOUT_TYPE === 'digital-waiver' && tokens.waiver) {
      checkCustomerToken()
    } else if (LAYOUT_TYPE === 'default' && tokens.default) {
      checkDefaultToken()
    } else {
      setShowSplashScreen(false)
    }
  }, [
    checkCustomerToken,
    checkDefaultToken,
    checkOutletToken,
    tokens.customer,
    tokens.default,
    tokens.outlet,
    tokens.waiver,
  ])

  useOnMount(() => {
    checkToken()
  })

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export default AuthInit
