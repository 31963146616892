import {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  PasswordResetForm,
  PasswordResetFormValues,
} from '../../../components/forms/PasswordResetForm'
import {actions} from '../redux/CustomerDelegateRedux'
import {ResetPassword} from '../redux/CustomerDelegateCRUD'
import {useCustomerDelegatesAuth} from '../hooks/useCustomerAuth'

const FirstLoginPasswordResetForm = () => {
  const _auth = useCustomerDelegatesAuth()
  const history = useHistory()
  useEffect(() => {
    if (!_auth) {
      history.replace('/auth/login')
    }
  }, [_auth, history])

  const dispatch = useDispatch()
  const handleOnSubmit = useCallback(
    async (formValues: PasswordResetFormValues) => {
      const response = await ResetPassword(formValues.newPassword)
      dispatch(actions.auth.login(response.data.token))
    },
    [dispatch]
  )

  return <PasswordResetForm onSubmit={handleOnSubmit} />
}

export default FirstLoginPasswordResetForm
