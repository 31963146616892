import {useCallback, useMemo} from 'react'
import {BarChart} from '../../../../../../components/charts/BarChart/BarChart'
import {WidgetF12Model} from '../../../../../../models/fnb/WidgetModel'

export interface OutletLocationChartProps {
  datum: WidgetF12Model[]
}

export const OutletLocationChart = ({datum}: OutletLocationChartProps) => {
  const chartData = useMemo((): OultletChartData[] => {
    const chartValues: OultletChartData[] = []
    if (datum) {
      datum.forEach((value) => {
        chartValues.push({
          name: value.locationName,
          total: value.total,
        })
      })
      return chartValues
    }
    return []
  }, [datum])

  const renderTooltip = useCallback(
    (key: string) => {
      const chartItem = chartData.find((item) => getName(item) === key)
      if (chartItem) {
        return (
          <div className='bg-light-primary p-3 border border-primary rounded text-white'>
            <div>
              {chartItem.name}: {chartItem.total}
            </div>
          </div>
        )
      }
    },
    [chartData]
  )

  return (
    <>
      <div className='w-100 pb-0 h-100 pt-10'>
        <BarChart
          yLabel='Locations'
          xLabel='Earnings'
          data={chartData}
          isHideXLabel
          xAccessor={getName}
          yAccessor={getCount}
          renderTooltip={renderTooltip}
        />
      </div>
    </>
  )
}

const getName = (datum: OultletChartData) => datum.name
const getCount = (datum: OultletChartData) => datum.total

export interface OultletChartData {
  name: string
  total: number
}
