import {useCallback, useEffect, useMemo, useState} from 'react'
import {Body} from '../../../../../_metronic/layout/foh/Body'
import {Paper} from '../../../../components/utils/Paper'
import {TabList} from '../../components/TabList'
import {
  CreateOrderForm,
  EMPTY_INITIAL_VALUES,
  validationSchema,
} from '../../components/forms/CreateOrderForm'
import {useCurrentOutletMenu} from '../../hooks/useCurrentOutletMenu'
import {ProductGrid} from '../../components/ProductGrid/ProductGrid'
import {ProductGridItemAttributes} from '../../components/ProductGrid/ProductGridItem'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {VAT} from '../../../../../config/env'
import {useQueryParams} from '../../../../components/hooks/useQueryParams'
import {useOutletOrderFormHandlers} from '../../hooks/useOutletOrderFormHandlers'
import {VatExclusivePriceTag} from '../../../../utils/VatExclusivePriceTag'
import {Sidebar} from '../../../../../_metronic/layout/foh/Sidebar'
import {useFormik} from 'formik'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {DefaultFNBProductImage} from '../../../../../config/logos'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'

export const FohNewOrderPage = () => {
  const [currentTab, setCurrentTab] = useState('')
  const query = useQueryParams()
  const menu = useCurrentOutletMenu()
  const {handleBack, handlePay, handlePayLater} = useOutletOrderFormHandlers()
  const {staticUrls} = useAppConfig()

  const formik = useFormik({
    initialValues: {
      ...EMPTY_INITIAL_VALUES,
      orderType: query.get('orderType') || '',
    },
    onSubmit: (values) => {
      if (values.submitType === 'pay-now') {
        handlePay(values)
      } else if (values.submitType === 'pay-later') {
        handlePayLater(values)
      }
    },
    validationSchema,
  })

  const addProductToCart = useCallback(
    (product: ProductGridItemAttributes) => {
      const newCart = formik.values.cart.clone()
      if (newCart.hasItem(product.value)) {
        newCart.incrementCount(product.value)
      } else {
        newCart.addItem(
          product.value,
          product.label,
          new VatExclusivePriceTag(product.price, 'AED', VAT)
        )
      }
      formik.setFieldValue('cart', newCart)
    },
    [formik]
  )

  const categories = useMemo((): SelectInputItem[] => {
    if (menu?.categories) {
      return menu.categories.map((category) => ({value: category.code, label: category.name}))
    }
    return []
  }, [menu?.categories])

  const products = useMemo((): ProductGridItemAttributes[] => {
    if (menu?.categories && currentTab) {
      const products: ProductGridItemAttributes[] = []
      menu.categories.some((category) => {
        if (category.code === currentTab && category.fnbProducts) {
          category.fnbProducts.forEach((product) => {
            products.push({
              currency: 'AED',
              image: product.file
                ? new ImageInputValue(staticUrls.public, product.file)
                : new ImageInputValue(
                    toAbsoluteUrl(DefaultFNBProductImage.src),
                    product.name,
                    product.code
                  ),
              label: product.name,
              price: product.price,
              value: product.code,
            })
          })
          return true
        }
        return false
      })
      return products
    }
    return []
  }, [currentTab, menu?.categories, staticUrls.public])

  useEffect(() => {
    if (menu?.categories) {
      const firstCategory = menu.categories[0]
      if (firstCategory) {
        setCurrentTab(firstCategory.code)
      }
    }
  }, [menu?.categories])

  return (
    <>
      <Body
        header={
          <Paper className='foh-header'>
            <TabList tabs={categories} onClick={setCurrentTab} value={currentTab} />
          </Paper>
        }
      >
        <ProductGrid products={products} onClick={addProductToCart} />
      </Body>
      <Sidebar>
        <CreateOrderForm
          formik={formik}
          availableOrderTypes={menu?.orderType}
          onPay={formik.submitForm}
          onPayLater={formik.submitForm}
          onBack={handleBack}
        />
      </Sidebar>
    </>
  )
}
