import {createContext, ReactNode, useCallback, useContext, useMemo} from 'react'
import {WizardStep} from './WizardControls'

const WizardControlContext = createContext<WizardControlContextData>({
  currentPage: 0,
  incrementPage: () => {},
  decrementPage: () => {},
  hasNext: false,
  hasPrevious: false,
  isHasPrevious: true,
})

export interface WizardControlProviderProps {
  onPageChange: (page: number, type?: 'increament' | 'decreament') => void
  currentPage: number
  children?: ReactNode
  steps: WizardStep[]
  isHasPrevious?: boolean
}

export const WizardControlProvider = ({
  currentPage,
  children,
  steps,
  onPageChange,
  isHasPrevious,
}: WizardControlProviderProps) => {
  const nextSteps = useMemo(() => {
    return steps.slice(currentPage + 1)
  }, [currentPage, steps])

  const previousSteps = useMemo(() => {
    return steps.slice(0, currentPage)
  }, [currentPage, steps])

  const nextIndex = useMemo(() => {
    if (nextSteps.length) {
      for (let i = 0; i < nextSteps.length; i++) {
        const step = nextSteps[i]
        if (!step.hidden) {
          return steps.indexOf(step)
        }
      }
    }
  }, [nextSteps, steps])

  const previousIndex = useMemo(() => {
    if (previousSteps.length) {
      for (let i = previousSteps.length - 1; i >= 0; i--) {
        const step = previousSteps[i]
        if (!step.hidden) {
          return steps.indexOf(step)
        }
      }
    }
  }, [previousSteps, steps])

  const hasNext = useMemo(() => {
    return nextIndex !== undefined
  }, [nextIndex])

  const hasPrevious = useMemo(() => {
    return isHasPrevious ? isHasPrevious : previousIndex !== undefined
  }, [isHasPrevious, previousIndex])

  const incrementPage = useCallback(() => {
    if (nextIndex !== undefined) {
      onPageChange(nextIndex, 'increament')
    }
  }, [nextIndex, onPageChange])

  const decrementPage = useCallback(() => {
    if (previousIndex !== undefined) {
      onPageChange(previousIndex, 'decreament')
    }
  }, [onPageChange, previousIndex])

  return (
    <WizardControlContext.Provider
      value={{
        currentPage,
        decrementPage,
        incrementPage,
        hasNext,
        hasPrevious,
        isHasPrevious,
      }}
    >
      {children}
    </WizardControlContext.Provider>
  )
}

export const useWizardControl = () => {
  return useContext(WizardControlContext)
}

interface WizardControlContextData {
  incrementPage: () => void
  decrementPage: () => void
  hasPrevious?: boolean
  hasNext?: boolean
  currentPage: number
  isHasPrevious?: boolean
}
