import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {HashLink as Link} from 'react-router-hash-link'
import {Button, ButtonVariant} from '../../../../components/inputs/Button'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'

export interface TabItemProps extends SelectInputItem {
  className?: string
  onClick?: (value: string) => void
  active?: boolean
  activeVariant?: ButtonVariant
  inactiveVariant?: ButtonVariant
  useLink?: boolean
}

export const TabItem = ({
  label,
  value,
  className,
  active,
  onClick,
  activeVariant = 'success',
  inactiveVariant,
  useLink,
}: TabItemProps) => {
  const handleOnClick = useCallback(() => {
    onClick && onClick(value)
  }, [onClick, value])

  const tabClassName = useMemo(() => {
    return clsx('rounded-0 text-nowrap', className)
  }, [className])

  const button = (
    <Button
      onClick={handleOnClick}
      variant={active ? activeVariant : inactiveVariant}
      className={tabClassName}
    >
      {label}
    </Button>
  )

  if (useLink) {
    return (
      <Link smooth to={value}>
        {button}
      </Link>
    )
  }

  return button
}
