import {ReactNode, useCallback, useMemo, useState} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {TicketGroupModel} from '../../../../../models/customer-portal/TicketModel'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {TicketTableGroupedData, TicketTableGroupedRow} from './TickeTableGroupedRow'

export interface TicketTableGroupedProps {
  data?: TicketGroupModel
  total?: number
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  columnsFlat: TableColumnOptions<TicketPortalModel>[]
}

export const TicketTableGrouped = ({
  onFilter,
  onRefresh,
  onRefreshCallback,
  data,
  initialFilters,
  total,
  advancedFilters,
  columnsFlat,
}: TicketTableGroupedProps) => {
  const [expanded, setExpanded] = useState<TableRowId[]>([])

  const tableData = useMemo((): TicketTableGroupedData[] => {
    if (data) {
      return Object.entries(data).reduce<TicketTableGroupedData[]>((acc, [email, tickets]) => {
        acc.push({email, tickets})
        return acc
      }, [])
    }
    return []
  }, [data])

  const Expansion = useCallback(
    (props: {data: TicketTableGroupedData}) => {
      return (
        <TicketTableGroupedRow
          columns={columnsFlat}
          onRefresh={onRefresh}
          onRefreshCallback={onRefreshCallback}
          {...props}
        />
      )
    },
    [columnsFlat, onRefresh, onRefreshCallback]
  )

  return (
    <FilterTable
      idExtractor={emailExtractor}
      columns={columns}
      expansion={Expansion}
      onExpandChange={setExpanded}
      expandedRows={expanded}
      onFilter={onFilter}
      initialFilters={initialFilters}
      totalItems={total}
      data={tableData}
      advancedFilters={advancedFilters}
    />
  )
}

const columns: TableColumnOptions<TicketTableGroupedData>[] = [
  {
    field: 'email',
    label: 'Email',
  },
]

const emailExtractor = (data: TicketTableGroupedData) => data.email
