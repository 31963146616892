import {useMemo} from 'react'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {BookingDetailModel} from '../../../../../models/customer-portal/BookingDetailModel'

export interface CustomerBookingTableActionsProps {
  onAssignSeats?: (data: BookingDetailModel) => void
  onFulfillNonSeatedProduct?: (data: BookingDetailModel) => void
  onAssignBoat?: (data: BookingDetailModel) => void
  data: BookingDetailModel
  loading?: boolean
}

export const CustomerBookingTableActions = ({
  data,
  onAssignSeats,
  onFulfillNonSeatedProduct,
  onAssignBoat,
  loading,
}: CustomerBookingTableActionsProps) => {
  const assignSeats = useMemo(() => {
    if (data.bookingProductStatus === 'pending' && data.isSeated) {
      return (
        <RowAction
          onClick={onAssignSeats}
          data={data}
          loading={loading}
          iconType='Home'
          iconName='Armchair'
          tooltip='Assign seats'
          variant='info'
        />
      )
    }
  }, [data, loading, onAssignSeats])

  const assignBoat = useMemo(() => {
    if (
      data.bookingProductStatus !== 'cancelled' &&
      data.isBoat &&
      data.bookingProductType === 'product'
    ) {
      return (
        <RowAction
          onClick={onAssignBoat}
          data={data}
          loading={loading}
          iconType='Electric'
          iconName='Iron'
          tooltip='Assign boat'
          variant='warning'
        />
      )
    }
  }, [data, loading, onAssignBoat])

  const fulfillNonSeatedProduct = useMemo(() => {
    if (data.bookingProductStatus === 'pending' && !data.isSeated) {
      return (
        <RowAction
          onClick={onFulfillNonSeatedProduct}
          data={data}
          loading={loading}
          iconType='Shopping'
          iconName='Barcode-read'
          tooltip={`Fulfill Non Seated ${
            data.bookingProductType === 'voucher' ? 'Voucher' : 'Product'
          }`}
          variant='warning'
        />
      )
    }
  }, [data, loading, onFulfillNonSeatedProduct])

  return (
    <>
      {assignBoat}
      {assignSeats}
      {fulfillNonSeatedProduct}
    </>
  )
}
