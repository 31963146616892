import {ReactNode, useMemo} from 'react'
import {ColorVariant} from '../../../../components/inputs/Button'
import {
  OrderSummaryItem,
  OrderSummaryItemAttributes as OrderSummaryItemAttributesBase,
} from './OrderSummaryItem'

export interface OrderSummaryItemAttributes extends OrderSummaryItemAttributesBase {
  id: string
}
export interface OrderSummaryProps {
  items: OrderSummaryItemAttributes[]
  variant: ColorVariant
}

export const OrderSummary = ({items, variant}: OrderSummaryProps) => {
  const orderSummaryItems = useMemo(() => {
    const orderSummaryItems: ReactNode[] = []
    items.forEach((item) => {
      orderSummaryItems.push(<OrderSummaryItem variant={variant} key={item.id} {...item} />)
    })
    return orderSummaryItems
  }, [items, variant])

  return (
    <table className='w-100'>
      <tbody>{orderSummaryItems}</tbody>
    </table>
  )
}
