import {Button} from '../../../../components/inputs/Button'
import {SweetAlert} from '../../../../components/modals/SweetAlert'

export interface NotEnoughBalanceProps {
  balance: number
  currency: string
  open: boolean
  onClose: () => void
  onCancel: () => void
  onUseAnother: () => void
}

export const NotEnoughBalanceModal = ({
  onClose,
  open,
  balance,
  currency,
  onCancel,
  onUseAnother,
}: NotEnoughBalanceProps) => {
  return (
    <SweetAlert open={open} onClose={onClose} icon='error' showConfirmButton={false}>
      <h1>Not enough balance</h1>
      <p>
        Your YasEats cash card does not have enough balance to cover the total payment amount.
        Kindly use another payment method.
      </p>
      <p className='fs-4 text-danger m-0'>Current Balance</p>
      <p className='text-danger'>
        {currency} {balance}
      </p>
      <Button onClick={onCancel} variant='text'>
        Cancel
      </Button>
      <Button className='rounded-0' onClick={onUseAnother} variant='success'>
        Use Another
      </Button>
    </SweetAlert>
  )
}
