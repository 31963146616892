import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {TicketCard} from './TicketCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../models/GlobalSearchModel'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {FilterInputContainer} from '../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {FilterProductInputMobile} from '../tables/AdvancedFilter/FilterProductInputMobile'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {FilterLocationInputMobile} from '../tables/AdvancedFilter/FilterLocationInputMobile'
import {FilterSeatNumberFromTo} from '../tables/AdvancedFilter/FilterSeatNumberFromTo'
import {CustomerSearchInputSelectItem} from '../inputs/CustomerSearchInput'
import {MultiSelectToggleFilterInputMobile} from '../tables/AdvancedFilter/MultiSelectToggleFilterInputMobile'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import clsx from 'clsx'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {CardCollapseRow} from '../CardCollapseRow'
import moment from 'moment'
import {MultiSelectInput} from '../../../../components/inputs/MultiSelectInput/MultiSelectInput'
import {Button} from '../../../../components/inputs/Button'
import {TicketTableSelectionActions} from '../tables/TicketTable/TicketTableSelectionActions'
import {useTicketTableActions} from '../../hooks/useTicketTableActions'
import {EmailModal, InitialValuesProps} from '../modals/EmailModal'
import {WhatsAppInitialValuesProps, WhatsAppModal} from '../modals/WhatsAppModal'
import {usePortalCardUtils} from '../../hooks/usePortalCardUtils'
export interface TicketCardGridProps {
  data?: GlobalSearchModel<TicketPortalModel> | GroupedSearchModel<TicketPortalModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  className?: string
  initialFilters?: FilterModel
  filters?: FilterModel
  tabCode?: string
  productCategoryCode?: string
  productCategorySlug?: string
}

export interface CollapsedProps {
  key: string
  value: boolean
}

export const TicketCardGrid = ({
  data,
  onFilter,
  onRefresh,
  className,
  initialFilters,
  filters,
  tabCode,
  productCategoryCode,
  productCategorySlug,
}: TicketCardGridProps) => {
  const [collapsedItem, setCollapsedItem] = useState<CollapsedProps>()
  const {setPageNumber, setPageSize} = useFilterState(onFilter, {
    initialFilters,
    filters,
  })

  const [filterProduct, setFilterProduct] = useState<CustomerSearchInputSelectItem | null>(null)
  const [filterLocation, setFilterLocation] = useState<CustomerSearchInputSelectItem | null>(null)
  const [filterShown, setFilterShown] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [selectedTickets, setSelectedTickets] = useState<TicketPortalModel[]>()
  const [openEmailModel, setOpenEmailModel] = useState(false)
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)

  const clearSelected = useCallback(() => {
    setSelected([])
  }, [])

  const {isLoading, handleShareByEmailBulk, handleShareByWhatsAppBulk} = useTicketTableActions({
    onRefresh,
    clearSelected,
  })

  const {
    selectionAllState,
    tableSelectionItems,
    onSelectGroupHandler,
    onSelectHandler,
    handleSelectAll,
  } = usePortalCardUtils<TicketPortalModel>({
    data,
    selected,
    setSelected,
  })

  const toggleFilter = useCallback(() => {
    setFilterShown(!filterShown)
  }, [filterShown])

  useOnChange(data, () => {
    if (data && !Array.isArray(data)) {
      const firstRow = Object.keys(data.data)[0]
      setCollapsedItem({
        key: firstRow,
        value: true,
      })
    }
  })

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onFilter?.({
        filters: {...filters?.filters, search: e.target.value},
      })
    },
    [filters?.filters, onFilter]
  )

  useOnChange(filterLocation, () => {
    onFilter?.({
      filters: {
        ...filters?.filters,
        locationCode: filterLocation ? filterLocation.code : undefined,
      },
    })
  })

  useOnChange(filterProduct, () => {
    onFilter?.({
      filters: {
        ...filters?.filters,
        productOrParentProduct: filterProduct ? filterProduct.code : undefined,
      },
    })
  })

  const toggleCollapse = useCallback(
    (key: string) => {
      const value = collapsedItem && collapsedItem.key === key ? !collapsedItem.value : true
      setCollapsedItem({
        key: key,
        value,
      })
    },
    [collapsedItem]
  )

  const seatFromValue = useMemo(() => {
    return parseInt(filters?.filters?.seatFrom?.toString() || '', 10)
  }, [filters?.filters?.seatFrom])

  const seatToValue = useMemo(() => {
    return parseInt(filters?.filters?.seatTo?.toString() || '', 10)
  }, [filters?.filters?.seatTo])

  const cards = useMemo(() => {
    if (data && data?.data) {
      return (
        <div className={clsx('row')}>
          {Array.isArray(data.data)
            ? data.data.map((ticket) => (
                <div key={ticket.code} className='mb-5 col-12'>
                  <TicketCard
                    className='h-100'
                    data={ticket}
                    tabCode={tabCode}
                    productCategoryCode={productCategoryCode}
                    onSelectHandler={onSelectHandler}
                    selected={selected}
                  />
                </div>
              ))
            : Object.entries(data.data).map(([ticketKey, tickets], index, keys) => {
                const isLast = index === keys.length - 1
                const keyValue =
                  filters?.groupBy === 'batchId'
                    ? ticketKey.match(BATCH_ID_REGEX)
                      ? ticketKey.match(BATCH_ID_REGEX)?.[1]
                      : ticketKey
                    : ticketKey

                return (
                  <>
                    <div className='col-12'>
                      <CardCollapseRow
                        collapseKey={ticketKey}
                        keyValue={keyValue}
                        collapsedItem={collapsedItem}
                        onClick={(key) => toggleCollapse(key)}
                        onSelectGroupHandler={(key) => onSelectGroupHandler(key, tickets)}
                        selected={selected}
                        items={tickets}
                      />
                    </div>

                    {collapsedItem?.value && collapsedItem.key === ticketKey && (
                      <div>
                        {tickets &&
                          tickets.length > 0 &&
                          tickets.map((ticket: TicketPortalModel) => {
                            return (
                              <div key={ticket.code} className='mb-3 col-12'>
                                <TicketCard
                                  className='h-100'
                                  data={ticket}
                                  tabCode={tabCode}
                                  productCategoryCode={productCategoryCode}
                                  onSelectHandler={onSelectHandler}
                                  selected={selected}
                                />
                              </div>
                            )
                          })}
                      </div>
                    )}
                    {!isLast && (
                      <div className='col-12 px-4'>
                        <HorizontalDivider className='bg-secondary' spacing={1} />
                      </div>
                    )}
                  </>
                )
              })}
        </div>
      )
    }
    return null
  }, [
    data,
    tabCode,
    productCategoryCode,
    onSelectHandler,
    selected,
    filters?.groupBy,
    collapsedItem,
    onSelectGroupHandler,
    toggleCollapse,
  ])

  const searchAction = useMemo(() => {
    return (
      <div className='w-100'>
        <div className='table-left-toolbar'>
          <TextInput
            className='filter-table-search-input'
            inputClassName='rounded-0'
            inputWrapperClassName='bg-primary-cp-dark rounded-0'
            noMargin
            placeholder='Search'
            value={filters?.filters?.search?.toString()}
            onChange={handleSearchChange}
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='General' iconName='Search' />
            }
          />
        </div>
      </div>
    )
  }, [filters?.filters?.search, handleSearchChange])

  const handleSeatRowChange = useCallback(
    (e) => {
      onFilter({filters: {...filters?.filters, seatRow: e.target.value}})
    },
    [onFilter, filters?.filters]
  )

  const handleSeatNumberFrom = useCallback(
    (value?: number) => {
      onFilter({filters: {...filters?.filters, seatFrom: value ? value.toString() : undefined}})
    },
    [onFilter, filters?.filters]
  )
  const handleSeatNumberTo = useCallback(
    (value?: number) => {
      onFilter({filters: {...filters?.filters, seatTo: value ? value.toString() : undefined}})
    },
    [onFilter, filters?.filters]
  )

  const handleSearchReservation = useCallback(
    (e) => {
      onFilter({
        filters: {...filters?.filters, search: '', bookingReservationNo: e.target.value},
      })
    },
    [filters?.filters, onFilter]
  )

  const handleChangeFilterByDay = useCallback(
    (value) => {
      onFilter({filters: {...filters?.filters, day: value}})
    },
    [filters?.filters, onFilter]
  )

  const handleFilterTicketStatus = useCallback(
    (value) => {
      onFilter?.({
        filters: {...filters?.filters, statusActivity: value},
      })
    },
    [filters?.filters, onFilter]
  )

  const statusActivity = useMemo(() => {
    if (filters && filters.filters && Array.isArray(filters.filters.statusActivity)) {
      return filters.filters.statusActivity
    }
    return []
  }, [filters])

  const advancedFiltersNode = useMemo(() => {
    return (
      <FilterInputContainer>
        <FilterProductInputMobile
          placeholder='Product'
          label='Product'
          classes={{
            input: 'search-cp-field',
          }}
          productCategorySlug={productCategorySlug}
          icon={{
            iconType: 'Food',
            iconName: 'Bread',
            color: 'white',
          }}
          productValue={filterProduct}
          setProduct={setFilterProduct}
        />

        <FilterLocationInputMobile
          classes={{
            input: 'search-cp-field',
          }}
          placeholder='Select section'
          label='Section'
          locationValue={filterLocation}
          productCategorySlug={productCategorySlug}
          productCode={filters?.filters?.productOrParentProduct}
          icon={{
            iconType: 'Design',
            iconName: 'Union',
            color: 'white',
          }}
          setLocation={setFilterLocation}
        />

        <MultiSelectInput
          inputClassName='search-cp-field'
          endAdornment={<MetronicIcon iconType='Home' iconName='Clock' size='md' color='white' />}
          hasSelectAll
          allowClear
          placeholder={`Filter Day`}
          items={TICKET_DAYS}
          label='Day'
          value={Array.isArray(filters?.filters?.day) ? filters?.filters?.day : undefined}
          onChange={handleChangeFilterByDay}
          className='mw-100'
          isLabelUpperCase
        />

        <div className='flex-grow-1'>
          <TextInput
            fullWidth
            label='Seat Row'
            className='filter-table-search-input mw-100'
            noMargin
            placeholder='Seat Row'
            value={filters?.filters?.seatRow?.toString() || ''}
            onChange={handleSeatRowChange}
            inputWrapperClassName='search-cp-field'
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='Home' iconName='Chair2' />
            }
          />
        </div>

        <div className='mt-3'>
          <FilterSeatNumberFromTo
            className='flex-grow'
            from={seatFromValue}
            to={seatToValue}
            setSeatNumberFrom={(value) => handleSeatNumberFrom(value)}
            setSeatNumberTo={(value) => handleSeatNumberTo(value)}
            min={0}
            max={Infinity}
            inputWrapperClassName='search-cp-field'
          />
        </div>
        <div className='mt-3'>
          <MultiSelectToggleFilterInputMobile
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='Text' iconName='Align-justify' />
            }
            items={TICKET_STATUS}
            label='Activity Status'
            values={statusActivity}
            setValues={handleFilterTicketStatus}
            isLabelUpperCase
            inputClassName='search-cp-field'
          />
        </div>
        <div className='mt-3'>
          <TextInput
            fullWidth
            label='Reservation'
            className='fsearch-cp-field mw-100'
            noMargin
            placeholder='Search by reservation No'
            value={filters?.filters?.bookingReservationNo?.toString() || ''}
            onChange={handleSearchReservation}
            inputWrapperClassName='search-cp-field'
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='General' iconName='Search' />
            }
          />
        </div>
      </FilterInputContainer>
    )
  }, [
    filterLocation,
    filterProduct,
    filters?.filters?.bookingReservationNo,
    filters?.filters?.day,
    filters?.filters?.productOrParentProduct,
    filters?.filters?.seatRow,
    handleChangeFilterByDay,
    handleFilterTicketStatus,
    handleSearchReservation,
    handleSeatNumberFrom,
    handleSeatNumberTo,
    handleSeatRowChange,
    productCategorySlug,
    seatFromValue,
    seatToValue,
    statusActivity,
  ])

  const handleOnShareByEmail = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (data) {
      if (!Array.isArray(data)) setSelectedTickets([data])
      else setSelectedTickets(data)
    }
    setOpenEmailModel(true)
  }, [])

  const handleOnShareWhatsApp = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (!Array.isArray(data)) setSelectedTickets([data])
    else setSelectedTickets(data)
    setOpenWhatsAppModel(true)
  }, [])

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (selectedTickets) {
        const response = await handleShareByEmailBulk(values, selectedTickets)
        if (response) return response
      }
    },
    [selectedTickets, handleShareByEmailBulk]
  )

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialValuesProps) => {
      if (selectedTickets) {
        const response = await handleShareByWhatsAppBulk(values, selectedTickets)
        if (response) return response
      }
    },
    [selectedTickets, handleShareByWhatsAppBulk]
  )

  return (
    <div className={className}>
      <div className='d-flex justify-content-end align-items-start gap-3 flex-grow-1 pt-4'>
        <div className='flex-grow-1'>{searchAction}</div>
        <div
          className={clsx(
            'px-4 cursor-pointer d-flex justify-content-between filter-cp-button',
            filterShown ? 'mobile-active' : 'mobile-not-active'
          )}
          onClick={toggleFilter}
        >
          <div className='mt-3'>
            <span className='fs-5 fw-bold text-white ff-admm-bold text-uppercase'>Filter</span>
          </div>
          <div>
            <MetronicIconButton
              iconType='Text'
              iconName='Filter'
              size='md'
              color='white'
              tooltip='Toggle filter'
              className='bg-transparent'
            />
          </div>
        </div>
      </div>

      {filterShown && (
        <div className='p-4 mh-250px overflow-auto filters-box-cp'>{advancedFiltersNode}</div>
      )}
      {selected && selected.length > 0 && (
        <div
          className={clsx('mt-2', filterShown && 'mt-5')}
        >{`SELECTED RECORDS COUNT: ${selected.length}`}</div>
      )}

      <div
        className={clsx(
          'mb-5',
          filterShown ? 'mt-5' : selected && selected.length > 0 ? 'mt-5' : 'mt-2'
        )}
      >
        <Button
          size='sm'
          type='button'
          className={clsx('btn-select-all-cp text-white rounded-0', selectionAllState && 'active')}
          onClick={handleSelectAll}
        >
          Select All
        </Button>
      </div>

      <div
        className={clsx('d-flex gap-3 flex-wrap filters-box-cp', selected.length > 0 && 'p-3 mb-5')}
      >
        <TicketTableSelectionActions
          items={tableSelectionItems}
          selected={selected}
          onShareByEmail={handleOnShareByEmail}
          onShareByWhatsApp={handleOnShareWhatsApp}
          // onDownload={handleDownloadBulk}
          isLoading={isLoading}
          size='sm'
          // className='btn-dark-primary-cp rounded-0'
        />
      </div>

      <div>{cards}</div>
      <div>
        <PaginationHelper
          currentPageNumber={filters && filters.page ? filters.page : 1}
          currentPageSize={filters && filters.limit ? filters.limit : 10}
          onChangePageNumber={setPageNumber}
          onChangePageSize={setPageSize}
          total={data?.total}
        />
      </div>

      {selectedTickets && (
        <EmailModal
          data={selectedTickets}
          setOpen={setOpenEmailModel}
          open={openEmailModel}
          onSubmit={handleSendEmail}
        />
      )}

      {selectedTickets && (
        <WhatsAppModal
          data={selectedTickets}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
        />
      )}
    </div>
  )
}

const TICKET_STATUS: SelectInputItem[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Shared',
    value: 'shared',
  },
  {
    label: 'Sent',
    value: 'sent',
  },
  {
    label: 'Downloaded',
    value: 'downloaded',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
]

const BATCH_ID_REGEX = /(.+) \(.+\)/

const TICKET_DAYS: SelectInputItem[] = [
  {
    label: 'Thursday',
    value: moment('17-11-2022', 'DD-MM-YYYY').toISOString(),
  },
  {
    label: 'Friday',
    value: moment('18-11-2022', 'DD-MM-YYYY').toISOString(),
  },
  {
    label: 'Saturday',
    value: moment('19-11-2022', 'DD-MM-YYYY').toISOString(),
  },
  {
    label: 'Sunday',
    value: moment('20-11-2022', 'DD-MM-YYYY').toISOString(),
  },
]
