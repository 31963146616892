import {Fragment, ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import {Spinner} from '../../utils/Spinner'
import {Toolbar} from './Toolbar'
import {ToolbarTitle} from './ToolbarTitle'

export interface LinkBreadCrumb {
  link?: string
  label: string
}

export interface LinkBreadcrumbsToolbarProps {
  title?: ReactNode
  isLoading?: boolean
  breadcrumbs?: LinkBreadCrumb[]
  children?: ReactNode
}

export const LinkBreadcrumbsToolbar = ({
  isLoading,
  breadcrumbs,
  title,
  children,
}: LinkBreadcrumbsToolbarProps) => {
  if (isLoading) {
    return (
      <Toolbar>
        <div className='h-100 d-flex align-items-center'>
          <Spinner size='lg'></Spinner>
        </div>
      </Toolbar>
    )
  }

  return (
    <Toolbar>
      <div className='d-flex h-100'>
        <div className='d-flex flex-column h-100 justify-content-center'>
          <div>{typeof title === 'string' ? <ToolbarTitle>{title}</ToolbarTitle> : title}</div>
          <div>
            {breadcrumbs &&
              breadcrumbs.length > 1 &&
              breadcrumbs.map((crumb, index) => {
                const hasNext = index < breadcrumbs.length - 1
                return (
                  <Fragment key={crumb.link + crumb.label}>
                    {crumb.link && hasNext ? (
                      <>
                        <Link to={crumb.link}>{crumb.label}</Link>
                        {hasNext && (
                          <MetronicIcon
                            iconType='Navigation'
                            iconName='Angle-right'
                            color='primary'
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {crumb.label}
                        {hasNext && (
                          <MetronicIcon
                            iconType='Navigation'
                            iconName='Angle-right'
                            color='primary'
                          />
                        )}
                      </>
                    )}
                  </Fragment>
                )
              })}
          </div>
        </div>
        <div className='flex-grow-1'>{children}</div>
      </div>
    </Toolbar>
  )
}
