import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, debounce} from 'redux-saga/effects'
import {AppConfigModel} from '../../../models/app-config/AppConfigModel'
import {ActionWithPayload} from '../../default/auth'
import {GetAllLanguages, GetAppConfig} from './AppConfigCRUD'
import {actionTypes as customerPortalActionTypes} from '../../customer-portal/redux/CustomerPortalRedux'
import {actionTypes as defaultAuthActionTypes} from '../../default/auth/redux/AuthRedux'
import {actionTypes as outletActionTypes} from '../../outlet/redux/OutletRedux'
import {EventModel} from '../../../models/ems/EventModel'
import {GetDefaultEvent} from '../../default/ems/redux/EmsCRUD'
import {LanguageModel} from '../../../models/LanguageModel'

export const actionTypes = {
  config: {
    GET_APP_CONFIG: '[APP CONFIG] GET APP CONFIG',
    GET_APP_CONFIG_SUCCESS: '[APP CONFIG] GET APP CONFIG SUCCESS',
    GET_APP_CONFIG_FAILED: '[APP CONFIG] GET APP CONFIG FAILED',
  },
  defaultEvent: {
    GET_DEFAULT_EVENT: '[APP CONFIG] GET DEFAULT EVENT',
    GET_DEFAULT_EVENT_SUCCESS: '[APP CONFIG] GET DEFAULT EVENT SUCCESS',
    GET_DEFAULT_EVENT_FAILED: '[APP CONFIG] GET DEFAULT EVENT FAILED',
  },
  languages: {
    GET_LANGUAGES: '[APP CONFIG] GET LANGUAGES',
    GET_LANGUAGES_SUCCESS: '[APP CONFIG] GET LANGUAGES SUCCESS',
    GET_LANGUAGES_FAILED: '[APP CONFIG] GET LANGUAGES FAILED',
  },
}

export interface AppConfigReduxState {
  config?: AppConfigModel[]
  defaultEvent?: EventModel | null
  languages?: LanguageModel[]
}

const initialState = {}

export const reducer = persistReducer<AppConfigReduxState, ActionWithPayload<any>>(
  {
    storage,
    key: 'webapp-app-config',
    whitelist: ['config', 'defaultEvent'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.languages.GET_LANGUAGES_SUCCESS: {
        const languages = action.payload?.languages
        return {...state, languages}
      }
      case actionTypes.config.GET_APP_CONFIG_SUCCESS:
        return {...state, config: action.payload}
      case actionTypes.defaultEvent.GET_DEFAULT_EVENT_SUCCESS:
        return {...state, defaultEvent: action.payload}
      default:
        return state
    }
  }
)

export function* saga() {
  yield takeLatest(
    [actionTypes.languages.GET_LANGUAGES, defaultAuthActionTypes.UserLoaded],
    function* afterEffectSaga() {
      try {
        const {data} = yield GetAllLanguages()
        yield put(actions.getLanguagesSuccess(data))
      } catch (e) {
        yield put(actions.getLanguagesFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.config.GET_APP_CONFIG,
      customerPortalActionTypes.auth.fulfillAuth,
      defaultAuthActionTypes.UserLoaded,
      outletActionTypes.auth.fulfillAuth,
    ],
    function* getAppConfig() {
      try {
        const {data} = yield GetAppConfig()
        yield put(actions.getAppConfigSuccess(data))
      } catch (e) {
        yield put(actions.getAppConfigFailed(e))
      }
    }
  )

  yield debounce(
    500,
    [actionTypes.defaultEvent.GET_DEFAULT_EVENT, defaultAuthActionTypes.UserLoaded],
    function* getAppConfig() {
      try {
        const {data} = yield GetDefaultEvent()
        yield put(actions.getDefaultEventSuccess(data))
      } catch (e) {
        yield put(actions.getDefaultEventFailed(e))
      }
    }
  )
}

export const actions = {
  getAppConfig: () => ({
    type: actionTypes.config.GET_APP_CONFIG,
  }),
  getAppConfigSuccess: (data: AppConfigModel[]) => ({
    type: actionTypes.config.GET_APP_CONFIG_SUCCESS,
    payload: data,
  }),
  getAppConfigFailed: (error: Error) => ({
    type: actionTypes.config.GET_APP_CONFIG_FAILED,
    payload: error,
  }),
  getDefaultEvent: () => ({
    type: actionTypes.defaultEvent.GET_DEFAULT_EVENT,
  }),
  getDefaultEventSuccess: (data: EventModel | null) => ({
    type: actionTypes.defaultEvent.GET_DEFAULT_EVENT_SUCCESS,
    payload: data,
  }),
  getDefaultEventFailed: (error: Error) => ({
    type: actionTypes.defaultEvent.GET_DEFAULT_EVENT_FAILED,
    payload: error,
  }),
  getLanguages: () => ({
    type: actionTypes.languages.GET_LANGUAGES,
  }),
  getLanguagesSuccess: (languages: LanguageModel[]) => ({
    type: actionTypes.languages.GET_LANGUAGES_SUCCESS,
    payload: {languages},
  }),
  getLanguagesFailed: () => ({
    type: actionTypes.languages.GET_LANGUAGES_FAILED,
  }),
}
