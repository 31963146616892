import currency from 'currency.js'
import {PriceTag} from './PriceTag'

export class VatExclusivePriceTag extends PriceTag {
  public getVatInclusivePrice(multiplier: number = 1) {
    return currency(this.rawPrice)
      .add(currency(this.rawPrice).multiply(this.percentVat))
      .multiply(multiplier).value
  }
  public getVatExclusivePrice(multiplier: number = 1) {
    return currency(this.rawPrice).multiply(multiplier).value
  }
  public getVatPrice(multiplier: number = 1) {
    return currency(currency(this.rawPrice).multiply(this.percentVat)).multiply(multiplier).value
  }
}
