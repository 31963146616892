import {useCallback, useEffect, useState} from 'react'
import {FilterModel} from '../../../../models/FilterModel'

export interface TicketFiltersGridProps {
  onClick?: (item: MenuItem) => void
  filters?: FilterModel
}
export const TicketFiltersGrid = ({onClick, filters}: TicketFiltersGridProps) => {
  const [menu, setMenu] = useState<MenuItem[]>([])

  useEffect(() => {
    setMenu(MENU_ITEMS)
  }, [])

  const handleChange = useCallback(
    (item: MenuItem) => {
      let newMenuItems = [...menu]
      setMenu(newMenuItems)
      onClick?.(item)
    },
    [menu, onClick]
  )

  const isChecked = useCallback(
    (item: MenuItem) => {
      if (item.value === 'available') {
        return filters?.filters?.statusPortal === 'active'
      } else {
        if (
          Array.isArray(filters?.filters?.dwStatus) &&
          filters?.filters?.dwStatus.find((status) => status === item.value)
        ) {
          return true
        } else return false
      }
    },
    [filters?.filters?.dwStatus, filters?.filters?.statusPortal]
  )

  return (
    <div className='d-flex gap-5 flex-wrap align-items-center'>
      <p className='text-white fs-6 fw-bolder mb-0'>Status: </p>
      {menu &&
        menu.length &&
        menu.map((item) => {
          const checked = isChecked(item)
          return (
            <div key={item.value} className='d-flex gap-2 align-items-center'>
              <input
                style={{
                  cursor: 'pointer',
                  width: '18px',
                  height: '18px',
                }}
                checked={checked}
                className='form-check-input me-2 bg-primary-dw-darker border border-1 border-primary'
                type='checkbox'
                onChange={() => handleChange(item)}
              />

              <span
                className='mb-0 fw-bolder flex-grow-1'
                style={{
                  color: getFilterStatusVariant(item.value),
                }}
              >
                {item.title}
              </span>
            </div>
          )
        })}
    </div>
  )
}

export interface MenuItem {
  title: string
  value: string
}

const MENU_ITEMS: MenuItem[] = [
  {
    title: 'Awaiting submission',
    value: 'available',
  },
  // {
  //   title: 'Pending',
  //   value: 'pending',
  // },
  {
    title: 'Awaiting guardian',
    value: 'awaiting-guardian',
  },

  {
    title: 'Submitted',
    value: 'submitted',
  },

  {
    title: 'Rejected',
    value: 'rejected',
  },
  {
    title: 'Approved',
    value: 'approved',
  },
  // {
  //   title: 'Review',
  //   value: 'review',
  // },
]

export const getFilterStatusVariant = (status: string, transparent?: string) => {
  if (!transparent) {
    transparent = 'ff'
  }
  switch (status) {
    case 'pending':
      return `#B4654A${transparent}`
    case 'awaiting-guardian':
      return `#b66d2b${transparent}`
    case 'approved':
      return `#0D7B40${transparent}`
    case 'submitted':
      return `#198996${transparent}`
    case 'rejected':
      return `#A50000${transparent}`
    case 'review':
      return `#0d617b${transparent}`
    case 'available':
      return `#d09700${transparent}`
    default:
      return `#D09700${transparent}`
  }
}
