import clsx from 'clsx'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'

export interface HeaderProps {
  onBackClick?: () => void
  title: string
  className?: string
}

export const Header = ({className, title, onBackClick}: HeaderProps) => {
  return (
    <div className={clsx('d-flex align-items-center', className)}>
      {onBackClick && (
        <button className='btn btn-icon' onClick={onBackClick}>
          <MetronicIcon
            className='svg-icon-success m-0'
            iconType='Navigation'
            iconName='Angle-left'
            size='lg'
          />
        </button>
      )}
      <h1
        className={clsx('fw-bolder text-uppercase m-0 fs-2', {
          'ms-8': Boolean(onBackClick),
          'ms-3': !onBackClick,
        })}
      >
        {title}
      </h1>
    </div>
  )
}
