import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {Tab} from './Tab'

export interface TabListProps {
  tabs: SelectInputItem[]
  value: string
  onClick: (value: string) => void
  className?: string
}

export const TabList = ({onClick, value, tabs, className}: TabListProps) => {
  const handleClick = useCallback(
    (value: string) => {
      onClick(value)
    },
    [onClick]
  )

  const tabNodes = useMemo(() => {
    return tabs.map((category) => {
      return (
        <Tab
          key={category.value}
          onClick={handleClick}
          isActive={value === category.value}
          {...category}
        />
      )
    })
  }, [tabs, handleClick, value])

  return (
    <div className={clsx('d-flex overflow-auto', className)}>
      <ul className='nav nav-tabs nav-line-tabs nav-tabs-primary nav-line-tabs-2x justify-content-center flex-grow-1 fs-1 fw-bold flex-nowrap h-100'>
        {tabNodes}
      </ul>
    </div>
  )
}
