import {useMemo} from 'react'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {FileModel} from '../../../../../models/FileModel'
import {useAppConfig} from '../../../../app-config/hooks/useAppConfig'

export interface TableAvatarImageColumnProps {
  image: FileModel
  alt: string
}

export const TableAvatarImageColumn = ({image, alt}: TableAvatarImageColumnProps) => {
  const {staticUrls} = useAppConfig()

  const src = useMemo(() => {
    const imageValue = new ImageInputValue(staticUrls.public, image)
    return imageValue.url
  }, [image, staticUrls.public])

  return (
    <img
      style={{
        height: '30px',
        width: '30px',
      }}
      src={src}
      alt={alt}
    />
  )
}
