import {useState, createContext, useMemo, useContext, ReactNode} from 'react'

const LayoutPortalContext = createContext<LayoutPortalContextData | null>(null)

export interface LayoutPortalContextData {
  toolbar: HTMLElement | null
  headerContent: HTMLElement | null
  headerControls: HTMLElement | null
  setToolbar: (el: HTMLElement | null) => void
  setHeaderContent: (el: HTMLElement | null) => void
  setHeaderControls: (el: HTMLElement | null) => void
}

export interface LayoutPortalProviderProps {
  children?: ReactNode
}

export const LayoutPortalProvider = ({children}: LayoutPortalProviderProps) => {
  const [toolbar, setToolbar] = useState<HTMLElement | null>(null)
  const [headerContent, setHeaderContent] = useState<HTMLElement | null>(null)
  const [headerControls, setHeaderControls] = useState<HTMLElement | null>(null)

  const value = useMemo(
    (): LayoutPortalContextData => ({
      toolbar,
      headerContent,
      headerControls,
      setToolbar,
      setHeaderContent,
      setHeaderControls,
    }),
    [headerContent, headerControls, toolbar]
  )

  return <LayoutPortalContext.Provider value={value}>{children}</LayoutPortalContext.Provider>
}

export const useLayoutPortalData = () => {
  const data = useContext(LayoutPortalContext)

  if (!data) {
    throw new Error('No portal provider.')
  }

  return data
}
