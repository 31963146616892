import {useCallback, useMemo} from 'react'
import {MetronicIcon} from '../../MetronicIcon'
import {MetronicIconButton} from '../../MetronicIconButton'
import {BaseFileInputValue} from '../BaseFileInputValue'
import {ImageInputValue} from '../ImageInputValue'

export interface DragDropFileInputDragAreaThumbnailProps {
  file: BaseFileInputValue
  onClose: (file: BaseFileInputValue) => void
  disabled?: boolean
  onClick?: () => void
}

export const DragDropFileInputDragAreaThumbnail = ({
  file,
  onClose,
  disabled,
  onClick,
}: DragDropFileInputDragAreaThumbnailProps) => {
  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      onClose && onClose(file)
    },
    [file, onClose]
  )

  const thumbnail = useMemo(() => {
    if (file instanceof ImageInputValue) {
      return (
        <img
          className='drag-drop-file-input-drop-area-thumbnail__image'
          src={file.url}
          alt={file.filename}
        />
      )
    }
    return (
      <div className='h-100 d-flex flex-column justify-content-center'>
        <MetronicIcon iconType='Files' iconName='File' size='xl' />
        <span className='fs-8 lh-1 overflow-hidden'>{file.filename}</span>
      </div>
    )
  }, [file])

  return (
    <div
      className='drag-drop-file-input-drop-area-thumbnail'
      onClick={(e) => {
        if (onClick) {
          e.preventDefault()
          onClick()
        }
      }}
    >
      {!disabled && (
        <MetronicIconButton
          type='button'
          className='btn-danger drag-drop-file-input-drop-area-thumbnail__close-button'
          iconType='Navigation'
          iconName='Close'
          variant='danger'
          size='sm'
          onClick={handleClose}
        />
      )}
      {thumbnail}
    </div>
  )
}
