import {useMemo, useCallback} from 'react'
import {ColumnStyle} from '../../../../components/tables/constants/ColumnStyle'
import {FilterTable} from '../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../components/tables/TableColumn'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'

export interface CustomerImportModel {
  firstName: string
  lastName: string
  email: string
  mobile?: string
}

export interface CustomerImportTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<CustomerImportModel>
}

export const CustomerImportTable = ({onFilter, data}: CustomerImportTableProps) => {
  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const idExtractor = useCallback((data: CustomerImportModel, index: number) => {
    return `${data.firstName}${index}`
  }, [])

  return (
    <FilterTable
      onFilter={onFilter}
      idExtractor={idExtractor}
      data={tableItems}
      currentPageNumber={data?.page}
      columns={columns}
      totalItems={data?.total || 0}
      cornersClassName='table-cut-corner-polygon rs-gradient'
      paginationClassName='px-10 pb-10'
      className='rs-table-bg'
      customWrapper='rs-top'
    />
  )
}

const columns: TableColumnOptions<CustomerImportModel>[] = [
  {
    field: 'firstName',
    label: 'First Name',
    cellStyle: ColumnStyle.NAME,
    sortable: true,
  },
  {
    field: 'lastName',
    label: 'Last Name',
    cellStyle: ColumnStyle.DESCRIPTION,
    sortable: true,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    field: 'mobile',
    label: 'Mobile',
    sortable: true,
  },
]
