import {useCallback, useMemo} from 'react'
// import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {CustomerModel} from '../../../models/CustomerModel'
import {EventModel} from '../../../models/ems/EventModel'
import {FilterModel} from '../../../models/FilterModel'
import {TicketTable} from '../../customer-delegate/components/tables/TicketTable/TicketTable'
import {TicketCardGrid} from '../components/TicketCard/TicketCardGrid'
// import {actions} from '../redux/CustomerPortalRedux'
interface TicketsPageProps {
  event?: EventModel
  customer?: CustomerModel
  bookingProductCode?: string
}

export const TicketsPage = ({event, customer, bookingProductCode}: TicketsPageProps) => {
  const tickets = useRootStateSelector((state) => state.customerPortal.tickets)
  const searchDebounce = useDebounce(200)
  const {setFilter} = useEntityFilter('customer-portal-ticket')
  // const dispatch = useDispatch()
  const refresh = useCallback(() => {
    searchDebounce(() => {
      // dispatch(actions.tickets.search())
    })
  }, [searchDebounce])

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active'],
        type: ['e-ticket'],
        eventCode: event?.code,
        customer: customer?.code,
        bookingProductCode: bookingProductCode,
      },
    }
    return filters
  }, [bookingProductCode, customer?.code, event?.code])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refresh()
    },
    [setFilter, refresh]
  )

  return (
    <>
      <div className='d-none d-md-block'>
        <TicketTable
          initialFilters={initialFilters}
          data={tickets}
          onFilter={handleFilter}
          onRefresh={refresh}
          event={event}
          customer={customer}
        />
      </div>
      <TicketCardGrid
        initialFilters={initialFilters}
        className='d-none'
        onRefresh={refresh}
        data={tickets}
        onFilter={handleFilter}
      />
    </>
  )
}
