import {ReactNode} from 'react'

export interface HeaderWrapperProps {
  children?: ReactNode
  className?: string
}

export function Header({children, className}: HeaderWrapperProps) {
  return <nav className='navbar'>{children}</nav>
}
