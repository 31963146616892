import {useMemo} from 'react'
import {Logo404} from '../../../../config/logos'
import styles from './Page404.module.scss'

export interface Page404Props {
  link?: string
}

export const Page404 = ({link}: Page404Props) => {
  const image = useMemo(() => {
    const image = <img className={styles.logo} src={Logo404.src} alt={Logo404.alt} />
    if (link) {
      return <a href='https://webntech.ae'>{image}</a>
    }
    return image
  }, [link])

  return (
    <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
      {image}
      <h1 className='text-center mt-5'>404</h1>
      <p className='text-center fs-4'>This page is not available.</p>
      {link && (
        <a className='text-center text-decoration-underline' href={link}>
          Take me somewhere else.
        </a>
      )}
    </div>
  )
}
