import {useCallback, useMemo, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {EVENT_CODE} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {Spinner} from '../../../components/utils/Spinner'
import {TopicModel} from '../../../models/eva/TopicModel'
import {FileList} from '../components/CustomerList/FileList'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {TopicList} from '../components/TopicList'
import {GetTopicByCode} from '../redux/EvaCRUD'
import {Button} from '../../../components/inputs/Button'
import {usePresentationWebSockets} from '../hooks/usePresentationWebSockets'

export const ViewTopic = () => {
  const [topic, setTopic] = useState<TopicModel>()
  const {topicCode} = useParams<{topicCode?: string}>()
  const {pushError} = useAlerts()
  const history = useHistory()
  const {endPresentation, isPresentationPlaying, isSpeaker, hasPresentations, startPresentation} =
    usePresentationWebSockets({topic})

  const resetTopicData = useCallback(async () => {
    if (topicCode && EVENT_CODE) {
      try {
        const {data} = await GetTopicByCode(topicCode)
        setTopic(data)
      } catch (e) {
        pushError(e)
      }
    }
  }, [topicCode, pushError])

  // const handleCustomerClick = useCallback(
  //   (customer: CustomerModel) => {
  //     history.push({
  //       pathname: `/attendees/${customer.code}`,
  //       state: {from: `/topics/${topicCode}`},
  //     })
  //   },
  //   [history, topicCode]
  // )

  const handleViewPresentation = useCallback(async () => {
    if (topic) {
      await startPresentation()
      history.push(`/topics/${topic.code}/presentations`)
    }
  }, [history, startPresentation, topic])

  const content = useMemo(() => {
    if (topic) {
      return (
        <>
          <h1>{topic.title}</h1>
          {topic.location && (
            <p className='mb-3 d-inline-flex align-contents-center'>
              <MetronicIcon className='me-1 svg-icon-primary' iconType='Map' iconName='Marker1' />
              {topic.location.name}
            </p>
          )}
          <p className='mb-10'>{topic.description}</p>
          {topic.children?.length ? (
            <div className='mb-10'>
              <h2 className='mb-3'>Sub-Topics</h2>
              <TopicList topics={topic.children} />
            </div>
          ) : null}
          {hasPresentations && (
            <div className='mb-10'>
              {isSpeaker && (
                <Button onClick={handleViewPresentation} variant='primary'>
                  {isPresentationPlaying ? 'Resume' : 'Start'} Presentation
                </Button>
              )}
              {isSpeaker && isPresentationPlaying && (
                <Button className='ms-3' onClick={endPresentation} variant='danger'>
                  Stop Presentation
                </Button>
              )}
              {!isSpeaker && isPresentationPlaying && (
                <Button variant='primary' onClick={handleViewPresentation}>
                  View Presentation
                </Button>
              )}
            </div>
          )}
          {topic.speakers?.length ? (
            <div className='mb-10'>
              <h2 className='fs-2 mb-3'>Speakers</h2>
              {/* TODO: Speakers is now SpeakerModel */}
              {/* <CustomerList customers={topic.speakers} onClick={handleCustomerClick} /> */}
            </div>
          ) : null}
          {topic.emsFiles?.length ? (
            <>
              <h2 className='mb-3'>Files</h2>
              <FileList files={topic.emsFiles} />
            </>
          ) : null}
        </>
      )
    }
    return (
      <div className='d-flex justify-content-center'>
        <Spinner size='lg' />
      </div>
    )
  }, [
    endPresentation,
    handleViewPresentation,
    hasPresentations,
    isPresentationPlaying,
    isSpeaker,
    topic,
  ])

  useOnChange(topicCode, () => {
    resetTopicData()
  })

  return <div className='p-5'>{content}</div>
}
