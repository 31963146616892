import {getUrlData} from '../../../../config/env'
import {useWebSockets} from '../../../components/hooks/useWebSockets'
import {BatchOrderModel} from '../../../models/fnb/BatchOrderModel'

export interface KdsWebSocketData {
  data: BatchOrderModel[]
}

export const useKdsWebSockets = (callback: (data: KdsWebSocketData) => void) => {
  useWebSockets<KdsWebSocketData>({
    socketName: `${getUrlData().outletCode?.toUpperCase()}:ORDERS:KDS`,
    callback,
  })
}
