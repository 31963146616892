import {useCallback, useMemo, useState} from 'react'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {useNumberState} from '../../../../components/hooks/useNumberState'
import {useOnMount} from '../../../../components/hooks/useOnMount'
import {OutletModel, OutletOrderType} from '../../../../models/fnb/OutletModel'
import {usePageNavigation} from '../../hooks/usePageNavigation'
import {CreateOrder, GetOutletByCode, GetTicketByCode} from '../../redux/DigitalMenuCRUD'
import {BasketContents} from './BasketContents'
import {DeliveryInformation} from './DeliveryInformation'
import {useCart} from '../../hooks/useCart'
import {OrderModelCreateParamsProducts} from '../../../../models/fnb/OrderModel'
import {TicketModel} from '../../../../models/ems/TicketModel'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {MobileNumberParser} from '../../../../utils/MobileNumberParser'
import {useCustomerProfile} from '../../hooks/useCustomerProfile'

interface PathParams {
  outletCode: string
  locationCode?: string
  ticketCode?: string
}

export interface CheckoutFormValues {
  customerName: string
  mobileNumber: string
  section: string
  row: string
  seat: string
  notes: string
  orderType: OutletOrderType
}

export const BasketPage = () => {
  const page = useNumberState(0)
  const {outletCode, locationCode: pathLocationCode, ticketCode} = useParams<PathParams>()
  const {goToOutletMenu, goToDraftedOrderCheckoutPage, getQueryParamsOrderType} =
    usePageNavigation()
  const [outlet, setOutlet] = useState<OutletModel>()
  const {cart} = useCart()
  const [ticket, setTicket] = useState<TicketModel>()
  const {pushError} = useAlerts()
  const {customerName, mobileNumber, setCustomerName, setMobileNumber} = useCustomerProfile()

  const orderType = getQueryParamsOrderType()

  useOnMount(async () => {
    const {data} = await GetOutletByCode(outletCode)
    setOutlet(data)
  })

  const goBackToOutlet = useCallback(() => {
    goToOutletMenu(outletCode)
  }, [goToOutletMenu, outletCode])

  const getLocationCode = useCallback(() => {
    if (ticket?.location) {
      return ticket.location.code
    } else if (ticket?.product?.locations && ticket.product.locations[0]) {
      return ticket.product.locations[0].code
    }
    return pathLocationCode
  }, [pathLocationCode, ticket])

  const formik = useFormik({
    initialValues: {
      ...EMPTY_INITIAL_FORM_VALUES,
      customerName,
      mobileNumber,
    },
    validationSchema,
    onSubmit: async (values) => {
      const products: OrderModelCreateParamsProducts[] = []

      cart.forEach((key, value) => {
        products.push({
          code: key,
          notes: '',
          qty: value.count,
        })
      })
      const locationCode = getLocationCode()
      if (locationCode) {
        try {
          const {data} = await CreateOrder({
            locationCode,
            name: values.customerName,
            outletCode,
            orderType: values.orderType,
            mobile: values.mobileNumber,
            products,
            seatNo: parseInt(values.seat),
            seatRow: values.row,
          })
          setCustomerName(values.customerName)
          setMobileNumber(values.mobileNumber)

          goToDraftedOrderCheckoutPage(data.code, {
            outletCode,
            locationCode: pathLocationCode,
            ticketCode,
            orderType,
          })
        } catch (e) {
          pushError(e)
        }
      }
    },
  })

  useOnMount(async () => {
    if (ticketCode) {
      const {data} = await GetTicketByCode(ticketCode)
      setTicket(data)
    }
  })

  useOnChange(orderType, () => {
    formik.setFieldValue('orderType', orderType)
  })

  const pageNode = useMemo(() => {
    switch (page.value) {
      case 0:
        return (
          <BasketContents
            formik={formik}
            outlet={outlet}
            onBack={goBackToOutlet}
            onConfirm={page.increment}
          />
        )
      case 1:
        return (
          <DeliveryInformation
            formik={formik}
            onBack={page.decrement}
            onConfirm={formik.handleSubmit}
          />
        )
      default:
        throw new Error('Invalid page')
    }
  }, [formik, goBackToOutlet, outlet, page.decrement, page.increment, page.value])

  return <>{pageNode}</>
}

const validationSchema = yup.object().shape({
  customerName: yup.string().required('Your name is required.'),
  mobileNumber: yup
    .string()
    .required('Mobile number is required')
    .test('is-valid-mobile', 'Please enter a valid mobile number.', (value) =>
      new MobileNumberParser(value || '').isValidMobileNumber()
    ),
  row: yup.string().when('orderType', {
    is: 'delivery',
    then: yup.string().required('Please enter your seat row.'),
  }),
  seat: yup.number().when('orderType', {
    is: 'delivery',
    then: yup.number().required('Please enter your seat row.').typeError('Please enter a number.'),
  }),
  section: yup.string(),
})

const EMPTY_INITIAL_FORM_VALUES: CheckoutFormValues = {
  orderType: 'delivery',
  customerName: '',
  mobileNumber: '',
  row: '',
  seat: '',
  section: '',
  notes: '',
}
