import {Redirect, Route, Switch} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {NavigatorLayout} from '../../modules/eva/layout/NavigatorLayout/NavigatorLayout'
import {AgendaPage} from '../../modules/eva/pages/AgendaPage'
import {AttendeesPage} from '../../modules/eva/pages/AttendeesPage/AttendeesPage'
import {ChatPage} from '../../modules/eva/pages/ChatPage'
import {ChatMessagePage} from '../../modules/eva/pages/ChatMessagePage/ChatMessagePage'
import {PollsPage} from '../../modules/eva/pages/PollsPage'
import {PollsRatingPage} from '../../modules/eva/pages/PollsRatingPage/PollsRatingPage'
import {HomePage} from '../../modules/eva/pages/HomePage'
import {ViewAttendeePage} from '../../modules/eva/pages/ViewAttendeePage/ViewAttendeePage'
import {ViewTopic} from '../../modules/eva/pages/ViewTopic'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {InfoPage} from '../../modules/eva/pages/InfoPage'
import {PresentationPage} from '../../modules/eva/pages/PresentationPage'
import {CutomerServiceDetails} from '../../modules/eva/pages/CustomerServiceDetails/CustomerServiceDetailsPage'
import {CustomerServicePDFPage} from '../../modules/eva/pages/CustomerServiceDetails/CustomerServicePDFPage'
import {LoginPage} from '../../modules/eva/pages/LoginPage'

export const Routes = () => {
  return (
    <>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Helmet>
      <Switch>
        <Route exact path='/login'>
          <NavigatorLayout>
            <LoginPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/agendas'>
          <NavigatorLayout>
            <AgendaPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/topics/:topicCode'>
          <NavigatorLayout>
            <ViewTopic />
          </NavigatorLayout>
        </Route>
        <Route exact path='/topics/:topicCode/presentations'>
          <PresentationPage />
        </Route>
        <Route exact path='/attendees'>
          <NavigatorLayout>
            <AttendeesPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/attendees/:userCode'>
          <NavigatorLayout>
            <ViewAttendeePage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/chat'>
          <NavigatorLayout>
            <ChatPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/chat/:code'>
          <NavigatorLayout>
            <ChatMessagePage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/polls'>
          <NavigatorLayout>
            <PollsPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/polls/:code'>
          <NavigatorLayout>
            <PollsRatingPage />
          </NavigatorLayout>
        </Route>

        <Route exact path='/customer-services/:serviceCode'>
          <NavigatorLayout>
            <CutomerServiceDetails />
          </NavigatorLayout>
        </Route>

        <Route exact path='/customer-services/:serviceCode/pdf/:pdfCode'>
          <NavigatorLayout>
            <CustomerServicePDFPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/info'>
          <NavigatorLayout>
            <InfoPage />
          </NavigatorLayout>
        </Route>
        <Route exact path='/eva' component={HomePage} />
        <Route exact path='/404' component={FullPage404} />
        <Route exact path='/403' component={FullPage403} />
        <Redirect to='/eva' />
      </Switch>
    </>
  )
}
