import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {useMemo} from 'react'

export const InstructionTooltip = () => {
  const toolTip = useMemo(() => {
    return (
      <div className='d-flex flex-row w-100' style={{textAlign: 'left'}}>
        <ul style={{marginBottom: '1px'}}>
          <li style={{marginBottom: '5px'}}>
            To select seated tickets, click on the chair icon below the 'Action' column.
          </li>
          <li style={{marginBottom: '5px'}}>
            Seated tickets are identified by a checkmark icon under the 'Seated?' column.
          </li>
          <li style={{marginBottom: '5px'}}>
            If you are reserving non-seated tickets, just click 'Done' to continue with your
            booking.
          </li>
        </ul>
      </div>
    )
  }, [])

  return (
    <div className='d-flex flex-row align-items-center'>
      <OverlayTrigger placement='left' overlay={<Tooltip>{toolTip}</Tooltip>}>
        <MetronicIconButton
          variant='text'
          size='md'
          iconType='General'
          iconName='Question-mark'
          tooltip=''
          onClick={() => {}}
          style={{marginRight: '10px', marginTop: '7px'}}
        />
      </OverlayTrigger>
    </div>
  )
}
