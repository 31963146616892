import {createContext, ReactNode} from 'react'

export const TooltipContext = createContext<any>(null)

export interface TooltipContextProviderProps<T> {
  value: T
  children?: ReactNode
}

export const TooltipContextProvider = <T,>({value, children}: TooltipContextProviderProps<T>) => {
  return <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
}
