import {useCallback, useMemo} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Drawer} from '../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../components/Drawer/useDrawerRef'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useDispatch} from 'react-redux'
import {actions} from '../../redux/EvaRedux'
import {SwalUtils} from '../../../../utils/SwalUtils'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useHistory} from 'react-router'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import clsx from 'clsx'
import {CustomerImage} from '../../components/CustomerImage/CustomerImage'
import SVG from 'react-inlinesvg'
import {useLocation} from 'react-router'
import {CustomerServicesList} from '../../components/CustomerServicesList'
import {CustomerServicesListModel} from '../../../../models/CustomerModel'
interface SideDrawerProps {
  drawerRef: (drawer: Drawer | null) => void
  isRouteActive: (path: string) => boolean
}
export const SideDrawer = ({drawerRef, isRouteActive}: SideDrawerProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {pathname} = useLocation()
  const {push, pushError} = useAlerts()
  const currentUser = useRootStateSelector((state) => state.eva?.user)
  const [drawer, setDrawer] = useDrawerRef()

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  const handleLogOut = useCallback(async () => {
    const confirmation = await SwalUtils.confirm({
      title: 'Are you sure you want to logout?',
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
    })
    if (confirmation.isConfirmed) {
      try {
        dispatch(actions.clearUser())
        push({
          message: `Logout Successfully.`,
          timeout: 3000,
          variant: 'success',
        })
        drawer?.hide()
      } catch (e) {
        pushError(e)
      }
    }
  }, [dispatch, drawer, push, pushError])

  const handleGoToService = useCallback(
    (service: CustomerServicesListModel) => {
      history.push(`/customer-services/${service.code}`, {
        from: '/customer-services',
        selectedService: service,
      })
      drawer && drawer.hide()
    },
    [drawer, history]
  )

  const polls = useMemo(() => {
    return (
      <div>
        <div
          role='button'
          className='d-flex justify-content-between align-items-center mb-3'
          onClick={() => {
            history.push('/polls')
            drawer?.hide()
          }}
        >
          <div className='d-flex align-items-center'>
            <div className='ms-1'>
              <h2 className={clsx('mb-0', isRouteActive('/polls') ? 'text-primary' : '')}>Polls</h2>
            </div>
          </div>
          <MetronicIcon iconType='Navigation' iconName='Angle-right' size='lg' />
        </div>
        <HorizontalDivider />
      </div>
    )
  }, [drawer, history, isRouteActive])

  return (
    <>
      <Drawer ref={handleDrawerRef}>
        <div className='p-5 w-100'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex items-center'>
              <h3 className='fw-bolder text-dark'>Services</h3>
            </div>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary'
              onClick={() => drawer?.hide()}
            >
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </button>
          </div>
          <HorizontalDivider />
          {currentUser && (
            <div className='mt-5 text-center'>
              <h1>Welcome!</h1>
              <div className='d-flex align-items-center flex-column'>
                <CustomerImage customer={currentUser.data} className='mb-3' />
                <h1>{currentUser.data.name}</h1>
              </div>
            </div>
          )}

          <>
            <div className='mt-5'>
              {currentUser ? (
                <button className='btn btn-danger w-100' onClick={handleLogOut}>
                  Logout
                </button>
              ) : (
                <button
                  className='btn btn-light-primary px-3 py-2 w-100'
                  onClick={() => {
                    history.push('/login', {
                      from: pathname,
                    })
                    drawer?.hide()
                  }}
                >
                  <span className='svg-icon svg-icon-dark-25'>
                    <SVG
                      src={toAbsoluteUrl('/media/icons/duotone/Shopping/Barcode-read.svg')}
                      className=' w-30px h-30px'
                    />
                  </span>
                  Scan QR Code
                </button>
              )}
            </div>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'> Services</span>
            </div>

            {polls}
            {currentUser && <CustomerServicesList goToService={handleGoToService} />}
          </>
        </div>
      </Drawer>
    </>
  )
}
