import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {TicketWaiverModel} from '../../../models/digital-waiver/TicketWaiverModel'
import {Helmet} from 'react-helmet'
import {TicketSubmitWaiverFormSign} from '../components/waiverForms'
import {WaiverFormHeader} from '../components/modals/WaiverFormHeader'
import {ARABIC_CONTENT, ENGLISH_CONTENT} from '../content/WaiverFormDescription'

export const QuickWaiverPage = () => {
  const history = useHistory()
  const {state: ticket} = useLocation<TicketWaiverModel>()

  useEffect(() => {
    if (!ticket) history.replace('/login/auth')
  }, [history, ticket])

  return ticket ? (
    <div className='p-5 container-fluid digital-waiver-container'>
      <Helmet>
        <title>Quick Waiver</title>
        <link rel='icon' href='/waiver.ico' />
      </Helmet>

      <div className='row'>
        <div className='col-12'>
          <p className='fs-3 text-secondary fw-bolder'>{`Ticket: ${ticket.batchId}`}</p>
        </div>
        <WaiverFormHeader
          arabicTextContent={ARABIC_CONTENT}
          englishTextContent={ENGLISH_CONTENT}
          selectedTicket={ticket}
        />
        <TicketSubmitWaiverFormSign
          isOpen={true}
          ticket={ticket}
          onRefresh={() => history.replace('/quick-waiver/thank-you')}
          isQuickWaiver
        />
      </div>
    </div>
  ) : (
    <p>Sorry the ticket is invalid</p>
  )
}
