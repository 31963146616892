import {ChangeEvent, useCallback, useMemo} from 'react'
import {BookingCard} from './BookingCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {BookingModel} from '../../../../models/ems/BookingModel'

export interface BookingCardGridProps {
  data?: GlobalSearchModel<BookingModel>
  onFilter: (filter: FilterModel) => void
  className?: string
  initialFilters?: FilterModel
}

export const BookingCardGrid = ({
  data,
  onFilter,
  className,
  initialFilters,
}: BookingCardGridProps) => {
  const {setSearch, search, pageNumber, pageSize, setPageNumber, setPageSize} = useFilterState(
    onFilter,
    {
      initialFilters,
    }
  )

  const cards = useMemo(() => {
    if (data?.data) {
      return (
        <div className='row'>
          {data.data.map((booking) => {
            return (
              <div key={booking.code} className='mb-3 col-xs-12 col-sm-6'>
                <BookingCard className='h-100' data={booking} />
              </div>
            )
          })}
          {/* {Object.entries(data.data).map(([, bookings]) => {
            return bookings.map((booking) => {
              return (
                <div key={booking.code} className='mb-3 col-xs-12 col-sm-6'>
                  <BookingCard className='h-100' data={booking} />
                </div>
              )
            })
          })} */}
        </div>
      )
    }
    return null
  }, [data])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  return (
    <div className={className}>
      <TextInput
        className='mb-3'
        noMargin
        placeholder='Search'
        value={search}
        onChange={handleSearchChange}
      />
      <div>{cards}</div>
      <PaginationHelper
        currentPageNumber={pageNumber}
        currentPageSize={pageSize}
        onChangePageNumber={setPageNumber}
        onChangePageSize={setPageSize}
        total={data?.total}
      />
    </div>
  )
}
