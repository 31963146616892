import {useEffect, useMemo, useState, useCallback, useRef} from 'react'
import { WIDGET_TIMER } from '../../../../../config/env'

const DEFAULT_WIDGET_TIMER = 60
const widgetTimer = Number(WIDGET_TIMER) || DEFAULT_WIDGET_TIMER

const MIUTES_IN_MILLISECOND = widgetTimer * 1000

export const useEmstimer = (callback: () => void) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const callbackRef = useRef(callback)



  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const start = useCallback(() => {
    const interval = setInterval(() => {
        callbackRef?.current()
    }, MIUTES_IN_MILLISECOND)
    setTimer(interval)
  }, [])

  const stop = useCallback(() => {
    if (timer) {
      clearInterval(timer)
      setTimer(undefined)
    }
  }, [timer])

  return useMemo(
    () => ({
      stop,
      start,
      timer,
    }),
    [stop, start, timer]
  )
}