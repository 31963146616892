import {useMemo} from 'react'
import {ColorVariant} from '../../../../components/inputs/Button'
import {Cart} from '../../../../utils/Cart'
import {OrderSummary, OrderSummaryItemAttributes} from './OrderSummary'

export interface OrderSummaryCartProps {
  cart: Cart
  variant: ColorVariant
}

export const OrderSummaryCart = ({cart, variant}: OrderSummaryCartProps) => {
  const items = useMemo(() => {
    const items: OrderSummaryItemAttributes[] = []
    cart.forEach((key, item) => {
      const price = item.price.getVatInclusivePrice(item.count)
      items.push({
        count: item.count,
        name: item.label,
        price,
        id: key,
      })
    })
    return items
  }, [cart])

  return <OrderSummary variant={variant} items={items} />
}
