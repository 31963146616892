import {useState, useCallback} from 'react'
// import {useDispatch} from 'react-redux'
import {PostBooking} from '../../../redux/CustomerDelegateCRUD'
import {useFormik} from 'formik'
import {BookingPortalForm, roleFormValidationSchema} from './BookingPortalForm'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {initialValues} from '../../../hooks/useFormDrawerBookingData'
import {BookingPortalFormValues} from './BookingPortalForm'
import {getBookingPayload} from '../../../hooks/useFormDrawerBookingData'
import {EventModel} from '../../../../../models/ems/EventModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
export interface BookingFormCreateProps {
  drawerRef: (drawer: Drawer | null) => void
  onChange: () => void
  event?: EventModel
  customer?: CustomerModel
}

const BookingPortalFormCreate = ({
  drawerRef,
  onChange,
  event,
  customer,
}: BookingFormCreateProps) => {
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useDrawerRef()
  const [isOpen, setIsOpen] = useState(false)
  const {pushError} = useAlerts()

  const createNewBooking = useCallback(
    async (values: BookingPortalFormValues) => {
      const payload = getBookingPayload(values)
      try {
        await PostBooking(payload)
        onChange()
      } catch (e) {
        throw e
      }
    },
    [onChange]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await createNewBooking(values)
        handleCloseDrawer()
        drawer?.hide()
      } catch (e) {
        pushError(e)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
    setIsOpen(false)
    if (event) formik.setFieldValue('eventCode', event?.code)
    if (customer)
      formik.setFieldValue('customer', {
        label: customer.name,
        value: customer.code,
      })
  }, [customer, event, formik])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  return (
    <Drawer onOpen={() => setIsOpen(true)} ref={handleDrawerRef} onClose={handleCloseDrawer}>
      <BookingPortalForm
        title='Create Booking'
        onCloseButtonClick={drawer?.hide}
        isLoading={loading}
        formik={formik}
        event={event}
        isOpen={isOpen}
        customer={customer}
      />
    </Drawer>
  )
}

export default BookingPortalFormCreate
