import leftImage from './CounterLeftImage.png'
import rightImage from './CounterRightImage.png'
import styles from './CounterCard.module.scss'
import clsx from 'clsx'
import {useBreakpoint} from '../../../../../../components/hooks/useBreakpoint'
import {useMemo} from 'react'

export interface CounterCardLeftProps {
  value: number
  title?: string
  subtitle?: string
  variant: 'left' | 'right'
  className?: string
}

export const MainCounterCard = ({
  value,
  title,
  subtitle,
  variant,
  className,
}: CounterCardLeftProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])
  return (
    <div
      className={clsx(
        className,
        styles.text,
        'position-relative d-flex flex-wrap flex-xs-wrap flex-sm-wrap flex-md-nowrap rounded text-white align-items-center justify-content-center fw-bold bg-light-primary',
        isMobile ? 'px-5' : 'px-12',
        isMobile ? 'py-7' : 'py-12'
      )}
    >
      <div
        className={clsx('position-absolute h-100 w-100', styles.backgroundImage)}
        style={{
          backgroundImage: `url(${variant === 'left' ? leftImage : rightImage})`,
          opacity: 0.4,
        }}
      />
      <div
        className='fst-italic'
        style={{
          fontSize: isMobile ? '5rem' : '6rem',
          zIndex: 1,
        }}
      >
        {Intl.NumberFormat('en-US').format(value || 0)}
      </div>
      <div
        className='ms-5'
        style={{
          zIndex: 1,
        }}
      >
        <div
          style={{
            fontSize: isMobile ? '2rem' : '3rem',
            lineHeight: '0.9em',
          }}
          className='fst-italic text-uppercase'
        >
          {title}
        </div>
        <div
          style={{
            fontSize: isMobile ? '1rem' : '1.5rem',
          }}
          className='text-uppercase'
        >
          {subtitle}
        </div>
      </div>
    </div>
  )
}
