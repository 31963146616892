import {ChangeEvent, useCallback, useEffect, useRef} from 'react'
import {TreeSelectNode} from './TreeSelectNode'

export interface TreeNodeCheckboxProps {
  node: TreeSelectNode
  onChange: (newValue: boolean) => void
  onBlur?: () => void
  /**
   * If `radioName` is provided, the input will use a
   * radio button instead of a checkbox.
   * */
  radioName?: string
}

export const TreeNodeCheckbox = ({node, onChange, radioName, onBlur}: TreeNodeCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null)

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onChange(true)
      } else {
        onChange(false)
      }
    },
    [onChange]
  )

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = node.isIndeterminate()
    }
  }, [node])

  return (
    <div style={{display: 'inline-block', whiteSpace: 'nowrap'}}>
      <label className='form-check-label'>
        <input
          ref={ref}
          style={{
            border: '1px solid gray',
            cursor: 'pointer',
          }}
          className='form-check-input me-2'
          type={radioName ? 'radio' : 'checkbox'}
          checked={node.selected}
          name={radioName}
          onChange={handleOnChange}
          onBlur={onBlur}
        />
        {node.label}
      </label>
    </div>
  )
}
