import {ReactNode, useCallback, useMemo} from 'react'
import {SeatMapSpacing} from '../SeatMapSpacing'
import {SeatMapValue} from '../SeatMapValue'
import {SeatValue} from '../SeatValue'
import {SeatMapDays, SVGSeat} from './SVGSeat'

export interface SVGSeatMapGridProps {
  columns: number
  rows: number
  height: number
  width: number
  boxHeight?: number
  boxWidth?: number
  isRightToLeft?: boolean
  isBottomToTop?: boolean
  spacingX?: SeatMapSpacing
  getSeatAttributes?: (seatValue: SeatValue) => Partial<SeatAttributes> | undefined
  onSeatClick?: (seatValue: SeatValue) => void
  isCircledDaysBox?: boolean
}

export interface SeatAttributes {
  color: string
  stroke: string
  hidden: boolean
  days?: SeatMapDays
}

export const SVGSeats = ({
  columns,
  rows,
  height,
  width,
  spacingX,
  isRightToLeft,
  isBottomToTop,
  getSeatAttributes,
  onSeatClick,
  boxHeight,
  boxWidth,
  isCircledDaysBox,
}: SVGSeatMapGridProps) => {
  const getSpacingOffset = useCallback(
    (seat: SeatValue) => {
      if (spacingX) {
        return spacingX.getSeatSpacingX(seat, isRightToLeft)
      }
      return 0
    },
    [isRightToLeft, spacingX]
  )

  const getXPos = useCallback(
    (columnIndex: number): number => {
      if (isRightToLeft) {
        return columns - columnIndex - 1
      }
      return columnIndex
    },
    [columns, isRightToLeft]
  )

  const getYPos = useCallback(
    (rowIndex: number): number => {
      if (isBottomToTop) {
        return rows - rowIndex - 1
      }
      return rowIndex
    },
    [isBottomToTop, rows]
  )

  const getSeatData = useCallback(() => {
    let width = 0
    let height = 0
    const coordinates: SeatCoordinates[] = []

    for (let cIndex = 0; cIndex < columns; cIndex++) {
      for (let rIndex = 0; rIndex < rows; rIndex++) {
        const seatLabel = `${SeatMapValue.getRowLetter(rIndex + 1)}${cIndex + 1}`
        const seatValue = new SeatValue(seatLabel)
        const x =
          (getXPos(cIndex) + getSpacingOffset(seatValue)) * (boxWidth ? DAYS_SPACING : SPACING)
        const y = getYPos(rIndex) * (boxWidth ? DAYS_SPACING : SPACING)
        const seatAttributes = getSeatAttributes?.(seatValue)
        if (width < x) {
          width = x
        }
        if (height < y) {
          height = y
        }
        coordinates.push({
          x,
          y,
          columnIndex: cIndex,
          rowIndex: rIndex,
          seatValue,
          attributes: seatAttributes,
          days: seatAttributes?.days,
        })
      }
    }

    return {width, height, coordinates}
  }, [boxWidth, columns, getSeatAttributes, getSpacingOffset, getXPos, getYPos, rows])

  const seats = useMemo((): ReactNode[] => {
    const nodes: ReactNode[] = []
    const seats = getSeatData()

    const thereIsDays = seats.coordinates.find(({days}) => days) ? true : false

    seats.coordinates.forEach(({columnIndex, rowIndex, x, y, attributes, seatValue, days}) => {
      nodes.push(
        <SVGSeat
          key={`${columnIndex}-${rowIndex}`}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          onClick={onSeatClick}
          hidden={attributes?.hidden}
          color={attributes?.color}
          x={x - seats.width / 2 + width / 2}
          y={y - seats.height / 2 + height / 2}
          stroke={attributes?.stroke}
          height={boxHeight ? boxHeight : BOX_WIDTH}
          width={boxWidth ? boxWidth : BOX_WIDTH}
          value={seatValue}
          daysBoxWidth={days && boxWidth ? boxWidth / 4 - 25 : undefined}
          daysBoxHeight={days && boxWidth ? boxWidth / 4 - 25 : undefined}
          days={days}
          isCircledDaysBox={isCircledDaysBox}
          isDays={thereIsDays}
        />
      )
    })

    return nodes
  }, [boxHeight, boxWidth, getSeatData, height, isCircledDaysBox, onSeatClick, width])

  return <>{seats}</>
}

const SPACING = 70
const DAYS_SPACING = 165
const BOX_WIDTH = 50

interface SeatCoordinates {
  x: number
  y: number
  rowIndex: number
  columnIndex: number
  seatValue: SeatValue
  attributes?: Partial<SeatAttributes>
  days?: SeatMapDays
}
