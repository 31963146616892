import {useMemo} from 'react'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {
  PortaProductVoucherModel,
  PortalEventTicketsValuesProps,
} from '../../../../models/customer-portal/PortalWidgetModal'

interface TicketsDetailsProps {
  items: PortalEventTicketsValuesProps
  product: PortaProductVoucherModel
  hasChildren?: boolean
}

export const ProductDetails = ({items, product, hasChildren = false}: TicketsDetailsProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])
  const qty = useMemo(() => {
    return (
      <div
        className='text-white mb-0 ff-admm-bold'
        style={{
          fontSize: isMobile ? '20px' : '22px',
          lineHeight: '27px',
        }}
      >
        {items.qty}
      </div>
    )
  }, [isMobile, items.qty])

  const remaining = useMemo(() => {
    return (
      <div
        className='text-white ff-admm-bold mb-0'
        style={{
          fontSize: '22px',
          lineHeight: '30px',
        }}
      >
        {items.remaining}
      </div>
    )
  }, [items.remaining])

  const used = useMemo(() => {
    return (
      <div
        className='text-white ff-admm-bold mb-0'
        style={{
          fontSize: '22px',
          lineHeight: '30px',
        }}
      >
        {items.used}
      </div>
    )
  }, [items.used])
  
  return (
    <div className='container-fluid px-0 mx-0 h-100 '>
      <div className='row mx-0 px-0 h-100'>
        <div className='col-12' style={{height: hasChildren ? 'calc(15% + 35px)' : 'calc(15% - 20px)'}}>
            <h1 className='text-center pt-16 px-3 text-active'>{product.name}</h1>
        </div>
        <div className='col-6 px-0 text-center border border-gray rounded-0 border-start-0'>
          <div className='h-100 d-flex flex-column justify-content-center px-2'>
            <div className=' text-white ff-admm-bold mb-0 d-flex flex-wrap gap-1 justify-content-center text-uppercase'>
              <div className='fs-3 mb-0 d-flex flex-column justify-content-center'>Total</div>
              {qty}
              <div className='fs-3 mb-0 d-flex flex-column justify-content-center'>Tickets</div>
            </div>
          </div>
        </div>

        <div className='col-6 px-0 column text-center d-flex flex-column flex-grow-1'>
          <div className='border border-gray border-start-0 d-flex flex-column justify-content-center h-50 border-end-0'>
            <div className='fs-7 text-white ff-admm-bold text-uppercase'>Remaining</div>
            {remaining}
          </div>

          <div className='border border-gray border-start-0 border-top-0 d-flex flex-column justify-content-center h-50 border-end-0'>
            <div className='fs-7 text-white ff-admm-bold text-uppercase'>Distributed</div>
            {used}
          </div>
        </div>
      </div>
    </div>
  )
}
