import {useCallback} from 'react'
import {SweetAlertCustomClass} from 'sweetalert2'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {TileButton} from '../../../../components/inputs/TileButton'
import {SweetAlert} from '../../../../components/modals/SweetAlert'

export interface PaymentTypeSelectModalProps {
  open: boolean
  onClose: () => void
  onSelect: (paymentType: PaymentType) => void
}

export type PaymentType = 'yas-pay' | 'bank-card'

export const PaymentTypeSelectModal = ({onClose, onSelect, open}: PaymentTypeSelectModalProps) => {
  const getSelectHandler = useCallback(
    (paymentType: PaymentType) => () => {
      onSelect(paymentType)
    },
    [onSelect]
  )

  return (
    <SweetAlert
      open={open}
      onClose={onClose}
      showConfirmButton={false}
      customClass={customClass}
      width='auto'
    >
      <div className='d-flex justify-content-between align-items-start'>
        <h2 className='mb-8'>Payment Method</h2>
        <MetronicIconButton
          onClick={onClose}
          variant='text'
          size='sm'
          iconType='Navigation'
          iconName='Close'
        />
      </div>
      <TileButton
        onClick={getSelectHandler('yas-pay')}
        className='me-4'
        variant='primary'
        iconName='Price1'
        iconType='Shopping'
      >
        YasEats CashCard
      </TileButton>
      <TileButton
        onClick={getSelectHandler('bank-card')}
        variant='primary'
        iconName='Credit-card'
        iconType='Shopping'
      >
        Credit/Debit Card
      </TileButton>
    </SweetAlert>
  )
}

const customClass: SweetAlertCustomClass = {
  htmlContainer: 'm-6',
}
