import clsx from 'clsx'
import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {useBooleanState} from '../../../../components/hooks/useBooleanState'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {QRCodeModalInput} from '../../../../components/inputs/QRCode/QRCodeInputModal'
import {LoginByTicketIdProps} from '../LoginByTIcketId'

interface LoginByTicketIdFormProps {
  formik: FormikContextType<LoginByTicketIdProps>
}
export const LoginByTicketIdForm = ({formik}: LoginByTicketIdFormProps) => {
  const {
    disableState: closeModal,
    enableState: openModal,
    state: isModalOpen,
  } = useBooleanState(false)

  const handleQrScan = useCallback(
    (value: string) => {
      formik.setFieldValue('ticketId', value)
      setImmediate(() => {
        formik.submitForm()
        closeModal()
      })
    },
    [closeModal, formik]
  )

  return (
    <>
      <div className='fv-row mb-10'>
        <div className='input-group input-group-solid search-dw-field'>
          <input
            placeholder='Ticket ID'
            {...formik.getFieldProps('ticketId')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.ticketId && formik.errors.ticketId},
              {
                'is-valid': formik.touched.ticketId && !formik.errors.ticketId,
              }
            )}
            type='text'
            name='ticketId'
            autoComplete='off'
          />
          <div className='input-group-append'>
            <MetronicIconButton
              variant='text'
              iconType='Shopping'
              iconName='Barcode-read'
              onClick={openModal}
              type='button'
            />
          </div>
        </div>
        {formik.touched.ticketId && formik.errors.ticketId && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {formik.errors.ticketId}
              </span>
            </div>
          </div>
        )}
      </div>
      <QRCodeModalInput
        autoSubmit
        onSubmit={handleQrScan}
        onClose={closeModal}
        open={isModalOpen}
        hideButton
      />
    </>
  )
}
