import {Page404} from '../../components/layouts/Page404/Page404'
import styles from './FullPage404.module.scss'

export interface FullPage404Props {
  link?: string
}

export const FullPage404 = ({link}: FullPage404Props) => {
  return (
    <div className={styles.root}>
      <Page404 link={link} />
    </div>
  )
}
