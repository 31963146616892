import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {Action} from 'redux'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IServiceWorkerState {
  serviceWorkerInitialized: Boolean
  serviceWorkerUpdated: Boolean
  serviceWorkerRegistration: any
}

const initialState: IServiceWorkerState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
}

export const reducer = persistReducer(
  {storage, key: 'worker', whitelist: []},
  (state: IServiceWorkerState = initialState, action: ActionWithPayload<IServiceWorkerState>) => {
    switch (action.type) {
      case 'SW_INIT': {
        return {...state, serviceWorkerInitialized: !state.serviceWorkerInitialized}
      }

      case 'SW_UPDATE': {
        return {
          ...state,
          serviceWorkerUpdated: !state.serviceWorkerUpdated,
          serviceWorkerRegistration: action.payload,
        }
      }

      default:
        return state
    }
  }
)
