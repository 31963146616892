import {useMemo} from 'react'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {FileModel} from '../../../../models/FileModel'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'

export interface TableAvatarImageColumnProps {
  image: FileModel
  alt: string
  privateFile?: boolean
}

export const TableAvatarImageColumn = ({image, alt, privateFile}: TableAvatarImageColumnProps) => {
  const {staticUrls} = useAppConfig()

  const src = useMemo(() => {
    const imageValue = new ImageInputValue(
      privateFile ? staticUrls.private : staticUrls.public,
      image
    )
    return imageValue.url
  }, [image, privateFile, staticUrls.private, staticUrls.public])

  return (
    <img
      style={{
        height: '30px',
        width: '30px',
      }}
      src={src}
      alt={alt}
    />
  )
}
