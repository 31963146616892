import {useCallback} from 'react'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {Drawer} from '../../../Drawer/Drawer'
import {useDrawerRef} from '../../../Drawer/useDrawerRef'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {HorizontalDivider} from '../../../utils/HorizontalDivider'
import {NavDrawerMenuItem} from './NavDrawerMenuItem'

export interface NavDrawerProps {
  menus: NavigationSectionMenuItem[]
  type?: string
  drawerRef?: (drawer: Drawer) => void
}

export const NavDrawer = ({menus, type, drawerRef}: NavDrawerProps) => {
  const [drawer, setDrawer] = useDrawerRef()
  const handleClose = useCallback(() => {
    drawer?.hide()
  }, [drawer])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef?.(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  return (
    <Drawer ref={handleDrawerRef}>
      <div
        className='menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-400 menu-arrow-gray-400 menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-bold'
        data-kt-menu='true'
      >
        <div className='text-end pt-2 pe-2'>
          <MetronicIconButton
            variant='text'
            iconType='Navigation'
            iconName='Close'
            size='lg'
            onClick={handleClose}
            tooltip='Close'
          />
        </div>
        <HorizontalDivider />
        {menus.map((item, i) => (
          <NavDrawerMenuItem type={type} key={i} item={item} />
        ))}
      </div>
    </Drawer>
  )
}
