import {Helmet} from 'react-helmet'
import {useHistory} from 'react-router-dom'
import {Button} from '../../../components/inputs/Button'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'

export const QuickWaiverThankyouPage = () => {
  const history = useHistory()
  return (
    <div>
      <Helmet>
        <title>Quick Waiver</title>
        <link rel='icon' href='/waiver.ico' />
      </Helmet>
      <div className='position-absolute top-50 start-50 translate-middle text-center align-items-center flex-column'>
        <div
          className='border border-1 border-success rounded-circle p-5 m-auto d-flex justify-content-center mb-5'
          style={{
            width: '100px',
            height: '100px',
          }}
        >
          <MetronicIcon
            color='success'
            size='xl'
            iconName='Check'
            iconType='Navigation'
            className='d-flex align-items-center'
          ></MetronicIcon>
        </div>
        <p className='text-white fs-1 fw-bolder'>Thank you for submitting your details.</p>
        <Button
          variant='text'
          className='py-0 pt-3 text-primary fs-6'
          onClick={() => history.replace('/auth/login')}
        >
          Go to Login Page
        </Button>
      </div>
    </div>
  )
}
