import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {idExtractor} from '../../../../../utils/idExtractor'

export interface CustomerSelectionActionProps {
  selected: TableRowId[]
  items: CustomerModel[]
  onDelete?: (customers: CustomerModel[]) => void
  onResetMfa?: (customers: CustomerModel[]) => void
  onActivate?: (customers: CustomerModel[]) => void
}

export const CustomerSelectionAction = ({
  selected,
  items,
  onDelete,
  onResetMfa,
  onActivate,
}: CustomerSelectionActionProps) => {
  return (
    <>
      <TableSelectionAction
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        onClick={onDelete}
        variant='danger'
        filter={customerCanBeDeleted}
      >
        Delete
      </TableSelectionAction>

      <TableSelectionAction
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        onClick={onResetMfa}
        variant='info'
        filter={customerCanBeSentMFA}
      >
        Send MFA
      </TableSelectionAction>

      <TableSelectionAction
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        onClick={onActivate}
        variant='info'
        filter={customerCanBeSentCredentials}
      >
        Send Credentials
      </TableSelectionAction>
    </>
  )
}

export const customerCanBeSentCredentials = (customer: CustomerModel) => {
  return customer.status === 'pending'
}

export const customerCanBeDeleted = (customer: CustomerModel) => {
  return customer.status === 'pending' && customer.type === 'reseller'
}

export const customerCanBeSentMFA = (customer: CustomerModel) => {
  return customer.status === 'active' && customer.type === 'reseller'
}
