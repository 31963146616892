import {useCallback, useMemo} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {DateUtil} from '../../../../../utils/DateUtil'

import {BookingProductCodeColumn} from './BookingProductCodeColumn'
import {BookingProductStatusColumn} from '../BookingProductStatusColumn'
import {FilterModel} from '../../../../../models/FilterModel'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {CustomerBookingTableActions} from './CustomerBookingDetailTableActions'
import {SeatMapPortalSelectionModalForm} from '../../../../../components/forms/SeatMapPortalSelectionModalForm'
import {BookingDetailModel} from '../../../../../models/customer-portal/BookingDetailModel'
import {ProductTypeColumn} from '../ProductTypeColumn'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {NonSeatedTimeslotDateModalForm} from '../../../../default/svc/components/tables/CustomerProductBookingDetailTable/modals/NonSeatedTimeslotDateModalForm'
import moment from 'moment'
import {useProductBookingDetailsTableHelpers} from '../../../hooks/useProductBookingDetailsTableHelpers'
import {TimeSlotFormatter} from '../../../../default/ems/components/tables/column-formatters/TimeSlotFormatter'
import {AssignBoatModalPortal} from './modal/AssignBoatModalPortal'

export type FromProp = 'bookings' | 'tickets' | 'booking'
export interface CustomerProductBookingDetailTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<BookingDetailModel>
  bookingCode?: string
  customerCode?: string
  onRefresh?: () => void
  onRefreshCallback?: () => void
  toPath?: FromProp
  baseRoute?: string
  customer?: CustomerModel
  cornersClassName?: string
  paginationClassName?: string
  removedColumns?: string[]
}

export const CustomerProductBookingDetailTable = ({
  data,
  onFilter,
  onRefresh,
  customerCode,
  onRefreshCallback,
  toPath,
  customer,
  cornersClassName,
  paginationClassName,
  removedColumns,
}: CustomerProductBookingDetailTableProps) => {
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-product-booking-detail',
    defaults: {hiddenColumns: ['bookingProductBoatName', 'bookingProductBoatSlug']},
  })
  const {
    fulfillNonSeatedProduct,
    fulfillTicket,
    getModalProps,
    isItemLoading,
    setModalData,
    modalData,
    handleFulfillTimeslotSubmit,
    assignBoat,
  } = useProductBookingDetailsTableHelpers({onRefresh})

  const handleOnAssignSeats = useCallback(
    (data: BookingDetailModel) => {
      setModalData({
        data,
        modalType: 'assign-seated',
      })
    },
    [setModalData]
  )

  const handleOnAssignBoat = useCallback(
    (data: BookingDetailModel) => {
      setModalData({
        data,
        modalType: 'assign-boat',
      })
    },
    [setModalData]
  )

  const idExtractor = useCallback((data: BookingDetailModel) => {
    return data.code
  }, [])

  const rowActions = useCallback(
    (data: BookingDetailModel) => (
      <CustomerBookingTableActions
        data={data}
        onAssignSeats={handleOnAssignSeats}
        onFulfillNonSeatedProduct={fulfillNonSeatedProduct}
        loading={isItemLoading(data)}
        // onAssignBoat={handleOnAssignBoat}
      />
    ),
    [fulfillNonSeatedProduct, handleOnAssignSeats, isItemLoading, handleOnAssignBoat]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<BookingDetailModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        render: ({data}) => BookingProductCodeColumn({data, toPath, customer}),
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'description',
        label: 'Description',
        cellStyle: ColumnStyle.DESCRIPTION,
      },
      {
        field: 'event',
        label: 'Event',
        sortable: true,
        hideable: true,
        dataExtract: (product) => product.eventName,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'productCategory',
        label: 'Category',
        sortable: true,
        hideable: true,
        dataExtract: (product) => product.productCategoryName,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        type: 'date',
        render: ({data}) => (
          <TimeSlotFormatter className='text-uppercase text-nowrap' isTimeslot={data.isTimeslot}>
            {data.startedAt}
          </TimeSlotFormatter>
        ),
        // dataExtract: (product) => DateUtil.getDateFromApiString(product.startedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        type: 'date',
        render: ({data}) => (
          <TimeSlotFormatter className='text-uppercase text-nowrap' isTimeslot={data.isTimeslot}>
            {data.endedAt}
          </TimeSlotFormatter>
        ),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'type',
        label: 'Ticket Type',
        sortable: true,
        hideable: true,
        render: ProductTypeColumn,
      },
      {
        field: 'isSeated',
        label: 'Seated',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isSeated,
      },
      {
        field: 'isConsumable',
        label: 'Consumable',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isConsumable,
      },
      {
        field: 'isTimeslot',
        label: 'Timeslot',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isTimeslot,
      },
      {
        field: 'booking',
        label: 'Qty',
        sortable: false,
        hideable: true,
        type: 'number',
        dataExtract: (product) => product.bookingProductQty,
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: BookingProductStatusColumn,
      },
      {
        field: 'bookingProductBoatName',
        label: 'Boat Name',
        sortable: true,
        hideable: true,
      },
      {
        field: 'bookingProductBoatSlug',
        label: 'Boat Alias',
        sortable: true,
        hideable: true,
      },
    ]
    return columns.filter((column) => !removedColumns?.includes(column.field))
  }, [customer, toPath, removedColumns])

  const activeProduct = useMemo(() => {
    if (modalData.data && 'code' in modalData.data) {
      return modalData.data
    }
  }, [modalData.data])
  const activeProductStart = useMemo(() => {
    const today = moment().startOf('day')
    if (modalData.data && 'code' in modalData.data) {
      const startedAt = DateUtil.getDateFromApiString(modalData.data.startedAt)
      return moment.max(today, moment(startedAt)).toDate()
    }
    return today.toDate()
  }, [modalData.data])

  const activeProductEnd = useMemo(() => {
    if (modalData.data && 'code' in modalData.data) {
      return DateUtil.getDateFromApiString(modalData.data.endedAt)
    }
  }, [modalData.data])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={hiddenColumns}
        onHiddenColumnsChange={setHiddenColumns}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columns}
        totalItems={data?.total || 10}
        actions={rowActions}
        cornersClassName={cornersClassName}
        paginationClassName={paginationClassName}
      />
      <SeatMapPortalSelectionModalForm
        onSubmit={fulfillTicket}
        product={activeProduct}
        customerCode={customerCode}
        submitButtonClassName='btn-cut-conrner pe-20 btn btn-primary'
        open={getModalProps('assign-seated').isOpen}
        onHide={() => getModalProps('assign-seated').onClose()}
        isCustom
      />
      <AssignBoatModalPortal
        onSubmit={assignBoat}
        product={activeProduct}
        {...getModalProps('assign-boat')}
      />

      <NonSeatedTimeslotDateModalForm
        {...getModalProps('assign-non-seated-timeslots')}
        onSubmit={handleFulfillTimeslotSubmit}
        qty={activeProduct?.bookingProductQty}
        maxDate={activeProductEnd}
        minDate={activeProductStart}
        isPortal={true}
      />
    </>
  )
}
