import clsx from 'clsx'
import {useCallback} from 'react'

export interface SearchableInputItemProps<T> {
  onClick: (value: T) => void
  className?: string
  label: string
  value: T
}

export const SearchableInputItem = <T,>({
  label,
  onClick,
  className,
  value,
}: SearchableInputItemProps<T>) => {
  const handleOnMouseDown = useCallback(
    (e) => {
      e.preventDefault()
      onClick(value)
    },
    [onClick, value]
  )

  return (
    <li
      role='button'
      onMouseDown={handleOnMouseDown}
      className={clsx('searchable-input-item', className)}
    >
      {label}
    </li>
  )
}
