import {useCallback, useMemo, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {VAT} from '../../../../config/env'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {Cart} from '../../../utils/Cart'
import {PaymentSummary} from '../../outlet/components/PaymentSummary'
import {
  getOrderStatusStage,
  OrderStatusTimeline,
  OrderStatusTimelineStage,
} from '../components/OrderStatusTimeline'
import {OrderSummaryCart} from '../components/OrderSummary/OrderSummaryCart'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {ConfirmPageLayout} from '../layout/ConfirmPageLayout'
import {GetOrderByCode} from '../redux/DigitalMenuCRUD'

interface PathParams {
  orderCode: string
}

export const OrderPage = () => {
  const [order, setOrder] = useState<OrderModel>()
  const pathParams = useParams<PathParams>()
  const {
    goToOutletMenu,
    goToOutlets: goToLocationOutlets,
    goToAllOrderList,
    goToDraftedOrderCheckoutPage,
  } = usePageNavigation()
  const {state = {}} = useLocation<{
    outletCode?: string
    locationCode?: string
    ticketCode?: string
    orderType?: string
  }>()
  const {outletCode, locationCode, ticketCode, orderType} = state

  const cart = useMemo(() => {
    if (order) {
      return Cart.fromOrderModel(order)
    }
    return new Cart()
  }, [order])

  const handleNewOrderClick = useCallback(() => {
    if (outletCode && (locationCode || ticketCode)) {
      goToOutletMenu(outletCode, {locationCode, ticketCode, orderType})
    }
  }, [goToOutletMenu, locationCode, orderType, outletCode, ticketCode])

  const resetOrderData = useCallback(async () => {
    const {data} = await GetOrderByCode(pathParams.orderCode)
    setOrder(data)
  }, [pathParams.orderCode])

  const handleGoToOrders = useCallback(() => {
    if (locationCode) {
      goToLocationOutlets({tab: 'orders', locationCode})
    }
  }, [goToLocationOutlets, locationCode])

  useOnMount(() => {
    resetOrderData()
  })

  const orderStatus = useMemo(() => {
    if (order) {
      return getOrderStatusStage(order.status)
    }
    return OrderStatusTimelineStage.PENDING
  }, [order])

  const handleGoToOrderCheckout = useCallback(() => {
    goToDraftedOrderCheckoutPage()
  }, [goToDraftedOrderCheckoutPage])

  return (
    <ConfirmPageLayout onBackClick={goToAllOrderList} headerLabel='Track Order'>
      <h2 className='fs-1 mb-5'>Order #{order?.code}</h2>
      <OrderStatusTimeline stage={orderStatus} />
      <HorizontalDivider spacing={8} />
      <OrderSummaryCart variant='success' cart={cart} />
      <HorizontalDivider spacing={5} />
      <PaymentSummary className='mb-6' cart={cart} vatPercent={VAT} />
      {order?.status === 'draft' && (
        <button
          className='btn btn-success border border-success bg-light-success flex-grow-1 rounded-0 float-end fs-1'
          onClick={handleGoToOrderCheckout}
        >
          Pay Order
        </button>
      )}
      {outletCode && (locationCode || ticketCode) && (
        <div className='d-flex fixed-bottom w-100'>
          <button
            className='btn btn-success flex-grow-1 rounded-0 fs-1'
            onClick={handleNewOrderClick}
          >
            New Order
          </button>
          <button
            className='btn btn-success border border-success bg-light-success flex-grow-1 rounded-0 fs-1'
            onClick={handleGoToOrders}
          >
            View Orders
          </button>
        </div>
      )}
    </ConfirmPageLayout>
  )
}
