/* eslint-disable jsx-a11y/alt-text */
import {EditorState} from 'draft-js'

import {Map} from 'immutable'
import {camelCase} from 'lodash'
import {useCallback, useEffect, useRef, useState} from 'react'

export const Image = (props: any) => {
  const image = useRef<HTMLImageElement | null>(null)
  const container = useRef<HTMLDivElement | null>(null)
  const {
    block,
    contentState,
    blockProps: {editor, getEditorState, onChange},
  } = props

  // function used to update block data and propagate it to editorState
  const updateBlockData = useCallback(
    (newData = {}, data = block.getData()) => {
      try {
        data = data.merge(newData)
        const newBlock = block.set('data', data)
        let blockMap = getEditorState().getCurrentContent().getBlockMap()
        blockMap = blockMap.set(block.getKey(), newBlock)
        const newContent = getEditorState().getCurrentContent().set('blockMap', blockMap)
        const selection = getEditorState().getSelection()
        let editorState = EditorState.push(getEditorState(), newContent, 'change-block-data')
        editorState = EditorState.forceSelection(editorState, selection)
        onChange(editorState)
      } catch (e) {}
    },
    [block, getEditorState, onChange]
  )

  // turn on resizing when the image is clicked
  const handleClick = () => {
    if (editor.editor.getAttribute('contenteditable') === 'false') {
      return null
    }
    const data = block.getData()
    if (!data.get('isActive')) {
      updateBlockData({isActive: true})
      keepActive.current = true
      setResize('both')
    }
  }

  // turn off resizing when isActive is removed from the block data (removal is done in the onChange method in index.js)
  const keepActive = useRef(false)
  const [resize, setResize] = useState('none')
  useEffect(() => {
    const {isActive} = block.getData()
    if (!isActive && !keepActive.current) {
      setResize('none')
    } else {
      keepActive.current = false
    }
  }, [block])

  // save the size info to local state, or if size data isn't present in block-data get it from the loaded image, with a fallback to a default of 250 x 250
  const data = block.getData()
  let imgStyle = data.get('imgStyle')
  let figStyle = data.delete('imgStyle')
  imgStyle =
    (imgStyle &&
      data
        .get('imgStyle')
        .mapKeys((k: string) => camelCase(k))
        .toJS()) ||
    {}
  figStyle =
    (figStyle &&
      figStyle
        .filter((v: string) => v !== 'class')
        .mapKeys((k: string) => camelCase(k))
        .toJS()) ||
    {}
  const {height, width} = imgStyle
  delete imgStyle.height
  delete imgStyle.width
  const [size, setSize] = useState({height, width})

  const handleImgLoaded = () => {
    const {naturalHeight, naturalWidth} = image.current || {}
    if (naturalHeight !== null && naturalWidth !== null) {
      if (!size.height || !size.width) {
        const height = `${naturalHeight}px`
        const width = `${naturalWidth}px`
        updateBlockData({imgStyle: Map({'object-fit': 'contain', height, width})})
        setSize({height, width})
      }
    } else {
      setSize({height: '250px', width: '250px'})
    }
  }

  // if the dimensions of the container div change, update state and block-data
  const handleMouseUp = () => {
    if (container.current) {
      const {height, width} = container.current.getBoundingClientRect()
      const newHeight = `${height}px`
      const newWidth = `${width}px`
      if (size.height !== newHeight || size.width !== newWidth) {
        updateBlockData({
          imgStyle: Map(block.getData().get('imgStyle')).merge({
            height: newHeight,
            width: newWidth,
          }),
        })
        setSize({height: newHeight, width: newWidth})
      }
    }
  }

  const {src} =
    (block.getEntityAt(0) && contentState.getEntity(block.getEntityAt(0)).getData()) || {}
  // const borderColor = (theme: any) => (resize === 'both' ? theme.colors.textOnPageBackground : 'transparent');
  return (
    <div
      ref={container}
      style={figStyle}
      // css={(theme: any) => [
      //   figStyle,
      //   {
      //     position: 'relative',
      //     zIndex: 1,
      //     display: 'inline-block',
      //     overflow: 'hidden',
      //     padding: 5,
      //     resize,
      //     border: `1px dashed ${borderColor(theme)}`,
      //   },
      //   size.height && size,
      // ]}
      className='image-container' // Add a className
      onClick={handleClick}
      onMouseUp={handleMouseUp}
    >
      <img
        ref={image}
        src={src}
        width='100%'
        height='100%'
        // css={imgStyle}
        onLoad={handleImgLoaded}
      />
    </div>
  )
}
