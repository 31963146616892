import {useFormik} from 'formik'
import {useCallback, useMemo} from 'react'
import {TextInput} from '../../../../components/inputs'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {SweetAlert} from '../../../../components/modals/SweetAlert'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import * as Yup from 'yup'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {useIsGSSSPP} from '../../hooks/useIsGSSSPP'
import {RouteParams} from '../../pages/PortalPage'
import {useRouteMatch} from 'react-router-dom'
import {useDay} from '../../hooks/useDay'
import clsx from 'clsx'

interface EmailShareModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  data: TicketPortalModel[]
  onSubmit: (values: FormInitialValuesProps) => void
  isLoading?: boolean
  isPortal?: boolean
}

export interface FormInitialValuesProps {
  ticketCodes: string[]
  email: string
  name: string
  eventCode: string
}

export const EmailShareModal = ({
  open,
  setOpen,
  data,
  onSubmit,
  isLoading = false,
  isPortal,
}: EmailShareModalProps) => {
  const {pushError} = useAlerts()
  const {
    params: {productCategoryCode},
  } = useRouteMatch<RouteParams>()

  const {getDayThreeLetters} = useDay()

  const initialValues = useMemo<FormInitialValuesProps>(() => {
    return {
      email: '',
      name: '',
      eventCode: '',
      ticketCodes: [],
    }
  }, [])

  const {isGCCSPP} = useIsGSSSPP({productCategoryCode})

  const formik = useFormik({
    initialValues,
    validationSchema: EmailSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await onSubmit(values)
      } catch (err) {
        pushError(err)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleClose = useCallback(() => {
    setOpen(false)
    formik.resetForm()
  }, [formik, setOpen])

  const day = useMemo(() => {
    return getDayThreeLetters(data[0].startedAt, data[0].endedAt)
  }, [data, getDayThreeLetters])

  const title = useMemo(() => {
    if (data.length > 1) return `EMAIL SELECTED TICKETS (${data.length})`
    return `EMAIL TICKET: ${
      !isGCCSPP && day === 'Thu' ? 'Pitlane Walk' : data[0].productName ? data[0].productName : ''
    }`
  }, [data, day, isGCCSPP])

  return (
    <SweetAlert
      containerClassName='overflow-auto'
      customClass={{
        htmlContainer: 'overflow-visible',
      }}
      open={open}
      onClose={handleClose}
      showConfirmButton={false}
    >
      {isPortal && <div className='swal2-corners' style={{width: '465px'}}></div>}
      <div
        className={clsx('h-100 d-flex flex-column me-5 mb-5', isPortal && 'position-relative pe-3')}
        style={{width: isPortal ? '385px' : ''}}
      >
        <div className='d-flex justify-content-between align-items-center'>
          <p className='text-white mb-0 fw-bolder'>{title}</p>
          <MetronicIconButton
            type='button'
            className='btn pb-1 ps-10'
            iconType='Navigation'
            iconName='Close'
            variant='text'
            size='md'
            color='white'
            onClick={handleClose}
          />
        </div>

        <HorizontalDivider className='bg-dark' />

        <form className='form mt-5' onSubmit={formik.handleSubmit}>
          <TextInput
            type='name'
            placeholder='Enter Name'
            inputClassName='rounded-0'
            {...formik.getFieldProps('name')}
            errorMessage={formik.errors.name}
            isTouched={formik.touched.name}
          />
          <TextInput
            type='email'
            placeholder='Enter Email Address'
            inputClassName='rounded-0 mt-5 '
            {...formik.getFieldProps('email')}
            errorMessage={formik.errors.email}
            isTouched={formik.touched.email}
          />

          <button
            disabled={isLoading}
            className='btn btn-primary d-flex gap-2 m-auto mt-5 rounded-0 text-white w-75 justify-content-center'
            onClick={formik.submitForm}
          >
            Send Email
            {isLoading && (
              <span className='indicator-progress ml-2' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </form>
      </div>
    </SweetAlert>
  )
}

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})
