import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import {LoadingSpinner} from '../utils/LoadingSpinner'
import {TextInput} from '../inputs'
import {OrDivider} from '../utils/OrDivider'
import {useOnChange} from '../hooks/useOnChange'
import {useAuthState} from '../../modules/customer-portal/components/forms/AuthStateContext'

export interface ForgotPasswordFormValues {
  accountCode?: string
  email: string
  userType: string
}

export interface ForgotPasswordFormProps {
  onSubmit?: (values: ForgotPasswordFormValues) => void | Promise<void>
  onCancel: () => void
  isPortal?: boolean
}

export const ForgotPasswordForm = ({onSubmit, onCancel, isPortal = false}: ForgotPasswordFormProps) => {
  const [hasErrors, setHasErrors] = useState<boolean>()
  const {userType, acctCode} = useAuthState()

  const initialValues: ForgotPasswordFormValues = {
    email: '',
    accountCode: acctCode,
    userType: userType,
  }

  useOnChange(acctCode, () => {
    if (acctCode) {
      formik.setFieldValue('accountCode', acctCode)
    }
  })

  useEffect(() => {
    if (!userType && !acctCode && isPortal) {
      onCancel()
    }
  }, [acctCode, isPortal, onCancel, userType])

  const forgotPasswordSchema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    })
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setHasErrors(false)
      setSubmitting(true)
      try {
        const payload = {...values}
        if (userType === '001') {
          const {accountCode, ...rest} = payload
          await onSubmit?.(rest)
          onCancel()
        } else {
          await onSubmit?.(payload)
          onCancel()
        }
      } catch (e) {
        setHasErrors(true)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <form className='form w-100' noValidate onSubmit={formik.handleSubmit}>
      <div className='text-center mb-10'>
        <h1 className='mb-3'>Forgot Password?</h1>
        <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}

      <TextInput
        label='Email'
        type='email'
        placeholder='Email'
        autoComplete='off'
        {...formik.getFieldProps('email')}
        errorMessage={formik.errors.email}
      />

      <div>
        <div className='d-flex flex-wrap justify-content-center'>
          <Button type='submit' variant='primary' disabled={!formik.isValid} className='me-4'>
            <LoadingSpinner loading={formik.isSubmitting}>Submit</LoadingSpinner>
          </Button>
          <Button variant='text' className='me-4' onClick={onCancel} disabled={formik.isSubmitting}>
            Cancel
          </Button>
        </div>
        <OrDivider className='my-10' />
        <div className='d-flex flex-wrap justify-content-center'>
          <Button
            variant='primary'
            className='me-4 w-100'
            onClick={onCancel}
            disabled={formik.isSubmitting}
          >
            Login
          </Button>
        </div>
      </div>
    </form>
  )
}
