import {useEffect, useRef} from 'react'

type Callback = () => void

export const useOnUnmount = (callback: Callback) => {
  const mem = useRef<Callback>()

  useEffect(() => {
    mem.current = callback
  }, [callback])

  useEffect(() => {
    return () => {
      const func = mem.current
      if (func) {
        func()
      }
    }
  }, [])
}
