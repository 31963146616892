import clsx from 'clsx'
import {ReactNode} from 'react'
import {Paper} from '../../../app/components/utils/Paper'

export interface BodyProps {
  className?: string
  children?: ReactNode
  header?: ReactNode
}

export const Body = ({children, className, header}: BodyProps) => {
  return (
    <div className={clsx('body', className)}>
      {header}
      <Paper className='content'>{children}</Paper>
    </div>
  )
}
