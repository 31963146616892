import {useDispatch} from 'react-redux'
import {QRCodeInput} from '../../../components/inputs/QRCode/QRCodeInput'
import {useMemo, useState, useCallback} from 'react'
import {SwalUtils} from '../../../utils/SwalUtils'
import {GetCustomerByTicketCode} from '../redux/EvaCRUD'
import {actions} from '../redux/EvaRedux'
import {useHistory} from 'react-router'
import {usePushNotification} from '../hooks/usePushNotification'

export const LoginPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [qrCodeScaned, setQrCodeScanned] = useState<boolean>(true)
  const {subscribeToPushNotifications} = usePushNotification()

  const goTo = useCallback(
    (path: string) => {
      history.replace(path)
    },
    [history]
  )

  const handleQrCodeModalSubmit = useCallback(
    async (value: string) => {
      try {
        setQrCodeScanned(false)
        let ticketCode = ''
        if (value.includes('q')) {
          const q = new URLSearchParams(new URL(value).search).get('q')
          if (q) {
            ticketCode = q
          }
        } else {
          ticketCode = value
        }
        if (qrCodeScaned && ticketCode) {
          const data = await GetCustomerByTicketCode(ticketCode)
          if (data) {
            dispatch(actions.saveUser(data.data, ticketCode))
            subscribeToPushNotifications(data.data)
            goTo('/agendas')
          }
        }
      } catch (e) {
        const confirmation = await SwalUtils.confirm({
          title: 'Invalid Badge!',
          confirmButtonText: 'Try again',
          cancelButtonText: 'Cancel',
        })
        if (!confirmation.isConfirmed) {
          goTo('/agendas')
        }
      }
    },
    [qrCodeScaned, dispatch, subscribeToPushNotifications, goTo]
  )

  const qrCodeBox = useMemo(() => {
    return (
      <div
        className='d-flex justify-content-center flex-column align-items-center h-100 animation-fade-in'
        id='out-of-qrcode-vide'
        onClick={async (e) => {
          if ((e.target as any).id === 'out-of-qrcode-vide') {
            const confirmation = await SwalUtils.confirm({
              text: 'Scan your Badge Here.',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            })
            if (confirmation.isConfirmed) {
              goTo('/agendas')
            }
          }
        }}
      >
        <div className='card w-75 p-5 text-center'>
          <QRCodeInput
            disabled={false}
            scanDelay={1000}
            className='w-100'
            onChange={handleQrCodeModalSubmit}
          />
        </div>
      </div>
    )
  }, [handleQrCodeModalSubmit, goTo])

  return <>{qrCodeBox}</>
}
