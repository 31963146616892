import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {DigitalWaiverCard} from './DigitalWaiverCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../models/GlobalSearchModel'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import clsx from 'clsx'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {CardCollapseRow} from '../CardCollapseRow'
import {TicketTableSelectionActions} from '../tables/TicketTable/TicketTableSelectionActions'
import {useWaiverCardUtils} from '../../hooks/useWaiverCardUtils'
import {WaiverSignModalForm} from '../modals/WaiverSignModalForm'
import {WaiverEditModalForm} from '../modals/WaiverEditModalForm'
import {DownloadDigitalWaiverPdf} from '../../redux/DigitalWaiverCRUD'
import {WaiverAddTicketButton} from '../WaivetAddTicketbutton/WaiverAddTicketButton'
import {useBooleanState} from '../../../../components/hooks/useBooleanState'
import {AddTicketByIdForm} from '../waiverForms/AddTicketByIdForm'
import styles from './DigitalWaiverCard.module.scss'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {BlobUtils} from '../../../../utils/BlobUtils'
import {isArray} from 'lodash'
import {MenuItem, TicketFiltersGrid} from '../TicketFiltersGrid/TicketFiltersGrid'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {WaiverStatusType} from '../../pages/DigitalWaiverTicketPage'

export interface DigitalWaiverCardGridProps {
  data?: GlobalSearchModel<TicketWaiverModel> | GroupedSearchModel<TicketWaiverModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  className?: string
  initialFilters?: FilterModel
  filters?: FilterModel
  tabCode?: string
  waiverStatus?: WaiverStatusType
}

export interface CollapsedProps {
  key: string
  value: boolean
}

export const DigitalWaiverCardGrid = ({
  data,
  onFilter,
  onRefresh,
  className,
  initialFilters,
  filters,
  tabCode,
  waiverStatus,
}: DigitalWaiverCardGridProps) => {
  const {pushError} = useAlerts()
  const [collapsedItem, setCollapsedItem] = useState<CollapsedProps>()

  const {setPageNumber, setPageSize, hasFilters, clearFilters} = useFilterState(onFilter, {
    initialFilters,
    filters,
  })

  const [selected, setSelected] = useState<string[]>([])
  const [selectedTickets, setSelectedTickets] = useState<TicketWaiverModel[]>()
  const [openSignWaiverModal, setOpenSignWaiverModal] = useState<boolean>(false)
  const [openEditWaiverModal, setOpenEditWaiverModal] = useState<boolean>(false)
  const {tableSelectionItems, onSelectGroupHandler} = useWaiverCardUtils<TicketWaiverModel>({
    data,
    selected,
    setSelected,
  })

  const {
    state: isPopupOpen,
    enableState: openPopup,
    disableState: closePopup,
  } = useBooleanState(false)

  useOnChange(data, () => {
    if (data && !Array.isArray(data)) {
      const firstRow = Object.keys(data.data)[0]
      setCollapsedItem({
        key: firstRow,
        value: true,
      })
    }
  })

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onFilter?.({
        filters: {...filters?.filters, search: e.target.value},
      })
    },
    [filters?.filters, onFilter]
  )

  const toggleCollapse = useCallback(
    (key: string) => {
      const value = collapsedItem && collapsedItem.key === key ? !collapsedItem.value : true
      setCollapsedItem({
        key: key,
        value,
      })
    },
    [collapsedItem]
  )

  const handleOnDelegate = useCallback((data: TicketWaiverModel | TicketWaiverModel[]) => {
    if (data) {
      if (!Array.isArray(data)) setSelectedTickets([data])
      else setSelectedTickets(data)
    }
  }, [])

  const handleOnSign = useCallback((data: TicketWaiverModel) => {
    setSelectedTickets([data])
    setOpenSignWaiverModal(true)
  }, [])

  const handleOnEdit = useCallback((data: TicketWaiverModel) => {
    setSelectedTickets([data])
    setOpenEditWaiverModal(true)
  }, [])

  const handleOnDownload = useCallback(
    async (ticket: TicketWaiverModel) => {
      if (ticket.dwCode) {
        try {
          const {data: pdf} = await DownloadDigitalWaiverPdf(ticket.dwCode)
          BlobUtils.downloadBlob(pdf, `${ticket.eventName}-${ticket.productName}-${ticket.dwCode}`)
        } catch (e) {
          pushError(e)
        }
      }
    },
    [pushError]
  )

  const cards = useMemo(() => {
    if (waiverStatus === 'current' && data && data?.data) {
      return (
        <div className={clsx('row')}>
          {Array.isArray(data.data)
            ? data.data.map((ticket, index) => (
                <div
                  key={ticket.code + index}
                  className='mb-5 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-0'
                >
                  <DigitalWaiverCard
                    data={ticket}
                    tabCode={tabCode}
                    waiverStatus={waiverStatus}
                    onDelegate={handleOnDelegate}
                    onSign={handleOnSign}
                    onEdit={handleOnEdit}
                    onDownload={handleOnDownload}
                    onRefresh={onRefresh}
                  />
                </div>
              ))
            : Object.entries(data.data).map(([ticketKey, tickets], index, keys) => {
                const isLast = index === keys.length - 1
                const keyValue =
                  filters?.groupBy === 'batchId'
                    ? ticketKey.match(BATCH_ID_REGEX)
                      ? ticketKey.match(BATCH_ID_REGEX)?.[1]
                      : ticketKey
                    : ticketKey

                return (
                  <>
                    <div className='col-12'>
                      <CardCollapseRow
                        collapseKey={ticketKey}
                        keyValue={keyValue}
                        collapsedItem={collapsedItem}
                        onClick={(key) => toggleCollapse(key)}
                        onSelectGroupHandler={(key) => onSelectGroupHandler(key, tickets)}
                        selected={selected}
                        items={tickets}
                      />
                    </div>

                    {collapsedItem?.value && collapsedItem.key === ticketKey && (
                      <div>
                        {tickets &&
                          tickets.length > 0 &&
                          tickets.map((ticket: TicketWaiverModel) => {
                            return (
                              <div key={ticket.code} className='mb-3 col-12'>
                                <DigitalWaiverCard
                                  data={ticket}
                                  tabCode={tabCode}
                                  waiverStatus={waiverStatus}
                                  onRefresh={onRefresh}
                                />
                              </div>
                            )
                          })}
                      </div>
                    )}
                    {!isLast && (
                      <div className='col-12 px-4'>
                        <HorizontalDivider className='bg-secondary' spacing={1} />
                      </div>
                    )}
                  </>
                )
              })}
        </div>
      )
    }
    return null
  }, [
    waiverStatus,
    data,
    tabCode,
    handleOnDelegate,
    handleOnSign,
    handleOnEdit,
    handleOnDownload,
    onRefresh,
    filters?.groupBy,
    collapsedItem,
    selected,
    toggleCollapse,
    onSelectGroupHandler,
  ])

  const previousCards = useMemo(() => {
    if (waiverStatus === 'previous' && data && data.data && isArray(data.data))
      return (
        <div className={clsx('row')}>
          {data.data.map((prevTicket, index) => (
            <div
              key={prevTicket.code + index}
              className='mb-5 col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-0'
            >
              <DigitalWaiverCard
                data={prevTicket}
                tabCode={tabCode}
                waiverStatus={waiverStatus}
                onDelegate={handleOnDelegate}
                isExpiredTicket
                onRefresh={onRefresh}
              />
            </div>
          ))}
        </div>
      )
    return null
  }, [data, handleOnDelegate, onRefresh, tabCode, waiverStatus])

  const searchAction = useMemo(() => {
    return (
      <div className='w-100'>
        <TextInput
          inputClassName='rounded-0 px-4 py-2'
          inputWrapperClassName='search-dw-field'
          noMargin
          placeholder='Search'
          value={filters?.filters?.search ? filters.filters.search.toString() : ''}
          onChange={handleSearchChange}
          endAdornment={
            <MetronicIcon size='md' color='white' iconType='General' iconName='Search' />
          }
        />
      </div>
    )
  }, [filters?.filters?.search, handleSearchChange])

  const handleWaiverStatusGridFilter = useCallback(
    (item: MenuItem) => {
      const newFilters: FilterModel = {
        ...filters,
        filters: {
          ...filters?.filters,
          dwStatus: filters?.filters?.dwStatus || [],
        },
      }

      if (item.value === 'available') {
        if (newFilters.filters) {
          if (newFilters.filters.statusPortal) {
            delete newFilters.filters.statusPortal
            delete newFilters.filters.dwStatus
          } else {
            newFilters.filters.statusPortal = 'active'
            delete newFilters.filters.dwStatus
          }
        }
      } else {
        delete newFilters?.filters?.statusPortal
        if (newFilters?.filters?.dwStatus && Array.isArray(newFilters?.filters?.dwStatus)) {
          const found = newFilters.filters.dwStatus.find((status) => status === item.value)
          if (found) {
            newFilters.filters.dwStatus = newFilters.filters.dwStatus.filter(
              (status) => status !== item.value
            )

            if (newFilters.filters.dwStatus.length === 0) delete newFilters.filters.dwStatus
          } else {
            newFilters?.filters?.dwStatus.push(item.value)
          }
        }
      }
      onFilter(newFilters)
    },
    [filters, onFilter]
  )

  return (
    <div className={className}>
      <WaiverSignModalForm
        handleClose={() => setOpenSignWaiverModal(false)}
        onRefresh={onRefresh}
        open={openSignWaiverModal}
        selectedTicket={selectedTickets && selectedTickets[0]}
      />
      <WaiverEditModalForm
        handleClose={() => setOpenEditWaiverModal(false)}
        onRefresh={onRefresh}
        open={openEditWaiverModal}
        selectedTicket={selectedTickets && selectedTickets[0]}
      />

      <div className='d-flex align-items-center gap-3 mb-6'>
        <div className='d-flex flex-nowrap flex-grow-1 gap-5 border rounded border-primary justify-content-between p-5'>
          <div className='d-flex flex-column flex-grow-1 gap-5'>
            <div className='d-flex flex-wrap flex-grow-1 gap-5'>
              <div className={styles.textInputFilter}>{searchAction}</div>
              {waiverStatus === 'current' && tabCode === 'available' && (
                <div className={styles.textInputFilter}>
                  <WaiverAddTicketButton
                    className='w-100'
                    isOpen={isPopupOpen}
                    onClose={closePopup}
                    onOpen={openPopup}
                    onRefresh={onRefresh}
                  >
                    <AddTicketByIdForm
                      onSubmit={() => {
                        closePopup()
                        onRefresh()
                      }}
                    />
                  </WaiverAddTicketButton>
                </div>
              )}
            </div>
            {tabCode === 'available' && waiverStatus !== 'previous' && (
              <TicketFiltersGrid onClick={handleWaiverStatusGridFilter} filters={filters} />
            )}
          </div>

          {hasFilters && waiverStatus === 'current' && (
            <MetronicIconButton
              iconType='Code'
              iconName='Stop'
              activeColor='danger'
              size='md'
              onClick={clearFilters}
              tooltip='Clear Filters'
              color='dark'
              className='bg-transparent'
            />
          )}
        </div>
      </div>

      {selected && selected.length > 0 && (
        <div className={clsx('mt-2')}>{`SELECTED RECORDS COUNT: ${selected.length}`}</div>
      )}

      <div
        className={clsx('d-flex gap-3 flex-wrap filters-box-dw', selected.length > 0 && 'p-3 mb-5')}
      >
        <TicketTableSelectionActions
          items={tableSelectionItems}
          selected={selected}
          onDelegate={handleOnDelegate}
          size='sm'
        />
      </div>

      {waiverStatus === 'current' && <div>{cards}</div>}

      {waiverStatus === 'previous' && previousCards && (
        <div className='position-relative'>
          <div className='mt-7'>{previousCards}</div>
        </div>
      )}

      <div>
        <PaginationHelper
          currentPageNumber={filters && filters.page ? filters.page : 1}
          currentPageSize={filters && filters.limit ? filters.limit : 10}
          onChangePageNumber={setPageNumber}
          onChangePageSize={setPageSize}
          total={data?.total}
        />
      </div>
    </div>
  )
}

const BATCH_ID_REGEX = /(.+) \(.+\)/
