import {FormikContextType} from 'formik'
import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {SelectInput, TextInput} from '../../../../../components/inputs'
import {Button} from '../../../../../components/inputs/Button'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {PasswordInput} from '../../../../../components/inputs/PasswordInput'
import {ProductInputItemValue} from '../../../../../components/inputs/ProductInput/ProductInputItem'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {TreeNodeItem, TreeSelect} from '../../../../../components/inputs/TreeSelect/TreeSelect'
import {useModalState} from '../../../../../components/modals/useModalState'
import {PromoCodeModel} from '../../../../../models/customer-delegate/RegistrationPayment'
import {Validators} from '../../../../../utils/Validators'
import {InvoiceModal} from '../../modals/InvoiceModal'

export interface NewRegistrationPaymentStepValues {
  email: string
  paymentType: string
  name: string
  products: ProductInputItemValue[]
  promoCode: string
  creditCardNumber: string
  cvv: string
  cardExpiryMonth: string
  cardExpiryYear: string
}

export interface NewRegistrationPaymentStepProps<T extends NewRegistrationPaymentStepValues> {
  formik: FormikContextType<T>
}
export const NewRegistrationPaymentStep = <T extends NewRegistrationPaymentStepValues>({
  formik,
}: NewRegistrationPaymentStepProps<T>) => {
  const textInputHelpers = useFormikTextInputHelpers(formik)
  const [totalPriceBeforeVat, setTotalPriceBeforeVat] = useState(0)
  const [vatPrice, setVatPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [promoCodeType, setPromoCodeType] = useState('')
  const [promoCodeValue, setPromoCodeValue] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)

  const getTreeChangeHandler = useCallback(
    (name: string) => (values: string[]) => {
      const value = values[0]
      formik.setFieldValue(name, value || '')
    },
    [formik]
  )
  const products = formik.values.products

  const {
    hide: hideSelectionModal,
    isOpen: isSelectionOpen,
    open: openSelectionModal,
  } = useModalState()

  const paymentTypeValue = useMemo(() => {
    return [formik.values.paymentType]
  }, [formik.values.paymentType])

  const parentPaymentMethodItems: TreeNodeItem[] = [
    {
      label: 'Bank Transfer',
      value: 'Bank Transfer',
    },
    {
      label: 'Credit Card',
      value: 'Credit Card',
    },
  ]
  const getProductPrice = useCallback((count: number, price: number | undefined) => {
    if (price) {
      const productPrice = count * price
      return <div className='flex-end'>{productPrice}</div>
    }
  }, [])
  const getTotalPrice = useMemo(() => {
    if (products) {
      let total = 0
      for (let item of products) {
        if (item.price) {
          total += item.price * item.count
        }
      }
      const priceBeforeVat = total
      setTotalPriceBeforeVat(priceBeforeVat)
      const vatPrice = (5 / 100) * total
      setVatPrice(vatPrice)

      if (isValid && isClicked) {
        if (promoCodeType === 'percentage') {
          const discount = (promoCodeValue / 100) * total
          setDiscountPrice(discount)
        } else if (promoCodeType === 'deductible') {
          const discount = promoCodeValue
          setDiscountPrice(discount)
        }
      }
      const totalPrice = total + vatPrice - discountPrice
      setTotalPrice(totalPrice)
      return (
        <>
          <div className='d-flex justify-content-between'>
            <div className='p-2'>Total (before Vat)</div>
            {priceBeforeVat}
          </div>

          <div className='d-flex justify-content-between'>
            <div className='p-2'>VAT @5%</div>
            {vatPrice}
          </div>

          {isValid && (
            <div className='d-flex justify-content-between'>
              <div className='p-2'>Discount Amount</div>- {discountPrice}
            </div>
          )}

          <div className='d-flex justify-content-between'>
            <div className='p-2'>TOTAL</div>
            {totalPrice}
          </div>
        </>
      )
    }
  }, [discountPrice, isClicked, isValid, products, promoCodeType, promoCodeValue])

  const cardValue = useMemo(() => {
    return formik.values.creditCardNumber
      .split(/(.{4})/)
      .filter((t) => Boolean(t))
      .join(' ')
  }, [formik.values.creditCardNumber])

  const handleCardChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.split(' ').join('')
      const LIMIT = 16
      if (value.length <= LIMIT && Validators.WHOLE_NUMBER_REGEX.test(value)) {
        formik.setFieldValue('creditCardNumber', value)
      }
    },
    [formik]
  )

  const yearList = useMemo((): SelectInputItem[] => {
    const currentYear = new Date().getFullYear()
    const YEARS_COUNT = 15
    return Array.from({length: YEARS_COUNT}, (v, i) => {
      const year = currentYear + i
      const value = year.toString()

      return {
        label: value,
        value: value.substring(2),
      }
    })
  }, [])

  const handleCvvChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const LIMIT = 3
      const isNumber = Validators.WHOLE_NUMBER_REGEX.test(value)
      if (isNumber && value.length <= LIMIT) {
        formik.setFieldValue('cvv', value)
      }
    },
    [formik]
  )

  const promoCodeValidity = useMemo(() => {
    if (isValid && isClicked) {
      return <div className='mb-5'>Discount code has been applied!</div>
    } else if (!isValid && isClicked) return <div className='mb-5'>Invalid discount code</div>
  }, [isClicked, isValid])

  const checkPromoCodeValidity = useCallback(() => {
    setIsValid(false)

    for (let item of getValidPromoCodes) {
      if (formik.values.promoCode === item.code) {
        setPromoCodeType(item.promoCodeType)
        setPromoCodeValue(item.promoCodeValue)
        setIsValid(true)
      }
    }

    setIsClicked(true)
  }, [formik.values.promoCode])

  return (
    <>
      <div className='justify-content-center mb-5'>
        By registering your details, you understand that your personal data will be handled
        according to GPCA Privacy Policy.
      </div>
      <TreeSelect
        label='Payment Method'
        items={parentPaymentMethodItems}
        radioName='paymentType'
        onChange={getTreeChangeHandler('paymentType')}
        values={paymentTypeValue}
      />
      <div className='col-12'>
        <TextInput
          label='Invoice to be sent to'
          placeholder='Enter Full Name'
          {...textInputHelpers.getProps('name')}
        />
      </div>
      <div className='col-12'>
        <TextInput
          label='Email Address'
          type='email'
          placeholder='Enter Email Address'
          {...textInputHelpers.getProps('email')}
        />
      </div>
      <div className='form-label'>Payment Details</div>
      <div className='col'>
        {products.map((product) => (
          <div className='d-flex justify-content-between' key={product.id}>
            <div className='p-2'>
              {product.name} x {product.count}
            </div>
            {getProductPrice(product.count, product.price)}
          </div>
        ))}
      </div>
      {/* separate API for promocode */}

      <div className='d-flex align-items-baseline mt-5'>
        <TextInput
          className='flex-grow-1 '
          placeholder='Promo Code'
          {...textInputHelpers.getProps('promoCode')}
        />
        <Button type='button' variant='primary' onClick={checkPromoCodeValidity}>
          ENTER
        </Button>
      </div>
      {promoCodeValidity}

      {getTotalPrice}
      {formik.values.paymentType === '' ? (
        <></>
      ) : formik.values.paymentType === 'Bank Transfer' ? (
        <>
          <div className='mt-10 form-label'>Account Details</div>
          <div>
            Confirmation email will be sent. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Desription details.
          </div>
          <div className='mt-5'>Bank Name: Mashreq Bank</div>
          <div className='mt-5'>SWIFT/BIC: EBILAEAD</div>
          <div className='mt-5'>IBAN: AE12345678912345</div>
          <div className='mt-5'>Account Number: 0123456789</div>
          <div className='mt-5'>Currency: AED</div>
          <div className='mt-5 mb-5'>Branch Address: Business Bay, Dubai</div>
        </>
      ) : (
        <>
          <div className='col-12 mt-10'>
            <TextInput
              {...formik.getFieldProps('creditCardNumber')}
              isTouched={formik.touched.creditCardNumber as boolean}
              errorMessage={formik.errors.creditCardNumber as string}
              label='Card Number'
              value={cardValue}
              onChange={handleCardChange}
              inputClassName='credit-card-input'
            />
          </div>

          <div className='row gx-3'>
            <div className='col-6 col-md-3'>
              <SelectInput
                items={MONTH_LIST}
                {...formik.getFieldProps('cardExpiryMonth')}
                label='Month'
                placeholder='MM'
              />
            </div>
            <div className='col-6 col-md-3'>
              <SelectInput
                items={yearList}
                {...formik.getFieldProps('cardExpiryYear')}
                label='Year'
                placeholder='YYYY'
              />
            </div>

            <div className='col-12 col-md-6'>
              <PasswordInput
                {...formik.getFieldProps('cvv')}
                isTouched={formik.touched.cvv as boolean}
                errorMessage={formik.errors.cvv as string}
                label='CVV'
                autoComplete='off'
                onChange={handleCvvChange}
                placeholder='Please enter CVV'
              />
            </div>
          </div>
        </>
      )}
      {/* invoice modal */}
      <Button
        uppercase={false}
        type='button'
        variant='link'
        className='mb-5 text-start px-0 '
        onClick={openSelectionModal}
      >
        Click here to view invoice
      </Button>
      <InvoiceModal
        open={isSelectionOpen}
        onHide={hideSelectionModal}
        data={products}
        totalPriceBeforeVat={totalPriceBeforeVat}
        vatPrice={vatPrice}
        totalPrice={totalPrice}
      >
        {getTotalPrice}
      </InvoiceModal>
    </>
  )
}

export const STEP_PAYMENT_KEYS: Array<keyof NewRegistrationPaymentStepValues> = ['email']

const MONTH_LIST: SelectInputItem[] = [
  {
    label: 'January',
    value: '01',
  },
  {
    label: 'February',
    value: '02',
  },
  {
    label: 'March',
    value: '03',
  },
  {
    label: 'April',
    value: '04',
  },
  {
    label: 'May',
    value: '05',
  },
  {
    label: 'June',
    value: '06',
  },
  {
    label: 'July',
    value: '07',
  },
  {
    label: 'August',
    value: '08',
  },
  {
    label: 'September',
    value: '09',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
]

const getValidPromoCodes: PromoCodeModel[] = [
  {
    code: 'DEDUCT10',
    promoCodeType: 'deductible',
    promoCodeValue: 10,
  },
  {
    code: 'DEDUCT30',
    promoCodeType: 'deductible',
    promoCodeValue: 30,
  },
  {
    code: 'PRC10',
    promoCodeType: 'percentage',
    promoCodeValue: 10,
  },
  {
    code: 'PRC15',
    promoCodeType: 'percentage',
    promoCodeValue: 15,
  },
  {
    code: 'PRC20',
    promoCodeType: 'percentage',
    promoCodeValue: 20,
  },
  {
    code: 'DEDUCT20',
    promoCodeType: 'deductible',
    promoCodeValue: 20,
  },
]
