import {useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {useOnMount} from '../../components/hooks/useOnMount'
import {Page404} from '../../components/layouts/Page404/Page404'
import {ValidateCurrentOutlet} from '../../modules/outlet/redux/OutletCRUD'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'

export const Routes = () => {
  const [outletIsValid, setOutletIsValid] = useState<boolean | null>(null)

  useOnMount(async () => {
    try {
      await ValidateCurrentOutlet()
      setOutletIsValid(true)
    } catch (e) {
      setOutletIsValid(false)
    }
  })

  if (outletIsValid) {
    return (
      <Switch>
        <Route path='/logout' component={Logout} />
        <Route path='/error/404'>
          <FullPage404 link='/' />
        </Route>
        <>
          <PublicRoutes />
          <PrivateRoutes />
        </>
      </Switch>
    )
  } else if (outletIsValid === false) {
    return <Page404 link='https://webntech.ae' />
  } else {
    return <FallbackView />
  }
}
