import { useCallback, useState } from "react"
import { Drawer } from "../../../../../components/Drawer/Drawer"
import { UserModel } from "../../../../../models/customer-portal/UserModel"
import { UserForm, UserFormValues, userFormValidationSchema } from "./UserForm"
import { useDispatch } from "react-redux"
import { useDrawerRef } from "../../../../../components/Drawer/useDrawerRef"
import { useAlerts } from "../../../../../components/alerts/useAlerts"
import * as RxCustPort from '../../../redux/CustomerPortalRedux'
import { useFormik } from "formik"
import { useOnChange } from "../../../../../components/hooks/useOnChange"
import { getUserPayload, initialValues, useFormDrawerUserData } from "../../../hooks/useFormDrawerUserData"
import { PutUser } from "../../../redux/CustomerPortalCRUD"

export interface UserFormEditProps {
  drawerRef: (drawer: Drawer | null) => void
  userToEdit?: UserModel
  onChange: () => void
}

const UserFormEdit = ({drawerRef, userToEdit, onChange}: UserFormEditProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [drawer, setDrawer] = useDrawerRef()
  const {pushError} = useAlerts()
  const formDrawer = useFormDrawerUserData()

  const updateUser = useCallback(
    async (values: UserFormValues) => {
      const payload = getUserPayload({values, editing: true})
      if (userToEdit) {
        try {
          await PutUser(payload, userToEdit.code)
          dispatch(RxCustPort.actions.users.updateSuccess())
          onChange()
        } catch (e) {
          dispatch(RxCustPort.actions.users.updateFailed())
          throw e
        }
      }
    },
    [userToEdit, dispatch, onChange]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: userFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await updateUser(values)
        handleCloseDrawer()
        drawer?.hide()
      } catch (e: any) {
        pushError(e)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
  }, [formik])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  useOnChange(userToEdit, () => {
    if (userToEdit) {
      const values = formDrawer.mapUserDataToFormValues(userToEdit)
      formik.setValues(values)
    }
  })

  return (
    <Drawer ref={handleDrawerRef} onClose={handleCloseDrawer}>
      <UserForm
        title='Edit User'
        onCloseButtonClick={drawer?.hide}
        isLoading={loading}
        formik={formik}
      />
    </Drawer>
  )
}

export default UserFormEdit