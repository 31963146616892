import {FC, useMemo} from 'react'
import {Redirect, useRouteMatch} from 'react-router-dom'
import {AuthNavigationSectionMenuItem} from '../../../../config/portalDrawer'
import {DigitalWaiverTicketPage, WaiverStatusType} from './DigitalWaiverTicketPage'
import {BannerImage} from '../components/BannerImage'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {Toolbar} from '../../../components/layouts/WaiverLayout/Toolbar'
import {NavHeader} from '../../../components/layouts/WaiverLayout/NavHeader/NavHeader'
import {useLocation} from 'react-router'
import {ActiveWaiverDescription} from './components/ActiveWaiverDescription'
import {SharedWaiverDescription} from './components/SharedWaiverDescription'
import {UsedWaiverDescription} from './components/UsedWaiverDescription'
export interface RouteParams {
  waiverStatus?: WaiverStatusType
  ticketStatus?: string
  ticketCode?: string
}

export interface TabProps {
  code: string
  name: string
}

const DigitalWaiverPage: FC = () => {
  const {down} = useBreakpoint()
  const {
    params: {waiverStatus, ticketStatus},
  } = useRouteMatch<RouteParams>()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const {pathname} = useLocation()

  const redirectInitialRoute = useMemo(() => {
    if (pathname.indexOf('current') === -1 && pathname.indexOf('previous') === -1) {
      return <Redirect to={`/waiver/current`} />
    } else {
      if (pathname.indexOf('available') === -1 && pathname.indexOf('shared') === -1) {
        return <Redirect to={`/waiver/${waiverStatus}/available`} />
      }
    }
  }, [pathname, waiverStatus])

  const subMenus = useMemo(() => {
    const allTabs: AuthNavigationSectionMenuItem[] = [
      {title: 'Available', to: `/waiver/${waiverStatus}/available`, isSub: true},
      {title: 'Shared', to: `/waiver/${waiverStatus}/shared`, isSub: true},
    ]
    return allTabs
  }, [waiverStatus])

  return (
    <>
      {redirectInitialRoute}
      {subMenus.length > 0 && waiverStatus === 'current' && (
        <Toolbar>
          <div
            className='d-flex flex-row w-100'
            style={{
              backgroundColor: '#0a393f',
              borderRadius: '40px',
              border: '1px solid #37ccde',
              height: '50px',
            }}
          >
            <NavHeader className='text-uppercase fs-5' menus={subMenus} subMenu={true} />
          </div>
        </Toolbar>
      )}

      <div
        style={{
          marginTop: waiverStatus === 'previous' ? '-75px' : '0px',
        }}
      >
        <BannerImage
          className={isMobile ? 'mx-auto w-100' : 'mx-auto w-75'}
          marginTop='0px'
          imageBanner='/media/saas/waiver-banner.png'
        />

        <div className='ff-admm-regular fs-3 text-gray mt-sm-0 mt-md-5 px-0 px-md-3 mb-5'>
          {waiverStatus === 'current' ? (
            ticketStatus === 'available' ? (
              <ActiveWaiverDescription />
            ) : (
              <SharedWaiverDescription />
            )
          ) : (
            <UsedWaiverDescription />
          )}
        </div>
        <DigitalWaiverTicketPage tabCode={ticketStatus} waiverStatus={waiverStatus} />
      </div>
    </>
  )
}

export default DigitalWaiverPage
