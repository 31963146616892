import clsx from 'clsx'
import {useMemo} from 'react'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {Paper} from '../../../../components/utils/Paper'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {PortalShareStatusColumn} from '../tables/PortalShareStatusColumn'
import {TicketTableRowActions} from '../../../customer-delegate/components/tables/TicketTable/TicketTableRowActions'

export interface TicketCardProps {
  data: TicketPortalModel
  className?: string
  loading?: boolean
}

export const TicketCard = ({data, className, loading}: TicketCardProps) => {
  const seats = useMemo(() => {
    if (data.seatRow !== undefined || data.seatNo !== undefined) {
      return (
        <div className={clsx({'mt-5': Boolean(data.share)})}>
          {data.seatRow && (
            <span className='me-5'>
              <MetronicIcon iconType='Layout' iconName='Layout-horizontal' />
              {data.seatRow}
            </span>
          )}
          {data.seatNo && (
            <span>
              <MetronicIcon iconType='Home' iconName='Chair2' />
              {data.seatNo}
            </span>
          )}
        </div>
      )
    }
    return null
  }, [data.seatNo, data.seatRow, data.share])

  return (
    <Paper className={clsx('p-5 d-flex flex-column justify-content-between', className)} rounded>
      <div className='d-flex justify-content-between'>
        <div className='d-flex flex-column'>
          <div>
            <div className='fw-bolder'>Product:</div>
            <div className='fs-6'>{data.productName}</div>
          </div>
        </div>
        <div className='d-flex flex-column align-items-end'>
          {data.status && (
            <div>
              <div className='fw-bolder'>Status:</div>
              <PortalShareStatusColumn data={data} />
            </div>
          )}
        </div>
      </div>
      <div className='mt-5 d-flex justify-content-between align-items-end'>
        <div>
          {data.share && (
            <div>
              <div className='fw-bolder'>Shared To:</div>
              <div>
                <MetronicIcon iconType='General' iconName='User' />
                {data.share.name}
              </div>
              <div>
                <MetronicIcon iconType='Communication' iconName='Mail-at' />
                {data.share.email}
              </div>
            </div>
          )}
          {seats}
        </div>
        <div className='table-action-container'>
          <TicketTableRowActions loading={loading} data={data} />
        </div>
      </div>
    </Paper>
  )
}
