import {useMemo, useState} from 'react'
import {Body} from '../../../../../_metronic/layout/foh/Body'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {Paper} from '../../../../components/utils/Paper'
import {OrderActionSidebar} from '../../components/sidebars/OrderActionSidebar'
import {FohOrderListTable} from '../../components/tables/FohOrderListTable'
import {TabList} from '../../components/TabList'
import {useCurrentOutletOrders} from '../../hooks/useCurrentOutletOrders'
import {useOrderActionSidebarProps} from '../../hooks/useOrderActionSidebarProps'

export const FohOrderListPage = () => {
  const [tab, setTab] = useState('all')
  const {getProps} = useOrderActionSidebarProps()
  const {orders} = useCurrentOutletOrders()

  const filteredOrders = useMemo(() => {
    return orders.filter((order) => {
      if (tab === 'incomplete') {
        return order.status !== 'completed'
      } else if (tab === 'completed') {
        return order.status === 'completed'
      } else if (tab === 'foh') {
        return !order.isDigitalMenu
      } else if (tab === 'dig') {
        return order.isDigitalMenu
      }
      return true
    })
  }, [orders, tab])

  return (
    <>
      <Body
        header={
          <Paper className='page-title foh-header p-0 overflow-hidden' variant='light'>
            <h1 className='mx-8'>Orders</h1>
            <div className='d-flex w-100 h-100 justify-content-center overflow-auto'>
              <TabList
                className='h-100 align-self-end'
                value={tab}
                onClick={setTab}
                tabs={tabList}
              />
            </div>
          </Paper>
        }
      >
        <Paper>
          <FohOrderListTable data={filteredOrders} />
        </Paper>
      </Body>
      <OrderActionSidebar {...getProps()} />
    </>
  )
}

const tabList: SelectInputItem[] = [
  {label: 'ALL', value: 'all'},
  {label: 'INCOMPLETE', value: 'incomplete'},
  {label: 'COMPLETED', value: 'completed'},
  {label: 'FOH', value: 'foh'},
  {label: 'DIGITAL MENU', value: 'dig'},
]
