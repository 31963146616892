import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {TableColumnIcon} from './TableColumnIcon'

export interface TableWrapperColumnProps {
  label: string
  className?: string
  onSort?: (isAscending: boolean) => void
  isAscending?: boolean
}

export const TableWrapperSortableColumnHeader = ({
  label,
  onSort,
  isAscending,
  className,
}: TableWrapperColumnProps) => {
  const handleOnClick = useCallback(() => {
    onSort && onSort(!isAscending)
  }, [onSort, isAscending])

  const sortIconButton = useMemo(() => {
    if (onSort) {
      let iconName: string | undefined = undefined
      if (typeof isAscending === 'boolean') {
        iconName = isAscending ? 'Arrow-up' : 'Arrow-down'
      }

      return (
        <TableColumnIcon
          onClick={handleOnClick}
          iconType='Navigation'
          iconName={iconName}
          label={label}
        />
      )
    }
    return <span className='text-uppercase text-muted fw-bolder'>{label}</span>
  }, [onSort, isAscending, handleOnClick, label])

  return <th className={clsx(className)}>{sortIconButton}</th>
}
