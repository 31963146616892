import {v4 as uuidv4} from 'uuid'
import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {OrganizationModel} from '../../../../models/OrganizationModel'
import {RoleModel} from '../../../../models/RoleModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {UserLogModel} from '../../../../models/UserLogModel'
import {UserModel, UserModelCreateParams} from '../../../../models/UserModel'
import {GetOrganizationRole, GetOrganizationUsers, PostUser, PutUser} from './SystemCRUD'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {CustomerModel} from '../../../../models/CustomerModel'
import {VenueModel} from '../../../../models/acs/VenueModel'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {GateModel} from '../../../../models/acs/GateModel'
import {ProductCategoryModel} from '../../../../models/ems/ProductCategoryModel'
import {LocationModel} from '../../../../models/acs/LocationModel'
import {FilterModel} from '../../../../models/FilterModel'
import {ActivityModel} from '../../../../models/ems/ActivityModel'
import {AlertCreateOptions, AlertData} from '../../../../components/alerts/Alert'

export type EntityName =
  | 'role'
  | 'event'
  | 'product'
  | 'product-category'
  | 'event-detail-booking'
  | 'location'
  | 'customer'
  | 'venue'
  | 'booking'
  | 'booking-product'
  | 'booking-bundle'
  | 'gate'
  | 'user'
  | 'activity'
  | 'bundle'
  | 'ticket'
  | 'ticket-log'
  | 'outlet'
  | 'fnb-product-category'
  | 'fnb-product'
  | 'fnb-user'
  | 'fnb-order'
  | 'fnb-outlet-log'

export type RoleModelFocusableEntity = FocusedEntity<RoleModel, 'role'>
export type EventModelFocusableEntity = FocusedEntity<EventModel, 'event'>
export type ProductModelFocusableEntity = FocusedEntity<ProductModel, 'product'>
export type ProductCategoryModelFocusableEntity = FocusedEntity<
  ProductCategoryModel,
  'product-category'
>
export type LocationModelFocusableEntity = FocusedEntity<LocationModel, 'location'>
export type CustomerModelFocusableEntity = FocusedEntity<CustomerModel, 'customer'>
export type VenueModelFocusableEntity = FocusedEntity<VenueModel, 'venue'>
export type BookingModelFocusableEntity = FocusedEntity<BookingModel, 'booking'>
export type GateModelFocusableEntity = FocusedEntity<GateModel, 'gate'>
export type UserModelFocusableEntity = FocusedEntity<UserModel, 'user'>
export type EventBookingModelFocusableEntity = FocusedEntity<BookingModel, 'event-detail-booking'>
export type ActivityBookingModelFocusableEntity = FocusedEntity<ActivityModel, 'activity'>

export type AllFocusableEntity =
  | RoleModelFocusableEntity
  | EventModelFocusableEntity
  | ProductModelFocusableEntity
  | ProductCategoryModelFocusableEntity
  | CustomerModelFocusableEntity
  | VenueModelFocusableEntity
  | BookingModelFocusableEntity
  | GateModelFocusableEntity
  | LocationModelFocusableEntity
  | UserModelFocusableEntity
  | EventBookingModelFocusableEntity
  | ActivityBookingModelFocusableEntity

export interface FocusedEntity<Entity, Name extends EntityName> {
  loading: boolean
  name: Name
  entity: Entity | null
}

export interface TableOption {
  hiddenColumns: string[]
}

interface ActionWithPayload<T> extends Action {
  payload?: T
}
export interface IPagesState {
  organization?: OrganizationModel
  roles?: GlobalSearchModel<RoleModel>
  users?: GlobalSearchModel<UserModel>
  userLogs?: GlobalSearchModel<UserLogModel>
  allRoles?: RoleModel[]
  focusedEntity: AllFocusableEntity | null
  filters: Partial<Record<string, FilterModel>>
  alerts: AlertData[]
  tableOptions: Partial<Record<string, TableOption>>
}

const initialAuthState: IPagesState = {
  organization: undefined,
  roles: undefined,
  users: undefined,
  userLogs: undefined,
  allRoles: [],
  focusedEntity: null,
  filters: {},
  alerts: [],
  tableOptions: {},
}

const actionTypes = {
  SET_ENTITY_FILTER: '[SYSTEM] SET ENTITY FILTER',
  GET_ORGANIZATION_LOG: '[SYSTEM] GET ORGANIZATION LOG',
  GET_ORGANIZATION_LOG_SUCCESS: '[SYSTEM] GET ORGANIZATION LOG SUCCESS',
  GET_ORGANIZATION_LOG_FAILED: '[SYSTEM] GET ORGANIZATION LOG FAILED',
  GET_ORGANIZATION_PROFILE: '[SYSTEM] GET ORGANIZATION PROFILE',
  GET_ORGANIZATION_PROFILE_SUCCESS: '[SYSTEM] GET ORGANIZATION PROFILE SUCCESS',
  GET_ORGANIZATION_PROFILE_FAILED: '[SYSTEM] GET ORGANIZATION PROFILE FAILED',
  GET_ORGANIZATION_ROLE: '[SYSTEM] GET ORGANIZATION ROLE',
  GET_ORGANIZATION_ROLE_SUCCESS: '[SYSTEM] GET ORGANIZATION ROLE SUCCESS',
  GET_ORGANIZATION_ROLE_FAILED: '[SYSTEM] GET ORGANIZATION ROLE FAILED',
  GET_ORGANIZATION_USERS: '[SYSTEM] GET ORGANIZATION USERS',
  GET_ORGANIZATION_USERS_SUCCESS: '[SYSTEM] GET ORGANIZATION USERS SUCCESS',
  GET_ORGANIZATION_USERS_FAILED: '[SYSTEM] GET ORGANIZATION USERS FAILED',
  GET_ORGANIZATION_ALL_ROLE: '[SYSTEM] GET ORGANIZATION ALL ROLE',
  GET_ORGANIZATION_ALL_ROLE_SUCCESS: '[SYSTEM] GET ORGANIZATION ALL ROLE SUCCESS',
  GET_ORGANIZATION_ALL_ROLE_FAILED: '[SYSTEM] GET ORGANIZATION ALL ROLE FAILED',
  CREATE_ROLE_SUCCESS: '[SYSTEM] CREATE ROLE SUCCESS',
  CREATE_USER: '[SYSTEM] CREATE USER',
  CREATE_USER_SUCCESS: '[SYSTEM] CREATE USER SUCCESS',
  CREATE_USER_FAILED: '[SYSTEM] CREATE USER FAILED',
  UPDATE_USER: '[SYSTEM] UPDATE USER',
  UPDATE_USER_FAILED: '[SYSTEM] UPDATE USER FAILED',
  UPDATE_USER_SUCCESS: '[SYSTEM] UPDATE USER SUCCESS',
  DELETE_ROLE_SUCCESS: '[SYSTEM] DELETE ROLE SUCCESS',
  DELETE_USER_SUCCESS: '[SYSTEM] DELETE USER SUCCESS',
  SET_FOCUSED_ENTITY: '[SYSTEM] SET FOCUSED ENTITY',
  SET_TABLE_OPTIONS: '[SYSTEM] SET TABLE OPTIONS',
  PUSH_ALERT: '[SYSTEM] PUSH ALERT',
  REMOVE_ALERT: '[SYSTEM] REMOVE ALERT',
}

// REDUCERS
export const reducer = persistReducer(
  {storage, key: 'webapp-pages', whitelist: ['tableOptions', 'alerts']},
  (state: IPagesState = initialAuthState, action: ActionWithPayload<IPagesState>) => {
    switch (action.type) {
      case actionTypes.GET_ORGANIZATION_LOG_SUCCESS: {
        const userLogs = action.payload?.userLogs
        return {...state, userLogs}
      }
      case actionTypes.GET_ORGANIZATION_PROFILE_SUCCESS: {
        const organization = action.payload?.organization
        return {...state, organization}
      }
      case actionTypes.GET_ORGANIZATION_ROLE_SUCCESS: {
        const roles = action.payload?.roles
        return {...state, roles}
      }
      case actionTypes.GET_ORGANIZATION_ALL_ROLE_SUCCESS: {
        const allRoles = action.payload?.allRoles
        return {...state, allRoles}
      }
      case actionTypes.GET_ORGANIZATION_USERS_SUCCESS: {
        const users = action.payload?.users
        return {...state, users}
      }
      case actionTypes.SET_FOCUSED_ENTITY: {
        return {...state, ...action.payload}
      }
      case actionTypes.SET_ENTITY_FILTER: {
        const actionName = (action.payload as any).name
        const filter = (action.payload as any).filter
        if (actionName) {
          return {...state, filters: {...state.filters, [actionName]: filter}}
        }
        return state
      }
      case actionTypes.SET_TABLE_OPTIONS: {
        const tableName = (action.payload as any).name
        const tableOption = (action.payload as any).option
        if (tableName) {
          return {...state, tableOptions: {...state.tableOptions, [tableName]: tableOption}}
        }
        return state
      }
      case actionTypes.PUSH_ALERT: {
        const newAlerts = [...state.alerts]
        if (action.payload) {
          newAlerts.push(action.payload as unknown as AlertData)
        }
        return {...state, alerts: newAlerts}
      }
      case actionTypes.REMOVE_ALERT: {
        const newAlerts = [...state.alerts]
        if (action.payload) {
          const alertIndex = newAlerts.findIndex((alert) => {
            return alert.key === (action.payload as unknown as string)
          })
          if (alertIndex >= 0) {
            newAlerts.splice(alertIndex, 1)
          }
        }
        return {...state, alerts: newAlerts}
      }
      default:
        return state
    }
  }
)

// ACTIONS
export const actions = {
  setFocusedEntity: (focusedEntity: AllFocusableEntity | null) => ({
    type: actionTypes.SET_FOCUSED_ENTITY,
    payload: {focusedEntity},
  }),
  fulfillAllRoles: (allRoles: RoleModel[]) => ({
    type: actionTypes.GET_ORGANIZATION_ALL_ROLE_SUCCESS,
    payload: {allRoles},
  }),
  fulfillOrganizationLogs: (userLogs: GlobalSearchModel<UserLogModel>) => ({
    type: actionTypes.GET_ORGANIZATION_LOG_SUCCESS,
    payload: {userLogs},
  }),
  fulfillOrganizationProfile: (organization: OrganizationModel) => ({
    type: actionTypes.GET_ORGANIZATION_PROFILE_SUCCESS,
    payload: {organization},
  }),
  fulfillRoles: (roles: GlobalSearchModel<RoleModel>) => ({
    type: actionTypes.GET_ORGANIZATION_ROLE_SUCCESS,
    payload: {roles},
  }),
  getOrganizationUsers: () => ({
    type: actionTypes.GET_ORGANIZATION_USERS,
  }),
  getOrganizationUsersSuccess: (users: GlobalSearchModel<UserModel>) => ({
    type: actionTypes.GET_ORGANIZATION_USERS_SUCCESS,
    payload: {users},
  }),
  getOrganizationUsersFailed: () => ({
    type: actionTypes.GET_ORGANIZATION_USERS_FAILED,
  }),
  createRoleSuccess: () => ({
    type: actionTypes.CREATE_ROLE_SUCCESS,
  }),
  createUser: (data: UserModelCreateParams) => ({
    type: actionTypes.CREATE_USER,
    payload: data,
  }),
  createUserSuccess: () => ({
    type: actionTypes.CREATE_USER_SUCCESS,
  }),
  createUserFailed: () => ({
    type: actionTypes.CREATE_USER_FAILED,
  }),
  updateUser: (data: UserModelCreateParams, userCode: string) => ({
    type: actionTypes.UPDATE_USER,
    payload: {user: data, code: userCode},
  }),
  updateUserSuccess: () => ({
    type: actionTypes.UPDATE_USER_SUCCESS,
  }),
  updateUserFailed: () => ({
    type: actionTypes.UPDATE_USER_FAILED,
  }),
  deleteRoleSuccess: () => ({
    type: actionTypes.DELETE_ROLE_SUCCESS,
  }),
  deleteUserSuccess: () => ({
    type: actionTypes.DELETE_USER_SUCCESS,
  }),
  setEntityFilter: (name: string, filter?: FilterModel) => ({
    type: actionTypes.SET_ENTITY_FILTER,
    payload: {
      name,
      filter,
    },
  }),
  setTableOptions: (name: string, option?: TableOption) => ({
    type: actionTypes.SET_TABLE_OPTIONS,
    payload: {
      name,
      option,
    },
  }),
  pushAlert: (options: AlertCreateOptions): ActionWithPayload<AlertData> => ({
    type: actionTypes.PUSH_ALERT,
    payload: {
      ...options,
      key: uuidv4(),
    },
  }),
  removeAlert: (key: string): ActionWithPayload<string> => ({
    type: actionTypes.REMOVE_ALERT,
    payload: key,
  }),
}

// AFTER EFFECTS
export function* saga() {
  yield takeLatest(actionTypes.CREATE_ROLE_SUCCESS, function* afterEffectSaga() {
    const {data} = yield GetOrganizationRole()
    yield put(actions.fulfillRoles(data))
  })
  yield takeLatest(actionTypes.GET_ORGANIZATION_USERS, function* afterEffectSaga() {
    const filter: FilterModel = yield select((state) => state.system.filters.user)
    try {
      const {data} = yield GetOrganizationUsers(filter)
      yield put(actions.getOrganizationUsersSuccess(data))
    } catch (e) {
      yield put(actions.getOrganizationUsersFailed())
    }
  })

  yield takeLatest(
    [
      actionTypes.CREATE_USER_SUCCESS,
      actionTypes.UPDATE_USER_SUCCESS,
      actionTypes.DELETE_USER_SUCCESS,
    ],
    function* afterEffectSaga() {
      yield put(actions.getOrganizationUsers())
    }
  )
  yield takeLatest(
    actionTypes.CREATE_USER,
    function* afterEffectSaga(action: Required<ActionWithPayload<UserModelCreateParams>>) {
      yield PostUser(action.payload)
      yield put(actions.createUserSuccess())
    }
  )
  yield takeLatest(
    actionTypes.UPDATE_USER,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{code: string; user: UserModelCreateParams}>>
    ) {
      try {
        yield PutUser(action.payload.user, action.payload.code)
        yield put(actions.createUserSuccess())
      } catch (e) {
        yield put(actions.createUserSuccess())
      }
    }
  )
  yield takeLatest(actionTypes.DELETE_ROLE_SUCCESS, function* afterEffectSaga() {
    const {data} = yield GetOrganizationRole()
    yield put(actions.fulfillRoles(data))
  })
}
