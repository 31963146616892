import {useMemo, useState, useCallback} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {FilterModel} from '../../../../../models/FilterModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {ReservationTableSelectionActions} from './ReservationTableSelectionActions'
import {ReservationTableRowActions} from './ReservationTableRowActions'
import {ReservationTableRightActions} from './ReservationTableRightActions'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {CustomerPortalLink} from '../../../../../components/utils/CustomerPortalLink'
import {FilterInputContainer} from '../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {CustomerFilterInput} from '../../../../../components/tables/advanced-filter-inputs/CustomerFilterInput'
import {MultiSelectToggleFilterInput} from '../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {FilterType} from '../../../../../components/tables/constants/FilterType'
import {ReservationPortalModel} from '../../../../../models/ems/ReservationMedel'
import {useReservationData} from '../../../hooks/useReservationData'
import {ReservationStatusColumn} from './ReservationStatusColumn'
import {EventModel} from '../../../../../models/ems/EventModel'
import {ReservationCodeColumn} from './ReservationCodeColumn'

export interface ReservationTableProps {
  data?: GlobalSearchModel<ReservationPortalModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  onNewReservationClick?: () => void
  className?: string
  event?: EventModel
  initialFilters?: FilterModel
  cornersClassName?: string
  customWrapper?: string
  paginationClassName?: string
}

export const ReservationTable = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  onNewReservationClick,
  className,
  event,
  initialFilters,
  paginationClassName,
  cornersClassName,
  customWrapper,
}: ReservationTableProps) => {
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-reservation',
  })

  const {releaseBulk, releaseSingle, isReservationLoading, isLoading} = useReservationData({
    onRefresh,
    onRefreshCallback,
  })

  const rowActions = useCallback(
    (data: ReservationPortalModel) => (
      <ReservationTableRowActions
        loading={isReservationLoading(data)}
        data={data}
        onRelease={releaseSingle}
      />
    ),
    [releaseSingle, isReservationLoading]
  )
  const tableData = useMemo((): ReservationPortalModel[] => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      }
    }
    return []
  }, [data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<ReservationPortalModel>[] = [
      {
        field: 'code',
        label: 'Code',
        hideable: true,
        sortable: true,
        render: ({data}) => ReservationCodeColumn({data, event}),

        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'customer',
        sortable: true,
        hideable: true,
        label: 'Customer',
        render: ({data}) => (
          <CustomerPortalLink customer={data.customer}>{data.customer?.name}</CustomerPortalLink>
        ),
        cellStyle: ColumnStyle.PERSON_FULL_NAME,
      },
      {
        field: 'event',
        label: 'Event',
        hideable: true,
        sortable: true,
        dataExtract: (data) => data.event?.name,
      },
      {
        field: 'status',
        label: 'Status',
        hideable: true,
        sortable: true,
        render: (data) => ReservationStatusColumn(data),
      },
      {
        field: 'reservationNo',
        label: 'Reservation',
        hideable: true,
        sortable: true,
        dataExtract: (data) => data.reservationNo,
      },
      {
        field: 'reason',
        label: 'Reason',
        hideable: true,
        sortable: true,
        dataExtract: (data) => data.reason,
      },
    ]
    return columns
  }, [event])

  return (
    <FilterTable
      className={className}
      onHiddenColumnsChange={setHiddenColumns}
      onSelectionChange={setSelected}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={tableData}
      selection={selected}
      totalItems={data?.total || 0}
      idExtractor={idExtractor}
      onFilter={onFilter}
      actions={rowActions}
      rightToolbar={<ReservationTableRightActions onNewReservationClick={onNewReservationClick} />}
      selectionAction={
        <ReservationTableSelectionActions
          loading={isLoading}
          selected={selected}
          reservations={tableData}
          onRelease={releaseBulk}
        />
      }
      initialFilters={initialFilters}
      cornersClassName={cornersClassName}
      customWrapper={customWrapper}
      paginationClassName={paginationClassName}
      advancedFilters={
        <FilterInputContainer>
          <CustomerFilterInput
            field='customer'
            label='Customer'
            placeholder='Filter Customer'
            customerType={['reseller']}
          />
          <MultiSelectToggleFilterInput
            field='status'
            items={FilterType.RESERVATION_STATUS}
            label='Status'
          />
        </FilterInputContainer>
      }
    />
  )
}
