import {Redirect, Route, Switch} from 'react-router-dom'
import {useWaiverAuth} from '../../modules/digital-waiver/hooks/useWaiverAuth'
import {ForgotPassword} from '../../modules/digital-waiver/components/forms/ForgotPassword'
import {useMemo} from 'react'
import DigitalWaiverPage from '../../modules/digital-waiver/pages/DigitalWaiverPage'

export const PrivateRoutes = () => {
  const auth = useWaiverAuth()

  const redirectRoutes = useMemo(() => {
    let link = '/error/404'
    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }
  return (
    <Switch>
      <Route path='/waiver/:waiverStatus/:ticketStatus'>
        <DigitalWaiverPage />
      </Route>

      <Route path='/waiver/:waiverStatus'>
        <DigitalWaiverPage />
      </Route>

      <Route path='/waiver'>
        <DigitalWaiverPage />
      </Route>

      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Redirect to='/waiver' />
      {redirectRoutes}
    </Switch>
  )
}
