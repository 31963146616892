import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useDrawerRef} from '../../../../components/Drawer/useDrawerRef'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {FilterModel} from '../../../../models/FilterModel'
import {ReservationModel} from '../../../../models/fnb/ReservationModel'
import {ReservationCreateDrawer} from '../../components/drawers/Reservation/ReservationCreateDrawer'
import {ReservationEditDrawer} from '../../components/drawers/Reservation/ReservationEditDrawer'
import {ReservationTable} from '../../components/tables/ReservationTable'
import {GetReservationByCode} from '../../redux/OutletCRUD'
import {actions} from '../../redux/OutletRedux'

export const OutletReservationPage = () => {
  const [editDrawer, setEditDrawer] = useDrawerRef()
  const [createDrawer, setCreateDrawer] = useDrawerRef()
  const [reservation, setReservation] = useState<ReservationModel>()
  const data = useRootStateSelector(({outlet}) => outlet.reservations)
  const dispatch = useDispatch()
  const {setFilter} = useEntityFilter('outlet-reservation')
  const {pushError} = useAlerts()

  const refreshData = useCallback(() => {
    dispatch(actions.reservations.search())
  }, [dispatch])

  const filterHandler = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshData()
    },
    [setFilter, refreshData]
  )

  const clearData = useCallback(() => {
    setReservation(undefined)
  }, [])

  const handleOnEdit = useCallback(
    async (data: ReservationModel) => {
      try {
        editDrawer?.show()
        const response = await GetReservationByCode(data.code)
        setReservation(response.data)
      } catch (e) {
        editDrawer?.hide()
        pushError(e)
      }
    },
    [editDrawer, pushError]
  )

  return (
    <>
      <PageTitle>Table Reservations</PageTitle>
      <ReservationTable
        data={data}
        onFilter={filterHandler}
        onRefresh={refreshData}
        onEdit={handleOnEdit}
        onCreateClick={createDrawer?.show}
      />
      <ReservationEditDrawer
        drawerRef={setEditDrawer}
        onClose={clearData}
        reservation={reservation}
      />
      <ReservationCreateDrawer drawerRef={setCreateDrawer} />
    </>
  )
}
