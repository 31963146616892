import clsx from 'clsx'
import {useCallback} from 'react'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'

export interface ProductGridItemAttributes {
  value: string
  label: string
  price: number
  currency: string
  image: ImageInputValue
}

export interface ProductGridItemProps extends ProductGridItemAttributes {
  onClick: (value: ProductGridItemAttributes) => void
  className?: string
}

export const ProductGridItem = ({
  value,
  label,
  currency,
  image,
  onClick,
  price,
  className,
}: ProductGridItemProps) => {
  const handleClick = useCallback(() => {
    onClick({
      currency,
      image,
      label,
      price,
      value,
    })
  }, [currency, image, label, onClick, price, value])

  return (
    <button className={clsx('foh-product-grid-item', className)} onClick={handleClick}>
      <div className='foh-product-grid-item-thumbnail'>
        <img
          className='foh-product-grid-item-thumbnail__image'
          src={image.url}
          alt={image.filename}
        />
      </div>
      <div className='foh-product-grid-item-label'>
        <p className='foh-product-grid-item-label__name'>{label}</p>
        <p>
          {price} <span className='text-muted fs-6'>{currency}</span>
        </p>
      </div>
    </button>
  )
}
