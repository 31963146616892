import {KTSVG} from '../../../../../_metronic/helpers'
import {Button} from '../../../../components/inputs/Button'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {WaiverFormContent} from '../WaiverFormContent'

interface WaiverFormHeaderProps {
  handleClose?: () => void
  selectedTicket?: TicketWaiverModel
  englishTextContent: string
  arabicTextContent: string
}

export const WaiverFormHeader = ({
  handleClose,
  selectedTicket,
  arabicTextContent,
  englishTextContent,
}: WaiverFormHeaderProps) => {
  return (
    <div>
      {handleClose && (
        <div>
          <Button
            className='bg-transparent border border-1 border-white px-10 text-white btn-sm py-1 fw-bolder'
            style={{
              borderRadius: '40px',
            }}
            onClick={handleClose}
          >
            <KTSVG
              path='/media/icons/duotone/Navigation/Close.svg'
              className='bg-transparent p-0 text-white'
            />
            Close
          </Button>
        </div>
      )}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <p className='text-white text-uppercase fs-3 fw-bolder text-end pe-5'>
              {selectedTicket?.productDigitalWaiverType === 'driver'
                ? 'EXPERIENCE ACTIVITY WAIVER & INDEMNITY'
                : 'Hotlaps ACTIVITY WAIVER & INDEMNITY'}
            </p>
          </div>
          <div className='col-12 col-md-6'>
            <p className='text-white text-uppercase fs-3 fw-bolder text-start ps-5'>
              {selectedTicket?.productDigitalWaiverType === 'driver'
                ? 'تنازل والتزام بتعويض خاص لنشاط التجربة'
                : 'التنازل عن النشاط والتعويض'}
            </p>
          </div>
        </div>
      </div>

      <WaiverFormContent
        arabicTextheader='تنازل و التعويض'
        englishTextheader='WAIVER & INDEMNITY'
        selectedTicket={selectedTicket}
        englishTextContent={englishTextContent}
        arabicTextContent={arabicTextContent}
      />
    </div>
  )
}
