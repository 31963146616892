import {
  Children,
  Fragment,
  useRef,
  useCallback,
  MouseEvent,
  useMemo,
  useState,
  TouchEvent,
  cloneElement,
} from 'react'
import clsx from 'clsx'
import {PaneDirection, PaneProps} from '../Pane/Pane'
import {Splitter} from '../Splitter/Splitter'
import styles from './PaneContainer.module.scss'

export interface PaneContainerProps {
  children?: React.ReactElement<PaneProps> | React.ReactElement<PaneProps>[]
  className?: string
  direction: PaneDirection
}

export const PaneContainer = ({children, className, direction}: PaneContainerProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const splitterRefs = useRef<Array<{el: HTMLDivElement}>>([])
  const [activeSplitter, setActiveSplitter] = useState<HTMLDivElement | null>(null)
  const paneRefs = useRef<Array<{el: HTMLDivElement; props: PaneProps}>>([])
  const handlePaneRef = useCallback(
    (props: PaneProps) => (ref: HTMLDivElement | null) => {
      if (ref) {
        paneRefs.current.push({
          el: ref,
          props,
        })
      }
    },
    []
  )

  const handleDragEnd = useCallback(() => {
    setActiveSplitter(null)
  }, [])

  const updateActiveSplitter = useCallback(
    (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      setActiveSplitter(e.currentTarget as HTMLDivElement)
    },
    []
  )

  const handleSplitterUnmount = useCallback((el: HTMLDivElement) => {
    const foundSplitterRefIndex = splitterRefs.current.findIndex((item) => item.el === el)
    if (foundSplitterRefIndex >= 0) {
      splitterRefs.current.splice(foundSplitterRefIndex, 1)
    }
  }, [])

  const getSplitterSiblings = useCallback((splitter: HTMLDivElement) => {
    if (parentRef.current) {
      const children = Array.from(parentRef.current.childNodes)
      const found = children.findIndex((item) => item === splitter)
      const beforeEl = children[found - 1] as HTMLDivElement | undefined
      const afterEl = children[found + 1] as HTMLDivElement | undefined

      if (beforeEl && afterEl) {
        const beforeProps = paneRefs.current.find((item) => item.el === beforeEl)
        const afterProps = paneRefs.current.find((item) => item.el === afterEl)

        if (beforeProps && afterProps) {
          return [beforeProps, afterProps]
        }
      }
    }
    return null
  }, [])

  const activeSplitterSiblings = useMemo(() => {
    if (activeSplitter) {
      const siblings = getSplitterSiblings(activeSplitter)
      return siblings
    }
  }, [activeSplitter, getSplitterSiblings])

  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      if (activeSplitter) {
        const pointerX = 'clientX' in e ? e.clientX : e.touches[0].pageX
        const splitterWidth = activeSplitter.offsetWidth
        const containerWidth =
          parentRef.current?.offsetWidth ?? 0 - splitterWidth * splitterRefs.current.length
        const splitX = pointerX - (parentRef.current?.offsetLeft ?? 0) - splitterWidth / 2

        if (activeSplitterSiblings) {
          const [before, after] = activeSplitterSiblings
          if (direction === 'horizontal') {
            before.el.style.flexBasis = `${Math.max(splitX, before.props.minimumWidth || 0)}px`
            after.el.style.flexBasis = `${Math.max(
              containerWidth - splitX,
              after.props.minimumWidth || 0
            )}px`
          }
        }
      }
    },
    [activeSplitter, activeSplitterSiblings, direction]
  )

  const handleSplitterRef = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      splitterRefs.current.push({
        el,
      })
    }
  }, [])

  const childrenWithSplitters = useMemo(() => {
    paneRefs.current = []
    return Children.map(children, (node, i) => {
      let key = i.toString()

      if (node && typeof node === 'object' && 'key' in node && node.key) {
        key += `-${node.key}`
      }

      return (
        <Fragment key={key}>
          {i > 0 && (
            <Splitter
              ref={handleSplitterRef}
              direction={direction}
              onMouseDown={updateActiveSplitter}
              onTouchStart={updateActiveSplitter}
              onUnmount={handleSplitterUnmount}
              onMouseUp={handleDragEnd}
            />
          )}
          {node &&
            cloneElement(node, {
              ref: handlePaneRef(node.props),
            })}
        </Fragment>
      )
    })
  }, [
    children,
    direction,
    handleDragEnd,
    handlePaneRef,
    handleSplitterRef,
    handleSplitterUnmount,
    updateActiveSplitter,
  ])

  return (
    <div
      ref={parentRef}
      className={clsx(
        styles.root,
        {
          [styles.vertical]: direction === 'vertical',
          [styles.horizontal]: direction === 'horizontal',
        },
        className
      )}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleDragEnd}
      onMouseMove={handleMouseMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
    >
      {childrenWithSplitters}
    </div>
  )
}
