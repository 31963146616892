import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {TicketTableFlat} from './TicketTableFlat'

export interface TicketTableGroupedData {
  email: string
  tickets: TicketPortalModel[]
}

export interface TicketTableGroupedRowProps {
  data: TicketTableGroupedData
  onRefresh: () => void
  onRefreshCallback?: () => void
  columns: TableColumnOptions<TicketPortalModel>[]
}

export const TicketTableGroupedRow = ({
  onRefresh,
  onRefreshCallback,
  data,
  columns,
}: TicketTableGroupedRowProps) => {
  return (
    <TicketTableFlat
      columns={columns}
      onRefresh={onRefresh}
      onRefreshCallback={onRefreshCallback}
      data={data.tickets}
    />
  )
}
