import {useCallback, useMemo, useState} from 'react'
import {ModalProps} from './Modal'

export type ModalState = Pick<ModalProps, 'open' | 'onHide'>

export interface UseModalStateReturn {
  isOpen: boolean
  getModalState: () => ModalState
  open: () => void
  hide: () => void
  toggle: () => void
}

export const useModalState = (): UseModalStateReturn => {
  const [isOpen, setIsOpen] = useState(false)

  const hide = useCallback(() => {
    setIsOpen(false)
  }, [])

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const getModalState = useCallback((): ModalState => {
    return {
      open: isOpen,
      onHide: hide,
    }
  }, [hide, isOpen])

  return useMemo(
    () => ({
      getModalState,
      isOpen,
      open,
      hide,
      toggle,
    }),
    [getModalState, hide, isOpen, open, toggle]
  )
}
