import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {CheckboxInput, DatePickerInput} from '../../../../../components/inputs'
import {AvatarImageInput} from '../../../../../components/inputs/AvatarImageInput/AvatarImageInput'
import {Button} from '../../../../../components/inputs/Button'
import {useFormikCheckboxInputHelpers} from '../../../../../components/inputs/CheckboxInput/useFormikCheckboxInputHelpers'
import {BaseFileInputValue} from '../../../../../components/inputs/FileInput/BaseFileInputValue'
import {DragDropFileInput} from '../../../../../components/inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileInputValueCollection} from '../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useFormikDatePickerInputHelpers} from '../../../../../components/inputs/hooks/useFormikDatePickerInputHelpers'
import {useFormikFileInputHelpers} from '../../../../../components/inputs/hooks/useFormikFileInputHelpers'
import {useFormikImageInputHelpers} from '../../../../../components/inputs/hooks/useFormikImageInputHelper'
import {useFormikStringInputHelpers} from '../../../../../components/inputs/hooks/useFormikStringInputHelpers'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {SearchableSelectInput} from '../../../../../components/inputs/SearchableSelect'
import {ISOCountryModel} from '../../../../../models/ISOCountryModel'
import {GetRegistrationCoutries} from '../../redux/CustomerRegistrationCRUD'

export interface RegistrationPersonalInformationProps<
  T extends RegistrationPersonalInformationValues
> {
  formik: FormikContextType<T>
}

export interface RegistrationPersonalInformationValues {
  nationality: ISOCountryModel | null
  residence: ISOCountryModel | null
  dateOfBirth: Date | null
  mobileNumber: string
  passportPhoto: ImageInputValue | null
  passportPhotoCropped: ImageInputValue | null
  passportCopy: FileInputValueCollection<BaseFileInputValue>
  documentExpiry: Date | null
  isFlight: boolean
  isHotel: boolean
  isTransportation: boolean
}

export const RegistrationPersonalInformation = <T extends RegistrationPersonalInformationValues>({
  formik,
}: RegistrationPersonalInformationProps<T>) => {
  const avatarInputRef = useRef<HTMLInputElement>(null)
  const passportInputRef = useRef<HTMLInputElement>(null)
  const [countryList, setCountryList] = useState<ISOCountryModel[]>([])

  const resetCountryList = useCallback(async () => {
    const {data} = await GetRegistrationCoutries()
    setCountryList(data)
  }, [])

  const dateInputHelpers = useFormikDatePickerInputHelpers(formik)
  const stringInputHelpers = useFormikStringInputHelpers(formik)
  const imageInputHelpers = useFormikImageInputHelpers(formik)
  const fileInputHelpers = useFormikFileInputHelpers(formik)

  const handleAvatarInputChange = useCallback(
    (image: ImageInputValue | null) => {
      formik.setFieldValue('passportPhoto', image)
    },
    [formik]
  )

  const isAskingForEmiratesId = useMemo(() => {
    return formik.values.residence?.code === 'AE'
  }, [formik.values.residence])

  const handleNationalityChange = useCallback(
    (key: string) => (value: ISOCountryModel | null) => {
      formik.setFieldValue(key, value)
    },
    [formik]
  )

  const handleNationalityBlur = useCallback(
    (key: string) => () => {
      formik.setFieldTouched(key)
    },
    [formik]
  )

  const handleUploadPhotoClick = useCallback(() => {
    avatarInputRef.current?.click()
  }, [])

  const handleResetPhotoClick = useCallback(() => {
    formik.setFieldValue('passportPhoto', null)
    formik.setFieldValue('passportPhotoCropped', null)
  }, [formik])

  const handleUploadPassportClick = useCallback(() => {
    passportInputRef.current?.click()
  }, [])

  const handleResetPassportClick = useCallback(() => {
    formik.setFieldValue('passportCopy', new FileInputValueCollection())
  }, [formik])

  const isFlight = useFormikCheckboxInputHelpers(formik, 'isFlight')
  const isHotel = useFormikCheckboxInputHelpers(formik, 'isHotel')
  const isTransportation = useFormikCheckboxInputHelpers(formik, 'isTransportation')

  useEffect(() => {
    resetCountryList()
  }, [resetCountryList])

  return (
    <div className='row'>
      <div className='col-12'>
        <SearchableSelectInput
          id='nationality'
          items={countryList}
          itemMapper={countryMapper}
          label='Nationality'
          placeholder='Select Nationality'
          value={formik.values.nationality}
          onChange={handleNationalityChange('nationality')}
          onBlur={handleNationalityBlur('nationality')}
        />
      </div>
      <div className='col-12'>
        <SearchableSelectInput
          id='residence'
          items={countryList}
          itemMapper={countryMapper}
          label='Country of Residence'
          placeholder='Select Country of Residence'
          value={formik.values.residence}
          onChange={handleNationalityChange('residence')}
          onBlur={handleNationalityBlur('residence')}
        />
      </div>
      <div className='col-12'>
        <DatePickerInput
          label='Date of birth'
          maxDate={TODAY}
          {...dateInputHelpers.getProps('dateOfBirth')}
        />
      </div>
      <div className='col-12'>
        <MobileNumberInput
          label='Mobile number'
          helperMessage='Ex: +971551234567'
          {...stringInputHelpers.getProps('mobileNumber')}
        />
      </div>
      <div className='col-12'>
        <AvatarImageInput
          inputRef={avatarInputRef}
          height={500}
          width={500}
          className='mb-3'
          label='Upload personal photo'
          {...imageInputHelpers.getProps('passportPhoto')}
          onChange={handleAvatarInputChange}
        />
        <div className='d-flex justify-content-end'>
          <Button type='button' className='me-1' variant='danger' onClick={handleResetPhotoClick}>
            Clear
          </Button>
          <Button type='button' variant='primary' onClick={handleUploadPhotoClick}>
            Upload
          </Button>
        </div>
      </div>
      <div className='col-12'>
        <DragDropFileInput
          limit={0}
          label={isAskingForEmiratesId ? 'Emirates ID (Front and Back)' : 'Passport copy'}
          inputRef={passportInputRef}
          {...fileInputHelpers.getProps('passportCopy')}
        />
        <div className='d-flex justify-content-end'>
          <Button
            type='button'
            className='me-1'
            variant='danger'
            onClick={handleResetPassportClick}
          >
            Clear
          </Button>
          <Button type='button' variant='primary' onClick={handleUploadPassportClick}>
            Upload
          </Button>
        </div>
      </div>
      <div className='col-12'>
        <DatePickerInput
          label={isAskingForEmiratesId ? 'Emirates ID expiry date' : 'Passport expiry date'}
          {...dateInputHelpers.getProps('documentExpiry')}
        />
      </div>
      <div className='col-12'>
        <label className='form-label'>Need assistance?</label>
      </div>
      <div className='col-12'>
        <CheckboxInput label='Flights' onChange={isFlight.onChange} checked={isFlight.value} />
      </div>
      <div className='col-12'>
        <CheckboxInput
          label='Hotel Reservation'
          onChange={isHotel.onChange}
          checked={isHotel.value}
        />
      </div>

      <div className='col-12'>
        <CheckboxInput
          label='Transportation'
          onChange={isTransportation.onChange}
          checked={isTransportation.value}
        />
      </div>
    </div>
  )
}

const countryMapper = (item: ISOCountryModel) => ({label: item.name, value: item.code})

const TODAY = new Date()

export const STEP_INFORMATION_KEYS: Array<keyof RegistrationPersonalInformationValues> = [
  'dateOfBirth',
  'mobileNumber',
  'nationality',
  'passportCopy',
  'documentExpiry',
  'passportPhoto',
  'residence',
]
