import {RowAction, RowActionProps} from './RowAction'

export interface DeleteRowActionProps<T>
  extends Omit<RowActionProps<T>, 'iconType' | 'iconName' | 'variant' | 'tooltip'> {}

export const DeleteRowAction = <T,>(props: DeleteRowActionProps<T>) => {
  return (
    <RowAction iconType='General' iconName='Trash' variant='danger' tooltip='Delete' {...props} />
  )
}
