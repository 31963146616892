import {BaseFileInputValue} from './BaseFileInputValue'

export class FileInputValueCollection<T extends BaseFileInputValue> {
  private files: T[] = []

  public getFirst = () => {
    return this.files[0] || null
  }

  public getFileArray = () => {
    return this.files
  }

  public clear = () => {
    this.files = []
  }

  public add = (file: T) => {
    this.files.push(file)
  }

  public addFileList = (fileList: FileList, fileFactory: (file: File) => T, limit: number = 0) => {
    let files = Object.values(fileList)
    if (limit > 0) {
      files = files.splice(0, limit)
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const inputFile = fileFactory(file)
      this.add(inputFile)
    }
    this.trimFileLimit(limit)
  }

  public hasValue = () => {
    return this.files.length > 0
  }

  public remove = (id: string) => {
    const index = this.files.findIndex((file) => file.id === id)

    if (index >= 0) {
      this.files.splice(index, 1)
    }
  }

  public clone = (): FileInputValueCollection<T> => {
    const clone = new FileInputValueCollection<T>()
    this.files.forEach(clone.add)
    return clone
  }

  private trimFileLimit = (limit: number) => {
    if (limit > 0) {
      const start = this.files.length - limit
      this.files = this.files.splice(start)
    }
  }
}
