import {
  Dispatch,
  SetStateAction,
  useState,
  BaseSyntheticEvent,
  ReactNode,
  useMemo,
  useCallback,
} from 'react'
import {useRef} from 'react'
import {useEffect} from 'react'
import {useDebounce} from 'use-debounce'
// import 'use-debounce/lib/index/debounce';
import {KTSVG} from '../../../_metronic/helpers'
import {TableColumns} from '../../models/TableColumns'
import PaginationHelper, {PaginationHelperProps} from '../extras/PaginationHelper'
import TableColumnDataFormatter from './TableColumnDataFormatter'
import _ from 'lodash'
import {TableWrapperSortableColumnHeader} from './TableWrapperSortableColumnHeader'
import {SortValue} from './SortValue'

export interface TableWrapperProps extends PaginationHelperProps {
  addBtnName?: string
  editBtnName?: string
  addBtnToggle?: string
  isFiltered?: boolean
  showFilter?: boolean
  setShowFilter?: Dispatch<SetStateAction<boolean>> | undefined
  removeAction?: boolean
  className?: string
  columns?: TableColumns[]
  onAdd?: () => void
  data?: any[]
  onChangeSearch?: (keyword: string) => void
  onDeleteRecord?: (ids: string[]) => void
  onEditRecord?: Dispatch<SetStateAction<object | undefined>> | undefined
  onEditButton?: Dispatch<SetStateAction<object | undefined>> | undefined
  disableOnAction?: string
  children?: ReactNode
  onSort?: (sortValue: SortValue) => void
  sortColumn?: [string, boolean]
}

const TableWrapper = ({
  children,
  addBtnName,
  editBtnName,
  addBtnToggle,
  isFiltered,
  className,
  columns,
  data,
  removeAction,
  showFilter,
  setShowFilter,
  currentPageNumber,
  currentPageSize,
  onChangePageNumber,
  onChangePageSize,
  total,
  onChangeSearch,
  onDeleteRecord,
  onEditRecord,
  onEditButton,
  onAdd,
  disableOnAction,
  onSort,
  sortColumn,
}: TableWrapperProps) => {
  const [selected, setSelected] = useState<string[]>([])
  const [search, setSearch] = useState<string>()
  const [searchValue] = useDebounce(search, 1000)
  const selectAllRef = useRef<HTMLInputElement | null>(null)

  const getSortHandler = useCallback(
    (column: string, isAscending?: boolean) => {
      if (onSort) {
        return () => {
          onSort && onSort([column, !isAscending])
        }
      }
    },
    [onSort]
  )

  const isColumnAscending = useCallback(
    (column: string) => {
      if (sortColumn) {
        if (sortColumn[0] === column) {
          return sortColumn[1]
        }
      }
    },
    [sortColumn]
  )

  const toggleFilter = useCallback(() => {
    if (setShowFilter) {
      setShowFilter(!showFilter)
    }
  }, [setShowFilter, showFilter])

  const handleBulkDeleteRecord = useCallback(() => {
    if (onDeleteRecord && selected.length > 0) {
      onDeleteRecord(selected)
    }
  }, [onDeleteRecord, selected])

  const handleDeleteRecord = useCallback(
    (code: string) => {
      if (onDeleteRecord) {
        onDeleteRecord([code])
      }
    },
    [onDeleteRecord]
  )

  const handleEditRecord = useCallback(
    (obj: object) => {
      if (onEditRecord) {
        onEditRecord(obj)
      }
    },
    [onEditRecord]
  )

  const handleSelectItem = useCallback(
    (e: BaseSyntheticEvent) => {
      const selectedIndex = selected.indexOf(e.target.value)
      let newSelected: string[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, e.target.value)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      setSelected(newSelected)
    },
    [selected]
  )

  const handleSelectAll = useCallback(
    (e: BaseSyntheticEvent) => {
      if (e.target.checked) {
        const newSelecteds: string[] = (data as any[]).map((n) =>
          disableOnAction ? disableOnAction !== n.code && n.code : n.code
        )
        setSelected(_.compact(newSelecteds) as string[])
        return
      }
      setSelected([])
    },
    [data, disableOnAction]
  )

  const isSelected = useCallback((code: string) => selected.indexOf(code) >= 0, [selected])

  const tableColumnHeaders = useMemo(() => {
    const columnHeaders = columns?.map((column) => {
      const key = `${column.dataField}${column.text}`
      const isAscending = isColumnAscending(column.dataField)
      return (
        <TableWrapperSortableColumnHeader
          key={key}
          label={column.text}
          isAscending={isAscending}
          onSort={getSortHandler(column.dataField, isAscending)}
          className={column.className}
        />
      )
    })
    return (
      <thead>
        <tr className='fw-bolder text-muted'>
          {!removeAction && (
            <th className='w-25px align-middle'>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  ref={selectAllRef}
                  onChange={handleSelectAll}
                />
              </div>
            </th>
          )}
          {columnHeaders}
          {!removeAction && <th className='max-w-100px text-end text-uppercase'>Actions</th>}
        </tr>
      </thead>
    )
  }, [columns, removeAction, handleSelectAll, isColumnAscending, getSortHandler])

  const body = useMemo(() => {
    if (data && data.length) {
      return data.map((_data, _i) => (
        <tr key={_i}>
          {!removeAction && (
            <td>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className={`form-check-input widget-9-check`}
                  onChange={handleSelectItem}
                  checked={isSelected(_data.code)}
                  disabled={disableOnAction && disableOnAction === _data.code ? true : false}
                  type='checkbox'
                  value={_data.code}
                />
              </div>
            </td>
          )}
          {columns &&
            columns.map((column, idx) => (
              <td key={idx} className={`${column.className}`}>
                {column.sublink ? (
                  <TableColumnDataFormatter
                    column={column.dataField}
                    sublink={column.sublink}
                    columnData={_data}
                    data={_data[column.dataField]}
                  />
                ) : (
                  <TableColumnDataFormatter
                    column={column.dataField}
                    link={column.link}
                    data={_data[column.dataField]}
                  />
                )}
              </td>
            ))}
          {!removeAction && (
            <td className='min-w-100px mw-150px'>
              <div className='d-flex justify-content-end flex-shrink-0'>
                <button
                  className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 ${
                    disableOnAction && disableOnAction === _data.code && 'disabled'
                  }`}
                  onClick={() => handleEditRecord(_data)}
                >
                  <KTSVG
                    path='/media/icons/duotone/Communication/Write.svg'
                    className='svg-icon-3'
                  />
                </button>
                <button
                  className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                    disableOnAction && disableOnAction === _data.code && 'disabled'
                  }`}
                  onClick={() => handleDeleteRecord(_data.code)}
                >
                  <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
                </button>
              </div>
            </td>
          )}
        </tr>
      ))
    }
    return (
      <tr>
        <td colSpan={(columns?.length || 0) + (!removeAction ? 2 : 0)} className='py-10'>
          No records found
        </td>
      </tr>
    )
  }, [
    data,
    disableOnAction,
    columns,
    handleDeleteRecord,
    handleEditRecord,
    handleSelectItem,
    isSelected,
    removeAction,
  ])

  useEffect(() => {
    if (selectAllRef.current) {
      const _checkedAll: HTMLInputElement = selectAllRef.current as HTMLInputElement
      _checkedAll.indeterminate =
        selected.length > 0 &&
        selected.length !== ((total || 0) < (currentPageSize || 0) ? total : currentPageSize)

      if (selected.length === 0) {
        _checkedAll.checked = false
      }

      if (
        total !== 0 &&
        selected.length === ((total || 0) < (currentPageSize || 0) ? total : currentPageSize)
      ) {
        _checkedAll.checked = true
      }
    }
  }, [selected, currentPageSize, total])

  useEffect(() => {
    // reset selected every change page size and total
    setSelected([])
  }, [currentPageSize, currentPageNumber, total])

  useEffect(() => {
    if (onChangeSearch) {
      onChangeSearch(searchValue || '')
    }
  }, [onChangeSearch, searchValue])

  return (
    <>
      <div className={`card card-flush mb-5 ${className || ''}`}>
        {/* begin::Header */}
        <div className='card-header pt-6'>
          <div className='card-title align-items-start flex-column'>
            <input
              type='text'
              className='form-control form-control-solid min-w-275px'
              placeholder='Search here'
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className='card-toolbar'>
            {!removeAction && addBtnName && (
              <button
                type='button'
                onClick={onAdd}
                className='btn btn-light-primary'
                id={addBtnToggle}
              >
                <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' />
                {addBtnName}
              </button>
            )}
            {onEditButton && editBtnName && (
              <button type='button' className='btn btn-light-warning' onClick={onEditButton}>
                <KTSVG path='/media/icons/duotone/Design/Edit.svg' />
                {editBtnName}
              </button>
            )}
            {isFiltered && (
              <button className='btn btn-light-primary ms-3' onClick={toggleFilter}>
                <KTSVG path='/media/icons/duotone/Text/Filter.svg' className='me-0' />
              </button>
            )}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body'>
          {children}
          {selected.length > 0 && (
            <div className='row g-5 mb-5'>
              <span>
                Selected records count: <b>{selected.length}</b>
              </span>
              <div className='d-flex flex-wrap'>
                <button className='btn btn-light-danger' onClick={handleBulkDeleteRecord}>
                  Delete All
                </button>
              </div>
            </div>
          )}
          <div className='row g-5'>
            <div className='col'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  {tableColumnHeaders}
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>{body}</tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
          </div>
        </div>
        {/* begin::Body */}
      </div>
      {total ? (
        <PaginationHelper
          currentPageNumber={currentPageNumber}
          currentPageSize={currentPageSize}
          onChangePageNumber={onChangePageNumber}
          onChangePageSize={onChangePageSize}
          total={total}
        />
      ) : null}
    </>
  )
}

export default TableWrapper
