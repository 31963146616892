import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {ButtonVariant} from '../../../components/inputs/Button'
import {MetronicIconPath} from '../../../components/inputs/MetronicIcon'
import {TileButton} from '../../../components/inputs/TileButton'
import {OutletOrderType} from '../../../models/fnb/OutletModel'

export interface OutletOrderTypeButtonProps {
  orderType: OutletOrderType
  onClick?: (orderType: OutletOrderType) => void
  className?: string
  availableOrderTypes?: OutletOrderType[]
  variant?: ButtonVariant
}

export const OutletOrderTypeButton = ({
  className,
  orderType,
  onClick,
  availableOrderTypes,
  variant,
}: OutletOrderTypeButtonProps) => {
  const handleClick = useCallback(() => {
    onClick && onClick(orderType)
  }, [onClick, orderType])

  const isActive = useMemo(() => {
    if (!availableOrderTypes) {
      return true
    }
    return availableOrderTypes.includes(orderType)
  }, [orderType, availableOrderTypes])

  const icon = useMemo(() => {
    return getIconType(orderType)
  }, [orderType])

  return (
    <TileButton
      className={clsx('outlet-order-type-button', className)}
      disabled={!isActive}
      onClick={handleClick}
      variant={variant}
      {...icon}
    >
      {orderType}
    </TileButton>
  )
}

export const getIconType = (orderType: OutletOrderType): MetronicIconPath => {
  switch (orderType) {
    case 'delivery':
      return {iconType: 'Navigation', iconName: 'Route'}
    case 'dine-in':
      return {iconType: 'Cooking', iconName: 'Fork-spoon'}
    case 'pickup':
      return {iconType: 'Communication', iconName: 'Outgoing-box'}
    case 'takeaway':
      return {iconType: 'Shopping', iconName: 'Bag1'}
    default:
      return {
        iconType: 'Shopping',
        iconName: 'Box2',
      }
  }
}

export const getTooltip = (orderType: OutletOrderType) => {
  switch (orderType) {
    case 'delivery':
      return 'Delivery'
    case 'dine-in':
      return 'Dine In'
    case 'pickup':
      return 'Pickup'
    case 'takeaway':
      return 'Takeaway'
    default:
      return orderType
  }
}
