import {DetailedHTMLProps, LabelHTMLAttributes} from 'react'
import clsx from 'clsx'

type LabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>

export interface InputLabelProps extends LabelProps {}

export const InputLabel = ({children, className, ...props}: LabelProps) => {
  return (
    <label className={clsx('form-label', className)} {...props}>
      {children}
    </label>
  )
}
