import {BaseCsvMapModalProps, CsvMapModal} from '../../../../components/forms/CsvMapModal'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'

export type CustomerMappingTableModalProps = BaseCsvMapModalProps

export const CustomerMappingTableModal = ({
  onClose,
  onSubmit,
  open,
}: CustomerMappingTableModalProps) => {
  return (
    <CsvMapModal
      onSubmit={onSubmit}
      mappableKeys={MAPPABLE_KEYS}
      onClose={onClose}
      open={open}
      className='bg-primary rounded-0 rs-table-bg'
    />
  )
}
const MAPPABLE_KEYS: SelectInputItem[] = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Mobile',
    value: 'mobile',
  },
]
