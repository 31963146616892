import {useCallback, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import clsx from 'clsx'
import styles from './ViewAttendeePage.module.scss'
import {CustomerModel} from '../../../../models/CustomerModel'
import {DraftJsRenderer} from '../../../../components/utils/DraftJsRenderer'
import {CustomerImage} from '../../components/CustomerImage/CustomerImage'
import {GetCustomerByCode} from '../../redux/EvaCRUD'
import {useOnMount} from '../../../../components/hooks/useOnMount'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {Badge} from '../../../../components/badge/Badge'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'

export const ViewAttendeePage = () => {
  const [customer, setCustomer] = useState<CustomerModel>()
  const {userCode} = useParams<{userCode?: string}>()
  const {pushError} = useAlerts()
  const history = useHistory()

  const currentUser = useRootStateSelector((state) => state.eva?.user)

  const refreshCustomerData = useCallback(async () => {
    if (userCode) {
      try {
        const {data} = await GetCustomerByCode(userCode)
        setCustomer(data)
      } catch (e) {
        pushError(e)
      }
    }
  }, [userCode, pushError])

  const goToUserChat = useCallback(() => {
    history.push({
      pathname: `/chat/${userCode}`,
      state: {from: `/attendees/${userCode}`, selectedUser: customer},
    })
  }, [customer, history, userCode])

  useOnMount(() => {
    refreshCustomerData()
  })

  return (
    <>
      <div className={clsx('p-5 mb-5', styles.header)}>
        <div className='d-flex align-items-start mb-5'>
          <CustomerImage className='me-3' customer={customer} />
          <div className='d-flex flex-column align-items-start'>
            <span className='fs-1'>{customer?.name}</span>
            <Badge variant='dark' className='text-uppercase'>
              {customer?.customerCategory?.name}
            </Badge>
          </div>
          {currentUser && currentUser.data.code !== userCode && (
            <div className='flex-grow-1 text-end align-self-start mt-4'>
              <MetronicIconButton
                classes={{
                  icon: 'svg-icon-primary',
                }}
                size='md'
                variant='text'
                iconType='Communication'
                iconName='Group-chat'
                onClick={goToUserChat}
              />
            </div>
          )}
        </div>
        <div>
          {customer?.customerOrganization && (
            <p>
              <span className='rounded fw-bold text-underline'>Organization: </span>
              {customer?.customerOrganization}
            </p>
          )}
          {customer?.designation && (
            <p>
              <span className='rounded fw-bold text-underline'>Designation: </span>
              {customer?.designation}
            </p>
          )}
        </div>
      </div>
      <div className='px-5 pb-5'>
        <DraftJsRenderer>{customer?.bio}</DraftJsRenderer>
      </div>
    </>
  )
}
