import {useCallback, useMemo} from 'react'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {MobileNumberInput} from '../../../components/inputs/MobileNumberInput'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {DateUtil} from '../../../utils/DateUtil'
import {Button} from './Button'
import {ButtonList, ButtonListItem} from './ButtonList'

export interface OrderListProps {
  mobileNumber: string
  onMobileNumberChange: (value: string) => void
  onMobileNumberSearch: () => void
  onOrderClick: (value: string) => void
  orders: OrderModel[] | null
  className?: string
}

export const OrderList = ({
  mobileNumber,
  onMobileNumberChange,
  onMobileNumberSearch,
  onOrderClick,
  orders,
  className,
}: OrderListProps) => {
  const items = useMemo(() => {
    return orders?.map<ButtonListItem>((order) => ({
      value: order.code,
      label: `Order #${order.code} ${DateUtil.formatToDateTimeString(order.createdAt)}`,
      light: true,
      border: true,
      variant: order.status === 'draft' ? 'warning' : 'success',
    }))
  }, [orders])

  const handleMobileNumberChange = useCallback(
    (mobileNumber: string) => {
      onMobileNumberChange(mobileNumber)
    },
    [onMobileNumberChange]
  )

  const orderList = useMemo(() => {
    if (items !== undefined) {
      if (items.length) {
        return <ButtonList items={items} onClick={onOrderClick} />
      }
      return <div className='border text-center p-10 fs-1'>No orders made using this number.</div>
    }
  }, [items, onOrderClick])

  return (
    <div className={className}>
      <div>
        <div>
          <div className='d-flex'>
            <MobileNumberInput
              noMargin
              className='flex-grow-1'
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              label={
                <div>
                  <p className='m-0'>
                    <MetronicIcon iconType='Devices' iconName='Phone' />
                    Mobile Number
                  </p>
                  <p className='m-0 text-muted fw-light'>
                    Enter your mobile number to search previous orders.
                  </p>
                </div>
              }
              placeholder='Enter your mobile number.'
              inputClassName='rounded-0'
            />
            <Button
              className='align-self-end'
              variant='success'
              border
              light
              onClick={onMobileNumberSearch}
              type='button'
            >
              Search
            </Button>
          </div>
        </div>
        <div className='mt-8'>
          <p className='fw-bolder'>
            <MetronicIcon iconType='Text' iconName='Bullet-list' />
            Orders List
          </p>
          {orderList}
        </div>
      </div>
    </div>
  )
}
