import {useCallback, useEffect, useState} from 'react'
import {useOutletAuth} from '../../../components/hooks/useOutletAuth'
import {OutletModel} from '../../../models/fnb/OutletModel'
import {GetCurrentOutlet} from '../redux/OutletCRUD'

export const useCurrentOutlet = () => {
  const auth = useOutletAuth()
  const [outlet, setOutlet] = useState<OutletModel>()

  const resetOutletData = useCallback(async () => {
    try {
      const {data} = await GetCurrentOutlet()
      setOutlet(data)
    } catch (e) {
      // Silently fail.
    }
  }, [])

  useEffect(() => {
    if (auth) {
      resetOutletData()
    }
  }, [auth, resetOutletData])

  return outlet
}
