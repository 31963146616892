import {DetailedHTMLProps, TdHTMLAttributes} from 'react'
import type {BaseTableColumnOptions} from './TableColumn'

export const useColumnStyle = (
  style: BaseTableColumnOptions<unknown>['cellStyle'] = {}
): ColumnStyle => {
  const {noWrap, minWidth, maxWidth} = style

  return {
    minWidth: minWidth && `${minWidth}rem`,
    maxWidth: maxWidth && `${maxWidth}rem`,
    whiteSpace: noWrap ? 'nowrap' : undefined,
  }
}

type ColumnStyle = DetailedHTMLProps<
  TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
>['style']
