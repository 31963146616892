import {TopicModel} from '../../../models/eva/TopicModel'
import {Fragment, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import NavigationCell from './NavigationCell/NavigationCell'

export interface TopicProps {
  topics?: TopicModel[]
}

export const TopicList = ({topics}: TopicProps) => {
  const history = useHistory()
  const goToTopic = useCallback(
    (topic: TopicModel) => {
      history.push(`/topics/${topic.code}`, {
        from: `/topics/${topic.code}`,
      })
    },
    [history]
  )
  return (
    <>
      {topics?.map((topic, index, array) => {
        const isLast = index === array.length - 1
        return (
          <Fragment key={topic.code}>
            <NavigationCell
              goTo={goToTopic}
              data={topic}
              title={topic.title}
              location={topic.location?.name}
              isDate={true}
            />
            {!isLast && <HorizontalDivider spacing={5} />}
          </Fragment>
        )
      })}
    </>
  )
}
