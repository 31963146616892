import {useCallback, useMemo, useState} from 'react'
import {getUrlData} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {useQueryParams} from '../../../components/hooks/useQueryParams'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {DigitalMenuLocationModel} from '../../../models/digital-menu/DigitalMenuLocationModel'
import {FnbQrCodeGenerator} from '../../../utils/Qr/FnbQrGenerator'
import {QrCodeDataParser} from '../../../utils/Qr/QrCodeDataParser'
import {ButtonListItem} from '../components/ButtonList'
import {LocationListSelect} from '../components/LocationSelection/LocationListSelect'
import {ScanOrTypeQr} from '../components/ScanOrTypeQr'
import {Tabs} from '../components/Tabs/Tabs'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {ConfirmPageLayout} from '../layout/ConfirmPageLayout'
import {GetDigitalMenuLocations} from '../redux/DigitalMenuCRUD'

export const LocationSelectPage = () => {
  const query = useQueryParams()
  const [tab, setTab] = useState(query.get('tab') || 'qr')
  const [locations, setLocations] = useState<DigitalMenuLocationModel[]>([])
  const {goToOutlets, goToMainMenu, goToFnbQrCodeData} = usePageNavigation()
  const {push, pushError} = useAlerts()

  const handleTicketScan = useCallback(
    (value: string) => {
      try {
        const qrcode = new QrCodeDataParser(value)
        goToOutlets({ticketCode: qrcode.getTicketCode()})
      } catch (e) {
        try {
          const qrCode = FnbQrCodeGenerator.fromQrCode(value)
          if (qrCode) {
            goToFnbQrCodeData(qrCode)
          }
        } catch (e) {
          push({
            message: 'Invalid code',
            timeout: 5000,
            variant: 'danger',
          })
        }
      }
    },
    [goToFnbQrCodeData, goToOutlets, push]
  )

  const handleSelectLocation = useCallback(
    (locationCode: string) => {
      goToOutlets({locationCode: locationCode})
    },
    [goToOutlets]
  )

  const locationList = useMemo(() => {
    return locations.map<ButtonListItem>((location) => ({
      label: location.name,
      value: location.code,
      border: true,
      light: true,
    }))
  }, [locations])

  const inputNode = useMemo(() => {
    if (tab === 'qr') {
      return (
        <div>
          <h2 className='text-uppercase fs-1 fw-bolder'>Scan QR Code</h2>
          <p>Scan the QR code on your ticket to view available outlets in your location.</p>
          <ScanOrTypeQr placeholder='Please type location code' onSubmit={handleTicketScan} />
        </div>
      )
    }
    return (
      <>
        <h2 className='text-uppercase fs-1 fw-bolder'>Select Location</h2>
        <div className='d-flex flex-column flex-grow-1 overflow-auto h-100'>
          <LocationListSelect locations={locationList} onClick={handleSelectLocation} />
        </div>
      </>
    )
  }, [handleSelectLocation, handleTicketScan, locationList, tab])

  const resetLocationList = useCallback(async () => {
    const {eventCode} = getUrlData()
    if (eventCode) {
      try {
        const {data} = await GetDigitalMenuLocations(eventCode)
        setLocations(data)
      } catch (e) {
        pushError(e)
      }
    }
  }, [pushError])

  useOnMount(() => {
    resetLocationList()
  })

  return (
    <ConfirmPageLayout headerLabel='Share Location' onBackClick={goToMainMenu}>
      <Tabs
        className='pb-5 flex-shrink-0'
        onClick={setTab}
        value={tab}
        tabs={LOCATION_SELECT_TABS}
      />
      {inputNode}
    </ConfirmPageLayout>
  )
}

const LOCATION_SELECT_TABS: SelectInputItem[] = [
  {
    label: 'Scan QR Code',
    value: 'qr',
  },
  {
    label: 'Select Location',
    value: 'list',
  },
]
