import {useMemo} from 'react'
import clsx from 'clsx'
import {ProductCategoryWidgetModel} from '../../../../models/customer-portal/WidgetModel'

interface ProductCategoryDetailsProps {
  items: ProductCategoryWidgetModel[]
}

export const ProductCategoryDetails = ({items}: ProductCategoryDetailsProps) => {
  const totalQtys = useMemo(() => {
    return items.map((item) => item.products.reduce((sum, product) => sum + product.qty, 0))
  }, [items])

  return (
    <div className='container-fluid px-0 mx-0 h-100'>
      <div className='row mx-0 px-0 h-100'>
        <div className='col-12' style={{height: 'calc(15% - 13px)'}}>
          {/* <h1 className='text-start pt-10 px-3 text-active'></h1> */}
          <div className='text-start ff-f1-bold text-white fs-4 pt-10 ps-10'>Ticket Inclusions</div>
        </div>

        <div
          className='col-12 px-0'
          style={{
            height: 'calc(100% - 1px)',
            maxHeight: 'calc(100% - 125px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {items.map((item, idx) => (
            <div key={idx} className='row'>
              <div
                className={clsx(
                  'col-8 px-10 text-center border border-gray rounded-0 border-start-0',
                  idx === items.length - 1 ? 'border-bottom-1' : 'border-bottom-0'
                )}
              >
                <div className='h-100 d-flex flex-column justify-content-center px-6'>
                  <div className='text-white ff-admm-bold mb-0 d-flex flex-wrap gap-1 justify-content-start text-uppercase'>
                    <div className='fs-3 mb-0 d-flex flex-column justify-content-start'>
                      {item.name}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={clsx(
                  'col-4 px-0 text-center border border-gray rounded-0 border-start-0',
                  idx === items.length - 1 ? 'border-bottom-1' : 'border-bottom-0'
                )}
              >
                <div className='h-100 d-flex flex-column justify-content-center px-2'>
                  <div className='d-flex flex-column justify-content-center my-5'>
                    <div className='fs-7 text-white ff-admm-bold text-uppercase '>Distributed</div>
                    <div
                      className='text-white ff-admm-bold mb-0'
                      style={{
                        fontSize: '22px',
                        lineHeight: '30px',
                      }}
                    >
                      {totalQtys[idx]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {!items.length && (
            <div className='d-flex flex-column justify-content-center my-5'>
              <div className='text-center ff-f1-bold text-white fs-5 mt-2 px-3'>Distributed</div>
              <div
                className='text-center text-white ff-f1-bold mb-0 mt-12'
                style={{
                  fontSize: '69px',
                  lineHeight: '30px',
                }}
              >
                0
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
