import {ChangeEvent, CSSProperties, useCallback, useEffect, useState} from 'react'

export interface TableSelectionCheckboxProps {
  checked: boolean | null
  onChange?: (checked: boolean) => void
  disabled?: boolean
  style?: CSSProperties
}

export const TableSelectioncheckbox = ({
  checked,
  onChange,
  disabled,
  style,
}: TableSelectionCheckboxProps) => {
  const [inputElement, setInputElRef] = useState<HTMLInputElement | null>(null)

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.checked)
    },
    [onChange]
  )

  useEffect(() => {
    if (inputElement) {
      if (checked === null) {
        inputElement.indeterminate = true
      } else {
        inputElement.indeterminate = false
      }
    }
  }, [checked, inputElement])

  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid mx-4'>
      <input
        ref={setInputElRef}
        disabled={disabled}
        className='form-check-input'
        type='checkbox'
        onChange={handleOnChange}
        checked={Boolean(checked)}
        style={style}
      />
    </div>
  )
}
