import {ReactNode, useMemo, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {SelectInputItem} from '../SelectInput'
import {SearchableSelectInput, SearchableSelectInputClasses} from './SearchableSelectInput'
import {useSearchableInputFilter} from './useSearchableInputFilter'

export interface FilterSearchableSelectInputProps<T> {
  onFilter: (filter: FilterModel) => void
  searchResult?: GlobalSearchModel<T> | null
  filterMixins?: FilterModel
  className?: string
  value: T | null
  onChange: (value: T | null) => void
  placeholder?: string
  onBlur?: () => void
  label?: string
  delay?: number
  disabled?: boolean
  searchKey?: string
  itemMapper: (item: T) => SelectInputItem
  allowClear?: boolean
  classes?: SearchableSelectInputClasses<T>
  noMargin?: boolean
  renderItem?: (item: T) => ReactNode
  displayedValue?: ReactNode
}

export const FilterSearchableSelectInput = <T,>({
  onFilter,
  filterMixins,
  delay = 200,
  searchResult,
  itemMapper,
  searchKey,
  value,
  ...inputProps
}: FilterSearchableSelectInputProps<T>) => {
  const id = useRef(uuidv4()).current

  const {onScrollBottom, onSearch} = useSearchableInputFilter({
    searchKey,
    filterMixins,
    onFilter,
    delay,
    searchResult,
  })

  const items = useMemo((): T[] => {
    if (searchResult) {
      let valueExists = false
      const items: T[] = []
      if (searchResult.data && searchResult.data.length) {
        for (let i = 0; i < searchResult.data.length; i++) {
          const item = searchResult.data[i]
          items.push(item)

          if (value) {
            const {value: valueA} = itemMapper(item)
            const {value: valueB} = itemMapper(value)
            if (valueA === valueB) {
              valueExists = true
            }
          }
        }
        if (!valueExists && value) {
          items.push(value)
        }
        return items
      }
    }
    if (value) {
      return [value]
    }
    return []
  }, [itemMapper, searchResult, value])

  return (
    <SearchableSelectInput
      onScrollBottom={onScrollBottom}
      onSearch={onSearch}
      items={items}
      id={id}
      value={value}
      filter={NO_FILTER}
      itemMapper={itemMapper}
      {...inputProps}
    />
  )
}

const NO_FILTER = () => true
