import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {concat} from 'lodash'
import {Button} from '../../inputs/Button'
import {useOnChange} from '../../hooks/useOnChange'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {map} from 'lodash'
import {
  ProductVoucherBulkTable,
  columns,
} from '../../BookingWizard/BookingWizardTables/bulkBookingTable/ProductVoucherBulkTable'
import {
  BookingWizardBulkProductVenueStepFormValues,
  BulkConsolidatedFormValues,
  ProductVoucherBulkModel,
} from '../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {ProductVoucherModalType} from '../../../models/booking-wizard/BookingWizard'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {FilterModel} from '../../../models/FilterModel'
import {GetBundleProducts} from '../../../modules/default/ems/redux/EmsCRUD'
import {useAlerts} from '../../alerts/useAlerts'
import {CustomerModel} from '../../../models/CustomerModel'
import {CustomerCreateBookingHeader} from '../../../modules/default/ems/components/CustomerCreateBookingHeader'
import {useDebounce} from '../../hooks/useDebounce'
import {EditBulkProductVoucherModal} from '../../BookingWizard/component/bulkConsolidated/EditBulkProductVoucherModal'
import {AddBulkProductVoucher} from '../../BookingWizard/component/bulkConsolidated/AddBulkProductVoucher'

export interface BookingWizardSharedConsolidatedProductVenueStepProps<
  T extends BookingWizardBulkProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  bookingBulkForm?: BulkConsolidatedFormValues
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  productSearchResults?: GlobalSearchModel<ProductModel>
  searchVouchers: (filter?: FilterModel) => Promise<void>
  refreshProductsList: (filter?: FilterModel) => Promise<void>
  isPortal?: boolean
}

export const BookingWizardSharedConsolidatedProductVenueStep = <
  T extends BookingWizardBulkProductVenueStepFormValues
>({
  formik,
  bookingBulkForm,
  productSearchResults,
  refreshProductsList,
  searchVouchers,
  voucherSearchResults,
  isPortal,
}: BookingWizardSharedConsolidatedProductVenueStepProps<T>) => {
  const {pushError} = useAlerts()
  const [modalType, setModalType] = useState<ProductVoucherModalType>()
  const [currentSelectedProductVoucher, setCurrentSelectedProductVoucher] =
    useState<ProductVoucherBulkModel>()
  const [productsSelectedValues, setProductsSelectedValues] = useState<ProductVoucherBulkModel>()
  const [vouchersSelectedValues, setVouchersSelectedValues] = useState<ProductVoucherBulkModel>()
  const [productsSelectedItems, setProductsSelectedItems] = useState<ProductVoucherBulkModel[]>([])
  const debounce = useDebounce(250)

  const isAllProductsSelected = useMemo(() => {
    if (formik.values.vouchers && formik.values.products?.length) {
      let productList = formik.values.products
      return productSearchResults
        ? productSearchResults.data.every((item) => {
            const isSelected = productList.some((value) => value?.code === item.code)
            if (isSelected) {
              return true
            }
            return false
          })
        : false
    }
    return false
  }, [formik, productSearchResults])

  const isAllVouchersSelected = useMemo(() => {
    if (formik.values.vouchers && formik.values.vouchers?.length) {
      let voucherList = formik.values.vouchers
      return voucherSearchResults?.data.every((item) => {
        const isSelected = voucherList.some((value) => value?.code === item.code)
        if (isSelected) {
          return true
        }
        return false
      })
    }
    return false
  }, [formik, voucherSearchResults?.data])

  const tableData = useMemo(() => {
    if (formik.values.products) setProductsSelectedItems(formik.values.products)
    return (
      formik.values.product ||
      formik.values.voucher ||
      formik.values.products ||
      formik.values.vouchers
        ? concat(
            formik.values.product,
            formik.values.voucher,
            formik.values.products,
            formik.values.vouchers
          ).filter((item) => item !== null)
        : null
    ) as ProductVoucherBulkModel[] | null
  }, [formik])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: tableData,
    columns: tableData ? (map(columns, 'field') as Array<keyof ProductVoucherBulkModel>) : null,
  })

  useEffect(() => {
    searchVouchers()
    refreshProductsList()
  }, [refreshProductsList, searchVouchers])

  const populateBundleProduct = useCallback(
    async (newValues: ProductVoucherBulkModel[]) => {
      try {
        let productVAL = [...newValues]
        for (let i = 0; i < productVAL.length; i++) {
          if (productVAL[i].type && productVAL[i].type === 'bundle') {
            const {data} = await GetBundleProducts(productVAL[i].code)
            productVAL[i].bundleProducts = data.data
          }
        }
        formik.setFieldValue('products', productVAL)
      } catch (e: any) {
        pushError(e)
        formik.setFieldValue('products', newValues)
      }
    },
    [formik, pushError]
  )

  const handleCloseModal = useCallback(() => {
    if (modalType === 'product') refreshProductsList()
    if (modalType === 'voucher') searchVouchers()
    setModalType(undefined)
    setCurrentSelectedProductVoucher(undefined)
    if (productsSelectedValues) {
      setProductsSelectedValues(productsSelectedValues)
    }
    if (vouchersSelectedValues) {
      setVouchersSelectedValues(vouchersSelectedValues)
    }
  }, [
    modalType,
    productsSelectedValues,
    refreshProductsList,
    searchVouchers,
    vouchersSelectedValues,
  ])

  const handleAddProductOrVoucher = useCallback(
    (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => {
      let productsValues = formik.values.products
      let vouchersValues = formik.values.vouchers
      setTimeout(async () => {
        if (type === 'product') {
          productsValues?.push(value)
          formik.setFieldValue('products', productsValues)
          if (value.type === 'bundle') {
            await populateBundleProduct(productsValues as ProductVoucherBulkModel[])
          }
        }
        if (type === 'voucher') {
          vouchersValues?.push(value)
          formik.setFieldValue('vouchers', vouchersValues)
        }
      }, 200)
      handleCloseModal()
    },
    [formik.values.products, formik.values.vouchers, handleCloseModal, populateBundleProduct]
  )

  const handleOnEditProductVoucher = useCallback(
    async (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => {
      let newProductsValues = formik.values.products
      let newVoucherValues = formik.values.vouchers
      let index = -1
      if (type === 'product') {
        const found = newProductsValues?.find((item, idx) => {
          if (item?.code === value.code) {
            index = idx
            return item
          }
          return null
        })
        if (found && newProductsValues?.length) {
          newProductsValues[index] = {
            ...found,
            qty: value.qty,
          }
          formik.setFieldValue('products', newProductsValues)
          if (value.type === 'bundle') {
            await populateBundleProduct(newProductsValues)
          }
          handleCloseModal()
        }
      }

      if (type === 'voucher') {
        const found = newVoucherValues?.find((item, idx) => {
          if (item?.code === value.code) {
            index = idx
            return item
          }
          return null
        })
        if (found && newVoucherValues?.length) {
          newVoucherValues[index] = {
            ...found,
            qty: value.qty,
          }
          formik.setFieldValue('vouchers', newVoucherValues)
          handleCloseModal()
        }
      }
    },
    [formik, handleCloseModal]
  )

  const handleDeleteProductsVouchers = useCallback(
    (codes: string[]) => {
      let formikProductsValues = formik.values.products
      let formikVouchersValues = formik.values.vouchers
      let formikProductValues = formik.values.product
      let formikVoucherValues = formik.values.voucher
      for (let i = 0; i < codes.length; i++) {
        const foundProduct = formikProductValues?.code === codes[i]
        const foundvoucher = formikVoucherValues?.code === codes[i]
        const removeProduct = formikProductsValues?.find((item) => item?.code === codes[i])
        const removeVoucher = formikVouchersValues?.find((item) => item?.code === codes[i])
        if (foundProduct || removeProduct) {
          formikProductsValues = formikProductsValues?.filter(
            (item) => item.code !== removeProduct?.code
          )
          formik.setFieldValue('products', formikProductsValues)
          setProductsSelectedValues(undefined)
        } else if (foundvoucher || removeVoucher) {
          formikVouchersValues = formikVouchersValues?.filter(
            (item) => item.code !== removeVoucher?.code
          )
          formik.setFieldValue('vouchers', formikVouchersValues)
          setVouchersSelectedValues(undefined)
        }
      }
    },
    [formik]
  )

  const handleEditProductsVouchers = useCallback(
    (data: ProductVoucherBulkModel) => {
      if (productSearchResults && productSearchResults.data?.length) {
        const p = productSearchResults.data.find((item) => item.code === data.code)
        if (p) {
          data.isTimeslot = p.isTimeslot
          data.startedAt = p.productCategory?.startedAt || ''
          data.endedAt = p.productCategory?.endedAt || ''
          debounce(() => setModalType('product'))
        }
      }
      if (voucherSearchResults && voucherSearchResults.data?.length) {
        const v = voucherSearchResults.data.find((item) => item.code === data.code)
        if (v) setModalType('voucher')
      }
      setCurrentSelectedProductVoucher(data)
    },
    [productSearchResults, voucherSearchResults, debounce]
  )

  useOnChange(bookingBulkForm, () => {
    if (bookingBulkForm) {
      setTimeout(() => {
        if (bookingBulkForm.product) {
          setProductsSelectedValues(bookingBulkForm.product)
          formik.setFieldValue('product', bookingBulkForm.product)
        }
        if (bookingBulkForm.voucher) {
          setVouchersSelectedValues(bookingBulkForm.voucher)
          formik.setFieldValue('voucher', bookingBulkForm.voucher)
        }
      }, 200)
    }
  })

  const selectedCustomer = useMemo(() => {
    if (formik.values.bookingResults?.length) {
      let bookingCustomers = [...formik.values.bookingResults]
      const hasSelectCustomer = bookingCustomers.find(
        (c) => c.isSelected === true && !c.isFulfill && !c.isSkip
      )
      if (hasSelectCustomer) {
        return hasSelectCustomer.customer
      }
    }
  }, [formik]) as CustomerModel

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <CustomerCreateBookingHeader customer={selectedCustomer} isPortal={isPortal} />
        </div>
        {/* <div className='d-flex justify-content-end gap-3 px-11'>
          <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('product')
            }}
            disabled={isAllProductsSelected}
          >
            {productSearchResults?.data.length === 0 || (formik.values.product ? true : false)
              ? 'No Products available'
              : 'Add products'}
          </Button>
        </div> */}
        <div>
          <ProductVoucherBulkTable
            onDelete={handleDeleteProductsVouchers}
            onEdit={handleEditProductsVouchers}
            data={searchableLocalTableData}
            onFilter={filterSearchableLocalTableData}
            rightToolbar={
              <Button
                className='btn btn-primary px-10 me-1'
                onClick={() => {
                  setModalType('product')
                }}
                disabled={isAllProductsSelected}
              >
                {productSearchResults?.data.length === 0 || (formik.values.product ? true : false)
                  ? 'No Products available'
                  : 'Add products'}
              </Button>
            }
          />
        </div>
      </div>
      {currentSelectedProductVoucher ? (
        <EditBulkProductVoucherModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          data={currentSelectedProductVoucher}
          onEdit={handleOnEditProductVoucher}
          isPortal={isPortal}
          bookingBulkForm={bookingBulkForm}
          selectedItems={productsSelectedItems}
        />
      ) : (
        <AddBulkProductVoucher
          modalType={modalType}
          onModalClose={handleCloseModal}
          onAdd={handleAddProductOrVoucher}
          productsValues={productsSelectedValues || null}
          productSearchResults={productSearchResults}
          refreshProductsList={refreshProductsList}
          voucherSearchResults={voucherSearchResults}
          vouchersValues={vouchersSelectedValues || null}
          searchVouchers={searchVouchers}
          isPortal={isPortal}
          selectedItems={productsSelectedItems}
          bookingBulkForm={bookingBulkForm}
        />
      )}
    </div>
  )
}
