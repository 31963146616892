import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {FilterModel} from '../../../models/FilterModel'
import {actions} from '../redux/DigitalWaiverRedux'
import {DigitalWaiverCardGrid} from '../components/DigitalWaiverCard/DigitalWaiverCardGrid'

import {useOnChange} from '../../../components/hooks/useOnChange'

export type WaiverStatusType = 'current' | 'previous'
interface PortalTicketPageProps {
  tabCode?: string
  waiverStatus?: WaiverStatusType
  className?: string
}

export const DigitalWaiverTicketPage = ({
  tabCode,
  waiverStatus,
  className,
}: PortalTicketPageProps) => {
  const {setFilter: setFilterTickets, filters: ticketFilters} =
    useEntityFilter('digital-waiver-ticket')
  const dispatch = useDispatch()

  const refreshDebounced = useDebounce(500)
  const tickets = useRootStateSelector((state) => state.digitalWaiver.tickets)
  const expiredTickets = useRootStateSelector((state) => state.digitalWaiver.expiredTickets)
  const ticketFilterMemo = useMemo(() => ticketFilters || {}, [ticketFilters])

  const refresh = useCallback(() => {
    if (waiverStatus === 'current') return dispatch(actions.tickets.search())
    if (waiverStatus === 'previous') return dispatch(actions.expiredTickets.search())
  }, [dispatch, waiverStatus])

  const handleCardsRefresh = useCallback(() => {
    refreshDebounced(() => refresh())
  }, [refreshDebounced, refresh])

  const onFilterTicketHandler = useCallback(
    (filter: FilterModel) => {
      setFilterTickets({
        ...ticketFilters,
        ...filter,
        filters: {
          ...filter?.filters,
        },
      })
      refreshDebounced(() => refresh())
    },
    [setFilterTickets, ticketFilters, refreshDebounced, refresh]
  )

  const initialFilters = useMemo<FilterModel>(() => {
    return {
      filters: {
        delegated:
          waiverStatus === 'current'
            ? tabCode === 'available'
              ? false
              : tabCode === 'shared'
              ? true
              : undefined
            : undefined,
      },
      sortOrder: 'ASC',
      limit: 10,
    }
  }, [tabCode, waiverStatus])

  useOnChange(tabCode, () => {
    setFilterTickets(initialFilters)
    refreshDebounced(() => {
      refreshDebounced(() => refresh())
    })
  })

  useOnChange(waiverStatus, () => {
    setFilterTickets(initialFilters)
    refreshDebounced(() => {
      refreshDebounced(() => refresh())
    })
  })

  return (
    <DigitalWaiverCardGrid
      onRefresh={handleCardsRefresh}
      data={waiverStatus === 'current' ? tickets : expiredTickets}
      filters={ticketFilterMemo}
      onFilter={onFilterTicketHandler}
      tabCode={tabCode}
      waiverStatus={waiverStatus}
      initialFilters={initialFilters}
      className={className}
    />
  )
}

export interface SelectGroupByButtons extends SelectInputItem {
  icon?: string
}
