import {useEffect, useRef} from 'react'

export interface UseClickAwayListenerOptions {
  onClickAway: (e: MouseEvent | TouchEvent) => void
}

export const useClickAwayListener = <T extends HTMLElement>({
  onClickAway,
}: UseClickAwayListenerOptions) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      onClickAway(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, onClickAway])

  return ref
}
