import {useMemo} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {ColorVariant} from '../../../../components/inputs/Button'
import {ProductTicketType} from '../../../../models/ems/ProductModel'

export interface PortalTicketProductTypeProps {
  data: {
    productType?: ProductTicketType
  }
}

export const PortalTicketProductType = ({data}: PortalTicketProductTypeProps) => {
  const variant = useMemo(() => {
    return getStatusVariant(data.productType)
  }, [data])

  return (
    <Badge uppercase variant={variant}>
      {data.productType}
    </Badge>
  )
}

const getStatusVariant = (productType?: ProductTicketType): ColorVariant => {
  switch (productType) {
    case 'e-ticket':
      return 'primary'
    case 'card':
      return 'warning'
    default:
      return 'dark'
  }
}
