import {useMemo} from 'react'
import {Button} from '../Button'
import {UserListInputItem, UserListInputItemValue} from './UserListInputItem'

export interface UserListInputProps {
  items: UserListInputItemValue[]
  onClick: (user: UserListInputItemValue) => void
  onBack?: () => void
}

export const UserListInput = ({items, onClick, onBack}: UserListInputProps) => {
  const inputItems = useMemo(() => {
    return items.map((item) => (
      <UserListInputItem key={item.value} onClick={onClick} value={item} />
    ))
  }, [items, onClick])

  return (
    <div>
      {onBack && (
        <Button onClick={onBack} variant='text' color='dark'>
          Back
        </Button>
      )}
      <div className='text-center'>
        <h2>Log in as</h2>
        <p>Choose one of the following accounts</p>
      </div>
      <ul className='user-list-input-list'>{inputItems}</ul>
    </div>
  )
}
