import clsx from 'clsx'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {Paper} from '../../../../components/utils/Paper'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import {TimeSlotFormatter} from '../../../default/ems/components/tables/column-formatters/TimeSlotFormatter'
import {PortalShareStatusColumn} from '../tables/PortalShareStatusColumn'
import {VoucherTableRowActions} from '../../../customer-delegate/components/tables/AdvancedFilter/VoucherTable/VoucherTableRowActions'

export interface VoucherCardProps {
  data: VoucherModel
  className?: string
  loading?: boolean
  onDisable?: (data: VoucherModel) => void
}

export const VoucherCard = ({data, className, loading, onDisable}: VoucherCardProps) => {
  return (
    <Paper className={clsx('p-5', className)} rounded>
      <div className='mb-2'>
        <span className='fs-6 fw-bolder'>{data.name}</span>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <span className='fw-bolder'>Code: </span>
          <span className='fs-6'>{data.code}</span>
        </div>
        <div>
          <span className='fw-bolder'>Status: </span>
          <PortalShareStatusColumn data={data} />
        </div>
      </div>
      <HorizontalDivider />
      <div>
        <span className='fw-bolder'>Event: </span>
        <span className='fs-6'>{data.event?.name}</span>
      </div>
      <div className='d-flex flex-wrap justify-content-between'>
        <div>
          <span className='fw-bolder me-1'>Start: </span>
          <span className='fs-6'>
            <TimeSlotFormatter className='text-uppercase'>{data.startedAt}</TimeSlotFormatter>
          </span>
        </div>
        <div>
          <span className='fw-bolder'>End: </span>
          <span className='fs-6'>
            <TimeSlotFormatter className='text-uppercase'>{data.startedAt}</TimeSlotFormatter>
          </span>
        </div>
      </div>
      <HorizontalDivider />
      {data.share && (
        <div>
          <div className='fw-bolder'>Shared To:</div>
          <div>
            <MetronicIcon iconType='General' iconName='User' />
            {data.share.name}
          </div>
          <div>
            <MetronicIcon iconType='Communication' iconName='Mail-at' />
            {data.share.email}
          </div>
        </div>
      )}
      <div className='table-action-container align-items-end'>
        <VoucherTableRowActions loading={loading} data={data} onDisable={onDisable} />
      </div>
    </Paper>
  )
}
