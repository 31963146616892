import {Component, ReactNode} from 'react'
import {MetronicIconButton} from '../MetronicIconButton'
import {DropdownMenu} from './DropDownMenu'

export interface OtherDropdownMenuButtonProps {
  children?: ReactNode
  className?: string
}

export interface OtherDropdownMenuButtonState {
  showMenu: boolean
}

export class OtherDropdownMenuButton extends Component<
  OtherDropdownMenuButtonProps,
  OtherDropdownMenuButtonState
> {
  public state: OtherDropdownMenuButtonState = {
    showMenu: false,
  }
  private button: HTMLButtonElement | null = null

  public showMenu = () => {
    this.setState({showMenu: true}, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  public closeMenu = (event: MouseEvent) => {
    if (this.button) {
      if (!this.button.contains(event.target as Node)) {
        this.setState({showMenu: false}, () => {
          document.removeEventListener('click', this.closeMenu)
        })
      }
    }
  }

  private handleButtonRef = (el: HTMLButtonElement | null) => {
    this.button = el
  }

  public render = () => {
    const {children, className} = this.props
    const {showMenu} = this.state
    return (
      <div
        className={className}
        style={{
          display: 'inline-block',
        }}
      >
        <MetronicIconButton
          ref={this.handleButtonRef}
          onClick={this.showMenu}
          type='button'
          iconName='Other1'
          iconType='General'
        />
        {showMenu && <DropdownMenu target={this.button}>{children}</DropdownMenu>}
      </div>
    )
  }
}
