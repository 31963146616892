import {useFormik} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ActivityModel} from '../../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {actions} from '../../../redux/CustomerPortalRedux'
import {GetBookingByCode, PutBooking} from '../../../redux/CustomerPortalCRUD'

import {BookingWizard} from './BookingWizard'

import {
  getPayload,
  EMPTY_FORM_VALUES,
  bookingWizardValidationSchema,
} from '../../../../../components/BookingWizard/hook/BookingWizardHelper'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {mapBookingDataToFormValues, useBookingWiard} from './hook/useBookingWizard'

export interface BookingEditWizardProps {
  onSuccess?: () => void
  event?: EventModel | ActivityModel | null
  step?: number
}

interface RouterProps {
  params: {
    eventCode: string
    bookingCode: string
    customerCode: string
  }
}

export const BookingEditWizard = ({onSuccess, event, step}: BookingEditWizardProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const {pushError, push} = useAlerts()
  const [booking, setBooking] = useState<BookingModel | null>(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: {eventCode, bookingCode, customerCode},
  }: RouterProps = useRouteMatch()

  useOnChange(step, () => {
    if (step) setCurrentStep(step)
  })

  const {customer, getCustomer} = useBookingWiard()

  useOnChange(customerCode, () => {
    if (customerCode) getCustomer(customerCode)
  })

  const getBooking = useCallback(async () => {
    if (bookingCode) {
      try {
        const data = await GetBookingByCode(bookingCode)
        if (data) setBooking(data.data)
      } catch (err) {
        pushError(err)
      }
    }
  }, [bookingCode, pushError])

  useOnChange(bookingCode, () => {
    if (bookingCode) {
      getBooking()
    }
  })

  useOnChange(booking, () => {
    if (booking) {
      formik.setValues(mapBookingDataToFormValues(booking))
      dispatch(actions.bookingForm.setBookingForm(mapBookingDataToFormValues(booking)))
    }
  })

  const formik = useFormik({
    initialValues: EMPTY_FORM_VALUES,
    validationSchema: bookingWizardValidationSchema,
    validateOnMount: true,
    onSubmit: async (values, {setSubmitting}) => {
      dispatch(actions.bookingForm.resetBookingForm())
      setSubmitting(true)
      try {
        onSuccess?.()
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleStepChange = useCallback(
    async (step: number) => {
      if (step === 3 && bookingCode) {
        try {
          const payload = getPayload(formik.values)
          const {data} = await PutBooking(payload, bookingCode)
          if (data) {
            dispatch(actions.bookingForm.setBookingForm(formik.values))
            push({
              message: `Booking updated successfully`,
              variant: 'success',
              timeout: 3000,
            })
            if (eventCode) history.replace(`/event/${eventCode}/booking/edit/${data.code}/final`)
            if (customerCode)
              history.replace(`/customer/${customerCode}/booking/edit/${data.code}/final`)
          }
        } catch (e) {
          pushError(e)
        }
      } else {
        setCurrentStep(step)
        dispatch(actions.bookingForm.setBookingForm(formik.values))
      }
    },
    [bookingCode, customerCode, dispatch, eventCode, formik.values, history, push, pushError]
  )

  const handleOnSubmit = useCallback(() => {
    dispatch(actions.bookingForm.resetBookingForm())
    onSuccess?.()
  }, [dispatch, onSuccess])

  const disabledFields = useMemo(() => {
    return {
      eventCode: Boolean(eventCode),
    }
  }, [eventCode])

  return (
    <BookingWizard
      formik={formik}
      step={currentStep}
      onStepChange={handleStepChange}
      event={event}
      customer={customer}
      isEdit
      onSubmit={handleOnSubmit}
      disabledFields={disabledFields}
    />
  )
}
