import {useCallback, useState} from 'react'
import {useParams} from 'react-router-dom'
import {GetCustomerServicesByCode} from '../../redux/EvaCRUD'
import {useOnMount} from '../../../../components/hooks/useOnMount'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {DocumentType} from '../../../../models/CustomerModel'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
import {Badge} from '../../../../components/badge/Badge'

export const CustomerServicePDFPage = () => {
  const [currentDocument, setCurrectDocument] = useState<DocumentType>()
  const {pdfCode, serviceCode} = useParams<{pdfCode?: string; serviceCode?: string}>()
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const {staticUrls} = useAppConfig()
  const {pushError} = useAlerts()

  const refreshData = useCallback(async () => {
    if (serviceCode && ticketCode) {
      try {
        const {data} = await GetCustomerServicesByCode(serviceCode, ticketCode)
        if (data && data.documents) {
          const documents = data.documents.find((doc) => doc.code === pdfCode)
          setCurrectDocument(documents)
        }
      } catch (e) {
        pushError(e)
      }
    }
  }, [serviceCode, ticketCode, pdfCode, pushError])

  useOnMount(() => {
    refreshData()
  })

  return currentDocument ? (
    <>
      <div
        className='px-5 mt-5 d-flex flex-column'
        style={{
          height: 'calc(100% - 32px)',
        }}
      >
        <div>
          <Badge variant='primary'>{`${currentDocument?.name}`}</Badge>
          <Badge className='ms-5' variant='warning'>{`${currentDocument?.size}/KB`}</Badge>
        </div>
        <div className='mt-5 flex-grow-1'>
          <embed
            src={`${staticUrls.private}/${currentDocument?.code}#toolbar=0`}
            width='100%'
            height='100%'
          />
        </div>
      </div>
    </>
  ) : null
}
