import {BaseSyntheticEvent, SetStateAction, useState, useEffect, useMemo} from 'react'
import {forEach, chunk} from 'lodash'
import {MetronicIcon} from '../inputs/MetronicIcon'
import {INITIAL_TABLE_PAGE_SIZE} from '../tables/FilterTable'
import clsx from 'clsx'
const GetPageCount = (total?: number, pageSize?: number): number => {
  return total && pageSize ? Math.ceil(total / pageSize) : 0
}

const GetPages = (page: number, pageSize: number, pageCount: number) => {
  const result: number[] = []
  if (!page) {
    return result
  }

  if (pageCount === 1) {
    result.push(1)
    return result
  }

  if (pageCount < page) {
    return result
  }

  if (pageCount < pageSize + 1) {
    for (let i = 1; i < pageCount + 1; i++) {
      result.push(i)
    }
    return result
  }

  if (page === 1) {
    for (let i = 1; i < pageSize + 1; i++) {
      if (i < pageCount) {
        result.push(i)
      }
    }
    return result
  }

  if (page === pageCount) {
    for (let i = pageCount - pageSize + 1; i <= pageCount; i++) {
      if (i <= pageCount) {
        result.push(i)
      }
    }
    return result
  }

  const shiftCount = Math.floor(pageSize / 2)
  if (shiftCount < 1) {
    result.push(page)
    return result
  }

  //
  if (page < shiftCount + 2) {
    for (let i = 1; i < pageSize + 1; i++) {
      result.push(i)
    }
    return result
  }

  if (pageCount - page < shiftCount + 2) {
    for (let i = pageCount - pageSize; i < pageCount + 1; i++) {
      result.push(i)
    }
    return result
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i)
    }
  }
  result.push(page)
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pageCount) {
      result.push(i)
    }
  }

  return result
}

interface PageSizeListProps {
  value: string
  text: string
}

export interface PaginationHelperProps {
  currentPageNumber?: number
  currentPageSize?: number
  onChangePageNumber?: (pageNumber: number) => void
  onChangePageSize?: (pageSize: number) => void
  total?: number
  pageListOptions?: PageSizeListProps[]
  additionalPagesSize?: number[]
  className?: string
}

const PaginationHelper = ({
  currentPageNumber = 1,
  currentPageSize = INITIAL_TABLE_PAGE_SIZE,
  onChangePageNumber,
  onChangePageSize,
  total,
  pageListOptions,
  additionalPagesSize = [],
  className,
}: PaginationHelperProps) => {
  const [pageCount, setPageCount] = useState(0)
  const [pagesChunk, setPagesChunk] = useState<number[][]>([])
  const [currentChunk, setCurrentChunk] = useState(0)
  const [showingCount, setShowingCount] = useState(1)
  const [limitPagesNumberPerTime] = useState(5)
  const [pagesSize] = useState<number[]>([10, 25, 50, 100])
  const handleChangePageSize = (e: BaseSyntheticEvent) => {
    if (onChangePageSize) onChangePageSize(parseInt(e.currentTarget.value, 10))
  }

  const sizes = useMemo(() => {
    let newSizes = [...pagesSize, ...additionalPagesSize]
    newSizes = newSizes.sort((a: number, b: number) => a - b)
    return newSizes
  }, [additionalPagesSize, pagesSize])

  const handleChangePageNumber = (page: number) => {
    if (onChangePageNumber) {
      onChangePageNumber(page)
    }

    if (pagesChunk) {
      forEach(pagesChunk, (_pg, idx) => {
        if (_pg.includes(page)) {
          setCurrentChunk(idx)
        }
      })
    }
  }

  const handleChangeChunk = (chunk: number) => {
    setCurrentChunk(chunk)
    handleChangePageNumber(chunk)
  }

  const pageSizeList = useMemo(() => {
    if (pageListOptions && pageListOptions.length) {
      return pageListOptions.map((item) => {
        return (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        )
      })
    }
    return null
  }, [pageListOptions])

  useEffect(() => {
    const _pageCount = GetPageCount(total, currentPageSize)
    const _pages = GetPages(currentPageNumber || 1, _pageCount, _pageCount)
    const _pageChunk = chunk(_pages, limitPagesNumberPerTime)
    setPageCount(_pageCount)
    setPagesChunk(_pageChunk)
    if (_pageChunk) {
      forEach(_pageChunk, (_pg, idx) => {
        if (_pg.includes(currentPageNumber || 1)) {
          setCurrentChunk(idx)
        }
      })
    }
    const _showingCount: SetStateAction<number> =
      (currentPageNumber
        ? currentPageNumber === 1
          ? (currentPageSize || 10) < (total || 10)
            ? currentPageSize
            : total
          : (currentPageSize || 10) * currentPageNumber < (total || 10)
          ? (currentPageSize || 10) * currentPageNumber
          : total
        : total) || 1
    setShowingCount(_showingCount)
  }, [currentPageNumber, currentPageSize, total, limitPagesNumberPerTime])

  if (!pageCount) {
    return null
  }

  return (
    <>
      {pageCount && (
        <div
          className={clsx(
            'd-flex flex-stack gap-2 flex-wrap mb-5 justify-content-center position-relative rs-pagination px-8',
            className
          )}
        >
          <div className='text-muted flex-grow-1 mb-5 mb-sm-0 me-5 me-sm-0'>
            Showing {showingCount} of {total}
          </div>
          <div
            className={clsx(
              `d-flex mb-5 mb-sm-0 gap-2`,
              className ? '' : 'me-sm-0 me-xs-0 me-md-5'
            )}
          >
            <div className='align-middle w-70px py-2 text-muted'>Page Size:</div>
            <div className='ms-2 w-70px'>
              <select
                value={currentPageSize}
                className='form-select form-select-solid form-select-sm select2-hidden-accessible'
                onChange={handleChangePageSize}
              >
                {pageSizeList
                  ? pageSizeList
                  : sizes &&
                    sizes.length > 0 &&
                    sizes.map((item, idx) => {
                      return <option key={idx + item} value={`${item}`}>{`${item}`}</option>
                    })}
              </select>
            </div>
          </div>
          <div className='d-flex flex-sm-grow-0 justify-content-between mb-5 mb-sm-0'>
            {pageCount > 1 && (
              <ul className='pagination'>
                {currentPageNumber && currentPageNumber !== 1 && (
                  <>
                    <li className='page-item previous'>
                      <button className='page-link' onClick={() => handleChangeChunk(1)}>
                        <MetronicIcon iconType='Navigation' iconName='Angle-double-left' />
                      </button>
                    </li>
                    <li className='page-item previous'>
                      <button
                        className='page-link'
                        onClick={() => handleChangePageNumber(currentPageNumber - 1)}
                      >
                        <i className='previous'></i>
                      </button>
                    </li>
                  </>
                )}

                {pagesChunk &&
                  currentPageNumber &&
                  pagesChunk[currentChunk] &&
                  pagesChunk[currentChunk].map((page) => {
                    return (
                      <li
                        className={`page-item ${
                          page ===
                          (pagesChunk[currentChunk][
                            pagesChunk[currentChunk].indexOf(currentPageNumber)
                          ] || 1)
                            ? 'active'
                            : ''
                        }`}
                        key={page}
                      >
                        <button
                          className='p-xs-1 p-auto page-link'
                          onClick={() => handleChangePageNumber(page)}
                        >
                          {page}
                        </button>
                      </li>
                    )
                  })}

                {pageCount && currentPageNumber !== pageCount && (
                  <>
                    <li className='page-item next'>
                      <button
                        className='page-link'
                        onClick={() => handleChangePageNumber((currentPageNumber || 1) + 1)}
                      >
                        <i className='next'></i>
                      </button>
                    </li>
                    <li className='page-item previous'>
                      <button className='page-link' onClick={() => handleChangeChunk(pageCount)}>
                        <MetronicIcon iconType='Navigation' iconName='Angle-double-right' />
                      </button>
                    </li>
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default PaginationHelper
