import {ProductVoucherBulkModel} from '../../../models/booking-wizard/BulkBookingWizard'
import {Badge} from '../../badge/Badge'
import {MetronicIcon} from '../../inputs/MetronicIcon'

interface FinalStepProductHeaderProps {
  product: ProductVoucherBulkModel | null
}

export const FinalStepProductHeader = ({product}: FinalStepProductHeaderProps) => {
  return product ? (
    <div className='card'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-row'>
                <div className='d-flex flex-column px-2'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='text-gray-800 fs-2 fw-bolder mb-1'>{`Product name: ${product?.name}`}</p>
                  </div>

                  <div className='d-flex flex-wrap mb-2 pe-2'>
                    <span className='d-flex align-items-center text-muted me-5 mb-2'>
                      product type:
                      <Badge
                        className='text-nowrap ms-2'
                        uppercase
                        variant={product.type === 'product' ? 'info' : 'warning'}
                      >
                        {product.type}
                      </Badge>
                    </span>
                  </div>

                  {product?.isSeated && (
                    <div className='d-flex flex-wrap mb-2 pe-2'>
                      <span className='d-flex align-items-center text-muted me-5 mb-2'>
                        Seated:
                        <MetronicIcon
                          className='svg-icon-success svg-icon-1hx ms-2'
                          iconType='Navigation'
                          iconName='Check'
                        />
                      </span>
                    </div>
                  )}

                  <div className='d-flex flex-wrap mb-2 pe-2'>
                    <span className='d-flex align-items-center text-muted me-5 mb-2'>
                      {`Qty: ${product.qty}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
