import {useAlerts} from './../../../../../../components/alerts/useAlerts'
import {useCallback, useMemo, useState} from 'react'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {
  GetCustomerListFlatten,
  GetEvents,
  GetProducts,
  GetVoucherTemplateList,
} from '../../../../redux/CustomerPortalCRUD'
import {EventModel} from '../../../../../../models/ems/EventModel'
import {ProductModel} from '../../../../../../models/ems/ProductModel'
import {VoucherModel} from '../../../../../../models/svc/VoucherModel'
import {GetEventProducts} from '../../../../../default/ems/redux/EmsCRUD'
interface UseBookingFormDataProps {
  eventCode?: string | null
}
export const useBookingFormData = ({eventCode}: UseBookingFormDataProps) => {
  const [customers, setCustomers] = useState<GlobalSearchModel<CustomerModel>>()
  const [productSearchResults, setProducts] = useState<GlobalSearchModel<ProductModel>>()
  const [voucherSearchResults, setVoucherSearchResults] =
    useState<GlobalSearchModel<VoucherModel>>()
  const [events, setEvents] = useState<EventModel[]>([])
  const {pushError} = useAlerts()
  const getCustomers = useCallback(
    async (filters?: FilterModel) => {
      try {
        const {data} = await GetCustomerListFlatten({
          ...filters,
          filters: {
            ...filters?.filters,
            status: ['active', 'pending'],
            search: filters?.filters?.search,
            type: ['customer'],
          },
        })
        if (Array.isArray(data.data)) setCustomers(data as GlobalSearchModel<CustomerModel>)
      } catch (err) {
        pushError(err)
      }
    },
    [pushError]
  )

  const getEventProducts = useCallback(
    async (filter?: FilterModel) => {
      try {
        if (eventCode) {
          const {data} = await GetEventProducts(eventCode, {
            ...filter,
            filters: {
              ...filter?.filters,
              eventCode: eventCode || undefined,
            },
          })
          if (data)
            setProducts({data: data.products, limit: 1000, page: 1, total: data.products.length})
        }
      } catch (err) {
        setProducts(undefined)
        pushError(err)
      }
    },
    [eventCode, pushError]
  )

  const resetEventFieldItems = useCallback(async () => {
    try {
      const {data} = await GetEvents()
      setEvents(data.data)
    } catch (e) {
      pushError(e)
    }
  }, [pushError])

  const refreshProductsList = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetProducts({
          ...filter,
          filters: {
            ...filter?.filters,
            eventCode: eventCode || undefined,
          },
        })
        if (data) setProducts(data)
      } catch (err) {
        setProducts(undefined)

        pushError(err)
      }
    },
    [eventCode, pushError]
  )

  const searchVouchers = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetVoucherTemplateList({
          ...filter,
          filters: {
            ...filter?.filters,
            event: eventCode,
          },
        })
        setVoucherSearchResults(data)
      } catch (err) {
        setVoucherSearchResults(undefined)
        pushError(err)
      }
    },
    [eventCode, pushError]
  )

  return useMemo(
    () => ({
      customers,
      getCustomers,
      resetEventFieldItems,
      events,
      refreshProductsList,
      productSearchResults,
      searchVouchers,
      getEventProducts,
      voucherSearchResults,
    }),
    [
      customers,
      events,
      getCustomers,
      refreshProductsList,
      searchVouchers,
      productSearchResults,
      getEventProducts,
      resetEventFieldItems,
      voucherSearchResults,
    ]
  )
}
