import {useMemo} from 'react'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {usePieChartTooltipContext} from '../../../../../../components/charts/PieChart/usePieChartTooltip'
import {WidgetF02Model} from '../../../../../../models/fnb/WidgetModel'

export interface EarningsTypePieChartTooltipProps {
  data?: WidgetF02Model[]
}

export const EarningsTypePieChartTooltip = ({data}: EarningsTypePieChartTooltipProps) => {
  const {key} = usePieChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (key && data) {
      return data.find((item) => item.orderPaymentType === key)
    }
  }, [data, key])

  if (!hoveredData || !key) {
    if (key) {
      return (
        <MarkdownTooltip>{`
**Payment Type:** ${getLabel(key)}

**Sales:** ${hoveredData?.total || 0}

**Quantity:** ${hoveredData?.quantity || 0}
`}</MarkdownTooltip>
      )
    }
    return null
  }

  return (
    <MarkdownTooltip>{`
**Payment Type:** ${getLabel(key)}

**Sales:** ${hoveredData?.total || 0}

**Quantity:** ${hoveredData?.quantity || 0}
`}</MarkdownTooltip>
  )
}

export const getLabel = (key: string) => {
  switch (key) {
    case 'cc':
      return 'Credit Card'
    case 'cash-card':
      return 'Cash Card'
    case 'voucher':
      return 'Voucher'
    default:
      return 'Unknown'
  }
}
