import {DeleteRowAction} from './DeleteRowAction'
import {EditRowAction} from './EditRowAction'
import {ScanQRRowAction} from './ScanQRRowAction'

export interface ManageRowActionProps<T> {
  data: T
  onEdit?: (data: T) => void
  onDelete?: (data: T) => void
  loading?: boolean
  onScanQR?: (data: T) => void
}

export const ManageRowAction = <T,>({
  data,
  loading,
  onDelete,
  onEdit,
  onScanQR,
}: ManageRowActionProps<T>) => {
  return (
    <>
      <ScanQRRowAction loading={loading} data={data} onClick={onScanQR} />
      <EditRowAction loading={loading} data={data} onClick={onEdit} />
      <DeleteRowAction loading={loading} data={data} onClick={onDelete} />
    </>
  )
}
