export class ImportMapping {
  constructor(private mapping: Record<string, number> = {}) {}

  public toArray = () => {
    const mappingArray: string[] = new Array(
      Math.max(...Object.values(this.mapping).map((value) => Number(value)))
    )

    Object.entries(this.mapping).forEach(([key, value]) => {
      mappingArray[Number(value)] = key
    })

    return mappingArray
  }
}
