import clsx from 'clsx'
import {useCallback, useMemo} from 'react'

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

export interface SelectTreeOptionGroupItem {
  label: string
  value?: string
  disabled?: boolean
  items?: SelectTreeOptionGroupItem[]
}

export interface SelectTreeOptionGroupProps extends SelectProps {
  items: SelectTreeOptionGroupItem[]
  label?: string
  value: string
  placeholder: string
  className?: string
  noMargin?: boolean
}

export const SelectTreeOptionGroup = ({
  items,
  label,
  placeholder,
  className,
  value,
  multiple,
  noMargin,
  disabled,
  ...selectProps
}: SelectTreeOptionGroupProps) => {
  const isValueListed = useMemo(() => {
    return value === '' || isValueExist(value, items)
  }, [items, value])
  const displayedPlaceholder = useMemo(() => {
    if (!isValueListed) {
      return 'Loading...'
    }
    return placeholder
  }, [isValueListed, placeholder])

  const flattenItems = useCallback((items: SelectTreeOptionGroupItem[]) => {
    let root: SelectTreeOptionGroupItem[] = [...items]
    for (let i = 0; i < root.length; i++) {
      const firstLevelItem = root[i]
      if (firstLevelItem.items) {
        for (let k = 0; k < firstLevelItem.items.length; k++) {
          const secondLevelItem = firstLevelItem.items[k]
          if (secondLevelItem.items?.length) {
            root.push(...secondLevelItem.items)
            secondLevelItem.items = []
            k--
          }
        }
      }
    }
    return root
  }, [])

  const optGroupNodes = useMemo(() => {
    const flatItems = flattenItems(items)
    return flatItems.map((group, i) => {
      const groupOption = group.value ? (
        <option disabled={group.disabled} key={group.value} value={group.value}>
          {group.label}
        </option>
      ) : null
      if (group.items?.length) {
        return (
          <optgroup disabled={group.disabled} key={group.label + i} label={group.label}>
            {groupOption}
            {group.items?.map((item) => {
              return (
                <option disabled={item.disabled} key={item.value} value={item.value}>
                  {item.label}
                </option>
              )
            })}
          </optgroup>
        )
      }
      return groupOption
    })
  }, [flattenItems, items])

  return (
    <div className={clsx({'mb-5': !noMargin}, className)}>
      {label && <label className='form-label'>{label}</label>}
      <select
        className='form-select form-select-solid'
        aria-label={label}
        value={value}
        multiple={multiple}
        disabled={!isValueListed || disabled}
        {...selectProps}
      >
        {!multiple && <option value=''>{displayedPlaceholder}</option>}
        {optGroupNodes}
      </select>
    </div>
  )
}

const isValueExist = (value: string, items: SelectTreeOptionGroupItem[]): boolean => {
  return items.some((item) => {
    if (item.value === value) {
      return true
    }
    if (item.items) {
      return isValueExist(value, item.items)
    }
    return false
  })
}
