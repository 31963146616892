import {ReactNode} from 'react'
import {HasCode} from '../../utils/idExtractor'
import {useAuthRole} from '../hooks/useAuthRole'
import {Link} from './Link'

export interface EventLinkProps {
  className?: string
  event?: {
    code: string
    parent?: HasCode
  }
  children: ReactNode
}

export const EventLink = ({className, event, children}: EventLinkProps) => {
  const auth = useAuthRole()

  if (event) {
    if (event.parent) {
      if (!auth?.canReadFeature('EMSACTIVITY')) {
        return <>{children}</>
      }
      return (
        <Link className={className} to={`/ems/event/${event.parent.code}/activity/${event.code}`}>
          {children}
        </Link>
      )
    }
    if (!auth?.canReadFeature('EMSEVENTS')) {
      return <>{children}</>
    }
    return (
      <Link className={className} to={`/ems/event/${event.code}`}>
        {children}
      </Link>
    )
  }
  return null
}
