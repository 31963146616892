import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {TicketWaiverModel} from '../../../models/digital-waiver/TicketWaiverModel'
import {DelegateTicketProps, DelegateTicket} from '../redux/DigitalWaiverCRUD'

import {InitialValuesProps} from '../components/modals/WaiverDelegateModal'

interface Props {
  onRefresh?: () => void
  onRefreshCallback?: () => void
  clearSelected?: () => void
}

export const useTicketTableActions = ({onRefresh, onRefreshCallback, clearSelected}: Props) => {
  const {pushError, push} = useAlerts()
  const {isLoading, isKeyLoading, setIsLoading} = useLoadingState()

  const delegate = useCallback(
    async (values: InitialValuesProps, codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        if (values.email) {
          const payload: DelegateTicketProps = {
            email: values.email,
            ticketCodes: codes,
            name: values.name,
          }
          const {data} = await DelegateTicket(payload)
          push({
            message: `Successfully delegated.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
          clearSelected && clearSelected()
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        doneLoading()
      }
    },
    [clearSelected, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleDelegateBulk = useCallback(
    (values: InitialValuesProps, data: TicketWaiverModel[]) => {
      return delegate(
        values,
        data.map((item) => item.code)
      )
    },
    [delegate]
  )

  const handleDelegateSingle = useCallback(
    (values: InitialValuesProps, data: TicketWaiverModel) => {
      return delegate(values, [data.code])
    },
    [delegate]
  )

  return useMemo(
    () => ({
      isLoading,
      isKeyLoading,
      handleDelegateSingle,
      handleDelegateBulk,
    }),
    [isLoading, isKeyLoading, handleDelegateSingle, handleDelegateBulk]
  )
}
