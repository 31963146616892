import {useCallback, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {VAT} from '../../../../config/env'
import {DefaultFNBProductImage} from '../../../../config/logos'
import {useBooleanState} from '../../../components/hooks/useBooleanState'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {TextInput} from '../../../components/inputs'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {OutletModel} from '../../../models/fnb/OutletModel'
import {ProductCategoryModel} from '../../../models/fnb/ProductCategoryModel'
import {ProductModel} from '../../../models/fnb/ProductModel'
import {VatExclusivePriceTag} from '../../../utils/VatExclusivePriceTag'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {BasketButton} from '../components/BasketButton'
import {MenuProductAttributes} from '../components/Menu/MenuProduct'
import {MenuProductCategory} from '../components/Menu/MenuProductCategory'
import {PreparingTimeText} from '../components/PreparingTimeText'
import {Tabs} from '../components/Tabs/Tabs'
import {useCart} from '../hooks/useCart'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {GetOutletByCode} from '../redux/DigitalMenuCRUD'

interface PathParams {
  locationCode: string
  outletCode: string
}

export const OutletPage = () => {
  const [activeTab, setActiveTab] = useState<string>()
  const {staticUrls} = useAppConfig()
  const [outlet, setOutlet] = useState<OutletModel>()
  const [searchFilter, setSearchFilter] = useState('')
  const {outletCode} = useParams<PathParams>()
  const {
    enableState: setIsSearching,
    state: isSearching,
    disableState: setIsNotSearching,
  } = useBooleanState(false)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const banner = useMemo(() => {
    if (outlet?.file) {
      return new ImageInputValue(staticUrls.public, outlet.file)
    }
    return null
  }, [outlet?.file, staticUrls.public])
  const {cart, setCart} = useCart(outletCode)
  const {
    goToCheckout,
    goToOutletTableReservation,
    goToOutlets: goToLocationOutlets,
    getQueryParamsOrderType,
  } = usePageNavigation()

  const handleTableReservationClick = useCallback(() => {
    goToOutletTableReservation(outletCode)
  }, [goToOutletTableReservation, outletCode])

  const menuContainerRef = useRef<HTMLDivElement | null>(null)
  const getProduct = useCallback(
    (productCode: string) => {
      if (outlet?.categories) {
        for (let category of outlet.categories) {
          if (category.fnbProducts) {
            for (let product of category.fnbProducts) {
              if (product.code === productCode) {
                return product
              }
            }
          }
        }
      }
      return null
    },
    [outlet?.categories]
  )

  const canOrder = useMemo(() => {
    return getQueryParamsOrderType() !== 'dine-in' && Boolean(outlet?.hasDigitalMenu)
  }, [getQueryParamsOrderType, outlet?.hasDigitalMenu])

  const handleProductClick = useCallback(
    (productCode: string) => {
      if (canOrder) {
        if (cart.hasItem(productCode)) {
          cart.incrementCount(productCode)
        } else {
          const product = getProduct(productCode)
          if (product) {
            cart.addItem(
              product.code,
              product.name,
              new VatExclusivePriceTag(product.price, 'AED', VAT)
            )
          }
        }
        setCart(cart)
      }
    },
    [canOrder, cart, getProduct, setCart]
  )

  const matchedProductCategories = useMemo(() => {
    return outlet?.categories?.reduce<ProductCategoryModel[]>((acc, category) => {
      const products: ProductModel[] = []

      if (category.fnbProducts) {
        category.fnbProducts.forEach((product) => {
          const titleSearchMatch = product.name.toLowerCase().includes(searchFilter.toLowerCase())
          const descriptionSearchMatch = product.description
            ?.toLowerCase()
            .includes(searchFilter.toLowerCase())

          if (titleSearchMatch || descriptionSearchMatch) {
            products.push(product)
          }
        })
      }

      if (products.length) {
        acc.push({
          ...category,
          fnbProducts: products,
        })
      }

      return acc
    }, [])
  }, [outlet?.categories, searchFilter])

  const tabs = useMemo((): SelectInputItem[] => {
    return (
      matchedProductCategories?.map((category) => ({value: category.code, label: category.name})) ||
      []
    )
  }, [matchedProductCategories])

  const productCategories = useMemo(() => {
    return matchedProductCategories?.map((category) => {
      const products: MenuProductAttributes[] = []

      if (category.fnbProducts) {
        category.fnbProducts.forEach((product) => {
          products.push({
            currency: 'AED',
            description: product.description,
            image: product.file
              ? new ImageInputValue(staticUrls.public, product.file)
              : new ImageInputValue(DefaultFNBProductImage.src, product.name, product.code),
            price: product.price,
            title: product.name,
            value: product.code,
          })
        })
      }

      return (
        <MenuProductCategory
          id={`category-${category.code}`}
          key={category.code}
          name={category.name}
          products={products}
          onClick={handleProductClick}
        />
      )
    })
  }, [handleProductClick, matchedProductCategories, staticUrls.public])

  const scrollToSection = useCallback((id: string) => {
    const menuContainer = menuContainerRef.current
    if (menuContainer) {
      const section = menuContainer.querySelector(`#category-${id}`)
      if (section) {
        const yOffset = -50
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({top: y, behavior: 'smooth'})
      }
    }
  }, [])

  const searchOutletsHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value)
  }, [])

  const resetOutletData = useCallback(async () => {
    const {data} = await GetOutletByCode(outletCode)
    setOutlet(data)
  }, [outletCode])

  const handleBack = useCallback(() => {
    goToLocationOutlets()
  }, [goToLocationOutlets])

  useOnChange(activeTab, () => {
    if (activeTab) {
      scrollToSection(activeTab)
    }
  })

  useOnMount(() => {
    resetOutletData()
  })

  return (
    <div className='outlet-food-menu'>
      <img className='banner' src={banner?.url} alt={outlet?.name} />
      <div className='fixed-top container d-flex justify-content-between p-5'>
        {isSearching ? (
          <TextInput
            className='flex-grow-1 me-2 fs-2 min-h-50px'
            placeholder='Enter product name'
            noMargin
            ref={searchInputRef}
            onBlur={setIsNotSearching}
            onChange={searchOutletsHandler}
          />
        ) : (
          <button className='btn btn-lg btn-icon bg-white rounded-circle' onClick={handleBack}>
            <MetronicIcon iconType='Navigation' iconName='Angle-left' size='lg' />
          </button>
        )}
        <div>
          <button className='btn btn-lg btn-icon bg-white rounded-circle' onClick={setIsSearching}>
            <MetronicIcon iconType='General' iconName='Search' size='lg' />
          </button>
        </div>
      </div>
      <div className='content'>
        <div className='container'>
          <h1>{outlet?.name}</h1>
          <p className='text-muted'>{outlet?.description}</p>
          <div className='d-flex justify-content-between'>
            <PreparingTimeText preparingTime={20} />
            {outlet?.orderType.includes('dine-in') && (
              <button
                className='btn btn-sm rounded-0 border border-success bg-light-success ms-3 text-nowrap'
                onClick={handleTableReservationClick}
              >
                Reserve a table
              </button>
            )}
          </div>
        </div>
        <Tabs
          className='category-tabs my-5 bg-light'
          onClick={setActiveTab}
          tabs={tabs}
          value={activeTab}
        />
        <div ref={menuContainerRef} className='outlet-menu'>
          {productCategories}
        </div>
      </div>
      {canOrder && (
        <BasketButton
          onClick={goToCheckout}
          className='fixed-bottom w-100'
          count={cart.getItemCount()}
          currency='AED'
          total={cart.getTotalVatInclusive()}
        />
      )}
    </div>
  )
}
