import {Fragment, useCallback, useMemo} from 'react'
import {SeatMapValue} from '../SeatMapValue'
import {SeatValue} from '../SeatValue'

export interface DayProps {
  color: string
  text: string
  stroke: string
}

export interface SeatMapDays {
  [key: string]: DayProps
}

export interface SVGSeatProps {
  rowIndex: number
  columnIndex: number
  onClick?: (seat: SeatValue) => void
  stroke?: string
  color?: string
  x: number
  y: number
  height: number
  width: number
  hidden?: boolean
  value: SeatValue
  daysBoxWidth?: number
  daysBoxHeight?: number
  days?: SeatMapDays
  isCircledDaysBox?: boolean
  isDays?: boolean
}

export const SVGSeat = ({
  columnIndex,
  rowIndex,
  onClick,
  hidden,
  color,
  width,
  height,
  x,
  y,
  stroke,
  value,
  days,
  daysBoxWidth,
  daysBoxHeight,
  isCircledDaysBox,
  isDays,
}: SVGSeatProps) => {
  const seatLabel = useMemo(
    () => `${SeatMapValue.getRowLetter(rowIndex + 1)}${columnIndex + 1}`,
    [columnIndex, rowIndex]
  )
  const handleClick = useCallback(() => {
    onClick?.(value)
  }, [onClick, value])

  if (hidden) {
    return null
  }

  return (
    <>
      <rect
        x={x}
        y={y}
        height={height}
        width={width}
        stroke={stroke || 'white'}
        fill={color}
        strokeWidth={1}
        onClickCapture={handleClick}
      ></rect>
      <text
        x={x + width / 2}
        y={y + height / 2}
        dominantBaseline='middle'
        fill='white'
        textAnchor='middle'
        onClickCapture={handleClick}
        fontSize={isDays ? '30' : '12'}
      >
        {seatLabel}
      </text>

      {days && daysBoxHeight && daysBoxWidth && (
        <>
          {Object.keys(days).map((item, idx) => {
            return isCircledDaysBox ? (
              <circle
                key={days[item].color + idx}
                cx={x + 23 + daysBoxWidth * idx + (idx > 0 ? 23 * idx : 0)}
                cy={y + height - daysBoxHeight - 10}
                r={daysBoxHeight}
                stroke={days[item].stroke || days[item].color}
                fill={days[item].color || 'transparent'}
                strokeWidth={1}
              />
            ) : (
              <Fragment key={days[item].color + idx}>
                <rect
                  x={x + 10 + daysBoxWidth * 2 * idx + (idx > 0 ? 10 * idx : 0)}
                  y={y + height - daysBoxHeight * 2 - 10}
                  height={daysBoxHeight * 2}
                  width={daysBoxWidth * 2}
                  stroke={days[item].stroke || days[item].color}
                  fill={days[item].color || 'transparent'}
                  strokeWidth={1}
                ></rect>
                <text
                  x={x + 10 + daysBoxWidth + daysBoxWidth * 2 * idx + (idx > 0 ? 10 * idx : 0)}
                  y={y + height - 10 - daysBoxHeight}
                  dominantBaseline='middle'
                  fill={days[item].color && days[item].color !== 'transparent' ? 'black' : 'white'}
                  textAnchor='middle'
                  fontSize='10'
                >
                  {days[item].text}
                </text>
              </Fragment>
            )
          })}
        </>
      )}
    </>
  )
}
