import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {DraftJsRenderer} from '../../../../components/utils/DraftJsRenderer'
import {ForcedLandScape} from '../ForcedLandScape/LandscapeFullScreen'
import styles from './FullScreenPresentationSlides.module.scss'
import {PresentationSlide} from './PresentationSlide'

export interface FullScreenPresentationSlidesProps {
  items: PresentationItem[]
  currentPageIndex: number
  onPageChange: (pageIndex: number) => void
  allowSkips?: boolean
  onExit?: () => void
  onStop?: () => void
}

export interface PresentationItem {
  notes?: string | null
  image: ImageInputValue
}

export const FullScreenPresentationSlides = ({
  currentPageIndex,
  items,
  onPageChange,
  allowSkips,
  onExit,
  onStop,
}: FullScreenPresentationSlidesProps) => {
  const getImageClickHandler = useCallback(
    (pageIndex: number) => () => {
      if (allowSkips) {
        onPageChange(pageIndex)
      }
    },
    [allowSkips, onPageChange]
  )

  const currentItem = useMemo(
    (): PresentationItem | undefined => items[currentPageIndex],
    [currentPageIndex, items]
  )

  const hasNextPage = useMemo(
    () => currentPageIndex < items.length - 1,
    [currentPageIndex, items.length]
  )

  const hasPreviousPage = useMemo(() => currentPageIndex > 0, [currentPageIndex])

  const decrementSlider = useCallback(() => {
    if (hasPreviousPage) {
      onPageChange(currentPageIndex - 1)
    }
  }, [currentPageIndex, hasPreviousPage, onPageChange])

  const incrementSlider = useCallback(() => {
    if (hasNextPage) {
      onPageChange(currentPageIndex + 1)
    }
  }, [currentPageIndex, hasNextPage, onPageChange])

  return (
    <ForcedLandScape className={styles.root}>
      <div className='d-flex flex-column h-100'>
        <div className={styles.main}>
          <div className={clsx(styles.noteContainer, 'bg-body border-bottom overflow-auto')}>
            <div className='d-flex border-bottom p-5 pb-3'>
              {onStop && (
                <MetronicIconButton
                  variant='text'
                  iconType='Electric'
                  iconName='Shutdown'
                  size='lg'
                  color='danger'
                  tooltip='End Presentation'
                  onClick={onStop}
                />
              )}
            </div>
            <div className='p-5'>
              <DraftJsRenderer>{currentItem?.notes}</DraftJsRenderer>
            </div>
          </div>
          <PresentationSlide
            className={styles.currentImageContainer}
            hideNext={!hasNextPage}
            hidePrev={!hasPreviousPage}
            imageSrc={currentItem?.image.url}
            onNextClick={incrementSlider}
            onPreviousClick={decrementSlider}
            onExit={onExit}
          />
        </div>
        <div className={clsx(styles.imageContainer, 'bg-body')}>
          {items.map((item, i) => {
            const isCurrentPage = i === currentPageIndex
            return (
              <img
                role='button'
                onClick={getImageClickHandler(i)}
                className={clsx(styles.image, 'rounded-1', {
                  'bg-light-primary': !isCurrentPage,
                  'opacity-25': !isCurrentPage,
                  'bg-primary': isCurrentPage,
                })}
                key={i}
                src={item.image.url}
                alt={item.image.filename}
              />
            )
          })}
        </div>
      </div>
    </ForcedLandScape>
  )
}
