import Swal, {SweetAlertResult} from 'sweetalert2'
import {createPortal} from 'react-dom'
import withReactContent, {ReactSweetAlert, ReactSweetAlertOptions} from 'sweetalert2-react-content'
import {ReactNode, useState} from 'react'
import {useOnUnmount} from '../hooks/useOnUnmount'
import {useOnChange} from '../hooks/useOnChange'
import clsx from 'clsx'

export const GlobalSweetAlertReact = withReactContent(Swal).mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-flush me-5',
    cancelButton: 'btn btn-danger',
  },
}) as typeof Swal & ReactSweetAlert

const noAnimationClass = {
  backdrop: 'swal2-noanimation', // disable backdrop animation
  popup: '', // disable popup animation
  icon: '', // disable icon animation
}

export interface SweetAlertProps extends ReactSweetAlertOptions {
  children?: ReactNode
  open: boolean
  onClose?: (result: SweetAlertResult) => void
  containerClassName?: string
  noShowAnimation?: boolean
  noHideAnimation?: boolean
  noPadding?: boolean
}

export const SweetAlert = ({
  children,
  open,
  onClose,
  containerClassName,
  noShowAnimation,
  noHideAnimation,
  noPadding,
  ...swalOptions
}: SweetAlertProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [result, setResult] = useState<SweetAlertResult | null>(null)
  const [isMounted, setIsMounted] = useState(false) // Add this state

  useOnChange(open, () => {
    if (open) {
      // setTimeout here is a hack to prevent race conditions on multiple modals
      // causing newly opened modals to close immediately since another modal
      // calls Swal.close() after a new one just opened.
      // This timeout will always make sure open() is called after close()
      // This is very ugly and can be prone to unexpected bugs. It should be refactored soon.
      setIsMounted(true) // Component is mounted
      setTimeout(() => {
        const options: ReactSweetAlertOptions = {
          html: swalOptions.text ? undefined : (
            <div
              className={clsx('swal2-html-portal', containerClassName)}
              ref={(el) => setContainer(el)}
            />
          ),
          ...swalOptions,
          customClass: {
            ...swalOptions.customClass,
            htmlContainer: clsx(swalOptions.customClass?.htmlContainer, {'m-0': noPadding}),
            popup: clsx(swalOptions.customClass?.popup, {'p-0': noPadding}),
          },
        }

        if (noShowAnimation) {
          options.showClass = {
            ...noAnimationClass,
            ...swalOptions.showClass,
          }
        }
        if (noHideAnimation) {
          options.hideClass = {
            ...noAnimationClass,
            ...swalOptions.hideClass,
          }
        }

        GlobalSweetAlertReact.fire(options).then((result) => {
          setResult(result)
        })
      }, 1)
    } else if (!open) {
      setIsMounted(false) // Component is unmounted
      GlobalSweetAlertReact.isVisible() && GlobalSweetAlertReact.close()
    }
  })

  useOnChange(result, () => {
    if (result) {
      onClose && onClose(result)
    }
  })

  useOnUnmount(() => {
    setIsMounted(false) // Component is unmounted
    GlobalSweetAlertReact.isVisible() && GlobalSweetAlertReact.close()
  })

  if (!isMounted) {
    return null // Don't render anything if the component is unmounted
  }

  if (container) {
    return createPortal(children, container)
  }

  return null
}
