import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {FilterModel} from '../../../../models/FilterModel'
import {GateModel, GateModelCreateParams} from '../../../../models/acs/GateModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {LocationModel} from '../../../../models/acs/LocationModel'
import {VenueModel} from '../../../../models/acs/VenueModel'
import {
  DeleteGates,
  GetGates,
  GetLocations,
  GetParentLocationByVenue,
  GetParentLocations,
  GetParentVenues,
  GetParentVenueTree,
  GetVenues,
  PutGate,
} from './AcsCRUD'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

interface IPagesState {
  gates?: GlobalSearchModel<GateModel>
  locations?: GlobalSearchModel<LocationModel>
  venues?: GlobalSearchModel<VenueModel>
  parentGates?: GateModel[]
  parentLocationTree?: VenueModel[]
  parentLocations?: LocationModel[]
  parentVenueTree?: VenueModel[]
  parentVenues?: VenueModel[]
}

const initialAuthState: IPagesState = {
  gates: undefined,
  locations: undefined,
  venues: undefined,
  parentGates: [],
  parentLocationTree: [],
  parentLocations: [],
  parentVenueTree: [],
  parentVenues: [],
}

// ACTION TYPES
const actionTypes = {
  // GATE
  CREATE_GATE: '[ACS] CREATE GATE',
  CREATE_GATE_SUCCESS: '[ACS] CREATE GATE SUCCESS',
  CREATE_GATE_FAILED: '[ACS] CREATE GATE FAILED',
  UPDATE_GATE: '[ACS] UPDATE GATE',
  UPDATE_GATE_SUCCESS: '[ACS] UPDATE GATE SUCCESS',
  UPDATE_GATE_FAILED: '[ACS] UPDATE GATE FAILED',
  DELETE_GATE: '[ACS] DELETE GATE',
  DELETE_GATE_SUCCESS: '[ACS] DELETE GATE SUCCESS',
  DELETE_GATE_FAILED: '[ACS] DELETE GATE FAILED',
  SEARCH_GATE: '[ACS] SEARCH GATE',
  SEARCH_GATE_SUCCESS: '[ACS] SEARCH GATE SUCCESS',
  SEARCH_GATE_FAILED: '[ACS] SEARCH GATE FAILED',

  // CREATE LOCATION
  CREATE_LOCATION: '[ACS] CREATE LOCATION',
  CREATE_LOCATION_SUCCESS: '[ACS] CREATE LOCATION SUCCESS',
  CREATE_LOCATION_FAILED: '[ACS] CREATE LOCATION FAILED',

  // VENUE
  CREATE_VENUE: '[ACS] CREATE VENUE',
  CREATE_VENUE_SUCCESS: '[ACS] CREATE VENUE SUCCESS',
  CREATE_VENUE_FAILED: '[ACS] CREATE VENUE FAILED',

  UPDATE_VENUE: '[ACS] UPDATE VENUE',
  UPDATE_VENUE_SUCCESS: '[ACS] UPDATE VENUE SUCCESS',
  UPDATE_VENUE_FAILED: '[ACS] UPDATE VENUE FAILED',

  DELETE_VENUE: '[ACS] DELETE VENUE',
  DELETE_VENUE_SUCCESS: '[ACS] DELETE VENUE SUCCESS',
  DELETE_VENUE_FAILED: '[ACS] DELETE VENUE FAILED',

  // UPDATE LOCATION
  UPDATE_LOCATION: '[ACS] UPDATE LOCATION',
  UPDATE_LOCATION_SUCCESS: '[ACS] UPDATE LOCATION SUCCESS',
  UPDATE_LOCATION_FAILED: '[ACS] UPDATE LOCATION FAILED',

  // DELETE LOCATION
  DELETE_LOCATION: '[ACS] DELETE LOCATION',
  DELETE_LOCATION_SUCCESS: '[ACS] DELETE LOCATION SUCCESS',
  DELETE_LOCATION_FAILED: '[ACS] DELETE LOCATION FAILED',

  GET_PARENT_GATE: '[ACS] GET PARENT GATE',
  GET_PARENT_GATE_SUCCESS: '[ACS] GET PARENT GATE SUCCESS',
  GET_PARENT_GATE_FAILED: '[ACS] GET PARENT GATE FAILED',

  GET_PARENT_LOCATION: '[ACS] GET PARENT LOCATION',
  GET_PARENT_LOCATION_SUCCESS: '[ACS] GET PARENT LOCATION SUCCESS',
  GET_PARENT_LOCATION_FAILED: '[ACS] GET PARENT LOCATION FAILED',

  GET_PARENT_LOCATION_TREE: '[ACS] GET PARENT LOCATION TREE',
  GET_PARENT_LOCATION_TREE_SUCCESS: '[ACS] GET PARENT LOCATION TREE SUCCESS',
  GET_PARENT_LOCATION_TREE_FAILED: '[ACS] GET PARENT LOCATION TREE FAILED',

  GET_PARENT_VENUE: '[ACS] GET PARENT VENUE',
  GET_PARENT_VENUE_SUCCESS: '[ACS] GET PARENT VENUE SUCCESS',
  GET_PARENT_VENUE_FAILED: '[ACS] GET PARENT VENUE FAILED',

  GET_PARENT_VENUE_TREE: '[ACS] GET PARENT VENUE TREE',
  GET_PARENT_VENUE_TREE_SUCCESS: '[ACS] GET PARENT VENUE TREE SUCCESS',
  GET_PARENT_VENUE_TREE_FAILED: '[ACS] GET PARENT VENUE TREE FAILED',

  SEARCH_LOCATION: '[ACS] SEARCH LOCATION',
  SEARCH_LOCATION_SUCCESS: '[ACS] SEARCH LOCATION SUCCESS',
  SEARCH_LOCATION_FAILED: '[ACS] SEARCH LOCATION FAILED',

  SEARCH_VENUE: '[ACS] SEARCH VENUE',
  SEARCH_VENUE_SUCCESS: '[ACS] SEARCH VENUE SUCCESS',
  SEARCH_VENUE_FAILED: '[ACS] SEARCH VENUE FAILED',
}

// REDUCERS
export const reducer = persistReducer(
  {storage, key: 'webapp-acs', whitelist: []},
  (state: IPagesState = initialAuthState, action: ActionWithPayload<IPagesState>) => {
    switch (action.type) {
      case actionTypes.GET_PARENT_GATE_SUCCESS: {
        const parentGates = action.payload?.parentGates
        return {...state, parentGates}
      }
      case actionTypes.GET_PARENT_LOCATION_SUCCESS: {
        const parentLocations = action.payload?.parentLocations
        return {...state, parentLocations}
      }
      case actionTypes.GET_PARENT_LOCATION_TREE_SUCCESS: {
        const parentLocationTree = action.payload?.parentLocationTree
        return {...state, parentLocationTree}
      }
      case actionTypes.GET_PARENT_VENUE_SUCCESS: {
        const parentVenues = action.payload?.parentVenues
        return {...state, parentVenues}
      }
      case actionTypes.GET_PARENT_VENUE_TREE_SUCCESS: {
        const parentVenueTree = action.payload?.parentVenueTree
        return {...state, parentVenueTree}
      }
      case actionTypes.SEARCH_VENUE_SUCCESS: {
        const venues = action.payload?.venues
        return {...state, venues}
      }
      case actionTypes.SEARCH_LOCATION_SUCCESS: {
        const locations = action.payload?.locations
        return {...state, locations}
      }
      case actionTypes.SEARCH_GATE_SUCCESS: {
        const gates = action.payload?.gates
        return {...state, gates}
      }
      default:
        return state
    }
  }
)

// ACTIONS
export const actions = {
  // GATE
  createGate: (data: GateModelCreateParams) => ({type: actionTypes.CREATE_GATE, payload: data}),
  createGateFailed: () => ({type: actionTypes.CREATE_GATE_FAILED}),
  createGateSuccess: () => ({type: actionTypes.CREATE_GATE_SUCCESS}),
  updateGate: (data: GateModelCreateParams, gateCode: string) => ({
    type: actionTypes.UPDATE_GATE,
    payload: {
      data,
      code: gateCode,
    },
  }),
  updateGateFailed: () => ({type: actionTypes.UPDATE_GATE_FAILED}),
  updateGateSuccess: () => ({type: actionTypes.UPDATE_GATE_SUCCESS}),
  deleteGate: (gateCode: string | string[]) => ({type: actionTypes.DELETE_GATE, payload: gateCode}),
  deleteGateFailed: () => ({type: actionTypes.DELETE_GATE_FAILED}),
  deleteGateSuccess: () => ({type: actionTypes.DELETE_GATE_SUCCESS}),
  searchGate: () => ({type: actionTypes.SEARCH_GATE}),
  searchGateFailed: () => ({type: actionTypes.SEARCH_GATE_FAILED}),
  searchGateSuccess: (payload: GlobalSearchModel<GateModel>) => ({
    type: actionTypes.SEARCH_GATE_SUCCESS,
    payload: {gates: payload},
  }),

  // CREATE LOCATION
  createLocation: () => ({type: actionTypes.CREATE_LOCATION}),
  createLocationFailed: () => ({type: actionTypes.CREATE_LOCATION_FAILED}),
  createLocationSuccess: () => ({type: actionTypes.CREATE_LOCATION_SUCCESS}),

  // CREATE VENUE
  createVenue: () => ({type: actionTypes.CREATE_VENUE}),
  createVenueFailed: () => ({type: actionTypes.CREATE_VENUE_FAILED}),
  createVenueSuccess: () => ({type: actionTypes.CREATE_VENUE_SUCCESS}),

  // UPDATE VENUE
  updateVenue: () => ({type: actionTypes.UPDATE_VENUE}),
  updateVenueFailed: () => ({type: actionTypes.UPDATE_VENUE_FAILED}),
  updateVenueSuccess: () => ({type: actionTypes.UPDATE_VENUE_SUCCESS}),

  // DELETE VENUE
  deleteVenue: () => ({type: actionTypes.DELETE_VENUE}),
  deleteVenueFailed: () => ({type: actionTypes.DELETE_VENUE_FAILED}),
  deleteVenueSuccess: () => ({type: actionTypes.DELETE_VENUE_SUCCESS}),

  // DELETE LOCATION
  deleteLocation: () => ({type: actionTypes.DELETE_LOCATION}),
  deleteLocationFailed: () => ({type: actionTypes.DELETE_LOCATION_FAILED}),
  deleteLocationSuccess: () => ({type: actionTypes.DELETE_LOCATION_SUCCESS}),

  // GET PARENT GATE
  getParentGate: () => ({type: actionTypes.GET_PARENT_GATE}),
  getParentGateFailed: () => ({type: actionTypes.GET_PARENT_GATE_FAILED}),
  getParentGateSuccess: (parentGates: GateModel[]) => ({
    type: actionTypes.GET_PARENT_GATE_SUCCESS,
    payload: {parentGates},
  }),

  // GET PARENT LOCATION
  getParentLocation: () => ({type: actionTypes.GET_PARENT_LOCATION}),
  getParentLocationFailed: () => ({type: actionTypes.GET_PARENT_LOCATION_FAILED}),
  getParentLocationSuccess: (parentLocations: LocationModel[]) => ({
    type: actionTypes.GET_PARENT_LOCATION_SUCCESS,
    payload: {parentLocations},
  }),

  // GET PARENT LOCATION TREE
  getParentLocationTree: () => ({type: actionTypes.GET_PARENT_LOCATION_TREE}),
  getParentLocationTreeFailed: () => ({type: actionTypes.GET_PARENT_LOCATION_TREE_FAILED}),
  getParentLocationTreeSuccess: (parentLocationTree: VenueModel[]) => ({
    type: actionTypes.GET_PARENT_LOCATION_TREE_SUCCESS,
    payload: {parentLocationTree},
  }),

  // GET PARENT VENUE
  getParentVenue: () => ({type: actionTypes.GET_PARENT_VENUE}),
  getParentVenueFailed: () => ({type: actionTypes.GET_PARENT_VENUE_FAILED}),
  getParentVenueSuccess: (parentVenues: VenueModel[]) => ({
    type: actionTypes.GET_PARENT_VENUE_SUCCESS,
    payload: {parentVenues},
  }),

  // GET PARENT VENUE
  getParentVenueTree: () => ({type: actionTypes.GET_PARENT_VENUE_TREE}),
  getParentVenueTreeFailed: () => ({type: actionTypes.GET_PARENT_VENUE_TREE_FAILED}),
  getParentVenueTreeSuccess: (parentVenueTree: VenueModel[]) => ({
    type: actionTypes.GET_PARENT_VENUE_TREE_SUCCESS,
    payload: {parentVenueTree},
  }),

  // SEARCH LOCATION
  searchLocation: () => ({type: actionTypes.SEARCH_LOCATION}),
  searchLocationFailed: () => ({type: actionTypes.SEARCH_LOCATION_FAILED}),
  searchLocationSuccess: (locations: GlobalSearchModel<LocationModel>) => ({
    type: actionTypes.SEARCH_LOCATION_SUCCESS,
    payload: {locations},
  }),

  // SEARCH VENUE
  searchVenue: () => ({type: actionTypes.SEARCH_VENUE}),
  searchVenueFailed: () => ({type: actionTypes.SEARCH_VENUE_FAILED}),
  searchVenueSuccess: (venues: GlobalSearchModel<VenueModel>) => ({
    type: actionTypes.SEARCH_VENUE_SUCCESS,
    payload: {venues},
  }),
}

// AFTER EFFECTS
export function* saga() {
  // GATE
  yield takeLatest(
    [
      actionTypes.CREATE_GATE_SUCCESS,
      actionTypes.UPDATE_GATE_SUCCESS,
      actionTypes.DELETE_GATE_SUCCESS,
    ],
    function* afterEffectSaga() {
      yield put(actions.searchGate())
    }
  )
  yield takeLatest(actionTypes.SEARCH_GATE, function* afterEffectSaga() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['gate'])
      const {data} = yield GetGates(filter)
      yield put(actions.searchGateSuccess(data))
    } catch (e) {
      yield put(actions.searchGateFailed())
    }
  })
  yield takeLatest(
    actionTypes.UPDATE_GATE,
    function* afterEffectSaga(
      action: Required<ActionWithPayload<{data: GateModelCreateParams; code: string}>>
    ) {
      try {
        yield PutGate(action.payload.data, action.payload.code)
        yield put(actions.updateGateSuccess())
      } catch (e) {
        yield put(actions.updateGateFailed())
      }
    }
  )
  yield takeLatest(
    actionTypes.DELETE_GATE,
    function* afterEffectSaga({payload}: Required<ActionWithPayload<string | string[]>>) {
      try {
        yield DeleteGates(Array.isArray(payload) ? payload : [payload])
        yield put(actions.deleteGateSuccess())
      } catch (e) {
        yield put(actions.deleteGateFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.UPDATE_LOCATION_SUCCESS,
      actionTypes.DELETE_LOCATION_SUCCESS,
      actionTypes.CREATE_LOCATION_SUCCESS,
    ],
    function* refreshLocations() {
      yield put(actions.searchLocation())
      yield put(actions.getParentLocation())
      yield put(actions.getParentLocationTree())
    }
  )

  yield takeLatest(actionTypes.SEARCH_LOCATION, function* searchLocation() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['location'])
      const {data} = yield GetLocations(filter)
      yield put(actions.searchLocationSuccess(data))
    } catch (e) {
      yield put(actions.searchLocationFailed())
    }
  })

  yield takeLatest(actionTypes.GET_PARENT_LOCATION, function* getParentLocation() {
    try {
      const {data} = yield GetParentLocations()
      yield put(actions.getParentLocationSuccess(data))
    } catch (e) {
      yield put(actions.getParentLocationFailed())
    }
  })

  yield takeLatest(actionTypes.GET_PARENT_LOCATION_TREE, function* getParentLocationTree() {
    try {
      const {data} = yield GetParentLocationByVenue()
      yield put(actions.getParentLocationTreeSuccess(data))
    } catch (e) {
      yield put(actions.getParentLocationTreeFailed())
    }
  })

  // VENUE
  yield takeLatest(actionTypes.SEARCH_VENUE, function* searchVenue() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['venue'])
      const {data} = yield GetVenues(filter)
      yield put(actions.searchVenueSuccess(data))
    } catch (e) {
      yield put(actions.searchVenueFailed())
    }
  })

  // PARENT VENUE

  yield takeLatest(actionTypes.GET_PARENT_VENUE, function* refreshParentVenues() {
    try {
      const {data: parentVenues} = yield GetParentVenues()
      yield put(actions.getParentVenueSuccess(parentVenues))
    } catch (e) {
      yield put(actions.getParentVenueFailed())
    }
  })

  yield takeLatest(actionTypes.GET_PARENT_VENUE_TREE, function* refreshParentVenues() {
    try {
      const {data: parentVenueTree} = yield GetParentVenueTree()
      yield put(actions.getParentVenueTreeSuccess(parentVenueTree))
    } catch (e) {
      yield put(actions.getParentVenueTreeFailed())
    }
  })

  yield takeLatest(
    [
      actionTypes.CREATE_VENUE_SUCCESS,
      actionTypes.UPDATE_VENUE_SUCCESS,
      actionTypes.DELETE_VENUE_SUCCESS,
    ],
    function* refreshVenues() {
      yield put(actions.searchVenue())
      yield put(actions.getParentVenue())
    }
  )
}
