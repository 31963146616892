import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {Fragment, ReactNode, useMemo} from 'react'
import {AsideMenuTitle} from './AsideMenuTitle'
import {NavigationSection} from '../../../../../config/drawer'

export interface AsideMenuProps {
  sections: NavigationSection[]
}

export function AsideMenu({sections}: AsideMenuProps) {
  const intl = useIntl()

  const sectionNodes = useMemo(() => {
    return sections.map((section, i) => {
      const menus: ReactNode[] = []

      if (section.menus) {
        section.menus.forEach((menu) => {
          const menuItems: ReactNode[] = []
          const menuTitle = menu.intlId ? intl.formatMessage({id: menu.intlId}) : menu.title

          if (menu.subMenus) {
            menu.subMenus.forEach((menu) => {
              menuItems.push(
                <AsideMenuItem
                  hasBullet
                  key={`${menu?.title}${menu.to}`}
                  to={menu.to || '#'}
                  title={menu.title || ''}
                />
              )
            })
          }

          if (menuItems.length) {
            menus.push(
              <AsideMenuItemWithSub
                key={`${menu.title}${menu.intlId}${menu.to}`}
                title={menuTitle || ''}
                to={menu.to || '#'}
                icon={menu.icon}
                hasBullet={!menu.icon}
              >
                {menuItems}
              </AsideMenuItemWithSub>
            )
          } else {
            menus.push(
              <AsideMenuItem
                icon={menu.icon}
                hasBullet={!menu.icon}
                key={`${menu.title}${menu.intlId}${menu.to}`}
                to={menu.to || '#'}
                title={menuTitle || ''}
              />
            )
          }
        })
      }

      return (
        <Fragment key={i}>
          {section.title && <AsideMenuTitle>{section.title}</AsideMenuTitle>}
          {menus}
        </Fragment>
      )
    }, [])
  }, [intl, sections])

  return <>{sectionNodes}</>
}
