import {useCallback} from 'react'
import {Button, ButtonProps} from './Button'

export interface DataButtonProps<T> extends Omit<ButtonProps, 'onClick'> {
  data?: T
  onClick?: (data: T) => void
}

export const DataButton = <T,>({data, onClick, ...buttonProps}: DataButtonProps<T>) => {
  const handleClick = useCallback(() => {
    data && onClick && onClick(data)
  }, [data, onClick])

  if (!data) {
    return null
  }

  return <Button onClick={handleClick} {...buttonProps} />
}
