import {useHistory} from 'react-router-dom'
import {EventModel} from '../../../../models/ems/EventModel'
import {useCallback, useMemo} from 'react'
import {Paper} from '../../../../components/utils/Paper'
import {LinkBreadCrumb} from '../../components/LinkBreadcrumbsPortalToolbar'
import {LinkBreadcrumbsToolbar} from '../../../../components/layouts/DefaultLayout/LinkBreadcrumbsToolbar'
import {ReservationCreateWizard} from '../../components/wizards/ReservationWizard/ReservationCreateWizard'

interface EventPortalDetailReservationCreateProps {
  event?: EventModel | null
  step?: number
}
export const EventPortalDetailReservationCreate = ({
  event,
  step,
}: EventPortalDetailReservationCreateProps) => {
  const history = useHistory()

  const breadcrumbs = useMemo(() => {
    const breadcrumbs: LinkBreadCrumb[] = [
      {
        label: 'Events',
        link: '/event',
      },
    ]
    if (event) {
      breadcrumbs.push({label: event.name, link: `/event/${event.code}`})
      breadcrumbs.push({label: 'Reservations', link: `/event/${event.code}/reservation`})
      breadcrumbs.push({label: 'Create'})
    }
    return breadcrumbs
  }, [event])

  const handleSuccess = useCallback(() => {
    if (event) {
      history.replace(`/event/${event.code}/reservation`)
    }
  }, [event, history])

  return (
    <>
      <LinkBreadcrumbsToolbar title='Create Reservation' breadcrumbs={breadcrumbs} />
      <div className='container-fluid mx-0 px-0'>
        <div className='position-relative rs-wizard rs-table-bg'>
          <div className='wizard-cut-corner-polygon rs-gradient'></div>
          <Paper rounded className='py-10'>
            <ReservationCreateWizard onSuccess={handleSuccess} event={event} step={step} isNew />
          </Paper>
        </div>
      </div>
    </>
  )
}
