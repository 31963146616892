import {ReactNode, useMemo} from 'react'
import {SweetAlertCustomClass} from 'sweetalert2'
import {useBreakpoint} from '../hooks/useBreakpoint'
import {SweetAlert} from './SweetAlert'

export interface ResponsiveModalProps {
  children?: ReactNode
  open: boolean
  size?: 'default' | 'big' | 'auto'
  onClose: () => void
  noShowAnimation?: boolean
  noHideAnimation?: boolean
  showCloseButton?: boolean
  noPadding?: boolean
}

export const ResponsiveModal = ({
  onClose,
  open,
  children,
  size,
  noShowAnimation,
  noHideAnimation,
  noPadding,
}: ResponsiveModalProps) => {
  const {down} = useBreakpoint()

  const isFullScreen = useMemo(() => {
    return down('md')
  }, [down])

  const customClass = useMemo(() => {
    if (!isFullScreen && size === 'big') {
      return BIG_SIZE_CLASS
    }
    if (!isFullScreen && size === 'auto') {
      return AUTO_HEIGHT_SIZE_CLASS
    }
  }, [size, isFullScreen])

  return (
    <SweetAlert
      noPadding={noPadding}
      open={open}
      onClose={onClose}
      customClass={customClass}
      grow={isFullScreen ? 'fullscreen' : false}
      showConfirmButton={false}
      noShowAnimation={noShowAnimation}
      noHideAnimation={noHideAnimation}
    >
      {children}
    </SweetAlert>
  )
}

const BIG_SIZE_CLASS: SweetAlertCustomClass = {
  popup: 'swal2-popup__big',
}
const AUTO_HEIGHT_SIZE_CLASS: SweetAlertCustomClass = {
  popup: 'swal2-popup__autoHeight',
}
