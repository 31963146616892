import {useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {SeatMapSelectionModalInput} from '../SeatMap/SeatMapSelectionModalInput'
import {ReasonModal} from '../../../../components/modals/ReasonModal'
import {useModalState} from '../../../../components/modals/useModalState'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'

export interface TicketSeatModalFormValues {
  reason: string
  locationCode: string
  ticketCode: string
  row?: string
  column?: number
}

export interface TicketSeatModalFormProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: Required<TicketSeatModalFormValues>) => void | Promise<void>
  ticket?: TicketPortalModel | null
}

export const TicketSeatModalForm = ({
  onClose,
  onSubmit,
  open,
  ticket,
}: TicketSeatModalFormProps) => {
  const {
    hide: hideSelectionModal,
    isOpen: isSelectionOpen,
    open: openSelectionModal,
  } = useModalState()
  const [selected, setSelected] = useState<SeatMapValue>()
  const {pushError} = useAlerts()

  useOnChange(isSelectionOpen, () => {
    if (!isSelectionOpen) setSelected(undefined)
  })

  const formik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    onSubmit: async ({...values}, {setSubmitting, setFieldValue}) => {
      if (selected) {
        const [[row, [column]]] = Object.entries(selected?.getSeatMapObject())
        if (row && column) {
          try {
            setSubmitting(true)
            onSubmit && (await onSubmit({row, column, ...values}))
            onClose()
            hideSelectionModal()
          } catch (e) {
            pushError(e)
            if (ticket) {
              setFieldValue('column', undefined)
              setFieldValue('row', undefined)
            }
          } finally {
            setSubmitting(false)
          }
        }
      }
    },
  })

  const handleReasonClose = useCallback(
    (value?: string) => {
      if (value) {
        formik.setFieldValue('reason', value)
        openSelectionModal()
      } else {
        onClose()
      }
    },
    [formik, onClose, openSelectionModal]
  )

  const handleSelectionModalHide = useCallback(() => {
    hideSelectionModal()
    onClose()
  }, [hideSelectionModal, onClose])

  useOnChange(ticket, () => {
    if (ticket) {
      formik.setValues({
        locationCode: ticket.locationCode || '',
        reason: '',
        ticketCode: ticket.code,
      })
      formik.setFieldValue('column', undefined)
      formik.setFieldValue('row', undefined)
    }
  })

  return (
    <>
      <ReasonModal
        title='Reassign Seat'
        description='Please specify a reason.'
        open={open && !isSelectionOpen}
        onClose={handleReasonClose}
      />

      <SeatMapSelectionModalInput
        open={isSelectionOpen}
        disableSubmit={formik.isSubmitting}
        disableSelection={formik.isSubmitting}
        onSubmit={formik.handleSubmit}
        onHide={handleSelectionModalHide}
        ticket={ticket}
        setSelected={setSelected}
        selected={selected}
      />
    </>
  )
}

const EMPTY_INITIAL_VALUES: TicketSeatModalFormValues = {
  locationCode: '',
  reason: '',
  ticketCode: '',
}
