import {useLocation} from 'react-router-dom'
import {useCallback} from 'react'

export const useHeaderLocation = () => {
  const location = useLocation()
  return useCallback(
    (path: string) => {
      return location.pathname === path
    },
    [location.pathname]
  )
}
