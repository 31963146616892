import {FormikContextType} from 'formik'
import {
  BookingWizardBulkCustomerStepFormValues,
  BulkConsolidatedFormValues,
} from '../../../../../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {EventModel} from '../../../../../../../models/ems/EventModel'
import {ActivityModel} from '../../../../../../../models/ems/ActivityModel'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../../setup'
import {useBookingFormData} from '../../hook/useBookingFormData'
import {BookingWizardSharedConsolidatedCustomerStep} from '../../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedConsolidatedCustomerStep'

export interface BookingWizardBulkConsolidatedCustomerStepProps<
  T extends BookingWizardBulkCustomerStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | ActivityModel | null
  disabledFields?: Partial<Record<keyof BookingWizardBulkCustomerStepFormValues, boolean>>
  isEdit?: boolean
  onNoCustonmers?: (is: boolean) => void
  onStepChange?: (step: number) => void
}

export const BookingWizardBulkConsolidatedCustomerStep = <
  T extends BookingWizardBulkCustomerStepFormValues
>({
  formik,
  event,
  isEdit,
  onNoCustonmers,
  onStepChange,
}: BookingWizardBulkConsolidatedCustomerStepProps<T>) => {
  const bookingBulkForm: BulkConsolidatedFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingBulkConsolidatedForm
  ) as BulkConsolidatedFormValues

  const {getCustomers, customers} = useBookingFormData({})

  return (
    <>
      <BookingWizardSharedConsolidatedCustomerStep
        formik={formik}
        bookingBulkForm={bookingBulkForm}
        onNoCustonmers={onNoCustonmers}
        onStepChange={onStepChange}
        customers={customers}
        searchCustomers={getCustomers}
        isEdit={isEdit}
        isPortal={true}
      />
    </>
  )
}
