import React, { createContext, ReactNode, useContext, useState } from "react";

interface AuthStateContextType {
    userType: string
    setUserType: (data: string) => void
    acctCode: string
    setAccountCode: (data: string) => void
}

const AuthStateContext = createContext<AuthStateContextType | undefined>(undefined)

export const AuthStateProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [userType, setUserType] = useState('')
    const [acctCode, setAccountCode] = useState('')

    return (
     <AuthStateContext.Provider
      value={{
        userType,
        setUserType,
        acctCode,
        setAccountCode,
      }}
    >
      {children}
    </AuthStateContext.Provider>
    )
}

export const useAuthState = () => {
    const context = useContext(AuthStateContext)
    if (!context) {
      throw new Error('useAuthState must be used within a AuthStateContext')
    }
    return context
  }