import {ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {HasCode} from '../../../utils/idExtractor'

export interface BookingLinkProps {
  booking?: HasCode
  children: ReactNode
}

export const BookingLink = ({children, booking}: BookingLinkProps) => {
  if (!booking) {
    return null
  }
  return <Link to={`/tickets/?search=${booking.code}`}>{children}</Link>
}
