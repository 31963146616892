import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {DigitalWaiverStatusColumn} from '../tables/DigitalWaiverStatusColumn'
import {WaiverPortalStatusColumn} from '../tables/WaiverPortalStatusColumn'
import {getWaiverStatusVariant} from './DigitalWaiverCard'
import moment from 'moment'
import styles from './DigitalWaiverCardFrontDetails.module.scss'
import clsx from 'clsx'

interface DigitalWaiverCardFrontDetailsProps {
  data: TicketWaiverModel
  tabCode?: string
  isHovered?: boolean
}

export const DigitalWaiverCardFrontDetails = ({
  data,
  tabCode,
  isHovered,
}: DigitalWaiverCardFrontDetailsProps) => {
  return (
    <div className={clsx('px-4 pt-5 pb-3 w-100 flex-grow-1', styles.root)}>
      <div
        style={{
          backgroundColor: getWaiverStatusVariant({data, tabCode}),
        }}
        className={clsx(styles.frontHorizontalSeparator, {
          [styles.frontHorizontalSeparatorHovered]: isHovered,
        })}
      ></div>
      {data.productName && (
        <p className='text-start ff-admm-bold text-white fs-3 mb-0'>{data.productName}</p>
      )}

      {data.startedAt && (
        <div>
          <p className='fs-7 text-white mb-0 pb-1'>{`Experience Date&Time: ${moment(
            data.startedAt
          ).format('DD MMM YYYY')} | ${moment(data.startedAt).format('LT')}`}</p>
        </div>
      )}

      {tabCode === 'shared' && data.delegateReceiverCustomerName && (
        <div>
          <p className='fs-7 text-white mb-0 pb-1'>{`Shared with: ${data.delegateReceiverCustomerName}`}</p>
        </div>
      )}

      {tabCode === 'available' ? (
        <>
          {data.dwStatus ? (
            <div>
              <p className='fs-7 text-white  mb-0 pb-1'>
                <span>Status:</span>
                <span className='ps-2'>
                  <DigitalWaiverStatusColumn data={data} tabCode={tabCode} />
                </span>
              </p>
            </div>
          ) : (
            <div>
              <p className='fs-7 text-white  mb-0 pb-1'>
                <span>Status:</span>
                <span className='ps-3'>
                  <WaiverPortalStatusColumn data={data} tabCode={tabCode} />
                </span>
              </p>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}
