import {useCallback, useMemo} from 'react'
import {FileInputValue} from '../../../components/inputs/FileInput/FileInputValue'
import * as yup from 'yup'
import {concat} from 'lodash'
import {PokenModel} from '../../../models/acs/PokenModel'

export interface PokenContentManagerCreateParams {
  pokenEmsFiles: Blob[] | undefined
  pokenPdfs: Blob[] | undefined
  pokenLogo: Blob | undefined
  deletedFileCodes: string[] | undefined
  eventCode: string
  gateCode: string
}

export const useContentManager = () => {
  const getPayload = useCallback(
    (
      {logo, gallery, pdfs}: PokenValues,
      options: {eventCode: string; gateCode: string; editedData?: PokenModel}
    ): PokenContentManagerCreateParams => {
      const photo = logo?.toApiValue() || undefined

      const galleries = gallery?.reduce<Blob[]>((acc, file) => {
        const blob = file.toApiValue()
        if (blob) {
          acc.push(blob)
        }
        return acc
      }, [])

      const files = pdfs?.reduce<Blob[]>((acc, file) => {
        const blob = file.toApiValue()
        if (blob) {
          acc.push(blob)
        }
        return acc
      }, [])

      const deletedGalleryFiles =
        options.editedData?.pokenEmsFiles?.reduce<string[]>((codes, emsFile) => {
          const isDeleted = !gallery?.some((file) => file.id !== emsFile.code)
          if (isDeleted) {
            codes.push(emsFile.code)
          }
          return codes
        }, []) || []

      const deletedPdfsFiles =
        options.editedData?.pokenPdfs?.reduce<string[]>((codes, emsFile) => {
          const isDeleted = !gallery?.some((file) => file.id !== emsFile.code)
          if (isDeleted) {
            codes.push(emsFile.code)
          }
          return codes
        }, []) || []

      const result: PokenContentManagerCreateParams = {
        pokenEmsFiles: galleries,
        pokenPdfs: files,
        pokenLogo: photo,
        deletedFileCodes: concat(deletedGalleryFiles, deletedPdfsFiles),
        eventCode: options.eventCode,
        gateCode: options.gateCode,
      }

      return result
    },
    []
  )

  return useMemo(() => ({getPayload}), [getPayload])
}

export interface PokenValues {
  gallery: FileInputValue[]
  pdfs: FileInputValue[]
  logo: FileInputValue | null
}

export const mapPokenToFormValues = (publicUrl: string, poken: PokenModel): PokenValues => {
  return {
    gallery: poken.pokenEmsFiles?.map((file) => new FileInputValue(publicUrl, file)) || [],
    pdfs: poken.pokenPdfs?.map((file) => new FileInputValue(publicUrl, file)) || [],
    logo: poken.pokenLogo ? new FileInputValue(publicUrl, poken.pokenLogo) : null,
  }
}

export const getPokenFormData = (params: PokenContentManagerCreateParams) => {
  const formData = new FormData()

  formData.append('eventCode', params.eventCode)

  params.pokenEmsFiles?.forEach((file) => {
    formData.append(`pokenEmsFiles`, file)
  })

  params.pokenPdfs?.forEach((file) => {
    formData.append(`pokenEmsFiles`, file)
  })

  formData.append(`pokenLogo`, params.pokenLogo || '')

  params.deletedFileCodes?.forEach((code) => {
    formData.append(`deletedFileCodes[]`, code)
  })

  return formData
}

export const EMPTY_INITIAL_VALUES: PokenValues = {
  gallery: [],
  pdfs: [],
  logo: null,
}

export const validationSchema = yup.object().shape({
  gallery: yup.array().of(yup.object().nullable(true)).nullable(true),
  pdfs: yup.array().of(yup.object().nullable(true)).nullable(true),
  logo: yup.object().nullable(true),
})
