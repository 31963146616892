import {Component, ReactNode} from 'react'
import {
  Modal as BootstrapModal,
  ModalProps as BootstrapModalProps,
  ModalBody,
  ModalFooter,
  ModalTitle,
  ModalHeader,
} from 'react-bootstrap'
import {MetronicIconButton} from '../inputs/MetronicIconButton'

export type ModalVariant = 'default' | 'big'

export interface ModalProps {
  header?: ReactNode
  title?: string
  footer?: ReactNode
  children: ReactNode
  className?: string
  open: boolean
  onHide: () => void
  variant?: ModalVariant
}

export class Modal extends Component<ModalProps> {
  private getHeader = () => {
    const {header, title, onHide} = this.props
    if (header || title) {
      return (
        <ModalHeader>
          {title && <ModalTitle>{title}</ModalTitle>}
          {header}
          <MetronicIconButton
            iconType='Navigation'
            iconName='Close'
            variant='text'
            onClick={onHide}
          />
        </ModalHeader>
      )
    }
    return null
  }

  private getFooter = () => {
    const {footer} = this.props
    if (footer) {
      return <ModalFooter>{footer}</ModalFooter>
    }
  }

  private getModalVariantProps = (): Partial<BootstrapModalProps> | null => {
    const {variant = DEFAULT_VARIANT} = this.props
    switch (variant) {
      case 'big':
        return {
          dialogClassName: 'modal-wide',
          contentClassName: 'modal-content-big',
        }
      default:
        return null
    }
  }

  public render = () => {
    const {open, children, onHide} = this.props

    return (
      <BootstrapModal show={open} onHide={onHide} centered {...this.getModalVariantProps()}>
        {this.getHeader()}
        <ModalBody>{children}</ModalBody>
        {this.getFooter()}
      </BootstrapModal>
    )
  }
}

const DEFAULT_VARIANT = 'default'
