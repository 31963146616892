import {getPokenFormData, PokenContentManagerCreateParams} from './../hooks/useContentManager'
import {FilterModel} from './../../../models/FilterModel'
import axios from 'axios'
import {AuthPokenModel} from '../../../models/poken/AuthModel'

const POKEN_AUHT_BASE_PATH = '/auth'
const POKEN_BASE_PATH = '/poken'

export interface EmailTypeParams {
  email: string
  type: string
}

export interface CustomerCodeParams {
  customerCode: string
}

// LOGIN
export const AuthLogin = (username: string, password: string) =>
  axios.post<AuthPokenModel>(`${POKEN_AUHT_BASE_PATH}/login`, {username, password})

export const RequestForgottenPassword = (email: string) =>
  axios.post<AuthPokenModel>(`${POKEN_AUHT_BASE_PATH}/forgot-password`, {email})

export const VerifyCustomerAuthToken = () =>
  axios.get<AuthPokenModel>(`${POKEN_AUHT_BASE_PATH}/verify-token`)

export const ResetPassword = (password: string) =>
  axios.post(`${POKEN_AUHT_BASE_PATH}/reset`, {password})

export const validateMfa = (mfa: string, authToken: string) =>
  axios.post(
    `${POKEN_AUHT_BASE_PATH}/validate-mfa`,
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

export const validateHash = (hash: string) =>
  axios.post(`${POKEN_AUHT_BASE_PATH}/validate-hash`, {hash})

export const loginWithHash = (username: string, password: string, hash: string) =>
  axios.post(`${POKEN_AUHT_BASE_PATH}/login-hash`, {username, password, hash})

export const GetPoken = async (filter: FilterModel) => {
  return await axios.post(`${POKEN_BASE_PATH}/list`, filter)
}

export const GetPokenByCode = async (code: string) => {
  return await axios.get(`${POKEN_BASE_PATH}/${code}`)
}

export const UpdatePoken = async (code: string, payload: PokenContentManagerCreateParams) => {
  const formData = getPokenFormData(payload)

  return await axios.put(`${POKEN_BASE_PATH}/${code}`, formData)
}
