import {ReactNode, createContext, useContext, useState} from 'react'

interface ReservationStateContextType {
  totalCountSelected: number
  setTotalCountSelected: (data: number) => void
}

const ReservationStateContext = createContext<ReservationStateContextType | undefined>(undefined)

export const ReservationStateProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [totalCountSelected, setTotalCountSelected] = useState<number>(0)
  return (
    <ReservationStateContext.Provider value={{totalCountSelected, setTotalCountSelected}}>
      {children}
    </ReservationStateContext.Provider>
  )
}

export const useReservationState = () => {
  const context = useContext(ReservationStateContext)
  if (!context) {
    throw new Error('useBookingState must be used within a BookingStateprovider')
  }
  return context
}
