import {saveAs} from 'file-saver'

export class DeviceUtils {
  public static isApple = () => {
    let isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    let isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform)
    return isMac || isIOS
  }

  public static isiPhone = () => {
    let isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform)
    return isIOS
  }

  public static downloadPkpass = (data: Blob, contentDisposition: string) => {
    if (contentDisposition) {
      let name = contentDisposition
      name = name
        .replace('attachment;', '')
        .replace('_', '')
        .replace(' ', '')
        .replace('filename=', '')
        .replaceAll('"', '')

      saveAs(data, `add to wallet${name}`)
    }
  }
}
