import {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Button} from '../../../../components/inputs/Button'
import {FileInputValue} from '../../../../components/inputs/FileInput/FileInputValue'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {useModalState} from '../../../../components/modals/useModalState'
import {FileModel} from '../../../../models/FileModel'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'

export interface FileListProps {
  files: FileModel[]
  isModal?: boolean
}

export const FileList = ({files, isModal}: FileListProps) => {
  const {
    isOpen: isSelectionModalOpen,
    hide: hideSelectionModal,
    open: openSelectionModal,
  } = useModalState()
  const [fileUrl, setFileUrl] = useState<FileInputValue>()
  const {staticUrls} = useAppConfig()

  const getFileClickHandler = useCallback(
    (file: FileModel) => () => {
      const fileValue = new FileInputValue(staticUrls.public, file)
      window.open(fileValue.url)
    },
    [staticUrls.public]
  )
  const openModalFileClickHandler = useCallback(
    (file: FileModel) => () => {
      const fileValue = new FileInputValue(staticUrls.private, file)
      setFileUrl(fileValue)
      openSelectionModal()
    },
    [openSelectionModal, staticUrls.private]
  )

  const isPdf = useMemo(() => {
    const file = fileUrl?.getFileModel()
    if (file?.type === 'application/pdf') return true
  }, [fileUrl])

  return (
    <div>
      {files.map((file) => (
        <Button
          key={file.code}
          type='button'
          className='w-100 mb-3 d-inline-flex align-items-center justify-content-between'
          variant='primary'
          onClick={isModal ? openModalFileClickHandler(file) : getFileClickHandler(file)}
        >
          <span>
            <MetronicIcon iconType='Files' iconName='DownloadedFile' size='lg' />
          </span>
          <span>{file.name}</span>
        </Button>
      ))}

      <div>
        <Modal
          show={isSelectionModalOpen}
          onHide={hideSelectionModal}
          className='bg-transparent'
          id='kt_mega_menu_modal'
          aria-hidden='true'
          tabIndex='-1'
          size='xl'
          centered
          contentClassName='shadow-none'
        >
          {isPdf ? (
            <div className='pdf-modal'>
              <embed src={fileUrl?.url} width='100%' height='95%'></embed>
            </div>
          ) : (
            <img src={fileUrl?.url} alt='file' />
          )}

          <div className='mx-5 py-5 text-end '>
            <Button onClick={hideSelectionModal}>Close</Button>
          </div>
        </Modal>
      </div>
    </div>
  )
}
