import clsx from 'clsx'
import {Button, ButtonProps} from './Button'
import {MetronicIcon, MetronicIconPath} from './MetronicIcon'

export interface TileButtonProps extends ButtonProps, MetronicIconPath {}

export const TileButton = ({
  iconName,
  iconType,
  className,
  children,
  ...buttonProps
}: TileButtonProps) => {
  return (
    <Button className={clsx('tile-button', className)} {...buttonProps}>
      <MetronicIcon className='m-0' size='xl' iconName={iconName} iconType={iconType} />
      {children}
    </Button>
  )
}
