import vipImage from './VIPBackground.png'
import grandstandImage from './GrandstandBackground.png'
import othersImage from './OtherVenues.png'
import oasisImage from './OasisBackground.png'
import styles from './CounterCard.module.scss'
import clsx from 'clsx'
import {useMemo} from 'react'
import {HorizontalDivider} from '../../../../../../components/utils/HorizontalDivider'

export type CounterCardVariant = 'oasis' | 'vip' | 'grandstand' | 'others'

export interface CounterCardProps {
  value: number
  title?: string
  subtitle?: string
  variant: CounterCardVariant
  className?: string
}

export const CounterCard = ({value, title, subtitle, variant, className}: CounterCardProps) => {
  const image = useMemo(() => {
    switch (variant) {
      case 'grandstand':
        return grandstandImage
      case 'vip':
        return vipImage
      case 'oasis':
        return oasisImage
      default:
        return othersImage
    }
  }, [variant])

  return (
    <div
      className={clsx(
        className,
        styles.text,
        styles.backgroundImage,
        'd-flex flex-column rounded text-white justify-content-center fw-bold'
      )}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div
        className='px-12'
        style={{
          fontSize: '6rem',
        }}
      >
        {Intl.NumberFormat('en-US').format(value || 0)}
      </div>
      <div
        style={{
          fontSize: '1.2rem',
          lineHeight: '0.9em',
        }}
        className='text-uppercase px-12 fw-bolder'
      >
        {title}
      </div>
      <HorizontalDivider className='opacity-100' />
      <div
        style={{
          fontSize: '1rem',
        }}
        className='text-uppercase px-12 pb-3'
      >
        {subtitle}
      </div>
    </div>
  )
}
