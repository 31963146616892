import {Link} from 'react-router-dom'
import {useBooleanState} from '../hooks/useBooleanState'
import {InputLabel} from './InputLabel'
import {MetronicIconButton} from './MetronicIconButton'
import {TextInput, TextInputProps} from './TextInput'
import React, {forwardRef} from 'react'

export interface PasswordInputProps extends Omit<TextInputProps, 'ref'> {
  forgotPasswordLink?: string
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({forgotPasswordLink, className, label, id, type = 'password', ...inputProps}, ref) => {
    const {
      disableState: hidePassword,
      enableState: showPassword,
      state: isPasswordShown,
    } = useBooleanState(false)

    return (
      <div className={className}>
        <div className='d-flex justify-content-between'>
          {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
          {forgotPasswordLink && (
            <Link
              to={forgotPasswordLink}
              className='link-text-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password?
            </Link>
          )}
        </div>
        <TextInput
          id={id}
          ref={ref}
          type={isPasswordShown ? 'text' : type}
          {...inputProps}
          endAdornment={
            <MetronicIconButton
              iconType='General'
              iconName={isPasswordShown ? 'Hidden' : 'Visible'}
              type='button'
              onPointerDown={showPassword}
              onPointerUp={hidePassword}
              onPointerLeave={hidePassword}
            />
          }
        />
      </div>
    )
  }
)
