import {useCallback, useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import {DetailHeaderContent} from '../../../components/DetailHeader/DetailHeaderContent'
// import {DetailHeaderTabList} from '../../../components/DetailHeader/DetailHeaderTabList'
// import {AuthDetailHeaderTabProps} from '../../../components/DetailHeader/useAuthTabs'
import {Button} from '../../../components/inputs/Button'
// import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
// import {Paper} from '../../../components/utils/Paper'
import {EventModel} from '../../../models/ems/EventModel'
// import {useAppConfig} from '../../app-config/hooks/useAppConfig'
// import {useCustomerAuth} from '../../customer-portal/hooks/useCustomerAuth'

export interface PortalEventDetailTabsProps {
  event?: EventModel | null
}

export const PortalEventDetailTabs = ({event}: PortalEventDetailTabsProps) => {
  const history = useHistory()
  const goToBilling = useCallback(() => {
    if (event) {
      history.push(`/events/${event.code}/billing`)
    }
  }, [event, history])

  const goToNewRegistration = useCallback(() => {
    if (event) {
      history.push(`/events/${event.code}/new`)
    }
  }, [event, history])

  const headerContent = useMemo(() => {
    if (event) {
      return (
        <DetailHeaderContent
          left={<></>}
          right={
            <div className='mb-5'>
              <Button onClick={goToBilling}>Billing History</Button>
              <Button onClick={goToNewRegistration} type='button' variant='primary'>
                New Registration
              </Button>
            </div>
          }
        />
      )
    }
    return null
  }, [event, goToBilling, goToNewRegistration])

  return (
    <>
      <div className='mt-2'>{headerContent}</div>
    </>
  )
}
