import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import TableWrapper from '../../../../components/tables/TableWrapper'
import {AccessLogModel} from '../../../../models/acs/AccessLogModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {TableColumns} from '../../../../models/TableColumns'

type Props = {
  eventCode: string
}

const tableColumns: TableColumns[] = [
  {
    dataField: 'code',
    text: 'code',
    isSortable: true,
    className: 'min-w-80px',
  },
  {
    dataField: 'name',
    text: 'name',
    isSortable: true,
    className: 'min-w-80px',
  },
  {
    dataField: 'user',
    text: 'user',
    isSortable: true,
    className: 'min-w-80px',
  },
  {
    dataField: 'event',
    text: 'event',
    isSortable: true,
  },
  {
    dataField: 'location',
    text: 'location',
    isSortable: true,
  },
  {
    dataField: 'contact',
    text: 'contact no',
    isSortable: true,
  },
  {
    dataField: 'updatedAt',
    text: 'date',
    isSortable: true,
    className: 'min-w-150px mw-200px',
  },
]

const CsrResolutionPoint: FC<Props> = ({eventCode}) => {
  const _accessLogs: GlobalSearchModel<AccessLogModel> = useSelector<RootState>(
    ({ems}) => ems.accessLogs,
    shallowEqual
  ) as GlobalSearchModel<AccessLogModel>

  return (
    <>
      <Toolbar title='Resolution Points' />
      <TableWrapper
        columns={tableColumns}
        total={_accessLogs?.total}
        data={_accessLogs?.data}
        currentPageNumber={_accessLogs?.page}
        currentPageSize={_accessLogs?.limit}
      />
    </>
  )
}
export default CsrResolutionPoint
