import clsx from 'clsx'
import {useFormik} from 'formik'
import {useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import * as yup from 'yup'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {DatePickerInput, TextInput} from '../../../components/inputs'
import {useFormikCheckboxInputHelpers} from '../../../components/inputs/CheckboxInput/useFormikCheckboxInputHelpers'
import {InputLabel} from '../../../components/inputs/InputLabel'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {MobileNumberInput} from '../../../components/inputs/MobileNumberInput'
import {TimePickerInput} from '../../../components/inputs/TimePickerInput'
import {useFormikMobileNumberInputHelpers} from '../../../components/inputs/useFormikMobileNumberInputHelpers'
import {DateUtil} from '../../../utils/DateUtil'
import {Time} from '../../../utils/Time'
import {Validators} from '../../../utils/Validators'
import {useCustomerProfile} from '../hooks/useCustomerProfile'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {ConfirmPageLayout} from '../layout/ConfirmPageLayout'
import {CreateTableReservation} from '../redux/DigitalMenuCRUD'

interface PathParams {
  locationCode: string
  outletCode: string
}

interface TableReservationFormValues {
  customerName: string
  mobileNumber: string
  date: null | Date
  time: null | Time
  pax: string
  isAgree: boolean
}

export const TableReservationPage = () => {
  const {goToOutletMenu, goBack} = usePageNavigation()
  const {pushError} = useAlerts()
  const {outletCode} = useParams<PathParams>()
  const dateNow = useMemo(() => new Date(), [])
  const {mobileNumber, customerName, setMobileNumber} = useCustomerProfile()

  const goBackToOutlet = useCallback(() => {
    if (goBack) {
      goBack()
    } else {
      goToOutletMenu(outletCode)
    }
  }, [goBack, goToOutletMenu, outletCode])

  const formik = useFormik({
    initialValues: {
      ...EMPTY_INITIAL_VALUES,
      customerName,
      mobileNumber,
    },
    onSubmit: async (values) => {
      try {
        if (values.date && values.time) {
          await CreateTableReservation({
            date: DateUtil.convertDateToApiString(values.date),
            isAgree: values.isAgree,
            mobile: values.mobileNumber,
            name: values.customerName,
            outletCode,
            pax: parseInt(values.pax),
          })
          setMobileNumber(values.mobileNumber)
          goBackToOutlet()
        } else {
          throw Error('Invalid form.')
        }
      } catch (e) {
        pushError(e)
      }
    },
    validationSchema,
  })

  const isAgree = useFormikCheckboxInputHelpers(formik, 'isAgree')

  const getDateWithTime = useCallback(
    (date: Date) => {
      const newDate = new Date(date)
      if (formik.values.time) {
        formik.values.time.applyToDate(newDate)
      }
      return newDate
    },
    [formik.values.time]
  )

  const handleDateChange = useCallback(
    (date: Date | null) => {
      let dateWithTime = date
      if (dateWithTime) {
        dateWithTime = getDateWithTime(dateWithTime)
      }
      formik.setFieldValue('date', dateWithTime)
    },
    [formik, getDateWithTime]
  )

  const handleTimeChange = useCallback(
    (time: Time | null) => {
      const date = formik.values.date
      if (date) {
        const newDate = getDateWithTime(date)
        formik.setFieldValue('date', newDate)
      }
      formik.setFieldValue('time', time)
    },
    [formik, getDateWithTime]
  )

  const handleSubmit = useCallback(() => {
    formik.submitForm()
  }, [formik])

  const mobileNumberInputHelpers = useFormikMobileNumberInputHelpers(formik)

  return (
    <ConfirmPageLayout
      headerLabel='Reserve a table'
      onBackClick={goBackToOutlet}
      onButtonClick={handleSubmit}
      disableButton={formik.isSubmitting}
      buttonLabel='Reserve'
    >
      <TextInput
        {...formik.getFieldProps('customerName')}
        errorMessage={formik.errors.customerName}
        isTouched={formik.touched.customerName}
        label={
          <span className='d-flex align-items-center'>
            <MetronicIcon iconType='General' iconName='User' />
            <span>Customer Name</span>
          </span>
        }
        placeholder='Type your name'
      />
      <InputLabel>
        <span className='d-flex align-items-center'>
          <MetronicIcon iconType='Devices' iconName='Phone' />
          <span>Mobile Number</span>
        </span>
      </InputLabel>
      <p className='text-muted'>
        Please provide us your contact number in case we need to contact you for an update on your
        order.
      </p>
      <MobileNumberInput
        placeholder='Type your mobile number'
        {...mobileNumberInputHelpers.getFieldProps('mobileNumber')}
      />
      <DatePickerInput
        minDate={dateNow}
        value={formik.values.date}
        onChange={handleDateChange}
        label={
          <span className='d-flex align-items-center'>
            <MetronicIcon iconType='Home' iconName='Clock' />
            <span>Date</span>
          </span>
        }
      />
      <TimePickerInput value={formik.values.time} onChange={handleTimeChange} />
      <TextInput
        errorMessage={formik.errors.pax}
        isTouched={formik.touched.pax}
        type='number'
        {...formik.getFieldProps('pax')}
        label={
          <span className='d-flex align-items-center'>
            <MetronicIcon iconType='Communication' iconName='Group' />
            <span>Pax</span>
          </span>
        }
        placeholder='Enter how many people are going'
      />
      <div className='form-check'>
        <input
          className={clsx('form-check-input', {'bg-success': formik.values.isAgree})}
          type='checkbox'
          id='isAgree'
          onChange={isAgree.onChange}
          checked={isAgree.value}
        />
        <label className='form-check-label' htmlFor='isAgree'>
          I Agree to reserve a table and acknowledge that I will have to order a minimum amount of
          50AED or it will be added on the total bill.
        </label>
      </div>
    </ConfirmPageLayout>
  )
}

const validationSchema = yup.object().shape({
  customerName: yup.string().required('Please enter your name.'),
  mobileNumber: yup
    .string()
    .test('valid-mobile', 'Please enter a valid mobile number.', (value) => {
      return Validators.INTERNATIONAL_MOBILE_NUMBER_REGEX.test(value || '')
    }),
  date: yup.date().required(),
  time: yup.mixed().nullable().required(),
  pax: yup.number().required('Please enter how many people are going.'),
  isAgree: yup.boolean().isTrue(),
})

const EMPTY_INITIAL_VALUES: TableReservationFormValues = {
  customerName: '',
  date: null,
  time: new Time(0, 0, 0),
  mobileNumber: '',
  pax: '',
  isAgree: false,
}
