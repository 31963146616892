import {useCallback, useState, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FilterModel} from '../../../../../models/FilterModel'
import {GetProducts} from '../../../redux/CustomerDelegateCRUD'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {useTableFilterState} from '../../../../../components/tables/useTableFilterState'
import {SearchableSelectInput} from '../../../../../components/inputs/SearchableSelect'
import {CustomerSearchInputSelectItem} from '../../../../customer-delegate/components/inputs/CustomerSearchInput'
export interface FilterProductInputProps {
  field: string
  label: string
  placeholder?: string
}

export const FilterProductInput = ({field, label, placeholder}: FilterProductInputProps) => {
  const {pushError} = useAlerts()
  const {clearAdvancedFilterValue, setAdvancedFilterValue} = useTableFilterState()
  const searchDebounce = useDebounce(500)
  const [searchResult, setSearchResult] = useState<CustomerSearchInputSelectItem[]>()
  const [value, setValue] = useState<CustomerSearchInputSelectItem | null>(null)
  const id = useRef(uuidv4()).current

  const searchProducts = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetProducts(filter)
        return data
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  const getSearchableFilterFieldFilterHandler = useCallback(
    async (filter: FilterModel) => {
      searchDebounce(async () => {
        const data = await searchProducts(filter)
        if (data) {
          const newData: CustomerSearchInputSelectItem[] = data.data.map((item) => {
            return {
              code: item.code,
              label: item.name,
              value: item.code,
            }
          })
          setSearchResult(newData)
        }
      })
    },
    [searchDebounce, searchProducts]
  )

  const getSearchableFilterFieldChangeHandler = useCallback(
    (valueInput: CustomerSearchInputSelectItem | null) => {
      if (valueInput) {
        setAdvancedFilterValue(field, valueInput.code)
        setValue(valueInput)
      } else {
        clearAdvancedFilterValue(field)
        setValue(null)
      }
    },
    [clearAdvancedFilterValue, field, setAdvancedFilterValue]
  )

  return (
    <SearchableSelectInput
      allowClear
      itemMapper={itemMapper}
      label={label}
      placeholder={placeholder}
      onSearch={(value) =>
        getSearchableFilterFieldFilterHandler({
          filters: {
            search: value,
          },
        })
      }
      items={searchResult ? searchResult : []}
      id={id}
      value={value}
      onChange={getSearchableFilterFieldChangeHandler}
      filter={NO_FILTER}
    />
  )
}

const itemMapper = (item: CustomerSearchInputSelectItem) => ({label: item.label, value: item.value})

const NO_FILTER = () => true
