import {FC, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../../_metronic/partials'
import {useAuthRole} from '../../../components/hooks/useAuthRole'
import CsrAccessLog from './pages/CsrAccessLog'
import CsrResolutionPoint from './pages/CsrResolutionPoint'

const CsrPage: FC = () => {
  const authRole = useAuthRole()
  try {
    const isAdmin = authRole?.isAdmin()
    if (isAdmin) {
      return (
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/csr/log' component={CsrAccessLog} />
            <Route path='/csr/rp' component={CsrResolutionPoint} />
            <Redirect from='/csr' exact={true} to='/csr/log' />
            <Redirect to='/csr/log' />
          </Switch>
        </Suspense>
      )
    }
    return null
  } catch (e) {
    return <Redirect to='/' />
  }
}

export default CsrPage
