import {useCallback, useEffect, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {TicketWaiverModel} from '../../../models/digital-waiver/TicketWaiverModel'
import {Helmet} from 'react-helmet'
import {TicketSubmitWaiverFormEdit} from '../components/waiverForms'
import {GetGuardianRejection, GetGuardianTicketByCode} from '../redux/DigitalWaiverCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {WaiverFormHeader} from '../components/modals/WaiverFormHeader'
import {ARABIC_CONTENT, ENGLISH_CONTENT} from '../content/WaiverFormDescription'
import {SwalUtils} from '../../../utils/SwalUtils'

interface RouteParams {
  ticketCode: string
}

export const DigitalWaiverGuardianFormPage = () => {
  const history = useHistory()
  const [ticket, setTicket] = useState<TicketWaiverModel>()
  const {pushError} = useAlerts()
  const {
    params: {ticketCode},
  } = useRouteMatch<RouteParams>()

  const getTicketByCode = useCallback(async () => {
    if (ticketCode) {
      try {
        const {data} = await GetGuardianTicketByCode(ticketCode)
        if (data) setTicket(data)
      } catch (err) {
        pushError(err)
      }
    }
  }, [pushError, ticketCode])

  useEffect(() => {
    if (!ticketCode) history.replace('/auth/login')
    else {
      getTicketByCode()
    }
  }, [getTicketByCode, history, ticketCode])

  const handleRejectWaiver = useCallback(async () => {
    await SwalUtils.asyncSpecifyReason(
      async (reason) => {
        try {
          if (ticket && ticket.dwCode) {
            const {data} = await GetGuardianRejection(ticket.dwCode, reason)
            if (data) history.replace('/guardian/thank-you')
          }
        } catch (e) {
          pushError(e)
        }
      },
      {
        titleText: 'Reject Waiver',
        text: 'Please specify a reason.',
        confirmButtonText: 'Confirm',
        icon: 'question',
        cancelButtonText: "Don't Reject!",
      }
    )
  }, [history, pushError, ticket])

  return ticket ? (
    <div className='p-5 container-fluid digital-waiver-container'>
      <Helmet>
        <title>Digital Waiver</title>
        <link rel='icon' href='/waiver.ico' />
      </Helmet>

      <div className='row'>
        <WaiverFormHeader
          arabicTextContent={ARABIC_CONTENT}
          englishTextContent={ENGLISH_CONTENT}
          selectedTicket={ticket}
        />
        <TicketSubmitWaiverFormEdit
          isOpen={true}
          ticket={ticket}
          isGuardianPage={true}
          onRefresh={() => history.replace('/quick-waiver/thank-you')}
          onCancel={handleRejectWaiver}
        />
      </div>
    </div>
  ) : null
}
