import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {concat} from 'lodash'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {Button} from '../../inputs/Button'
import {ListCountInputItemValue} from '../../inputs/VoucherInput/ListCountInputItem'
import {
  ProductVoucherTable,
  ProductVoucherModel,
  columns,
} from '../BookingWizardTables/ProductVoucherTable'
import {ProductModel} from '../../../models/ems/ProductModel'
import {useOnChange} from '../../hooks/useOnChange'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {map, uniq, uniqBy} from 'lodash'
import {
  BookingFormValues,
  BookingWizardProductVenueStepFormValues,
  ProductVoucherModalType,
} from '../../../models/booking-wizard/BookingWizard'
import {AddProductVoucherModal} from '../component/AddProductVoucherModal'
import {EditProductVoucherModal} from '../component/EditProductVoucherModal'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {useAlerts} from '../../alerts/useAlerts'
import _ from 'lodash'
import {GetBundleProducts} from '../../../modules/default/ems/redux/EmsCRUD'

export interface BookingWizardProductVoucherSharedStepProps<
  T extends BookingWizardProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  bookingForm?: BookingFormValues
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  productSearchResults?: GlobalSearchModel<ProductModel>
  searchVouchers: (filter?: FilterModel) => Promise<void>
  refreshProductsList: (filter?: FilterModel) => Promise<void>
  isPortal?: boolean
}

export const BookingWizardProductVoucherSharedStep = <
  T extends BookingWizardProductVenueStepFormValues
>({
  formik,
  bookingForm,
  productSearchResults,
  refreshProductsList,
  searchVouchers,
  voucherSearchResults,
  isPortal,
}: BookingWizardProductVoucherSharedStepProps<T>) => {
  const [modalType, setModalType] = useState<ProductVoucherModalType>()
  const [currentSelectedProductVoucher, setCurrentSelectedProductVoucher] =
    useState<ProductVoucherModel>()
  const [productsSelectedValues, setProductsSelectedValues] = useState<
    ListCountInputItemValue<ProductModel>[]
  >([])
  const [vouchersSelectedValues, setVouchersSelectedValues] = useState<
    ListCountInputItemValue<VoucherModel>[]
  >([])
  const {pushError} = useAlerts()
  const [canAdd, setCanAdd] = useState<boolean>(false)

  const isAllProductsSelected = useMemo(() => {
    return productSearchResults
      ? productSearchResults.data.every((item) => {
          const isSelected = formik.values.products.some((value) => value.data?.code === item.code)
          if (isSelected) {
            return true
          }
          return false
        })
      : false
  }, [formik.values.products, productSearchResults])

  const isAllVouchersSelected = useMemo(() => {
    return voucherSearchResults?.data.every((item) => {
      const isSelected = formik.values.vouchers.some((value) => value.data?.code === item.code)
      if (isSelected) {
        return true
      }
      return false
    })
  }, [formik.values.vouchers, voucherSearchResults?.data])

  const tableData = useMemo(() => {
    return formik.values.products || formik.values.vouchers
      ? concat(formik.values.products, formik.values.vouchers as any)
          .filter((item) => item !== null)
          .map((item) => {
            return {
              code: item.data.code,
              qty: item.count,
              name: item.data.name,
              type: item.type,
              isSeated: item.data?.isSeated ? item.data.isSeated : false,
              remainQty: item.remainingQTY,
              maxQty: item.maxQty,
            }
          })
      : null
  }, [formik.values.products, formik.values.vouchers])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: tableData,
    columns: columns ? (map(columns, 'field') as Array<keyof ProductVoucherModel>) : null,
  })

  useEffect(() => {
    searchVouchers()
    refreshProductsList()
  }, [refreshProductsList, searchVouchers])

  const handleCloseModal = useCallback(() => {
    if (modalType === 'product') refreshProductsList()
    if (modalType === 'voucher') searchVouchers()
    setModalType(undefined)
    setCurrentSelectedProductVoucher(undefined)
    if (productsSelectedValues && productsSelectedValues.length) {
      setProductsSelectedValues((products) =>
        products.filter((item) => item.data !== null && !item.isNew)
      )
    }
    if (vouchersSelectedValues && vouchersSelectedValues.length) {
      setVouchersSelectedValues((voucher) =>
        voucher.filter((item) => item.data !== null && !item.isNew)
      )
    }
  }, [
    modalType,
    productsSelectedValues,
    refreshProductsList,
    searchVouchers,
    vouchersSelectedValues,
  ])

  const populateBundleProduct = useCallback(
    async (newValues: ListCountInputItemValue<ProductModel>[]) => {
      try {
        let productValues = [...newValues]
        for (let i = 0; i < productValues.length; i++) {
          if (productValues[i].type && productValues[i].type === 'bundle') {
            if (productValues[i].data) {
              const {data} = await GetBundleProducts(productValues[i].data?.code as string)
              productValues[i].bundleProducts = data.data
            }
          } else {
            productValues[i].startDate = productValues[i].data?.startedAt
            productValues[i].endDate = productValues[i].data?.endedAt
          }
        }
        formik.setFieldValue('products', productValues)
      } catch (e: any) {
        pushError(e)
        formik.setFieldValue('products', newValues)
      }
    },
    [pushError]
  )

  const handleAddProductOrVoucher = useCallback(async () => {
    setTimeout(async () => {
      let productsWithoutIsNew = [...productsSelectedValues]

      productsWithoutIsNew = productsWithoutIsNew
        .filter((item) => item.data && item.data !== null)
        .map((item) => {
          return {
            id: item.id,
            data: item.data,
            count: item.count,
            type: item.data?.type === 'bundle' ? 'bundle' : 'product',
            remainingQTY: item.remainingQTY,
            maxQty: item.maxQTY,
          }
        })
      let vouchersWithoutIsNew = [...vouchersSelectedValues]

      vouchersWithoutIsNew = vouchersWithoutIsNew
        .filter((item) => item.data && item.data !== null)
        .map((item) => {
          return {id: item.id, data: item.data, count: item.count, type: 'voucher'}
        })

      if (productsWithoutIsNew.length) {
        formik.setFieldValue('products', productsWithoutIsNew)
        await populateBundleProduct(productsWithoutIsNew)
      }
      if (vouchersWithoutIsNew.length) formik.setFieldValue('vouchers', vouchersWithoutIsNew)
      handleCloseModal()
    }, 200)
  }, [formik, handleCloseModal, productsSelectedValues, vouchersSelectedValues])

  const handleProductQTYComputation = useCallback(
    async (newValues: ListCountInputItemValue<ProductModel>[]) => {
      const parentProduct = newValues.filter((p) => p.data?.children)
      newValues.forEach((v) => {
        v.remainingQTY = v.data?.remainingQty
        v.maxQTY = v.data?.remainingQty
        if (parentProduct.length && !v.data?.children) {
          for (let parent of parentProduct) {
            const hasParent = parent.data?.children?.find((c) => c.code === v.data?.code)
            if (hasParent) {
              v.parentId = parent.data?.code
            }
          }
        }
      })

      let arrTmp: ListCountInputItemValue<ProductModel>[] = []
      setCanAdd(false)
      for (const val of newValues) {
        // let isPush: boolean = true
        if (val.maxQTY && val.count > val.maxQTY) {
          pushError(new Error(`Cannot add this product reached maximum quantity.`))
          setCanAdd(true)
          // continue
        }
        // const checkParentProd = arrTmp.filter((p) => p.data?.children)
        // for (let parent of checkParentProd) {
        //   const hasParent = parent.data?.children?.find((c) => c.code === val.data?.code)
        //   if (hasParent) {
        //     const parentMaxQty = parent.maxQTY ? parent.maxQTY : 0
        //     const childParentCount = val.count + parent.count
        //     const childMaxQty = val.maxQTY ? val.maxQTY : 0

        //     if (parent.count > parentMaxQty || childParentCount > childMaxQty) {
        //       pushError(new Error(`Cannot add this parent product reached maximum quantity.`))
        //       // isPush = false
        //       setCanAdd(true)
        //       // break
        //     } else if (val.maxQTY && parent.maxQTY && val.maxQTY === parent.maxQTY) {
        //       if (parent.count + val.count > parentMaxQty) {
        //         pushError(new Error(`Cannot add this parent product reached maximum quantity.`))
        //         // isPush = false
        //         setCanAdd(true)
        //         // break
        //       }
        //     }
        //   }
        // }
        // if (val.data && val.data.children?.length && val.count) {
        //   let hasChild = arrTmp.filter((p) => val.data?.code === p.parentId)
        //   if (hasChild.length) {
        //     hasChild = _.orderBy(hasChild, ['count'], ['desc'])
        //     const highestChildQty = hasChild[0]
        //     const parentMaxQty = val.maxQTY ? val.maxQTY : 0

        //     if (val.count + highestChildQty.count > parentMaxQty) {
        //       pushError(new Error(`Cannot add this parent product reached maximum quantity.`))
        //       setCanAdd(true)
        //     }
        //   }
        // }
        if (val.parentId) {
          const hasParent = arrTmp.find((item) => item.data?.code === val.parentId)
          if (val.maxQTY && hasParent) {
            const parentAndChildCount = hasParent.count + val.count
            if (parentAndChildCount > val.maxQTY) {
              pushError(new Error(`Cannot add this product reached maximum quantity1.`))
              setCanAdd(true)
            }
          }
        }

        if (val.data && val.data.children?.length && val.count) {
          let hasChild = arrTmp.filter((p) => val.data?.code === p.parentId)
          if (hasChild.length) {
            hasChild = _.orderBy(hasChild, ['count'], ['desc'])
            const highestChildQty = hasChild[0]
            // const parentMaxQty = val.maxQTY ? val.maxQTY : 0
            const childPlusParent = val.count + highestChildQty.count
            if (highestChildQty.maxQTY) {
              if (childPlusParent > highestChildQty.maxQTY) {
                pushError(new Error(`Cannot add this parent product reached maximum quantity4.`))
                setCanAdd(true)
              }
            }
          }
        }
        arrTmp.push(val)
      }
      newValues = arrTmp
      for (const val of newValues) {
        if (val.count && val.maxQTY && val.remainingQTY && val.count > val.maxQTY) {
          val.count = 1
        }
        const dif = val.remainingQTY ? val.remainingQTY - val.count : 0
        if (dif >= 0) {
          val.remainingQTY = dif
        } else {
          val.remainingQTY = 0
        }
        if (val.parentId) {
          const hasParent = newValues.find((item) => item.data?.code === val.parentId)
          if (hasParent) {
            const diff: number = val.remainingQTY ? val.remainingQTY - hasParent.count : 0
            if (diff > 0) {
              val.remainingQTY = diff
            } else {
              val.remainingQTY = 0
            }
          }
        } else {
          let arrCountCheck = newValues.filter((n) => n.parentId === val.data?.code)
          arrCountCheck = _.orderBy(arrCountCheck, ['count'], ['desc'])

          if (arrCountCheck.length) {
            const highestQty = arrCountCheck[0]

            if (highestQty) {
              const diffs = val.remainingQTY ? val.remainingQTY - highestQty.count : 0
              if (diffs > 0) {
                val.remainingQTY = diffs
              } else {
                val.remainingQTY = 0
              }
            }
          }
        }
      }

      return newValues
    },
    [pushError]
  )

  const handleOnChangeProduct = useCallback(
    async (newValues: ListCountInputItemValue<ProductModel>[]) => {
      if (isPortal) {
        const newComputation = await handleProductQTYComputation(newValues)
        const uniqData = uniqBy(newComputation, (p) => p.data?.code)
        setProductsSelectedValues(uniqData)
      } else {
        const uniqData = uniqBy(newValues, (p) => p.data?.code)
        setProductsSelectedValues(uniqData)
      }
    },
    [handleProductQTYComputation, isPortal]
  )

  const handleVoucherChange = useCallback((newValues: ListCountInputItemValue<VoucherModel>[]) => {
    setVouchersSelectedValues(newValues)
  }, [])

  const handleOnEditProductVoucher = useCallback(
    async (type: ProductVoucherModalType, value: ProductVoucherModel) => {
      let newProductsValues = [...formik.values.products]
      let newVouchersValues = [...formik.values.vouchers]
      let index = -1
      if (type === 'product') {
        const found = newProductsValues.find((item, idx) => {
          if (item.data?.code === value.code) {
            index = idx
            return item
          }
          return null
        })

        if (found) {
          newProductsValues[index] = {
            ...found,
            count: value.qty,
          }
          if (isPortal) {
            const newComputation = await handleProductQTYComputation(newProductsValues)
            formik.setFieldValue('products', newComputation)
          } else {
            formik.setFieldValue('products', newProductsValues)
          }

          handleCloseModal()
        }
      }

      if (type === 'voucher') {
        const found = newVouchersValues.find((item, idx) => {
          if (item.data?.code === value.code) {
            index = idx
            return item
          }
          return null
        })

        if (found) {
          newVouchersValues[index] = {
            ...found,
            count: value.qty,
          }

          formik.setFieldValue('vouchers', newVouchersValues)
          handleCloseModal()
        }
      }
    },
    [formik, handleCloseModal, handleProductQTYComputation, isPortal]
  )

  const handleDeleteProductsVouchers = useCallback(
    (codes: string[]) => {
      let formikProductValues = [...formik.values.products]
      let formikVoucherValues = [...formik.values.vouchers]
      for (let i = 0; i < codes.length; i++) {
        const foundProduct = formikProductValues.find((item) => item?.data?.code === codes[i])
        const foundvoucher = formikVoucherValues.find((item) => item?.data?.code === codes[i])

        if (foundProduct) {
          formikProductValues = formikProductValues.filter(
            (item) => item.data?.code !== foundProduct.data?.code
          )
        } else if (foundvoucher) {
          formikVoucherValues = formikVoucherValues.filter(
            (item) => item.data?.code !== foundvoucher.data?.code
          )
        }
      }
      formik.setFieldValue('products', formikProductValues)
      setProductsSelectedValues(formikProductValues)
      formik.setFieldValue('vouchers', formikVoucherValues)
      setVouchersSelectedValues(formikVoucherValues)
    },
    [formik]
  )

  const handleEditProductsVouchers = useCallback(
    async (data: ProductVoucherModel) => {
      if (productSearchResults && productSearchResults.data?.length) {
        const p = productSearchResults.data.find((item) => item.code === data.code)
        if (p) setModalType('product')
      }
      if (voucherSearchResults && voucherSearchResults.data?.length) {
        const v = voucherSearchResults.data.find((item) => item.code === data.code)
        if (v) setModalType('voucher')
      }
      const newComputation = await handleProductQTYComputation(formik.values.products)
      const found = newComputation.find((item) => item.data?.code === data.code)
      if (found && found.remainingQTY) {
        data.remainQty = found?.remainingQTY
      }
      setCurrentSelectedProductVoucher(data)
    },
    [productSearchResults, voucherSearchResults]
  )

  const productsValues = useMemo(() => {
    if (productsSelectedValues.length !== formik.values.products.length) {
      const newData = productsSelectedValues.concat(formik.values.products)
      return uniq(newData)
    } else return formik.values.products
  }, [formik.values.products, productsSelectedValues])

  const vouchersValues = useMemo(() => {
    if (vouchersSelectedValues.length !== formik.values.vouchers.length)
      return vouchersSelectedValues
    else return formik.values.vouchers
  }, [formik.values.vouchers, vouchersSelectedValues])

  useOnChange(bookingForm, () => {
    if (bookingForm) {
      setTimeout(async () => {
        if (bookingForm.products) {
          setProductsSelectedValues(bookingForm.products)
          await populateBundleProduct(bookingForm.products)
          // formik.setFieldValue('products', bookingForm.products)
        }
        if (bookingForm.vouchers) {
          setVouchersSelectedValues(bookingForm.vouchers)
          formik.setFieldValue('vouchers', bookingForm.vouchers)
        }
      }, 200)
    }
  })

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>{/* <h5 className='my-10 px-8'>Add product</h5> */}</div>
        <div className='d-flex gap-3 px-11 mb-3'>
          {/* <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('product')
            }}
            disabled={isAllProductsSelected}
          >
            {isAllProductsSelected ? 'No Products available' : 'Add products'}
          </Button> */}
          {!isAllVouchersSelected && !isPortal && (
            <Button
              className='btn btn-primary px-10 me-1'
              onClick={() => {
                setModalType('voucher')
              }}
              disabled={isAllVouchersSelected}
            >
              {isAllVouchersSelected ? 'No Vouchers available' : 'Add Voucher'}
            </Button>
          )}
        </div>
        <div>
          <ProductVoucherTable
            onDelete={handleDeleteProductsVouchers}
            onEdit={handleEditProductsVouchers}
            data={searchableLocalTableData}
            onFilter={filterSearchableLocalTableData}
            rightToolbar={
              <Button
                className='btn btn-primary px-10 me-1'
                onClick={() => {
                  setModalType('product')
                }}
                disabled={isAllProductsSelected}
              >
                {isAllProductsSelected ? 'No Products available' : 'Add products'}
              </Button>
            }
          />
        </div>
      </div>
      {currentSelectedProductVoucher ? (
        <EditProductVoucherModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          data={currentSelectedProductVoucher}
          onEdit={handleOnEditProductVoucher}
          isPortal={isPortal}
          isShowRemaining={true}
        />
      ) : (
        <AddProductVoucherModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          productSearchResults={productSearchResults}
          refreshProductsList={refreshProductsList}
          productsValues={productsValues}
          onProductChange={handleOnChangeProduct}
          voucherSearchResults={voucherSearchResults}
          vouchersValues={vouchersValues}
          onVoucherChange={handleVoucherChange}
          searchVouchers={searchVouchers}
          onAdd={handleAddProductOrVoucher}
          isPortal={isPortal}
          canAdd={canAdd}
        />
      )}
    </div>
  )
}
