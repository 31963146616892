import {useContext} from 'react'
import {TooltipContext} from '../TooltipContext/TooltipContextProvider'

export const useTooltipContext = <T = unknown>() => {
  const context = useContext<T>(TooltipContext)

  if (!context) {
    throw new Error('Tooltip context provider was not defined.')
  }

  return context
}
