import {useSafeStateUpdate} from './../../../components/hooks/useSafeStateUpdate'
import {useCallback, useMemo, useRef, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {EVENT_CODE} from '../../../../config/env'
import {RouteParams, TabProps} from '../pages/PortalPage'
import {
  GetTicketProductCategoryTabs,
  GetVoucherTabs,
  DownloadUserGuide,
} from '../redux/CustomerPortalCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {BlobUtils} from '../../../utils/BlobUtils'
import {CUSTOMER_PORTAL_USER_GUIDE} from '../../../../config/env'

export const usePoratlTabs = () => {
  const previousRouteName = useRef<string | null>(null)
  const match = useRouteMatch<RouteParams>()
  const {productCategoryCode, tabCode, voucherTabCode, shipmentsTabCode} = match.params
  const safeUpdate = useSafeStateUpdate()
  const [subTabs, setSubTabs] = useState<TabProps[]>([])
  const history = useHistory()
  const {push, pushError} = useAlerts()
  const routeName = useMemo(() => {
    const name = productCategoryCode
      ? `/product-category/${productCategoryCode}`
      : voucherTabCode
      ? `/voucher-tab/${voucherTabCode}`
      : shipmentsTabCode
      ? `/shipments-tab/${shipmentsTabCode}`
      : null
    return name
  }, [productCategoryCode, shipmentsTabCode, voucherTabCode])

  const redirectTab = useCallback(
    (routeName: string | null, tabStatus: string) => {
      if (!routeName || routeName === previousRouteName.current) {
        history.replace(`${routeName}/${tabStatus}`)
      }
    },
    [history]
  )

  const handleSetTabs = useCallback((routeName: string | null, tabs: TabProps[]) => {
    if (!routeName || routeName === previousRouteName.current) {
      setSubTabs(tabs)
    }
  }, [])
  const refreshVouchertabs = useCallback(async () => {
    previousRouteName.current = routeName
    const {data} = await GetVoucherTabs({filters: {eventCode: EVENT_CODE}})
    if (data && data.length) {
      const firstTab = data[0].code
      safeUpdate(() => {
        if ((firstTab && !tabCode) || (tabCode && !data.find((item) => item.code === tabCode)))
          redirectTab(routeName, firstTab)
        handleSetTabs(routeName, data)
      })
    }
  }, [handleSetTabs, redirectTab, routeName, safeUpdate, tabCode])

  const refreshShipmentstabs = useCallback(() => {
    const data = [{code: 'booking', name: 'Booking'}]
    const firstTab = data[0].code
    safeUpdate(() => {
      if ((firstTab && !tabCode) || (tabCode && !data.find((item) => item.code === tabCode)))
        redirectTab(routeName, firstTab)
      handleSetTabs(routeName, data)
    })
  }, [handleSetTabs, redirectTab, routeName, safeUpdate, tabCode])

  const refreshTicketProductCategoryTabs = useCallback(
    async (productCategorySlug: string) => {
      if (productCategorySlug) {
        previousRouteName.current = routeName
        const {data} = await GetTicketProductCategoryTabs({
          filters: {eventCode: EVENT_CODE, productCategorySlug: productCategorySlug},
        })
        if (data && data.length) {
          const firstTab = data[0].code
          safeUpdate(() => {
            if ((firstTab && !tabCode) || (tabCode && !data.find((item) => item.code === tabCode)))
              redirectTab(routeName, firstTab)
            handleSetTabs(routeName, data)
          })
        }
      }
    },
    [handleSetTabs, redirectTab, routeName, safeUpdate, tabCode]
  )

  const downloadFromTab = useCallback(async () => {
    try {
      if (CUSTOMER_PORTAL_USER_GUIDE) {
        const {data} = await DownloadUserGuide(CUSTOMER_PORTAL_USER_GUIDE)
        if (data) {
          let name = CUSTOMER_PORTAL_USER_GUIDE
          const splitName = name.split('/')
          name = name.split('/')[splitName.length - 1]
          if (name.split('.').length) {
            name = name.split('.')[0]
          }
          BlobUtils.downloadBlob(data, name)
        }
        push({
          message: `Successfully downloaded.`,
          timeout: 5000,
          variant: 'success',
        })
      }
    } catch (e) {
      pushError(e)
    }
  }, [push, pushError])

  return useMemo(
    () => ({
      refreshVouchertabs,
      refreshTicketProductCategoryTabs,
      refreshShipmentstabs,
      subTabs,
      setSubTabs,
      routeName,
      downloadFromTab,
    }),
    [
      refreshTicketProductCategoryTabs,
      refreshShipmentstabs,
      refreshVouchertabs,
      routeName,
      subTabs,
      setSubTabs,
      downloadFromTab,
    ]
  )
}
