import {useMemo} from 'react'
import {TicketWaiverModel} from '../../../models/digital-waiver/TicketWaiverModel'
import moment from 'moment'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import clsx from 'clsx'
import {MarkdownTooltip} from '../../../components/charts/MarkdownTooltip'
interface WaiverFormContentProps {
  arabicTextheader: string
  englishTextheader: string
  arabicTextContent: string
  englishTextContent: string
  selectedTicket?: TicketWaiverModel
}

export const WaiverFormContent = ({
  arabicTextheader,
  englishTextheader,
  englishTextContent,
  arabicTextContent,
  selectedTicket,
}: WaiverFormContentProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])
  const eventName = useMemo(() => {
    const isOpenYas =
      selectedTicket?.productCategorySlug &&
      ['open yas', 'openyas', 'openYas'].includes(
        selectedTicket.productCategorySlug.toLocaleLowerCase()
      )
    if (isOpenYas) {
      return selectedTicket?.productName
    }
    return selectedTicket?.productCategorySlug || selectedTicket?.productCategoryName
  }, [selectedTicket])
  return (
    <div className='bg-primary-dw-darker border border-primary p-2 p-md-10 rounded'>
      <div className='container-fluid p-5'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <p className='text-white text-uppercase fs-4 fw-bolder text-start'>
              {englishTextheader}
            </p>
          </div>
          <div className='col-12 col-md-6'>
            <p
              className={clsx(
                'text-white text-uppercase fs-4 fw-bolder',
                isMobile ? 'text-start' : 'text-end'
              )}
            >
              {arabicTextheader}
            </p>
          </div>
        </div>
      </div>
      <div className='p-5'>
        <div className='px-5 h-100 border border-primary bg-primary-dw-light py-3'>
          <div className='contaiver-fluid'>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <p className='mb-0 text-white fs-3 fw-bolder'>{eventName}</p>
              </div>
              <div className='col-12 col-sm-6'>
                <p
                  className={clsx(
                    'mb-0 text-white fs-6 fw-bolder',
                    isMobile ? 'text-start' : 'text-end'
                  )}
                >
                  Experince Date: {moment(selectedTicket?.startedAt).format('DD MMM YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-center gap-5 p-5'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <MarkdownTooltip>{`${englishTextContent}`}</MarkdownTooltip>
          </div>
          <div className='col-12 col-md-6'>
            <MarkdownTooltip>{`${arabicTextContent}`}</MarkdownTooltip>
          </div>
        </div>
      </div>
    </div>
  )
}
