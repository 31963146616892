import {ReactNode} from 'react'
import clsx from 'clsx'

export interface ToolbarTitleProps {
  children?: ReactNode
  className?: string
}

export const ToolbarTitle = ({children, className}: ToolbarTitleProps) => {
  return <h1 className={clsx('m-0 fs-3', className)}>{children}</h1>
}
