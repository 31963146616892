import clsx from 'clsx'
import {ReactNode} from 'react'
import {createPortal} from 'react-dom'
import {useLayout} from '../../../../_metronic/layout/core'
import {useLayoutPortalData} from './LayoutPortalProvider'

export interface ToolbarProps {
  title?: ReactNode
  description?: string
  children?: ReactNode
}

export const Toolbar = ({description, title, children}: ToolbarProps) => {
  const {classes, attributes} = useLayout()
  const {toolbar} = useLayoutPortalData()

  if (!toolbar) {
    return null
  }

  return createPortal(
    <div className='toolbar flex-column align-items-start rs-breadcrumbs' id='kt_toolbar'>
      <div id='kt_toolbar_container' className={clsx(classes.toolbarContainer, 'd-flex h-100')}>
        {title && (
          <div
            {...attributes.pageTitle}
            className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
          >
            <h1 className='d-flex align-items-center fw-bolder my-1 fs-3'>
              {title}
              {description && (
                <small className='text-muted fs-7 fw-bold my-1 ms-1'>{description}</small>
              )}
            </h1>
          </div>
        )}
        <div
          className='flex-grow-1 d-flex flex-column justify-content-center'
          style={{minWidth: 0}}
        >
          {children}
        </div>
      </div>
    </div>,
    toolbar
  )
}
