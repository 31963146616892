import clsx from 'clsx'
import {ButtonSize} from '../../../../../components/inputs/Button'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {Spinner} from '../../../../../components/utils/Spinner'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {isVoucherShareable} from '../../../hooks/useVoucherTableActions'
export interface VoucherTableSelectionActionsProps {
  onDisable?: (vouchers: VoucherModel[]) => void
  onDownload?: (data: VoucherModel[]) => void
  onShareByEmail?: (data: VoucherModel[]) => void
  onShareByWhatsApp?: (data: VoucherModel[]) => void
  items: VoucherModel[]
  selected: TableRowId[]
  isLoading?: boolean
  size?: ButtonSize
  className?: string
}

export const VoucherTableSelectionActions = ({
  items,
  onDisable,
  onDownload,
  onShareByEmail,
  onShareByWhatsApp,
  selected,
  isLoading,
  size,
  className,
}: VoucherTableSelectionActionsProps) => {
  return (
    <>
      {onDisable && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onDisable}
          selected={selected}
          items={items}
          filter={isVoucherShareable}
          variant='primary'
          className={clsx('text-white', className)}
          disabled={isLoading}
          size={size}
        >
          {isLoading && <Spinner className='me-5' size='lg' />} Disable Vouchers
        </TableSelectionAction>
      )}

      {onDownload && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onDownload}
          selected={selected}
          items={items}
          variant='primary'
          className={clsx('text-white', className)}
          disabled={isLoading}
          size={size}
        >
          {isLoading && <Spinner className='me-5' size='lg' />} Download Voucher
        </TableSelectionAction>
      )}

      {onShareByEmail && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareByEmail}
          selected={selected}
          items={items}
          variant='primary'
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Share by email
        </TableSelectionAction>
      )}

      {onShareByWhatsApp && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareByWhatsApp}
          selected={selected}
          items={items}
          variant='primary'
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Share by whatsApp
        </TableSelectionAction>
      )}
    </>
  )
}
