import {useMemo} from 'react'
import {RowAction} from '../../tables/actions/RowAction'
import {CustomersProductProps} from '../../../models/booking-wizard/BulkBookingWizard'

export interface CustomersProductTableActionsProps {
  onAssignSeats?: (data: CustomersProductProps) => void
  onFulfillNonSeatedProduct?: (data: CustomersProductProps) => void
  onFulfillVoucher?: (data: CustomersProductProps) => void
  data: CustomersProductProps
  loading?: boolean
}

export const CustomersProductTableActions = ({
  data,
  onAssignSeats,
  onFulfillNonSeatedProduct,
  onFulfillVoucher,
  loading,
}: CustomersProductTableActionsProps) => {
  const assignSeats = useMemo(() => {
    if (data.productIsSeated) {
      return (
        <RowAction
          onClick={onAssignSeats}
          data={data}
          loading={loading}
          iconType='Home'
          iconName='Armchair'
          tooltip='Assign seats'
          variant='info'
        />
      )
    }
  }, [data, loading, onAssignSeats])

  const fulfillNonSeatedProduct = useMemo(() => {
    if (!data.productIsSeated && data.isTimeslot) {
      return (
        <RowAction
          onClick={onFulfillNonSeatedProduct}
          data={data}
          loading={loading}
          iconType='Shopping'
          iconName='Barcode-read'
          tooltip='Fulfill Non Seated Product'
          variant='warning'
        />
      )
    }
  }, [data, loading, onFulfillNonSeatedProduct])

  const fulfillVoucher = useMemo(() => {
    if (!data.productIsSeated && data.productType === 'voucher') {
      return (
        <RowAction
          onClick={onFulfillVoucher}
          data={data}
          loading={loading}
          iconType='Shopping'
          iconName='Ticket'
          tooltip='Fulfill Voucher'
          variant='warning'
        />
      )
    }
  }, [data, loading, onFulfillVoucher])

  return (
    <>
      {assignSeats}
      {fulfillNonSeatedProduct}
      {fulfillVoucher}
    </>
  )
}
