import {useMemo} from 'react'
import {useAuthRole} from '../../../../components/hooks/useAuthRole'
import {Auth} from '../../../../utils/RBAC/Role'
import {
  AuthNavigationSectionMenuItem,
  NavigationSectionMenuItem,
} from '../../../../../config/drawer'

export const useAuthMenus = (sections: NavigationSectionMenuItem[]) => {
  const authRole = useAuthRole()

  const rbacMenus = useMemo((): NavigationSectionMenuItem[] => {
    if (!authRole) {
      return []
    }
    return filterMenuAvailability(sections, authRole)
  }, [authRole, sections])

  return rbacMenus
}

const filterMenuAvailability = (
  sections: AuthNavigationSectionMenuItem[],
  auth: Auth
): AuthNavigationSectionMenuItem[] => {
  const rbacMenus: AuthNavigationSectionMenuItem[] = []
  sections.forEach((menu) => {
    if (userHasAccessToMenu(menu, auth)) {
      if (auth.isAdmin() && (
        menu.featureCode === 'VOUCHERCASHIN' || 
        menu.featureCode === 'VOUCHERCASHOUT' ||
        (Array.isArray(menu.featureCode) && menu.featureCode.some(code => code === 'VOUCHERCASHIN' || code === 'VOUCHERCASHOUT'))
      )) {
        return
      }

      if (menu.subMenus) {
        const subMenus = getMenuItems(menu.subMenus, auth)
        if (subMenus.length) {
          rbacMenus.push({
            ...menu,
            subMenus,
          })
        }
      } else {
        rbacMenus.push(menu)
      }
    }
  })
  return rbacMenus
}

const getMenuItems = (
  menus: AuthNavigationSectionMenuItem[],
  auth: Auth
): AuthNavigationSectionMenuItem[] => {
  const rbacMenuItems: AuthNavigationSectionMenuItem[] = []
  menus.forEach((menu) => {
    const isSystemMenuItem = menu.to && menu.to.startsWith('/system') && auth.isAdmin()

    const subMenusArray = menu.subMenus || []
    const hasSystemAccess = subMenusArray.some(subMenu => subMenu.to && subMenu.to.startsWith('/system')) && auth.isAdmin()

    if (menu.featureCode) {
      if (Array.isArray(menu.featureCode)) {
        if (menu.featureCode.some(code => auth.canReadFeature(code))) {
          rbacMenuItems.push(menu)
        }
      } else {
        if (auth.canReadFeature(menu.featureCode)) {
          rbacMenuItems.push(menu)
        }
      }
    } else if (isSystemMenuItem || hasSystemAccess) {
      rbacMenuItems.push(menu)
    } else if (menu.subMenus) {
      const filteredSubMenus = getMenuItems(subMenusArray, auth)
      if (filteredSubMenus.length > 0) {
        rbacMenuItems.push({
          ...menu,
          subMenus: filteredSubMenus
        })
      }
    } else if (!menu.featureCode && menu.applicationCode) {
      const application = auth.getApplication(menu.applicationCode)
      if (application?.hasAnyFeatures()) {
        rbacMenuItems.push(menu)
      }
    }
  })

  return rbacMenuItems
}
const userHasAccessToMenu = (menu: AuthNavigationSectionMenuItem, auth: Auth) => {
  if (menu.isAdminOnly) {
    return auth.isAdmin()
  }
  if (menu.featureCode) {
    if (Array.isArray(menu.featureCode)) {
      return menu.featureCode.some(code => auth.canReadFeature(code))
    } else {
      return auth.canReadFeature(menu.featureCode)
    }
  }
  if (menu.applicationCode) {
    const application = auth.getApplication(menu.applicationCode)
    return application ? auth.isAdmin() || application?.hasAnyFeatures() : false
  }
  return true
}