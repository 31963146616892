import {useCallback, useMemo, useState} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {DateUtil} from '../../../../../utils/DateUtil'
import {
  getPayload,
  SeatMapSelectionModalFormValues,
} from '../../../../default/svc/components/tables/CustomerProductBookingDetailTable/modals/SeatMapSelectionModalForm'
import {useModalState} from '../../../../../components/modals/useModalState'
import {
  FulfillNonSeatedVoucher,
  FulfillTicket,
  FulfillNonSeated,
} from '../../../redux/CustomerDelegateCRUD'
// import {useDispatch} from 'react-redux'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {BookingProductCodeColumn} from './BookingProductCodeColumn'
import {BookingProductStatusColumn} from '../BookingProductStatusColumn'
import {FilterModel} from '../../../../../models/FilterModel'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {useLoadingState} from '../../../../../components/hooks/useLoadingState'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {CustomerBookingTableActions} from './CustomerBookingDetailTableActions'
import {SeatMapPortalSelectionModalForm} from '../../../../../components/forms/SeatMapPortalSelectionModalForm'
import {BookingDetailModel} from '../../../../../models/customer-portal/BookingDetailModel'
// import {actions} from '../../../redux/CustomerPortalRedux'
import {ProductTypeColumn} from '../../../../customer-delegate/components/tables/ProductTypeColumn'
import {CustomerModel} from '../../../../../models/CustomerModel'

export type FromProp = 'bookings' | 'tickets'
export interface CustomerProductBookingDetailTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<BookingDetailModel>
  bookingCode?: string
  customerCode?: string
  onRefresh?: () => void
  onRefreshCallback?: () => void
  toPath?: FromProp
  baseRoute?: string
  customer?: CustomerModel
}

export const CustomerProductBookingDetailTable = ({
  data,
  onFilter,
  onRefresh,
  customerCode,
  onRefreshCallback,
  toPath,
  customer,
}: CustomerProductBookingDetailTableProps) => {
  const {isKeyLoading, setIsLoading} = useLoadingState()
  const {getModalState, open: openModal, hide: hideModal} = useModalState()
  const [activeProduct, setActiveProduct] = useState<BookingDetailModel | null>(null)
  const {push, pushError} = useAlerts()
  // const dispatch = useDispatch()
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-product-booking-detail',
  })

  const getSeatAssignmentHandler = useCallback(
    async (data: BookingDetailModel) => {
      setActiveProduct(data)
      openModal()
    },
    [openModal]
  )

  const fulfillNonSeatedProduct = useCallback(
    async (data: BookingDetailModel) => {
      const bookingProductCode = data.bookingProductCode
      if (bookingProductCode) {
        const doneLoading = setIsLoading(data.code)
        try {
          if (data.bookingProductType === 'voucher')
            await FulfillNonSeatedVoucher(bookingProductCode)
          else await FulfillNonSeated(bookingProductCode)

          setTimeout(() => {
            onRefresh && onRefresh()
            if (onRefreshCallback) {
              // dispatch(actions.bookingProducts.search())
              onRefreshCallback()
            }
            push({
              message: 'Successfully fulfilled ticket.',
              timeout: 5000,
              variant: 'success',
            })
          }, 2000)
          hideModal()
        } catch (e) {
          pushError(e)
        } finally {
          setTimeout(() => {
            doneLoading()
          }, 2000)
        }
      }
    },
    [hideModal, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const handleSelectionSubmit = useCallback(
    async (values: SeatMapSelectionModalFormValues) => {
      if (activeProduct && activeProduct.bookingProductCode) {
        const payload = getPayload(values, activeProduct.bookingProductCode)
        const doneLoading = setIsLoading(payload.productCode)
        try {
          await FulfillTicket(payload)
          setTimeout(() => {
            onRefresh && onRefresh()
            if (onRefreshCallback) {
              // dispatch(actions.bookingProducts.search())
              onRefreshCallback()
              push({
                message: 'Successfully fulfilled ticket.',
                timeout: 5000,
                variant: 'success',
              })
            }
          }, 2000)
          hideModal()
        } catch (e) {
          pushError(e)
        } finally {
          setTimeout(() => {
            doneLoading()
          }, 2000)
        }
      }
    },
    [activeProduct, hideModal, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const idExtractor = useCallback((data: BookingDetailModel) => {
    return data.code
  }, [])

  const rowActions = useCallback(
    (data: BookingDetailModel) => (
      <CustomerBookingTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        onFulfillNonSeatedProduct={fulfillNonSeatedProduct}
        loading={isKeyLoading(data.code)}
      />
    ),
    [fulfillNonSeatedProduct, getSeatAssignmentHandler, isKeyLoading]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<BookingDetailModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        render: ({data}) => BookingProductCodeColumn({data, toPath, customer}),
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'description',
        label: 'Description',
        cellStyle: ColumnStyle.DESCRIPTION,
      },
      {
        field: 'event',
        label: 'Event',
        sortable: true,
        hideable: true,
        dataExtract: (product) => product.eventName,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'productCategory',
        label: 'Category',
        sortable: true,
        hideable: true,
        dataExtract: (product) => product.productCategoryName,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        type: 'date',
        dataExtract: (product) => DateUtil.getDateFromApiString(product.startedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        type: 'date',
        dataExtract: (product) => DateUtil.getDateFromApiString(product.endedAt),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'type',
        label: 'Ticket Type',
        sortable: true,
        hideable: true,
        render: ProductTypeColumn,
      },
      {
        field: 'isSeated',
        label: 'Seated',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isSeated,
      },
      {
        field: 'isConsumable',
        label: 'Consumable',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isConsumable,
      },
      {
        field: 'isTimeslot',
        label: 'Timeslot',
        sortable: true,
        hideable: true,
        type: 'boolean',
        dataExtract: (data) => data.isTimeslot,
      },
      {
        field: 'booking',
        label: 'Qty',
        sortable: false,
        hideable: true,
        type: 'number',
        dataExtract: (product) => product.bookingProductQty,
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: BookingProductStatusColumn,
      },
    ]
    return columns
  }, [customer, toPath])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={hiddenColumns}
        onHiddenColumnsChange={setHiddenColumns}
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columns}
        totalItems={data?.total || 10}
        actions={rowActions}
      />
      <SeatMapPortalSelectionModalForm
        onSubmit={handleSelectionSubmit}
        product={activeProduct}
        customerCode={customerCode}
        {...getModalState()}
      />
    </>
  )
}
