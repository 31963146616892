import {useCallback, useState} from 'react'
import {ISOCountryModel} from '../../../../../../models/ISOCountryModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {CustomerCategoryModel} from '../../../../../../models/svc/CustomerCategoryModel'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {GetISOCountries} from '../../../../redux/CustomerPortalCRUD'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GetCustomerCategoryList} from '../../../../../default/svc/redux/SvcCRUD'

export const useCustomerFormData = () => {
  const [countries, setCountries] = useState<ISOCountryModel[]>([])
  const [categorySearchResults, setCategorySearchResults] =
    useState<GlobalSearchModel<CustomerCategoryModel>>()
  const {pushError} = useAlerts()

  const resetCountries = useCallback(async () => {
    try {
      const {data} = await GetISOCountries()
      setCountries(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [pushError])

  const searchCategories = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetCustomerCategoryList(filter)
        setCategorySearchResults(data)
      } catch (e: any) {
        pushError(e)
      }
    },
    [pushError]
  )

  return {countries, categorySearchResults, searchCategories, resetCountries}
}
