import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {RatingInput} from '../../../../components/inputs/RatingInput/RatingInput'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {PollRatingModel, PollSubmitParams} from '../../../../models/eva/PollRatingModel'
import {PollAnswerModel} from '../../../../models/eva/PollRatingModel'
import {PollQuestionsRatingModel} from '../../../../models/eva/PollRatingModel'
import InputRatingPolls from '../../components/InputRating/Inputrating'
import {getAnswerValueAndDisability} from '../../hooks/usePolls'
import clsx from 'clsx'
import {getPollsByCode, submitPollsAnswers} from '../../redux/EvaCRUD'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useHistory} from 'react-router'
import {DraftJsRenderer} from '../../../../components/utils/DraftJsRenderer'

const PollsRatingPage = () => {
  const [currentPoll, setCurrentPoll] = useState<PollRatingModel>()
  const [pollQuestions, setQuestions] = useState<PollQuestionsRatingModel[]>([])
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [pollAnswers, setPollAnswers] = useState<PollSubmitParams>({pollAnswers: []})
  const history = useHistory()
  const {code} = useParams<{code: string}>()
  const {pushError, push} = useAlerts()
  const goTo = useCallback(
    (path: string) => {
      history.replace(path)
    },
    [history]
  )

  useEffect(() => {
    if (!ticketCode) goTo('/polls')
  }, [ticketCode, goTo])

  const refreshQuestionslist = useCallback(
    async (ticketCode) => {
      const {data} = await getPollsByCode({code, ticketCode})
      setCurrentPoll(data)
    },
    [code]
  )

  useEffect(() => {
    if (ticketCode) refreshQuestionslist(ticketCode)
  }, [refreshQuestionslist, ticketCode])

  useEffect(() => {
    if (currentPoll && currentPoll.pollQuestions) {
      currentPoll.pollQuestions.forEach((question) => {
        question.pollOptions.forEach((item) => {
          item['value'] = false
          item['disable'] = false
        })
      })
      setQuestions(currentPoll.pollQuestions)
    }
  }, [currentPoll])

  const handleInputChange = useCallback(
    (questionCode: string, answerCode: string) => {
      let oldResult = [...pollQuestions]
      const existedQuestion = oldResult.find(
        (item: PollQuestionsRatingModel) => item.code === questionCode
      )
      if (existedQuestion) {
        const existedanswer = existedQuestion.pollOptions.find(
          (item: PollAnswerModel) => item.code === answerCode
        )
        let checkedAnswers: PollAnswerModel[] = []
        if (existedanswer) {
          existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
            if (element.code === answerCode) element.value = !element.value // change the value
            if (element.value === true) checkedAnswers.push(element) // save value if true
            return null
          })
        }
        if (existedQuestion.isMultiple) {
          // if multible selection
          if (checkedAnswers.length === existedQuestion.maximumOptions) {
            // if we reach the limit
            existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
              checkedAnswers.forEach((_checked) => {
                if (element.code !== _checked.code && element.value !== true) {
                  // if the element is not one of the saved values(true)
                  element.disable = true
                } else element.disable = false
              })
            })
          } else {
            existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
              element.disable = false
            })
          }
        } else {
          existedQuestion.pollOptions.forEach((element: PollAnswerModel) => {
            if (element.code === answerCode) {
              element.value = true
            } else element.value = false
          })
        }

        setQuestions(oldResult)
      }
    },
    [pollQuestions]
  )

  const handleRatingValue = useCallback(
    (questionCode: string, value: number) => {
      let oldPollAnswers = {...pollAnswers}
      const existedQuestion = oldPollAnswers.pollAnswers.find(
        (item) => item.pollQuestionCode === questionCode
      )
      if (existedQuestion) {
        existedQuestion.rating = value
      } else {
        oldPollAnswers.pollAnswers.push({
          pollQuestionCode: questionCode,
          rating: value,
        })
      }
      setPollAnswers(oldPollAnswers)
    },
    [pollAnswers]
  )
  useEffect(() => {
    if (disableSubmit && pollAnswers && pollAnswers.pollAnswers.length > 0) setDisableSubmit(false)
  }, [pollAnswers, disableSubmit])

  useEffect(() => {
    let answersArray: boolean = false
    pollQuestions.forEach((_question) => {
      _question.pollOptions.forEach((_answer) => {
        if (_answer.value) {
          answersArray = true
        }
      })
      if (answersArray) setDisableSubmit(false)
      else setDisableSubmit(true)
    })
  }, [pollQuestions])

  const handleVotingSubmit = useCallback(async () => {
    let oldPollAnswers = {...pollAnswers}
    pollQuestions.forEach((_question) => {
      let answersArray: string[] = []
      _question.pollOptions.forEach((_answer) => {
        if (_answer.value) {
          answersArray.push(_answer.code)
        }
      })
      if (answersArray.length > 0)
        oldPollAnswers.pollAnswers.push({
          pollQuestionCode: _question.code,
          pollOptionCodes: answersArray,
        })
    })
    setPollAnswers(oldPollAnswers)
    try {
      if (ticketCode) {
        const result = await submitPollsAnswers({ticketCode, code, body: oldPollAnswers})
        if (result) {
          push({
            message: 'Thank you for your participation.',
            timeout: 3000,
            variant: 'success',
          })
          goTo('/polls')
        }
      }
    } catch (err) {
      pushError(err)
    }
  }, [code, goTo, pollAnswers, pollQuestions, push, pushError, ticketCode])

  return (
    <div className='d-flex flex-column justify-content-between w-100 h-100 overflow-hidden'>
      <div className={clsx('d-flex w-100 bg-body flex-column h-100 overflow-hidden')}>
        <div className='flex-grow-1 p-3 overflow-auto'>
          {currentPoll &&
            currentPoll.pollQuestions &&
            currentPoll.pollQuestions.map((_question, idx) => {
              return (
                <>
                  <div key={_question.code} className='py-3'>
                    <form>
                      <h1 className='mb-5'>{`Q${idx + 1}: ${_question.name}`}</h1>
                      {_question.description && (
                        <div className='pb-2'>
                          <DraftJsRenderer>{_question.description}</DraftJsRenderer>
                        </div>
                      )}
                      {_question.pollOptions &&
                        _question.pollOptions.map((_answer) => {
                          return (
                            <div key={_answer.code} className={['form-check mb-4 ps-5'].join(' ')}>
                              {_question.type !== 'rating' && !_question.isMultiple && (
                                <>
                                  <InputRatingPolls
                                    answerCode={_answer.code}
                                    questionCode={_question.code}
                                    name={_question.name!}
                                    answerText={_answer.name!}
                                    type='radio'
                                    result={pollQuestions}
                                    onChange={handleInputChange}
                                  />
                                </>
                              )}

                              {_question.type !== 'rating' && _question.isMultiple && (
                                <>
                                  <InputRatingPolls
                                    answerCode={_answer.code}
                                    questionCode={_question.code}
                                    name={_question.name!}
                                    answerText={_answer.name!}
                                    type='checkBox'
                                    result={pollQuestions}
                                    onChange={handleInputChange}
                                    disabled={
                                      getAnswerValueAndDisability(
                                        _question.code,
                                        _answer.code,
                                        pollQuestions
                                      )[1]
                                    }
                                  />
                                </>
                              )}
                            </div>
                          )
                        })}
                      {_question.type === 'rating' && (
                        <div className='d-flex justify-content-center'>
                          <RatingInput
                            width={30}
                            height={30}
                            className='mx-2'
                            questionCode={_question.code}
                            count={5}
                            onChange={handleRatingValue}
                          />
                        </div>
                      )}
                    </form>
                  </div>
                  {currentPoll.pollQuestions && idx < currentPoll.pollQuestions.length - 1 && (
                    <HorizontalDivider />
                  )}
                </>
              )
            })}
        </div>

        <div className='text-primary'>
          <button
            disabled={disableSubmit}
            type='button'
            className='btn btn-md w-100 btn-light-primary rounded-0 text-uppercase fullwidth'
            onClick={handleVotingSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export {PollsRatingPage}
