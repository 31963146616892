import {SeatMapValue} from './SeatMapValue'
import {SeatValue} from './SeatValue'

export interface SeatMapSpacingObject {
  [key: string]: number
}

export class SeatMapSpacing {
  constructor(private seat: SeatMapSpacingObject = {}) {}

  public getSeatSpacingX = (seat: SeatValue, isRtl?: boolean) => {
    const seatA = seat
    let count = 0
    Object.entries(this.seat).forEach(([seatLabel, spacing]) => {
      const seatB = new SeatValue(seatLabel)
      const columnComparison = seatA.compareColumn(seatB)
      const rowComparison = seatA.compareRow(seatB)
      if (isRtl) {
        if (columnComparison === 0 && rowComparison <= 0) {
          count += spacing
        }
      } else {
        if (columnComparison === 0 && rowComparison >= 0) {
          count += spacing
        }
      }
    })
    return count
  }

  private static forEachSeats = (seatMap: SeatMapValue, callback: (seat: SeatValue) => void) => {
    const seatMapObject = seatMap.getSeatMapObject()
    Object.entries(seatMapObject).forEach(([row, columns]) => {
      columns.forEach((column) => {
        const seat = new SeatValue(row, column)
        callback(seat)
      })
    })
  }

  public incrementSpacingFromSeatMapValue = (seatMap: SeatMapValue) => {
    SeatMapSpacing.forEachSeats(seatMap, (seat) => {
      this.incrementSpacing(seat)
    })
  }

  public decrementSpacingFromSeatMapValue = (seatMap: SeatMapValue) => {
    SeatMapSpacing.forEachSeats(seatMap, (seat) => {
      this.decrementSpacing(seat)
    })
  }

  public incrementSpacing = (seat: SeatValue) => {
    const key = seat.getValue()
    if (this.seat[key]) {
      this.seat[key]++
    } else {
      this.seat[key] = 1
    }
  }

  public decrementSpacing = (seat: SeatValue) => {
    const key = seat.getValue()
    if (this.seat[key]) {
      this.seat[key]--
      if (this.seat[key] <= 0) {
        delete this.seat[key]
      }
    }
  }

  public toObject = () => {
    return {...this.seat}
  }

  public clone = () => {
    return new SeatMapSpacing(this.toObject())
  }
}
