import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {Cart} from '../../../utils/Cart'
import {VatExclusivePriceTag} from '../../../utils/VatExclusivePriceTag'
import {actions} from '../redux/DigitalMenuRedux'

export const useCart = (outletCodeParam?: string) => {
  const {outletCode: outletCodePathParam} = useParams<{outletCode?: string}>()
  const outlets = useRootStateSelector((state) => state.digitalMenu.order)
  const dispatch = useDispatch()

  const outletCode = useMemo(() => {
    const outletCode = outletCodeParam || outletCodePathParam
    return outletCode
  }, [outletCodeParam, outletCodePathParam])

  const cart = useMemo(() => {
    const cart = new Cart()

    if (outletCode) {
      const cartItems = outlets[outletCode]

      if (cartItems) {
        Object.entries(cartItems.cart).forEach(([key, value]) => {
          const price = new VatExclusivePriceTag(value.rawPrice, 'AED', value.vat)
          cart.addItem(key, value.label, price, value.count)
        })
      }
    }

    return cart
  }, [outletCode, outlets])

  const setCart = useCallback(
    (cart: Cart) => {
      if (outletCode) {
        dispatch(actions.cart.setCart(outletCode, cart.toObject()))
      }
    },
    [dispatch, outletCode]
  )

  const clearCart = useCallback(() => {
    setCart(new Cart())
  }, [setCart])

  return useMemo(() => ({cart, setCart, clearCart}), [cart, setCart, clearCart])
}
