import {FormikContextType} from 'formik'
import {ChangeEvent, useCallback, useMemo} from 'react'
import * as yup from 'yup'
import {
  OrderModelCreateParams,
  OrderModelCreateParamsProducts,
  OrderModelStatus,
} from '../../../../models/fnb/OrderModel'
import {OutletOrderType} from '../../../../models/fnb/OutletModel'
import {Validators} from '../../../../utils/Validators'
import {Cart} from '../../../../utils/Cart'
import {OutletOrderTypeIconButton} from '../OutletOrderTypeIconButton'
import {getTooltip} from '../OutletOrderTypeButton'
import {TextInput} from '../../../../components/inputs'
import {OrderInput} from '../inputs/OrderInput/OrderInput'
import {PaymentSummary} from '../PaymentSummary'
import {Button} from '../../../../components/inputs/Button'
import {LoadingSpinner} from '../../../../components/utils/LoadingSpinner'
import {MobileNumberInput} from '../../../../components/inputs/MobileNumberInput'
import {useFormikMobileNumberInputHelpers} from '../../../../components/inputs/useFormikMobileNumberInputHelpers'
import {MobileNumberParser} from '../../../../utils/MobileNumberParser'

export type CreateOrderFormSubmitType = 'pay-now' | 'pay-later' | 'print' | 'cancel'

export interface CreateOrderFormValues {
  tableNo: string
  customerName: string
  mobileNumber: string
  orderType: string
  cart: Cart
  submitType: CreateOrderFormSubmitType | null
}

export interface CreateOrderFormProps<T extends CreateOrderFormValues> {
  formik: FormikContextType<T>
  onPrint?: (values: T) => void
  onPay?: (values: T) => void
  onPayLater?: (values: T) => void
  onCancel?: (values: T) => void
  availableOrderTypes?: OutletOrderType[]
  onBack?: () => void
  isPaid?: boolean
  loading?: boolean
  paymentRemaining?: number
}

export const CreateOrderForm = <T extends CreateOrderFormValues>({
  availableOrderTypes,
  onCancel,
  onPay,
  onPrint,
  onBack,
  onPayLater,
  isPaid,
  formik,
  loading,
  paymentRemaining,
}: CreateOrderFormProps<T>) => {
  const getOrderTypeButtonVariant = useCallback(
    (orderType: OutletOrderType) => {
      if (formik.values.orderType === orderType) {
        return 'primary'
      }
      return 'light'
    },
    [formik.values.orderType]
  )

  const handleOrderTypeSelect = useCallback(
    (orderType: OutletOrderType) => {
      formik.setFieldValue('orderType', orderType)
    },
    [formik]
  )

  const getSubmitHandler = useCallback(
    (click: CreateOrderFormSubmitType) => () => {
      formik.setFieldValue('submitType', click)
      switch (click) {
        case 'pay-now':
          onPay && onPay(formik.values)
          break
        case 'pay-later':
          onPayLater && onPayLater(formik.values)
          break
        case 'print':
          onPrint && onPrint(formik.values)
          break
        case 'cancel':
          onCancel && onCancel(formik.values)
          break
      }
    },
    [formik, onCancel, onPay, onPayLater, onPrint]
  )

  const handleCartChange = useCallback(
    (cart: Cart) => {
      formik.setFieldValue('cart', cart)
    },
    [formik]
  )

  const handleTableNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      let newValue = value.replace(/^0+/, '').padStart(4, '0').slice(-4)
      formik.setFieldValue('tableNo', newValue)
    },
    [formik]
  )

  const numberInputHandler = useFormikMobileNumberInputHelpers(formik)

  const isLoading = useMemo(() => {
    return Boolean(formik.isSubmitting || loading)
  }, [formik.isSubmitting, loading])

  return (
    <>
      <form className='d-flex flex-column h-100'>
        <div className='d-flex justify-content-between'>
          <OutletOrderTypeIconButton
            orderType='delivery'
            variant={getOrderTypeButtonVariant('delivery')}
            availableOrderTypes={availableOrderTypes}
            onClick={handleOrderTypeSelect}
            tooltip={getTooltip('delivery')}
            type='button'
          />
          <OutletOrderTypeIconButton
            orderType='dine-in'
            variant={getOrderTypeButtonVariant('dine-in')}
            onClick={handleOrderTypeSelect}
            availableOrderTypes={availableOrderTypes}
            tooltip={getTooltip('dine-in')}
            type='button'
          />
          <OutletOrderTypeIconButton
            orderType='pickup'
            variant={getOrderTypeButtonVariant('pickup')}
            onClick={handleOrderTypeSelect}
            availableOrderTypes={availableOrderTypes}
            tooltip={getTooltip('pickup')}
            type='button'
          />
          <OutletOrderTypeIconButton
            orderType='takeaway'
            variant={getOrderTypeButtonVariant('takeaway')}
            onClick={handleOrderTypeSelect}
            availableOrderTypes={availableOrderTypes}
            tooltip={getTooltip('takeaway')}
            type='button'
          />
        </div>
        <div className='flex-grow-1 overflow-auto py-2'>
          <TextInput
            label='Customer Name'
            placeholder='Enter customer name'
            errorMessage={formik.errors.customerName as string}
            isTouched={formik.touched.customerName as boolean}
            {...formik.getFieldProps('customerName')}
            disabled={isPaid}
          />
          {formik.values.orderType === 'dine-in' && (
            <TextInput
              label='Table Number'
              placeholder='Please enter table number.'
              {...formik.getFieldProps('tableNo')}
              onChange={handleTableNumberChange}
              errorMessage={formik.errors.tableNo as string}
              isTouched={formik.touched.tableNo as boolean}
              disabled={isPaid}
            />
          )}
          {formik.values.orderType === 'delivery' && (
            <>
              <MobileNumberInput
                label='Mobile Number'
                placeholder='Enter mobile number'
                disabled={isPaid}
                {...numberInputHandler.getFieldProps('mobileNumber')}
              />
            </>
          )}
          <OrderInput value={formik.values.cart} onChange={handleCartChange} disabled={isPaid} />
        </div>
        <div className='border border-1 p-3 mb-3 bg-light'>
          <PaymentSummary
            fontSize={1}
            paymentRemaining={paymentRemaining}
            cart={formik.values.cart}
          />
        </div>
        <div>
          {onPrint && isPaid && (
            <div className='mb-2'>
              <Button
                disabled={formik.isSubmitting || loading}
                className='fs-2'
                onClick={getSubmitHandler('print')}
                fullWidth
                type='button'
                variant='info'
              >
                <LoadingSpinner loading={isLoading}>Print Bill</LoadingSpinner>
              </Button>
            </div>
          )}
          {onPay && !isPaid && (
            <Button
              onClick={getSubmitHandler('pay-now')}
              type='button'
              className='mb-2 fs-2'
              variant='primary'
              fullWidth
              disabled={!formik.isValid || isLoading}
            >
              <LoadingSpinner spinnerOnly loading={isLoading}>
                Pay Now
              </LoadingSpinner>
            </Button>
          )}
          {onPayLater && !isPaid && (
            <Button
              onClick={getSubmitHandler('pay-later')}
              type='button'
              className='mb-2 fs-2'
              fullWidth
              variant='warning'
              disabled={!formik.isValid || isLoading}
            >
              <LoadingSpinner spinnerOnly loading={isLoading}>
                Pay Later
              </LoadingSpinner>
            </Button>
          )}
          {onCancel && (
            <Button
              className='mb-2 fs-2'
              onClick={getSubmitHandler('cancel')}
              type='button'
              fullWidth
              variant='danger'
            >
              Cancel
            </Button>
          )}
          {onBack && (
            <Button onClick={onBack} className='fs-2' type='button' fullWidth variant='default'>
              Back
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export const EMPTY_INITIAL_VALUES: CreateOrderFormValues = {
  customerName: '',
  mobileNumber: '',
  orderType: '',
  tableNo: '',
  cart: new Cart(),
  submitType: null,
}

export const validationSchema = yup.object().shape({
  customerName: yup.string(),
  mobileNumber: yup
    .mixed()
    .test('mobile-required', 'Enter a valid mobile number.', (value: string, context) => {
      const values = context.parent as CreateOrderFormValues
      if (values.orderType === 'delivery') {
        return new MobileNumberParser(value || '').isValidMobileNumber()
      }
      return true
    }),
  tableNo: yup.string().when('orderType', {
    is: 'dine-in',
    then: (schema) =>
      schema
        .required('Table number is required')
        .length(4, 'Please provide four digits.')
        .test('is-required', 'Table number is required', (value) => {
          return value !== '0000'
        })
        .test('is-number', 'Please enter numbers only.', (value) => {
          return Validators.WHOLE_NUMBER_REGEX.test(value || '')
        }),
  }),
  cart: yup.object().test('cart-item-required', 'Select at least one item.', (value) => {
    if (value instanceof Cart) {
      return value.getItemCount() > 0
    }
    return true
  }),
})

export const getPayload = (
  values: CreateOrderFormValues,
  variables: {status: OrderModelStatus}
): OrderModelCreateParams => {
  const products: OrderModelCreateParamsProducts[] = []

  values.cart.forEach((code, value) => {
    products.push({
      code,
      notes: '',
      qty: value.count,
    })
  })

  const payload: OrderModelCreateParams = {
    name: values.customerName,
    mobile: values.orderType === 'delivery' ? values.mobileNumber : null,
    tableNo: values.orderType === 'dine-in' ? values.tableNo : null,
    orderType: values.orderType as OutletOrderType,
    products,
    locationCode: null,
    ...variables,
  }

  return payload
}
