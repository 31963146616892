import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {Validators} from '../../../utils/Validators'
import {GetOrderByMobileNumber} from '../redux/DigitalMenuCRUD'
import {actions} from '../redux/DigitalMenuRedux'

export const useOrders = () => {
  const [orders, setOrders] = useState<OrderModel[] | null>(null)
  const mobileNumber = useRootStateSelector((state) => state.digitalMenu.profile.mobileNumber)
  const {push} = useAlerts()
  const dispatch = useDispatch()

  const setMobileNumber = useCallback(
    (value: string) => {
      dispatch(actions.profile.setMobileNumber(value))
    },
    [dispatch]
  )

  const searchOrders = useCallback(async () => {
    if (Validators.INTERNATIONAL_MOBILE_NUMBER_REGEX.test(mobileNumber)) {
      setOrders(null)
      const {data} = await GetOrderByMobileNumber(mobileNumber)
      setOrders(data.data)
    } else if (mobileNumber) {
      push({message: 'Please enter a valid mobile number', timeout: 5000, variant: 'danger'})
    }
  }, [mobileNumber, push])

  return useMemo(
    () => ({setMobileNumber, searchOrders, orders, mobileNumber}),
    [mobileNumber, orders, searchOrders, setMobileNumber]
  )
}
