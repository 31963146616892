import {useMemo} from 'react'
import {Button} from '../../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'

export interface BookingTableActionsProps {
  onNewBookingClick?: () => void
  onNewBulkBookingClick?: () => void
}
export const BookingTableRightActions = ({
  onNewBookingClick,
  onNewBulkBookingClick,
}: BookingTableActionsProps) => {
  const addButton = useMemo(() => {
    if (onNewBookingClick) {
      return (
        <Button variant='primary' onClick={onNewBookingClick}>
          <MetronicIcon iconType='Navigation' iconName='Plus' /> Single Booking
        </Button>
      )
    }
    return null
  }, [onNewBookingClick])

  const addBulkButton = useMemo(() => {
    if (onNewBulkBookingClick) {
      return (
        <Button variant='primary' onClick={onNewBulkBookingClick}>
          <MetronicIcon iconType='Navigation' iconName='Plus' /> Bulk Booking
        </Button>
      )
    }
    return null
  }, [onNewBulkBookingClick])
  return (
    <>
      {addButton}
      {addBulkButton}
    </>
  )
}
