export const SharedWaiverDescription = () => {
  return (
    <div className='text-white'>
      <p>
        Here, you can view and easily keep track of active tickets you’ve shared with your guests.
        We look forward to hosting your driving experience at Yas Marina Circuit - The Meeting Place
        of Champions™.
      </p>

      <p className='ff-admm-bold'>IMPORTANT INFORMATION:</p>

      <div>
        <ul>
          <li>Do not share same ticket multiple times.</li>
          <li>Advise respective participant to submit waiver before arrival to the circuit.</li>
        </ul>
      </div>
    </div>
  )
}
