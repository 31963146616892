import {useCallback} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {FilterModel} from '../../../../models/FilterModel'
import {ReservationModel} from '../../../../models/fnb/ReservationModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {BaseReservationTable} from '../../../default/fnb/components/tables/BaseReservationTable'
import {ReservationSwalUtils} from '../../../default/fnb/utils/ReservationSwalUtils'
import {UpdateTableReservationStatus} from '../../redux/OutletCRUD'

export interface ReservationTableProps {
  data?: GlobalSearchModel<ReservationModel>
  onFilter: (filter: FilterModel) => void
  onRefresh?: () => void
  onEdit?: (data: ReservationModel) => void
  onCreateClick?: () => void
}

export const ReservationTable = ({
  onFilter,
  data,
  onRefresh,
  onEdit,
  onCreateClick,
}: ReservationTableProps) => {
  const {push, pushError} = useAlerts()

  const handleApprove = useCallback(
    (data: ReservationModel) => {
      const outlet = data.outlet
      if (!outlet) {
        push({
          message: 'Cannot approve outlet, data is invalid. Please contact support.',
          variant: 'danger',
          timeout: 6000,
        })
        return
      }
      ReservationSwalUtils.approveReservation(async (tableNumber) => {
        try {
          await UpdateTableReservationStatus(data.code, {
            status: 'pre-approved',
            tableNo: parseInt(tableNumber),
          })
          onRefresh && onRefresh()
        } catch (e) {
          pushError(e)
        }
      })
    },
    [onRefresh, push, pushError]
  )

  const handleDeny = useCallback(
    (data: ReservationModel) => {
      const outlet = data.outlet
      if (!outlet) {
        push({
          message: 'Cannot approve outlet, data is invalid. Please contact support.',
          variant: 'danger',
          timeout: 6000,
        })
        return
      }
      ReservationSwalUtils.cancelReservation(async (reason) => {
        try {
          await UpdateTableReservationStatus(data.code, {
            status: 'cancelled',
            reason,
          })
          onRefresh && onRefresh()
        } catch (e) {
          pushError(e)
        }
      })
    },
    [onRefresh, push, pushError]
  )

  return (
    <BaseReservationTable
      data={data}
      onFilter={onFilter}
      tableName='outlet-reservation'
      removeColumns={REMOVED_COLUMNS}
      onApprove={handleApprove}
      onDeny={handleDeny}
      onEdit={onEdit}
      onCreateClick={onCreateClick}
    />
  )
}

const REMOVED_COLUMNS = ['outlet']
