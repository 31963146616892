import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {MetronicIconButton} from '../MetronicIconButton'
import {TreeSelectNode} from '../TreeSelect/TreeSelectNode'
import {TreeSelectNodeCollection} from '../TreeSelect/TreeSelectNodeCollection'
import {TreeNodeCheckboxLastLevel} from './TreeNodeCheckboxLastLevel'

export interface TreeNodeLastLevelProps {
  node: TreeSelectNode
  tree: TreeSelectNodeCollection
  onChange: (value: TreeSelectNodeCollection, node: TreeSelectNode, isChecked: boolean) => void
  radioName?: string
  level: number
  onBlur?: () => void
  isLastLevelSelect?: boolean
  disabled?: (item: TreeSelectNode) => boolean
}

export const TreeNodeLastLevel = ({
  onChange,
  node,
  radioName,
  isLastLevelSelect = false,
  level,
  tree,
  onBlur,
  disabled,
}: TreeNodeLastLevelProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [removeParentRadioBtn, setRemoveParentRadioBtn] = useState(false)
  const handleCheckboxChange = useCallback(
    (isChecked: boolean) => {
      tree.setNodeValue(node.id, isChecked)
      onChange(tree, node, isChecked)
    },
    [onChange, tree, node]
  )

  const itemElements = useMemo(() => {
    const nodes: ReactNode[] = []
    if (node.children.length && isCollapsed) {
      node.children.forEach((node) => {
        nodes.push(
          <TreeNodeLastLevel
            onBlur={onBlur}
            key={node.id}
            level={level + 1}
            node={node}
            onChange={onChange}
            tree={tree}
            radioName={radioName}
          />
        )
      })
    }
    return nodes
  }, [node.children, isCollapsed, onBlur, level, onChange, tree, radioName])

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  const collapseButton = useMemo(() => {
    if (!node.children.length) {
      return (
        <button
          type='button'
          className='btn btn-sm btn-icon bt-active-light-primary flex-shrink-0'
          style={{display: 'inline-block', cursor: 'default'}}
        >
          &nbsp;
        </button>
      )
    }
    return (
      <MetronicIconButton
        className='flex-shrink-0'
        onClick={toggleCollapse}
        size='sm'
        type='button'
        iconType='Navigation'
        iconName={isCollapsed ? 'Angle-down' : 'Angle-right'}
      />
    )
  }, [node, toggleCollapse, isCollapsed])

  useEffect(() => {
    if (radioName && node.hasChildrenSelected() && isLastLevelSelect) {
      setIsCollapsed(true)
      setRemoveParentRadioBtn(true)
    } else {
    }
  }, [isLastLevelSelect, node, radioName])

  useEffect(() => {
    if (radioName && node.children.length) {
      setRemoveParentRadioBtn(true)
    }
  }, [node, radioName])

  return (
    <div
      style={{
        marginLeft: `${Math.min(level, 1)}rem`,
        display: 'flex',
      }}
    >
      {collapseButton}
      <div
        className='my-2'
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TreeNodeCheckboxLastLevel
          onBlur={onBlur}
          node={node}
          onChange={handleCheckboxChange}
          radioName={radioName}
          removeParentRadioBtn={removeParentRadioBtn}
          disabled={disabled}
        />
        {itemElements}
      </div>
    </div>
  )
}
