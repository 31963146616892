import {SweetAlertCustomClass} from 'sweetalert2'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {SweetAlert} from '../../../../components/modals/SweetAlert'
import {OutletOrderType} from '../../../../models/fnb/OutletModel'
import {OutletOrderTypeButton} from '../OutletOrderTypeButton'

export interface OrderTypeSelectModalProps {
  open: boolean
  onClose: () => void
  onSelect: (orderType: OutletOrderType) => void
  orderTypes: OutletOrderType[]
}

export const OrderTypeSelectModal = ({
  onClose,
  onSelect,
  open,
  orderTypes,
}: OrderTypeSelectModalProps) => {
  return (
    <SweetAlert
      open={open}
      onClose={onClose}
      showConfirmButton={false}
      customClass={customClass}
      width='auto'
    >
      <div className='d-flex justify-content-between align-items-start'>
        <h2 className='mb-8'>Order Type</h2>
        <MetronicIconButton
          onClick={onClose}
          variant='text'
          size='sm'
          iconType='Navigation'
          iconName='Close'
        />
      </div>
      <div className='mb-4'>
        <OutletOrderTypeButton
          className='me-4'
          variant='primary'
          orderType='takeaway'
          availableOrderTypes={orderTypes}
          onClick={onSelect}
        />
        <OutletOrderTypeButton
          variant='primary'
          orderType='delivery'
          availableOrderTypes={orderTypes}
          onClick={onSelect}
        />
      </div>
      <div>
        <OutletOrderTypeButton
          className='me-4'
          variant='primary'
          orderType='dine-in'
          availableOrderTypes={orderTypes}
          onClick={onSelect}
        />
        <OutletOrderTypeButton
          variant='primary'
          orderType='pickup'
          availableOrderTypes={orderTypes}
          onClick={onSelect}
        />
      </div>
    </SweetAlert>
  )
}

const customClass: SweetAlertCustomClass = {
  htmlContainer: 'm-6',
}
