import {useMemo} from 'react'
import clsx from 'clsx'
import {ProductCategoryWidgetModel} from '../../../../models/customer-portal/WidgetModel'
interface SubProductsCardProps {
  items?: ProductCategoryWidgetModel[]
}

export const SubProductsCard = ({items}: SubProductsCardProps) => {
  const subProducts = useMemo(() => {
    return items && items.length > 0
      ? items.flatMap((item, idx) =>
          item.products.map((product, prodIdx) => {
            return (
              <div
                className='col-12 row p-0 m-0'
                key={`${item.code}-${prodIdx}`}
                style={{height: '75px'}}
              >
                <div
                  className={clsx(
                    'col-8  d-flex flex-grow-1 px-0 mx-0 border border-gray border-start-0',
                    idx === items.length - 1 ? 'border-bottom-1' : 'border-bottom-0'
                  )}
                >
                  <div
                    className='h-100 w-100 ff-admm-bold d-flex justify-content-start align-items-center text-white fs-1 text-center bg-primary px-10'
                    style={{
                      boxShadow: '0 1rem 2rem 1rem rgb(0 0 0 / 30%)',
                    }}
                  >
                    <div>{product.name}</div>
                  </div>
                </div>

                <div
                  className={clsx(
                    'col-4 d-flex px-0 mx-0 text-center',
                    'border border-gray rounded-0 border-start-0',
                    idx === items.length - 1 ? 'border-bottom-1' : 'border-bottom-0'
                  )}
                >
                  <div className='w-100 d-flex flex-column justify-content-center'>
                    <div className='fs-7 text-white ff-admm-bold text-uppercase'>Distributed</div>
                    <div
                      className='text-white ff-admm-bold mb-0'
                      style={{
                        fontSize: '22px',
                        lineHeight: '30px',
                      }}
                    >
                      {product.qty}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        )
      : null
  }, [items])

  return (
    <div
      className='container-fluid px-0 mx-0'
      style={{
        maxHeight: '305px',
        overflowY: 'auto',
      }}
    >
      <div className='row px-0 mx-0'>
        <div className='col-12 px-0 mx-0 row text-uppercase'>{subProducts}</div>
      </div>
    </div>
  )
}
