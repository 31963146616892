import {FormikContextType} from 'formik'
import {ChangeEvent, useCallback, useMemo} from 'react'
import {PAYFORT_TOKENIZATION_URL} from '../../../../../../config/env'
import {TextInput} from '../../../../../components/inputs'
import {PasswordInput} from '../../../../../components/inputs/PasswordInput'
import {SelectInput, SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {Validators} from '../../../../../utils/Validators'
import {ConfirmPageLayout} from '../../../layout/ConfirmPageLayout'

export interface CardPaymentMethodFormValues {
  payfortAccessCode: string
  payfortMerchantIdentifier: string
  payfortMerchantReference: string
  payfortExpiryDate: string
  payfortCardNumber: string
  payfortCardSecurityCode: string
  payfortSignature: string
  payfortReturnUrl: string
  cardNumber: string
  cvv: string
  cardExpiryMonth: string
  cardExpiryYear: string
  customerEmail: string
}

export interface CardPaymentMethodProps<T extends CardPaymentMethodFormValues> {
  onBack: () => void
  formik: FormikContextType<T>
  onPay: () => void
  formRef?: React.Ref<HTMLFormElement> | null
  disableSubmit?: boolean
}

export const CardPaymentMethod = <T extends CardPaymentMethodFormValues>({
  onBack,
  onPay,
  formik,
  formRef,
  disableSubmit,
}: CardPaymentMethodProps<T>) => {
  const formHasErrors = useMemo(() => {
    return Boolean(
      formik.errors.cardNumber ||
        formik.errors.cardExpiryMonth ||
        formik.errors.cardExpiryYear ||
        formik.errors.cvv ||
        formik.errors.customerEmail
    )
  }, [formik.errors])

  const cardValue = useMemo(() => {
    return formik.values.cardNumber
      .split(/(.{4})/)
      .filter((t) => Boolean(t))
      .join(' ')
  }, [formik.values.cardNumber])

  const handleCardChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.split(' ').join('')
      const LIMIT = 16
      if (value.length <= LIMIT && Validators.WHOLE_NUMBER_REGEX.test(value)) {
        formik.setFieldValue('cardNumber', value)
      }
    },
    [formik]
  )

  const handleCvvChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const LIMIT = 3
      const isNumber = Validators.WHOLE_NUMBER_REGEX.test(value)
      if (isNumber && value.length <= LIMIT) {
        formik.setFieldValue('cvv', value)
      }
    },
    [formik]
  )

  const yearList = useMemo((): SelectInputItem[] => {
    const currentYear = new Date().getFullYear()
    const YEARS_COUNT = 15
    return Array.from({length: YEARS_COUNT}, (v, i) => {
      const year = currentYear + i
      const value = year.toString()

      return {
        label: value,
        value: value.substring(2),
      }
    })
  }, [])

  return (
    <ConfirmPageLayout
      disableButton={disableSubmit || formik.isSubmitting || formHasErrors}
      onBackClick={onBack}
      headerLabel='Payment Method'
      buttonLabel='Pay'
      onButtonClick={onPay}
    >
      <TextInput
        {...formik.getFieldProps('customerEmail')}
        isTouched={formik.touched.customerEmail as boolean}
        errorMessage={formik.errors.customerEmail as string}
        label='Email'
      />
      <TextInput {...formik.getFieldProps('cardHolder')} label='Card Holder Name' />
      <TextInput
        {...formik.getFieldProps('cardNumber')}
        isTouched={formik.touched.cardNumber as boolean}
        errorMessage={formik.errors.cardNumber as string}
        label='Card Number'
        value={cardValue}
        onChange={handleCardChange}
        inputClassName='credit-card-input'
      />
      <div className='row'>
        <div className='col-6 col-md-3'>
          <SelectInput
            items={yearList}
            {...formik.getFieldProps('cardExpiryYear')}
            label='Year'
            placeholder='Select Year'
          />
        </div>
        <div className='col-6 col-md-3'>
          <SelectInput
            items={MONTH_LIST}
            {...formik.getFieldProps('cardExpiryMonth')}
            label='Month'
            placeholder='Select Month'
          />
        </div>
        <div className='col-12 col-md-6'>
          <PasswordInput
            {...formik.getFieldProps('cvv')}
            isTouched={formik.touched.cvv as boolean}
            errorMessage={formik.errors.cvv as string}
            label='CVV'
            autoComplete='off'
            onChange={handleCvvChange}
            placeholder='Please enter CVV'
          />
        </div>
      </div>
      <div>
        <form className='d-none' method='POST' action={PAYFORT_TOKENIZATION_URL} ref={formRef}>
          <input
            placeholder='sevice_command'
            name='service_command'
            readOnly
            value='TOKENIZATION'
          />
          <input
            placeholder='access_code'
            readOnly
            name='access_code'
            value={formik.values.payfortAccessCode}
          />
          <input
            placeholder='merchant_identifier'
            readOnly
            name='merchant_identifier'
            value={formik.values.payfortMerchantIdentifier}
          />
          <input
            placeholder='merchant_reference'
            readOnly
            name='merchant_reference'
            value={formik.values.payfortMerchantReference}
          />
          <input placeholder='language' readOnly name='language' value='en' />
          <input
            placeholder='expiry_date'
            readOnly
            name='expiry_date'
            value={formik.values.payfortExpiryDate}
          />
          <input
            placeholder='card_number'
            readOnly
            name='card_number'
            value={formik.values.payfortCardNumber}
          />
          <input
            placeholder='card_security_code'
            readOnly
            name='card_security_code'
            value={formik.values.payfortCardSecurityCode}
          />
          <input
            placeholder='signature'
            readOnly
            name='signature'
            value={formik.values.payfortSignature}
          />
          <input
            placeholder='return_url'
            readOnly
            name='return_url'
            value={formik.values.payfortReturnUrl}
          />
          <button>submit</button>
        </form>
      </div>
    </ConfirmPageLayout>
  )
}

const MONTH_LIST: SelectInputItem[] = [
  {
    label: 'January',
    value: '01',
  },
  {
    label: 'February',
    value: '02',
  },
  {
    label: 'March',
    value: '03',
  },
  {
    label: 'April',
    value: '04',
  },
  {
    label: 'May',
    value: '05',
  },
  {
    label: 'June',
    value: '06',
  },
  {
    label: 'July',
    value: '07',
  },
  {
    label: 'August',
    value: '08',
  },
  {
    label: 'September',
    value: '09',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
]
