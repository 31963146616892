import {useCallback} from 'react'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {BookingModel} from '../../../../../models/ems/BookingModel'

export interface BookingActionsProps {
  selected: TableRowId[]
  bookings: BookingModel[]
  onCancel?: (booking: BookingModel[]) => void
  onSend?: (booking: BookingModel[]) => void
  onPrint?: (booking: BookingModel[]) => void
  loading?: boolean
}

export const BookingTableActions = ({
  onCancel,
  onPrint,
  onSend,
  bookings,
  selected,
  loading,
}: BookingActionsProps) => {
  const idExtractor = useCallback((item: BookingModel) => item.code, [])

  return (
    <>
      <TableSelectionAction
        disabled={loading}
        variant='info'
        onClick={onSend}
        idExtractor={idExtractor}
        items={bookings}
        selected={selected}
        filter={bookingIsSendable}
      >
        Send
      </TableSelectionAction>
      <TableSelectionAction
        disabled={loading}
        variant='primary'
        onClick={onPrint}
        idExtractor={idExtractor}
        items={bookings}
        selected={selected}
        filter={bookingIsPrintable}
      >
        Print
      </TableSelectionAction>
      <TableSelectionAction
        disabled={loading}
        variant='danger'
        onClick={onCancel}
        idExtractor={idExtractor}
        items={bookings}
        selected={selected}
        filter={bookingIsCancellable}
      >
        Cancel
      </TableSelectionAction>
    </>
  )
}

export const bookingIsCancellable = (booking: BookingModel) => {
  return booking.status !== 'cancelled'
}

export const bookingIsSendable = (booking: BookingModel) => {
  return booking.status === 'fulfilled'
}

export const bookingIsPrintable = (booking: BookingModel) => {
  return booking.status === 'fulfilled'
}

export const bookingIsShippable = (booking: BookingModel) => {
  return !booking.awb && ['fulfilled', 'active'].includes(booking.status)
}

export const bookingIsEditable = (booking: BookingModel) => {
  return booking.status === 'confirmed'
}
