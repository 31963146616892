import {useMemo} from 'react'
import {Button} from '../../../../components/inputs/Button'
import {ResponsiveModal} from '../../../../components/modals/ResponsiveModal'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {LoadingSpinner} from '../../../../components/utils/LoadingSpinner'
import {TicketDetailSeatMaps} from './TicketsDetailsSeatMaps'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'

export interface SeatMapSelectionModalInputProps {
  open: boolean
  onHide: () => void
  onSubmit: () => void
  disableSubmit?: boolean
  loading?: boolean
  disableSelection?: boolean
  ticket?: TicketPortalModel | null
  setSelected: (seats: SeatMapValue) => void
  selected?: SeatMapValue
}

export const SeatMapSelectionModalInput = ({
  open,
  onHide,
  onSubmit,
  disableSubmit,
  loading = false,
  ticket,
  setSelected,
  selected,
}: SeatMapSelectionModalInputProps) => {
  const getModalTitle = useMemo(() => {
    return ticket?.productName || ''
  }, [ticket?.productName])

  const header = useMemo(() => {
    const title = `Please select seat.`
    let subtitle: string = ''
    if (getModalTitle) {
      if (typeof getModalTitle === 'string') {
        subtitle = getModalTitle
      }
    }
    return (
      <div className='w-100 d-flex justify-content-between'>
        <h2>{title}</h2>
        <h2>{subtitle}</h2>
      </div>
    )
  }, [getModalTitle])

  return (
    <ResponsiveModal noShowAnimation noPadding size='big' onClose={onHide} open={open}>
      <div className='h-100 p-5 d-flex flex-column gap-5'>
        <div className='d-flex'>
          <div className='px-5 pt-5 flex-grow-1'>{header}</div>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
          />
        </div>
        <div className='flex-grow-1'>
          <TicketDetailSeatMaps
            ticket={ticket}
            count={1}
            setSelected={setSelected}
            selected={selected}
          />
        </div>
        <div className='pt-2 d-flex flex-row-reverse'>
          <Button
            type='button'
            className='px-12'
            disabled={disableSubmit || loading}
            variant='primary'
            onClick={onSubmit}
          >
            <LoadingSpinner loading={loading}>OK</LoadingSpinner>
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  )
}
