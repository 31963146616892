import {ContentBlock, EditorBlock, Editor} from 'draft-js'
import {createPortal} from 'react-dom'
import {Map} from 'immutable'
import {camelCase} from 'lodash'
export const table = (props: {
  block: ContentBlock
  blockProps: {
    editor: Editor
  }
}) => {
  const {
    block,
    blockProps: {editor},
  } = props
  // if this is not the first table block, then we target the <td> element in the applicable position
  // to render the current block into.

  if (block.getData().get('tablePosition') && !block.getData().get('tableShape')) {
    const position = block.getData().get('tablePosition')

    const target = editor?.editor?.querySelector(`[data-position='${position}']`)

    if (target) {
      return createPortal(<EditorBlock {...props} />, target)
    }

    return null
  }

  const data = block.getData()
  const tableKey: string = data.get('tableKey')
  const tableStyle = Map(data.get('tableStyle'))
    .mapKeys((k: any) => camelCase(k))
    .toJS()
  const tableShape = data.get('tableShape')
  const colgroup = data.get('tableColgroup')

  if (Array.isArray(tableShape)) {
    return (
      <table key={tableKey} style={tableStyle} id={tableKey}>
        {colgroup && <colgroup dangerouslySetInnerHTML={{__html: colgroup}}></colgroup>}
        <tbody>
          {tableShape.map((row, i) => (
            <tr
              key={i}
              style={
                data.get('rowStyle')[i] &&
                Map(data.get('rowStyle')[i])
                  .mapKeys((k: any) => camelCase(k))
                  .toJS()
              }
            >
              {row.map((cell: any, j: number) => {
                const cellStyle = Map(cell.style)
                  .mapKeys((k: any) => camelCase(k))
                  .toJS()
                if (cell.element === 'th') {
                  return (
                    <th
                      key={j}
                      style={cellStyle}
                      colSpan={cell.colspan}
                      rowSpan={cell.rowspan}
                      data-position={`${tableKey}-${i}-${j}`}
                    >
                      {!!((i === 0 ? 0 : 1) & (j === 0 ? 0 : 1)) && <EditorBlock {...props} />}
                    </th>
                  )
                }
                return (
                  <td
                    key={j}
                    style={cellStyle}
                    colSpan={cell.colspan}
                    rowSpan={cell.rowspan}
                    data-position={`${tableKey}-${i}-${j}`}
                  >
                    {!!((i === 0 ? 1 : 0) & (j === 0 ? 1 : 0)) && <EditorBlock {...props} />}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    )
  } else {
    return <EditorBlock {...props} />
  }
}
