import {TableColumnField, TableColumnFieldOptions} from './TableColumnField'
import {TableColumnRender, TableColumnRenderOptions} from './TableColumnRender'
import type {GroupedTableData} from './Table'

export type TableColumnType = 'date' | 'number' | 'string' | 'boolean'

export interface BaseTableColumnOptions<T> {
  field: string
  label: string
  sortField?: string
  sortable?: boolean
  hideable?: boolean
  className?: string
  classes?: {
    header?: string
    row?: string
  }
  cellStyle?: {
    align?: 'start' | 'center' | 'end'
    minWidth?: number
    maxWidth?: number
    noWrap?: boolean
  }
  groupedColumnRender?: (props: {groupData: GroupedTableData<T>}) => JSX.Element | null
}

export type TableColumnOptions<T> = TableColumnRenderOptions<T> | TableColumnFieldOptions<T>

export interface TableColumnProps<T> {
  data: T
  groupData?: GroupedTableData<T>
  options: TableColumnOptions<T>
  isFirstIndex?: boolean
}

export const TableColumn = <T,>({data, options, groupData, isFirstIndex}: TableColumnProps<T>) => {
  if ('render' in options) {
    return (
      <TableColumnRender
        data={data}
        options={options}
        groupData={groupData}
        isFirstIndex={isFirstIndex}
      />
    )
  }
  return <TableColumnField data={data} options={options} isFirstIndex={isFirstIndex} />
}
