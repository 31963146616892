import {useCallback, useMemo, useState} from 'react'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {GetCustomerByCode} from '../../../../redux/CustomerPortalCRUD'

export const useReservationWizard = () => {
  const [customer, setCustomer] = useState<CustomerModel>()
  const {pushError} = useAlerts()
  const getCustomer = useCallback(
    async (customerCode) => {
      if (customerCode) {
        try {
          const data = await GetCustomerByCode(customerCode)
          setCustomer(data.data)
        } catch (err: any) {
          pushError(err)
        }
      }
    },
    [pushError]
  )

  return useMemo(
    () => ({
      getCustomer,
      customer,
    }),
    [customer, getCustomer]
  )
}
