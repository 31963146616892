import clsx from 'clsx'
import {ReactNode, useMemo} from 'react'

export type PaperBackgroundVariant =
  | 'primary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | 'default'

export interface PaperProps {
  className?: string
  children?: ReactNode
  rounded?: boolean
  variant?: PaperBackgroundVariant
  shadow?: boolean
}

export const Paper = ({children, className, rounded, variant = 'default', shadow}: PaperProps) => {
  const variantClass = useMemo(() => {
    switch (variant) {
      case 'light':
        return 'bg-light'
      case 'default':
        return 'bg-body'
      default:
        return `bg-light-${variant}`
    }
  }, [variant])

  return (
    <div className={clsx({'shadow-sm': shadow}, variantClass, {rounded: rounded}, className)}>
      {children}
    </div>
  )
}
