import {useMemo} from 'react'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {ReservationPortalModel} from '../../../../../models/ems/ReservationMedel'

import {ReservationIsReleased} from './ReservationTableSelectionActions'

export interface ReservationTableActionsProps {
  onRelease?: (data: ReservationPortalModel) => void
  data: ReservationPortalModel
  loading?: boolean
}

export const ReservationTableRowActions = ({
  data,
  onRelease,
  loading,
}: ReservationTableActionsProps) => {
  const cancelButton = useMemo(() => {
    if (ReservationIsReleased(data)) {
      return (
        <RowAction
          loading={loading}
          data={data}
          iconType='Code'
          iconName='Stop'
          variant='danger'
          tooltip='Release Reservation'
          onClick={onRelease}
        />
      )
    }
    return null
  }, [data, onRelease, loading])

  return <>{cancelButton}</>
}
