import {FormikContextType} from 'formik'
import {ChangeEvent, useCallback, useMemo} from 'react'

export const useFormikCheckboxInputHelpers = <Values extends Record<string, any>>(
  formik: FormikContextType<Values>,
  name: string
) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(name, e.target.checked)
    },
    [formik, name]
  )

  const value = useMemo(() => {
    return formik.values[name] as boolean
  }, [formik.values, name])
  return {
    value,
    onChange,
  }
}
