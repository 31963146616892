import {EnhancedStore} from '@reduxjs/toolkit'
import axios, {AxiosRequestConfig} from 'axios'
import {getUrlData, LAYOUT_TYPE, PDF_API_URL, } from '../../config/env'
import {RootState} from '../redux/RootReducer'
import store from '../redux/Store'

axios.defaults.baseURL = PDF_API_URL

export const axiosPdf = axios.create()

axiosPdf.interceptors.request.use(
  async (config) => {
    const {organizationCode} = getUrlData()
    config.headers['x-organization'] = organizationCode

    const _exception = config.url?.split(':')[0]
    const isToken = _exception === 'https' ? false : true
    if (isToken) {
      setAuthorizationHeader(config, store)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosPdf

const setAuthorizationHeader = (config: AxiosRequestConfig, store: EnhancedStore<RootState>) => {
  const {auth, outlet, customerPortal, poken, digitalWaiver} = store.getState()

  switch (LAYOUT_TYPE) {
    case 'outlet': {
      if (outlet.auth?.token) {
        config.headers.Authorization = `Bearer ${outlet.auth.token}`
      }
      break
    }
    case 'customer-delegate':
    case 'customer-portal': {
      if (customerPortal.auth?.token) {
        config.headers.Authorization = `Bearer ${customerPortal.auth.token}`
      }
      break
    }
    case 'digital-waiver': {
      if (digitalWaiver.auth?.token) {
        config.headers.Authorization = `Bearer ${digitalWaiver.auth.token}`
      }
      break
    }

    case 'poken': {
      if (poken.auth?.token) {
        config.headers.Authorization = `Bearer ${poken.auth.token}`
      }
      break
    }
    default: {
      if (auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }
    }
  }
}
