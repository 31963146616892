import {ReactNode} from 'react'
import {Helmet} from 'react-helmet'
import {NavigationSectionMenuItem} from '../../../../config/drawer'
// import {GPCALogo} from '../../../../config/logos'
import {Content} from '../../../../_metronic/layout/components/Content'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {ScrollTop} from '../../../../_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {MasterInit} from '../../../../_metronic/layout/MasterInit'
import {Main} from '../../../../_metronic/partials'
import {LayoutPortalProvider} from '../DefaultLayout/LayoutPortalProvider'
import {Header} from './Header/Header'

export interface LayoutProps {
  children?: ReactNode
  controls?: ReactNode
  menus?: NavigationSectionMenuItem[]
}

export const PokenLayout = ({children, menus}: LayoutProps) => {
  return (
    <PageDataProvider>
      <LayoutPortalProvider>
        <Helmet>
          <title>WEB•N•TECH CMS|POKEN</title>
          <link rel='icon' href='/portal.ico' />
        </Helmet>
        <div className='d-flex flex-column flex-root  poken-container'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <div className='wrapper d-flex flex-column flex-row-fluid px-0' id='kt_wrapper'>
              <Header menus={menus} logoMaxWidth='12rem' />
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </LayoutPortalProvider>
      <Main />
      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}
