import clsx from 'clsx'
import {ChangeEvent, forwardRef, useCallback, useState} from 'react'
import {Validators} from '../../../utils/Validators'
import {useOnChange} from '../../hooks/useOnChange'
import {ButtonVariant} from '../Button'
import {InputLabel} from '../InputLabel'
import {MetronicIconButton} from '../MetronicIconButton'
import {TextInput, TextInputProps} from '../TextInput'

export interface NumberInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  value: number
  variant?: ButtonVariant
  onChange: (value: number) => void
  allowDecimals?: boolean
  min?: number
  max?: number
  ref?: React.Ref<HTMLInputElement>
  step?: number
  hideButtons?: boolean
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      variant = 'primary',
      onChange,
      min,
      max,
      value,
      allowDecimals,
      onBlur,
      className,
      label,
      disabled,
      hideButtons,
      step = 1,
      ...props
    },
    ref
  ) => {
    const [displayedValue, setDisplayedValue] = useState(String(value))

    const isValidNumber = useCallback(
      (value: string) => {
        const isNumber = Validators.FRACTIONAL_NUMBER_REGEX.test(value)
        if (isNumber) {
          const parsedValue = allowDecimals ? Number(value) : parseInt(value)
          if (min !== undefined && parsedValue <= min) {
            return false
          }
          if (max !== undefined && parsedValue >= max) {
            return false
          }
          return true
        }
        return false
      },
      [allowDecimals, max, min]
    )

    const getValidNumber = useCallback(
      (value: string): number | null => {
        const isNumber = Validators.FRACTIONAL_NUMBER_REGEX.test(value)
        if (isNumber) {
          const parsedValue = allowDecimals ? Number(value) : parseInt(value)
          if (min !== undefined && parsedValue < min) {
            return min
          }
          if (max !== undefined && parsedValue > max) {
            return max
          }
          return parsedValue
        }
        return null
      },
      [allowDecimals, max, min]
    )

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (isValidNumber(value)) {
          const parsedValue = getValidNumber(value)
          if (parsedValue !== null) {
            onChange?.(parsedValue)
          }
        }
        setDisplayedValue(value)
      },
      [getValidNumber, isValidNumber, onChange]
    )

    const handleChange = useCallback(
      (newValue: number | string) => {
        const parsedValue = getValidNumber(String(newValue))
        if (parsedValue !== null) {
          onChange(parsedValue)
        }
        setDisplayedValue(String(value))
      },
      [getValidNumber, onChange, value]
    )

    const handleOnBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur && onBlur(e)
        handleChange(e.target.value)
      },
      [handleChange, onBlur]
    )

    const increment = useCallback(() => {
      handleChange(value + step)
    }, [handleChange, step, value])

    const decrement = useCallback(() => {
      handleChange(value - step)
    }, [handleChange, step, value])

    useOnChange(value, () => {
      setDisplayedValue(String(value))
    })

    return (
      <div className={clsx('number-input', className)}>
        {label && <InputLabel className='w-100 d-flex justify-content-start'>{label}</InputLabel>}
        <div className='number-input-field'>
          {!hideButtons && (
            <MetronicIconButton
              className='number-input-field-button--left'
              iconType='Navigation'
              iconName='Minus'
              variant={variant}
              type='button'
              onClick={decrement}
              disabled={disabled}
            />
          )}
          <TextInput
            fullWidth
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={displayedValue}
            disabled={disabled}
            ref={ref}
            type='text'
            inputMode='numeric'
            {...props}
          />
          {!hideButtons && (
            <MetronicIconButton
              className='number-input-field-button--right'
              iconType='Navigation'
              iconName='Plus'
              variant={variant}
              type='button'
              onClick={increment}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    )
  }
)
