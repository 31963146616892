import {SelectTreeOptionGroupItem} from '../../components/inputs/SelectTreeNative/SelectTreeOptionGroup'
import {TreeNodeItem} from '../../components/inputs/TreeSelect/TreeSelect'
import {ApiTreeNode} from './TreeNode'

export interface HasChildren {
  children?: HasChildren[]
}

export class ApiTree<T extends ApiTreeNode<T>> {
  constructor(private nodes: T[]) {}

  public static fromTreeSelectItems = (tree: TreeNodeItem[]) => {
    return new ApiTree(ApiTree.recursiveMapTreeSelectItems(tree))
  }

  private static recursiveMapTreeSelectItems = (tree: TreeNodeItem[]): ApiTreeNode<any>[] => {
    const nodes: ApiTreeNode<any>[] = []

    tree.forEach((node) => {
      nodes.push({
        code: node.value,
        name: node.label,
        children: node.items ? ApiTree.recursiveMapTreeSelectItems(node.items) : [],
      })
    })

    return nodes
  }

  private static recursiveFlatten = <T extends ApiTreeNode<T>>(
    nodes: T[]
  ): Omit<T, 'children'>[] => {
    return nodes.reduce<Omit<T, 'children'>[]>((acc, {children, ...others}) => {
      acc.push({...others})
      if (children) {
        const flattenedChildren = ApiTree.recursiveFlatten(children) as Omit<T, 'children'>[]
        acc.push(...flattenedChildren)
      }
      return acc
    }, [])
  }

  private static getTreeSelectItems = <T extends ApiTreeNode<T>>(
    nodes: ApiTreeNode<T>[]
  ): TreeNodeItem[] => {
    return nodes.map((item): TreeNodeItem => {
      return {
        label: item.name ?? item.title,
        value: item.code,
        items: item.children ? ApiTree.getTreeSelectItems(item.children) : [],
      }
    })
  }

  private static recursiveForEach = <T extends ApiTreeNode<T>>(
    nodes: T[],
    callback: (node: T) => boolean | void
  ): boolean => {
    return nodes.some((node) => {
      const returnValue = callback(node)
      if (returnValue === true) {
        return true
      }
      if (node.children) {
        return ApiTree.recursiveForEach(node.children, callback)
      }
      return false
    })
  }

  private static recursiveFilter = <T extends ApiTreeNode<T>>(
    nodes: T[],
    callback: (node: T) => boolean
  ): T[] => {
    const newChildren: T[] = []
    nodes.forEach((node) => {
      const isValid = callback(node)
      if (isValid) {
        newChildren.push(node)
      }
      if (node.children) {
        node.children = ApiTree.recursiveFilter(node.children, callback)
      }
    })
    return newChildren
  }

  /**
   * Creates a copy of the array that contains the found node,
   * then removes node with the same `code`
   * */
  private static removeNode = <T extends ApiTreeNode<T>>(tree: T[], code: string): T[] => {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.code === code) {
        const newChildren = [...tree]
        newChildren.splice(i, 1)
        return newChildren
      } else if (node.children && node.children.length) {
        const newChildren = ApiTree.removeNode(node.children, code)
        if (newChildren !== node.children) {
          node.children = newChildren
          break
        }
      }
    }
    return tree
  }

  public removeNode = (code: string) => {
    this.nodes = ApiTree.removeNode(this.nodes, code)
  }

  public getOptGroupSelectItems = (): SelectTreeOptionGroupItem[] => {
    return ApiTree.getTreeSelectItems(this.nodes)
  }

  public getTreeSelectItems = (): TreeNodeItem[] => {
    return ApiTree.getTreeSelectItems(this.nodes)
  }

  public flatten = () => {
    this.nodes = ApiTree.recursiveFlatten(this.nodes) as T[]
  }

  public getTopLevelNodes = () => {
    return this.nodes
  }

  public forEachNode = (callback: (node: T) => void) => {
    ApiTree.recursiveForEach(this.nodes, callback)
  }

  public findByCode = (code: string): T | undefined => {
    let found: T | undefined
    ApiTree.recursiveForEach(this.nodes, (node) => {
      if (node.code === code) {
        found = node
        return true
      }
    })
    return found
  }

  public filter = (callback: (data: T) => boolean) => {
    const filtered: T[] = ApiTree.recursiveFilter(this.nodes, callback)
    return new ApiTree(filtered)
  }
}
