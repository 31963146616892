import {useCallback, useEffect, useMemo, useState} from 'react'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {GetCustomerServices} from '../redux/EvaCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import NavigationCell from './NavigationCell/NavigationCell'
import {CustomerServicesListModel} from '../../../models/CustomerModel'
import {useParams} from 'react-router-dom'
interface ServiceListProps {
  goToService: (service: CustomerServicesListModel) => void
}
const CustomerServicesList = ({goToService}: ServiceListProps) => {
  const {serviceCode} = useParams<{serviceCode?: string}>()
  const [allServices, setAllCustomerServices] = useState<CustomerServicesListModel[]>([])
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const {pushError} = useAlerts()
  const refreshList = useCallback(
    async (ticket: string) => {
      try {
        if (ticket) {
          const {data} = await GetCustomerServices(ticket)
          setAllCustomerServices(data)
        }
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  useEffect(() => {
    if (ticketCode) refreshList(ticketCode)
  }, [ticketCode, refreshList])

  const servicesMemo = useMemo(() => {
    return (
      allServices &&
      allServices.length > 0 &&
      allServices.map((service, index, array) => {
        const isLast = index === array.length - 1
        return (
          <div key={service.code}>
            <NavigationCell
              goTo={goToService}
              data={service}
              title={service.type ? service.type : ''}
              isRoute={serviceCode === service.code}
            />
            {!isLast && (
              <div className='my-4'>
                <HorizontalDivider />
              </div>
            )}
          </div>
        )
      })
    )
  }, [allServices, goToService, serviceCode])
  return <div>{servicesMemo}</div>
}

export {CustomerServicesList}
