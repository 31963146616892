import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {DateUtil} from '../../../utils/DateUtil'
import {actions} from '../redux/OutletRedux'

export const useCurrentOutletOrders = () => {
  const dispatch = useDispatch()

  const orders = useRootStateSelector((state) => state.outlet.allOrders || [])

  const refresh = useCallback(async () => {
    dispatch(actions.orders.getAll())
  }, [dispatch])

  const setOrders = useCallback(
    (orders: OrderModel[]) => {
      dispatch(actions.orders.getAllSuccess(orders))
    },
    [dispatch]
  )

  useOnMount(() => {
    refresh()
  })

  const sortedOrders = useMemo(() => {
    return orders.sort((orderA, orderB) => {
      return DateUtil.getDateFromApiString(orderA.createdAt) <
        DateUtil.getDateFromApiString(orderB.createdAt)
        ? 1
        : -1
    })
  }, [orders])

  return {orders: sortedOrders, refresh, setOrders}
}
