import {useCallback, useState, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'
import {FilterModel} from '../../../../../models/FilterModel'
import {GetProductsBySlug} from '../../../redux/CustomerPortalCRUD'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {
  SearchableSelectInput,
  SearchableSelectInputClasses,
} from '../../../../../components/inputs/SearchableSelect'
import {CustomerSearchInputSelectItem} from '../../inputs/CustomerSearchInput'
import {MetronicIconPath} from '../../../../../components/inputs/MetronicIcon'
export interface FilterProductInputMobileProps {
  label: string
  productValue: CustomerSearchInputSelectItem | null
  placeholder?: string
  productCategorySlug?: string
  icon?: MetronicIconPath
  classes?: SearchableSelectInputClasses<any>
  setProduct: (data: CustomerSearchInputSelectItem | null) => void
}

export const FilterProductInputMobile = ({
  label,
  placeholder,
  productCategorySlug,
  icon,
  productValue,
  setProduct,
  classes,
}: FilterProductInputMobileProps) => {
  const {pushError} = useAlerts()
  const searchDebounce = useDebounce(500)
  const [searchResult, setSearchResult] = useState<CustomerSearchInputSelectItem[]>()
  const id = useRef(uuidv4()).current

  const searchProducts = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetProductsBySlug(filter)
        return data
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  const getSearchableFilterFieldFilterHandler = useCallback(
    async (filter: FilterModel) => {
      searchDebounce(async () => {
        const data = await searchProducts(filter)
        if (data) {
          const newData: CustomerSearchInputSelectItem[] = data.map((item) => {
            return {
              code: item.code,
              label: item.name,
              value: item.code,
            }
          })
          setSearchResult(newData)
        }
      })
    },
    [searchDebounce, searchProducts]
  )

  const getSearchableFilterFieldChangeHandler = useCallback(
    (valueInput: CustomerSearchInputSelectItem | null) => {
      if (valueInput) {
        setProduct(valueInput)
      } else {
        setProduct(null)
      }
    },
    [setProduct]
  )

  return (
    <SearchableSelectInput
      classes={classes}
      allowClear
      itemMapper={itemMapper}
      label={label}
      placeholder={placeholder}
      onSearch={(value) =>
        getSearchableFilterFieldFilterHandler({
          filters: {
            search: value,
            productCategorySlug,
          },
        })
      }
      items={searchResult ? searchResult : []}
      id={id}
      value={productValue}
      onChange={getSearchableFilterFieldChangeHandler}
      filter={NO_FILTER}
      icon={icon}
    />
  )
}

const itemMapper = (item: CustomerSearchInputSelectItem) => ({label: item.label, value: item.value})

const NO_FILTER = () => true
