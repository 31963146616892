import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {ButtonStyleProps, Button} from './Button'

export interface ButtonListItem extends SelectInputItem, Partial<ButtonStyleProps> {}

export interface ButtonListProps {
  items: ButtonListItem[]
  onClick: (value: string) => void
  className?: string
}

export const ButtonList = ({items, onClick, className}: ButtonListProps) => {
  const getOnClick = useCallback(
    (value: string) => () => {
      onClick(value)
    },
    [onClick]
  )

  const buttons = useMemo(() => {
    return items.map((item, index) => {
      const isLast = items.length - 1 === index
      return (
        <Button
          className={clsx({
            'mb-3': !isLast,
          })}
          big
          key={item.value}
          onClick={getOnClick(item.value)}
          variant={item.variant || 'success'}
          rounded={item.rounded}
          border={item.border}
          light={item.light}
        >
          {item.label}
        </Button>
      )
    })
  }, [getOnClick, items])

  return <div className={clsx('d-flex flex-column', className)}>{buttons}</div>
}
