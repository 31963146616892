import {MutableRefObject, useEffect, useMemo, useRef, useState} from 'react'
import {BrowserQRCodeReader, IScannerControls} from '@zxing/browser'
import {isMediaDevicesSupported} from './utils'
import {useAlerts} from '../../alerts/useAlerts'
import {useOnChange} from '../../hooks/useOnChange'
import {DecodeContinuouslyCallback} from '@zxing/browser/esm/common/DecodeContinuouslyCallback'
import {Result} from '@zxing/library'
import {useOnMount} from '../../hooks/useOnMount'

export type OnResultFunction = (result: Result) => void

export type UseQrReaderHookProps = {
  constraints: MediaTrackConstraints
  onResult: OnResultFunction
  scanDelay?: number
  disabled?: boolean
}

export const useQrReader = ({
  scanDelay,
  constraints: video,
  onResult,
  disabled,
}: UseQrReaderHookProps) => {
  const {push} = useAlerts()
  const constraints = useRef(video)
  const [result, setResult] = useState<Result>()
  const controlsRef: MutableRefObject<IScannerControls | null> = useRef(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const codeReader = useRef(
    new BrowserQRCodeReader(undefined, {
      delayBetweenScanAttempts: scanDelay,
      delayBetweenScanSuccess: scanDelay,
    })
  )

  useOnChange(result, () => {
    if (!disabled && result) {
      onResult(result)
    }
  })

  useOnMount(() => {
    if (!isMediaDevicesSupported()) {
      push({
        message: 'Your device does not support QR reader.',
        timeout: 5000,
        variant: 'danger',
      })
    }
  })

  useEffect(() => {
    const videoEl = videoRef.current
    if (videoEl && isMediaDevicesSupported()) {
      const callback: DecodeContinuouslyCallback = (result) => {
        if (result) {
          setResult(result)
        }
      }

      codeReader.current
        .decodeFromConstraints({video: constraints.current}, videoEl, callback)
        .then((controls: IScannerControls) => {
          controlsRef.current = controls
        })
        .catch((e) => {
          console.error(e)
        })
    }
    return () => {
      controlsRef.current?.stop()
    }
  }, [])

  return useMemo(() => ({videoRef}), [])
}
