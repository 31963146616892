import {FormikContextType} from 'formik'
import {useCallback, useRef} from 'react'
import SignatureCanvas from 'react-signature-canvas'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {MetronicIconButton} from '../../../../../components/inputs/MetronicIconButton'
import {BlobUtils} from '../../../../../utils/BlobUtils'
import {TicketSubmitFormValues} from '../TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'

interface SignatureFormProps {
  formik: FormikContextType<TicketSubmitFormValues>
  isOpen?: boolean
}

export const SignatureForm = ({formik, isOpen}: SignatureFormProps) => {
  const signatureRef = useRef<SignatureCanvas>(null)
  const signaturContainereRef = useRef<HTMLDivElement>(null)

  useOnChange(isOpen, () => {
    if (!isOpen) signatureRef.current?.clear()
  })

  const handleSaveSignature = useCallback(() => {
    const base64 = signatureRef.current && signatureRef.current.getTrimmedCanvas().toDataURL()
    if (base64 && !signatureRef.current?.isEmpty())
      formik.setFieldValue('signature', BlobUtils.convertBase64ToBlob(base64))
    if (base64 && signatureRef.current?.isEmpty()) formik.setFieldValue('signature', null)
  }, [formik])

  return (
    <div className='w-100 px-2'>
      <div className='border border-1 border-primary rounded overflow-hidden bg-primary-dw-darker'>
        <div className='d-flex bg-primary-dw-light text-white fs-3 text-center px-4 py-2 mb-0 text-uppercase fw-bolder position-relative'>
          <MetronicIconButton
            iconName='Trash'
            iconType='Home'
            className='bg-transparent'
            size='md'
            color='warning'
            tooltip='Clear signature'
            onClick={() => {
              signatureRef.current?.clear()
              formik.setFieldValue('signature', null)
            }}
          />
          <p className='position-absolute start-50 top-50 translate-middle mb-0 w-75 mx-2'>
            Signature of individual توقيع الشخص
          </p>
        </div>
        <div ref={signaturContainereRef}>
          <SignatureCanvas
            ref={signatureRef}
            dotSize={1}
            onEnd={handleSaveSignature}
            canvasProps={{
              width:
                signaturContainereRef && signaturContainereRef.current
                  ? signaturContainereRef.current.clientWidth - 10
                  : 330,
              height: 200,
              className: 'sigCanvas',
            }}
            penColor='black'
            onBegin={() => formik.setFieldTouched('signature', true)}
          />
        </div>
      </div>
      {formik.errors.signature && (
        <div className='fv-plugins-message-container mb-3 px-4'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.signature}</span>
          </div>
        </div>
      )}
    </div>
  )
}
