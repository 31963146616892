import {RowAction} from '../../../../../../components/tables/actions/RowAction'
import {ReservationModel} from '../../../../../../models/fnb/ReservationModel'

export interface ReservationTableRowActionsProps {
  data: ReservationModel
  onApprove?: (data: ReservationModel) => void
  onDeny?: (data: ReservationModel) => void
  onEdit?: (data: ReservationModel) => void
}

export const ReservationTableRowActions = ({
  data,
  onApprove,
  onDeny,
  onEdit,
}: ReservationTableRowActionsProps) => {
  return (
    <>
      {reservationCanBeApproved(data) && (
        <RowAction
          iconType='Navigation'
          iconName='Check'
          tooltip='Approve Reservation'
          data={data}
          onClick={onApprove}
          variant='success'
        />
      )}
      {reservationCanBeDenied(data) && (
        <RowAction
          iconType='Code'
          iconName='Stop'
          tooltip='Deny Reservation'
          data={data}
          onClick={onDeny}
          variant='danger'
        />
      )}
      <RowAction
        iconType='General'
        iconName='Edit'
        tooltip='Edit'
        data={data}
        onClick={onEdit}
        variant='danger'
      />
    </>
  )
}

const reservationCanBeApproved = (data: ReservationModel) => {
  return data.status === 'pending'
}

const reservationCanBeDenied = (data: ReservationModel) => {
  return data.status === 'pending'
}
