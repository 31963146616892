import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {AvatarInitials} from '../../../components/utils/AvatarInitials'

export interface UserProfileMenuProps {
  signoutLink?: string
  initials: string
  name: string
  email: string
  userType?: string
}

export const UserProfileMenu = ({
  email,
  initials,
  name,
  signoutLink = '/logout',
  userType,
}: UserProfileMenuProps) => {
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div className={clsx('d-flex align-items-center')} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol bg-opacity-10')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <AvatarInitials className='fs-2'>{initials}</AvatarInitials>
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-5'>
              <div className='d-flex flex-column'>
                <div>
                  <span className='fw-bolder fs-5'>{name}</span>
                  {userType && (
                    <span className='badge badge-light-success text-uppercase ms-3'>
                      {userType}
                    </span>
                  )}
                </div>
                <span className='fw-bold text-muted fs-7'>{email}</span>
              </div>
            </div>
          </div>
          <HorizontalDivider />
          <div className='menu-item px-5'>
            <Link to={signoutLink} className='menu-link px-5'>
              Sign Out
            </Link>
          </div>
        </div>
        {/* end::Toggle */}
      </div>
    </div>
  )
}
