import clsx from 'clsx'
import {ReactNode, useCallback} from 'react'
import {Button, ButtonVariant} from '../inputs/Button'
import {MetronicIcon} from '../inputs/MetronicIcon'

export interface ButtonCrumbAttributes {
  label: ReactNode
  disabled?: boolean
  variant?: ButtonVariant
  value: string
  loading?: boolean
  className?: string
}

export interface ButtonCrumbProps extends ButtonCrumbAttributes {
  hasNext?: boolean
  onClick?: (value: string) => void
}

export const ButtonCrumb = ({
  label,
  value,
  disabled,
  hasNext,
  onClick,
  variant,
  loading = false,
  className,
}: ButtonCrumbProps) => {
  const handleClick = useCallback(() => {
    onClick && onClick(value)
  }, [onClick, value])

  return (
    <>
      <Button
        className={clsx('text-nowrap', className)}
        onClick={handleClick}
        disabled={loading || disabled}
        variant={variant}
        type='button'
      >
        {label}
      </Button>
      {hasNext && <MetronicIcon size='lg' iconType='Navigation' iconName='Angle-right' />}
    </>
  )
}
