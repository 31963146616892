import {useState, useCallback} from 'react'
// import {useDispatch} from 'react-redux'
import {PutCustomer} from '../../../redux/CustomerDelegateCRUD'
import {useFormik} from 'formik'
// import * as RxCustPort from '../../../redux/CustomerPortalRedux'
import {CustomerForm, CustomerFormValues, roleFormValidationSchema} from './CustomerForm'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {CustomerModel} from '../../../../../models/CustomerModel'

import {
  useFormDrawerCustomerData,
  getCustomerPayload,
  initialValues,
} from '../../../hooks/useFormDrawerCustomerData'
import {useOnChange} from '../../../../../components/hooks/useOnChange'

export interface CustomerFormEditProps {
  drawerRef: (drawer: Drawer | null) => void
  customerToEdit?: CustomerModel
  onChange: () => void
}

const CustomerFormEdit = ({drawerRef, customerToEdit, onChange}: CustomerFormEditProps) => {
  const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()
  const [drawer, setDrawer] = useDrawerRef()
  const {pushError} = useAlerts()
  const formDrawer = useFormDrawerCustomerData()

  const updateCustomer = useCallback(
    async (values: CustomerFormValues) => {
      const payload = getCustomerPayload({values, editing: true})
      if (customerToEdit) {
        try {
          await PutCustomer(payload, customerToEdit.code)
          // dispatch(RxCustPort.actions.customers.updateSuccess())
          onChange()
        } catch (e) {
          // dispatch(RxCustPort.actions.customers.updateFailed())
          throw e
        }
      }
    },
    [customerToEdit, onChange]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        await updateCustomer(values)
        handleCloseDrawer()
        drawer?.hide()
      } catch (e) {
        pushError(e)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
  }, [formik])

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      drawerRef(drawer)
      setDrawer(drawer)
    },
    [drawerRef, setDrawer]
  )

  useOnChange(customerToEdit, () => {
    if (customerToEdit) {
      const values = formDrawer.mapCustomerDataToFormValues(customerToEdit)
      formik.setValues(values)
    }
  })

  return (
    <Drawer ref={handleDrawerRef} onClose={handleCloseDrawer}>
      <CustomerForm
        title='Edit Customer'
        countries={formDrawer.countries}
        onCloseButtonClick={drawer?.hide}
        isLoading={loading}
        formik={formik}
        editing
      />
    </Drawer>
  )
}

export default CustomerFormEdit
