import {ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom'

export interface LinkProps {
  children?: ReactNode
  to: string
  className?: string
}

export const Link = ({children, className, to}: LinkProps) => {
  if (LOCAL_LINK_REGEX.test(to)) {
    return (
      <RouterLink className={className} to={to}>
        {children}
      </RouterLink>
    )
  }
  return <a href={to}>{children}</a>
}

const LOCAL_LINK_REGEX = /^\//
