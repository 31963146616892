import {FileModel} from '../../../models/FileModel'
import {BaseFileInputValue} from './BaseFileInputValue'

export class FileInputValue extends BaseFileInputValue {
  private file?: File | FileModel | Blob

  constructor(file: File)
  constructor(blob: Blob, name: string)
  constructor(staticUrl: string, file: FileModel)
  constructor(url: string, name: string, id: string)
  constructor(a: File | Blob | string, b?: string | FileModel, c?: string) {
    // File constructor
    if (a instanceof File) {
      const file = a
      const url = URL.createObjectURL(file)
      super(url, file.name, url)
      this.file = file
      return
    }
    // Blob constructor
    if (a instanceof Blob && typeof b === 'string') {
      const blob = a
      const name = b
      const url = URL.createObjectURL(blob)
      super(url, name, url)
      this.file = blob
      return
    }
    // FileModel constructor
    if (typeof a === 'string' && typeof b === 'object' && 'code' in b) {
      const staticUrl = a
      const fileModel = b
      const url = FileInputValue.getUrlFromFileModel(staticUrl, fileModel)
      super(url, fileModel.name, fileModel.code)
      this.file = fileModel
      return
    }
    // URL constructor
    if (typeof a === 'string' && typeof b === 'string' && typeof c === 'string') {
      const url = a
      const name = b
      const id = c
      super(url, name, id)
      return
    }
    throw new Error('Invalid constructor usage.')
  }

  public getFile() {
    if (this.file instanceof File) {
      return this.file
    }
  }
  public getFileModel(): FileModel | undefined {
    const file = this.file
    if (file && 'code' in file) {
      return file
    }
  }

  public toApiValue() {
    if (this.file instanceof Blob) {
      return this.file
    }
    return null
  }
}
