import {useCallback, useState} from 'react'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import clsx from 'clsx'
import {GetCustomerServicesByCode} from '../../redux/EvaCRUD'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {CustomerServicesInnerModel} from '../../../../models/CustomerModel'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {Button} from '../../../../components/inputs/Button'
import {useOnChange} from '../../../../components/hooks/useOnChange'
export const CutomerServiceDetails = () => {
  const [customerService, setCustomerService] = useState<CustomerServicesInnerModel>()
  const history = useHistory()
  const {serviceCode} = useParams<{serviceCode?: string}>()
  const ticketCode = useRootStateSelector((state) => state.eva?.user?.ticketCode)
  const {pushError} = useAlerts()
  const {pathname} = useLocation()
  const refreshData = useCallback(async () => {
    if (serviceCode && ticketCode) {
      try {
        const {data} = await GetCustomerServicesByCode(serviceCode, ticketCode)
        setCustomerService(data)
      } catch (e) {
        pushError(e)
      }
    }
  }, [serviceCode, ticketCode, pushError])

  useOnChange(serviceCode, () => {
    refreshData()
  })

  return customerService ? (
    <>
      <div className={clsx('p-5 mb-5')}>
        {customerService.description && customerService.description !== '' && (
          <span>{customerService.description}</span>
        )}
        <div className='mb-5'>
          {customerService.documents && customerService.documents.length > 0
            ? customerService.documents.map((document) => {
                return (
                  <div className='mt-5'>
                    <Button
                      key={document.code}
                      className='w-100 mb-3 d-inline-flex align-items-center justify-content-between'
                      variant='primary'
                      onClick={() =>
                        history.push(`/customer-services/${serviceCode}/pdf/${document.code}`, {
                          from: pathname,
                        })
                      }
                    >
                      <span>
                        <MetronicIcon iconType='Files' iconName='DownloadedFile' size='lg' />
                      </span>
                      <span>{document.name}</span>
                    </Button>

                    <HorizontalDivider />
                  </div>
                )
              })
            : null}
        </div>
      </div>
    </>
  ) : null
}
