import {useCallback} from 'react'
import {requestPassword} from '../redux/AuthCRUD'
import {
  ForgotPasswordForm,
  ForgotPasswordFormValues,
} from '../../../../components/forms/ForgotPasswordForm'
import {useHistory} from 'react-router-dom'

export function ForgotPassword() {
  const history = useHistory()

  const handleSubmit = useCallback(async (values: ForgotPasswordFormValues) => {
    await requestPassword(values.email)
  }, [])

  const logout = useCallback(() => {
    history.push('/auth/login')
  }, [history])

  return <ForgotPasswordForm onSubmit={handleSubmit} onCancel={logout} />
}
