import {Badge} from '../../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../../components/inputs/Button'
import {BookingDetailModel} from '../../../../../../models/customer-portal/BookingDetailModel'
import {BookingModelProductType} from '../../../../../../models/ems/BookingModel'
import {BookingProductListModel} from '../../../../../../models/ems/ProductModel'

export const BookingProductTypeColumn = ({
  data,
}: {
  data: BookingProductListModel | BookingDetailModel
}) => {
  return (
    <Badge uppercase variant={getBadge(data.bookingProductType)}>
      {data.bookingProductType}
    </Badge>
  )
}

const getBadge = (type?: BookingModelProductType | BookingDetailModel): ColorVariant => {
  switch (type) {
    case 'product':
      return 'primary'
    case 'bundle':
      return 'info'
    case 'voucher':
      return 'success'
    default:
      return 'dark'
  }
}
