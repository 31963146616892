import {useMemo, useState, useCallback, useEffect} from 'react'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {BookingStatusColumn} from './BookingStatusColumn'
import {DateTimeFormatter} from '../../../../../components/utils/formatter/DateTimeFormatter'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {BookingTableActions} from './BookingTableSelectionActions'
import {useBookingDataUtils} from '../../../hooks/useBookingDataUtils'
import {BookingTableRowActions} from './BookingTableRowActions'
import {BookingTableRightActions} from './BookingTableRightActions'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {BookingCodeColumn} from './BookingCodeColumn'
import {CustomerPortalLink} from '../../../../../components/utils/CustomerPortalLink'
import {CustomerBookingPortalLink} from '../../../../../components/utils/BookingPortalLink'
import {FilterInputContainer} from '../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {CustomerFilterInput} from '../../../../../components/tables/advanced-filter-inputs/CustomerFilterInput'
import {MultiSelectToggleFilterInput} from '../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {FilterType} from '../../../../../components/tables/constants/FilterType'
import {EventModel} from '../../../../../models/ems/EventModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {useDispatch} from 'react-redux'
import {actions} from '../../../redux/CustomerPortalRedux'
export interface BookingTableProps {
  data?: GlobalSearchModel<BookingModel>
  onFilter: (filter: FilterModel) => void
  onEdit?: (data: BookingModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  onNewBookingClick?: () => void
  onNewBulkBookingClick?: () => void
  className?: string
  initialFilters?: FilterModel
  event?: EventModel
  customer?: CustomerModel
  paginationClassName?: string
  cornersClassName?: string
  customWrapper?: string
  removeColumns?: string[]
}

export const BookingTable = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  onEdit,
  onNewBookingClick,
  onNewBulkBookingClick,
  className,
  initialFilters,
  event,
  customer,
  paginationClassName,
  cornersClassName,
  customWrapper,
  removeColumns,
}: BookingTableProps) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer-portal-booking',
  })
  const columns = useMemo(() => {
    let theCulomns: TableColumnOptions<BookingModel>[] = [
      {
        field: 'code',
        label: 'Code',
        hideable: true,
        sortable: true,
        render: ({data}) => BookingCodeColumn({data, event}),
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'customer',
        sortable: true,
        hideable: true,
        label: 'Customer',
        render: ({data}) => (
          <CustomerPortalLink customer={data.customer}>{data.customer?.name}</CustomerPortalLink>
        ),
        cellStyle: ColumnStyle.PERSON_FULL_NAME,
      },
      {
        field: 'event',
        label: 'Event',
        hideable: true,
        sortable: true,
        dataExtract: (data) => data.event?.name,
      },

      {
        field: 'status',
        sortable: true,
        hideable: true,
        label: 'Status',
        render: (data) => BookingStatusColumn(data),
      },
      {
        field: 'reason',
        sortable: true,
        hideable: true,
        label: 'Reason',
      },
      {
        field: 'updatedAt',
        sortable: true,
        label: 'Date',
        hideable: true,
        render: ({data}) => (
          <DateTimeFormatter className='text-uppercase text-nowrap'>
            {data.updatedAt}
          </DateTimeFormatter>
        ),
        cellStyle: ColumnStyle.DATE,
      },
      {
        field: 'reservationNo',
        label: 'Reservation',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <CustomerBookingPortalLink customer={data.customer} booking={data}>
            {data.reservationNo}
          </CustomerBookingPortalLink>
        ),
      },
    ]

    if (customer) {
      theCulomns = theCulomns.filter((item) => item.field !== 'customer')
    }

    return theCulomns.filter((column) => !removeColumns?.includes(column.field))
  }, [customer, event, removeColumns])

  const {cancelBulk, cancelSingle, isBookingLoading, isLoading} = useBookingDataUtils({
    onRefresh,
    onRefreshCallback,
  })

  useEffect(() => {
    dispatch(actions.bookingBulkForm.resetBookingBulkForm())
    dispatch(actions.bookingForm.resetBookingForm())
    dispatch(actions.bookingBulkForm.resetBookingBulkConsolidatedForm())
  }, [dispatch])

  const rowActions = useCallback(
    (data: BookingModel) => (
      <BookingTableRowActions
        loading={isBookingLoading(data)}
        data={data}
        onCancel={cancelSingle}
        onEdit={onEdit}
      />
    ),
    [cancelSingle, isBookingLoading, onEdit]
  )

  const tableData = useMemo((): BookingModel[] => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      }
    }
    return []
  }, [data])

  return (
    <FilterTable
      className={selected ? className : 'd-none d-md-block '}
      cornersClassName={cornersClassName}
      customWrapper={customWrapper}
      onHiddenColumnsChange={setHiddenColumns}
      onSelectionChange={setSelected}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={tableData}
      selection={selected}
      totalItems={data?.total || 0}
      idExtractor={idExtractor}
      onFilter={onFilter}
      actions={rowActions}
      rightToolbar={
        <BookingTableRightActions
          onNewBookingClick={onNewBookingClick}
          onNewBulkBookingClick={onNewBulkBookingClick}
        />
      }
      paginationClassName={paginationClassName}
      selectionAction={
        <BookingTableActions
          loading={isLoading}
          selected={selected}
          bookings={tableData}
          onCancel={cancelBulk}
        />
      }
      advancedFilters={
        <FilterInputContainer>
          <CustomerFilterInput
            field='customer'
            label='Customer'
            placeholder='Filter Customer'
            customerType={['customer']}
          />
          <MultiSelectToggleFilterInput
            field='status'
            items={FilterType.BOOKING_PORTAL_STATUS_CUSTOM}
            label='Status'
          />
        </FilterInputContainer>
      }
      initialFilters={initialFilters}
    />
  )
}
