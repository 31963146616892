import {FormikContextType} from 'formik'
import {CustomerFormFields, CustomerFormValues} from './CustomerFormFields'
import {ISOCountryModel} from '../../../../../../models/ISOCountryModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {CustomerCategoryModel} from '../../../../../../models/svc/CustomerCategoryModel'
// import {FormTranslationTabList} from '../../../../../../components/utils/FormTranslationTabList'
import {FileInputValueCollection} from '../../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../../components/inputs/FileInput/ImageInputValue'
import {
  CustomerModelCreateFormDataParams,
  CustomerModelCreateParams,
} from '../../../../../../models/CustomerModel'
import {RichTextEditor} from '../../../../../../components/inputs/RichTextEditor/RichTextEditor'
import {ApiFormData} from '../../../../../../utils/ApiFormData'

export interface CustomerFormProps {
  formik: FormikContextType<CustomerFormValues>
  countries: ISOCountryModel[]
  onSearchCategories: (filter: FilterModel) => void
  categorySearchResults?: GlobalSearchModel<CustomerCategoryModel>
  lang?: string
  onLangChange?: (lang: string) => void
  disabledFields?: Partial<Record<keyof CustomerFormValues, boolean>>
}

export const CustomerForm = ({
  formik,
  countries,
  onSearchCategories,
  categorySearchResults,
  lang,
  onLangChange,
  disabledFields,
}: CustomerFormProps) => {
  return (
    <>
      <div className='d-flex justify-content-end'>
        {/* <FormTranslationTabList value={lang} onChange={onLangChange} /> */}
      </div>
      <CustomerFormFields
        formik={formik}
        countries={countries}
        categorySearchResults={categorySearchResults}
        onSearchCategories={onSearchCategories}
        lang={lang}
        disabledFields={disabledFields}
      />
    </>
  )
}

export const getPayload = (
  values: CustomerFormValues,
  options?: {eventCode?: string; gateCode?: string}
) => {
  const photo = new FileInputValueCollection<ImageInputValue>()

  if (values.avatar) {
    photo.add(values.avatar)
  }

  const payload: CustomerModelCreateParams = {
    firstName: values.firstName,
    lastName: values.lastName,
    salutation: null,
    email: values.email,
    type: values.type,
    mobile: values.mobile || null,
    gender: values.gender || null,
    nationality: null,
    residence: null,
    dob: null,
    bio: null,
    address: null,
    customerCategoryCode: null,
    designation: null,
    customerOrganization: null,
    translations: {},
    ...options,
    customFields: values.customFields,
  }

  Object.entries(values.translations).forEach(([lang, translation]) => {
    if (payload.translations) {
      payload.translations[lang] = {
        ...translation,
        bio: translation.bio
          ? JSON.stringify(RichTextEditor.getRawValueFromEditorState(translation.bio))
          : null,
        customFields: translation.customFields,
      }
    }
  })

  const hasAddressSpecified = values.addressCity || values.addressCountryCode || values.addressLine1

  if (hasAddressSpecified) {
    payload.address = {
      city: values.addressCity,
      countryCode: values.addressCountryCode,
      postCode: values.addressPostCode || null,
      stateOrProvince: values.addressStateOrProvince || null,
      line1: values.addressLine1,
      line2: values.addressLine2 || null,
      name: values.addressName || null,
    }
  }

  return new ApiFormData<CustomerModelCreateFormDataParams>({
    photo: photo,
    data: JSON.stringify(payload),
  })
}
interface getCustomerPayloadProps {
  values: CustomerFormValues
  editing?: boolean
}

export const getPortalCustomerPayload = ({values}: getCustomerPayloadProps) => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    type: values.type,
    mobile: values.mobile || null,
  }
}
