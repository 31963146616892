import currency from 'currency.js'

export class PriceCalculator {
  private total: currency

  constructor(initialValue = 0) {
    this.total = currency(initialValue)
  }

  public add = (value: number) => {
    this.total = this.total.add(value)
    return this
  }

  public subtract = (value: number) => {
    this.total = this.total.subtract(value)
    return this
  }

  public divide = (value: number) => {
    this.total = this.total.divide(value)
    return this
  }

  public multiply = (value: number) => {
    this.total = this.total.multiply(value)
    return this
  }

  public getValue = () => {
    return this.total.value
  }
}
