import {useCallback, useState} from 'react'
import {ActivityModel} from '../../../../../models/ems/ActivityModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {useFormik} from 'formik'
import {v4 as uuidv4} from 'uuid'
import {useReservationWizard} from './hooks/useReservationWizard'
import {actions} from '../../../redux/CustomerPortalRedux'
import {
  ReservationWizard,
  reservationValidationSchema,
  EMPTY_FORM_VALUES,
  getPayload,
} from './ReservationWizard'
import {PostReservation} from '../../../redux/CustomerPortalCRUD'

export interface ReservationCreateWizardProps {
  onSuccess?: () => void
  event?: EventModel | ActivityModel | null
  step?: number
  isNew?: boolean
}

interface RouterProps {
  params: {
    eventCode: string
    customerCode: string
  }
}

export const ReservationCreateWizard = ({
  onSuccess,
  event,
  step,
  isNew = false,
}: ReservationCreateWizardProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const {pushError, push} = useAlerts()

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: {eventCode, customerCode},
  }: RouterProps = useRouteMatch()

  const {customer, getCustomer} = useReservationWizard()

  useOnChange(customerCode, () => {
    if (customerCode) getCustomer(customerCode)
  })

  useOnChange(step, () => {
    if (step) setCurrentStep(step)
  })

  const formik = useFormik({
    initialValues: EMPTY_FORM_VALUES,
    validationSchema: reservationValidationSchema,
    validateOnMount: true,
    onSubmit: async (values, {setSubmitting}) => {
      dispatch(actions.reservationForm.resetReservationForm())
      setSubmitting(true)
      try {
        onSuccess?.()
      } catch (e: any) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useOnChange(customer, () => {
    if (customer) formik.setFieldValue('customer', customer)
  })

  const handleStepChange = useCallback(
    async (step: number) => {
      setCurrentStep(step)
      if (step === 3 && event) {
        try {
          const payload = getPayload(formik.values, event)
          const {data} = await PostReservation(payload)

          if (data) {
            dispatch(actions.reservationForm.setReservationForm(formik.values))
            push({
              message: `Reservation created successfully`,
              variant: 'success',
              timeout: 3000,
            })
            if (eventCode) history.replace(`/event/${eventCode}/reservation/new/${data.code}`)
          }
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    [dispatch, event, eventCode, formik.values, history, push, pushError]
  )

  const handleOnSubmit = useCallback(() => {
    dispatch(actions.reservationForm.resetReservationForm())
    onSuccess?.()
  }, [dispatch, onSuccess])

  useOnChange(isNew, () => {
    if (isNew) {
      formik.setFieldValue('locations', [{value: '', id: uuidv4(), seatMaps: null, label: ''}])
      formik.setFieldValue('products', [{count: 0, value: '', id: uuidv4()}])
    }
  })

  return (
    <ReservationWizard
      formik={formik}
      step={currentStep}
      onStepChange={handleStepChange}
      event={event}
      customer={customer}
      onSubmit={handleOnSubmit}
    />
  )
}
