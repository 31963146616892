import {useMemo, useCallback} from 'react'
import Cookies from 'js-cookie'
export interface CookiesProps {
  name: string
  value: string
  path?: string
  domain?: string
}

export const useCookies = () => {
  const setCookie = useCallback((options: CookiesProps[], exdays: number) => {
    Cookies.set('cookies', 'true', {
      sameSite: 'none',
      secure: true,
    })
  }, [])

  const clearCookies = useCallback((cookies: CookiesProps[]) => {
    cookies.forEach((item) => {
      Cookies.remove(item.name, {sameSite: 'none', path: item.domain})
    })
    Cookies.remove('cookies')
  }, [])
  return useMemo(
    () => ({
      setCookie,
      clearCookies,
    }),
    [setCookie, clearCookies]
  )
}
