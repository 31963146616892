import {FormikContextType} from 'formik'
import {TicketSubmitFormValues} from '../TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'

interface RulesAndPolicyCheckBoxProps {
  formik: FormikContextType<TicketSubmitFormValues>
  disabled?: boolean
}

export const RulesAndPolicyCheckBox = ({formik, disabled = false}: RulesAndPolicyCheckBoxProps) => {
  return (
    <>
      <div>
        <input
          style={{
            cursor: 'pointer',
          }}
          disabled={disabled}
          className='form-check-input me-2 bg-primary-dw-darker border border-1 border-primary'
          type='checkbox'
          checked={formik.values.rulesAndPolicy}
          {...formik.getFieldProps('rulesAndPolicy')}
        />
      </div>
      <div className='flex-grow-1'>
        <p className='mb-0 text-white fs-6 fw-bolder'>
          I have read the activity waiver and I agree to the rules and policies mentioned.
        </p>
        <p className='mb-0 text-white fs-6 fw-bolder'>
          لقد قرأت التنازل والالتزام لنشاط التجربة وأوافق على القواعد والسياسات المذكورة
        </p>
        {formik.errors.rulesAndPolicy && formik.touched.rulesAndPolicy && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span className='' role='alert'>
                {formik.errors.rulesAndPolicy}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
