import {useCallback, useState} from 'react'
import {WidgetModel} from '../../../../models/ems/WidgetModel'
import {GetEmsWidgets} from '../redux/EmsCRUD'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'
// import { useDebounce } from '../../../../components/hooks/useDebounce'

export interface UseEventDashboardWidgetOptions {
  dailyWidgets?: (keyof WidgetModel)[]
  monthlyWidgets?: (keyof WidgetModel)[]
  date: Date | null
  eventCode?: string
  month?: Date | null
}

export const useEventDashboardWidget = ({
  dailyWidgets,
  monthlyWidgets,
  date,
  eventCode,
  month,
}: UseEventDashboardWidgetOptions) => {
  const [widgetData, setWidgetData] = useState<WidgetModel>({})
  const {isLoading: isDashboardLoading, managePromise} = usePromiseManager()
  // const resetWidgetDebounce = useDebounce(500)

  const resetWidgetData = useCallback(async () => {
    if (eventCode && date) {
      try {
        const {data} = await managePromise(
          'dashboard',
          GetEmsWidgets({
            widgetCodes: [...(dailyWidgets || []), ...(monthlyWidgets || [])],
            eventCode,
            date: date.toISOString(),
            month: month ? month.getMonth() + 1 : undefined,
            year: month?.getFullYear(),
          })
        )
        setWidgetData(data)
      } catch (e) {
        if (e instanceof CancelledPromiseError) {
          // Ignore cancelled promise.
        }
      }
    }
  }, [eventCode, date, managePromise, dailyWidgets, monthlyWidgets, month])
  // useEffect(() => {
  //   resetWidgetDebounce(async () => {
  //     resetWidgetData()
  //   })
  // }, [resetWidgetData, resetWidgetDebounce])
  return {
    data: widgetData,
    isLoading: isDashboardLoading,
    resetWidgetData,
  }
}
