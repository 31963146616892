import {useCallback, FC} from 'react'
import {useHistory} from 'react-router-dom'
import clsx from 'clsx'
import {CustomerChatUserModel} from '../../../../models/eva/CustomerChatUserModel'
import styles from './UsersChat.module.scss'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {CustomerImage} from '../CustomerImage/CustomerImage'
import {DateFormatter} from '../../../../components/utils/formatter/DateFormatter'

export interface UserChatProps {
  usersList: CustomerChatUserModel[]
}

const UsersChat: FC<UserChatProps> = ({usersList}) => {
  const history = useHistory()
  const handleMoveToChat = useCallback(
    (user: CustomerChatUserModel) => {
      history.push(`/chat/${user.code}`, {
        from: '/chat',
        selectedUser: user,
      })
    },
    [history]
  )

  return (
    <div className='d-flex flex-column'>
      {usersList.length > 0 &&
        usersList.map((user, index, array) => {
          const isLast = index === array.length - 1
          return (
            <div
              className='d-flex flex-column flex-grow-1'
              key={user.code}
              style={{
                minHeight: '60px',
              }}
            >
              <div
                className={clsx('d-flex flex-row cursor-pointer flex-grow-1', styles.cardEffect)}
                onClick={() => handleMoveToChat(user)}
              >
                <div className='d-flex flex-grow-1 flex-column justify-content-top'>
                  <div className='d-flex align-items-start'>
                    <CustomerImage width='4rem' height='4rem' className='me-3' customer={user} />
                    <div className='d-flex flex-column justify-content-center'>
                      <h2 className='mb-0'>{user.name}</h2>
                      {user.lastMessage && (
                        <>
                          <p className={clsx('text-secondary mt-2', styles.lastMessage)}>
                            {user.lastMessage.message}
                          </p>
                          <DateFormatter
                            moment={{
                              format: 'MMM D, h:mm A',
                            }}
                            className={clsx('text-uppercase', styles.date)}
                          >
                            {user.lastMessage.createdAt}
                          </DateFormatter>
                        </>
                      )}
                    </div>
                    {user.unreadCount > 0 && (
                      <div className='d-flex flex-column justify-content-center align-items-end flex-grow-1'>
                        <div className='rounded-circle w-20px h-20px bg-primary text-white text-center d-flex align-items-center flex-column justify-content-center'>
                          <p className='m-0'>{user.unreadCount}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <MetronicIcon iconName='Angle-right' iconType='Navigation' size='lg' />
                </div>
              </div>
              {!isLast && (
                <div className='my-4'>
                  <HorizontalDivider />
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

export {UsersChat}
