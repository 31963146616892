import clsx from 'clsx'
import {ReactNode} from 'react'
import styles from './LandscapeFullScreen.module.scss'

export interface ForcedLandScapeProps {
  children?: ReactNode
  className?: string
}

export const ForcedLandScape = ({children, className}: ForcedLandScapeProps) => {
  return <div className={clsx(styles.content, className)}>{children}</div>
}
