import {useCallback} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../config/drawer'

export const useLayoutHelpers = () => {
  const {pathname} = useLocation()

  const isRouteActive = useCallback(
    (path?: string) => {
      if (path) {
        return Boolean(matchPath(pathname, {path}))
      }
      return false
    },
    [pathname]
  )

  const childrenIsActive = useCallback(
    (menu?: NavigationSectionMenuItem[]): boolean => {
      if (menu) {
        return menu.some((item) => {
          const isRouteMatch = isRouteActive(item.to)
          if (!isRouteMatch && item.subMenus) {
            return childrenIsActive(item.subMenus)
          }
          return isRouteMatch
        })
      }
      return false
    },
    [isRouteActive]
  )

  const itemHasSubMenu = useCallback((item: NavigationSectionMenuItem) => {
    return Boolean(item.subMenus?.length)
  }, [])

  return {isRouteActive, childrenIsActive, itemHasSubMenu}
}
