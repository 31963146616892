import {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  PasswordResetForm,
  PasswordResetFormValues,
} from '../../../../components/forms/PasswordResetForm'
import {useAuthRole} from '../../../../components/hooks/useAuthRole'
import {resetPassword} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

const FirstLoginPasswordResetForm = () => {
  const _auth = useAuthRole()
  const history = useHistory()
  useEffect(() => {
    if (!_auth) {
      history.replace('/auth/login')
    }
  }, [_auth, history])

  const dispatch = useDispatch()
  const handleOnSubmit = useCallback(
    async (formValues: PasswordResetFormValues) => {
      const response = await resetPassword(formValues.newPassword)
      dispatch(auth.actions.login(response.data.token))
    },
    [dispatch]
  )

  return <PasswordResetForm onSubmit={handleOnSubmit} />
}

export default FirstLoginPasswordResetForm
