import {FormikContextType} from 'formik'
import {useCallback, useState} from 'react'
import {useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {RootState} from '../../../../../../../setup'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {useOnChange} from '../../../../../../components/hooks/useOnChange'
import {BookingFormValues} from '../../../../../../models/booking-wizard/BookingWizard'
import {BookingDetailModel} from '../../../../../../models/customer-portal/BookingDetailModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {useCustomerAuth} from '../../../../hooks/useCustomerAuth'
import {GetBookingDetail} from '../../../../redux/CustomerPortalCRUD'
import {ProductBookingDetailsTable} from '../table/ProductBookingDetailsTable'

export interface BookingWizardFinalEditStepProps {
  formik: FormikContextType<BookingFormValues>
}

export const BookingWizardFinalEditStep = ({formik}: BookingWizardFinalEditStepProps) => {
  const [bookingProducts, setBookingProducts] = useState<GlobalSearchModel<BookingDetailModel>>()
  const bookingForm: BookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingForm
  ) as BookingFormValues
  const [bookingProductFilters, setBookingProductFilter] = useState<FilterModel>()
  const {pushError} = useAlerts()

  const auth = useCustomerAuth()
  useOnChange(bookingForm, () => {
    if (bookingForm) {
      formik.setFieldValue('customer', bookingForm.customer)
      formik.setFieldValue('products', bookingForm.products)
      formik.setFieldValue('vouchers', bookingForm.vouchers)
      formik.setFieldValue('eventCode', bookingForm.eventCode)
    }
  })

  const {
    params: {bookingCode},
  }: any = useRouteMatch()

  const refreshProductList = useCallback(async () => {
    try {
      const {data} = await GetBookingDetail(bookingProductFilters)
      setBookingProducts(data)
    } catch (e) {
      pushError(e)
    }
  }, [bookingProductFilters, pushError])

  const onFilterProductHandler = useCallback(
    async (filter: FilterModel) => {
      if (bookingCode) {
        try {
          const filters = {
            ...filter,
            filters: {...filter.filters, booking: bookingCode},
          }
          setBookingProductFilter(filters)
          const {data} = await GetBookingDetail(filters)
          setBookingProducts(data)
        } catch (e) {
          pushError(e)
        }
      }
    },
    [bookingCode, pushError]
  )

  return (
    <div className='container'>
      <div>
        <h5 className='mt-10 mb-0 px-8'>Fulfill ticket</h5>
        <ProductBookingDetailsTable
          data={bookingProducts}
          onFilter={onFilterProductHandler}
          onRefresh={refreshProductList}
          customerCode={auth?.getUser().code}
        />
      </div>
    </div>
  )
}
