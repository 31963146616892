import {useFormik} from 'formik'
import {
  SIGNUP_SCHEMA,
  initialValues,
  getMyPassSignupPayload,
} from '../hooks/useFormMyPassSignupData'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {GetCountries, MyPassExistEmail, MyPassSignup} from '../redux/CustomerPortalCRUD'
import {CheckboxInput, SelectInput, TextInput} from '../../../components/inputs'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {useFormikMobileNumberInputHelpers} from '../../../components/inputs/useFormikMobileNumberInputHelpers'
import {Button} from '../../../components/inputs/Button'
import {MetronicIconButton} from '../../../components/inputs/MetronicIconButton'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {MobileNumberInputWithSelect} from '../../../components/inputs/MobileNumberInputWithSelect'
import {SweetAlert} from '../../../components/modals/SweetAlert'
import {ISOCountryModel} from '../../../models/ISOCountryModel'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
export interface MyPassSignupValues {
  email: string
  firstName: string
  lastName: string
  country: string
  nationality: string
  mobile: string
  countryCode: string
  policy: boolean
  offers: boolean
}

interface MyPassSignUpProps {
  setShowSignUp: (value: boolean) => void
  pageState: any
}

export const MyPassSignUp = ({setShowSignUp, pageState}: MyPassSignUpProps) => {
  const [emailExist, setEmailExist] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const emailDebounce = useDebounce(400)
  const {pushError} = useAlerts()
  const [countries, setCountries] = useState<ISOCountryModel[]>([])

  const resetCountryList = useCallback(async () => {
    try {
      const {data} = await GetCountries()
      if (data) setCountries(data)
    } catch (err) {
      pushError(err)
    }
  }, [pushError])

  useEffect(() => {
    resetCountryList()
  }, [resetCountryList])

  const countryList = useMemo(() => {
    return countries.map<SelectInputItem>((country) => ({
      label: country.name,
      value: country.code,
    }))
  }, [countries])

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: SIGNUP_SCHEMA,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const dataValues = getMyPassSignupPayload({values})
        const {data} = await MyPassSignup(dataValues, pageState)
        if (data) setOpen(true)
      } catch (e) {
        pushError(e)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useOnChange(formik.values.email, async () => {
    if (formik.values.email) {
      emailDebounce(async () => {
        const {data} = await MyPassExistEmail(formik.values.email)
        if (data.error.code === 'API200') setEmailExist('Email Already exist')
        else setEmailExist(undefined)
      })
    }
  })
  const mobileNumberInput = useFormikMobileNumberInputHelpers(formik)

  return (
    <div className='px-5'>
      <Button
        onClick={() => setShowSignUp(false)}
        className='me-3 px-0 d-inline-flex align-items-center text-light'
        variant='secondary'
      >
        <MetronicIconButton
          type='reset'
          className='btn'
          iconType='Navigation'
          iconName='Arrow-left'
          variant='text'
          size='md'
          color='light'
        />
        Back
      </Button>
      <TextInput
        label='Email'
        errorMessage={formik.errors.email || emailExist}
        isTouched={formik.touched.email || Boolean(emailExist)}
        labelClassName='text-light'
        errorMessageClassName='text-danger'
        placeholder='Email*'
        {...formik.getFieldProps('email')}
      />

      <TextInput
        label='First Name'
        errorMessage={formik.errors.firstName}
        isTouched={formik.touched.firstName}
        placeholder='First name*'
        disabled={Boolean(emailExist)}
        labelClassName='text-light'
        errorMessageClassName='text-danger'
        {...formik.getFieldProps('firstName')}
      />

      <TextInput
        label='Last Name'
        errorMessage={formik.errors.lastName}
        isTouched={formik.touched.lastName}
        placeholder='Last name*'
        labelClassName='text-light'
        disabled={Boolean(emailExist)}
        errorMessageClassName='text-danger'
        {...formik.getFieldProps('lastName')}
      />

      <SelectInput
        placeholder='Country*'
        label='Country'
        errorMessage={formik.errors.country}
        isTouched={formik.touched.country}
        allowInvalidValue
        disabled={Boolean(emailExist)}
        items={countryList}
        labelClassName='text-light'
        errorMessageClassName='text-danger'
        {...formik.getFieldProps('country')}
      />
      <SelectInput
        placeholder='Nationality*'
        label='Nationality'
        errorMessage={formik.errors.nationality}
        isTouched={formik.touched.nationality}
        allowInvalidValue
        disabled={Boolean(emailExist)}
        items={countryList}
        labelClassName='text-light'
        errorMessageClassName='text-danger'
        {...formik.getFieldProps('nationality')}
      />
      <MobileNumberInputWithSelect
        labelClassName='text-light'
        errorMessageClassName='text-danger'
        label='Phone Number'
        disabled={Boolean(emailExist)}
        placeholder='581234567'
        {...mobileNumberInput.getFieldProps('mobile')}
        onChangePhoneCode={(value) => formik.setFieldValue('countryCode', value)}
      />
      <CheckboxInput
        label='I acknowledge that I have read and understand the Privacy Policy *'
        labelTextClass='text-light'
        disabled={Boolean(emailExist)}
        noMargin
        {...formik.getFieldProps('policy')}
      />
      {formik.touched.policy && formik.errors.policy && (
        <div className='fv-plugins-message-container mt-2'>
          <div className='fv-help-block'>
            <span className='text-danger' role='alert'>
              {formik.errors.policy}
            </span>
          </div>
        </div>
      )}
      <CheckboxInput
        className='my-5'
        label='Yes I would like to receive updates and special offers'
        labelTextClass='text-light'
        disabled={Boolean(emailExist)}
        noMargin
        {...formik.getFieldProps('offers')}
      />

      <Button
        className='mt-5 w-100'
        type='submit'
        disabled={formik.isSubmitting || Boolean(emailExist)}
        variant='primary'
        onClick={formik.submitForm}
      >
        Sign up
      </Button>

      <SweetAlert
        customClass={{popup: 'bg-primary-cp-dark'}}
        open={open}
        showConfirmButton={false}
        width='auto'
        onClose={() => setShowSignUp(false)}
      >
        <div className='p-5'>
          <p className='fs-1 text-white ff-admm-bold'>Thank you for your registration.</p>
          <p className='fs-3 text-white'>You Will receive email soon to set your password.</p>
          <Button
            className='mt-5'
            type='button'
            disabled={formik.isSubmitting || Boolean(emailExist)}
            variant='primary'
            onClick={() => setShowSignUp(false)}
          >
            Continue to login
          </Button>
        </div>
      </SweetAlert>
    </div>
  )
}
