import {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {EVENT_CODE} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useBooleanState} from '../../../components/hooks/useBooleanState'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {TopicModel} from '../../../models/eva/TopicModel'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {
  FullScreenPresentationSlides,
  PresentationItem,
} from '../components/presentations/FullScreenPresentationSlides'
import {FullScreenPrentationView} from '../components/presentations/FullScreenPresentationView'
import {usePresentationWebSockets} from '../hooks/usePresentationWebSockets'
import {GetTopicByCode} from '../redux/EvaCRUD'

interface PresentationPageParams {
  topicCode: string
}

export const PresentationPage = () => {
  const {enableState: showPresentation, state: isPresentationShown} = useBooleanState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const {pushError} = useAlerts()
  const {topicCode} = useParams<PresentationPageParams>()
  const [topic, setTopic] = useState<TopicModel>()
  const history = useHistory()
  const {
    currentPageIndex,
    updateCurrentPage,
    isSpeaker,
    startPresentation,
    presentations,
    refreshTopicSlide,
    endPresentation,
    isPresentationPlaying,
  } = usePresentationWebSockets({topic})
  const {staticUrls, refreshAppConfig} = useAppConfig()

  const handleExit = useCallback(() => {
    history.push(`/topics/${topicCode}`)
  }, [history, topicCode])

  const resetTopicData = useCallback(async () => {
    if (topicCode && EVENT_CODE) {
      try {
        const {data} = await GetTopicByCode(topicCode)
        setTopic(data)
      } catch (e) {
        pushError(e)
        handleExit()
      }
    }
  }, [topicCode, pushError, handleExit])

  const presentationFiles = useMemo((): PresentationItem[] => {
    return presentations.map((file) => {
      return {
        image: new ImageInputValue(staticUrls.public, file),
        notes: file.note,
      }
    })
  }, [presentations, staticUrls.public])

  const handlePageIndexChange = useCallback(
    async (newPageIndex: number) => {
      const previousPageIndex = pageIndex
      try {
        setPageIndex(newPageIndex)
        await updateCurrentPage(newPageIndex)
      } catch (e) {
        pushError(e)
        setPageIndex(previousPageIndex)
      }
    },
    [pageIndex, pushError, updateCurrentPage]
  )

  const handleStartPresentation = useCallback(async () => {
    if (topic) {
      try {
        await startPresentation()
        setPageIndex(currentPageIndex)
        showPresentation()
      } catch (e) {
        pushError(e)
      }
    }
  }, [currentPageIndex, pushError, showPresentation, startPresentation, topic])

  const handleViewPresentation = useCallback(async () => {
    if (topic) {
      try {
        await refreshTopicSlide(topic)
        showPresentation()
      } catch (e) {
        pushError(e)
      }
    }
  }, [pushError, refreshTopicSlide, showPresentation, topic])

  const handleStop = useCallback(async () => {
    await endPresentation()
    handleExit()
  }, [endPresentation, handleExit])

  useEffect(() => {
    refreshAppConfig()
  }, [refreshAppConfig])

  const presentation = useMemo(() => {
    if (isPresentationShown) {
      if (isSpeaker) {
        return (
          <FullScreenPresentationSlides
            items={presentationFiles}
            onPageChange={handlePageIndexChange}
            currentPageIndex={pageIndex}
            onExit={handleExit}
            onStop={handleStop}
          />
        )
      }
      return (
        <FullScreenPrentationView
          items={presentationFiles}
          currentPageIndex={currentPageIndex}
          onExit={handleExit}
        />
      )
    }
  }, [
    currentPageIndex,
    handleExit,
    handlePageIndexChange,
    handleStop,
    isPresentationShown,
    isSpeaker,
    pageIndex,
    presentationFiles,
  ])

  useEffect(() => {
    if (isPresentationPlaying === false) {
      handleExit()
    }
  }, [handleExit, isPresentationPlaying])

  useOnChange(topicCode, () => {
    resetTopicData()
  })

  useOnChange(topic, () => {
    if (topic) {
      if (isSpeaker) {
        handleStartPresentation()
      } else {
        handleViewPresentation()
      }
    }
  })

  return <div>{presentation}</div>
}
