import {
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
  useEffect,
  FormEvent,
  MouseEvent,
  useRef,
} from 'react'
import styles from './ChatMessagePage.module.scss'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useHistory, useParams} from 'react-router-dom'
import {useChat} from '../../hooks/useChat'
import clsx from 'clsx'
import {useInfiniteScrollContainer} from '../../../../components/hooks/useInfiniteScrollContainer'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ChatModel} from '../../../../models/eva/ChatModel'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'
import {GetChatHistory, MarkUserChatsAsRead} from '../../redux/EvaCRUD'
import {FilterModel} from '../../../../models/FilterModel'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {DateUtil} from '../../../../utils/DateUtil'
import {TextInput} from '../../../../components/inputs'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {useBooleanState} from '../../../../components/hooks/useBooleanState'
import {DateFormatter} from '../../../../components/utils/formatter/DateFormatter'

export const ChatMessagePage = () => {
  const {
    disableState: hideNewMessagePrompt,
    enableState: showNewMessagePrompt,
    state: isNewMessagePromptShown,
  } = useBooleanState(false)
  const [message, setMessage] = useState<string>('')
  const {code} = useParams<{code: string}>()
  const {managePromise} = usePromiseManager()
  const {pushError} = useAlerts()
  const [chatSearchResult, setChatSearchResult] = useState<GlobalSearchModel<ChatModel>>()
  const elementRef = useRef<HTMLDivElement>(null)

  const {
    chatHistory: webSocketChat,
    sendChat,
    isConnected,
  } = useChat({
    targetUserCode: code,
    autoRead: true,
  })

  const currentUser = useRootStateSelector((state) => state.eva.user)
  const history = useHistory()
  const refreshSearch = useCallback(
    async (filters: FilterModel) => {
      if (currentUser) {
        try {
          const {data} = await managePromise(
            'chat',
            GetChatHistory({
              ...filters,
              filters: {
                ...filters.filters,
              },
              currentUserCode: currentUser.data.code,
              chatUserCode: code,
            })
          )
          setChatSearchResult(data)
          return data.data
        } catch (e) {
          if (e instanceof CancelledPromiseError) {
            // Ignore cancelled promises
          } else {
            pushError(e)
          }
        }
      }
    },
    [code, currentUser, managePromise, pushError]
  )
  const {
    handleOnScroll: handleInfiniteScroll,
    items: chatHistory,
    refresh,
  } = useInfiniteScrollContainer<HTMLDivElement, ChatModel>({
    total: chatSearchResult?.total,
    onFetch: refreshSearch,
    bottomToTop: true,
  })

  const chatMessageList = useMemo(() => {
    const data: ChatMessageListData[] = []

    webSocketChat.forEach((chat) => {
      const isCreatedBySelf = chat.senderCode === currentUser?.data.code
      data.push({
        id: `${chat.time}${chat.message}`,
        isCreatedBySelf,
        message: chat.message,
        time: DateUtil.getDateFromApiString(chat.time),
      })
    })

    chatHistory.forEach((chat) => {
      const isCreatedBySelf = chat.sender?.code === currentUser?.data.code
      const isExisting = data.some((item) => item.id === chat.code)
      if (!isExisting) {
        data.push({
          id: chat.code,
          isCreatedBySelf,
          message: chat.message,
          time: DateUtil.getDateFromApiString(chat.createdAt),
        })
      }
    })

    return data
  }, [chatHistory, webSocketChat, currentUser?.data.code])

  const messagesNode = useMemo(() => {
    return chatMessageList.map((chat) => {
      return (
        <div key={chat.id} className={clsx('d-flex flex-column-reverse mb-2 ')}>
          <div
            className={clsx('rounded px-4 py-3', {
              'text-start align-self-start bg-light-success': !chat.isCreatedBySelf,
              'text-end align-self-end bg-light-primary': chat.isCreatedBySelf,
            })}
          >
            <p className='mb-0'>{chat.message}</p>
            <DateFormatter
              moment={{
                format: 'MMM D, h:mm A',
              }}
              className={clsx(styles.date, 'mb-0 fs-9 text-uppercase')}
            >
              {chat.time}
            </DateFormatter>
          </div>
        </div>
      )
    })
  }, [chatMessageList])

  const handleChatSubmit = useCallback(
    (e?: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault()
      if (message) {
        sendChat(message)
        setMessage('')
      }
    },
    [message, sendChat]
  )

  const scrollToBottom = useCallback(() => {
    elementRef.current?.scrollBy({
      top: elementRef.current.scrollHeight,
    })
  }, [elementRef])

  const handleTextInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  }, [])

  const handleOnScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const {scrollTop, clientHeight, scrollHeight} = e.target as HTMLDivElement
      const percentScrolled = scrollTop / (scrollHeight - clientHeight)
      if (percentScrolled === 0) {
        hideNewMessagePrompt()
      }
      handleInfiniteScroll(e)
    },
    [handleInfiniteScroll, hideNewMessagePrompt]
  )

  useEffect(() => {
    if (!currentUser) {
      history.replace('/chat')
    }
  }, [currentUser, history])

  useEffect(() => {
    if (currentUser) {
      MarkUserChatsAsRead(currentUser.data.code, code)
    }
  }, [code, currentUser])

  useEffect(() => {
    if (elementRef.current) {
      const {scrollTop, clientHeight, scrollHeight} = elementRef.current
      const percentScrolled = scrollTop / (scrollHeight - clientHeight)
      if (percentScrolled < 0) {
        showNewMessagePrompt()
      }
    }
  }, [elementRef, showNewMessagePrompt, webSocketChat])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className='d-flex flex-column justify-content-between w-100 h-100 overflow-hidden'>
      <div
        ref={elementRef}
        onScroll={handleOnScroll}
        className='mb-4 mt-5 d-flex flex-column-reverse flex-grow-1 overflow-auto px-5'
      >
        {messagesNode}
        {isNewMessagePromptShown && (
          <div
            className={clsx(
              'bg-light-primary rounded shadow p-2 text-center align-self-center',
              styles.newMessagePrompt
            )}
            role='button'
            onClick={scrollToBottom}
          >
            New Message
            <MetronicIcon iconType='Navigation' iconName='Angle-down' />
          </div>
        )}
      </div>
      <div className={clsx('d-flex w-100 bg-body')}>
        <div className='flex-grow-1 p-1'>
          <form onSubmit={handleChatSubmit}>
            <TextInput
              autoFocus={true}
              fullWidth
              className='w-100 rounded-0 bg-body border border-light'
              noMargin
              placeholder={isConnected ? 'Message' : 'Connecting...'}
              value={message}
              onChange={handleTextInputChange}
            />
          </form>
        </div>
        <div className='pe-1 d-flex align-items-center text-primary'>
          <button
            disabled={!message || !isConnected}
            type='button'
            className='btn btn-md btn-light-primary rounded-0 text-uppercase'
            onClick={handleChatSubmit}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

interface ChatMessageListData {
  id: string
  time: Date
  message: string
  isCreatedBySelf: boolean
}
