import {useFormik} from 'formik'
import {useCallback} from 'react'
import {Button} from '../inputs/Button'
import {NumberInput} from '../inputs/NumberInput/NumberInput'

export interface SocialDistancingFormValues {
  spacing: number
  per: number
}

export interface SocialDistancingFormProps {
  initialValues?: SocialDistancingFormValues
  onSubmit?: (values: SocialDistancingFormValues) => void
}

export const SocialDistancingForm = ({
  initialValues = EMPTY_INITIAL_VALUES,
  onSubmit,
}: SocialDistancingFormProps) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onSubmit && onSubmit(values)
    },
  })

  const getNumberChangeHandler = useCallback(
    (name: string) => (value: number) => {
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  return (
    <form onSubmit={formik.handleSubmit}>
      <NumberInput
        onChange={getNumberChangeHandler('spacing')}
        value={formik.values.spacing}
        label='Spacing'
      />
      <NumberInput
        onChange={getNumberChangeHandler('per')}
        value={formik.values.per}
        label='Per seats'
      />
      <Button type='submit' variant='primary'>
        Apply
      </Button>
    </form>
  )
}

const EMPTY_INITIAL_VALUES: SocialDistancingFormValues = {
  spacing: 1,
  per: 1,
}
