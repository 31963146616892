export class PushNotification {
  /**
   * Browser asks for notification permission.
   * Does not show when the user already declines
   * or approved.
   */
  public static askUserPermission = () => {
    return Notification.requestPermission()
  }

  /**
   * Browser supports displaying and polling push notifications.
   */
  public static isSupported = () => {
    return 'serviceWorker' in navigator && 'PushManager' in window
  }

  /**
   * Gets the existing push manager subscription.
   * If not existing, creates a new one.
   */
  public static getNotificationSubscription = async (vapidKey: string) => {
    const serviceWorker = await navigator.serviceWorker.ready

    const existingSubscription = await serviceWorker.pushManager.getSubscription()
    if (existingSubscription) {
      return existingSubscription
    }
    return serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: PushNotification.urlBase64ToUint8Array(vapidKey),
    })
  }

  public static get permission() {
    return Notification.permission
  }

  private static urlBase64ToUint8Array = (base64String: string) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
}
