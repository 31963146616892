import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import _ from 'lodash'
import {Button} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'
import {ProductVoucherModalType} from '../../../../models/booking-wizard/BookingWizard'
import {
  ProductVoucherBulkModel,
  BulkConsolidatedFormValues,
} from '../../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {useAlerts} from '../../../alerts/useAlerts'
import {useSafeStateUpdate} from '../../../hooks/useSafeStateUpdate'

interface EditBulkProductVoucherModalProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onEdit: (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => void
  data: ProductVoucherBulkModel
  isPortal?: boolean
  bookingBulkForm?: BulkConsolidatedFormValues
  selectedItems?: ProductVoucherBulkModel[]
}

export const EditBulkProductVoucherModal = ({
  modalType,
  onModalClose,
  onEdit,
  data,
  isPortal,
  bookingBulkForm,
  selectedItems,
}: EditBulkProductVoucherModalProps) => {
  const [value, setValue] = useState<ProductVoucherBulkModel>(data)
  const {pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()

  const handleProductQTYComputation = useCallback(
    async (newValue: ProductVoucherBulkModel) => {
      if (selectedItems?.length && newValue.maxQTY) {
        newValue.remainingQty = newValue.maxQTY
        const productDetails = selectedItems.find((p) => p.code === newValue.code)
        let childArray = []
        if (productDetails?.product?.children?.length) {
          for (const selected of selectedItems) {
            if (selected.code !== newValue.code) {
              const found = productDetails.product.children?.find(
                (item) => item.code === selected.code
              )
              if (found) {
                childArray.push(selected)
              }
            }
          }

          const highestQty = _.orderBy(childArray, ['qty'], ['desc'])
          if (highestQty.length) {
            const initialDiff = newValue.remainingQty - highestQty[0].qty
            newValue.remainingQty = initialDiff
          }
          const remaining = newValue.remainingQty - newValue.qty

          if (remaining >= 0) {
            newValue.remainingQty = remaining
            setValue(newValue)
          } else {
            newValue.remainingQty = 0
          }
        } else if (productDetails && newValue.remainingQty) {
          for (const selected of selectedItems) {
            if (selected.code !== newValue.code) {
              if (selected.product?.children?.length) {
                const hasParent = selected.product.children.find(
                  (item) => item.code === newValue.code
                )

                if (hasParent) {
                  const remaining: number = newValue.remainingQty - selected.qty

                  if (remaining >= 0) {
                    newValue.remainingQty = remaining
                  } else {
                    newValue.remainingQty = 0
                  }
                }
              }
            }
          }

          const childRemaining = newValue.remainingQty - newValue.qty

          if (childRemaining >= 0) {
            newValue.remainingQty = childRemaining
            safeUpdate(() => setValue(newValue))
          } else {
            newValue.remainingQty = 0
          }
        }
      }
    },
    [pushError, bookingBulkForm?.customers, selectedItems]
  )

  const handleCountChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      let count = e.target.value
      if (Number(count) === 0) {
        count = '1'
      }
      const newValue: ProductVoucherBulkModel = {...data, qty: Number(count)}
      if (isPortal) {
        await handleProductQTYComputation(newValue)
      } else {
        setValue(newValue)
      }
    },
    [data, handleProductQTYComputation, isPortal]
  )

  const customerCount = useMemo(() => {
    return value.remainingQty || 0
  }, [value.remainingQty])

  const handleEdit = useCallback(() => {
    if (modalType === 'product' && value) onEdit(modalType, value)
    if (modalType === 'voucher' && value) onEdit(modalType, value)
  }, [modalType, onEdit, value])

  const updatedData = useMemo(() => {
    if (value) {
      return {...value, qty: value?.qty || 0}
    } else {
      return {qty: 0}
    }
  }, [value])

  useEffect(() => {
    handleProductQTYComputation(data)
  }, [])

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      <div className='swal2-corners'>
        {isPortal && (
          <div className='d-flex justify-content-end mt-5 me-5'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
        )}
      </div>
      <div className='h-100 d-flex flex-column position-relative p-5'>
        <div className='d-flex flex-column flex-grow-1 text-start'>
          {!isPortal && (
            <div className='position-absolute top-0 end-0'>
              <MetronicIconButton
                variant='text'
                size='md'
                iconType='Navigation'
                iconName='Close'
                tooltip='Close Modal'
                onClick={onModalClose}
              />
            </div>
          )}
          <h2 className='text-start mb-5'>{`Edit ${data.name || ''}`}</h2>
          <label className='text-start mb-5'>Quantity</label>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            value={updatedData.qty}
            min={0}
          />
          {isPortal && value && value.qty ? (
            <div className='flex-grow-1'>
              <div className='d-flex flex-column justify-content-center align-items-end fs-7 h-100'>
                {`Remaining Quantity: ${customerCount}`}
              </div>
            </div>
          ) : null}
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          <Button
            className='btn btn-primary position-relative btn-cut-conrner pe-20 w-100'
            onClick={handleEdit}
          >
            <LoadingSpinner loading={false}>Save</LoadingSpinner>
          </Button>
        </div>
      </div>
    </SweetAlert>
  )
}
