import {Component, ReactNode} from 'react'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'

export interface DrawerProps {
  onClose?: () => void
  onOpen?: () => void
  children?: ReactNode
  className?: string
}

interface DrawerState {
  ktDrawer: DrawerComponent | null
}

export class Drawer extends Component<DrawerProps, DrawerState> {
  public state: DrawerState = {
    ktDrawer: null,
  }

  private handleRef = (element: HTMLDivElement | null) => {
    if (element && !this.state.ktDrawer) {
      const newKtDrawer = new DrawerComponent(element)
      this.setState({ktDrawer: newKtDrawer})
      newKtDrawer.on('kt.drawer.hide', this.handleOnClose)
      newKtDrawer.on('kt.drawer.show', this.handleOnOpen)
    }
  }

  private handleOnClose = () => {
    const {onClose} = this.props
    const {ktDrawer} = this.state
    if (onClose && ktDrawer?.isShown()) {
      onClose()
    }
  }

  private handleOnOpen = () => {
    const {onOpen} = this.props
    const {ktDrawer} = this.state
    if (onOpen && !ktDrawer?.isShown()) {
      onOpen()
    }
  }

  public show = () => {
    const {ktDrawer} = this.state
    if (ktDrawer) {
      ktDrawer.show()
    }
  }
  public hide = () => {
    const {ktDrawer} = this.state
    if (ktDrawer) {
      ktDrawer.hide()
    }
  }

  public isShown = () => {
    const {ktDrawer} = this.state
    if (ktDrawer) {
      return ktDrawer.isShown()
    }
    return false
  }

  public toggle = () => {
    const {ktDrawer} = this.state
    if (ktDrawer) {
      return ktDrawer.toggle()
    }
  }

  public render = () => {
    return (
      <div
        ref={this.handleRef}
        data-kt-drawer='true'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'lg':'400px'}"
        data-kt-drawer-direction='end'
        className={this.props.className}
      >
        {this.props.children}
      </div>
    )
  }
}
