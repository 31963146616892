import {ChangeEvent, forwardRef, ReactNode, useCallback, useEffect, useMemo} from 'react'
import clsx from 'clsx'
import {PASSWORD_DOT} from './PinInput'
import {Validators} from '../../utils/Validators'
import {useBooleanState} from '../hooks/useBooleanState'

export interface DigitInputProps {
  length: number
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  className?: string
  isAlphaNumeric?: boolean
  shouldAutoFocus?: boolean
}

export const DigitInput = forwardRef<HTMLInputElement, DigitInputProps>(
  (
    {
      length,
      onChange,
      value,
      className,
      disabled,
      isAlphaNumeric = false,
      shouldAutoFocus = false,
    }: DigitInputProps,
    ref
  ) => {
    const {enableState: onFocus, disableState: onBlur, state: isFocused} = useBooleanState(false)

    const isItemFocused = useCallback(
      (index: number) => {
        const isItemFocused = value.length === index && isFocused
        return isItemFocused
      },
      [isFocused, value.length]
    )

    const getValue = useCallback(
      (index: number, isItemFocused: boolean) => {
        const itemValue = value[index]
        if (itemValue) {
          return itemValue
        } else if (isItemFocused) {
          return '-'
        } else {
          return PASSWORD_DOT
        }
      },
      [value]
    )

    const digits = useMemo(() => {
      const digitNodes: ReactNode[] = []

      for (let i = 0; i < length; i++) {
        const isFocused = isItemFocused(i)
        digitNodes.push(
          <span
            className={'form-control form-control-solid mx-1 fs-1 fw-bolder digit-input-item text-uppercase'}
            key={i}
          >
            <span
              className={clsx('digit-input-item__value', {
                'digit-input-item__value--focused': isFocused,
              })}
            >
              {getValue(i, isFocused)}
            </span>
          </span>
        )
      }

      return digitNodes
    }, [getValue, isItemFocused, length])

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (isAlphaNumeric) {
          if (value.length <= length) {
            onChange(value)
          }
        } else {
          if (Validators.WHOLE_NUMBER_REGEX.test(value) && value.length <= length) {
            onChange(value)
          }
        }
      },
      [isAlphaNumeric, length, onChange]
    )

    useEffect(() => {
      if (shouldAutoFocus && ref && typeof ref === 'object' && ref.current) {
        ref.current.focus()
      }
    }, [shouldAutoFocus, ref])

    return (
      <div className={clsx('digit-input', className)}>
        <label className='digit-input-label'>
          {digits}
          <input
            ref={ref}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            className='digit-hidden-input'
            type={isAlphaNumeric ? 'string' : 'number'}
            onChange={handleChange}
            value={value}
          />
        </label>
      </div>
    )
  }
)
