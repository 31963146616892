import {KTSVG} from '../../../../../../_metronic/helpers'
import {Button} from '../../../../../components/inputs/Button'
import {CustomerPortalRowActions} from '../../../../../components/tables/actions/CustomerPortalRowActions'
import {Spinner} from '../../../../../components/utils/Spinner'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {isVoucherShareable, isVoucherIsAddToWallet} from '../../../hooks/useVoucherTableActions'

export interface VoucherTableRowActionsProps {
  onDisable?: (voucher: VoucherModel) => void
  onView?: (data: VoucherModel) => void
  onDownload?: (data: VoucherModel[]) => void
  onWallet?: (data: VoucherModel) => void
  onShareByWahtsApp?: (data: VoucherModel) => void
  onShareByEmail?: (data: VoucherModel) => void
  data: VoucherModel
  disabled?: boolean
  loading?: boolean
  iconWidth?: string
}

export const VoucherTableRowActions = ({
  onDisable,
  onView,
  onDownload,
  onWallet,
  onShareByWahtsApp,
  onShareByEmail,
  data,
  disabled,
  loading,
  iconWidth,
}: VoucherTableRowActionsProps) => {
  return (
    <div className='d-flex gap-3'>
      {onDisable && isVoucherShareable(data) && (
        <CustomerPortalRowActions
          loading={loading}
          disabled={disabled}
          variant='danger'
          onClick={onDisable}
          data={data}
          iconType='Code'
          iconName='Stop'
          activeColor='danger'
          tooltip='Disable Voucher'
        />
      )}
      {onView && (
        <Button
          type='button'
          className='bg-transparent p-2'
          tooltip='View'
          size='sm'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/view.svg'
            onClick={() => onView(data)}
          />
        </Button>
      )}

      {onDownload && (
        <Button
          type='button'
          className='bg-transparent p-2'
          tooltip='Download'
          size='sm'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          {loading ? (
            <Spinner className='me-5' size='lg' />
          ) : (
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/download.svg'
              onClick={() => onDownload([data])}
            />
          )}
        </Button>
      )}

      {onWallet && isVoucherIsAddToWallet(data) && (
        <Button
          type='button'
          className='bg-transparent p-2'
          size='sm'
          tooltip='Add to wallet'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/wallet.svg'
            onClick={() => onWallet(data)}
          />
        </Button>
      )}
      {onShareByWahtsApp && (
        <Button
          type='button'
          className='bg-transparent p-2'
          size='sm'
          tooltip='Share by whatsApp'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/whatsapp.svg'
            onClick={() => onShareByWahtsApp(data)}
          />
        </Button>
      )}

      {onShareByEmail && (
        <Button
          type='button'
          className='bg-transparent p-2'
          size='sm'
          tooltip='Share by email'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/mail.svg'
            onClick={() => onShareByEmail(data)}
          />
        </Button>
      )}
    </div>
  )
}
