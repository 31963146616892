import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {FilterModel} from '../../../../models/FilterModel'
import {BookingCardGrid} from '../../components/BookingCard/BookingCardGrid'
import {BookingTable} from '../../components/tables/BookingTable/BookingTable'
import {actions} from '../../redux/CustomerPortalRedux'
import {CustomerModel} from '../../../../models/CustomerModel'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {EventModel} from '../../../../models/ems/EventModel'
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PortalEventDetailsBookingEdit} from './PortalEventDetailsBookingEdit'
import {PortalEventDetailsBookingCreate} from './PortalEventDetailsBookingCreate'
// import {useOnChange} from '../../../../components/hooks/useOnChange'
import {PortalEventDetailsBulkBookingCreate} from './PortalEventDetailsBulkBookingCreate'
import {BookingStateProvider} from './BookingStateContext'

interface BookingPageProps {
  customer?: CustomerModel
  event?: EventModel
  isDetail?: boolean
}

export const BookingsPortalPage = ({customer, event, isDetail = false}: BookingPageProps) => {
  const bookings = useRootStateSelector((state) => state.customerPortal.bookings)
  const searchDebounce = useDebounce(200)
  const {setFilter} = useEntityFilter('customer-portal-booking')
  const dispatch = useDispatch()
  // const {pathname} = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['draft', 'confirmed', 'fulfilled', 'active'],
        event: event?.code,
        customer: customer?.code,
      },
      groupBy: '',
    }
    return filters
  }, [customer?.code, event?.code])

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.bookings.search())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refresh()
    },
    [setFilter, refresh]
  )

  const handleOnEdit = useCallback(
    async (booking) => {
      if (customer) {
        history.push(`/customer/${customer.code}/booking/edit/${booking.code}`)
      }
      if (event) {
        history.push(`/event/${event.code}/booking/edit/${booking.code}`)
      }
    },
    [customer, event, history]
  )

  const handleNewBookingClick = useCallback(() => {
    if (customer) {
      history.push(`/customer/${customer.code}/booking/new`)
    }
    if (event) {
      history.push(`/event/${event.code}/booking/new`)
    }
  }, [customer, event, history])

  // useOnChange(pathname, () => {
  //   if (pathname && !pathname.includes('new') && !pathname.includes('edit')) {
  //     refresh()
  //   }
  // })

  const handleNewBulkBooking = useCallback(() => {
    if (customer) {
      history.push(`/customer/${customer.code}/booking/new/bulk`)
    }

    if (event) {
      history.push(`/event/${event.code}/booking/new/bulk`)
    }
  }, [customer, event, history])

  return (
    <>
      {!customer && <PageTitle>Bookings</PageTitle>}
      <BookingStateProvider>
        <Switch>
          {/* BILK BOOKING ROUTES */}
          <Route exact path={`${match.path}/new/bulk`}>
            <PortalEventDetailsBulkBookingCreate event={event} />
          </Route>

          {/* SINGLE BOOKING ROUTES */}
          <Route exact path={`${match.path}/edit/:bookingCode/final`}>
            <PortalEventDetailsBookingEdit event={event} step={3} />
          </Route>
          <Route exact path={`${match.path}/edit/:bookingCode`}>
            <PortalEventDetailsBookingEdit event={event} />
          </Route>
          <Route exact path={`${match.path}/new/:bookingCode/final`}>
            <PortalEventDetailsBookingCreate event={event} step={3} />
          </Route>
          <Route exact path={`${match.path}/new/:bookingCode`}>
            <PortalEventDetailsBookingCreate event={event} />
          </Route>
          <Route exact path={`${match.path}/new`}>
            <PortalEventDetailsBookingCreate event={event} />
          </Route>

          {/*  BOOKING TABLE */}

          <Route exact path={match.path}>
            <BookingTable
              initialFilters={initialFilters}
              onRefresh={refresh}
              onEdit={handleOnEdit}
              onNewBookingClick={customer ? undefined : handleNewBookingClick}
              className={isDetail ? '' : 'rs-table-bg'}
              customWrapper='rs-top'
              cornersClassName='table-cut-corner-polygon rs-gradient'
              paginationClassName='px-10 pb-10 rs-pagination'
              onNewBulkBookingClick={!customer ? handleNewBulkBooking : undefined}
              data={bookings}
              onFilter={handleFilter}
              event={event}
              customer={customer}
              removeColumns={removeColumns}
            />
            <BookingCardGrid
              initialFilters={initialFilters}
              className='d-none'
              data={bookings}
              onFilter={handleFilter}
            />
          </Route>
        </Switch>
      </BookingStateProvider>
    </>
  )
}

export const removeColumns = ['event']
