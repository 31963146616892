import {FormikContextType} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {SearchableSelectInput} from '../../../../../components/inputs/SearchableSelect'
import {CompanyModel} from '../../../../../models/CompanyModel'
import {GetRegistrationCoutries} from '../redux/CustomerRegistrationCRUD'

export interface MemberCompanyValues {
  companyName: CompanyModel | null
}

export interface MemberCompanyFormProps<T extends MemberCompanyValues> {
  formik: FormikContextType<T>
}

export const MemberCompanyForm = <T extends MemberCompanyValues>({
  formik,
}: MemberCompanyFormProps<T>) => {
  const [companyList, setCompanyList] = useState<CompanyModel[]>([])

  const resetCompanyList = useCallback(async () => {
    const {data} = await GetRegistrationCoutries()
    setCompanyList(data)
  }, [])

  const handleCompanyChange = useCallback(
    (key: string) => (value: CompanyModel | null) => {
      formik.setFieldValue(key, value)
    },
    [formik]
  )

  const handleCompanyBlur = useCallback(
    (key: string) => () => {
      formik.setFieldTouched(key)
    },
    [formik]
  )

  useEffect(() => {
    resetCompanyList()
  }, [resetCompanyList])

  return (
    <SearchableSelectInput
      id='companyName'
      items={companyList}
      itemMapper={companyMapper}
      label='Select member company name'
      placeholder='Please select'
      value={formik.values.companyName}
      onChange={handleCompanyChange('companyName')}
      onBlur={handleCompanyBlur('companyName')}
    />
  )
}

const companyMapper = (item: CompanyModel) => ({label: item.name, value: item.code})

export const STEP_COMPANY_KEYS: Array<keyof MemberCompanyValues> = ['companyName']
