import axios from 'axios'
import {LocationModel} from '../../../models/acs/LocationModel'
import {
  DigitalMenuFilterModel,
  DigitalMenuScanFilterModel,
} from '../../../models/digital-menu/DigitalMenuFilterModel'
import {DigitalMenuLocationModel} from '../../../models/digital-menu/DigitalMenuLocationModel'
import {DigitalMenuOrderModelCreateParams} from '../../../models/digital-menu/DigitalMenuOrderModel'
import {
  DigitalMenuPayfortToken,
  DigitalMenuVoucherPaymentCreateParams,
} from '../../../models/digital-menu/DigitalMenuVoucherPayment'
import {TableReservationCreateParams} from '../../../models/digital-menu/DigitalTableReservationModel'
import {TicketModel} from '../../../models/ems/TicketModel'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {OutletModel, OutletOrderType} from '../../../models/fnb/OutletModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {
  PayfortPurchase3dsResponse,
  PayfortPurchaseResponse,
  PayfortTokenizationSuccessResponse,
} from '../../../models/outlet/PayfortModel'

export interface PayfortCreateParams {
  cardNumber: string
  orderCode: string
  expiryDate: string
  cardSecurityCode: string
  amount: number
  customerEmail: string
}

export const SearchDigitalMenu = (filters?: DigitalMenuFilterModel) =>
  axios.post<OutletModel[]>(`/digital-menu/list`, filters)
export const SearchDigitalMenuTypes = (filters?: DigitalMenuFilterModel) =>
  axios.post<OutletOrderType[]>(`/digital-menu/outlet-types`, filters)
export const CreateOrder = (payload: DigitalMenuOrderModelCreateParams) =>
  axios.post<OrderModel>(`/digital-menu/order`, payload)
export const DigitalMenuScan = (payload: DigitalMenuScanFilterModel) =>
  axios.post(`/digital-menu/scan`, payload)
export const GetOutletByCode = (code: string, filters: object = {}) =>
  axios.post<OutletModel>(`/digital-menu/${code}`, {filters})
export const CreateTableReservation = (params: TableReservationCreateParams) =>
  axios.post(`/digital-menu/table-reservation`, params)
export const GetDigitalMenuLocations = (eventCode: string) =>
  axios.post<DigitalMenuLocationModel[]>(`/digital-menu/locations`, {eventCode})
export const GetOrderByCode = (orderCode: string) =>
  axios.get<OrderModel>(`/digital-menu/order/${orderCode}`)
export const GetOrderByMobileNumber = (mobileNumber: string) =>
  axios.post<GlobalSearchModel<OrderModel>>(`/digital-menu/get-order`, {
    filters: {mobile: mobileNumber},
  })
export const GetOrderBill = (orderCode: string) =>
  axios.get<Blob>(`/print/${orderCode}`, {
    responseType: 'blob',
  })
export const GetTicketByCode = (ticketCode: string) =>
  axios.get<TicketModel>(`/digital-menu/ticket/${ticketCode}`)

export const PayByVoucher = (params: DigitalMenuVoucherPaymentCreateParams) =>
  axios.post(`/digital-menu/payment`, params)

export const ProcessCardPayment = (merchantReference: string, authCode: string) =>
  axios.post(`/digital-menu/process-payment`, {merchantReference, authCode})

export const GetPayfortParams = (orderCode: string, returnUrl: string) =>
  axios.post<DigitalMenuPayfortToken>(`/digital-menu/payfort/token`, {
    orderCode,
    returnUrl,
  })

export const GetPayfortCredentials = (
  orderCode: string,
  signature: string,
  customerEmail: string,
  returnUrl: string
) =>
  axios.post<PayfortPurchaseResponse>(`/digital-menu/payfort/payment`, {
    customerEmail,
    orderCode,
    tokenName: signature,
    returnUrl,
  })

export const GetPayfortToken = (orderCode: string) =>
  axios.post<{code: string; data: PayfortTokenizationSuccessResponse}>(`/digital-menu/token-data`, {
    orderCode,
  })

export const GetPayfortPayment = (orderCode: string) =>
  axios.post<{code: string; data: PayfortPurchase3dsResponse}>(`/digital-menu/payment-data`, {
    orderCode,
  })

export const GetLocationByTicketCode = (ticketCode: string) =>
  axios.get<LocationModel>(`/digital-menu/ticket-location/${ticketCode}`)
export const GetLocationByCode = (locationCode: string) =>
  axios.get<LocationModel>(`/digital-menu/location/${locationCode}`)
