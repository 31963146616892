import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {Zoom} from '@visx/zoom'
import {useCallback, useEffect, useRef} from 'react'
import {useSeatHelper} from '../hooks/useSeatHelper'
import {SeatMapEditorProps} from '../SeatMapEditorProps'
import {SeatMapValue} from '../SeatMapValue'
import {SeatValue} from '../SeatValue'
import {SeatAttributes, SVGSeats} from './SVGSeats'
import {ZoomChildrenParam, ZoomArea} from './ZoomArea'
export interface SVGSeatMapInputProps extends SeatMapEditorProps {
  isCircledDaysBox?: boolean
  isShowNoneFilledDays?: boolean
  isPortal?: boolean
}

export const SVGSeatMapInput = ({
  columns,
  rows,
  disabled,
  extra,
  shared,
  sharedDisabled,
  hidden,
  isBottomToTop,
  isRightToLeft,
  occupied,
  onChange,
  onRowClick,
  renderHidden,
  spacingX,
  value,
  isCustom = false,
  seatsDays,
  isCircledDaysBox,
  isShowNoneFilledDays,
  isPortal,
}: SVGSeatMapInputProps) => {
  const zoomRef = useRef<ZoomChildrenParam>(null)

  const {getSeatColor, shouldSeatRender, getSeatStroke, getCustomColor, getSeatDays} =
    useSeatHelper({
      renderHidden,
      occupied,
      extra,
      shared,
      sharedDisabled,
      hidden,
      disabled,
      value,
      seatsDays,
      isShowNoneFilledDays,
      isPortal,
    })

  const handleSeatClick = useCallback(
    (seatValue: SeatValue) => {
      const newValue = value?.clone() || new SeatMapValue()
      newValue.toggleValue(seatValue.row, seatValue.column)
      onChange?.(newValue)
    },
    [onChange, value]
  )

  const getSeatAttributes = useCallback(
    (seatValue: SeatValue): Partial<SeatAttributes> => {
      return {
        color: isCustom
          ? getCustomColor(seatValue.row, seatValue.column)
          : getSeatColor(seatValue.row, seatValue.column),
        hidden: !shouldSeatRender(seatValue.row, seatValue.column),
        stroke: getSeatStroke(seatValue.row, seatValue.column),
        days: getSeatDays(seatValue.row, seatValue.column),
      }
    },
    [getCustomColor, getSeatColor, getSeatDays, getSeatStroke, isCustom, shouldSeatRender]
  )
  useEffect(() => {
    zoomRef.current?.center()
  }, [rows, columns])

  return (
    <ParentSize>
      {({height, width}) => (
        <Zoom<SVGSVGElement>
          width={width}
          height={height}
          scaleXMin={1 / 5}
          scaleXMax={4}
          scaleYMin={1 / 5}
          scaleYMax={4}
        >
          {(zoom) => (
            <ZoomArea zoomRef={zoomRef} height={height} width={width} zoom={zoom}>
              <SVGSeats
                isBottomToTop={isBottomToTop}
                isRightToLeft={isRightToLeft}
                spacingX={spacingX}
                height={height}
                width={width}
                columns={columns}
                rows={rows}
                onSeatClick={handleSeatClick}
                getSeatAttributes={getSeatAttributes}
                boxHeight={seatsDays ? 150 : undefined}
                boxWidth={seatsDays ? 150 : undefined}
                isCircledDaysBox={seatsDays ? isCircledDaysBox : false}
              />
            </ZoomArea>
          )}
        </Zoom>
      )}
    </ParentSize>
  )
}
