import clsx from 'clsx'
import {ReactNode, useMemo} from 'react'
import DatePicker from 'react-datetime-picker'
import styles from './DateTimePickerInput.module.scss'

export interface DateTimePickerInputProps {
  className?: string
  value: Date | null
  onChange: (value: Date | null) => void
  minDate?: Date
  maxDate?: Date
  label?: string | ReactNode
  disabled?: boolean
  noMargin?: boolean
  noClear?: boolean
  format?: string
}

export const DateTimePickerInput = ({
  className,
  value,
  onChange,
  minDate,
  maxDate,
  label,
  disabled,
  noMargin,
  noClear,
  format,
}: DateTimePickerInputProps) => {
  const locale = useMemo(() => {
    if (navigator?.language) {
      return navigator.language
    }
    return 'en-US'
  }, [])

  return (
    <div className={clsx({'mb-5': !noMargin}, className)}>
      {label && <label className='form-label'>{label}</label>}
      <div>
        <DatePicker
          disabled={disabled}
          locale={locale}
          className={clsx(styles.input, 'form-date-input form-control-solid')}
          value={value || undefined}
          minDate={minDate}
          onChange={onChange}
          maxDate={maxDate}
          clearIcon={noClear ? null : undefined}
          format={format}
          disableClock
        />
      </div>
    </div>
  )
}
