import {useCallback, useMemo, useState} from 'react'

export const useBooleanState = (defaultValue: boolean) => {
  const [state, setState] = useState(defaultValue)

  const toggleState = useCallback(() => {
    setState(!state)
  }, [state])

  const disableState = useCallback(() => {
    setState(false)
  }, [])

  const enableState = useCallback(() => {
    setState(true)
  }, [])

  return useMemo(() => {
    return {
      state,
      setState,
      toggleState,
      disableState,
      enableState,
    }
  }, [disableState, enableState, state, toggleState])
}
