import { useMemo } from "react"
import { RowAction } from "../../../../../../../components/tables/actions/RowAction"
import { ManageRowAction } from "../../../../../../../components/tables/actions/ManageRowAction"
import { ProductLocationModel } from "../../../../../../../models/ems/ReservationModel"

export interface ReservationProductLocationTableActionsProps {
    onAssignSeats?: (data: ProductLocationModel) => void
    data: ProductLocationModel
    loading?: boolean
    onDelete?: (item: any) => void
    onEdit?: (item: any) => void
  }
  
  export const ReservationProductLocationTableActions = ({
    data,
    onAssignSeats,
    loading,
    onDelete,
    onEdit,
  }: ReservationProductLocationTableActionsProps) => {

    
    const assignSeats = useMemo(() => {
      if (data.isSeated) {
        return (
          <RowAction
            onClick={onAssignSeats}
            data={data}
            loading={loading}
            iconType='Home'
            iconName='Armchair'
            tooltip='Assign seats'
            variant='info'
          />
        )
      }
    }, [data, loading, onAssignSeats])

    const editButton = useMemo(() => {

          return (
            <RowAction
              loading={loading}
              data={data}
              iconType='Design'
              iconName='Edit'
              variant='warning'
              tooltip='Edit'
              onClick={onEdit}
            />
          )
  
      }, [data, onEdit, loading])

      const deleteButton = useMemo(() => {
          return (
            <ManageRowAction data={data} onDelete={onDelete}  />
          )
      }, [data, onDelete])


    return (
      <>
        {assignSeats}
        {editButton}
        {deleteButton}
        
      </>
    )
  }
  