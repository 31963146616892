import {ReactNode} from 'react'
import {createPortal} from 'react-dom'
import {useLayoutPortalData} from '../LayoutPortalProvider'

export interface HeaderContentProps {
  children?: ReactNode
}

export const HEADER_PARENT_ID = 'header_content_parent'

export const HeaderContent = ({children}: HeaderContentProps) => {
  const {headerContent} = useLayoutPortalData()

  if (!headerContent) {
    return null
  }

  return createPortal(children, headerContent)
}
