import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {MetronicIcon, MetronicIconPath} from '../../inputs/MetronicIcon'
import styles from './NumberStepper.module.scss'

export interface NumberStepperProps {
  state?: 'warning' | 'danger' | 'completed'
  onClick?: (value: number) => void
  active?: boolean
  value: number
  icon?: MetronicIconPath
  title: string
  description?: string
  className?: string
  disabled?: boolean
}

export const NumberStepper = ({
  state,
  value,
  title,
  icon,
  active,
  disabled,
  description,
  onClick,
  className,
}: NumberStepperProps) => {
  const colorVariant = useMemo(() => {
    switch (state) {
      case 'completed':
        return 'success'
      default:
        return state || 'primary'
    }
  }, [state])

  const backgroundClassName = useMemo(() => {
    if (active) {
      return `bg-${colorVariant}`
    }
    return `bg-light-${colorVariant}`
  }, [active, colorVariant])

  const stateIcon = useMemo(() => {
    let iconType: string | undefined = undefined
    let iconName: string | undefined = undefined

    if (state === 'completed') {
      iconType = 'Navigation'
      iconName = 'Check'
    } else if (state === 'warning') {
      iconType = 'Code'
      iconName = 'Warning-1-circle'
    } else if (state === 'danger') {
      iconType = 'Code'
      iconName = 'Error-circle'
    }

    if (iconType && iconName) {
      return (
        <KTSVG
          className={clsx('svg-icon-sm', 'ms-1', `svg-icon-${colorVariant}`, {
            'opacity-50': !active,
          })}
          path={`/media/icons/duotone/${iconType}/${iconName}.svg`}
        />
      )
    }
  }, [active, colorVariant, state])

  const handleClick = useCallback(() => {
    !disabled && onClick?.(value)
  }, [disabled, onClick, value])

  const thumbIcon = useMemo(() => {
    if (icon) {
      return (
        <MetronicIcon
          iconType={icon.iconType}
          iconName={icon.iconName}
          color={active ? 'white' : colorVariant}
          size='md'
        />
      )
    }
  }, [active, colorVariant, icon])

  return (
    <div
      className={clsx('d-flex text-start text-white', className, {
        [styles.rootDisabled]: disabled,
        'opacity-50': !active && state === 'completed',
      })}
      role={onClick && 'button'}
      onClick={handleClick}
    >
      <div
        className={clsx(
          styles.stepNumber,
          'rounded d-flex align-items-center justify-content-center me-3 fw-bolder fs-3 flex-shrink-0',
          {
            'text-muted': !active && state === 'completed',
            'text-white': active,
          },
          backgroundClassName
        )}
      >
        {thumbIcon ?? value}
      </div>
      <div
        className={clsx({
          'text-muted': !active && state === 'completed',
          'text-white': active,
        })}
      >
        <div className='fw-bolder d-flex align-items-center justify-content-start'>
          <h4
            className={clsx({
              'text-white': (active && state !== 'completed') || !active,
              'text-success': active && state === 'completed',
            })}
          >
            {title}
          </h4>
          {stateIcon}
        </div>
        {description && <div className='fs-7'>{description}</div>}
      </div>
    </div>
  )
}
