import {ReactNode, useEffect, useRef, useState} from 'react'

export interface DropdownMenuProps {
  children?: ReactNode
  target?: Element | null
}

export const DropdownMenu = ({children, target}: DropdownMenuProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  let [height, setHeight] = useState<number>(target?.getBoundingClientRect().height || 0)

  useEffect(() => {
    const container = ref.current
    if (container) {
      const {height} = container.getBoundingClientRect()
      setHeight(height)
    }
  }, [target])

  return (
    <div
      className='card dropdown-menu'
      style={{
        position: 'absolute',
        bottom: -height,
        right: 0,
        minWidth: '5rem',
        padding: '1rem',
      }}
      ref={ref}
    >
      {children}
    </div>
  )
}
