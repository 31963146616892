import clsx from 'clsx'
import moment from 'moment'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {EVENT_CODE} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {Button} from '../../../components/inputs/Button'
import {GetAgendaDateRange} from '../redux/EvaCRUD'

export interface AgendaDayListProps {
  onClick: (date: string) => void
  selectedDate?: string
  className?: string
}

export const AgendaDayList = ({onClick, selectedDate, className}: AgendaDayListProps) => {
  const [dayList, setDayList] = useState<string[]>([])
  const {pushError} = useAlerts()

  const resetDayList = useCallback(async () => {
    try {
      if (!EVENT_CODE) {
        throw new Error('EVENT_CODE is not set')
      }
      const {data} = await GetAgendaDateRange(EVENT_CODE)
      setDayList(data)
    } catch (e) {
      pushError(e)
    }
  }, [pushError])

  const sortedDayList = useMemo(() => {
    return [...dayList].sort()
  }, [dayList])

  const getOnClickHandler = useCallback(
    (day: string) => () => {
      onClick(day)
    },
    [onClick]
  )

  useOnChange(sortedDayList, () => {
    if (sortedDayList[0]) {
      onClick(sortedDayList[0])
    }
  })

  const buttonList = useMemo(() => {
    return sortedDayList.map((day) => {
      const label = moment(day).format('MMM DD')
      return (
        <Button
          size='lg'
          className='rounded-0 text-nowrap'
          variant={selectedDate === day ? 'primary' : 'default'}
          onClick={getOnClickHandler(day)}
          key={day}
        >
          {label}
        </Button>
      )
    })
  }, [getOnClickHandler, selectedDate, sortedDayList])

  useEffect(() => {
    resetDayList()
  }, [resetDayList])

  return <div className={clsx('overflow-auto d-flex', className)}>{buttonList}</div>
}
