import {useCallback, useEffect, useMemo, useState} from 'react'
import {CustomerModel} from '../../../models/CustomerModel'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {GetCustomerByCode} from '../../../modules/customer-portal/redux/CustomerPortalCRUD'
import {GetCustomers} from '../../../modules/default/svc/redux/SvcCRUD'
import {selectItemMapper} from '../../../utils/idExtractor'
import {useAlerts} from '../../alerts/useAlerts'
import {useDebounce} from '../../hooks/useDebounce'
import {FilterSearchableSelectInput} from '../../inputs/SearchableSelect/FilterSearchableSelectInput'
import {useTableFilterState} from '../useTableFilterState'

export interface CustomerFilterInputProps {
  field: string
  label: string
  placeholder?: string
  customerType?: string[]
}

export const CustomerFilterInput = ({
  field,
  label,
  placeholder,
  customerType,
}: CustomerFilterInputProps) => {
  const {pushError} = useAlerts()
  const {clearAdvancedFilterValue, setAdvancedFilterValue, getAdvancedFilterValue} =
    useTableFilterState()
  const searchDebounce = useDebounce(500)
  const fetchCustomerDebounce = useDebounce(500)
  const [searchResult, setSearchResult] = useState<GlobalSearchModel<CustomerModel>>()
  const [value, setValue] = useState<CustomerModel | null>(null)

  const fieldValue = useMemo(() => getAdvancedFilterValue(field), [field, getAdvancedFilterValue])

  const searchCustomers = useCallback(
    async (filter?: FilterModel) => {
      let newFilter = {...filter}
      if (newFilter.filters && customerType) {
        newFilter.filters['type'] = customerType
      }
      try {
        const {data} = await GetCustomers(newFilter)
        return data
      } catch (e) {
        pushError(e)
      }
    },
    [customerType, pushError]
  )

  const getSearchableFilterFieldFilterHandler = useCallback(
    async (filter: FilterModel) => {
      searchDebounce(async () => {
        const data = await searchCustomers(filter)
        setSearchResult(data || undefined)
      })
    },
    [searchDebounce, searchCustomers]
  )

  const getSearchableFilterFieldChangeHandler = useCallback(
    (value: CustomerModel | null) => {
      if (value) {
        setAdvancedFilterValue(field, value.code)
        setValue(value)
      } else {
        clearAdvancedFilterValue(field)
        setValue(null)
      }
    },
    [clearAdvancedFilterValue, field, setAdvancedFilterValue]
  )

  useEffect(() => {
    if (value) {
      if (fieldValue !== value.code) {
        setValue(null)
      }
    } else {
      if (fieldValue && typeof fieldValue === 'string') {
        fetchCustomerDebounce(() => {
          GetCustomerByCode(fieldValue)
            .then(({data}) => {
              setValue(data)
            })
            .catch(() => {
              setValue(null)
              clearAdvancedFilterValue(field)
            })
        })
      }
    }
  }, [clearAdvancedFilterValue, fetchCustomerDebounce, field, fieldValue, value])

  return (
    <FilterSearchableSelectInput
      allowClear
      itemMapper={selectItemMapper}
      searchResult={searchResult}
      onFilter={getSearchableFilterFieldFilterHandler}
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={getSearchableFilterFieldChangeHandler}
    />
  )
}
