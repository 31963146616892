import {ButtonCrumb, ButtonCrumbAttributes} from './ButtonCrumb'

export interface ButtonCrumbsProps {
  crumbs: ButtonCrumbAttributes[]
  onClick?: (value: string) => void
}

export const ButtonCrumbs = ({crumbs, onClick}: ButtonCrumbsProps) => {
  return (
    <div className='overflow-auto d-flex align-items-center'>
      {crumbs.map((crumb, index, array) => {
        const isLast = array.length - 1 === index
        return <ButtonCrumb key={crumb.value} hasNext={!isLast} {...crumb} onClick={onClick} />
      })}
    </div>
  )
}
