import {useCallback, useMemo, useState} from 'react'

export const useLoadingState = (initialValues?: unknown[]) => {
  const [loadingState, setLoadingState] = useState<Partial<Record<string, boolean>>>(
    getInitialValues(initialValues)
  )

  const isKeyLoading = useCallback(
    (key: string) => {
      return loadingState[key] || false
    },
    [loadingState]
  )

  const setDoneLoading = useCallback((key: string | string[]) => {
    setLoadingState((currentValue) => {
      const newValue = {
        ...currentValue,
      }

      if (Array.isArray(key)) {
        key.forEach((key) => {
          delete newValue[key]
        })
      } else {
        delete newValue[key]
      }
      return newValue
    })
  }, [])

  const setIsLoading = useCallback(
    (key: string | string[]) => {
      setLoadingState((currentValue) => {
        const newValue = {...currentValue}

        if (Array.isArray(key)) {
          key.forEach((key) => {
            newValue[key] = true
          })
        } else {
          newValue[key] = true
        }

        return newValue
      })
      return () => setDoneLoading(key)
    },
    [setDoneLoading]
  )

  const isLoading = useMemo(() => {
    return Object.values(loadingState).some((value) => value)
  }, [loadingState])

  return useMemo(
    () => ({
      setIsLoading,
      isKeyLoading,
      isLoading,
      setDoneLoading,
    }),
    [setIsLoading, isKeyLoading, isLoading, setDoneLoading]
  )
}

const getInitialValues = (initialValues?: unknown[]): Record<string, boolean> => {
  const values: Record<string, boolean> = {}

  initialValues?.forEach((key) => {
    if (typeof key === 'string') {
      values[key] = true
    }
  })

  return values
}
