import {useMemo, useState, useCallback} from 'react'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {BookingStatusColumn} from './BookingStatusColumn'
import {DateTimeFormatter} from '../../../../../components/utils/formatter/DateTimeFormatter'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {useBookingDataUtils} from '../../../hooks/useBookingDataUtils'
import {BookingTableRowActions} from './BookingTableRowActions'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {BookingCodeColumn} from './BookingCodeColumn'
import {EventModel} from '../../../../../models/ems/EventModel'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {ControlledFilterTable} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterTable'

export interface BookingTableProps {
  data?: GlobalSearchModel<BookingModel>
  onFilter: (filter: FilterModel) => void
  onEdit?: (data: BookingModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  onNewBookingClick?: () => void
  className?: string
  event?: EventModel
  customer?: CustomerModel
  filters: FilterModel
  expandedGroups?: TableRowId[]
  onExpandedGroupChange?: (groups: TableRowId[]) => void
  initialFilters?: FilterModel
}

export const BookingTable = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  onEdit,
  onNewBookingClick,
  className,
  event,
  customer,
  filters,
  expandedGroups,
  onExpandedGroupChange,
  initialFilters,
}: BookingTableProps) => {
  const [selected, setSelected] = useState<TableRowId[]>([])

  const columns = useMemo(() => {
    let theCulomns: TableColumnOptions<BookingModel>[] = [
      {
        field: 'code',
        label: 'Code',
        hideable: true,
        sortable: true,
        render: ({data}) => BookingCodeColumn({data, event}),
        cellStyle: ColumnStyle.CODE,
      },

      {
        field: 'status',
        sortable: true,
        hideable: true,
        label: 'Status',
        render: (data) => BookingStatusColumn(data),
      },
      {
        field: 'email',
        sortable: true,
        hideable: true,
        label: 'email',
      },

      {
        field: 'updatedAt',
        sortable: true,
        label: 'Date',
        hideable: true,
        render: ({data}) => (
          <DateTimeFormatter className='text-uppercase text-nowrap'>
            {data.updatedAt}
          </DateTimeFormatter>
        ),
        cellStyle: ColumnStyle.DATE,
      },
    ]

    if (customer) {
      theCulomns = theCulomns.filter((item) => item.field !== 'customer')
    }

    return theCulomns
  }, [customer, event])

  const {cancelSingle, isBookingLoading} = useBookingDataUtils({
    onRefresh,
    onRefreshCallback,
  })

  const rowActions = useCallback(
    (data: BookingModel) => (
      <BookingTableRowActions
        loading={isBookingLoading(data)}
        data={data}
        onCancel={cancelSingle}
        onEdit={onEdit}
      />
    ),
    [cancelSingle, isBookingLoading, onEdit]
  )

  return (
    <ControlledFilterTable<BookingModel>
      initialFilters={initialFilters}
      onFilter={onFilter}
      idExtractor={idExtractor}
      columns={columns}
      filters={filters}
      searchResults={data}
      actions={rowActions}
      selection={selected}
      onSelectionChange={setSelected}
      expandedGroups={expandedGroups}
      onExpandedGroupsChange={onExpandedGroupChange}
      filterOnMount
    />
  )
}
