import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {VerifyCustomerAuthToken} from './PokenCRUD'
import {CustomerUserModel} from '../../../models/customer-portal/AuthModel'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface OutletAuthState {
  user?: CustomerUserModel
  token?: string
}

export interface IOutletState {
  auth?: OutletAuthState
}

const initialAuthState: IOutletState = {}

// ACTIONS TYPES
export const actionTypes = {
  auth: {
    login: '[Poken] LOGIN',
    logout: '[Poken] LOGOUT',
    requestAuth: '[Poken] REQUEST AUTH',
    fulfillAuth: '[Poken] FULFILL AUTH',
  },
}

export const reducer = persistReducer(
  {storage, key: 'outlet', whitelist: ['auth']},
  (state: IOutletState = initialAuthState, action: ActionWithPayload<IOutletState>) => {
    switch (action.type) {
      case actionTypes.auth.login: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.requestAuth: {
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.logout: {
        return {
          ...state,
          auth: undefined,
        }
      }
      case actionTypes.auth.fulfillAuth: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: auth?.user,
          },
        }
      }

      default:
        return state
    }
  }
)

// AFTER EFFECT
export function* saga() {
  yield takeLatest(actionTypes.auth.login, function* () {
    yield put(actions.auth.requestAuth())
  })

  yield takeLatest(actionTypes.auth.requestAuth, function* () {
    const {data} = yield VerifyCustomerAuthToken()
    yield put(actions.auth.fulfillUser(data.user))
  })
}

// ACTIONS
export const actions = {
  auth: {
    login: (token: string) => ({type: actionTypes.auth.login, payload: {auth: {token}}}),
    logout: () => ({type: actionTypes.auth.logout}),
    requestAuth: () => ({type: actionTypes.auth.requestAuth}),
    fulfillUser: (user: CustomerUserModel) => ({
      type: actionTypes.auth.fulfillAuth,
      payload: {
        auth: {
          user,
        },
      },
    }),
  },
}
