import {ChangeEvent, useCallback, useMemo, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'
import {BaseFileInputValue} from './BaseFileInputValue'
import {FileInputValueCollection} from './FileInputValueCollection'

export enum FileType {
  IMAGE = 'image/*',
  PNG_JPG_JPEG = 'image/png, image/jpg, image/jpeg',
  VIDEO = 'video/*',
  VIDEO_AND_IMAGE = 'video/*,image/*',
  CSV = 'text/csv',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  FONTS = 'font/otf,font/ttf,font/woff,font/woff2',
}

export interface FileInputProps<T extends BaseFileInputValue> {
  onChange: (file: FileInputValueCollection<T>) => void
  fileFactory: (file: File) => T
  label?: string
  accept?: FileType
  limit?: number
  value: FileInputValueCollection<T>
  placeholder?: string
}

export const FileInput = <T extends BaseFileInputValue>({
  onChange,
  accept,
  label,
  limit = 1,
  value,
  fileFactory,
  placeholder,
}: FileInputProps<T>) => {
  const id = useRef(uuidv4()).current

  const isMultiple = useMemo(() => {
    return limit > 1 || limit === 0
  }, [limit])

  const handleFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files
      if (fileList) {
        const clone = value.clone()
        extractFiles(fileList).map(fileFactory).forEach(clone.add)
        onChange && onChange(clone)
      }
    },
    [fileFactory, onChange, value]
  )

  const inputPlaceholder = useMemo(() => {
    const file = value.getFirst()
    if (file) {
      return file.filename
    }
    return placeholder
  }, [placeholder, value])

  return (
    <div className='mb-3'>
      <label role='button' htmlFor={id} className='form-label'>
        {label}
      </label>
      <label role='button' htmlFor={id} className='form-control form-control-solid'>
        {inputPlaceholder}
      </label>
      <input
        accept={accept}
        multiple={isMultiple}
        onChange={handleFileInputChange}
        className='d-none'
        type='file'
        id={id}
      />
    </div>
  )
}

export const extractFiles = (fileList: FileList) => {
  const files = Object.values(fileList)
  return files
}
