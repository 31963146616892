import {TicketListModel, TicketPortalModel} from '../../../../../models/ems/TicketModel'

export const ticketIdExtractor = (ticket: TicketListModel | TicketPortalModel) => {
  return ticket.code
}

export const ticketIsCancellable = (ticket: TicketListModel) => {
  return !['cancelled', 'converted'].includes(ticket.status)
}

export const ticketIsQrCodeDownloadable = (ticket: TicketListModel) => {
  return ['active'].includes(ticket.status)
}

export const ticketIsReassignable = (ticket: TicketListModel) => {
  return ticket.status && ['active'].includes(ticket.status) && ticket.isSeated
}

export const ticketIsPrintable = (ticket: TicketListModel) => {
  return ticket.status === 'confirmed'
}

export const ticketIsReprintable = (ticket: TicketListModel) => {
  return ['downloaded', 'emailed'].includes(ticket.displayStatus)
}

export const ticketIsReplacable = (ticket: TicketListModel) => {
  return ticket.status === 'active'
}

export const ticketIsActivatable = (ticket: TicketListModel) => {
  return ticket.status === 'printed'
}

export const eTicketIsPrintable = (ticket: TicketListModel) => {
  if (ticket.productType === 'e-ticket') {
    return ticketIsPrintable(ticket)
  }
  return false
}

export const cardIsPrintable = (ticket: TicketListModel) => {
  if (ticket.productType === 'card') {
    return ticketIsPrintable(ticket)
  }
  return false
}

export const eTicketIsReprintable = (ticket: TicketListModel) => {
  if (ticket.productType === 'e-ticket') {
    return ticketIsReprintable(ticket)
  }
  return false
}

export const eTicketIsReplacable = (ticket: TicketListModel) => {
  if (ticket.productType === 'e-ticket') {
    return ticketIsReplacable(ticket)
  }
  return false
}

export const ticketIsActiveOrSent = (ticket: TicketListModel) => {
  if (ticket.displayStatus === 'active' || ticket.displayStatus === 'sent') {
    return true
  }
  return false
}

export const cardIsReprintable = (ticket: TicketListModel) => {
  if (ticket.productType === 'card') {
    return ticketIsReprintable(ticket)
  }
  return false
}

export const ticketIsConvertable = (ticket: TicketListModel) => {
  return (
    ['active'].includes(ticket.status) && ticket.productIsConvertable && ticket.type === 'e-ticket'
  )
}

export const isTicketDownloadable = (ticket: TicketListModel) => {
  return ticket.status === 'active' && ticket.productIsDownloadable
}
