import clsx from 'clsx'
import {useCallback, useMemo} from 'react'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import styles from './PresentationSlide.module.scss'

export interface PresentationSlideProps {
  hideNext?: boolean
  hidePrev?: boolean
  onNextClick?: () => void
  onPreviousClick?: () => void
  onImageClick?: () => void
  onExit?: () => void
  imageSrc?: string
  className?: string
  hideControls?: boolean
}

export const PresentationSlide = ({
  hideNext,
  hidePrev,
  hideControls,
  onImageClick,
  onNextClick,
  onPreviousClick,
  onExit,
  imageSrc,
  className,
}: PresentationSlideProps) => {
  const handleNextClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      onNextClick?.()
    },
    [onNextClick]
  )

  const handlePreviousClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      onPreviousClick?.()
    },
    [onPreviousClick]
  )

  const handleExit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      onExit?.()
    },
    [onExit]
  )

  const isPreviousButtonHidden = useMemo(() => hidePrev || hideControls, [hideControls, hidePrev])
  const isNextButtonHidden = useMemo(() => hideNext || hideControls, [hideControls, hideNext])
  const isExitButtonHidden = useMemo(() => hideControls || !onExit, [hideControls, onExit])

  return (
    <div
      className={clsx(styles.root, className)}
      style={{
        backgroundImage: imageSrc && `url(${imageSrc})`,
      }}
      onClick={onImageClick}
    >
      <button
        disabled={isPreviousButtonHidden}
        onClick={handlePreviousClick}
        className={clsx('btn btn-text rounded-0', {
          'opacity-0': isPreviousButtonHidden,
        })}
      >
        <MetronicIcon size='xl' iconType='Navigation' iconName='Angle-left' />
      </button>
      <div>
        <MetronicIconButton
          variant='text'
          iconType='Navigation'
          iconName='Close'
          size='lg'
          tooltip='Exit Presentation'
          onClick={handleExit}
          disabled={isExitButtonHidden}
          className={clsx({
            'opacity-0': isExitButtonHidden,
          })}
        />
      </div>
      <button
        disabled={isNextButtonHidden}
        onClick={handleNextClick}
        className={clsx('btn btn-text rounded-0', {
          'opacity-0': isNextButtonHidden,
        })}
      >
        <MetronicIcon size='xl' iconType='Navigation' iconName='Angle-right' />
      </button>
    </div>
  )
}
