import {useMemo} from 'react'
import {Helmet} from 'react-helmet'
import {Redirect, Route, Switch} from 'react-router-dom'
import {
  DigitalWaiverAuthBackground,
  DigitalWaiverAuthBackgroundMobile,
} from '../../../../config/logos'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import FirstLoginPasswordResetForm from '../components/FirstLoginPasswordReset'
import {ForgotPassword} from '../components/forms/ForgotPassword'
import {Login} from '../components/Login'
import {MyPassLogin} from '../components/MyPassLogin'
import {useWaiverAuth} from '../hooks/useWaiverAuth'
export const AuthDigitalWaiverPage = () => {
  const {down} = useBreakpoint()
  const auth = useWaiverAuth()
  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  const image = useMemo(() => {
    if (isMobile) {
      return DigitalWaiverAuthBackgroundMobile.src
    }
    return DigitalWaiverAuthBackground.src
  }, [isMobile])

  // const {
  //   state: isPopupOpen,
  //   enableState: openPopup,

  //   disableState: closePopup,
  // } = useBooleanState(false)

  // const loginTypeLink = useMemo(() => {
  //   if (MY_PASS_LINK && QUICK_WAIVER_LINK) {
  //     const myPassUrl = new URL(MY_PASS_LINK)
  //     const isMyPass = window.location.hostname === myPassUrl.hostname
  //     const url = isMyPass ? QUICK_WAIVER_LINK : MY_PASS_LINK

  //     return (
  //       <div className='position-absolute top-0 end-0 me-5 mt-5'>
  //         <ThreeDots isOpen={isPopupOpen} onClose={closePopup} onOpen={openPopup}>
  //           <div className='d-flex gap-2'>
  //             <MetronicIcon iconName='Location-arrow' iconType='Map' color='primary' />
  //             <a className='text-white' href={url}>{`Go to ${
  //               isMyPass ? 'Quick Waiver' : 'Digital waiver'
  //             }`}</a>
  //           </div>
  //         </ThreeDots>
  //       </div>
  //     )
  //   }
  // }, [closePopup, isPopupOpen, openPopup])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed position-relative'
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
      }}
    >
      <Helmet>
        <title>Digital Waiver</title>
        <link rel='icon' href='/waiver.ico' />
      </Helmet>
      <div className='d-flex flex-center flex-column flex-column-fluid p-0 min-h-100vh'>
        <div
          className='w-sm-500px w-300px rounded shadow-sm p-10 p-lg-15 mx-auto text-center'
          style={{
            backgroundColor: 'rgba(0,0,0 , 0.8)',
          }}
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/saas/yas-marina.png')}
            className='img-fluid w-250px w-100 mb-8'
          />
          <div className='rounded shadow-sm mx-auto'>
            {auth?.isFirstLogin() && <Redirect to='/auth/password-reset' />}
            {auth && !auth.isFirstLogin() && <Redirect to='/' />}
            <Switch>
              <Route path='/auth/login'>
                {process.env.REACT_APP_WEBNTECH_LOGIN &&
                process.env.REACT_APP_WEBNTECH_LOGIN === 'TRUE' ? (
                  <Login />
                ) : (
                  <MyPassLogin />
                )}
              </Route>
              <Route path='/auth/login/:hash'>
                <Login />
              </Route>

              <Route path='/auth/password-reset' component={FirstLoginPasswordResetForm} />
              <Route path='/auth/forgot-password'>
                <ForgotPassword />
              </Route>
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}
