import {useOnMount} from '../../../components/hooks/useOnMount'
import {OrderList} from '../components/OrderList'
import {useOrders} from '../hooks/useOrders'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {ConfirmPageLayout} from '../layout/ConfirmPageLayout'

export const OrdersPage = () => {
  const {goToMainMenu, goToOrderByCode} = usePageNavigation()
  const {searchOrders, orders, mobileNumber, setMobileNumber} = useOrders()

  useOnMount(() => {
    if (mobileNumber) {
      searchOrders()
    }
  })

  return (
    <ConfirmPageLayout headerLabel='Track Order' onBackClick={goToMainMenu}>
      <OrderList
        className='mt-8'
        onOrderClick={goToOrderByCode}
        orders={orders}
        mobileNumber={mobileNumber}
        onMobileNumberSearch={searchOrders}
        onMobileNumberChange={setMobileNumber}
      />
    </ConfirmPageLayout>
  )
}
