import clsx from 'clsx'
import {Paper} from '../../../../components/utils/Paper'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {BookingLink} from '../BookingLink'

export interface BookingCardProps {
  data: BookingModel
  className?: string
}

export const BookingCard = ({data, className}: BookingCardProps) => {
  return (
    <Paper className={clsx('p-5', className)} rounded>
      <div className='row'>
        <div className='col-12'>
          <div>
            <span className='fw-bolder'>Code: </span>
            <span className='fs-6'>
              <BookingLink booking={data}>{data.reservationNo || data.code}</BookingLink>
            </span>
          </div>
        </div>
        <div className='col-12'>
          <div>
            <span className='fw-bolder'>Event: </span>
            <span className='fs-6'>{data.event?.name}</span>
          </div>
        </div>
      </div>
    </Paper>
  )
}
