import {ReactNode, useCallback, useMemo} from 'react'
import {ButtonVariant} from '../../../../../components/inputs/Button'
import {NumberInput} from '../../../../../components/inputs/NumberInput/NumberInput'
import {Cart} from '../../../../../utils/Cart'

export interface OrderInputProps {
  value: Cart
  onChange: (value: Cart) => void
  disabled?: boolean
  variant?: ButtonVariant
}

export const OrderInput = ({onChange, value, disabled, variant}: OrderInputProps) => {
  const getChangeHandler = useCallback(
    (key: string) => (count: number) => {
      if (count > 0) {
        value.setCount(key, count)
        onChange(value.clone())
      } else {
        value.removeItem(key)
        onChange(value.clone())
      }
    },
    [onChange, value]
  )

  const inputFields = useMemo(() => {
    const fields: ReactNode[] = []
    value.forEach((key, value) => {
      fields.push(
        <NumberInput
          key={key}
          disabled={disabled}
          label={
            <span className='d-inline-flex form-label justify-content-between w-100'>
              <span>{value.label}</span>
              <span>
                {value.price.getCurrency()} {value.price.getVatInclusivePriceRoundedOff()}
              </span>
            </span>
          }
          onChange={getChangeHandler(key)}
          value={value.count}
          variant={variant}
        />
      )
    })
    return fields
  }, [value, disabled, getChangeHandler, variant])

  return <div>{inputFields}</div>
}
