import {useCallback, useState} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {OutletModel} from '../../../models/fnb/OutletModel'
import {GetCurrentOutletMenu} from '../redux/OutletCRUD'

export const useCurrentOutletMenu = () => {
  const [menu, setMenu] = useState<OutletModel>()
  const {push} = useAlerts()

  const resetMenuData = useCallback(async () => {
    try {
      const {data} = await GetCurrentOutletMenu()
      setMenu(data)
    } catch (e) {
      push({message: 'Error fetching menu.', timeout: 5000, variant: 'danger'})
    }
  }, [push])

  useOnMount(() => {
    resetMenuData()
  })

  return menu
}
