import {ReactNode, useCallback, useMemo} from 'react'
import {MultiSelectInput} from '../../inputs/MultiSelectInput/MultiSelectInput'
import {SelectInputItem} from '../../inputs/SelectInput'
import {useTableFilterState} from '../useTableFilterState'

export interface MultiSelectToggleFilterInputProps {
  label: string
  items: SelectInputItem[]
  field: string
  endAdornment?: ReactNode
  className?: string
  isLabelUpperCase?: boolean
  inputClassName?: string
}

export const MultiSelectToggleFilterInput = ({
  items,
  label,
  field,
  endAdornment,
  className,
  isLabelUpperCase,
  inputClassName,
}: MultiSelectToggleFilterInputProps) => {
  const {setAdvancedFilterValue, getAdvancedFilterValue, clearAdvancedFilterValue} =
    useTableFilterState()

  const value = useMemo(() => {
    const value = getAdvancedFilterValue(field)
    if (Array.isArray(value)) {
      return value
    }
    return []
  }, [field, getAdvancedFilterValue])

  const handleChange = useCallback(
    (values: string[]) => {
      if (values.length) {
        setAdvancedFilterValue(field, values)
      } else {
        clearAdvancedFilterValue(field)
      }
    },
    [clearAdvancedFilterValue, field, setAdvancedFilterValue]
  )

  return (
    <MultiSelectInput
      inputClassName={inputClassName}
      endAdornment={endAdornment}
      hasSelectAll
      allowClear
      placeholder={`Filter ${label}`}
      items={items}
      label={label}
      value={value}
      onChange={handleChange}
      className={className}
      isLabelUpperCase={isLabelUpperCase}
    />
  )
}
