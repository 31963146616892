import {useMemo, useCallback, useState, useEffect, ReactNode} from 'react'
import clsx from 'clsx'
import {SelectInputItem} from '../SelectInput'
import {SearchableInputList, SearchableInputListClasses} from './SearchableInputList'
import {MetronicIcon, MetronicIconPath} from '../MetronicIcon'
import {MetronicIconButton} from '../MetronicIconButton'

export interface SearchableSelectInputClasses<T> extends SearchableInputListClasses<T> {
  input?: string
}

export interface SearchableSelectInputProps<T> {
  className?: string
  items: T[]
  value: T | null
  onChange: (value: T | null) => void
  onSearch?: (value: string) => void
  placeholder?: string
  onBlur?: () => void
  label?: string
  id: string
  disabled?: boolean
  onScrollBottom?: () => void
  allowClear?: boolean
  classes?: SearchableSelectInputClasses<T>
  filter?: (value: T) => boolean
  noMargin?: boolean
  itemMapper: (item: T) => SelectInputItem
  renderItem?: (item: T) => ReactNode
  displayedValue?: ReactNode
  icon?: MetronicIconPath
}

export const SearchableSelectInput = <T,>({
  className,
  items,
  placeholder,
  onChange,
  value,
  onBlur,
  id,
  label,
  disabled,
  onSearch,
  onScrollBottom,
  allowClear,
  classes,
  filter,
  noMargin,
  itemMapper,
  renderItem,
  displayedValue,
  icon,
}: SearchableSelectInputProps<T>) => {
  const [searchInput, setSearchInput] = useState<HTMLInputElement | null>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false)
    onBlur && onBlur()
  }, [onBlur])

  const handleOnListItemClick = useCallback(
    (item: T) => {
      onChange(item)
      handleMenuClose()
    },
    [handleMenuClose, onChange]
  )

  const handleOnFocus = useCallback(() => {
    if (!disabled) {
      setIsMenuOpen(true)
    }
  }, [disabled])

  const handleClear = useCallback(() => {
    onChange(null)
  }, [onChange])

  const itemList = useMemo(() => {
    if (isMenuOpen) {
      return (
        <SearchableInputList
          classes={classes}
          searchInputRef={setSearchInput}
          onItemClick={handleOnListItemClick}
          items={items}
          filter={filter}
          searchInputPlaceholder='Search...'
          onSearchInputFocus={handleOnFocus}
          onSearchInputBlur={handleMenuClose}
          searchInputId={id}
          onSearch={onSearch}
          onScrollBottom={onScrollBottom}
          itemMapper={itemMapper}
          renderItem={renderItem}
        />
      )
    }
    return null
  }, [
    isMenuOpen,
    classes,
    handleOnListItemClick,
    items,
    filter,
    handleOnFocus,
    handleMenuClose,
    id,
    onSearch,
    onScrollBottom,
    itemMapper,
    renderItem,
  ])

  const valueLabel = useMemo(() => {
    if (value) {
      const {label} = itemMapper(value)
      return label
    }
  }, [itemMapper, value])

  useEffect(() => {
    if (isMenuOpen) {
      searchInput?.focus()
    }
  }, [isMenuOpen, searchInput])

  return (
    <div className={clsx('searchable-select-input-container', {'mb-3': !noMargin}, className)}>
      {label && (
        <label onClick={handleOnFocus} className='form-label' htmlFor={id}>
          {label}
        </label>
      )}
      <div
        onClick={handleOnFocus}
        role='button'
        className={clsx('searchable-select-input', classes?.input)}
      >
        <label
          className='searchable-select-input__input-container me-5'
          onClick={handleOnFocus}
          htmlFor={id}
        >
          {displayedValue || valueLabel || placeholder}
        </label>
        <div className='drop-down-arrow'>
          {allowClear && !disabled && value && (
            <MetronicIconButton
              type='button'
              onClick={handleClear}
              iconType='Navigation'
              iconName='Close'
              variant='text'
              size='sm'
            />
          )}

          {icon ? (
            <MetronicIcon
              color={icon?.color ? icon.color : 'primary'}
              size='sm'
              iconType={icon?.iconType}
              iconName={icon?.iconName}
            />
          ) : (
            <MetronicIcon size='sm' iconType='Navigation' iconName='Angle-down' />
          )}
        </div>
      </div>
      {itemList}
    </div>
  )
}
