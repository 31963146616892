import {ManageRowAction} from '../../../../../components/tables/actions/ManageRowAction'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {CustomerModel} from '../../../../../models/CustomerModel'

export interface CustomerTableRowActionsProps {
  data: CustomerModel
  onDelete?: (data: CustomerModel) => void
  onEdit?: (data: CustomerModel) => void
  onResetMfa?: (data: CustomerModel) => void
  onActivate?: (data: CustomerModel) => void
}

export const CustomerTableRowActions = ({
  data,
  onDelete,
  onEdit,
  onResetMfa,
  onActivate,
}: CustomerTableRowActionsProps) => {
  return (
    <>
      <RowAction
        data={data}
        iconType='General'
        iconName='Trash'
        variant='danger'
        tooltip='Delete'
        onClick={onDelete}
      />
      {data.status === 'active' && data.type === 'reseller' && (
        <>
          <RowAction
            data={data}
            iconType='General'
            iconName='Unlock'
            variant='warning'
            tooltip='Reset Customer MFA'
            onClick={onResetMfa}
          />
        </>
      )}
      {data.status === 'pending' && data.type === 'reseller' && (
        <>
          <RowAction
            data={data}
            iconType='Communication'
            iconName='Sending mail'
            variant='success'
            tooltip='Send Credentials'
            onClick={onActivate}
          />
        </>
      )}
      <ManageRowAction data={data} onEdit={onEdit} />
    </>
  )
}
