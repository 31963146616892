import {useFormik} from 'formik'
import {useCallback, useMemo} from 'react'
import {TextInput} from '../../../../components/inputs'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {SweetAlert} from '../../../../components/modals/SweetAlert'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import * as Yup from 'yup'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {useIsGSSSPP} from '../../hooks/useIsGSSSPP'
import {RouteParams} from '../../pages/PortalPage'
import {useRouteMatch} from 'react-router-dom'
import {useDay} from '../../hooks/useDay'
interface EmailModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  data: TicketPortalModel[]
  onSubmit: (values: InitialValuesProps) => void
}

export interface InitialValuesProps {
  email: string
  name: string
}

export const EmailModal = ({open, setOpen, data, onSubmit}: EmailModalProps) => {
  const {pushError} = useAlerts()
  const {
    params: {productCategoryCode},
  } = useRouteMatch<RouteParams>()

  const {getDayThreeLetters} = useDay()

  const initialValues = useMemo<InitialValuesProps>(() => {
    return {
      email: '',
      name: '',
    }
  }, [])

  const {isGCCSPP} = useIsGSSSPP({productCategoryCode})

  const formik = useFormik({
    initialValues,
    validationSchema: EmailSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await onSubmit(values)
      } catch (err) {
        pushError(err)
      } finally {
        setSubmitting(false)
        setOpen(false)
      }
    },
  })

  const handleClose = useCallback(() => {
    setOpen(false)
    formik.resetForm()
  }, [formik, setOpen])

  const day = useMemo(() => {
    return getDayThreeLetters(data[0].startedAt, data[0].endedAt)
  }, [data, getDayThreeLetters])

  const title = useMemo(() => {
    if (data.length > 1) return 'EMAIL SELECTED TICKETS'
    return `EMAIL TICKET: ${
      !isGCCSPP && day === 'Thu' ? 'Pitlane Walk' : data[0].productName ? data[0].productName : ''
    }`
  }, [data, day, isGCCSPP])

  return (
    <SweetAlert
      containerClassName='mw-300px'
      customClass={{
        popup: 'bg-primary-cp-dark',
      }}
      open={open}
      onClose={handleClose}
      showConfirmButton={false}
    >
      <div className='d-flex'>
        <div className='flex-grow-1 text-start d-flex flex-column justify-content-center'>
          <p className='text-white mb-0 fw-bolder'>{title}</p>
        </div>
        <div>
          <MetronicIconButton
            type='button'
            className='btn'
            iconType='Navigation'
            iconName='Close'
            variant='text'
            size='md'
            color='white'
            onClick={handleClose}
          />
        </div>
      </div>
      <HorizontalDivider className='bg-dark' />
      <form className='form mt-5' onSubmit={formik.handleSubmit}>
        <TextInput
          type='name'
          placeholder='Enter Name'
          inputClassName='rounded-0'
          {...formik.getFieldProps('name')}
          errorMessage={formik.errors.name}
          isTouched={formik.touched.name}
        />
        <TextInput
          type='email'
          placeholder='Enter Email Address'
          inputClassName='rounded-0 mt-5 '
          {...formik.getFieldProps('email')}
          errorMessage={formik.errors.email}
          isTouched={formik.touched.email}
        />

        <button
          disabled={formik.isSubmitting}
          className='btn bg-body d-flex gap-2 m-auto mt-5 rounded-0 text-white w-50 justify-content-center'
          onClick={formik.submitForm}
        >
          Send Email
          {formik.isSubmitting && (
            <span className='indicator-progress ml-2' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </form>
    </SweetAlert>
  )
}

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})
