import {useMemo} from 'react'
import {PoralEventProductWidgetModel} from '../../../../models/customer-portal/PortalWidgetModal'
import {DashboardCard} from './DashboardCard'

export interface DashboardCardsProps {
  productsVoucers: PoralEventProductWidgetModel
}

export const DashboardCards = ({productsVoucers}: DashboardCardsProps) => {
  const card = useMemo(() => {
    return (
      <>
            {productsVoucers.products.map((product, idx) => {
              return <DashboardCard key={idx} product={product} />
            })}
      </>
    )
  }, [productsVoucers])
  return <>{card}</>
}
