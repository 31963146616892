import {useCallback} from 'react'
import {ColorVariant} from '../../inputs/Button'
import {MetronicIconButton, MetronicIconButtonProps} from '../../inputs/MetronicIconButton'

export interface RowActionProps<T> extends Omit<MetronicIconButtonProps, 'onClick'> {
  onClick?: (data: T) => void
  data: T
  variant: ColorVariant
}

export const RowAction = <T,>({onClick, data, variant, ...buttonProps}: RowActionProps<T>) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(data)
    }
  }, [data, onClick])

  if (onClick) {
    return (
      <MetronicIconButton
        onClick={handleOnClick}
        {...buttonProps}
        variant='default'
        size='sm'
        activeColor={variant}
      />
    )
  }
  return null
}
