import clsx from 'clsx'

export interface AvatarInitialProps {
  children: string
  className?: string
}

export const AvatarInitials = ({children, className}: AvatarInitialProps) => {
  return (
    <div
      className={clsx(
        'd-inline-flex align-items-center justify-content-center symbol-label fw-bold bg-opacity-10 bg-info text-inverse-info',
        className
      )}
    >
      {children}
    </div>
  )
}
