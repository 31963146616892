import {useCallback, useMemo} from 'react'
import {Button, ButtonProps} from '../../inputs/Button'
import {TableRowId} from '../TableRow'

export interface TableSelectionActionProps<T> extends Omit<ButtonProps, 'onClick'> {
  idExtractor: (data: T) => TableRowId
  filter?: (data: T, index: number, array: T[]) => boolean
  items: T[]
  selected: TableRowId[]
  onClick?: (data: T[]) => void
  limitCount?: number
}

export const TableSelectionAction = <T,>({
  idExtractor,
  items,
  filter,
  selected,
  children,
  onClick,
  limitCount,
  disabled,
  ...buttonProps
}: TableSelectionActionProps<T>) => {
  const filteredItems = useMemo(() => {
    return items.filter((item, i, array) => {
      const id = idExtractor(item)
      const isSelected = selected.includes(id)
      if (isSelected) {
        if (filter) {
          return filter(item, i, array)
        }
      }
      return isSelected
    })
  }, [filter, idExtractor, items, selected])

  const handleClick = useCallback(() => {
    onClick && onClick(filteredItems)
  }, [onClick, filteredItems])

  const reachedLimit = useMemo(() => {
    if (limitCount && filteredItems.length > limitCount) {
      return true
    }
    return false
  }, [filteredItems.length, limitCount])

  if (onClick && filteredItems.length && !reachedLimit) {
    return (
      <Button onClick={handleClick} disabled={disabled} {...buttonProps}>
        {children} ({filteredItems.length})
      </Button>
    )
  }

  return null
}
