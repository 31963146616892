import {ActivityTimeline} from '../../../components/timeline/Timeline'
import {ActivityTimelineItemAttributes} from '../../../components/timeline/TimelineItem'
import {OrderModelStatus} from '../../../models/fnb/OrderModel'

export interface OrderStatusTimelineProps {
  stage: OrderStatusTimelineStage
}

export enum OrderStatusTimelineStage {
  PENDING = 0,
  ACCEPTED = 1,
  PREPARING = 2,
  ON_THE_WAY = 3,
  DELIVERED = 4,
}

export const getOrderStatusStage = (status: OrderModelStatus) => {
  switch (status) {
    case 'pending':
      return OrderStatusTimelineStage.PENDING
    case 'confirmed':
      return OrderStatusTimelineStage.ACCEPTED
    case 'delivered':
      return OrderStatusTimelineStage.DELIVERED
    case 'dispatched':
      return OrderStatusTimelineStage.ON_THE_WAY
    case 'preparing':
      return OrderStatusTimelineStage.PREPARING
    default:
      return OrderStatusTimelineStage.PENDING
  }
}

export const OrderStatusTimeline = ({stage}: OrderStatusTimelineProps) => {
  return <ActivityTimeline items={ORDER_STATUS} stage={stage} variant='success' />
}

const ORDER_STATUS: ActivityTimelineItemAttributes[] = [
  {
    label: 'Accepted',
    icon: {
      type: 'Communication',
      name: 'Clipboard-list',
    },
  },
  {
    label: 'Preparing',
    icon: {
      type: 'Cooking',
      name: 'Cooking-pot',
    },
  },
  {
    label: 'On the way',
    icon: {
      type: 'Navigation',
      name: 'Route',
    },
  },
  {
    label: 'Delivered',
    icon: {
      type: 'Navigation',
      name: 'Check',
    },
  },
]
