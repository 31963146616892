import {useCallback} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {CustomerModel} from '../../../../models/CustomerModel'
import {CustomerImage} from '../CustomerImage/CustomerImage'

export interface CustomerListItemProps {
  customer: CustomerModel
  onClick?: (item: CustomerModel) => void
}

export const CustomerListItem = ({customer, onClick}: CustomerListItemProps) => {
  const handleClick = useCallback(() => {
    onClick?.(customer)
  }, [customer, onClick])

  return (
    <div
      role='button'
      className='d-flex justify-content-between align-items-center mb-3'
      onClick={handleClick}
    >
      <div className='d-flex align-items-center'>
        <CustomerImage
          height='4rem'
          width='4rem'
          customer={customer}
          className='align-self-start me-3'
        />
        <div className='ms-1'>
          <h3>{customer.name}</h3>
          <Badge variant='dark' className='text-uppercase'>
            {customer.customerCategory?.name}
          </Badge>
        </div>
      </div>
      <MetronicIcon iconType='Navigation' iconName='Angle-right' size='lg' />
    </div>
  )
}
