import {PollModel} from '../../../models/eva/PollModel'
import axios from 'axios'
import {CustomerModel, CustomerServicesInnerModel} from '../../../models/CustomerModel'
import {AgendaModel} from '../../../models/eva/AgendaModel'
import {TopicModel} from '../../../models/eva/TopicModel'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {CustomerChatUserModel} from '../../../models/eva/CustomerChatUserModel'
import {ChatModel} from '../../../models/eva/ChatModel'
import {ChatHistoryFilterModel} from '../../../models/eva/ChatHistoryFilterModel'
import {PollRatingModel, PollSubmitParams} from '../../../models/eva/PollRatingModel'

const CRUD_CUSTOMER_LIST = '/eva/customer/list'
const CRUD_CUSTOMER_TICKET = '/eva/customer/ticket'

export interface TicketProps {
  code: string
}

export interface PollsPayload {
  code: string
  ticketCode: string
  body?: PollSubmitParams
}

export const GetAgendaDateRange = (eventCode: string) =>
  axios.post<string[]>('/eva/agenda/date-range', {
    eventCode,
  })
export const GetAgendaByDay = (eventCode: string, date: string) =>
  axios.post<AgendaModel[]>('/eva/agenda/day', {
    eventCode,
    date,
  })
export const GetAgendaByCode = (agendaCode: string) =>
  axios.get<AgendaModel>(`/eva/agenda/${agendaCode}`)
export const GetTopicByCode = (topicCode: string) =>
  axios.get<TopicModel>(`/eva/topic/${topicCode}`)

export const GetCustomerList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerModel>>(`/eva/customer/list`, filter)
export const GetCustomerByCode = (customerCode: string) =>
  axios.get<CustomerModel>(`/eva/customer/${customerCode}`)
export const GetCustomerByTicketCode = (ticketCode: string) =>
  axios.post<CustomerChatUserModel>(`${CRUD_CUSTOMER_TICKET}`, {
    code: ticketCode,
  })

export const SearchChatUsers = async (filters?: FilterModel) =>
  await axios.post<CustomerChatUserModel[]>(`${CRUD_CUSTOMER_LIST}`, filters)

export const GetChatUsers = async (currentUserCode: string) =>
  await axios.get<CustomerChatUserModel[]>(`/eva/chat/${currentUserCode}`)

export const GetPolls = async ({code, ticketCode}: PollsPayload) =>
  await axios.post<PollModel[]>(
    `/eva/poll/time`,
    {eventCode: code},
    {
      headers: {
        'x-ticket': ticketCode,
      },
    }
  )

export const getPollsByCode = async ({code, ticketCode}: PollsPayload) =>
  await axios.get<PollRatingModel>(`/eva/poll/${code}`, {
    headers: {
      'x-ticket': ticketCode,
    },
  })

export const submitPollsAnswers = async ({code, ticketCode, body}: PollsPayload) =>
  await axios.post<PollRatingModel>(`/eva/poll/${code}`, body, {
    headers: {
      'x-ticket': ticketCode,
    },
  })

export const SendPushSubscription = async (customerCode: string, subscription: PushSubscription) =>
  axios.post(`/eva/notification/subscribe`, {
    customerCode,
    endpoint: subscription.endpoint,
    ...subscription.toJSON().keys,
  })
export const GetPublicVapidKey = () =>
  axios.get<{vapidPublicKey: string}>('/eva/notification/public-key')

export const MarkUserChatsAsRead = (currentUserCode: string, chatUserCode: string) =>
  axios.post('/eva/chat/read', {
    currentUserCode,
    chatUserCode,
  })

export const GetChatHistory = (filter: ChatHistoryFilterModel) =>
  axios.post<GlobalSearchModel<ChatModel>>('/eva/chat/history', filter)

export const GetTopicCurrentSlide = (topicCode: string) =>
  axios.get<{topic?: {currentPresentationSlide?: number}}>(`/eva/topic/current-slide/${topicCode}`)
export const SetTopicCurrentSlide = (topicCode: string, slideNumber: number, ticketCode: string) =>
  axios.post(
    `/eva/topic/set-current-slide/${topicCode}`,
    {
      currentPresentationSlide: slideNumber,
    },
    {
      headers: {
        'x-ticket': ticketCode,
      },
    }
  )
export const SetTopicPresentationState = (
  topicCode: string,
  isPlaying: boolean,
  ticketCode: string
) =>
  axios.post(
    `/eva/topic/present/${topicCode}`,
    {
      isPresentationStarted: isPlaying,
    },
    {
      headers: {
        'x-ticket': ticketCode,
      },
    }
  )

export const GetCustomerServices = (ticketCode: string) =>
  axios.get('/eva/customer-service', {
    headers: {
      'x-ticket': ticketCode,
    },
  })

export const GetCustomerServicesByCode = (code: string, ticketCode: string) =>
  axios.get<CustomerServicesInnerModel>(`/eva/customer-service/${code}`, {
    headers: {
      'x-ticket': ticketCode,
    },
  })
