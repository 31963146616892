import {useCallback, useMemo} from 'react'
import {BarChart} from '../../../../../../components/charts/BarChart/BarChart'
import {GateScannerCountWidgetModel} from '../../../../../../models/ems/WidgetModel'

export interface GateDailyBarChartProps {
  data?: GateScannerCountWidgetModel[]
}

export const GateDailyBarChart = ({data}: GateDailyBarChartProps) => {
  const renderTooltip = useCallback(
    (key: string) => {
      const datum = data?.find((item) => getName(item) === key)
      if (datum) {
        return (
          <div className='bg-light-primary border border-primary p-3 rounded text-white'>
            <div>
              <span className='fw-bolder'>Name: </span>
              {datum?.name}
            </div>
            <div>
              <span className='fw-bolder'>Count: </span>
              {datum?.total}
            </div>
          </div>
        )
      }
    },
    [data]
  )

  const chartDatum = useMemo(() => data || [], [data])

  return (
    <div className='h-100 w-100'>
      <div className='d-flex h-100 flex-column'>
        <div className='fs-1 mb-5 fw-bolder text-white text-uppercase text-center'>Gates</div>
        <div className='flex-grow-1 ps-10 mb-20'>
          <BarChart
            data={chartDatum}
            xAccessor={getName}
            yAccessor={getCount}
            renderTooltip={renderTooltip}
          />
        </div>
      </div>
    </div>
  )
}

const getName = (datum: GateScannerCountWidgetModel) => datum.name

const getCount = (datum: GateScannerCountWidgetModel) => {
  return Number(datum.total)
}
