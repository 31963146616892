import * as yup from 'yup'
import {useFormik} from 'formik'
import {useLoadingState} from '../../hooks/useLoadingState'
import {Button} from '../../inputs/Button'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {OrDivider} from '../../utils/OrDivider'
import {PinInput} from '../../inputs/PinInput'
import {useCallback, useMemo} from 'react'
import {TextInput} from '../../inputs'
import {PasswordInput} from '../../inputs/PasswordInput'
import clsx from 'clsx'
import {UserListInput} from '../../inputs/UserListInput/UserListInput'
import {UserModel} from '../../../models/UserModel'
import {UserListInputItemValue} from '../../inputs/UserListInput/UserListInputItem'
import {Spinner} from '../../utils/Spinner'
import {useAlerts} from '../../alerts/useAlerts'

const loginSchema = yup.object().shape({
  username: yup
    .string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required(),
  password: yup.string().test('valid-password', 'Invalid Password', (value, context) => {
    const parent = context.parent as OutletLoginFormValues
    if (value) {
      if (parent.usePin) {
        return value.length === 4
      }
      return value.length >= 8
    }
    return false
  }),
})

export interface OutletLoginFormValues {
  username: string
  password: string
  usePin: boolean
}

export interface OutletLoginFormClasses {
  userNameListFormClass?: string
  pinFormClass?: string
  usernameFormClass?: string
}
export interface OutletLoginFormProps {
  onSubmit: (values: OutletLoginFormValues) => Promise<void> | void
  users: UserModel[] | null
  className?: string
  classes?: OutletLoginFormClasses
}

export const OutletLoginForm = ({onSubmit, users, classes, className}: OutletLoginFormProps) => {
  const {setIsLoading, isLoading} = useLoadingState()
  const {push} = useAlerts()
  const formik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus}) => {
      const doneLoading = setIsLoading('form')
      try {
        await onSubmit(values)
      } catch (e) {
        if (values.usePin) {
          push({message: 'Invalid PIN. Please try again.', variant: 'danger', timeout: 5000})
        } else {
          setStatus('Invalid username or password.')
        }
      }
      doneLoading()
    },
  })

  const handlePinChange = useCallback(
    (pin: string) => {
      if (pin.length <= 4) {
        formik.setFieldValue('password', pin)
      }
    },
    [formik]
  )

  const setFormType = useCallback(
    (type: 'pin' | 'username') => {
      formik.resetForm()
      formik.setFieldValue('usePin', type === 'pin')
    },
    [formik]
  )

  const showPin = useCallback(() => {
    setFormType('pin')
  }, [setFormType])

  const showUsername = useCallback(() => {
    setFormType('username')
  }, [setFormType])

  const userSelectHandler = useCallback(
    (user: UserListInputItemValue) => {
      formik.setFieldValue('username', user.value)
    },
    [formik]
  )

  const resetUser = useCallback(() => {
    formik.setFieldValue('username', '')
  }, [formik])

  const userListItems = useMemo(() => {
    return (
      users?.map<UserListInputItemValue>((user) => ({
        initials: user.avatarCode,
        value: user.email,
        name: user.name,
      })) || null
    )
  }, [users])

  const formContents = useMemo(() => {
    if (formik.values.usePin) {
      if (!formik.values.username) {
        if (!userListItems) {
          return <Spinner size='lg' />
        }
        return (
          <UserListInput
            onBack={showUsername}
            items={userListItems || []}
            onClick={userSelectHandler}
          />
        )
      }
      return (
        <PinInput
          onChange={handlePinChange}
          value={formik.values.password}
          loading={formik.isSubmitting}
          onSubmit={formik.handleSubmit}
          disableSubmit={!formik.isValid}
          password
          title='Enter PIN number'
          header={
            <Button onClick={resetUser} className='mb-5' variant='text' size='flush' color='dark'>
              Back
            </Button>
          }
        />
      )
    }

    return (
      <>
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In</h1>
          <div className='text-gray-400 fw-bold fs-4'>Enter username and password to login</div>
        </div>
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <div className='fv-row mb-10'>
          <TextInput
            fullWidth
            label='Username'
            placeholder='Email or mobile'
            errorMessage={formik.errors.username}
            isTouched={formik.touched.username}
            {...formik.getFieldProps('username')}
          />
        </div>
        <div className='fv-row mb-10'>
          <PasswordInput
            fullWidth
            label='Password'
            placeholder='Enter Password'
            errorMessage={formik.errors.password}
            isTouched={formik.touched.password}
            forgotPasswordLink='/auth/forgot-password'
            {...formik.getFieldProps('password')}
          />
        </div>
        <div className='text-center'>
          <Button
            type='submit'
            disabled={isLoading || !formik.isValid}
            variant='primary'
            light={false}
            fullWidth
          >
            <LoadingSpinner loading={isLoading}>Continue</LoadingSpinner>
          </Button>
          <OrDivider className='mt-5 mb-5' />
          <Button variant='primary' light={false} fullWidth onClick={showPin}>
            PIN
          </Button>
        </div>
      </>
    )
  }, [
    formik,
    handlePinChange,
    isLoading,
    resetUser,
    showPin,
    showUsername,
    userListItems,
    userSelectHandler,
  ])

  const formClasses = useMemo(() => {
    const formClasses: Array<undefined | string> = []
    const {usePin, username} = formik.values
    if (classes) {
      if (usePin) {
        if (username) {
          formClasses.push(classes.pinFormClass)
        } else {
          formClasses.push(classes.userNameListFormClass)
        }
      } else {
        formClasses.push(classes.usernameFormClass)
      }
    }
    return formClasses
  }, [classes, formik.values])

  return (
    <form className={clsx(className, formClasses)} onSubmit={formik.handleSubmit} noValidate>
      {formContents}
    </form>
  )
}

const EMPTY_INITIAL_VALUES: OutletLoginFormValues = {
  username: '',
  password: '',
  usePin: false,
}
