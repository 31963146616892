import {useCallback, useState} from 'react'
import {PinInput} from '../../../../components/inputs/PinInput'
import {SweetAlert} from '../../../../components/modals/SweetAlert'

export interface BankAuthorizationCodeModalProps {
  onSubmit: (code: string) => void
  open: boolean
  onClose: () => void
}

export const BankAuthorizationCodeModal = ({
  onSubmit,
  open,
  onClose,
}: BankAuthorizationCodeModalProps) => {
  const [value, setValue] = useState('')

  const handleSubmit = useCallback(() => {
    onSubmit(value)
    setValue('')
  }, [onSubmit, value])

  return (
    <SweetAlert onClose={onClose} open={open} showConfirmButton={false} width='auto'>
      <PinInput
        onSubmit={handleSubmit}
        disableSubmit={!value}
        onChange={setValue}
        value={value}
        title='Auth Code'
      />
    </SweetAlert>
  )
}
