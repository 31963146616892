import {FormikContextType} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {CustomerModel} from '../../../models/CustomerModel'
import {FilterModel} from '../../../models/FilterModel'
import {TableRowId} from '../../tables/TableRow'
import {CustomerWizardTable} from '../BookingWizardTables/CustomerWizardTable'
import {useOnChange} from '../../hooks/useOnChange'
import {Button} from '../../inputs/Button'
import {
  BookingWizardCustomerStepFormValues,
  BookingFormValues,
} from '../../../models/booking-wizard/BookingWizard'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'

export interface BookingWizardSharedCustomerStepProps<
  T extends BookingWizardCustomerStepFormValues
> {
  formik: FormikContextType<T>
  disabledFields?: Partial<Record<keyof BookingWizardCustomerStepFormValues, boolean>>
  isEdit?: boolean
  onNoCustonmers?: (is: boolean) => void
  onStepChange?: (step: number) => void
  bookingForm?: BookingFormValues
  customers?: GlobalSearchModel<CustomerModel>
  searchCustomers: (filter?: FilterModel | undefined) => Promise<void>
}

export const BookingWizardSharedCustomerStep = <T extends BookingWizardCustomerStepFormValues>({
  formik,
  isEdit,
  onNoCustonmers,
  onStepChange,
  bookingForm,
  customers,
  searchCustomers,
}: BookingWizardSharedCustomerStepProps<T>) => {
  const [isStart, setIsStart] = useState<boolean>(false)

  useOnChange(bookingForm, () => {
    if (bookingForm) {
      if (bookingForm.customer) formik.setFieldValue('customer', bookingForm.customer)
    }
  })

  const selectedItems = useMemo(() => {
    return formik.values?.customer ? [formik.values.customer.code] : []
  }, [formik.values.customer])

  const handleOnRowSelectionChange = useCallback(
    (rows: TableRowId[]) => {
      if (isStart) {
        const foundData = customers?.data.find((customer) => {
          return customer.code === rows[0]
        })
        if (foundData) {
          if (foundData.code === formik.values.customer?.code) formik.setFieldValue('customer', '')
          else formik.setFieldValue('customer', foundData)
        }
      }
    },
    [customers?.data, formik, isStart]
  )

  useOnChange(formik.values.customer, () => {
    if (!isStart) {
      if (formik.values.customer) {
        setTimeout(() => {
          setIsStart(true)
          formik.setFieldValue('customer', formik.values?.customer)
        }, 1000)
      } else {
        setTimeout(() => {
          setIsStart(true)
        }, 1000)
      }
    }
  })

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      searchCustomers(filter)
    },
    [searchCustomers]
  )

  const handleIsSelectDisabled = useCallback(
    (rowData: CustomerModel) => {
      if (isEdit) {
        return true
      }
      const firstSelection = formik.values.customer
      const foundData = customers?.data.find((customer) => {
        return customer.code === firstSelection?.code
      })
      if (foundData) {
        return foundData?.code !== rowData?.code
      }
      return false
    },
    [customers?.data, formik.values.customer, isEdit]
  )

  const isCustomer = useMemo(() => {
    const noCustomer = customers?.data && customers.data.length > 0

    return noCustomer
  }, [customers?.data])

  useOnChange(isCustomer, () => {
    if (!isCustomer) onNoCustonmers && onNoCustonmers(true)
    else if (isCustomer) onNoCustonmers && onNoCustonmers(false)
  })

  const customersTable = useMemo(() => {
    return (
      <div className='px-0 mt-0'>
        <CustomerWizardTable
          onFilter={onFilterHandler}
          data={customers}
          hideSelectAll={true}
          onRowSelectionChange={handleOnRowSelectionChange}
          selectedItems={selectedItems}
          isSelectDisabled={handleIsSelectDisabled}
          isEdit={isEdit}
        />
        {!isCustomer && (
          <div className='text-center'>
            <Button
              variant='primary'
              className='btn'
              onClick={() => onStepChange && onStepChange(1)}
            >
              Create new customer
            </Button>
          </div>
        )}
      </div>
    )
  }, [
    customers,
    handleIsSelectDisabled,
    handleOnRowSelectionChange,
    isCustomer,
    isEdit,
    onFilterHandler,
    onStepChange,
    selectedItems,
  ])

  return (
    <>
      <div className='container-fluid'>
        <div className='row g-5'>
          <div className='col-12'>
            <h5 className='mb-0 px-8'>Select a customer</h5>
          </div>
          <div className='col-12'>{customersTable}</div>
        </div>
      </div>
    </>
  )
}
