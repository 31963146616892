import {useMemo} from 'react'
import {usePieChartTooltipContext} from '../../../../../components/charts/PieChart/usePieChartTooltip'
import {TicketCountUsedRemainingModel} from '../../../../../models/customer-portal/WidgetModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {FilterType} from '../../../../../components/tables/constants/FilterType'

export interface TicketCountTooltipProps {
  data?: TicketCountUsedRemainingModel
  event?: EventModel | null
}

export const TicketCountTooltip = ({data, event}: TicketCountTooltipProps) => {
  const {key} = usePieChartTooltipContext()
  const hoveredData = useMemo(() => {
    if (key && data) {
      // return data[key]
      if (key === 'usedPercentage') {
        return data['usedPercentage']
      } else if (key === 'remainingPercentage') {
        return data['remainingPercentage']
      } 
      // return data.find((item) => item.status === key)
    }
  }, [data, key])

  if (!hoveredData || !key) {
    if (key) {
      let percentage = "100%"
      if (hoveredData !== undefined) {
          percentage = `${hoveredData || 0}%`;
      }
      
      return (
          <div>
              <div className='ff-f1-bold text-secondary fs-8 mx-2 mt-4 mb-2'>{getStatusLabel(key)}</div>
              <div className='ff-f1-bold text-center text-secondary fs-2 mb-4'>{percentage}</div>
          </div>
      );
  }
    return null
  }

  return (
    <div>
      <div className='ff-f1-bold text-secondary fs-8 mx-2 mt-4 mb-2'>{getStatusLabel(key)}</div>
      <div className='ff-f1-bold text-center text-secondary fs-2 mb-4'>{`${
        hoveredData || 0
      }%`}</div>
    </div>
  )
}

export const getStatusLabel = (statusKey: string) => {
  const statusItem = FilterType.ADMM_TICKET_STATUS.find((item) => item.value === statusKey)
  return statusItem ? statusItem.label : statusKey
}
