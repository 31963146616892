import {useCallback, useMemo, useRef} from 'react'
import {useLoadingState} from './useLoadingState'

/**
 * Prevents race conditions in promises.
 * */
export const usePromiseManager = () => {
  const keys = useRef<Record<string, number>>({})
  const {isLoading, isKeyLoading, setIsLoading} = useLoadingState()

  const getKey = useCallback((key: string) => {
    if (!keys.current[key]) {
      const initialValue = 1
      keys.current[key] = initialValue
      return initialValue
    } else {
      return ++keys.current[key]
    }
  }, [])

  const isKeyLatest = useCallback((key: string, value: number) => {
    const latestValue = keys.current[key]
    return latestValue === value
  }, [])

  const managePromise = useCallback(
    <T>(key: string, promise: Promise<T>): Promise<T> => {
      const doneLoading = setIsLoading(key)
      return new Promise<T>(async (resolve, reject) => {
        const keyValue = getKey(key)
        try {
          const data = await promise
          if (isKeyLatest(key, keyValue)) {
            resolve(data)
            doneLoading()
          } else {
            reject(new CancelledPromiseError(data))
          }
        } catch (e) {
          reject(e)
          doneLoading()
        }
      })
    },
    [getKey, isKeyLatest, setIsLoading]
  )

  return useMemo(
    () => ({
      managePromise,
      isLoading,
      isKeyLoading,
    }),
    [isKeyLoading, isLoading, managePromise]
  )
}

export class CancelledPromiseError<T> extends Error {
  public data: T

  constructor(data: T) {
    super('Promise is cancelled.')
    this.data = data
  }
}
