import clsx from 'clsx'
import {FC} from 'react'
import Moment from 'react-moment'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {LocationModel} from '../../models/acs/LocationModel'
import {SubLink} from '../../models/TableColumns'
import {VenueModel} from '../../models/acs/VenueModel'

type Props = {
  column: string
  link?: string
  sublink?: SubLink
  data?: any
  columnData?: any
}
const TableColumnDataFormatter: FC<Props> = ({column, data, link, sublink, columnData}) => {
  if (!data) {
    return null
  }
  switch (column) {
    case 'seatMap':
      return data && data.seats ? data.seats : null
    case 'booking':
      return data ? data.qty : null
    case 'isAdmin':
    case 'isSeated':
    case 'isConsumable':
    case 'isCard':
    case 'isEticket':
    case 'isTimeslot':
      return data ? (
        <KTSVG
          path='/media/icons/duotone/Navigation/Check.svg'
          className='svg-icon-success svg-icon-1hx'
        />
      ) : null

    case 'locations':
      const _dataLocations: LocationModel[] = data as LocationModel[]
      return data
        ? _dataLocations.map((item, idx) => (
            <span key={idx} className='badge badge-light-primary text-uppercase'>
              {item.name}
            </span>
          ))
        : null

    case 'venues':
      const _dataVenues: VenueModel[] = data as VenueModel[]
      return data
        ? _dataVenues.map((item, idx) => (
            <span key={idx} className='badge badge-light-primary text-uppercase'>
              {item.name}
            </span>
          ))
        : null

    case 'startedAt':
    case 'endedAt':
    case 'createdAt':
    case 'updatedAt':
      return data ? (
        <Moment format='MMM DD, YYYY hh:mm a' className='text-uppercase'>
          {data}
        </Moment>
      ) : null

    case 'type':
      switch (data) {
        case 'deleted':
          return <span className='badge badge-light-danger text-uppercase'>{data || null}</span>
        case 'updated':
          return <span className='badge badge-light-info text-uppercase'>{data || null}</span>
        case 'e-ticket':
        case 'card':
        case 'sticker':
        case 'created':
          return <span className='badge badge-light-success text-uppercase'>{data || null}</span>
        default:
          return <span className='badge badge-light-primary text-uppercase'>{data || null}</span>
      }

    case 'moduleName':
      return <span className='text-uppercase text-muted'>{data || null}</span>

    case 'event':
    case 'customer':
    case 'parent':
    case 'role':
    case 'user':
      return data ? data.name : null

    case 'gate':
    case 'entrance':
    case 'location':
    case 'venue':
      return <span className='badge badge-light-primary text-uppercase'>{data.name || null}</span>

    case 'productCategory':
      return <span className='text-success text-uppercase'>{data.name || null}</span>

    case 'status':
      return <span className={`text-uppercase ${getBadge(data)}`}>{data || null}</span>
    case 'code':
    case 'description':
    case 'slug':
    case 'name':
    case 'email':
    case 'mobile':
    default: {
      let isLinkUrl = undefined
      if (sublink) {
        const {type, link} = sublink
        switch (type) {
          case 'booking':
            isLinkUrl = `${link}/${columnData.customer.code}/booking-detail/${data}`
            break

          default:
            break
        }
      } else if (link) {
        isLinkUrl = `${link}/${data || null}`
      }

      return isLinkUrl ? <Link to={isLinkUrl}>{data || null}</Link> : data || null
    }
  }
}

export default TableColumnDataFormatter

const getBadge = (status: string) => {
  let baseClass = 'badge text-uppercase'

  switch (status) {
    case 'active':
      return clsx(baseClass, 'badge-light-primary')
    case 'fulfilled':
      return clsx(baseClass, 'badge-light-success')
    case 'cancelled':
      return clsx(baseClass, 'badge-light-danger')
    case 'draft':
      return clsx(baseClass, 'badge-light-dark')
    case 'confirmed':
      return clsx(baseClass, 'badge-light-info')
    default:
      return clsx(baseClass, 'badge-light-dark')
  }
}
