import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {MarkdownTooltip} from '../../../../../../components/charts/MarkdownTooltip'
import {WidgetF11Model} from '../../../../../../models/fnb/WidgetModel'

export interface ProductCategoryRevenueTooltipProps {
  datum?: WidgetF11Model[]
}

export const ProductCategoryRevenueTooltip = ({datum}: ProductCategoryRevenueTooltipProps) => {
  const {key} = useBarChartTooltipContext()

  const data = useMemo(() => {
    return datum?.find((d) => d.fnbProductCategoryName === key)
  }, [datum, key])

  if (!data) {
    return null
  }

  return (
    <MarkdownTooltip>{`**Category:** ${data.fnbProductCategoryName}
  
  **Total:** ${data.total}
  
  **Quantity:** ${data.quantity}
  `}</MarkdownTooltip>
  )
}
