import {PoweredBy, EvaInfoPageLogo} from '../../../../config/logos'

export const InfoPage = () => {
  return (
    <div className='d-flex flex-column justify-content-center h-100'>
      <div className='d-flex flex-column align-items-center'>
        <img className='w-200px mb-10' src={EvaInfoPageLogo.src} alt={EvaInfoPageLogo.alt} />
        <p>Licensed by WNT Solutions LLC</p>
        <img className='w-100px' src={PoweredBy.src} alt={PoweredBy.alt} />
        <span className='text-muted fs-9'>BUILD {process.env.REACT_APP_BUILD}</span>
      </div>
    </div>
  )
}
