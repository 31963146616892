import {useCallback} from 'react'
import {useAuthRole} from '../../../../../components/hooks/useAuthRole'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {UserModel, UserModelStatus} from '../../../../../models/customer-portal/UserModel'
import {idExtractor} from '../../../../../utils/idExtractor'

export interface UserSelectionActionProps {
  selected: TableRowId[]
  items: UserModel[]
  onResetMFA?: (users: UserModel[]) => void
  onActivate?: (users: UserModel[]) => void
  onDelete?: (users: UserModel[]) => void
  onSetSmartPin?: (users: UserModel[]) => void
}
export const UserSelectionAction = ({selected, items, onResetMFA, onDelete, onActivate, onSetSmartPin}: UserSelectionActionProps) => {
  const auth = useAuthRole()
  const isNotSameCode = useCallback(
    (data: UserModel) => {
      if (auth) {
        return auth.getUser().code !== data.code
      }
      return false
    },
    [auth]
  )
  return (
    <>
      <TableSelectionAction
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        onClick={onActivate}
        variant='success'
        filter={userCanBeActivated}
      >
        'Activate'
      </TableSelectionAction>
      <TableSelectionAction
        variant='warning'
        onClick={onResetMFA}
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        filter={isNotSameCode}
      >
        Reset MFA
      </TableSelectionAction>
      <TableSelectionAction
        variant='success'
        onClick={onSetSmartPin}
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        filter={isNotSameCode}
      >
        Set Customer Smart Pin
      </TableSelectionAction>

      <TableSelectionAction
        idExtractor={idExtractor}
        items={items}
        selected={selected}
        onClick={onDelete}
        variant='danger'
        filter={userCanBeDeleted}
      >
        Delete
      </TableSelectionAction>
    </>
  )
}

export const userCanBeDeleted = (user: UserModel) => {
  return !user.isActive
}

export const userCanBeActivated = (user: UserModel) => {
  const allowedStatus: UserModelStatus[] = ['pending']
  return allowedStatus.includes(user.status)
}