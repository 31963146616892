import {BaseFileInputValue} from '../components/inputs/FileInput/BaseFileInputValue'
import {FileInputValueCollection} from '../components/inputs/FileInput/FileInputValueCollection'

export class ApiFormData<T extends Record<string, any>> {
  private formData: FormData

  constructor(private payload: T) {
    this.formData = new FormData()
    this.buildFormData()
  }
  private buildFormData() {
    Object.entries(this.payload).forEach(([key, value]) => {
      if (value instanceof FileInputValueCollection) {
        const files = (value as FileInputValueCollection<BaseFileInputValue>).getFileArray()
        files.forEach((file) => {
          const blob = file.toApiValue()
          if (blob) {
            this.formData.append(key, blob)
          }
        });
      } else if (Array.isArray(value) && key === 'customFields') {
        this.formData.append(key, JSON.stringify(value))
      } else if (Array.isArray(value) && key === 'subProducts') {
        this.formData.append(key, JSON.stringify(value))
      } else if (Array.isArray(value)) {
        value.forEach((val) => this.formData.append(`${key}[]`, val))
      } else {
        if (typeof value !== 'undefined') this.formData.append(key, value)
      }
    })
  }

  public append(key: string, value: string) {
    this.formData.append(key, value)
  }

  public getFormData = () => {
    return this.formData
}
}