import {useCallback, useState, useMemo, useEffect} from 'react'
import {UsersChat} from '../components/UsersChat/UsersChat'
import {CustomerChatUserModel} from '../../../models/eva/CustomerChatUserModel'
import {TextInput} from '../../../components/inputs/TextInput'
import {GetChatUsers} from '../redux/EvaCRUD'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useChat} from '../hooks/useChat'
import {TicketAuth} from '../components/TicketAuth/TicketAuth'

export const ChatPage = () => {
  const [searchToDisplay, setSearchToDisplay] = useState<string>('')
  const currentUser = useRootStateSelector((state) => state.eva?.user)
  const [userList, setUserList] = useState<CustomerChatUserModel[]>([])
  const {pushError} = useAlerts()

  const refreshChatList = useCallback(
    async (user: CustomerChatUserModel) => {
      try {
        const {data} = await GetChatUsers(user.code)
        setUserList(data)
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  const handleMessageReceive = useCallback(() => {
    if (currentUser) {
      refreshChatList(currentUser.data)
    }
  }, [currentUser, refreshChatList])

  useChat({
    onMessageReceive: handleMessageReceive,
  })

  const handleInputChage = useCallback((e) => {
    const {value} = e.target
    setSearchToDisplay(value)
  }, [])

  const searchedUsers = useMemo(() => {
    return userList.filter((user) => {
      return user.name.toLowerCase().includes(searchToDisplay.toLowerCase())
    })
  }, [searchToDisplay, userList])

  const content = useMemo(() => {
    if (currentUser) {
      return (
        <div>
          <div>
            <TextInput
              fullWidth
              startAdornment={
                <span className='input-group-text'>
                  <MetronicIcon iconType='General' iconName='Search' size='md' />
                  Search
                </span>
              }
              className='w-100 border rounded-0'
              id='search-input'
              noMargin
              value={searchToDisplay}
              onChange={(e) => handleInputChage(e)}
            />
          </div>
          {searchedUsers.length > 0 && (
            <div className='p-5'>
              <UsersChat usersList={searchedUsers} />
            </div>
          )}
        </div>
      )
    }
  }, [currentUser, handleInputChage, searchToDisplay, searchedUsers])

  useEffect(() => {
    if (currentUser) {
      refreshChatList(currentUser.data)
    }
  }, [currentUser, refreshChatList])

  return (
    <div className='h-100'>
      <TicketAuth qrCodemessage='To communicate with other participants, click here to scan your badge'>
        {content}
      </TicketAuth>
    </div>
  )
}
