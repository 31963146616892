import {ChangeEvent, useCallback, useMemo} from 'react'
import {TicketGroupModel} from '../../../../models/customer-portal/TicketModel'
import {TicketCard} from './TicketCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {useTicketTableActions} from '../../hooks/useTicketTableActions'
import {PortalSearchModel} from '../../../../models/customer-portal/PortalSearchModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
export interface TicketCardGridProps {
  data?: PortalSearchModel<TicketGroupModel> | GlobalSearchModel<TicketPortalModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  className?: string
  initialFilters?: FilterModel
}

export const TicketCardGrid = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  className,
  initialFilters,
}: TicketCardGridProps) => {
  const {setSearch, search, pageNumber, pageSize, setPageNumber, setPageSize} = useFilterState(
    onFilter,
    {
      initialFilters,
    }
  )
  const {isKeyLoading} = useTicketTableActions(onRefresh, onRefreshCallback)

  const cards = useMemo(() => {
    if (data?.data) {
      return (
        <div className='row'>
          {Array.isArray(data.data)
            ? data.data.map((ticket) => (
                <div key={ticket.code} className='mb-3 col-xs-12 col-sm-6'>
                  <TicketCard loading={isKeyLoading(ticket.code)} className='h-100' data={ticket} />
                </div>
              ))
            : Object.entries(data.data).map(([, tickets]) => {
                return (
                  tickets &&
                  tickets.length > 0 &&
                  tickets.map((ticket) => {
                    return (
                      <div key={ticket.code} className='mb-3 col-xs-12 col-sm-6'>
                        <TicketCard
                          loading={isKeyLoading(ticket.code)}
                          className='h-100'
                          data={ticket}
                        />
                      </div>
                    )
                  })
                )
              })}
        </div>
      )
    }
    return null
  }, [data, isKeyLoading])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  return (
    <div className={className}>
      <TextInput
        className='mb-3'
        noMargin
        placeholder='Search'
        value={search}
        onChange={handleSearchChange}
      />
      <div>{cards}</div>
      <PaginationHelper
        currentPageNumber={pageNumber}
        currentPageSize={pageSize}
        onChangePageNumber={setPageNumber}
        onChangePageSize={setPageSize}
        total={data?.total}
      />
    </div>
  )
}
