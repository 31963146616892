export const UsedWaiverDescription = () => {
  return (
    <div className='text-white'>
      <p>
        We hope you enjoyed your time with us at Yas Marina Circuit - Home of the Formula 1® Season
        Finale. Here, you can view your past tickets & completed waiver forms. We look forward to
        hosting your next on-track experience!
      </p>
    </div>
  )
}
