import {Link} from 'react-router-dom'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {EventModel} from '../../../../../models/ems/EventModel'
interface BookingCodeColumnProps {
  data: BookingModel
  event?: EventModel
}
export const BookingCodeColumn = ({data, event}: BookingCodeColumnProps) => {
  if (event) {
    return (
      <Link
        className='min-w-80px'
        to={`/event/${event.code}/booking/customer/${data?.customer?.code}/booking/${data?.code}/booking-detail`}
      >
        {data.code}
      </Link>
    )
  }
  return (
    <Link
      className='min-w-80px'
      to={`/event/${data?.event?.code}/booking/customer/${data?.customer?.code}/booking/${data?.code}/booking-detail`}
      // to={`/customer/${data?.customer?.code}/booking/${data?.code}/booking-detail`}
    >
      {data.code}
    </Link>
  )
}
