import {isValidPhoneNumber} from 'react-phone-number-input'

export class MobileNumberParser {
  constructor(private mobileNumber: string) {}

  public isValidMobileNumber() {
    try {
      return isValidPhoneNumber(this.mobileNumber)
    } catch (e) {
      return false
    }
  }
}
