import {FormikContextType} from 'formik'
import {ReservationFormValues} from '../ReservationWizard'
import {useCallback, useState} from 'react'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {FilterModel} from '../../../../../../models/FilterModel'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../../../../components/alerts/useAlerts'
import {} from '../../../../redux/CustomerPortalCRUD'
import { CustomerReservationTable } from '../../../../../default/ems/components/CustomerReservationTable'
import { ReservationItemModel } from '../../../../../../models/ems/ReservationModel'
import { GetReservationItems } from '../../../../../default/ems/redux/EmsCRUD'

export interface ReservationWizardFinalizeStepStepProps {
  formik: FormikContextType<ReservationFormValues>
}

interface RouteMatch {
  reservationCode?: string
}

export const ReservationWizardFinalizeStep = ({formik}: ReservationWizardFinalizeStepStepProps) => {
  const match = useRouteMatch<RouteMatch>()
  const {reservationCode} = match.params
  const [reservationData, setReservationData] =
    useState<GlobalSearchModel<ReservationItemModel>>()
  const {pushError} = useAlerts()
  const [reservationFilters, setReservationFilter] = useState<FilterModel>()

  const refreshReservationList = useCallback(async () => {
    try {
      const {data} = await GetReservationItems(reservationFilters)
      setReservationData(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [reservationFilters, pushError])

  const onFilterReservationHandler = useCallback(
    async (filter: FilterModel) => {
      if (reservationCode) {
        try {
          const filters = {
            ...filter,
            filters: {...filter.filters, reservation: reservationCode},
          }
          setReservationFilter(filters)
          const {data} = await GetReservationItems(filters)
          setReservationData(data)
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    [reservationCode, pushError]
  )


  return (
    // <div className='container'>
      <div>
      <CustomerReservationTable
          data={reservationData}
          onFilter={onFilterReservationHandler}
          onRefresh={refreshReservationList}
        />
      </div>
    // </div>
  )
}
