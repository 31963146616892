import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useOnMount} from '../../../components/hooks/useOnMount'
import {useQueryParam} from '../../../components/hooks/useQueryParam'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {AgendaDayList} from '../components/AgendaDayList'
import {AgendaTable} from '../components/AgendaTable'
import {CustomerImage} from '../components/CustomerImage/CustomerImage'
import {usePushNotification} from '../hooks/usePushNotification'
import {GetCustomerByTicketCode} from '../redux/EvaCRUD'
import {actions} from '../redux/EvaRedux'

export const AgendaPage = () => {
  const [selectedDate, setSelectedDate] = useState<string>()
  const {value: ticketCode} = useQueryParam('q')
  const dispatch = useDispatch()
  const user = useRootStateSelector((state) => state.eva.user)
  const {subscribeToPushNotifications} = usePushNotification()

  const validateTicket = useCallback(async () => {
    if (ticketCode) {
      try {
        const data = await GetCustomerByTicketCode(ticketCode)
        dispatch(actions.saveUser(data.data, ticketCode))
        subscribeToPushNotifications(data.data)
      } catch (e) {
        // Invalid code
      }
    }
  }, [dispatch, subscribeToPushNotifications, ticketCode])

  useOnMount(() => {
    validateTicket()
  })

  return (
    <div className='d-flex flex-column h-100'>
      <AgendaDayList
        className='flex-shrink-0 bg-light'
        onClick={setSelectedDate}
        selectedDate={selectedDate}
      />
      <div className='pt-5 flex-grow-1 overflow-auto'>
        {user && (
          <div className='mx-5 text-center'>
            <h1 className='mb-0'>Welcome!</h1>
            <div className='d-flex align-items-center flex-column'>
              <CustomerImage customer={user.data} className='my-3' />
              <h1>{user.data.name}</h1>
            </div>
          </div>
        )}
        <AgendaTable day={selectedDate} />
      </div>
    </div>
  )
}
