import {RowAction} from '../../../../../../components/tables/actions/RowAction'
import {VoucherModel} from '../../../../../../models/svc/VoucherModel'
import {isVoucherShareable} from '../../../../hooks/useVoucherTableActions'

export interface VoucherTableRowActionsProps {
  onDisable?: (voucher: VoucherModel) => void
  data: VoucherModel
  disabled?: boolean
  loading?: boolean
}

export const VoucherTableRowActions = ({
  onDisable,
  data,
  disabled,
  loading,
}: VoucherTableRowActionsProps) => {
  return (
    <>
      {isVoucherShareable(data) && (
        <RowAction
          loading={loading}
          disabled={disabled}
          variant='danger'
          onClick={onDisable}
          data={data}
          iconType='Code'
          iconName='Stop'
          activeColor='danger'
          tooltip='Disable Voucher'
        />
      )}
    </>
  )
}
