import {useCallback, useMemo} from 'react'
import {useLoadingState} from '../../../../../../components/hooks/useLoadingState'
import {useTableOptions} from '../../../../../../components/tables/useTableOptions'
import {FilterModel} from '../../../../../../models/FilterModel'
import {useCustomerHelpers} from '../../../../../default/svc/components/tables/CustomerTable/useCustomerHelpers'
import {CustomerModel} from '../../../../../../models/CustomerModel'
import {CustomerTableRowActions} from '../../../../../default/svc/components/tables/CustomerTable/CustomerTableRowActions'
import {TableColumnOptions} from '../../../../../../components/tables/TableColumn'
import {TableAvatarImageColumn} from '../../../../../default/svc/components/tables/TableAvatarImageColumn'
import {FilterTable} from '../../../../../../components/tables/FilterTable'
import {idExtractor} from '../../../../../../utils/idExtractor'
import {FilterInputContainer} from '../../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {MultiSelectToggleFilterInput} from '../../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {FilterType} from '../../../../../../components/tables/constants/FilterType'
import {GlobalSearchModel} from '../../../../../../models/GlobalSearchModel'
import {TableRowId} from '../../../../../../components/tables/TableRow'

export interface CustomerTableProps {
  onFilter: (filter: FilterModel) => void
  onRefresh?: () => void
  data?: GlobalSearchModel<CustomerModel>
  viewOnly?: boolean
  hideSelectAll?: boolean
  isSelectDisabled?: (data: CustomerModel) => boolean
  onRowSelectionChange?: (rows: TableRowId[]) => void
  selectedItems: TableRowId[]
  isEdit?: boolean
}

export const CustomerWizardTable = ({
  onFilter,
  data,
  onRefresh,
  viewOnly,
  hideSelectAll,
  isSelectDisabled,
  onRowSelectionChange,
  selectedItems,
  isEdit,
}: CustomerTableProps) => {
  const {isLoading: isTableLoading} = useLoadingState()
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'customer',
    defaults: {
      hiddenColumns: ['salutation'],
    },
  })
  const {canManage, isCustomerLoading} = useCustomerHelpers({
    onRefresh,
  })

  const rowActions = useCallback(
    (data: CustomerModel) =>
      canManage && (
        <CustomerTableRowActions
          data={data}
          isLoading={isCustomerLoading(data) || isTableLoading}
        />
      ),
    [canManage, isCustomerLoading, isTableLoading]
  )

  const tableItems = useMemo(() => {
    return data?.data || []
  }, [data?.data])

  const columns: TableColumnOptions<CustomerModel>[] = useMemo(() => {
    return [
      {
        field: 'photo',
        label: 'Photo',
        sortable: true,
        hideable: true,
        className: 'w-50px',
        render: ({data}) =>
          data.photo ? <TableAvatarImageColumn image={data.photo} alt={data.name} /> : null,
      },

      {
        field: 'name',
        label: 'Name',
        sortable: true,
        hideable: true,
      },
      {
        field: 'email',
        label: 'Email',
        sortable: true,
        hideable: true,
      },
      {
        field: 'mobile',
        label: 'Mobile',
        sortable: true,
        hideable: true,
      },
    ]
  }, [])

  return (
    <>
      <FilterTable
        onFilter={onFilter}
        idExtractor={idExtractor}
        hiddenColumns={viewOnly ? undefined : hiddenColumns}
        onHiddenColumnsChange={viewOnly ? undefined : setHiddenColumns}
        initialFilters={initialFilters}
        advancedFilters={
          viewOnly ? undefined : (
            <FilterInputContainer>
              <MultiSelectToggleFilterInput
                field='type'
                items={FilterType.CUSTOMER_TYPE}
                label='Type'
              />
              <MultiSelectToggleFilterInput
                field='status'
                items={FilterType.CUSTOMER_STATUS}
                label='Status'
              />
            </FilterInputContainer>
          )
        }
        data={tableItems}
        currentPageNumber={data?.page}
        columns={columns}
        totalItems={data?.total || 0}
        actions={viewOnly ? undefined : rowActions}
        selection={selectedItems}
        onRowSelectionChange={onRowSelectionChange}
        isSelectSingleRow={true}
        hideSelectAll={hideSelectAll}
        isSelectDisabled={isSelectDisabled}
        isAllCheckBoxDisabled={isEdit}
        // additionalPagesSize={[5]}
        noPadding
      />
    </>
  )
}

const initialFilters = {
  filters: {
    status: ['pending', 'queue', 'sent', 'submitted', 'active'],
  },
  limit: 10,
}
