export type TimeTuple = [number, number, number]

export class Time {
  private hours!: number
  private minutes!: number
  private seconds!: number

  constructor(hours: number, minutes: number, seconds: number) {
    this.setHours(hours)
    this.setMinutes(minutes)
    this.setSeconds(seconds)
  }

  public static fromArray = (time: TimeTuple) => {
    return new Time(time[0], time[1], time[2])
  }

  public static fromSeconds = (seconds: number) => {
    return Time.fromDate(new Date(seconds * 1000))
  }

  public static fromDate = (date: Date) => {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return new Time(hours, minutes, seconds)
  }

  public getHours() {
    return this.hours
  }

  public getMinutes() {
    return this.minutes
  }

  public getSeconds() {
    return this.seconds
  }

  public setHours(hours: number) {
    this.hours = this.clampNumber(hours, 0, 23)
  }

  public setMinutes(minutes: number) {
    this.minutes = this.clampNumber(minutes, 0, 59)
  }

  public setSeconds(seconds: number) {
    this.seconds = this.clampNumber(seconds, 0, 59)
  }

  public toArray = (): TimeTuple => {
    return [this.hours, this.minutes, this.seconds]
  }

  public applyToDate = (date: Date) => {
    date.setHours(this.hours)
    date.setMinutes(this.minutes)
    date.setSeconds(this.seconds)
  }

  public clone() {
    return new Time(this.hours, this.minutes, this.seconds)
  }

  private clampNumber(number: number, min: number, max: number) {
    return Math.max(Math.min(number, max), min)
  }

  /**
   * Returns >=1 if `this` is greater.
   * Returns <=1 if `this` is lesser.
   * Returns 0 if equal.
   * */
  public compare = (time: Time) => {
    const hoursComparison = this.compareHours(time)
    if (hoursComparison !== 0) {
      return hoursComparison
    }
    const minutesComparison = this.compareMinutes(time)
    if (minutesComparison !== 0) {
      return minutesComparison
    }
    return this.compareSeconds(time)
  }

  private compareHours = (time: Time): number => {
    return this.hours - time.hours
  }

  private compareMinutes = (time: Time): number => {
    return this.minutes - time.minutes
  }

  private compareSeconds = (time: Time): number => {
    return this.seconds - time.seconds
  }

  public toString = () => {
    const hoursString = String(this.hours).padStart(2, '0')
    const minutesString = String(this.minutes).padStart(2, '0')
    const secondsString = String(this.seconds).padStart(2, '0')

    return `${hoursString}:${minutesString}:${secondsString}`
  }
}
