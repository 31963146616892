import {useCallback, useMemo} from 'react'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'

export const useCustomerDelegatesAuth = () => {
  const user = useRootStateSelector((state) => state.customerPortal.auth?.user)

  const getUser = useCallback(() => {
    if (!user) {
      throw new Error('User is not logged in.')
    }
    return user
  }, [user])

  const isFirstLogin = useCallback(() => {
    return getUser().isFirstLogin
  }, [getUser])

  return useMemo(() => {
    if (user) {
      return {
        getUser,
        isFirstLogin,
      }
    }
  }, [user, getUser, isFirstLogin])
}
