import {useMemo} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {
  CustomerPortalAuthBackground,
  CustomerPortalAuthBackgroundMobile,
  CustomerPortalAuthFormLogoHeader,
  PoweredBy,
} from '../../../../config/logos'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import FirstLoginPasswordResetForm from '../components/FirstLoginPasswordReset'
import {ForgotPassword} from '../components/forms/ForgotPassword'
import {Login} from '../components/Login'
import {useCustomerDelegatesAuth} from '../hooks/useCustomerAuth'

export const AuthDelegatesPage = () => {
  const {down} = useBreakpoint()
  const auth = useCustomerDelegatesAuth()

  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  const image = useMemo(() => {
    if (isMobile) {
      return CustomerPortalAuthBackgroundMobile.src
    }
    return CustomerPortalAuthBackground.src
  }, [isMobile])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
        <img
          alt={CustomerPortalAuthFormLogoHeader.alt}
          src={CustomerPortalAuthFormLogoHeader.src}
          className='img-fluid w-300px w-100 mb-8'
        />
        <div className='w-sm-500px w-300px bg-body opacity-75 rounded shadow-sm p-10 p-lg-15 mx-auto'>
          {auth?.isFirstLogin() && <Redirect to='/auth/password-reset' />}
          {auth && !auth.isFirstLogin() && <Redirect to='/' />}
          <Switch>
            <Route path='/auth/login'>
              <Login />
            </Route>

            <Route path='/auth/password-reset' component={FirstLoginPasswordResetForm} />
            <Route path='/auth/forgot-password'>
              <ForgotPassword />
            </Route>
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        <img alt={PoweredBy.alt} src={PoweredBy.src} className='h-30px mt-12' />
      </div>
    </div>
  )
}
