import clsx from 'clsx'
import {Badge} from '../../badge/Badge'
import {ColorVariant} from '../../inputs/Button'

export interface OrderCardItemAttributes {
  name: string
  category: string
  count: number
  cancelled?: boolean
}

export interface OrderCardItemProps {
  item: OrderCardItemAttributes
  variant: ColorVariant
}

export const OrderCardItem = ({item, variant}: OrderCardItemProps) => {
  return (
    <li className='order-card-item'>
      <div>
        <p
          className={clsx('order-card-item__name', {
            'text-decoration-line-through': item.cancelled,
            'opacity-25': item.cancelled,
          })}
        >
          {item.name}
        </p>
        <p
          className={clsx('order-card-item__category', {
            'text-decoration-line-through': item.cancelled,
            'opacity-25': item.cancelled,
          })}
        >
          {item.category}
        </p>
      </div>
      <div className='d-flex align-items-center'>
        <Badge
          variant={variant}
          className={clsx('order-card-item__count', {
            'opacity-25': item.cancelled,
          })}
        >
          {item.count}
        </Badge>
      </div>
    </li>
  )
}
