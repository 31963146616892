import {useCallback, useMemo, useState} from 'react'
import {CustomerRegistrationFormLogoHeader, PoweredBy} from '../../../../config/logos'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {VerifyRSVPHash} from '../components/redux/CustomerRegistrationCRUD'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useQueryParam} from '../../../components/hooks/useQueryParam'
import {useHistory} from 'react-router'

interface VerifyRSVPHashResponse {
  error: string
  data: {
    answer: string
  }
}

interface answerParams {
  message: string
  answer: string
}
export const RSVPPage = () => {
  const history = useHistory()
  const {value: hash} = useQueryParam('hash')
  const [hashData, setHashData] = useState<answerParams | null>()
  const [loading, setLoading] = useState<boolean>(true)
  const {pushError} = useAlerts()

  const verifyRSVPHash = useCallback(async () => {
    if (hash) {
      try {
        const {data}: {data: VerifyRSVPHashResponse} = await VerifyRSVPHash(hash)
        setHashData({
          message: data.error,
          answer: data.data.answer,
        })
      } catch (err) {
        pushError(err)
        history.replace('/error/403')
      } finally {
        setLoading(false)
      }
    }
  }, [hash, history, pushError])

  useOnChange(hash, () => {
    if (!hash) history.replace('/error/403')
    verifyRSVPHash()
  })

  const resultMessage = useMemo(() => {
    if (hashData) {
      return <h4>Thank you for your response.</h4>
    }
    return null
  }, [hashData])
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'>
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
      {!loading && (
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-100vh'>
          <img
            alt={CustomerRegistrationFormLogoHeader.alt}
            src={CustomerRegistrationFormLogoHeader.src}
            className='img-fluid w-300px w-100 mb-8'
          />
          <div className='w-sm-500px w-300px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto text-center'>
            {resultMessage}
          </div>
          <img alt={PoweredBy.alt} src={PoweredBy.src} className='h-30px mt-12' />
        </div>
      )}
    </div>
  )
}
