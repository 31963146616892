import {SelectInputItem} from '../../inputs/SelectInput'

export abstract class FilterType {
  public static PRODUCT_TYPE: SelectInputItem[] = [
    {label: 'Card', value: 'card'},
    {label: 'E-Ticket', value: 'e-ticket'},
    {label: 'Sticker', value: 'sticker'},
  ]

  public static TICKET_TYPE: SelectInputItem[] = [
    {label: 'Card', value: 'card'},
    {label: 'E-Ticket', value: 'e-ticket'},
    {label: 'Sticker', value: 'sticker'},
  ]

  public static VOUCHER_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Disabled',
      value: 'disabled',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
    {
      label: 'Shared',
      value: 'shared',
    },
    {
      label: 'Unassigned',
      value: 'unassigned',
    },
    {
      label: 'Used',
      value: 'used',
    },
  ]

  public static VOUCHER_TYPE: SelectInputItem[] = [
    {
      label: 'Cash card',
      value: 'cash-card',
    },
    {
      label: 'Amount',
      value: 'amount',
    },
  ]

  public static TEMPLATE_TYPE: SelectInputItem[] = [
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Ticket',
      value: 'ticket',
    },
    {
      label: 'Voucher',
      value: 'voucher',
    },
  ]

  public static TICKET_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
    {
      label: 'Confirmed',
      value: 'confirmed',
    },
    {
      label: 'Printed',
      value: 'printed',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
    {
      label: 'Shared',
      value: 'shared',
    },
    {
      label: 'Converted',
      value: 'converted',
    },
  ]

  public static ADMM_TICKET_STATUS: SelectInputItem[] = [
    // {
    //   label: 'Draft Bookings',
    //   value: 'draft',
    // },
    // {
    //   label: 'Confirmed Bookings',
    //   value: 'confirmed',
    // },
    // {
    //   label: 'Fulfilled Bookings',
    //   value: 'fulfilled',
    // },
    {
      label: 'Total Shared',
      value: 'usedPercentage',
    },
    {
      label: 'Total Remaining',
      value: 'remainingPercentage',
    },
  ]

  public static BOOKING_PORTAL_STATUS_CUSTOM: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
  ]

  public static TICKET_VOUCHER_PORTAL_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Disabled',
      value: 'disabled',
    },
    {
      label: 'Shared',
      value: 'shared',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
  ]

  public static BOOKING_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
    {
      label: 'Confirmed',
      value: 'confirmed',
    },
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Fulfilled',
      value: 'fulfilled',
    },
  ]
  public static ADMM_BOOKING_STATUS: SelectInputItem[] = [
    // {
    //   label: 'Draft Bookings',
    //   value: 'draft',
    // },
    // {
    //   label: 'Confirmed Bookings',
    //   value: 'confirmed',
    // },
    // {
    //   label: 'Fulfilled Bookings',
    //   value: 'fulfilled',
    // },
    {
      label: 'Active Bookings',
      value: 'active',
    },
    {
      label: 'Cancelled Bookings',
      value: 'cancelled',
    },
  ]

  public static RESERVATION_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
    {
      label: 'Released',
      value: 'released',
    },
  ]

  public static CUSTOMER_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Disabled',
      value: 'disabled',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Queue',
      value: 'queue',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
    {
      label: 'Submitted',
      value: 'submitted',
    },
  ]

  public static GUEST_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
    {
      label: 'Confirmed',
      value: 'confirmed',
    },
    {
      label: 'Declined',
      value: 'declined',
    },
    {
      label: 'Maybe',
      value: 'maybe',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Printed',
      value: 'printed',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
    {
      label: 'Submitted',
      value: 'submitted',
    },
    {
      label: 'Queue',
      value: 'queue',
    },
    {
      label: 'To Be Reprinted',
      value: 'to-be-reprinted',
    },
  ]

  public static WORKSHOP_GUEST_STATUS: SelectInputItem[] = [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Booked',
      value: 'booked',
    },
    {
      label: 'Checked-In',
      value: 'checked-in',
    },
    {
      label: 'Checked-Out',
      value: 'checked-out',
    },
    {
      label: 'Missed',
      value: 'missed',
    },
    {
      label: 'Waiting',
      value: 'waiting',
    },
  ]

  public static CUSTOMER_TYPE: SelectInputItem[] = [
    {
      label: 'Customer',
      value: 'customer',
    },
    {
      label: 'Guest',
      value: 'guest',
    },
    {
      label: 'Partner',
      value: 'partner',
    },
    {
      label: 'Reseller',
      value: 'reseller',
    },
  ]

  public static CUSTOMER_PORTAL_TYPE: SelectInputItem[] = [
    {
      label: 'Customer',
      value: 'customer',
    },
    {
      label: 'Sub-Account',
      value: 'reseller',
    },
  ]

  public static BADGE_STATUS: SelectInputItem[] = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Confirmed',
      value: 'confirmed',
    },
    {
      label: 'Pre-activated',
      value: 'pre-activated',
    },
    {
      label: 'Printed',
      value: 'printed',
    },
    {
      label: 'Unassigned',
      value: 'unassigned',
    },
  ]
}
