import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {AlertCreateOptions} from '../../../components/alerts/Alert'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {useQueryParams} from '../../../components/hooks/useQueryParams'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {Spinner} from '../../../components/utils/Spinner'
import {OrderModel} from '../../../models/fnb/OrderModel'
import {Validators} from '../../../utils/Validators'
import {useCart} from '../hooks/useCart'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {
  GetOrderByCode,
  GetPayfortCredentials,
  GetPayfortPayment,
  GetPayfortToken,
  ProcessCardPayment,
} from '../redux/DigitalMenuCRUD'

export const PayfortCheckoutConfirmationPage = () => {
  const [order, setOrder] = useState<OrderModel>()
  const {clearCart} = useCart(order?.outlet?.code)
  const {goToOrderByCode} = usePageNavigation()
  const queryParams = useQueryParams()
  const pathParams = useParams<{orderCode: string}>()
  const email = useRootStateSelector((state) => state.digitalMenu.profile.email)
  const {push, pushError} = useAlerts()

  const goToOrderPage = useCallback(
    (alert?: AlertCreateOptions) => {
      if (alert) {
        push(alert)
      }
      goToOrderByCode(pathParams.orderCode)
    },
    [goToOrderByCode, pathParams.orderCode, push]
  )

  const markOrderAsSuccess = useCallback(
    async (merchantReference: string, authorizationCode: string) => {
      try {
        await ProcessCardPayment(merchantReference, authorizationCode)
        clearCart()
        goToOrderPage({
          message: 'Order successfully paid.',
          timeout: 10000,
          variant: 'success',
        })
      } catch (e) {
        pushError(e)
      }
    },
    [clearCart, goToOrderPage, pushError]
  )

  const procesTokenization = useCallback(
    async (tokenName: string) => {
      try {
        const returnUrl = `${window.location.origin}${window.location.pathname}`
        const {data} = await GetPayfortCredentials(
          pathParams.orderCode,
          tokenName,
          email,
          returnUrl
        )
        if (data['3ds_url'] && Validators.isUrl(data['3ds_url'])) {
          window.location.replace(data['3ds_url'])
        }
      } catch (e) {
        pushError(e)
      }
    },
    [email, pathParams.orderCode, pushError]
  )

  const processTokenization = useCallback(
    async (orderCode: string) => {
      const {data} = await GetPayfortToken(orderCode)
      await procesTokenization(data.data.token_name)
    },
    [procesTokenization]
  )

  const process3ds = useCallback(
    async (orderCode: string) => {
      const {data} = await GetPayfortPayment(orderCode)
      if (data.data.authorization_code) {
        await markOrderAsSuccess(data.data.merchant_reference, data.data.authorization_code)
      } else {
        push({
          message: data.data.response_message || 'Transaction failed',
          timeout: 10000,
          variant: 'danger',
        })
        goToOrderPage()
      }
    },
    [goToOrderPage, markOrderAsSuccess, push]
  )

  useOnChange(order, async () => {
    if (order) {
      const type = queryParams.get('type')
      if (type === '3ds') {
        process3ds(order.code)
      } else {
        processTokenization(order.code)
      }
    }
  })

  useEffect(() => {
    GetOrderByCode(pathParams.orderCode)
      .then(({data}) => {
        setOrder(data)
      })
      .catch(pushError)
  }, [pathParams.orderCode, pushError])

  return (
    <div className='container vh-100'>
      <div className='h-100 d-flex flex-direction-column align-items-center justify-content-center'>
        <div className='d-flex align-items-center'>
          <Spinner className='me-5' size='lg' />
          <h1 className='m-0'>Loading...</h1>
        </div>
      </div>
    </div>
  )
}
