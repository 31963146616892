import {useCallback, useMemo, useState} from 'react'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {FilterSearchableSelectInput} from '../../../../../components/inputs/SearchableSelect/FilterSearchableSelectInput'
import {EventModel} from '../../../../../models/ems/EventModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {selectItemMapper} from '../../../../../utils/idExtractor'
import {useAppConfig} from '../../../../app-config/hooks/useAppConfig'
import {GetEvents} from '../../redux/EmsCRUD'

export interface EventSearchInputProps {
  onChange: (item: EventModel | null) => void
  value: EventModel | null
  className?: string
  noMargin?: boolean
  placeholder?: string
}

export const EventSearchInput = ({
  onChange,
  value,
  className,
  noMargin,
  placeholder,
}: EventSearchInputProps) => {
  const [eventSearchResult, setEventSearchResult] = useState<GlobalSearchModel<EventModel>>()
  const {pushError} = useAlerts()
  const {staticUrls} = useAppConfig()

  const searchEvents = useCallback(
    async (filter: FilterModel) => {
      try {
        const {data} = await GetEvents(filter)
        setEventSearchResult(data)
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  const image = useMemo(() => {
    return value?.emsFile ? new ImageInputValue(staticUrls.public, value.emsFile) : null
  }, [staticUrls.public, value?.emsFile])

  const renderEvent = useCallback(
    (item: EventModel) => {
      const image = item.emsFile ? new ImageInputValue(staticUrls.public, item.emsFile) : null
      return (
        <div
          className='d-flex align-items-center mb-1'
          role='button'
          onMouseDown={() => onChange(item)}
        >
          <div
            className='flex-shrink-0 d-flex align-items-center me-2'
            style={{width: '20px', height: '20px'}}
          >
            {image && <img className='img-fluid' src={image?.url} alt={item.name} />}
          </div>
          <div>{item.name}</div>
        </div>
      )
    },
    [onChange, staticUrls.public]
  )

  return (
    <FilterSearchableSelectInput
      className={className}
      searchResult={eventSearchResult}
      onFilter={searchEvents}
      itemMapper={selectItemMapper}
      onChange={onChange}
      value={value}
      noMargin={noMargin}
      renderItem={renderEvent}
      placeholder={placeholder}
      displayedValue={
        value && (
          <div className='d-flex align-items-center'>
            {image && (
              <div
                className='flex-shrink-0 d-flex align-items-center me-2'
                style={{width: '20px', height: '20px'}}
              >
                <img className='img-fluid' src={image?.url} alt={value.name} />
              </div>
            )}
            <div className='text-truncate overflow-hidden'>{value.name}</div>
          </div>
        )
      }
    />
  )
}
