import {useCallback} from 'react'
import {ColorVariant} from '../../inputs/Button'
import {MetronicIconButton, MetronicIconButtonProps} from '../../inputs/MetronicIconButton'

export interface CustomerPortalRowActionsProps<T> extends Omit<MetronicIconButtonProps, 'onClick'> {
  onClick?: (data: T) => void
  data: T
  variant: ColorVariant
}

export const CustomerPortalRowActions = <T,>({
  onClick,
  data,
  variant,
  ...buttonProps
}: CustomerPortalRowActionsProps<T>) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(data)
    }
  }, [data, onClick])

  if (onClick) {
    return (
      <MetronicIconButton
        onClick={handleOnClick}
        {...buttonProps}
        variant='text'
        color='white'
        size='sm'
        activeColor={variant}
      />
    )
  }
  return null
}
