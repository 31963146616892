import {Link} from 'react-router-dom'
import {useHeaderLocation} from './useHeaderLocation'
import clsx from 'clsx'
import {uniqueId} from 'lodash'

export interface LinkType {
  title: string
  to: string
}
export interface PortalHeaderProps {
  links: LinkType[]
}

const PortalHeader = ({links}: PortalHeaderProps) => {
  const checkActive = useHeaderLocation()

  return (
    <>
      <div id='kt_header' className='px-3 mb-6'>
        <div id='kt_header' className='card mb-5 mb-xl-10'>
          <div className='card-body py-0 px-3'>
            <div id='kt_header' className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {links.map((link) => {
                  return (
                    <li key={uniqueId()} className='nav-item'>
                      <Link
                        className={clsx(`nav-link text-active-primary me-6 `, {
                          active: checkActive(link.to),
                        })}
                        to={link.to}
                      >
                        {link.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PortalHeader
