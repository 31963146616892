import {useMemo} from 'react'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {BookingModel} from '../../../../../models/ems/BookingModel'

import {bookingIsCancellable, bookingIsEditable} from './BookingTableSelectionActions'

export interface BookingTableActionsProps {
  onCancel?: (data: BookingModel) => void
  onEdit?: (data: BookingModel) => void
  data: BookingModel
  loading?: boolean
}

export const BookingTableRowActions = ({
  data,
  onEdit,
  onCancel,
  loading,
}: BookingTableActionsProps) => {
  const cancelButton = useMemo(() => {
    if (bookingIsCancellable(data)) {
      return (
        <RowAction
          loading={loading}
          data={data}
          iconType='Code'
          iconName='Stop'
          variant='danger'
          tooltip='Cancel Booking'
          onClick={onCancel}
        />
      )
    }
    return null
  }, [data, onCancel, loading])

  const editButton = useMemo(() => {
    if (bookingIsEditable(data)) {
      return (
        <RowAction
          loading={loading}
          data={data}
          iconType='Design'
          iconName='Edit'
          variant='warning'
          tooltip='Edit Booking'
          onClick={onEdit}
        />
      )
    }
    return null
  }, [data, onEdit, loading])

  return (
    <>
      {cancelButton}
      {editButton}
    </>
  )
}
