import clsx from 'clsx'
import {useMemo} from 'react'
import {ColorVariant} from '../../../components/inputs/Button'

type ButtonNativeProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export interface ButtonStyleProps {
  variant: ColorVariant
  light?: boolean
  border?: boolean
  rounded?: boolean
  big?: boolean
}

export interface ButtonProps extends ButtonStyleProps, ButtonNativeProps {}

export const Button = ({
  variant,
  light,
  border,
  rounded,
  className,
  big,
  ...buttonProps
}: ButtonProps) => {
  const classNames = useMemo(() => {
    return clsx(
      `btn`,
      {
        [`min-h-100px fs-1`]: big,
        [`btn-light-${variant}`]: light,
        [`btn-${variant}`]: !light,
        [`border border-${variant}`]: border,
        [`rounded-0`]: !rounded,
      },
      className
    )
  }, [border, className, light, rounded, variant, big])

  return <button className={classNames} {...buttonProps} />
}
