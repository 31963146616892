import clsx from 'clsx'
import {Button} from '../../../inputs/Button'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'

export interface CustomerInvalidDataModalProps {
  onSubmit: () => void
  open: boolean
  invalidEmails: string[] | []
  isLoading: boolean
  onHide: () => void
  isPortal?: boolean
}

export const CustomerInvalidDataModal = ({
  open,
  onSubmit,
  invalidEmails,
  isLoading,
  onHide,
  isPortal,
}: CustomerInvalidDataModalProps) => {
  return (
    <SweetAlert
      onClose={onHide}
      open={open}
      showConfirmButton={false}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      {/* {isPortal && <div className='swal2-corners' style={{width: '450px'}}></div>} */}
      <div className={clsx('swal2-icon swal2-warning swal2-icon-show', isPortal && 'mt-0 mb-5')}>
        <div className='swal2-icon-content ms-9'>!</div>
      </div>
      {isPortal && <div className='swal2-corners'></div>}
      <div className={clsx(isPortal && 'position-relative')}>
        <div className='d-flex flex-column'>
          <h3>Something went wrong with the following data.</h3>
        </div>
        <div className='d-flex my-4'>
          <div className='w-25'></div>
          <div className='w-50'>
            <ul>
              {invalidEmails.length
                ? invalidEmails.map((item, id) => (
                    <li key={id} className='text-start'>
                      {item}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
        <div className='w-25'></div>
        <h6>Do you wish to continue?</h6>
        <div className='swal2-actions d-flex justify-content-center gap-2 mt-5'>
          <Button variant='primary' onClick={onSubmit} disabled={isLoading}>
            <LoadingSpinner spinnerOnly loading={isLoading}></LoadingSpinner>
            OK
          </Button>
          <Button variant='danger' onClick={onHide}>
            CANCEL
          </Button>
        </div>
      </div>
    </SweetAlert>
  )
}
