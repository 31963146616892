import {FormikContextType} from 'formik'
import {useMemo} from 'react'
import type {CheckoutFormValues} from '.'
import {TextInput} from '../../../../components/inputs'
import {InputLabel} from '../../../../components/inputs/InputLabel'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {MobileNumberInput} from '../../../../components/inputs/MobileNumberInput'
import {useFormikMobileNumberInputHelpers} from '../../../../components/inputs/useFormikMobileNumberInputHelpers'
import {ConfirmPageLayout} from '../../layout/ConfirmPageLayout'

export interface DeliveryInformationProps {
  onBack: () => void
  onConfirm: () => void
  formik: FormikContextType<CheckoutFormValues>
}

export const DeliveryInformation = ({onBack, onConfirm, formik}: DeliveryInformationProps) => {
  const formHasErrors = useMemo(() => {
    return Boolean(
      formik.errors.customerName ||
        formik.errors.mobileNumber ||
        formik.errors.section ||
        formik.errors.row ||
        formik.errors.seat
    )
  }, [formik.errors])

  const mobileNumberInputHelpers = useFormikMobileNumberInputHelpers(formik)

  return (
    <ConfirmPageLayout
      disableButton={formik.isSubmitting || formHasErrors}
      onBackClick={onBack}
      onButtonClick={onConfirm}
      headerLabel='Delivery Info'
      buttonLabel='Checkout'
    >
      <TextInput
        {...formik.getFieldProps('customerName')}
        errorMessage={formik.errors.customerName}
        isTouched={formik.touched.customerName}
        label={
          <span className='d-flex align-items-center'>
            <MetronicIcon iconType='General' iconName='User' />
            <span>Customer Name</span>
          </span>
        }
        placeholder='Type your name'
      />
      <InputLabel>
        <span className='d-flex align-items-center'>
          <MetronicIcon iconType='Devices' iconName='Phone' />
          <span>Mobile Number</span>
        </span>
      </InputLabel>
      <p className='text-muted'>
        Please provide us your contact number in case we need to contact you for an update on your
        order.
      </p>
      <MobileNumberInput
        placeholder='Type your mobile number'
        {...mobileNumberInputHelpers.getFieldProps('mobileNumber')}
      />
      {formik.values.orderType === 'delivery' && (
        <>
          <TextInput
            {...formik.getFieldProps('section')}
            placeholder='Select Section'
            errorMessage={formik.errors.section}
            isTouched={formik.touched.section}
            label={
              <span className='d-flex align-items-center'>
                <MetronicIcon iconType='Layout' iconName='Layout-grid' />
                <span>Section</span>
              </span>
            }
          />
          <TextInput
            errorMessage={formik.errors.row}
            isTouched={formik.touched.row}
            {...formik.getFieldProps('row')}
            label={
              <span className='d-flex align-items-center'>
                <MetronicIcon iconType='Layout' iconName='Layout-horizontal' />
                <span>Row</span>
              </span>
            }
            placeholder='Type your seat row'
          />
          <TextInput
            errorMessage={formik.errors.seat}
            isTouched={formik.touched.seat}
            {...formik.getFieldProps('seat')}
            label={
              <span className='d-flex align-items-center'>
                <MetronicIcon iconType='Home' iconName='Chair2' />
                <span>Seat</span>
              </span>
            }
            placeholder='Type your seat number'
          />
        </>
      )}
    </ConfirmPageLayout>
  )
}
