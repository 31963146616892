import {useCallback, useMemo} from 'react'
import {useHistory, useRouteMatch, matchPath} from 'react-router-dom'
import {OutletOrderType} from '../../../models/fnb/OutletModel'
import {useCurrentOutlet} from './useCurrentOutlet'
import {OrderActionSidebarProps} from '../components/sidebars/OrderActionSidebar'

export const useOrderActionSidebarProps = () => {
  const outlet = useCurrentOutlet()
  const history = useHistory()
  const match = useRouteMatch()

  const handleNewOrder = useCallback(
    (orderType: OutletOrderType) => {
      history.push(`/orders/new?orderType=${orderType}`)
    },
    [history]
  )

  const handleViewOrders = useCallback(() => {
    history.push(`/orders`)
  }, [history])

  const handleViewDashboard = useCallback(() => {
    history.push(`/overview`)
  }, [history])

  const activePage = useMemo(() => {
    if (matchPath(match.url, {path: '/overview'})) {
      return 'dashboard'
    }
    if (matchPath(match.url, {path: '/orders/new'})) {
      return 'order-create'
    }
    if (matchPath(match.url, {path: '/orders'})) {
      return 'order-list'
    }
  }, [match.url])

  const getProps = useCallback(
    (): OrderActionSidebarProps => ({
      onDashboardClick: handleViewDashboard,
      onNewOrder: handleNewOrder,
      onViewOrdersClick: handleViewOrders,
      orderTypes: outlet?.orderType || [],
      disableDashboardButton: activePage === 'dashboard',
      disableNewOrderButton: activePage === 'order-create',
      disableOrdersButton: activePage === 'order-list',
    }),
    [handleViewDashboard, handleNewOrder, handleViewOrders, outlet?.orderType, activePage]
  )

  return useMemo(() => ({getProps}), [getProps])
}
