import {EventModel} from '../../../models/ems/EventModel'
import {WidgetModel} from '../../../models/customer-portal/WidgetModel'
import {useEventDashboardWidgetData} from '../hooks/useEventDashboardWidgetData'
import {DashboardCards} from '../components/EventDashboardProductVoucherCards/DashbordsCards'
import {useMemo} from 'react'
import {DashboardPercentageChart} from '../components/EventDashboardBarCharts/DashboardPercentageChart'
// import {DashboardBarChart} from '../components/EventDashboardBarCharts/DashboardBarChart'
import {Button} from '../../../components/inputs/Button'
// import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {useCallback} from 'react'
// import {useHistory} from 'react-router-dom'
import {DownloadReports} from '../redux/CustomerPortalCRUD'
import {BlobUtils} from '../../../utils/BlobUtils'
import {useAlerts} from '../../../components/alerts/useAlerts'
import moment from 'moment'
import {ProdCatDashboardCard} from '../components/EventDashboardProductCategoryCards/DashboardCard'
export interface DashboardProps {
  event?: EventModel | null
}

export const EventOverview = ({event}: DashboardProps) => {
  const {data: eventData} = useEventDashboardWidgetData({
    widgets: PORTAL_WIDGETS,
    eventCode: event?.code,
  })

  // const history = useHistory()
  const {push, pushError} = useAlerts()
  const bookingsChart = useMemo(() => {
    return (
      <>
        {/* {eventData.WIDGETP06 && <DashboardRingChart data={eventData.WIDGETP06} label='Bookings' />} */}
        {eventData.WIDGETP07 && (
          <DashboardPercentageChart data={eventData.WIDGETP07} label='Tickets' />
        )}
      </>
    )
  }, [eventData.WIDGETP07])


  // const usedProducts = useMemo(() => {
  //   if (eventData.WIDGETP05)
  //     return eventData.WIDGETP05.products.filter(
  //       (product) => product.used > 0 && product?.productCategory?.slug === 'INCLUSIONS'
  //     )
  // }, [eventData.WIDGETP05])

  // const usedVouchers = useMemo(() => {
  //   if (eventData.WIDGETP05)
  //     return eventData.WIDGETP05.vouchers.filter((voucher) => voucher.used > 0)
  // }, [eventData.WIDGETP05])

  // const distributedVouchersBarGraph = useMemo(() => {
  //   return (
  //     <>
  //       {eventData.WIDGETP05 && (
  //         <DashboardBarChart
  //           datum={usedVouchers}
  //           label='Distributed Vouchers'
  //           isFirstColumn={true}
  //         />
  //       )}
  //     </>
  //   )
  // }, [eventData.WIDGETP05, usedVouchers])

  // const distributedTicketsBarGraph = useMemo(() => {
  //   return (
  //     <>
  //       {eventData.WIDGETP05 && (
  //         <DashboardBarChart datum={usedProducts} label='Ticket Inclusions' isFirstColumn={false} />
  //       )}
  //     </>
  //   )
  // }, [eventData.WIDGETP05, usedProducts])

  const handleDownloadReport = useCallback(async () => {
    if (event?.code) {
      try {
        const {
          data,
          //  headers
        } = await DownloadReports(event.code)
        if (data) {
          // let name = headers['content-disposition']
          // console.log('headers', name)
          // name = name
          //   .replace('attachment;', '')
          //   .replace('filename=', '')
          //   .replaceAll('"', '')
          //   .split('(')[0]
          //   .trim()
          const name = `${event.name.replaceAll(' ', '_')}_Ticket_Details_${moment().format(
            'YYYY-MM-DD'
          )}`
          BlobUtils.downloadBlob(data, name)
        }
        push({
          message: `Successfully downloaded.`,
          timeout: 5000,
          variant: 'success',
        })
      } catch (e: any) {
        pushError(e)
      }
    }
  }, [event?.code, event?.name, push, pushError])

  // const handleImportCustomer = useCallback(() => {
  //   history.push(`/customer/import`)
  // }, [history])

  return (
    <div>
      <div className='row mb-6 g-6'>
        <div className='d-flex gap-5'>
          <Button variant='primary' onClick={handleDownloadReport}>
            {/* <MetronicIcon iconType='Navigation' iconName='Plus' /> New Customer */}
            Download Reports
          </Button>
          {/* <Button variant='text' className='btn-primary' onClick={handleImportCustomer}>           
            Import customers
          </Button> */}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>{bookingsChart}</div>
        <div className='col-md-6 mt-6'>
          {eventData.WIDGETP08 && <ProdCatDashboardCard items={eventData.WIDGETP08} />}
        </div>
      </div>
      <div className='row'>
        {eventData.WIDGETP05 && <DashboardCards productsVoucers={eventData.WIDGETP05} />}
      </div>
    </div>
  )
}

const PORTAL_WIDGETS: (keyof WidgetModel)[] = ['WIDGETP05', 'WIDGETP06', 'WIDGETP07', 'WIDGETP08']
