import {ReactNode, useCallback} from 'react'
import {Button} from '../../../../components/inputs/Button'
import {LoadingSpinner} from '../../../../components/utils/LoadingSpinner'

export interface ImportSectionBaseActionsProps {
  name: string
  onFinalizeImport: () => void
  onImportClick: () => void
  hasImported: boolean
  isLoading: boolean
  children?: ReactNode
}

export const ImportSectionBaseActions = ({
  name,
  onFinalizeImport,
  onImportClick,
  hasImported,
  isLoading,
  children,
}: ImportSectionBaseActionsProps) => {
  const handleImportClick = useCallback(() => {
    onImportClick()
  }, [onImportClick])

  return (
    <>
      <div className='row g-3'>
        {children}
        <div className='col'>
          <Button
            className='w-100 h-100'
            disabled={isLoading}
            onClick={handleImportClick}
            variant='primary'
          >
            <LoadingSpinner loading={isLoading}>Import {name}</LoadingSpinner>
          </Button>
        </div>
        <div className='col'>
          <Button
            variant='warning'
            className='w-100 h-100'
            disabled={!hasImported}
            onClick={onFinalizeImport}
          >
            <LoadingSpinner loading={isLoading}>Finalize Import</LoadingSpinner>
          </Button>
        </div>
      </div>
    </>
  )
}
