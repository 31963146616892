import clsx from 'clsx'

export interface SpinnerProps {
  hide?: boolean
  size?: 'sm' | 'lg'
  className?: string
}
export const Spinner = ({hide, size = 'sm', className}: SpinnerProps) => {
  if (hide) {
    return null
  }
  return (
    <span className={clsx(`spinner-border spinner-border-${size} align-middle`, className)}></span>
  )
}
