import {ReactNode, useCallback, useMemo} from 'react'
import {MultiSelectInput} from '../../../../../components/inputs/MultiSelectInput/MultiSelectInput'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'

export interface MultiSelectToggleFilterInputMobileProps {
  label: string
  items: SelectInputItem[]
  values: string[]
  endAdornment?: ReactNode
  setValues: (data: string[]) => void
  isLabelUpperCase?: boolean
  inputClassName?: string
}

export const MultiSelectToggleFilterInputMobile = ({
  items,
  label,
  values,
  endAdornment,
  setValues,
  isLabelUpperCase,
  inputClassName,
}: MultiSelectToggleFilterInputMobileProps) => {
  const value = useMemo(() => {
    if (values && Array.isArray(values)) {
      return values
    }
    return []
  }, [values])

  const handleChange = useCallback(
    (values: string[]) => {
      if (values && values.length) {
        setValues(values)
      } else {
        setValues([])
      }
    },
    [setValues]
  )

  return (
    <MultiSelectInput
      endAdornment={endAdornment}
      hasSelectAll
      placeholder={`Filter ${label}`}
      items={items}
      label={label}
      value={value}
      onChange={handleChange}
      isLabelUpperCase={isLabelUpperCase}
      inputClassName={inputClassName}
    />
  )
}
