import {FormikContextType} from 'formik'
import {get} from 'lodash'
import {useCallback} from 'react'

export const useTranslationFieldHelpers = <T>(formik: FormikContextType<T>, lang?: string) => {
  const getTranslationTextFieldProps = useCallback(
    (field: string) => {
      const fieldName = lang ? `translations.${lang}.${field}` : field

      const fieldProps = formik.getFieldProps(fieldName)
      return {
        ...fieldProps,
        value: fieldProps.value || '',
        errorMessage: get(formik.errors, fieldName) || '',
        isTouched: get(formik.touched, fieldName) || '',
      }
    },
    [formik, lang]
  )

  return {getTranslationTextFieldProps}
}
