import {useEffect} from 'react'
import {usePrevious} from './usePrevious'

export type UseOnChangeCallback<T> = (previousValue: T | undefined, currentValue: T) => void

export const useOnChange = <T>(currentValue: T, callback: UseOnChangeCallback<T>) => {
  const previousValue = usePrevious<T>(currentValue)

  useEffect(() => {
    if (previousValue !== currentValue) {
      callback(previousValue, currentValue)
    }
  }, [callback, currentValue, previousValue])
}
