import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {FilterModel} from '../../../models/FilterModel'
import {actions} from '../redux/CustomerPortalRedux'
import {CustomerModel} from '../../../models/CustomerModel'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {ReservationTable} from '../components/tables/ReservationTable/ReservationTable'
import {EventModel} from '../../../models/ems/EventModel'
import {useHistory, useRouteMatch} from 'react-router-dom'
// import {ReservationFormCreate} from '../components/DrawerForm/ReservationFormDrawer'
// import {useDrawerRef} from '../../../components/Drawer/useDrawerRef'

interface ReservationPageProps {
  customer?: CustomerModel
  event?: EventModel
}
interface RouteMatch {
  reservationCode?: string
  customerCode?: string
  eventCode?: string
}
export const ReservationPage = ({customer, event}: ReservationPageProps) => {
  const reservations = useRootStateSelector((state) => state.customerPortal.reservations)
  const history = useHistory()
  const match = useRouteMatch<RouteMatch>()

  // const [drawerCreate, setDrawerCreate] = useDrawerRef()
  const searchDebounce = useDebounce(200)
  const {setFilter} = useEntityFilter('customer-portal-reservation')
  const dispatch = useDispatch()

  const refresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.reservations.search())
    })
  }, [dispatch, searchDebounce])

  const handleFilter = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refresh()
    },
    [setFilter, refresh]
  )

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active', 'released'],
      },
      groupBy: '',
    }
    if (event && filters.filters) {
      filters.filters.event = event.code
    }

    return filters
  }, [event])

  const handleCreateRecord = useCallback(() => {
    history.push(`${match.url}/new`)
  }, [history, match.url])

  return (
    <>
      {!customer && <PageTitle>Reservations</PageTitle>}
      <ReservationTable
        onRefresh={refresh}
        // onNewReservationClick={drawerCreate?.show}
        onNewReservationClick={handleCreateRecord}
        className='rs-table-bg'
        data={reservations}
        onFilter={handleFilter}
        event={event}
        initialFilters={initialFilters}
        cornersClassName='table-cut-corner-polygon rs-gradient'
        customWrapper='rs-top'
        paginationClassName='px-10 pb-10 rs-pagination'
      />

      {/* <ReservationFormCreate drawerRef={setDrawerCreate} onChange={refresh} event={event} /> */}
    </>
  )
}
