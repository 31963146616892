import {useMemo} from 'react'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {billingIsDownloadable} from './BillingHistoryTableActions'

export interface BillingHistoryTableRowActionsProps {
  onDownload?: (data?: BookingModel) => void //change to billing history model
  data: BookingModel //change to billing history model
  loading?: boolean
}
export const BillingHistoryTableRowActions = ({
  onDownload,
  data,
  loading,
}: BillingHistoryTableRowActionsProps) => {
  const downloadIcon = useMemo(() => {
    if (billingIsDownloadable(data)) {
      return (
        <RowAction
          loading={loading}
          data={data}
          iconType='Files'
          iconName='Download'
          variant='warning'
          tooltip='Download invoice'
          onClick={onDownload}
        />
      )
    }
    return null
  }, [data, loading, onDownload])

  return <>{downloadIcon}</>
}
