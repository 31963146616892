import clsx from 'clsx'
import {useMemo} from 'react'
import {HorizontalDivider} from '../../../components/utils/HorizontalDivider'
import {Cart} from '../../../utils/Cart'
import {PriceCalculator} from '../../../utils/PriceCalculator'

export interface PaymentSummaryProps {
  cart: Cart
  vatPercent?: number
  className?: string
  paymentRemaining?: number
  fontSize?: number
}

export const PaymentSummary = ({
  className,
  cart,
  vatPercent,
  paymentRemaining,
  fontSize = 4,
}: PaymentSummaryProps) => {
  const vatPrice = useMemo(() => {
    return cart.getVatPrice()
  }, [cart])

  const subtotal = useMemo(() => {
    return cart.getSubTotalVatInclusive()
  }, [cart])

  const roundedTotal = useMemo(() => {
    return cart.getTotalVatInclusive()
  }, [cart])

  const vatLabel = useMemo(() => {
    if (vatPercent) {
      return `VAT ${vatPercent * 100}%`
    }
    return 'VAT'
  }, [vatPercent])

  const fontSizeClassName = useMemo(() => {
    return `fs-${fontSize}`
  }, [fontSize])

  return (
    <div className={clsx(className, fontSizeClassName)}>
      {vatPrice > 0 && (
        <>
          <div>
            <p className='d-flex justify-content-between align-items-end'>
              <span className='fw-bold'>Sub total</span>
              <span>{subtotal}</span>
            </p>
            <p className='d-flex justify-content-between align-items-end'>
              <span className='fw-bold'>{vatLabel}</span>
              <span>{vatPrice}</span>
            </p>
          </div>
          <HorizontalDivider />
        </>
      )}
      <div className='d-flex justify-content-between align-items-end'>
        <span className='fw-bold'>Total</span>
        <span>{roundedTotal}</span>
      </div>
      {typeof paymentRemaining === 'number' && (
        <>
          <HorizontalDivider />
          <div>
            <div className='d-flex justify-content-between'>
              <span className='fw-bold'>Paid</span>
              <span>
                {new PriceCalculator(cart.getTotalVatInclusive())
                  .subtract(paymentRemaining || 0)
                  .getValue()}
              </span>
            </div>
            <div className='d-flex justify-content-between'>
              <span className='fw-bold'>Remaining</span>
              <span>{paymentRemaining}</span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
