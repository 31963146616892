import clsx from 'clsx'
import {useMemo} from 'react'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {getWaiverStatusVariant} from '../DigitalWaiverCard/DigitalWaiverCard'

export interface WaiverPortalStatusColumnProps {
  data: TicketWaiverModel
  className?: string
  tabCode?: string
}

export const WaiverPortalStatusColumn = ({
  data,
  className,
  tabCode,
}: WaiverPortalStatusColumnProps) => {
  const variant = useMemo(() => {
    return getWaiverStatusVariant({data, tabCode})
  }, [data, tabCode])

  return (
    <span
      style={{
        color: variant,
      }}
      className={clsx(`text-capitalize`, className)}
    >
      {/* {data.statusPortal === 'active' ? 'available' : data.statusPortal} */}
      Awaiting submission
    </span>
  )
}
