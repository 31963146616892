import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {FilterModel} from '../../../models/FilterModel'
import {AuthModel} from '../../../models/fnb/AuthModel'
import {OrderModel, OrderModelCreateParams} from '../../../models/fnb/OrderModel'
import {OutletLogModel} from '../../../models/fnb/OutletLogModel'
import {OutletModel} from '../../../models/fnb/OutletModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../models/fnb/ProductCategoryModel'
import {ProductModel, ProductModelCreateParams} from '../../../models/fnb/ProductModel'
import {ReservationModel} from '../../../models/fnb/ReservationModel'
import {UserModel, UserModelCreateParams} from '../../../models/fnb/UserModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {
  GetOrderList,
  GetOrders,
  GetProductCategories,
  GetProducts,
  GetUsers,
  SearchOutletLogs,
  SearchTableReservations,
  VerifyOutletAuthToken,
} from './OutletCRUD'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface OutletAuthState {
  user?: UserModel
  token?: string
}

export interface IOutletState {
  auth?: OutletAuthState
  productCategories?: GlobalSearchModel<ProductCategoryModel>
  orders?: GlobalSearchModel<OrderModel>
  products?: GlobalSearchModel<ProductModel>
  users?: GlobalSearchModel<UserModel>
  outletLogs?: GlobalSearchModel<OutletLogModel>
  allOrders?: OrderModel[]
  reservations?: GlobalSearchModel<ReservationModel>
}

const initialAuthState: IOutletState = {}

export const reducer = persistReducer(
  {storage, key: 'outlet', whitelist: ['auth']},
  (state: IOutletState = initialAuthState, action: ActionWithPayload<IOutletState>) => {
    switch (action.type) {
      case actionTypes.auth.login: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.requestAuth: {
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.logout: {
        return {
          ...state,
          auth: undefined,
        }
      }
      case actionTypes.auth.fulfillAuth: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: auth?.user,
          },
        }
      }
      case actionTypes.products.search.success: {
        const products = action.payload?.products
        return {...state, products}
      }
      case actionTypes.productCategories.search.success: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }
      case actionTypes.users.search.success: {
        const users = action.payload?.users
        return {...state, users}
      }
      case actionTypes.orders.search.success: {
        const orders = action.payload?.orders
        return {...state, orders}
      }
      case actionTypes.orders.getAll.success: {
        const allOrders = action.payload?.allOrders
        return {...state, allOrders}
      }
      case actionTypes.outletLogs.search.success: {
        const outletLogs = action.payload?.outletLogs
        return {...state, outletLogs}
      }
      case actionTypes.reservations.search.success: {
        const reservations = action.payload?.reservations
        return {...state, reservations}
      }
      default:
        return state
    }
  }
)

export function* saga() {
  yield takeLatest(actionTypes.auth.login, function* () {
    yield put(actions.auth.requestAuth())
  })
  yield takeLatest(actionTypes.auth.requestAuth, function* () {
    const {data} = yield VerifyOutletAuthToken()
    yield put(actions.auth.fulfillUser(data.user))
  })

  yield takeLatest(
    [
      actionTypes.productCategories.search.execute,
      actionTypes.productCategories.create.success,
      actionTypes.productCategories.update.success,
      actionTypes.productCategories.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['outlet-product-category']
        )
        const {data} = yield GetProductCategories(filter)
        yield put(actions.productCategories.searchSuccess(data))
      } catch (e) {
        yield put(actions.productCategories.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.products.search.execute,
      actionTypes.products.create.success,
      actionTypes.products.update.success,
      actionTypes.products.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['outlet-product'])
        const {data} = yield GetProducts(filter)
        yield put(actions.products.searchSuccess(data))
      } catch (e) {
        yield put(actions.products.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.users.search.execute,
      actionTypes.users.create.success,
      actionTypes.users.update.success,
      actionTypes.users.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['outlet-user'])
        const {data} = yield GetUsers(filter)
        yield put(actions.users.searchSuccess(data))
      } catch (e) {
        yield put(actions.users.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.orders.search.execute,
      actionTypes.orders.create.success,
      actionTypes.orders.update.success,
      actionTypes.orders.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['outlet-order'])
        const {data} = yield GetOrderList(filter)
        yield put(actions.orders.searchSuccess(data))
      } catch (e) {
        yield put(actions.orders.searchFailed())
      }
    }
  )

  yield takeLatest([actionTypes.orders.getAll.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['outlet-all-order'])
      const {data} = yield GetOrders(filter)
      yield put(actions.orders.getAllSuccess(data))
    } catch (e) {
      yield put(actions.orders.getAllFailed())
    }
  })

  yield takeLatest(
    [
      actionTypes.reservations.search.execute,
      actionTypes.reservations.create.success,
      actionTypes.reservations.update.success,
      actionTypes.reservations.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select(
          (state) => state.system.filters['outlet-reservation']
        )
        const {data} = yield SearchTableReservations(filter)
        yield put(actions.reservations.searchSuccess(data))
      } catch (e) {
        yield put(actions.reservations.searchFailed())
      }
    }
  )

  yield takeLatest(
    [
      actionTypes.outletLogs.search.execute,
      actionTypes.outletLogs.create.success,
      actionTypes.outletLogs.update.success,
      actionTypes.outletLogs.delete.success,
    ],
    function* refresh() {
      try {
        const filter: FilterModel = yield select((state) => state.system.filters['outlet-log'])
        const {data} = yield SearchOutletLogs(filter)
        yield put(actions.outletLogs.searchSuccess(data))
      } catch (e) {
        yield put(actions.outletLogs.searchFailed())
      }
    }
  )
}

export const actions = {
  auth: {
    login: (token: string) => ({type: actionTypes.auth.login, payload: {auth: {token}}}),
    logout: () => ({type: actionTypes.auth.logout}),
    requestAuth: () => ({type: actionTypes.auth.requestAuth}),
    fulfillUser: (user: AuthModel) => ({
      type: actionTypes.auth.fulfillAuth,
      payload: {
        auth: {
          user,
        },
      },
    }),
  },
  products: {
    search: () => ({type: actionTypes.products.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ProductModel>) => ({
      type: actionTypes.products.search.success,
      payload: {products: data},
    }),
    searchFailed: () => ({type: actionTypes.products.search.failed}),

    create: (payload: ProductModelCreateParams) => ({
      type: actionTypes.products.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.products.create.success}),
    createFailed: () => ({type: actionTypes.products.create.failed}),

    update: (payload: ProductModelCreateParams) => ({
      type: actionTypes.products.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.products.update.success}),
    updateFailed: () => ({type: actionTypes.products.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.products.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.products.delete.success}),
    deleteFailed: () => ({type: actionTypes.products.delete.failed}),
  },
  productCategories: {
    search: () => ({type: actionTypes.productCategories.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ProductCategoryModel>) => ({
      type: actionTypes.productCategories.search.success,
      payload: {productCategories: data},
    }),
    searchFailed: () => ({type: actionTypes.productCategories.search.failed}),

    create: (payload: ProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.productCategories.create.success}),
    createFailed: () => ({type: actionTypes.productCategories.create.failed}),

    update: (payload: ProductCategoryModelCreateParams) => ({
      type: actionTypes.productCategories.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.productCategories.update.success}),
    updateFailed: () => ({type: actionTypes.productCategories.update.failed}),

    delete: (codes: string[]) => ({
      type: actionTypes.productCategories.delete.execute,
      payload: codes,
    }),
    deleteSuccess: () => ({type: actionTypes.productCategories.delete.success}),
    deleteFailed: () => ({type: actionTypes.productCategories.delete.failed}),
  },
  users: {
    search: () => ({type: actionTypes.users.search.execute}),
    searchSuccess: (data: GlobalSearchModel<UserModel>) => ({
      type: actionTypes.users.search.success,
      payload: {users: data},
    }),
    searchFailed: () => ({type: actionTypes.users.search.failed}),

    create: (payload: UserModelCreateParams) => ({
      type: actionTypes.users.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.users.create.success}),
    createFailed: () => ({type: actionTypes.users.create.failed}),

    update: (payload: UserModelCreateParams) => ({
      type: actionTypes.users.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.users.update.success}),
    updateFailed: () => ({type: actionTypes.users.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.users.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.users.delete.success}),
    deleteFailed: () => ({type: actionTypes.users.delete.failed}),
  },
  orders: {
    getAll: () => ({type: actionTypes.orders.getAll.execute}),
    getAllSuccess: (data: OrderModel[]) => ({
      type: actionTypes.orders.getAll.success,
      payload: {allOrders: data},
    }),
    getAllFailed: () => ({type: actionTypes.orders.getAll.failed}),

    search: () => ({type: actionTypes.orders.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OrderModel>) => ({
      type: actionTypes.orders.search.success,
      payload: {orders: data},
    }),
    searchFailed: () => ({type: actionTypes.orders.search.failed}),

    create: (payload: OrderModelCreateParams) => ({
      type: actionTypes.orders.create.execute,
      payload,
    }),
    createSuccess: () => ({type: actionTypes.orders.create.success}),
    createFailed: () => ({type: actionTypes.orders.create.failed}),

    update: (payload: OrderModelCreateParams) => ({
      type: actionTypes.orders.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.orders.update.success}),
    updateFailed: () => ({type: actionTypes.orders.update.failed}),

    delete: (codes: string[]) => ({type: actionTypes.orders.delete.execute, payload: codes}),
    deleteSuccess: () => ({type: actionTypes.orders.delete.success}),
    deleteFailed: () => ({type: actionTypes.orders.delete.failed}),
  },
  outletLogs: {
    search: () => ({type: actionTypes.outletLogs.search.execute}),
    searchSuccess: (data: GlobalSearchModel<OutletModel>) => ({
      type: actionTypes.outletLogs.search.success,
      payload: {outletLogs: data},
    }),
    searchFailed: () => ({type: actionTypes.outletLogs.search.failed}),
  },
  reservations: {
    search: () => ({type: actionTypes.reservations.search.execute}),
    searchSuccess: (data: GlobalSearchModel<ReservationModel>) => ({
      type: actionTypes.reservations.search.success,
      payload: {reservations: data},
    }),
    searchFailed: () => ({type: actionTypes.reservations.search.failed}),

    update: (payload: OrderModelCreateParams) => ({
      type: actionTypes.reservations.update.execute,
      payload,
    }),
    updateSuccess: () => ({type: actionTypes.reservations.update.success}),
    updateFailed: () => ({type: actionTypes.reservations.update.failed}),
  },
}

export const actionTypes = {
  auth: {
    login: '[Outlet] LOGIN',
    logout: '[Outlet] LOGOUT',
    requestAuth: '[Outlet] REQUEST AUTH',
    fulfillAuth: '[Outlet] FULFILL AUTH',
  },
  products: {
    search: {
      execute: '[Outlet] SEARCH PRODUCT',
      success: '[Outlet] SEARCH PRODUCT SUCCESS',
      failed: '[Outlet] SEARCH PRODUCT FAILED',
    },
    create: {
      execute: '[Outlet] CREATE PRODUCT',
      success: '[Outlet] CREATE PRODUCT SUCCESS',
      failed: '[Outlet] CREATE PRODUCT FAILED',
    },
    update: {
      execute: '[Outlet] UPDATE PRODUCT',
      success: '[Outlet] UPDATE PRODUCT SUCCESS',
      failed: '[Outlet] UPDATE PRODUCT FAILED',
    },
    delete: {
      execute: '[Outlet] DELETE PRODUCT',
      success: '[Outlet] DELETE PRODUCT SUCCESS',
      failed: '[Outlet] DELETE PRODUCT FAILED',
    },
  },
  productCategories: {
    search: {
      execute: '[Outlet] SEARCH PRODUCT CATEGORY',
      success: '[Outlet] SEARCH PRODUCT CATEGORY SUCCESS',
      failed: '[Outlet] SEARCH PRODUCT CATEGORY FAILED',
    },
    create: {
      execute: '[Outlet] CREATE PRODUCT CATEGORY',
      success: '[Outlet] CREATE PRODUCT CATEGORY SUCCESS',
      failed: '[Outlet] CREATE PRODUCT CATEGORY FAILED',
    },
    update: {
      execute: '[Outlet] UPDATE PRODUCT CATEGORY',
      success: '[Outlet] UPDATE PRODUCT CATEGORY SUCCESS',
      failed: '[Outlet] UPDATE PRODUCT CATEGORY FAILED',
    },
    delete: {
      execute: '[Outlet] DELETE PRODUCT CATEGORY',
      success: '[Outlet] DELETE PRODUCT CATEGORY SUCCESS',
      failed: '[Outlet] DELETE PRODUCT CATEGORY FAILED',
    },
  },
  users: {
    search: {
      execute: '[Outlet] SEARCH USER',
      success: '[Outlet] SEARCH USER SUCCESS',
      failed: '[Outlet] SEARCH USER FAILED',
    },
    create: {
      execute: '[Outlet] CREATE USER',
      success: '[Outlet] CREATE USER SUCCESS',
      failed: '[Outlet] CREATE USER FAILED',
    },
    update: {
      execute: '[Outlet] UPDATE USER',
      success: '[Outlet] UPDATE USER SUCCESS',
      failed: '[Outlet] UPDATE USER FAILED',
    },
    delete: {
      execute: '[Outlet] DELETE USER',
      success: '[Outlet] DELETE USER SUCCESS',
      failed: '[Outlet] DELETE USER FAILED',
    },
  },
  orders: {
    getAll: {
      execute: '[Outlet] SEARCH ALL ORDER',
      success: '[Outlet] SEARCH ALL ORDER SUCCESS',
      failed: '[Outlet] SEARCH ALL ORDER FAILED',
    },
    search: {
      execute: '[Outlet] SEARCH ORDER',
      success: '[Outlet] SEARCH ORDER SUCCESS',
      failed: '[Outlet] SEARCH ORDER FAILED',
    },
    create: {
      execute: '[Outlet] CREATE ORDER',
      success: '[Outlet] CREATE ORDER SUCCESS',
      failed: '[Outlet] CREATE ORDER FAILED',
    },
    update: {
      execute: '[Outlet] UPDATE ORDER',
      success: '[Outlet] UPDATE ORDER SUCCESS',
      failed: '[Outlet] UPDATE ORDER FAILED',
    },
    delete: {
      execute: '[Outlet] DELETE ORDER',
      success: '[Outlet] DELETE ORDER SUCCESS',
      failed: '[Outlet] DELETE ORDER FAILED',
    },
  },
  outletLogs: {
    search: {
      execute: '[Outlet] SEARCH OUTLET LOG',
      success: '[Outlet] SEARCH OUTLET LOG SUCCESS',
      failed: '[Outlet] SEARCH OUTLET LOG FAILED',
    },
    create: {
      execute: '[Outlet] CREATE OUTLET LOG',
      success: '[Outlet] CREATE OUTLET LOG SUCCESS',
      failed: '[Outlet] CREATE OUTLET LOG FAILED',
    },
    update: {
      execute: '[Outlet] UPDATE OUTLET LOG',
      success: '[Outlet] UPDATE OUTLET LOG SUCCESS',
      failed: '[Outlet] UPDATE OUTLET LOG FAILED',
    },
    delete: {
      execute: '[Outlet] DELETE OUTLET LOG',
      success: '[Outlet] DELETE OUTLET LOG SUCCESS',
      failed: '[Outlet] DELETE OUTLET LOG FAILED',
    },
  },
  reservations: {
    search: {
      execute: '[Outlet] SEARCH TABLE RESERVATION',
      success: '[Outlet] SEARCH TABLE RESERVATION SUCCESS',
      failed: '[Outlet] SEARCH TABLE RESERVATION FAILED',
    },
    create: {
      execute: '[Outlet] CREATE TABLE RESERVATION',
      success: '[Outlet] CREATE TABLE RESERVATION SUCCESS',
      failed: '[Outlet] CREATE TABLE RESERVATION FAILED',
    },
    update: {
      execute: '[Outlet] UPDATE TABLE RESERVATION',
      success: '[Outlet] UPDATE TABLE RESERVATION SUCCESS',
      failed: '[Outlet] UPDATE TABLE RESERVATION FAILED',
    },
    delete: {
      execute: '[Outlet] DELETE TABLE RESERVATION',
      success: '[Outlet] DELETE TABLE RESERVATION SUCCESS',
      failed: '[Outlet] DELETE TABLE RESERVATION FAILED',
    },
  },
}
