import {useEffect, useCallback, useRef} from 'react'

export const useSafeStateUpdate = () => {
  const isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])
  const safeUpdate = useCallback((func: () => void) => {
    if (isMounted.current) {
      func()
    }
  }, [])
  return safeUpdate
}
