import moment from 'moment'
import {useMemo} from 'react'
import { TIMEZONE_OFFSET } from '../../../../config/env'

export interface DateTimeFormatterProps {
  className?: string
  children: Date | string
  ampm?: boolean
}

export const DateTimeFormatter = ({ children, className, ampm }: DateTimeFormatterProps) => {
  const dateString = useMemo(() => {
    let momentDate = moment(children)
  
    if (ampm) {
      return momentDate.utcOffset(TIMEZONE_OFFSET || '+04:00').format('MMM DD, YYYY h:mm A')
    }
    return momentDate.utcOffset(TIMEZONE_OFFSET || '+04:00').format('MMM DD, YYYY HH:mm')
  }, [ampm, children])
  return <time className={className}>{dateString}</time>
}
