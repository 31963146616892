import {LegacyRef, UIEventHandler} from 'react'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {CustomerModel} from '../../../../models/CustomerModel'
import {CustomerListItem} from './CustomerListItem'

export interface CustomerListProps {
  customers: CustomerModel[]
  onClick?: (item: CustomerModel) => void
  containerRef?: LegacyRef<HTMLDivElement>
  onScroll?: UIEventHandler<HTMLDivElement>
  className?: string
}

export const CustomerList = ({
  customers,
  onClick,
  containerRef,
  onScroll,
  className,
}: CustomerListProps) => {
  return (
    <div ref={containerRef} onScroll={onScroll} className={className}>
      {customers.map((customer, index, array) => {
        const isLast = index === array.length - 1
        return (
          <div key={customer.code}>
            <CustomerListItem key={customer.code} customer={customer} onClick={onClick} />
            {!isLast && <HorizontalDivider />}
          </div>
        )
      })}
    </div>
  )
}
