import {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {ForgotPasswordForm, ForgotPasswordFormValues} from './ForgotPasswordForm'
import {RequestForgottenPassword} from '../../redux/PokenCRUD'

export function ForgotPassword() {
  const history = useHistory()

  const handleSubmit = useCallback(async (values: ForgotPasswordFormValues) => {
    await RequestForgottenPassword(values.email)
  }, [])

  const logout = useCallback(() => {
    history.push('/auth/login')
  }, [history])

  return <ForgotPasswordForm onSubmit={handleSubmit} onCancel={logout} />
}
