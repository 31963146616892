import {useCallback, useMemo} from 'react'
import chroma from 'chroma-js'

export const useColorScale = (length: number, colors: string[]) => {
  const createColorScale = useCallback(
    (length: number) => {
      const scale = chroma.scale(colors).domain([0, length - 1])
      return (index: number) => {
        return scale(index).hex()
      }
    },
    [colors]
  )

  const colorArray = useMemo(() => {
    const colors = []
    const getColorScale = createColorScale(length)

    for (let i = 0; i < length; i++) {
      colors.push(getColorScale(i))
    }

    return colors
  }, [createColorScale, length])

  return useMemo(
    () => ({
      colorArray,
    }),
    [colorArray]
  )
}
