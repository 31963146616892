import clsx from 'clsx'
import {Button} from '../../../../components/inputs/Button'
import {SweetAlert} from '../../../../components/modals/SweetAlert'

export interface NotEnoughBalanceProps {
  balance: number
  currency: string
  open: boolean
  onClose: () => void
  onCancel: () => void
  onUseAnother: () => void
  onAddAnother: () => void
}

export const NotEnoughBalanceModal = ({
  onClose,
  open,
  balance,
  currency,
  onCancel,
  onUseAnother,
  onAddAnother,
}: NotEnoughBalanceProps) => {
  return (
    <SweetAlert open={open} onClose={onClose} icon='error' showConfirmButton={false}>
      <h1>Not enough balance</h1>
      <p>
        Your YasEats cash card does not have enough balance to cover the total payment amount.
        Kindly use another payment method.
      </p>
      <p className='fs-4 text-danger m-0'>Current Balance</p>
      <p className='text-danger'>
        {currency} {balance}
      </p>
      <div className='container'>
        <div className='row'>
          {balance ? (
            <div className='col-6'>
              <Button className='fs-3' onClick={onAddAnother} variant='info'>
                Use Voucher
              </Button>
            </div>
          ) : null}
          <div className={clsx({'col-6': Boolean(balance)})}>
            <Button className='fs-3' onClick={onUseAnother} variant='primary'>
              Change Voucher
            </Button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Button className='fs-3 mt-1 text-nowrap' onClick={onCancel} variant='light'>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </SweetAlert>
  )
}
