import {useState, useCallback, useMemo} from 'react'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {Button} from '../../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../../components/inputs/MetronicIcon'
import {FilterTable} from '../../../../../components/tables/FilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {HasCode, idExtractor} from '../../../../../utils/idExtractor'
import {SwalUtils} from '../../../../../utils/SwalUtils'
import {DeleteCustomer} from '../../../redux/CustomerDelegateCRUD'
import {TableAvatarImageColumn} from '../../../../customer-delegate/components/tables/TableAvatarImageColumn'
import {CustomerStatusColumn} from '../../../../default/svc/components/CustomerStatusColumn'
import {CustomerSelectionAction} from './CustomerSelectionAction'
import {CustomerTableRowActions} from './CustomerTableRowActions'
import {FilterInputContainer} from '../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {MultiSelectToggleFilterInput} from '../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {FilterType} from '../../../../../components/tables/constants/FilterType'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {PortalSearchModel} from '../../../../../models/customer-portal/PortalSearchModel'
import {CustomerPortalLink} from '../../../../../components/utils/CustomerPortalLink'
import {CustomerTypeBadge} from '../../../../default/svc/components/CustomerTypeBadge'
import {CustomerGroupModel} from '../../../../../models/customer-portal/CustomerModel'

export interface CustomerTableProps {
  onFilter: (filter: FilterModel) => void
  onDeleteSuccess?: () => void
  onEdit?: (data: CustomerModel) => void
  data?: GlobalSearchModel<CustomerModel> | PortalSearchModel<CustomerGroupModel>
  onNewCustomerClick?: () => void
}

export const CustomerTable = ({
  onFilter,
  data,
  onDeleteSuccess,
  onEdit,
  onNewCustomerClick,
}: CustomerTableProps) => {
  const {pushError, push} = useAlerts()
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {hiddenColumns, setHiddenColumns} = useTableOptions({tableName: 'customer'})

  const tableItems = useMemo((): CustomerModel[] => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      } else {
        return Object.entries(data?.data).reduce<CustomerModel[]>((acc, [, customers]) => {
          customers.forEach((customer) => {
            acc.push(customer)
          })
          return acc
        }, [])
      }
    }
    return []
  }, [data])

  const deleteCustomer = useCallback(
    async (codes: string[]) => {
      const response = await SwalUtils.deleteItem()
      if (response.isConfirmed) {
        try {
          await DeleteCustomer(codes)
          push({
            message: `Customer successfully cancelled.`,
            timeout: 5000,
            variant: 'success',
          })
          onDeleteSuccess && onDeleteSuccess()
        } catch (e) {
          pushError(e)
        }
      }
    },
    [onDeleteSuccess, push, pushError]
  )

  const handleDeleteBulk = useCallback(
    (data: HasCode[]) => {
      deleteCustomer(data.map((customer) => customer.code))
    },
    [deleteCustomer]
  )

  const handleDeleteSingle = useCallback(
    (data: HasCode) => {
      deleteCustomer([data.code])
    },
    [deleteCustomer]
  )

  const rowActions = useCallback(
    (data: CustomerModel) => (
      <CustomerTableRowActions data={data} onDelete={handleDeleteSingle} onEdit={onEdit} />
    ),
    [handleDeleteSingle, onEdit]
  )

  return (
    <FilterTable
      onFilter={onFilter}
      idExtractor={idExtractor}
      hiddenColumns={hiddenColumns}
      onHiddenColumnsChange={setHiddenColumns}
      actions={rowActions}
      initialFilters={{
        filters: {
          status: ['active', 'pending'],
        },
      }}
      advancedFilters={
        <FilterInputContainer>
          <MultiSelectToggleFilterInput
            field='status'
            items={FilterType.CUSTOMER_STATUS}
            label='Status'
          />
          <MultiSelectToggleFilterInput
            field='type'
            items={FilterType.CUSTOMER_PORTAL_TYPE}
            label='Type'
          />
        </FilterInputContainer>
      }
      selectionAction={
        <CustomerSelectionAction
          items={tableItems}
          selected={selected}
          onDelete={handleDeleteBulk}
        />
      }
      rightToolbar={
        <Button variant='primary' onClick={onNewCustomerClick}>
          <MetronicIcon iconType='Navigation' iconName='Plus' /> New Customer
        </Button>
      }
      data={tableItems}
      currentPageNumber={data?.page}
      columns={columns}
      totalItems={data?.total || 0}
      selection={selected}
      onSelectionChange={setSelected}
    />
  )
}

const columns: TableColumnOptions<CustomerModel>[] = [
  {
    field: 'photo',
    label: 'Photo',
    sortable: true,
    hideable: true,
    className: 'w-50px',
    render: ({data}) =>
      data.photo ? <TableAvatarImageColumn image={data.photo} alt={data.name} /> : null,
  },
  {
    field: 'code',
    label: 'Code',
    sortable: true,
    hideable: true,
    className: 'min-w-80px',
    render: ({data}) => <CustomerPortalLink customer={data}>{data.code}</CustomerPortalLink>,
  },
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    hideable: true,
  },
  {
    field: 'email',
    label: 'Email',
    sortable: true,
    hideable: true,
  },
  {
    field: 'type',
    label: 'Type',
    sortable: false,
    hideable: false,
    render: ({data}) => <CustomerTypeBadge customer={data}>{data.type}</CustomerTypeBadge>,
  },
  {
    field: 'mobile',
    label: 'mobile',
    sortable: true,
    hideable: true,
  },
  {
    field: 'status',
    label: 'Status',
    sortable: true,
    hideable: true,
    render: ({data}) => <CustomerStatusColumn data={data} />,
  },
]
