import {FormikContextType} from 'formik'
import {map} from 'lodash'
import {useMemo, useState} from 'react'
import {BookingWizardFinalSeatsStepFormValues} from '../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {EventModel} from '../../../models/ems/EventModel'
import {ActivityModel} from '../../../models/ems/ActivityModel'
import {BulkConsolidatedFormValues} from '../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import {useOnChange} from '../../hooks/useOnChange'
import {FilterTable} from '../../tables/FilterTable'
import {TableRowId} from '../../tables/TableRow'
import {TableColumnOptions} from '../../tables/TableColumn'
import {ColumnStyle} from '../../tables/constants/ColumnStyle'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {idExtractor} from '../../../utils/idExtractor'
import {GroupedTableData} from '../../tables/Table'

export interface BookingWizardSharedConsolidatedFinaleSteprops<
  T extends BookingWizardFinalSeatsStepFormValues
> {
  formik: FormikContextType<T>
  bookingBulkForm?: BulkConsolidatedFormValues
  customerCode?: string
  event?: EventModel | ActivityModel | null
  isPortal?: boolean
}

export interface CustomerFinalizeProps {
  code: string
  name: string
  productCode: string
  productName: string
  productType: 'product' | 'voucher' | 'bundle'
  productQty: number
  isSkip: boolean
}

export const BookingWizardSharedConsolidatedFinaleStep = <
  T extends BookingWizardFinalSeatsStepFormValues
>({
  formik,
  bookingBulkForm,
  customerCode,
  event,
  isPortal,
}: BookingWizardSharedConsolidatedFinaleSteprops<T>) => {
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])

  useOnChange(bookingBulkForm, () => {
    if (bookingBulkForm) {
      const selectedCustomer = bookingBulkForm.bookingResults?.find(
        (customer) => customer.isSelected
      )
      if (selectedCustomer) {
        formik.setFieldValue('customers', [selectedCustomer.customer])
      }
      formik.setFieldValue('bookingResults', bookingBulkForm.bookingResults)
      formik.setFieldValue('products', bookingBulkForm.products)
      formik.setFieldValue('voucher', bookingBulkForm.voucher)
      formik.setFieldValue('eventCode', bookingBulkForm.eventCode)
      formik.setFieldValue('customersSeats', bookingBulkForm.customersSeats)
    }
  })

  const customersProductsVouchers = useMemo<CustomerFinalizeProps[] | null>(() => {
    if (bookingBulkForm?.bookingResults?.length) {
      let data: CustomerFinalizeProps[] = []
      for (const customer of bookingBulkForm?.bookingResults) {
        if (customer.isSelected) {
          if (bookingBulkForm.products?.length) {
            for (const product of bookingBulkForm.products) {
              data.push({
                code: customer.customer?.code || '',
                name: customer.customer?.name || '',
                productCode: product.code || '',
                productName: product.name || '',
                productType: product.type || 'product',
                productQty: product?.qty || 0,
                isSkip: customer.isSkip || false,
              })
            }
          }
        } else {
          if (customer.products?.length) {
            for (const product of customer.products) {
              data.push({
                code: customer.customer?.code || '',
                name: customer.customer?.name || '',
                productCode: product.code || '',
                productName: product.name || '',
                productType: product.type || 'product',
                productQty: product?.qty || 0,
                isSkip: customer.isSkip || false,
              })
            }
          }
        }
      }

      if (data) {
        return data
      }
    }

    return null
  }, [bookingBulkForm])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<CustomerFinalizeProps>[] = [
      {
        field: 'code',
        label: 'Customer Name',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        render: ({data, groupData}) => {
          if (groupData) {
            return <>{null}</>
          }
          return <>{data.code}</>
        },
      },
      {
        field: 'productName',
        label: 'Product Name',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productName,
        groupedColumnRender: ({groupData}) => {
          return <>{null}</>
        },
      },
      {
        field: 'productQty',
        label: 'Qty',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productQty,
        groupedColumnRender: ({groupData}) => {
          return <>{null}</>
        },
      },
      {
        field: 'isSkip',
        label: '',
        sortable: true,
        cellStyle: ColumnStyle.NAME,
        render: ({data, groupData}) => {
          return <>{null}</>
        },
        groupedColumnRender: ({groupData}) => {
          const data = groupData.data[0]
          if (data) {
            return <>{null}</>
          } else {
            return (
              <div className='d-flex justify-content-center'>
                <p>
                  <i style={{color: '#f64e60'}}>Skipped</i>
                </p>
              </div>
            )
          }
        },
      },
    ]
    return columns
  }, [])

  const groupedTableData = useMemo(() => {
    if (
      bookingBulkForm &&
      bookingBulkForm?.bookingResults &&
      bookingBulkForm?.bookingResults.length
    ) {
      let newSearchableLocalTableData: GroupedTableData<CustomerFinalizeProps>[] = []
      for (const data of bookingBulkForm?.bookingResults) {
        let consolidateProduct: CustomerFinalizeProps[] = []
        if (customersProductsVouchers?.length) {
          for (const product of customersProductsVouchers) {
            if (product.code === data.customer?.code) {
              consolidateProduct.push({
                code: `${product.productCode}`,
                name: product.productName,
                productCode: product.productCode,
                productName: product.productName,
                productType: product.productType,
                productQty: product.productQty || 0,
                isSkip: product.isSkip || false,
              })
            }
          }
        }
        newSearchableLocalTableData.push({
          data: consolidateProduct,
          field: 'code',
          key: data.customer?.code || '',
          label: `${data.customer?.name}` || '',
        })
      }
      return newSearchableLocalTableData
    }
  }, [bookingBulkForm])

  const {
    searchableLocalTableData,
    filterSearchableLocalTableData,
    searchableLocalTableGroupedData,
  } = useLocalTableSearch({
    data: customersProductsVouchers,
    groupData: groupedTableData,
    columns: customersProductsVouchers
      ? (map(columns, 'field') as Array<keyof CustomerFinalizeProps>)
      : null,
    additionColumns: ['name', 'productType', 'productCode', 'productName', 'productQty', 'isSkip'],
  })

  return (
    <>
      <div className='container-fluid'>
        <div className='row g-5'>
          <div className='col-12 d-flex justify-content-start mt-20 ms-10 gap-2'>
            <MetronicIcon iconType='Communication' iconName='Flag' />
            <h3>Booking details</h3>
          </div>
          <div className='col-12'>
            <FilterTable
              onFilter={filterSearchableLocalTableData}
              idExtractor={idExtractor}
              data={searchableLocalTableData.data}
              currentPageNumber={searchableLocalTableData?.page}
              columns={columns}
              totalItems={searchableLocalTableGroupedData.length}
              groupedData={searchableLocalTableGroupedData}
              expandedGroups={expandedGroups}
              onExpandedGroupsChange={setExpandedGroups}
              grouping={'productType'}
              isFromFilter={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}
