import {useCallback, useMemo} from 'react'
import {BookingPortalFormValues} from '../components/DrawerForm/BookingPortalFormDrawer/BookingPortalForm'
import {BookingModel} from '../../../models/ems/BookingModel'
import {PortalBookingModelCreateParams} from '../../../models/customer-portal/BookingModel'

export const initialValues: BookingPortalFormValues = {
  customer: null,
  eventCode: '',
  products: [],
  vouchers: [],
}

export const useFormDrawerBookingData = () => {
  const mapBookingDataToFormValues = useCallback(
    (booking: BookingModel): BookingPortalFormValues => {
      if (!booking.customer) {
        throw new Error('Booking has no customer data.')
      }
      if (!booking.event) {
        throw new Error('Booking has no event data.')
      }
      if (!booking.products) {
        throw new Error('Booking has no product data.')
      }

      if (!booking.vouchers) {
        throw new Error('Booking has no voucher data.')
      }
      const formValues: BookingPortalFormValues = {
        customer: booking.customer,
        eventCode: booking.event.code,
        products: [],
        vouchers: [],
      }
      if (booking.products) {
        booking.products.forEach((product) => {
          formValues.products.push({
            count: product.qty,
            value: product.code,
            type: 'product',
            id: product.code,
          })
        })
      }
      if (booking.vouchers) {
        booking.vouchers.forEach((voucher) => {
          formValues.vouchers.push({
            count: voucher.qty,
            data: voucher,
            id: voucher.code,
          })
        })
      }

      return formValues
    },
    []
  )

  return useMemo(
    () => ({
      mapBookingDataToFormValues,
    }),
    [mapBookingDataToFormValues]
  )
}

export const getBookingPayload = (
  values: BookingPortalFormValues
): PortalBookingModelCreateParams => {
  if (!values.customer) {
    throw new Error('Invalid form data.')
  }
  const payload: PortalBookingModelCreateParams = {
    customerCode: values.customer.code,
    eventCode: values.eventCode || '',
    products: [],
    vouchers: [],
  }
  values.products.forEach((product) => {
    if (product.type && product.value && product.count > 0) {
      if (product.type === 'product') {
        payload.products.push({code: product.value, qty: product.count})
      }
    }
  })
  values.vouchers.forEach((item) => {
    if (item.data?.code && item.count > 0) {
      payload.vouchers.push({code: item.data.code, qty: item.count})
    }
  })
  return payload
}
