import clsx from 'clsx'
import {BookingModel} from '../../../../../models/ems/BookingModel'

export const BookingStatusColumn = ({data}: {data: BookingModel}) => {
  return <span className={getBadge(data)}>{data.status}</span>
}

const getBadge = (data: BookingModel) => {
  let baseClass = 'badge text-uppercase'

  switch (data.status) {
    case 'active':
      return clsx(baseClass, 'badge-light-primary')
    case 'fulfilled':
      return clsx(baseClass, 'badge-light-success')
    case 'cancelled':
      return clsx(baseClass, 'badge-light-danger')
    case 'draft':
      return clsx(baseClass, 'badge-light-dark')
    case 'confirmed':
      return clsx(baseClass, 'badge-light-info')
    default:
      return clsx(baseClass, 'badge-light-dark')
  }
}
