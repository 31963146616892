import clsx from 'clsx'
import styles from './CustomerImage.module.scss'
import {useMemo} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {FileModel} from '../../../../models/FileModel'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'

export interface CustomerImageProps {
  customer?: {
    photo?: FileModel
    name: string
  }
  className?: string
  height?: string | number
  width?: string | number
}

export const CustomerImage = ({customer, className, height, width}: CustomerImageProps) => {
  const {staticUrls} = useAppConfig()
  const image = useMemo(() => {
    if (customer?.photo) {
      return new ImageInputValue(staticUrls.public, customer.photo).url
    }
    return toAbsoluteUrl('/media/icons/duotone/General/User.svg')
  }, [customer?.photo, staticUrls.public])
  return (
    <div
      style={{
        height,
        width,
      }}
      className={clsx(
        styles.imageContainer,
        {
          [styles.imageContainerEmpty]: !customer?.photo,
        },
        className
      )}
    >
      <img
        className={clsx(styles.image, {
          [styles.imageEmpty]: !customer?.photo,
        })}
        src={image}
        alt={customer?.name}
      />
    </div>
  )
}
