import {XYChart, darkTheme, Axis, Tooltip, LineSeries} from '@visx/xychart'
import {curveMonotoneX} from '@visx/curve'
import {ReactNode, useMemo} from 'react'
import {RenderTooltipParams} from '@visx/xychart/lib/components/Tooltip'
import {useColorScale} from '../hooks/useColorScale'

type Accessor<T> = (data: T) => string | number

export type RenderTooltipProps<T extends object> = RenderTooltipParams<T>

export interface LineChartProps<T extends object> {
  data: [string, T[]][]
  xAccessor: Accessor<T>
  yAccessor: Accessor<T>
  yTicks?: number
  xTicks?: number
  renderTooltip?: (data: RenderTooltipProps<T>) => ReactNode
  hidden?: string[]
}

export const LineChart = <T extends object>({
  data,
  xAccessor,
  yAccessor,
  renderTooltip,
  hidden,
  xTicks,
  yTicks,
}: LineChartProps<T>) => {
  const {colorArray} = useColorScale(Object.values(data).length, colorPalette)

  const theme = useMemo(() => {
    return {...darkTheme, colors: colorArray}
  }, [colorArray])

  return (
    <XYChart theme={theme} xScale={{type: 'band', paddingInner: 0.3}} yScale={{type: 'linear'}}>
      {data.map(([key, value], index) => {
        const isHidden = hidden && hidden.includes(key)
        return (
          <LineSeries
            data={isHidden ? [] : value}
            stroke={colorArray[index]}
            dataKey={key}
            key={key}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            curve={curveMonotoneX}
          />
        )
      })}
      <Axis
        orientation='bottom'
        numTicks={xTicks}
        label='Date'
        labelOffset={25}
        tickLabelProps={() => {
          return {y: 25, stroke: 'white', strokeWidth: '1px', angle: 90, verticalAnchor: 'middle'}
        }}
      />
      <Axis
        orientation='left'
        numTicks={yTicks}
        label='Count'
        labelOffset={20}
        tickLabelProps={() => {
          return {stroke: 'white'}
        }}
        tickLineProps={{stroke: 'transparent'}}
        tickFormat={(value) => {
          return Number(value) % 1 === 0 ? value : ''
        }}
      />
      {renderTooltip && (
        <Tooltip
          showVerticalCrosshair
          snapTooltipToDatumX
          snapTooltipToDatumY
          showDatumGlyph
          glyphStyle={{fill: 'white'}}
          renderTooltip={renderTooltip}
          className='bg-transparent shadow-none'
        />
      )}
    </XYChart>
  )
}

export const colorPalette = [
  '#00BCBC',
  '#00c2cc',
  '#008996',
  '#0d6f7f',
  '#044f60',
  '#095f63',
  '#3b896d',
  '#19604a',
  '#0d4938',
  '#063529',
  '#84645f',
  '#6a4c46',
  '#593a35',
  '#472e28',
  '#33231f',
  '#2f140d',
  '#93794c',
  '#735d31',
  '#634b21',
]
