import {BaseCsvMapModalProps, CsvMapModal} from '../../forms/CsvMapModal'
import {SelectInputItem} from '../../inputs/SelectInput'

export type CustomerMappingTableModalProps = BaseCsvMapModalProps

export const CustomerMappingTableModal = ({
  onClose,
  onSubmit,
  open,
  isLoading = false,
}: CustomerMappingTableModalProps) => {
  return (
    <CsvMapModal
      onSubmit={onSubmit}
      mappableKeys={MAPPABLE_KEYS}
      onClose={onClose}
      open={open}
      isLoading={isLoading}
    />
  )
}
const MAPPABLE_KEYS: SelectInputItem[] = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Mobile',
    value: 'mobile',
  },
]
