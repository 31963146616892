import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useAuthRole} from '../../components/hooks/useAuthRole'
import {EventModel} from '../../models/ems/EventModel'
import {WidgetModel as EmsWidgetModel} from '../../models/ems/WidgetModel'
import {WidgetModel as WidgetFandBModel} from '../../models/fnb/WidgetModel'
import {
  ExportOutlet,
  ExportOutletPayload,
  GetDefaultEvent,
} from '../../modules/default/ems/redux/EmsCRUD'
import {DateUtil} from '../../utils/DateUtil'
import {Toolbar} from '../../components/layouts/DefaultLayout/Toolbar'
import {HeaderContent} from '../../components/layouts/DefaultLayout/Header/HeaderContent'
import {Button} from '../../components/inputs/Button'
import {Link, useRouteMatch} from 'react-router-dom'
import {MetronicIcon} from '../../components/inputs/MetronicIcon'
import {EventSearchInput} from '../../modules/default/ems/components/inputs/EventSearchInput'
import {
  DasboardTabs,
  DashboardTabValue,
} from '../../modules/default/ems/components/tabs/DashboardTabs/DasboardTabs'
import {MainCounterCard} from '../../modules/default/ems/components/charts/CounterCard/MainCounterCard'
import {
  CounterCard,
  CounterCardVariant,
} from '../../modules/default/ems/components/charts/CounterCard/CounterCard'
import {ChartPaper} from '../../modules/default/ems/components/charts/ChartPaper/ChartPaper'
import {YasMarinaCircuitChart} from '../../modules/default/ems/components/charts/YasMarinaCircuitChart/YasMarinaCircuitChart'
import {groupBy} from 'lodash'
import {OasisCounterBarChart} from '../../modules/default/ems/components/charts/OasisCounterBarChart/OasisCounterBarChart'
import {ScannerTimelineChart} from '../../modules/default/ems/components/charts/ScannerTimelineChart/ScannerTimelineChart'
import {GateDailyBarChart} from '../../modules/default/ems/components/charts/GateDailyBarChart/GateDailyBarChart'
import {DasboardToolbarTabs} from '../../modules/default/ems/components/tabs/DashboardTabs/DasboardToolbarTabs'
import {ProductCategoryRevenue} from '../../modules/default/ems/components/wizards/ProductCategoryRevenue/ProductCategoryRevenue'
import {EarningsTypePieChart} from '../../modules/default/ems/components/wizards/EarningsTypePieChart/EarningsTypePieChart'
import {OutletHourlyRevenue} from '../../modules/default/fnb/components/charts/OutletHourlyRevenue/OutletHourlyRevenue'
import {CounterChart} from '../../components/charts/CounterChart'
import {OutletChart} from '../../modules/default/ems/components/wizards/OutletChart/OutletChart'
import {OutletLocationChart} from '../../modules/default/ems/components/wizards/OutletLocationChart/OutletLocationChart'
import {Spinner} from '../../components/utils/Spinner'
import {DateRangeInput} from '../../components/inputs/datepickers/DateRangeInput'
import {DateRange} from '../../utils/DateRange'
import {useBreakpoint} from '../../components/hooks/useBreakpoint'
import {useAlerts} from '../../components/alerts/useAlerts'
import {BlobUtils} from '../../utils/BlobUtils'
import {KTSVG} from '../../../_metronic/helpers'
import {WeekdayTabsInputWithPagination} from '../../components/inputs/WeekdayTabsInputWithPagination/WeekdayTabsInputWithPagination'
import {useEventDashboardWidget} from '../../modules/default/ems/hooks/useEventDashboardWidget'
import {useEventDashboardEmsWidget} from '../../modules/default/ems/hooks/useEventDashboardEmsWidget'
import {useOnChange} from '../../components/hooks/useOnChange'
import {useEmstimer} from '../../modules/default/ems/hooks/useEmsTimer'
import {useEventFnBDashboardWidget} from '../../modules/default/ems/hooks/useEventFnbDashboardWidget'
import { useLocation } from 'react-router-dom'


export const DashboardWrapper = () => {
  const location = useLocation()
  const [selectedEvent, setSelectedEvent] = useState<EventModel | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  // const [timerFandB, setTimerFandB] = useState<NodeJS.Timeout>()

  const [isCurrentDay, setIsCurrentDay] = useState(false)
  const [dashboardTab, setDashboardTab] = useState<DashboardTabValue>('grid')
  const auth = useAuthRole()
  const [dateExport, setDateExport] = useState<DateRange | null>(null)
  const match = useRouteMatch()
  const {pushError} = useAlerts()
  const {down} = useBreakpoint()
  const [isLaodingExport, setIsLoadingExport] = useState<boolean>(false)
  const [isDashboardRoute, setIsDashboardRoute] = useState(false);

  useEffect(() => {
    const onDashboardRoute = location.pathname === '/dashboard' || location.pathname.includes('/overview');
    setIsDashboardRoute(onDashboardRoute);
  }, [location])

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])
  const {
    data: todayData,
    // startSocket,
    // isConnected,
    // socket,
    // setSocket
    resetWidgetData: todayResetWidgetData,
  } = useEventDashboardWidget({
    date: selectedDate,
    dailyWidgets: DAILY_EMS_WIDGETS,
    eventCode: selectedEvent?.code,
  })

  const {data: emsData, resetWidgetData: emsResetWidgetData} = useEventDashboardEmsWidget({
    date: selectedDate,
    dailyWidgets: DAILY_EMS_WIDGETS,
    eventCode: selectedEvent?.code,
  })

  // const {
  //   data: emsData,
  //   startSocket,
  //   isConnected,
  //   socket,
  //   setSocket,
  //   // resetWidgetData,
  // } = useEventDashboardWidgetData({
  //   date: selectedDate,
  //   dailyWidgets: DAILY_EMS_WIDGETS,
  //   eventCode: selectedEvent?.code,
  // })

  const {data: dataFandB, resetWidgetData: resetWidgetDataFandB} = useEventFnBDashboardWidget({
    date: selectedDate,
    widgets: FANDBWIDGETS,
    eventCode: selectedEvent?.code,
  })

  const checkedData = useMemo(() => {
    if (isCurrentDay) {
      return todayData
    } else {
      return emsData
    }
  }, [emsData, isCurrentDay, todayData])

  const isDashboardOnlyUser = useMemo(() => {
    if (auth) {
      if (!auth.isAdmin()) {
        const features = auth.getAllApplicationFeature()
        if (
          features.length >= 1 &&
          (auth.canReadFeature('SVCDASHBOARD') || auth.canReadFeature('SVCFNBDASHBOARD'))
        ) {
          return true
        }
      }
    }
    return false
  }, [auth])

  const isHasFandBAccess = useMemo(() => {
    if (isDashboardOnlyUser && auth?.getUser()) {
      const applications = auth.getUser().role?.applications?.find((item) => item.code === 'SVC')
      if (applications) {
        if (applications.features?.find((item) => item.code === 'SVCFNBDASHBOARD')) return true
        else return false
      }
    }
    return false
  }, [auth, isDashboardOnlyUser])

  const isHasDashboardAccess = useMemo(() => {
    if (isDashboardOnlyUser && auth?.getUser()) {
      const applications = auth.getUser().role?.applications?.find((item) => item.code === 'SVC')
      if (applications) {
        if (applications.features?.find((item) => item.code === 'SVCDASHBOARD')) return true
        else return false
      }
    }
    return false
  }, [auth, isDashboardOnlyUser])

  useEffect(() => {
    if (isHasFandBAccess && !isHasDashboardAccess) setDashboardTab('F&B')
  }, [isHasDashboardAccess, isHasFandBAccess])

  const resetSelectedDate = useCallback((event: EventModel | null) => {
    if (event) {
      const today = moment().startOf('day')
      const eventEnd = moment(DateUtil.getDateFromApiString(event.endedAt))
      // const eventStart = moment(DateUtil.getDateFromApiString(event.startedAt))
      const earliestDate = moment.min(today, eventEnd ).toDate()
      setSelectedDate(earliestDate)
    } else {
      setSelectedDate(null)
    }
  }, [])

  const resetDefaultEvent = useCallback(async () => {
    if (!selectedEvent) {
      const {data} = await GetDefaultEvent()
      setSelectedEvent(data)
    }
  }, [selectedEvent])

  const isAdmin = useMemo(() => {
    return auth?.isAdmin()
  }, [auth])

  const {stop, start, timer} = useEmstimer(todayResetWidgetData)
  const tick = useCallback(async () => {
    todayResetWidgetData()
  }, [todayResetWidgetData])

  useOnChange(selectedDate, () => {
    if (dashboardTab === 'F&B') {
      resetWidgetDataFandB()
    }
    if (!isDashboardRoute) {
      stop()
    }
  })

  useOnChange(dashboardTab, () => {
    if (dashboardTab === 'F&B') {
      resetWidgetDataFandB()
    }
    if (!isDashboardRoute) {
      stop()
    }
  })


  useOnChange(selectedDate, () => {
      if (dashboardTab !== 'F&B') {
        const currentDateInOffset = moment().utcOffset('+04:00').startOf('day')
  
        let selectedDay = null
        if (selectedDate) {
          selectedDay = moment(selectedDate).utcOffset('+04:00').startOf('day')
        }
        if (selectedDay && currentDateInOffset.isSame(selectedDay, 'day')) {
          setIsCurrentDay(true)
          // todayResetWidgetData()
          if (!timer) {
            tick()
            start()
          }
        } else {
          setIsCurrentDay(false)
          stop()
          emsResetWidgetData()
        }
      }
      if (!isDashboardRoute) {
        stop()
      }
  })
  useOnChange(dashboardTab, () => {
      if (dashboardTab !== 'F&B') {
        const currentDateInOffset = moment().utcOffset('+04:00').startOf('day')
  
        let selectedDay = null
        if (selectedDate) {
          selectedDay = moment(selectedDate).utcOffset('+04:00').startOf('day')
        }
        if (selectedDay && currentDateInOffset.isSame(selectedDay, 'day')) {
          setIsCurrentDay(true)
          // todayResetWidgetData()
  
          if (!timer) {
            tick()
            start()
          }
        } else {
          setIsCurrentDay(false)
          stop()
          emsResetWidgetData()
        }
      } 
      if (!isDashboardRoute) {
        stop()
      }
  })

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);


  const grid = useMemo(() => {
    return (
      <>
        <div className='col-xs-12 col-lg-6'>
          <MainCounterCard
            className='h-100'
            value={checkedData.WIDGETADMM01?.total || 0}
            variant='left'
            title='Total Visitors Today'
            subtitle='YMC & W Hotel | Unique Count'
          />
        </div>
        <div className='col-xs-12 col-lg-6'>
          <MainCounterCard
            className='h-100'
            value={checkedData.WIDGETADMM02?.total || 0}
            variant='right'
            title='Total Visitors Till Date'
            subtitle='YMC & W Hotel | Unique Count | Accumulative Days'
          />
        </div>
        {checkedData.WIDGETADMM03?.sort((a, b) => {
          return Number(a.order) - Number(b.order)
        }).map((gate) => (
          <div key={gate.code} className='col-xs-12 col-lg-3 col-md-4'>
            <CounterCard
              className='h-100'
              value={gate.total}
              variant={gate.color as CounterCardVariant}
              title={gate.gridViewTitle}
              subtitle={gate.gridViewSubTitle}
            />
          </div>
        ))}
      </>
    )
  }, [checkedData.WIDGETADMM01?.total, checkedData.WIDGETADMM02?.total, checkedData.WIDGETADMM03])

  const map = useMemo(() => {
    return (
      <div className='col-12'>
        <ChartPaper style={{height: '80vh'}}>
          <YasMarinaCircuitChart
            data={checkedData.WIDGETADMM03}
            total={checkedData.WIDGETADMM01?.total || 0}
          />
        </ChartPaper>
      </div>
    )
  }, [checkedData.WIDGETADMM01?.total, checkedData.WIDGETADMM03])

  const oasis = useMemo(() => {
    if (checkedData.WIDGETADMM04) {
      const widgetGroup = groupBy(checkedData.WIDGETADMM04, 'code')
      return Object.entries(widgetGroup).map(([code, datum]) => {
        return (
          <div key={code} className='ps-10 col-md-12 col-lg-6 col-xl-6 col-xs-12 col-xxl-6'>
            <ChartPaper className='p-10' style={{height: '500px'}}>
              <OasisCounterBarChart datum={datum} />
            </ChartPaper>
          </div>
        )
      })
    }
    return null
  }, [checkedData.WIDGETADMM04])

  const analytics = useMemo(() => {
    return (
      <>
        <div className='col-12'>
          <ChartPaper className='pt-10' style={{height: '500px'}}>
            <ScannerTimelineChart data={checkedData.WIDGETADMM05} selectedDate={selectedDate} />
          </ChartPaper>
        </div>
        <div className='col-12'>
          <ChartPaper className='p-10 pb-20' style={{height: '500px'}}>
            <GateDailyBarChart
              data={checkedData.WIDGETADMM06 ? Object.values(checkedData.WIDGETADMM06)[0] : []}
            />
          </ChartPaper>
        </div>
      </>
    )
  }, [checkedData.WIDGETADMM05, checkedData.WIDGETADMM06, selectedDate])

  const handleDateRangeChange = useCallback((value: DateRange) => {
    setDateExport(value)
  }, [])

  const exportDateRange = useMemo(() => {
    if (selectedEvent) {
      const dateMinMax = new DateRange()
      dateMinMax.setStart(DateUtil.getDateFromApiString(selectedEvent.startedAt))
      dateMinMax.setEnd(DateUtil.getDateFromApiString(selectedEvent.endedAt))
      return dateMinMax
    }
  }, [selectedEvent])

  const handleExport = useCallback(async () => {
    setIsLoadingExport(true)
    try {
      if (selectedEvent) {
        const payload: ExportOutletPayload = {
          eventCode: selectedEvent.code,
          startedAt: dateExport
            ? moment(dateExport.getStart()).startOf('day').toISOString()
            : undefined,
          endedAt: dateExport ? moment(dateExport.getEnd()).endOf('day').toISOString() : undefined,
        }
        const {data, headers} = await ExportOutlet(payload)
        let name = headers['content-disposition']
        name = name
          .replace('attachment;', '')
          .replace('filename=', '')
          .split('(')[0]
          .replaceAll('"', '')
          .trim()
        BlobUtils.downloadBlob(data, name)
      }
    } catch (err: any) {
      pushError(err)
    } finally {
      setIsLoadingExport(false)
    }
  }, [dateExport, selectedEvent, pushError])

  const foodAndBeverage = useMemo(() => {
    return (
      <>
        <div className='col-12 d-flex flex-wrap gap-10 justify-content-end'>
          <DateRangeInput
            minDate={exportDateRange?.getStart()}
            maxDate={exportDateRange?.getEnd()}
            onChange={handleDateRangeChange}
            value={dateExport || new DateRange()}
          />
          <Button
            className='text-white ff-admm-bold bg-transparent p-0'
            style={{
              fontSize: isMobile ? '15px' : '20px',
            }}
            onClick={handleExport}
            disabled={isLaodingExport}
          >
            <div
              className='btn btn-icon btn-lg me-1'
              style={{height: isMobile ? '20px' : '30px', width: isMobile ? '20px' : '30px'}}
            >
              {isLaodingExport ? (
                <Spinner />
              ) : (
                <KTSVG
                  className='h-100 w-100 me-5 svg-icon-primary'
                  svgClassName='h-100 w-100'
                  path='/media/icons/duotone/Files/Download.svg'
                />
              )}
            </div>
            Export
          </Button>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-6'>
          <CounterChart
            className='h-100 border border-primary border-1'
            label='Total sales'
            value={dataFandB.WIDGETF05 || 0}
          />
        </div>

        <div className='col-xs-12 col-sm-12 col-md-6'>
          <CounterChart
            className='h-100 border border-primary border-1'
            label='Total sales for this day'
            value={dataFandB.WIDGETF01 || 0}
          />
        </div>

        {(dataFandB.WIDGETF09?.length && (
          <div className='col-md-12 col-lg-6'>
            <ChartPaper className='ps-5 py-10' style={{height: '600px'}}>
              <div className='h-100 w-100 d-flex flex-column'>
                <p className='mb-3 fs-1'>Outlet hourly sales</p>
                <div className='flex-grow-1 p-10'>
                  <OutletHourlyRevenue datum={dataFandB.WIDGETF09} />
                </div>
              </div>
            </ChartPaper>
          </div>
        )) ||
          null}

        {(dataFandB.WIDGETF02?.length && (
          <div className='col-md-12 col-lg-6'>
            <ChartPaper className='px-5 py-10' style={{height: isMobile ? '400px' : '600px'}}>
              <p className='mb-3 fs-1'>Payment type</p>
              <EarningsTypePieChart datum={dataFandB.WIDGETF02} />
            </ChartPaper>
          </div>
        )) ||
          null}

        {(dataFandB.WIDGETF11?.length && (
          <div className='col-12'>
            <ChartPaper className='ps-5 py-10' style={{height: '600px'}}>
              <div className='h-100 w-100 d-flex flex-column'>
                <p className='mb-3 fs-1'>Category sales</p>
                <div className='flex-grow-1 ps-10 pe-10 pt-2 ms-0 mt-10'>
                  <ProductCategoryRevenue datum={dataFandB.WIDGETF11} />
                </div>
              </div>
            </ChartPaper>
          </div>
        )) ||
          null}

        {(dataFandB.WIDGETF12 && (
          <div className='col-12'>
            <ChartPaper className='ps-5 py-10' style={{height: '600px'}}>
              <div className='h-100 w-100 d-flex flex-column'>
                <p className='mb-3 fs-1'>Locations</p>
                <div className='flex-grow-1 ps-20 pe-10 pt-2 ms-0'>
                  <OutletLocationChart datum={dataFandB.WIDGETF12} />
                </div>
              </div>
            </ChartPaper>
          </div>
        )) ||
          null}

        {(dataFandB.WIDGETF10 && (
          <div className='col-12'>
            <ChartPaper className='ps-5 py-10' style={{height: '600px'}}>
              <div className='h-100 w-100 d-flex flex-column'>
                <p className='mb-3 fs-1'>Outlets</p>
                <div className='flex-grow-1 ps-20 pe-10 pt-2 ms-0'>
                  <OutletChart datum={dataFandB.WIDGETF10} />
                </div>
              </div>
            </ChartPaper>
          </div>
        )) ||
          null}
      </>
    )
  }, [
    dataFandB.WIDGETF01,
    dataFandB.WIDGETF02,
    dataFandB.WIDGETF05,
    dataFandB.WIDGETF09,
    dataFandB.WIDGETF10,
    dataFandB.WIDGETF11,
    dataFandB.WIDGETF12,
    dateExport,
    exportDateRange,
    handleDateRangeChange,
    handleExport,
    isLaodingExport,
    isMobile,
  ])

  const tabContents = useMemo(() => {
    switch (dashboardTab) {
      case 'grid':
        return grid
      case 'map':
        return map
      case 'oasis':
        return oasis
      case 'analytics':
        return analytics
      case 'F&B':
        return foodAndBeverage
    }
  }, [analytics, dashboardTab, grid, map, oasis, foodAndBeverage])

  const handleChangeTab = useCallback((value: DashboardTabValue) => {
    setDashboardTab(value)
    // if (value === 'F&B') {
    //   // stop()
    //   // tickFandB()
    //   // if (timerFandB) clearInterval(timerFandB)
    //   // const time = setInterval(tickFandB, MIUTES_IN_MILLISECOND)
    //   // setTimerFandB(time)
    //   if (isConnected) {
    //     socket?.disconnect()
    //     setSocket && setSocket(undefined)
    //   }
    //   if (!isConnectedFandB) startSocketFandB(WSS_EMS_URL)
    // } else {
    //   // if (timerFandB) clearInterval(timerFandB)
    //   // if (!timer) {
    //   //   tick()
    //   //   start()
    //   // }
    //   if (isConnectedFandB) {
    //     socketFandB?.disconnect()
    //     setSocketFandB && setSocketFandB(undefined)
    //   }
    //   if (!isConnected) startSocket(WSS_EMS_URL)
    // }
  }, [])

  const toolbar = useMemo(() => {
    return (
      <Toolbar title='Dashboard'>
        <div className='h-100 d-flex align-items-center justify-content-end'>
          {auth?.canManageFeature('EMSEVENTS') && (
            <Link
              to={{
                pathname: '/ems/event/new',
                state: {breadcrumbs: [{label: 'Dashboard', link: '/dashboard'}], from: match.url},
              }}
            >
              <Button variant='primary' size='sm'>
                <MetronicIcon iconType='Navigation' iconName='Plus' />
                New Event
              </Button>
            </Link>
          )}
        </div>
      </Toolbar>
    )
  }, [auth, match.url])

  useEffect(() => {
    resetDefaultEvent()
  }, [resetDefaultEvent])

  useEffect(() => {
    if (selectedEvent ) {
      resetSelectedDate(selectedEvent)
    }
  }, [resetSelectedDate, selectedEvent])

  return (
    <>
      {auth?.canReadFeature('EMSEVENTS') && (
        <HeaderContent>
          <div className='d-flex align-items-center h-100 mx-3' style={{width: '15rem'}}>
            <EventSearchInput
              className='w-100'
              noMargin
              onChange={setSelectedEvent}
              value={selectedEvent}
              placeholder='Search Events'
            />
          </div>
        </HeaderContent>
      )}
      {toolbar}

      <div>
        <div className='row g-6 mb-6'>
          {isDashboardOnlyUser && (
            <div className='col-12'>
              <div className='container'>
                <DasboardTabs
                  value={dashboardTab}
                  isHasFandBAccess={isHasFandBAccess}
                  isHasDashboardAccess={isHasDashboardAccess}
                  onChange={handleChangeTab}
                />
              </div>
            </div>
          )}
          {isAdmin && (
            <div className='col-12'>
              <div className='container'>
                <DasboardToolbarTabs value={dashboardTab} onChange={handleChangeTab} />
              </div>
            </div>
          )}
          {(isAdmin || isDashboardOnlyUser) && selectedEvent && (
            <>
              <div className='col-12'>
                <WeekdayTabsInputWithPagination
                  value={selectedDate}
                  onChange={setSelectedDate}
                  min={DateUtil.getDateFromApiString(selectedEvent.startedAt)}
                  max={DateUtil.getDateFromApiString(selectedEvent.endedAt)}
                />
              </div>
              {tabContents}
            </>
          )}
        </div>
      </div>
    </>
  )
}

const DAILY_EMS_WIDGETS: (keyof EmsWidgetModel)[] = [
  'WIDGETADMM01',
  'WIDGETADMM02',
  'WIDGETADMM03',
  'WIDGETADMM04',
  'WIDGETADMM05',
  'WIDGETADMM06',
]

const FANDBWIDGETS: (keyof WidgetFandBModel)[] = [
  'WIDGETF01',
  'WIDGETF02',
  'WIDGETF05',
  'WIDGETF08',
  'WIDGETF09',
  'WIDGETF10',
  'WIDGETF11',
  'WIDGETF12',
]
