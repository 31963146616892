import {Modal} from 'react-bootstrap'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
export interface ViewImageModalProps {
  isOpen: boolean
  onClose?: () => void
  imageURL: string
  title?: string
  zoom?: boolean
}

export const ViewImageModal = ({onClose, isOpen, imageURL, title, zoom}: ViewImageModalProps) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className='bg-transparent'
      style={{
        zIndex: 20000,
      }}
      id='kt_mega_menu_modal'
      aria-hidden='true'
      tabIndex='-1'
      size='lg'
      centered
      contentClassName='shadow-none p-10 position-relative'
    >
      <div className='text-end position-absolute top-0 end-0 m-1' id='888888888'>
        <MetronicIconButton
          type='button'
          className='btn'
          iconType='Navigation'
          iconName='Close'
          variant='text'
          size='md'
          color='white'
          onClick={onClose}
        />
        <p>{title}</p>
      </div>
      <div className='p-3'>
        {zoom ? (
          <TransformWrapper
            // initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            zoomAnimation={{
              animationType: 'easeOut',
            }}
            centerOnInit
            // maxPositionX={100}
            wheel={{
              wheelDisabled: false,
            }}
          >
            <TransformComponent wrapperClass='w-100  p-3' contentClass='m-auto w-100'>
              <img
                src={imageURL}
                width='400'
                alt='license'
                className='m-auto rounded'
                style={{
                  maxHeight: '80vh',
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        ) : (
          <img src={imageURL} width='400' alt='license' className='m-auto rounded' />
        )}
      </div>
    </Modal>
  )
}
