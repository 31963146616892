import {Children, ReactNode} from 'react'

export interface FilterInputContainerProps {
  children: ReactNode
}

export const FilterInputContainer = ({children}: FilterInputContainerProps) => {
  const wrappedChildren = Children.map(children, (node, i) => {
    let key = i.toString()
    if (node && typeof node === 'object' && 'key' in node) {
      key += `-${node.key}`
      return (
        <div key={key} className={WRAPPER_CLASS_NAME}>
          {node}
        </div>
      )
    }
    return null
  })

  return <div className='row'>{wrappedChildren}</div>
}

const WRAPPER_CLASS_NAME = 'col-xl-3 col-lg-6 col-md-4 col-sm-6 col-xs-12'
