import {useMemo} from 'react'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {DigitalWaiverStatusColumn} from '../tables/DigitalWaiverStatusColumn'
import {WaiverPortalStatusColumn} from '../tables/WaiverPortalStatusColumn'
import moment from 'moment'
interface DigitalWaiverCardDetailsProps {
  data: TicketWaiverModel
  tabCode?: string
}

interface DatadQueryProps {
  name: string
  key: string
  isDate?: boolean
  isTime?: boolean
}

const DETAILS: DatadQueryProps[] = [
  {
    name: 'Experience Date',
    key: 'startedAt',
    isDate: true,
  },

  {
    name: 'Experience Time',
    key: 'startedAt',
    isTime: true,
  },
  {
    name: 'Ticket Id',
    key: 'batchId',
  },
  {
    name: 'Reservation No',
    key: 'reservationNo',
  },

  {
    name: 'Event Type',
    key: 'productCategoryName',
  },
]

export const DigitalWaiverCardDetails = ({data, tabCode}: DigitalWaiverCardDetailsProps) => {
  const cardDetails = useMemo(() => {
    const details =
      data && Object.entries(data).length
        ? DETAILS.map((det: DatadQueryProps) => {
            const found = Object.entries(data).find(([key, value]) => {
              return det.key === key && value
            })
            if (found)
              return (
                <div key={det.name} className='d-flex flex-column h-100 pb-1 fw-bolder'>
                  {det.isDate ? (
                    <p className='fs-5 text-white mb-0'>{`${det.name}: ${moment(found[1]).format(
                      'DD MMM YYYY'
                    )}`}</p>
                  ) : det.isTime ? (
                    <p className='fs-5 text-white mb-0'>{`${det.name}: ${moment(found[1]).format(
                      'LT'
                    )}`}</p>
                  ) : (
                    <p className='fs-5 text-white mb-0'>{`${det.name}: ${found[1]}`}</p>
                  )}
                </div>
              )
            return null
          })
        : null
    return details
  }, [data])

  return (
    <div>
      {cardDetails}
      {tabCode === 'shared' && (
        <>
          <div className='d-flex flex-column h-100 pb-1 fw-bolder'>
            <p className='fs-5 text-white mb-0'>{`Shared with: ${
              data.delegateReceiverCustomerName || ''
            }`}</p>
          </div>
          <div className='d-flex flex-column h-100 pb-1 fw-bolder'>
            <p className='fs-5 text-white mb-0'>{`Email: ${
              data.delegateReceiverCustomerEmail || ''
            }`}</p>
          </div>
        </>
      )}

      {tabCode === 'available' ? (
        <>
          {data.dwStatus ? (
            <div>
              <p className='fs-5 text-white  mb-0 pb-1 fw-bolder'>
                <span>Status:</span>
                <span className='ps-2'>
                  <DigitalWaiverStatusColumn data={data} tabCode={tabCode} />
                </span>
              </p>
            </div>
          ) : (
            <div>
              <p className='fs-5 text-white  mb-0 pb-1 fw-bolder'>
                <span>Status:</span>
                <span className='ps-3'>
                  <WaiverPortalStatusColumn data={data} tabCode={tabCode} />
                </span>
              </p>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}
