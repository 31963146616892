import clsx from 'clsx'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {NavButton} from './NavButton'

export interface NavHeaderProps {
  menus: NavigationSectionMenuItem[]
  className?: string
  subMenu?: boolean
}

export const NavHeader = ({menus, className, subMenu = false}: NavHeaderProps) => {
  return (
    <div className='d-flex overflow-auto h-100 w-100'>
      {menus.map((item, i) => (
        <NavButton
          className={clsx('text-nowrap h-100 digital-waiver-nav-button', className)}
          key={i}
          item={item}
          subMenu={subMenu}
        />
      ))}
    </div>
  )
}
