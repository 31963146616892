import {useMemo} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {ColorVariant} from '../../../../components/inputs/Button'
import {TicketModelStatus} from '../../../../models/ems/TicketModel'
import {VoucherModelStatus} from '../../../../models/svc/VoucherModel'

export interface PortalShareStatusColumnProps {
  data: {
    status?: TicketModelStatus | VoucherModelStatus
  }
}

export const PortalShareStatusColumn = ({data}: PortalShareStatusColumnProps) => {
  const variant = useMemo(() => {
    return getStatusVariant(data.status)
  }, [data])

  return (
    <Badge uppercase variant={variant}>
      {data.status}
    </Badge>
  )
}

const getStatusVariant = (status?: TicketModelStatus | VoucherModelStatus): ColorVariant => {
  switch (status) {
    case 'active':
      return 'primary'
    case 'sent':
      return 'warning'
    case 'shared':
      return 'info'
    case 'disabled':
      return 'danger'
    case 'cancelled':
      return 'danger'
    default:
      return 'dark'
  }
}
