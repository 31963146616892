import {useCallback, useMemo, useState} from 'react'
import {SeatMapModel} from '../../../models/SeatMapModel'
import {SeatMapSpacing} from '../../../components/inputs/SeatMapInput/SeatMapSpacing'
import {SeatMapValue} from '../../../components/inputs/SeatMapInput/SeatMapValue'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {useSafeStateUpdate} from '../../../components/hooks/useSafeStateUpdate'

export const useSeatMapPortalReservationState = () => {
  const [isRightToLeft, setIsRightToLeft] = useState(false)
  const [isBottomToTop, setIsBottomToTop] = useState(false)
  const [rows, setRows] = useState(0)
  const [columns, setColumns] = useState(0)
  const [disabled, setDisabled] = useState(new SeatMapValue())
  const [occupied, setOccupied] = useState(new SeatMapValue())
  const [extra, setExtra] = useState(new SeatMapValue())
  const [active, setActive] = useState(new SeatMapValue())
  const [shared] = useState(new SeatMapValue())
  const [hidden, setHidden] = useState(new SeatMapValue())
  const [seatMapSpacingX, setSeatMapSpacingX] = useState(new SeatMapSpacing())
  const {isLoading, setIsLoading} = useLoadingState()
  const safeUpdate = useSafeStateUpdate()

  const resetState = useCallback((seatMap?: SeatMapModel) => {
    if (seatMap) {
      const {col, disabled, hidden, row, spacing, isRtlCol, isRtlRow} = seatMap
      if (spacing?.x) {
        const seatMapSpacing = new SeatMapSpacing(spacing.x)
        setSeatMapSpacingX(seatMapSpacing)
      }
      setIsRightToLeft(isRtlCol || false)
      setIsBottomToTop(isRtlRow || false)
      setColumns(parseInt(col as string))
      setRows(parseInt(row as string))
      setDisabled(new SeatMapValue(disabled))
      setHidden(new SeatMapValue(hidden))
    } else {
      setIsRightToLeft(false)
      setIsBottomToTop(false)
      setColumns(0)
      setRows(0)
      setDisabled(new SeatMapValue())
      setHidden(new SeatMapValue())
      setOccupied(new SeatMapValue())
      setExtra(new SeatMapValue())
      setSeatMapSpacingX(new SeatMapSpacing())
    }
  }, [])

  const setAvailableSeates = useCallback(
    (available: SeatMapValue) => {
      safeUpdate(() => {
        setActive(available)
      })
    },
    [safeUpdate]
  )

  return useMemo(() => {
    return {
      isLoading,
      setIsLoading,
      isRightToLeft,
      setIsRightToLeft,
      isBottomToTop,
      setIsBottomToTop,
      rows,
      setRows,
      columns,
      setColumns,
      disabled,
      active,
      setDisabled,
      occupied,
      setOccupied,
      extra,
      setExtra,
      shared,
      hidden,
      setHidden,
      seatMapSpacingX,
      setSeatMapSpacingX,
      resetState,

      setAvailableSeates,
    }
  }, [
    columns,
    setIsLoading,
    disabled,
    extra,
    hidden,
    active,
    shared,
    isBottomToTop,
    isLoading,
    isRightToLeft,
    occupied,
    resetState,
    rows,
    seatMapSpacingX,
    setAvailableSeates,
  ])
}
