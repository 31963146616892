import {useCallback, useState} from 'react'
import {useBooleanState} from '../../../components/hooks/useBooleanState'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {Button} from '../../../components/inputs/Button'
import {MobileNumberInput} from '../../../components/inputs/MobileNumberInput'
import {SweetAlert} from '../../../components/modals/SweetAlert'
import {Cart} from '../../../utils/Cart'
import {OrderSummaryCart} from '../../digital-menu/components/OrderSummary/OrderSummaryCart'
import {PaymentSummary} from './PaymentSummary'

export interface OrderSummaryModalProps {
  open: boolean
  onClose: () => void
  cart: Cart
  defaultMobileNumber?: string
  voucherAmount?: number | null
  onSubmit: (mobileNumber: string) => void
  loading?: boolean
}

export const OrderSummaryModal = ({
  open,
  onClose,
  cart,
  defaultMobileNumber,
  voucherAmount,
  onSubmit,
  loading,
}: OrderSummaryModalProps) => {
  const smsForm = useBooleanState(false)
  const [mobileNumber, setMobileNumber] = useState('')

  useOnChange(open, () => {
    if (open && defaultMobileNumber) {
      setMobileNumber(defaultMobileNumber)
    }
    if (!open) {
      smsForm.setState(false)
    }
  })

  const handleSmsSend = useCallback(() => {
    onSubmit(mobileNumber)
  }, [onSubmit, mobileNumber])

  return (
    <SweetAlert open={open} onClose={onClose} showConfirmButton={false}>
      <div className='text-white'>
        {!smsForm.state ? (
          <div>
            <p className='fs-1'>Order Summary</p>
            <OrderSummaryCart variant='primary' cart={cart} />
            <PaymentSummary className='mb-6' cart={cart} />
            {typeof voucherAmount === 'number' && (
              <p className='fs-3 my-5'>Voucher Balance: {voucherAmount}</p>
            )}
          </div>
        ) : (
          <div>
            <p className='fs-1'>Enter Mobile Number</p>
            <MobileNumberInput onChange={setMobileNumber} value={mobileNumber} />
          </div>
        )}
        <div className='container'>
          <div className='row'>
            <div className='col-6'>
              <Button fullWidth onClick={onClose}>
                Close
              </Button>
            </div>
            <div className='col-6'>
              {!smsForm.state && (
                <Button onClick={smsForm.enableState} fullWidth variant='primary'>
                  Send SMS
                </Button>
              )}
              {smsForm.state && (
                <Button disabled={loading} onClick={handleSmsSend} fullWidth variant='primary'>
                  Send
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </SweetAlert>
  )
}
