import {Action} from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {TicketGroupModel} from '../../../models/customer-portal/TicketModel'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../models/GlobalSearchModel'
import {WaiverSearchModel} from '../../../models/digital-waiver/WaiverSearchModel'
import {
  GetTicketList,
  VerifyCustomerAuthToken,
  GetEvents,
  GetExpiredTickets,
} from './DigitalWaiverCRUD'
import {CustomerUserModel} from '../../../models/customer-portal/AuthModel'
import {TicketWaiverModel} from '../../../models/digital-waiver/TicketWaiverModel'
import {EventModel} from '../../../models/ems/EventModel'

interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface OutletAuthState {
  user?: CustomerUserModel
  token?: string
}

export interface IOutletState {
  auth?: OutletAuthState
  tickets?: GlobalSearchModel<TicketWaiverModel> | GroupedSearchModel<TicketWaiverModel>
  expiredTickets?: GlobalSearchModel<TicketWaiverModel> | GroupedSearchModel<TicketWaiverModel>
  quickWaiver?: TicketWaiverModel
  delegatedTickets?: GlobalSearchModel<TicketWaiverModel> | GroupedSearchModel<TicketWaiverModel>
}

const initialAuthState: IOutletState = {}

// ACTIONS TYPES
export const actionTypes = {
  auth: {
    login: '[Digital Waiver] LOGIN',
    logout: '[Digital Waiver] LOGOUT',
    reload: '[Digital Waiver] RELOAD',
    requestAuth: '[Digital Waiver] REQUEST AUTH',
    fulfillAuth: '[Digital Waiver] FULFILL AUTH',
  },

  tickets: {
    search: {
      execute: '[Digital Waiver] SEARCH TICKETS',
      success: '[Digital Waiver] SEARCH TICKETS SUCCESS',
      failed: '[Digital Waiver] SEARCH TICKETS FAILED',
    },
  },

  expiredTickets: {
    search: {
      execute: '[Digital Waiver] SEARCH EXPIRED TICKETS',
      success: '[Digital Waiver] SEARCH EXPIRED TICKETS SUCCESS',
      failed: '[Digital Waiver] SEARCH EXPIRED TICKETS FAILED',
    },
  },

  quickWaiver: {
    search: {
      execute: '[Digital Waiver] SEARCH QUICK WAIVER',
      success: '[Digital Waiver] SEARCH QUICK WAIVER SUCCESS',
      failed: '[Digital Waiver] SEARCH QUICK WAIVER FAILED',
    },
  },

  events: {
    search: {
      execute: '[Digital Waiver] SEARCH EVENT',
      success: '[Digital Waiver] SEARCH EVENT SUCCESS',
      failed: '[Digital Waiver] SEARCH EVENT FAILED',
    },
  },
}

export const reducer = persistReducer(
  {storage, key: 'outlet', whitelist: ['auth']},
  (state: IOutletState = initialAuthState, action: ActionWithPayload<IOutletState>) => {
    switch (action.type) {
      case actionTypes.auth.login: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: auth?.token,
            user: undefined,
          },
        }
      }
      case actionTypes.auth.requestAuth: {
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: undefined,
          },
        }
      }

      case actionTypes.auth.fulfillAuth: {
        const auth = action.payload?.auth
        return {
          ...state,
          auth: {
            token: state.auth?.token,
            user: auth?.user,
          },
        }
      }

      case actionTypes.auth.logout: {
        return {
          ...state,
          auth: undefined,
        }
      }

      case actionTypes.tickets.search.success: {
        const tickets = action.payload?.tickets
        return {...state, tickets}
      }

      case actionTypes.expiredTickets.search.success: {
        const expiredTickets = action.payload?.expiredTickets
        return {...state, expiredTickets}
      }

      case actionTypes.quickWaiver.search.success: {
        const quickWaiver = action.payload?.quickWaiver
        return {...state, quickWaiver}
      }

      default:
        return state
    }
  }
)

// AFTER EFFECT
export function* saga() {
  yield takeLatest(actionTypes.auth.login, function* () {
    yield put(actions.auth.requestAuth())
  })

  yield takeLatest(actionTypes.auth.requestAuth, function* () {
    const {data} = yield VerifyCustomerAuthToken()
    yield put(actions.auth.fulfillUser(data.user))
  })

  yield takeLatest([actionTypes.tickets.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['digital-waiver-ticket']
      )
      const {data} = yield GetTicketList(filter)
      yield put(actions.tickets.searchSuccess(data))
    } catch (e) {
      yield put(actions.tickets.searchFailed())
    }
  })

  yield takeLatest([actionTypes.expiredTickets.search.execute], function* refresh() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['digital-waiver-ticket']
      )
      const {data} = yield GetExpiredTickets(filter)
      yield put(actions.expiredTickets.searchSuccess(data))
    } catch (e) {
      yield put(actions.expiredTickets.searchFailed())
    }
  })

  yield takeLatest(actionTypes.events.search.execute, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select(
        (state) => state.system.filters['digital-waiver-event']
      )
      const {data} = yield GetEvents(filter)
      yield put(actions.events.searchSuccess(data))
    } catch (e) {
      yield put(actions.events.searchFailed())
    }
  })
}

// ACTIONS
export const actions = {
  auth: {
    login: (token: string) => {
      return {type: actionTypes.auth.login, payload: {auth: {token}}}
    },
    logout: () => ({type: actionTypes.auth.logout}),
    requestAuth: () => ({type: actionTypes.auth.requestAuth}),
    fulfillUser: (user: CustomerUserModel) => ({
      type: actionTypes.auth.fulfillAuth,
      payload: {
        auth: {
          user,
        },
      },
    }),
  },
  tickets: {
    search: () => ({type: actionTypes.tickets.search.execute}),
    searchSuccess: (
      data: GlobalSearchModel<TicketWaiverModel> | WaiverSearchModel<TicketGroupModel>
    ) => ({
      type: actionTypes.tickets.search.success,
      payload: {tickets: data},
    }),
    searchFailed: () => ({type: actionTypes.tickets.search.failed}),
  },

  expiredTickets: {
    search: () => ({type: actionTypes.expiredTickets.search.execute}),
    searchSuccess: (
      data: GlobalSearchModel<TicketWaiverModel> | WaiverSearchModel<TicketGroupModel>
    ) => ({
      type: actionTypes.expiredTickets.search.success,
      payload: {expiredTickets: data},
    }),
    searchFailed: () => ({type: actionTypes.expiredTickets.search.failed}),
  },

  events: {
    search: () => ({
      type: actionTypes.events.search.execute,
    }),
    searchSuccess: (events: GlobalSearchModel<EventModel>) => ({
      type: actionTypes.events.search.success,
      payload: {events},
    }),
    searchFailed: () => ({type: actionTypes.events.search.failed}),
  },
}
