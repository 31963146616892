import {useCallback, useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {Body} from '../../../../../_metronic/layout/foh/Body'
import {Paper} from '../../../../components/utils/Paper'
import {TabList} from '../../components/TabList'
import {Cart} from '../../../../utils/Cart'
import {useCurrentOutletMenu} from '../../hooks/useCurrentOutletMenu'
import {ProductGrid} from '../../components/ProductGrid/ProductGrid'
import {ProductGridItemAttributes} from '../../components/ProductGrid/ProductGridItem'
import {ImageInputValue} from '../../../../components/inputs/FileInput/ImageInputValue'
import {VAT} from '../../../../../config/env'
import {useOutletOrderFormHandlers} from '../../hooks/useOutletOrderFormHandlers'
import {VatExclusivePriceTag} from '../../../../utils/VatExclusivePriceTag'
import {
  CreateOrderForm,
  CreateOrderFormValues,
  EMPTY_INITIAL_VALUES as CREATE_ORDER_EMPTY_INITIAL_VALUES,
  validationSchema,
} from '../../components/forms/CreateOrderForm'
import {Sidebar} from '../../../../../_metronic/layout/foh/Sidebar'
import {useFormik} from 'formik'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {PaymentType, PaymentTypeSelectModal} from '../../components/modals/PaymentTypeSelectModal'
import {ExceptionFactory} from '../../../../exceptions/ExceptionFactory'
import {VoucherCreditException} from '../../../../exceptions/VoucherCreditException'
import {PayOrder, SendOrderSMSConfirmation} from '../../redux/OutletCRUD'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {BankAuthorizationCodeModal} from '../../components/modals/BankAuthorizationCodeModal'
import {QRCodeModalInput} from '../../../../components/inputs/QRCode/QRCodeInputModal'
import {QrCodeDataParser} from '../../../../utils/Qr/QrCodeDataParser'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {useLoadingState} from '../../../../components/hooks/useLoadingState'
import {OrderSummaryModal} from '../../components/OrderSummaryModal'
import {PriceCalculator} from '../../../../utils/PriceCalculator'
import {useRadioStates} from '../../../../components/hooks/useModalStates'
import {NotEnoughBalanceModal} from '../../components/modals/NotEnoughBalanceModal'
import {DefaultFNBProductImage} from '../../../../../config/logos'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'

interface PageParams {
  orderCode: string
}

interface EditOrderFormValues extends CreateOrderFormValues {
  paymentType: PaymentType
  paymentAmount: number
  voucherCode: string
  authCode: string
}

const EMPTY_INITIAL_VALUES: EditOrderFormValues = {
  ...CREATE_ORDER_EMPTY_INITIAL_VALUES,
  paymentAmount: 0,
  paymentType: 'bank-card',
  voucherCode: '',
  authCode: '',
}

export const FohEditOrderPage = () => {
  const {setIsLoading, isLoading} = useLoadingState()
  const [voucherBalance, setVoucherBalance] = useState(0)
  const [currentTab, setCurrentTab] = useState('')
  const params = useParams<PageParams>()
  const menu = useCurrentOutletMenu()
  const {handleBack, handlePrint, order, updateOrder, refreshOrder, handlePayLater} =
    useOutletOrderFormHandlers(params.orderCode)
  const location = useLocation<{checkout?: boolean}>()
  const history = useHistory()
  const {pushError, push} = useAlerts()
  const {activateValue, getDeactivateHandler, isValueActive, deactivateValue} = useRadioStates()
  const [voucherAmount, setVoucherAmount] = useState<number | null>(null)
  const {staticUrls} = useAppConfig()

  const isCheckingOut = useMemo(() => {
    if (location.state && order && !order.isPaid) {
      return location.state.checkout || false
    }
    return false
  }, [location.state, order])

  const paymentPendingTotal = useMemo(() => {
    if (order) {
      const calculator = new PriceCalculator(order.total)
      order.payments?.forEach((payment) => {
        calculator.subtract(payment.amount)
      })
      return calculator.getValue()
    }
    return 0
  }, [order])

  const formik = useFormik({
    initialValues: EMPTY_INITIAL_VALUES,
    onSubmit: async (values) => {
      if (order) {
        const paymentType = values.paymentType
        if (paymentType === 'yas-pay') {
          const doneLoading = setIsLoading('yas-pay')
          try {
            const response = await PayOrder({
              amount: values.paymentAmount,
              orderCode: order.code,
              type: 'voucher',
              voucherCode: values.voucherCode,
            })
            await refreshOrder()
            if (!response.data?.fullyPaid) {
              activateValue('choose-payment-method')
            } else {
              if (typeof response.data?.voucher?.remainingAmount === 'number') {
                setVoucherAmount(response.data?.voucher?.remainingAmount)
              }
              activateValue('order-summary')
            }
          } catch (e) {
            const error = ExceptionFactory.fromError(e)
            if (error instanceof VoucherCreditException) {
              setVoucherBalance(error.balance)
              activateValue('payment-failed')
            } else {
              pushError(e)
            }
          } finally {
            doneLoading()
          }
        } else if (paymentType === 'bank-card') {
          await PayOrder({
            amount: paymentPendingTotal,
            orderCode: order.code,
            type: 'cc',
            authCode: values.authCode,
          })
          refreshOrder()
          activateValue('order-summary')
        }
      } else {
        push({message: 'Could not get order data.', variant: 'danger', timeout: 5000})
      }
    },
    validationSchema,
  })

  const handlePaymentTypeSelect = useCallback(
    (paymentType: PaymentType) => {
      formik.setFieldValue('paymentType', paymentType)
      if (paymentType === 'bank-card') {
        activateValue('bank-payment')
      } else {
        activateValue('voucher-payment')
      }
    },
    [formik, activateValue]
  )

  const addProductToCart = useCallback(
    (product: ProductGridItemAttributes) => {
      if (order && !order.isPaid) {
        const newCart = formik.values.cart.clone()
        if (newCart.hasItem(product.value)) {
          newCart.incrementCount(product.value)
        } else {
          newCart.addItem(
            product.value,
            product.label,
            new VatExclusivePriceTag(product.price, 'AED', VAT)
          )
        }
        formik.setFieldValue('cart', newCart)
      }
    },
    [formik, order]
  )

  const categories = useMemo((): SelectInputItem[] => {
    if (menu?.categories) {
      return menu.categories.map((category) => ({value: category.code, label: category.name}))
    }
    return []
  }, [menu?.categories])

  const products = useMemo((): ProductGridItemAttributes[] => {
    if (menu?.categories && currentTab) {
      const products: ProductGridItemAttributes[] = []
      menu.categories.some((category) => {
        if (category.code === currentTab && category.fnbProducts) {
          category.fnbProducts.forEach((product) => {
            products.push({
              currency: 'AED',
              image: product.file
                ? new ImageInputValue(staticUrls.public, product.file)
                : new ImageInputValue(DefaultFNBProductImage.src, product.name, product.code),
              label: product.name,
              price: product.price,
              value: product.code,
            })
          })
          return true
        }
        return false
      })
      return products
    }
    return []
  }, [currentTab, menu?.categories, staticUrls.public])

  const handlePay = useCallback(
    async (values: CreateOrderFormValues) => {
      const doneLoading = setIsLoading('pay')
      try {
        await updateOrder(values)
        activateValue('choose-payment-method')
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [activateValue, pushError, updateOrder, setIsLoading]
  )

  const handleAuthorizationModalSubmit = useCallback(
    (code: string) => {
      deactivateValue('payment-failed')
      formik.setFieldValue('authCode', code)
      formik.submitForm()
    },
    [deactivateValue, formik]
  )

  const handleQrCodeModalSubmit = useCallback(
    (code: string) => {
      if (code) {
        try {
          const qr = new QrCodeDataParser(code)
          formik.setFieldValue('voucherCode', qr.getVoucherCode())
          formik.submitForm()
          deactivateValue('voucher-payment')
        } catch (e) {
          push({
            message: 'Invalid QR Code.',
            timeout: 5000,
            variant: 'danger',
          })
        }
      }
    },
    [deactivateValue, formik, push]
  )

  const handleUseAnotherVoucher = useCallback(() => {
    activateValue('voucher-payment')
  }, [activateValue])

  const handleChooseAnotherPaymentType = useCallback(() => {
    activateValue('choose-payment-method')
  }, [activateValue])

  const handleOrderSummaryClose = useCallback(() => {
    if (order?.isPaid) {
      history.push('/')
    } else {
      setVoucherAmount(null)
      deactivateValue('order-summary')
    }
  }, [history, order, deactivateValue])

  const handleAddAnotherVoucher = useCallback(() => {
    formik.setFieldValue('paymentAmount', voucherBalance)
    formik.submitForm()
  }, [formik, voucherBalance])

  const handleSmsSend = useCallback(
    async (mobileNumber: string) => {
      if (order) {
        const doneLoading = setIsLoading('sms')
        try {
          await SendOrderSMSConfirmation(order.code, mobileNumber)
          push({message: 'SMS has been sent!', timeout: 5000, variant: 'success'})
          deactivateValue('order-summary')
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [deactivateValue, order, push, pushError, setIsLoading]
  )

  useOnChange(isCheckingOut, () => {
    if (isCheckingOut) {
      activateValue('choose-payment-method')
    }
  })

  useEffect(() => {
    if (menu?.categories) {
      const firstCategory = menu.categories[0]
      if (firstCategory) {
        setCurrentTab(firstCategory.code)
      }
    }
  }, [menu?.categories])

  useOnChange(order, () => {
    if (order) {
      const cart = new Cart()
      order?.orderItems?.forEach((item) => {
        if (item.product && item.status !== 'cancelled') {
          cart.addItem(
            item.product.code,
            item.product.name,
            new VatExclusivePriceTag(item.product.price, 'AED', VAT),
            item.qty
          )
        }
      })
      formik.setValues({
        cart,
        customerName: order.name || '',
        mobileNumber: order.mobile || '',
        orderType: order.orderType,
        tableNo: order.tableNo || '',
        submitType: null,
        paymentAmount: paymentPendingTotal,
        paymentType: formik.values.paymentType,
        authCode: formik.values.authCode,
        voucherCode: formik.values.voucherCode,
      })
    }
  })

  return (
    <>
      <Body
        header={
          <Paper className='foh-header'>
            <TabList tabs={categories} onClick={setCurrentTab} value={currentTab} />
          </Paper>
        }
      >
        <ProductGrid products={products} onClick={addProductToCart} />
      </Body>
      <Sidebar>
        <CreateOrderForm
          paymentRemaining={paymentPendingTotal}
          formik={formik}
          availableOrderTypes={menu?.orderType}
          isPaid={order?.isPaid}
          onPay={handlePay}
          onPayLater={handlePayLater}
          onBack={handleBack}
          onPrint={handlePrint}
          loading={isLoading}
        />
      </Sidebar>
      <PaymentTypeSelectModal
        onSelect={handlePaymentTypeSelect}
        onClose={getDeactivateHandler('choose-payment-method')}
        open={isValueActive('choose-payment-method')}
      />
      <BankAuthorizationCodeModal
        onSubmit={handleAuthorizationModalSubmit}
        open={isValueActive('bank-payment')}
        onClose={getDeactivateHandler('bank-payment')}
      />
      <QRCodeModalInput
        autoSubmit
        hideLabel
        hideButton
        open={isValueActive('voucher-payment')}
        onClose={getDeactivateHandler('voucher-payment')}
        onSubmit={handleQrCodeModalSubmit}
        loading={formik.isSubmitting || isLoading}
      />
      <NotEnoughBalanceModal
        onAddAnother={handleAddAnotherVoucher}
        balance={voucherBalance}
        onUseAnother={handleUseAnotherVoucher}
        onClose={getDeactivateHandler('payment-failed')}
        open={isValueActive('payment-failed')}
        currency='AED'
        onCancel={handleChooseAnotherPaymentType}
      />
      <OrderSummaryModal
        onSubmit={handleSmsSend}
        voucherAmount={voucherAmount}
        cart={formik.values.cart}
        open={isValueActive('order-summary')}
        defaultMobileNumber={formik.values.mobileNumber}
        onClose={handleOrderSummaryClose}
        loading={isLoading}
      />
    </>
  )
}
