import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {idExtractor} from '../../../../../utils/idExtractor'

export interface BillingHistoryTableActionProps {
  onDownload?: (data: BookingModel[]) => void //change to billingHistory model
  data: BookingModel[] //change to billingHistory model
  selected: TableRowId[]
  loading?: boolean
}

export const BillingHistoryTableActions = ({
  onDownload,
  data,
  selected,
  loading,
}: BillingHistoryTableActionProps) => {
  return (
    <TableSelectionAction
      disabled={loading}
      variant='primary'
      onClick={onDownload}
      idExtractor={idExtractor}
      items={data}
      selected={selected}
      filter={billingIsDownloadable}
    >
      Download Invoice
    </TableSelectionAction>
  )
}

//change to billingHistory model
export const billingIsDownloadable = (booking: BookingModel) => {
  return booking.status === 'closed'
}
