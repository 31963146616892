import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import TableWrapper from '../../../../components/tables/TableWrapper'
import {AccessLogModel} from '../../../../models/acs/AccessLogModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {TableColumns} from '../../../../models/TableColumns'

const tableColumns: TableColumns[] = [
  {
    dataField: 'barcode',
    text: 'barcode',
    isSortable: true,
    className: 'min-w-80px',
  },
  {
    dataField: 'booking',
    text: 'booking',
    isSortable: true,
    className: 'min-w-80px',
  },
  {
    dataField: 'customer',
    text: 'customer',
    isSortable: true,
  },
  {
    dataField: 'gate',
    text: 'gate',
    isSortable: true,
  },
  {
    dataField: 'user',
    text: 'user',
    isSortable: true,
  },
  {
    dataField: 'status',
    text: 'status',
    isSortable: true,
  },
  {
    dataField: 'reason',
    text: 'reason',
    isSortable: true,
  },
  {
    dataField: 'updatedAt',
    text: 'date',
    isSortable: true,
    className: 'min-w-150px mw-200px',
  },
]

const CsrAccessLog = () => {
  const _accessLogs: GlobalSearchModel<AccessLogModel> = useSelector<RootState>(
    ({ems}) => ems.accessLogs,
    shallowEqual
  ) as GlobalSearchModel<AccessLogModel>

  return (
    <>
      <Toolbar title='Customer Service Logs' />
      <TableWrapper
        columns={tableColumns}
        total={_accessLogs?.total}
        data={_accessLogs?.data}
        currentPageNumber={_accessLogs?.page}
        currentPageSize={_accessLogs?.limit}
        removeAction
      />
    </>
  )
}
export default CsrAccessLog
