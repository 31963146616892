import clsx from 'clsx'
import {ReactNode, useCallback, useMemo, useRef} from 'react'
import {Link, useHistory, useLocation, useRouteMatch, matchPath} from 'react-router-dom'
import {CustomerRegistrationFormLogoHeader} from '../../../../../config/logos'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import styles from './NavigatorLayout.module.scss'
import {CustomerImage} from '../../components/CustomerImage/CustomerImage'
import {CustomerChatUserModel} from '../../../../models/eva/CustomerChatUserModel'
import {PollModel} from '../../../../models/eva/PollModel'
import {useDrawerRef} from '../../../../components/Drawer/useDrawerRef'
import {SideDrawer} from './SideDrawer'
import {CustomerServicesListModel} from '../../../../models/CustomerModel'
export interface NavigatorLayoutProps {
  children?: ReactNode
}

export const NavigatorLayout = ({children}: NavigatorLayoutProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [drawer, setDrawer] = useDrawerRef()
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation<{
    from?: string
    selectedUser?: CustomerChatUserModel
    selectedPoll: PollModel
    selectedService: CustomerServicesListModel
  }>()
  const isRouteActive = useCallback(
    (route: string) => {
      if (route === '/agendas') {
        return match.path.includes(route) || location.pathname.includes('/topics')
      }
      return match.path.includes(route)
    },
    [location.pathname, match.path]
  )

  const canGoBack = useMemo(() => {
    return Boolean(location.state?.from)
  }, [location.state?.from])

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const goToInfoPage = useCallback(() => {
    history.push('/info', {
      from: location.pathname,
    })
  }, [history, location.pathname])

  const isFooterShown = useMemo(() => {
    if (location.pathname === '/info') {
      return !canGoBack
    }
    if (
      matchPath(location.pathname, {
        path: '/chat/:code',
      })
    ) {
      return !location.state?.selectedUser
    }

    if (
      matchPath(location.pathname, {
        path: '/polls/:code',
      })
    ) {
      return !location.state?.selectedPoll
    }

    if (
      matchPath(location.pathname, {
        path: '/customer-serices/:code',
      })
    ) {
      return !location.state?.selectedService
    }
    return true
  }, [
    canGoBack,
    location.pathname,
    location.state?.selectedPoll,
    location.state?.selectedService,
    location.state?.selectedUser,
  ])

  const isInfoIconShown = useMemo(() => {
    return location.pathname !== '/info'
  }, [location.pathname])

  useOnChange(match, () => {
    contentRef.current?.scrollBy({
      top: 0,
    })
  })

  return (
    <div className={styles.root}>
      <div className={clsx('p-5 d-flex justify-content-center bg-body', styles.header)}>
        {canGoBack && (
          <MetronicIconButton
            className={styles.backButton}
            iconType='Navigation'
            iconName='Angle-left'
            size='lg'
            variant='text'
            color='secondary'
            onClick={goBack}
          />
        )}
        {location.state?.selectedUser ? (
          <div className='w-100 ms-5 d-flex align-items-center'>
            <div>
              <CustomerImage
                className='p-0 ms-5 w-20px h-20px'
                customer={location.state.selectedUser}
              />
            </div>
            <div className='ms-3 '>
              <h2
                className={[
                  'text-start d-flex flex-column align-items-center justify-content-center m-0',
                ].join(' ')}
              >
                <div className={styles.guestName}>{location.state.selectedUser.name}</div>
              </h2>
            </div>
          </div>
        ) : location.state?.selectedPoll ? (
          <div className='w-100 ms-5 d-flex align-items-center'>
            <div className='ms-3 '>
              <h2
                className={[
                  'text-start d-flex flex-column align-items-center justify-content-center m-0 ms-3',
                ].join(' ')}
              >
                <div className={styles.guestName}>{location.state.selectedPoll.name}</div>
              </h2>
            </div>
          </div>
        ) : location.state?.selectedService ? (
          <div className='w-100 ms-5 d-flex align-items-center'>
            <div className='ms-3 '>
              <h2
                className={[
                  'text-start d-flex flex-column align-items-center justify-content-center m-0 ms-3',
                ].join(' ')}
              >
                <div className={styles.guestName}>{location.state.selectedService.type}</div>
              </h2>
            </div>
          </div>
        ) : (
          <img
            className='h-20px'
            src={CustomerRegistrationFormLogoHeader.src}
            alt={CustomerRegistrationFormLogoHeader.alt}
          />
        )}
        {isInfoIconShown && (
          <MetronicIconButton
            className={styles.infoButton}
            iconType='Code'
            iconName='Info-circle'
            size='md'
            variant='text'
            color='secondary'
            onClick={goToInfoPage}
          />
        )}
      </div>
      <div className={styles.content} ref={contentRef}>
        {children}
      </div>
      {isFooterShown && (
        <div className={styles.footer}>
          {ROUTES.map((route: RoutesType) => {
            if (route.path)
              return (
                <Link key={route.path} to={route.path}>
                  <button
                    className={clsx('btn rounded-0 p-0 text-white', {
                      'btn-primary': isRouteActive(route.path),
                      'btn-bg-light': !isRouteActive(route.path),
                    })}
                  >
                    <div className='mb-n1'>
                      <MetronicIcon
                        iconType={route.icon.type}
                        iconName={route.icon.name}
                        size='md'
                        className='text-white m-0'
                      />
                    </div>
                    <span>{route.label}</span>
                  </button>
                </Link>
              )
            else
              return (
                <div className={styles.service} key={route.label}>
                  <button
                    className={clsx('btn rounded-0 p-0 text-white btn-bg-light')}
                    onClick={() => drawer?.show()}
                  >
                    <div className='mb-n1'>
                      <MetronicIcon
                        iconType={route.icon.type}
                        iconName={route.icon.name}
                        size='md'
                        className='text-white m-0'
                      />
                    </div>
                    <span>{route.label}</span>
                  </button>
                </div>
              )
          })}
        </div>
      )}

      <SideDrawer drawerRef={setDrawer} isRouteActive={isRouteActive} />
    </div>
  )
}

interface RoutesType {
  path?: string
  label: string
  icon: {
    type: string
    name: string
  }
}

const ROUTES: RoutesType[] = [
  {
    path: '/agendas',
    label: 'Agenda',
    icon: {
      type: 'Interface',
      name: 'Calendar',
    },
  },
  {
    path: '/attendees',
    label: 'Attendees',
    icon: {
      type: 'Communication',
      name: 'Group',
    },
  },
  {
    path: '/chat',
    label: 'Chat',
    icon: {
      type: 'Communication',
      name: 'Chat1',
    },
  },
  {
    label: 'Services',
    icon: {
      type: 'Shopping',
      name: 'Settings',
    },
  },
]
