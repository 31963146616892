import React, {useMemo} from 'react'
import {Footer} from '../components/Footer'
import {HeaderWrapper} from '../components/header/HeaderWrapper'
import {Toolbar} from '../components/toolbar/Toolbar'
import {ScrollTop} from '../components/ScrollTop'
import {Content} from '../components/Content'
import {MasterInit} from '../MasterInit'
import {PageDataProvider} from '../core'
import {Main} from '../../partials'
import {useOutletAuth} from '../../../app/components/hooks/useOutletAuth'
import {AsideOutletManager} from '../components/aside/outlet-manager/AsideOutletManager'

export const OutletManagerLayout: React.FC = ({children}) => {
  const auth = useOutletAuth()
  const user = useMemo(() => auth?.getUser(), [auth])
  const topbarProps = useMemo(() => {
    if (!user) {
      return null
    }
    return {
      email: user.email,
      initials: user.avatarCode,
      name: user.name,
    }
  }, [user])

  if (!topbarProps) {
    return null
  }

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideOutletManager />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper topbarProps={topbarProps} />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <Main />
      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}
