import {useMemo} from 'react'
import {MetronicIconButton} from '../../../components/inputs/MetronicIconButton'
import {TableSelectioncheckbox} from '../../../components/tables/TableSelectionCheckbox'
import {TicketPortalModel} from '../../../models/ems/TicketModel'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {CollapsedProps} from './TicketCard/TicketCardGrid'
import {difference} from 'lodash'
interface CardCollapseRowProps {
  onClick?: (collapseKey: string) => void
  collapseKey: string
  keyValue?: string
  collapsedItem?: CollapsedProps
  onSelectGroupHandler?: (key: string) => void
  selected?: string[]
  items?: TicketPortalModel[] | VoucherModel[]
}

export const CardCollapseRow = ({
  collapseKey,
  onClick,
  keyValue,
  collapsedItem,
  onSelectGroupHandler,
  selected,
  items,
}: CardCollapseRowProps) => {
  const isSelected = useMemo(() => {
    if (items && selected && selected.length > 0) {
      const result = items.map((item) => item.code)
      return difference(result, selected).length === 0
    }
    return false
  }, [items, selected])
  const collapse = useMemo(() => {
    return (
      <div className='bg-transparent ff-admm-bold text-uppercase fs-2 px-1 mb-0 w-100 d-flex gap-3 justify-content-between'>
        {keyValue && onSelectGroupHandler && (
          <div className='d-flex flex-column justify-content-center'>
            <TableSelectioncheckbox
              disabled={false}
              checked={isSelected}
              onChange={() => onSelectGroupHandler(keyValue)}
              style={{
                width: '25px',
                height: '25px',
              }}
            />
          </div>
        )}

        <div
          className='d-flex gap-3 flex-grow-1 justify-content-between'
          onClick={() => onClick && onClick(collapseKey)}
        >
          <div className='mt-2'>{keyValue}</div>
          <div>
            <MetronicIconButton
              className='flex-shrink-0 bg-transparent'
              size='md'
              type='button'
              iconType='Navigation'
              iconName={
                collapsedItem?.value && collapsedItem.key === collapseKey
                  ? 'Angle-down'
                  : 'Angle-right'
              }
            />
          </div>
        </div>
      </div>
    )
  }, [keyValue, onSelectGroupHandler, isSelected, collapsedItem, collapseKey, onClick])

  return collapse
}
