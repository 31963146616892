import {useCallback, useState, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'
import {AdvancedFilterTypes, FilterModel} from '../../../../../models/FilterModel'
import {GetLocationsBySlug} from '../../../redux/CustomerPortalCRUD'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {SearchableSelectInput} from '../../../../../components/inputs/SearchableSelect'
import {CustomerSearchInputSelectItem} from '../../inputs/CustomerSearchInput'
import {MetronicIconPath} from '../../../../../components/inputs/MetronicIcon'
export interface FilterLocationInputMobileProps {
  label: string
  locationValue: CustomerSearchInputSelectItem | null
  placeholder?: string
  productCategorySlug?: string
  productCode?: AdvancedFilterTypes
  icon?: MetronicIconPath
  className?: string
  classes?: {
    input: string
  }
  setLocation: (data: CustomerSearchInputSelectItem | null) => void
}

export const FilterLocationInputMobile = ({
  label,
  placeholder,
  productCategorySlug,
  icon,
  setLocation,
  locationValue,
  className,
  classes,
  productCode,
}: FilterLocationInputMobileProps) => {
  const {pushError} = useAlerts()
  const searchDebounce = useDebounce(500)
  const [searchResult, setSearchResult] = useState<CustomerSearchInputSelectItem[]>()
  const id = useRef(uuidv4()).current

  const searchLocations = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetLocationsBySlug(filter)
        return data
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  const getSearchableFilterFieldFilterHandler = useCallback(
    async (filter: FilterModel) => {
      searchDebounce(async () => {
        const data = await searchLocations(filter)
        if (data) {
          const newData: CustomerSearchInputSelectItem[] = data.map((item) => {
            return {
              code: item.code,
              label: item.name,
              value: item.code,
            }
          })
          setSearchResult(newData)
        }
      })
    },
    [searchDebounce, searchLocations]
  )

  const getSearchableFilterFieldChangeHandler = useCallback(
    (valueInput: CustomerSearchInputSelectItem | null) => {
      if (valueInput) {
        setLocation(valueInput)
      } else {
        setLocation(null)
      }
    },
    [setLocation]
  )

  return (
    <SearchableSelectInput
      className={className}
      classes={classes}
      allowClear
      itemMapper={itemMapper}
      label={label}
      placeholder={placeholder}
      onSearch={(value) =>
        getSearchableFilterFieldFilterHandler({
          filters: {
            search: value,
            productCategorySlug,
            productCode,
          },
        })
      }
      items={searchResult ? searchResult : []}
      id={id}
      value={locationValue}
      onChange={getSearchableFilterFieldChangeHandler}
      filter={NO_FILTER}
      icon={icon}
    />
  )
}

const itemMapper = (item: CustomerSearchInputSelectItem) => ({label: item.label, value: item.value})

const NO_FILTER = () => true
