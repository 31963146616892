import {ReactNode, useMemo} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AuthPage} from '../../modules/poken/pages/AuthPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import {HeaderControls} from '../../components/layouts/DefaultLayout/Header/HeaderControls'
import {PokenLayout} from '../../components/layouts/PokenLayout/PokenLayout'
import {usePokenAuth} from '../../modules/poken/hooks/usePokenAuth'
import {AuthPokenHeaderThumbnail} from '../../modules/poken/components/AuthPokenHeaderThumbnail'
import {NavigationSectionMenuItem} from '../../../config/drawer'

const MENUE: NavigationSectionMenuItem[] = [
  {
    title: 'Pokens',
    to: '/pokens',
  },
]

export const Routes = () => {
  const auth = usePokenAuth()

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <PokenLayout menus={MENUE}>
        <HeaderControls>
          <AuthPokenHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </PokenLayout>
    )
  }, [auth])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthPage />
        </Route>
      )
    }
    return <Redirect from='/auth' to='/' />
  }, [auth])

  return (
    <Switch>
      <Route path='/logout' component={Logout} />
      <Route path='/auth/login/:hash' component={AuthPage} />
      <Route path='/auth/login' component={AuthPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://webntech.ae' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
  )
}
