import clsx from 'clsx'
import {ReactNode, useCallback, MouseEvent, useEffect, useRef} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Button} from '../Button'

export interface RichTextEditorBlockControlButtonProps {
  value?: string
  className?: string
  onClick?: (value?: string) => void
  children?: ReactNode
  active?: boolean
  isIcon?: boolean
  isPopOver?: boolean
  isShowPopOver?: boolean
  popOverChildren?: ReactNode
  onHidePopOver?(): void
}

export const RichTextEditorControlButton = ({
  value,
  className,
  onClick,
  children,
  active,
  isIcon,
  isPopOver,
  isShowPopOver,
  popOverChildren,
  onHidePopOver,
}: RichTextEditorBlockControlButtonProps) => {
  const newRef = useRef<HTMLButtonElement | null>(null)

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      onClick?.(value)
    },
    [value, onClick]
  )

  const handleOutsideClick = useCallback(
    (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        onHidePopOver?.()
      }
    },
    [onHidePopOver]
  )

  useEffect(() => {
    if (onHidePopOver) document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  return isPopOver ? (
    <OverlayTrigger
      show={isShowPopOver}
      rootClose={true}
      rootCloseEvent='click'
      placement='right'
      trigger='click'
      overlay={<Tooltip>{popOverChildren}</Tooltip>}
    >
      <Button
        ref={newRef}
        className={clsx(
          'rounded-0',
          {
            'btn-icon': isIcon,
          },
          className
        )}
        size='sm'
        type='button'
        variant={active ? 'primary' : 'text'}
        onMouseDown={handleClick}
      >
        {children}
      </Button>
    </OverlayTrigger>
  ) : (
    <Button
      className={clsx(
        'rounded-0',
        {
          'btn-icon': isIcon,
        },
        className
      )}
      size='sm'
      type='button'
      variant={active ? 'primary' : 'text'}
      onMouseDown={handleClick}
    >
      {children}
    </Button>
  )
}
