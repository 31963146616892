import {useCallback, useMemo} from 'react'
import {
  MetronicIconButton,
  MetronicIconButtonProps,
} from '../../../components/inputs/MetronicIconButton'
import {OutletOrderTypeButtonProps, getIconType} from './OutletOrderTypeButton'

export interface OutletOrderTypeIconButtonProps
  extends OutletOrderTypeButtonProps,
    Omit<MetronicIconButtonProps, 'iconName' | 'iconType' | 'onClick'> {}

export const OutletOrderTypeIconButton = ({
  orderType,
  onClick,
  availableOrderTypes,
  disabled,
  ...buttonProps
}: OutletOrderTypeIconButtonProps) => {
  const icon = useMemo(() => {
    return getIconType(orderType)
  }, [orderType])

  const isButtonDisabled = useMemo(() => {
    if (!availableOrderTypes) {
      return disabled
    }
    return !availableOrderTypes.includes(orderType)
  }, [availableOrderTypes, disabled, orderType])

  const handleClick = useCallback(() => {
    onClick && onClick(orderType)
  }, [onClick, orderType])

  return (
    <MetronicIconButton
      {...icon}
      onClick={handleClick}
      disabled={isButtonDisabled}
      {...buttonProps}
    />
  )
}
