import {useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {
  ReservationSeatInputItem,
  ReservationSeatInputItemAttributes,
  ReservationSeatInputItemValue,
} from './ReservationSeatInputItem'
import {Button} from '../Button'
import {v4 as uuid4} from 'uuid'
import {PortalReservationSeatInputItem} from './PortalReservationSeatInputItem'
import {ProductLocationModel} from '../../../models/ems/ReservationModel'
import {FormikContextType} from 'formik'
import {ReservationFormValues} from '../../../modules/customer-portal/components/wizards/ReservationWizard/ReservationWizard'

export interface ReservationSeatInputProps<T extends ReservationFormValues> {
  className?: string
  items: ReservationSeatInputItemAttributes[]
  values: Array<ReservationSeatInputItemValue>
  onChange: (newValues: ReservationSeatInputItemValue[]) => void
  label: string
  disabled?: boolean
  eventCode: string | null
  nextLocationId: number
  setNextLocationId: React.Dispatch<React.SetStateAction<number>>
  isPortal?: boolean
  productCode: string
  productName: string
  product: ProductLocationModel
  customerCode?: string
  qty: number
  initialValues: ReservationSeatInputItemValue
  selectedSeat?: ProductLocationModel
  formik: FormikContextType<T>
  open: boolean
}

export const ReservationSeatInput = <T extends ReservationFormValues>({
  className,
  onChange,
  items,
  values,
  label,
  disabled,
  eventCode,
  nextLocationId,
  setNextLocationId,
  isPortal = false,
  productCode,
  productName,
  customerCode,
  qty,
  initialValues,
  selectedSeat,
  open,
  product,
  formik,
}: ReservationSeatInputProps<T>) => {
  const selectedItems = useMemo(() => {
    return values.map((item) => item.value)
  }, [values])

  const filteredItems = useMemo(() => {
    return items
      .map((item) => {
        if (item.items) {
          const filteredItem = {
            ...item,
            items: item.items.filter((subItem) => !selectedItems.includes(subItem.value)),
          }
          return filteredItem
        }
        return item
      })
      .filter((item) => !selectedItems.includes(item.value))
  }, [items, selectedItems])

  const handleInputChange = useCallback(
    (changed: ReservationSeatInputItemValue) => {
      const inputIndex = values.findIndex((item) => item.id === changed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        const modifiedItem = newValues[inputIndex]
        modifiedItem.seatMaps = changed.seatMaps
        modifiedItem.value = changed.value
        modifiedItem.label = changed.label
        modifiedItem.productCode = changed.productCode
        modifiedItem.qty = changed.qty

        onChange(newValues)
      }
    },
    [values, onChange]
  )
  const handleRemove = useCallback(
    (removed: ReservationSeatInputItemValue) => {
      const inputIndex = values.findIndex((item) => item.value === removed.value)
      if (inputIndex >= 0) {
        const newValues = [...values]
        newValues.splice(inputIndex, 1)
        onChange(newValues)
      }
    },
    [onChange, values]
  )

  const isLocationDisabled = useCallback(
    (locationCode: string) => {
      return values.some((item) => item.value === locationCode)
    },
    [values]
  )
  const inputNodes = useMemo(() => {
    const nodes = values.map((item, i) => {
      return (
        <>
          {isPortal ? (
            <PortalReservationSeatInputItem
              key={`${item.value}${i}${uuid4}`}
              placeholder='Select a Location'
              onChange={handleInputChange}
              selectedItems={selectedItems}
              items={filteredItems}
              label={`${label} ${i + 1}`}
              onRemove={handleRemove}
              value={item}
              isLocationDisabled={isLocationDisabled}
              eventCode={eventCode}
              productCode={productCode}
              productName={productName}
              customerCode={customerCode}
              product={product}
              qty={qty}
              selectedSeat={selectedSeat}
              initialValues={initialValues}
              open={open}
              formik={formik}
            />
          ) : (
            <ReservationSeatInputItem
              key={`${item.value}${i}${uuid4}`}
              placeholder='Select a Location'
              onChange={handleInputChange}
              selectedItems={selectedItems}
              items={filteredItems}
              label={`${label} ${i + 1}`}
              onRemove={handleRemove}
              value={item}
              isLocationDisabled={isLocationDisabled}
              eventCode={eventCode}
              productCode={productCode}
              productName={productName}
              qty={qty}
            />
          )}
        </>
      )
    })
    return nodes
  }, [
    values,
    isPortal,
    handleInputChange,
    selectedItems,
    filteredItems,
    label,
    handleRemove,
    isLocationDisabled,
    eventCode,
    productCode,
    productName,
    customerCode,
    product,
    qty,
    selectedSeat,
    initialValues,
    open,
    formik,
  ])

  const isAllItemsSelected = useMemo(() => {
    const checkItem = (item: ReservationSeatInputItemAttributes): boolean => {
      if (values.some((value) => value.value === item.value)) {
        return true
      }
      if (item.items && item.items.length > 0) {
        return item.items.every((nestedItem) => checkItem(nestedItem))
      }

      return false
    }
    return items.every((item) => checkItem(item))
  }, [items, values])

  const hasBlankItem = useMemo(() => {
    return values.some((some) => some.value === '')
  }, [values])

  const handleOnAdd = useCallback(() => {
    const newLocation = {
      value: '',
      id: nextLocationId,
      seatMaps: null,
      label: '',
      productCode: '',
      qty: 0,
    }
    setNextLocationId(nextLocationId + 1)

    const newValues = [...values, newLocation]
    onChange(newValues)
  }, [nextLocationId, setNextLocationId, values, onChange])

  const button = useMemo(() => {
    let label = 'Add Location'
    if (hasBlankItem) {
      return null
    }
    if (!values.length && isAllItemsSelected) {
      label = 'No locations available'
    } else if (isAllItemsSelected) {
      label = 'No more locations available'
    }

    return (
      <Button
        size='sm'
        variant='primary'
        onClick={handleOnAdd}
        disabled={isAllItemsSelected || disabled}
        uppercase={false}
      >
        {label}
      </Button>
    )
  }, [disabled, handleOnAdd, hasBlankItem, isAllItemsSelected, values.length])

  return (
    <div className={clsx('mt-5', className)}>
      <div>{inputNodes}</div>
      {button}
    </div>
  )
}
