import {useCallback, useMemo, useState} from 'react'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {ForcedLandScape} from '../ForcedLandScape/LandscapeFullScreen'
import {PresentationItem} from './FullScreenPresentationSlides'
import {PresentationSlide} from './PresentationSlide'

export interface FullScreenPrentationViewProps {
  items: PresentationItem[]
  currentPageIndex: number
  onExit?: () => void
}

export const FullScreenPrentationView = ({
  items,
  currentPageIndex,
  onExit,
}: FullScreenPrentationViewProps) => {
  const [pageIndex, setPageIndex] = useState(currentPageIndex)

  const hasNext = useMemo(() => {
    return pageIndex < currentPageIndex
  }, [currentPageIndex, pageIndex])

  const hasPrevious = useMemo(() => {
    return pageIndex > 0
  }, [pageIndex])

  const handleNextClick = useCallback(() => {
    if (hasNext) {
      setPageIndex((pageIndex) => pageIndex + 1)
    }
  }, [hasNext])

  const handlePreviousClick = useCallback(() => {
    if (hasPrevious) {
      setPageIndex((pageIndex) => pageIndex - 1)
    }
  }, [hasPrevious])

  const currentItem = useMemo(() => items[pageIndex], [items, pageIndex])

  useOnChange(currentPageIndex, (previousPageIndex) => {
    if (previousPageIndex !== undefined && previousPageIndex <= pageIndex) {
      setPageIndex(currentPageIndex)
    }
  })

  return (
    <ForcedLandScape>
      <PresentationSlide
        className='h-100'
        hideNext={!hasNext}
        hidePrev={!hasPrevious}
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviousClick}
        imageSrc={currentItem?.image?.url}
        onExit={onExit}
      />
    </ForcedLandScape>
  )
}
