import {useState, useCallback} from 'react'
import {useFormik} from 'formik'
import {TicketSubmitWaiverForm} from './TicketSubmitWaiverForm'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {
  getWaiverSignPayload,
  initialValues,
  useSubmitTicketData,
} from '../../../hooks/useSubmitTicketData'
import {TicketWaiverModel} from '../../../../../models/digital-waiver/TicketWaiverModel'
import {
  GetCustomerByCode,
  SignQuickWaiverTicket,
  SignWaiverTicket,
} from '../../../redux/DigitalWaiverCRUD'
import {useWaiverAuth} from '../../../hooks/useWaiverAuth'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {FileInputValueCollection} from '../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useAppConfig} from '../../../../app-config/hooks/useAppConfig'
import {DateUtil} from '../../../../../utils/DateUtil'

export interface TicketSubmitWaiverFormSignProps {
  ticket?: TicketWaiverModel | null
  onRefresh?: () => void
  isOpen: boolean
  handleClose?: () => void
  isQuickWaiver?: boolean
}

const TicketSubmitWaiverFormSign = ({
  ticket,
  onRefresh,
  isOpen = false,
  handleClose,
  isQuickWaiver,
}: TicketSubmitWaiverFormSignProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const {pushError} = useAlerts()
  const auth = useWaiverAuth()

  const {waiverFormValidationSchema, resetCountries, countries} = useSubmitTicketData({
    ticket,
    isQuickWaiver,
  })

  const {staticUrls} = useAppConfig()

  useOnChange(isOpen, () => {
    if (isOpen) resetCountries()
  })

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: waiverFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true)

      try {
        const payload = getWaiverSignPayload({
          values,
          ticketCode: ticket ? ticket.code : '',
          delegateCode: ticket ? ticket.delegateCode : undefined,
          ticket,
        })
        if (isQuickWaiver) {
          await SignQuickWaiverTicket(payload)
        } else {
          await SignWaiverTicket(payload)
        }
        handleCloseDrawer()
        onRefresh && onRefresh()
      } catch (e) {
        pushError(e)
      } finally {
        setIsLoading(false)
        setSubmitting(false)
      }
    },
  })

  const getCustomerByCode = useCallback(async () => {
    if (auth?.getUser() && ticket && ticket.productDigitalWaiverType) {
      const {data} = await GetCustomerByCode({
        code: auth.getUser().code,
        waiverType: ticket.productDigitalWaiverType,
      })
      let imagesCodes: imagesCodesProps = {}
      if (data?.license) {
        if (data.license.number) formik.setFieldValue('licenseNumber', data.license.number)
        if (data?.digitalWaiver?.mobile) formik.setFieldValue('mobile', data.digitalWaiver.mobile)
        if (data?.digitalWaiver?.phone) formik.setFieldValue('phone', data.digitalWaiver.phone)
        if (data.license.country)
          formik.setFieldValue('licenseCountryCode', data.license.country.code)
        if (data.license.issueDate)
          formik.setFieldValue(
            'licenseIssueDate',
            DateUtil.getDateFromApiString(data.license.issueDate)
          )
        if (data.license.expiryDate)
          formik.setFieldValue(
            'licenseExpiryDate',
            DateUtil.getDateFromApiString(data.license.expiryDate)
          )
      }
      if (data?.digitalWaiver) {
        const imageFront = new FileInputValueCollection<ImageInputValue>()
        data.digitalWaiver.licenseFront &&
          imageFront.add(new ImageInputValue(staticUrls.private, data.digitalWaiver.licenseFront))

        const imageBack = new FileInputValueCollection<ImageInputValue>()
        data.digitalWaiver.licenseBack &&
          imageBack.add(new ImageInputValue(staticUrls.private, data.digitalWaiver.licenseBack))

        if (imageFront && imageFront.getFirst()) formik.setFieldValue('licenseFront', imageFront)

        imagesCodes['licenseFrontCode'] = data.digitalWaiver.licenseFront?.code
        if (imageBack && imageBack.getFirst()) formik.setFieldValue('licenseBack', imageBack)

        imagesCodes['licenseBackCode'] = data.digitalWaiver.licenseBack?.code

        const passportFront = new FileInputValueCollection<ImageInputValue>()
        data.digitalWaiver.passportFront &&
          passportFront.add(
            new ImageInputValue(staticUrls.private, data.digitalWaiver.passportFront)
          )

        const passportBack = new FileInputValueCollection<ImageInputValue>()
        data.digitalWaiver.passportBack &&
          passportBack.add(
            new ImageInputValue(staticUrls.private, data?.digitalWaiver.passportBack)
          )

        if (passportFront && passportFront.getFirst())
          formik.setFieldValue('passportFront', passportFront)

        imagesCodes['passportFrontCode'] = data.digitalWaiver.passportFront?.code
        if (passportBack && passportBack.getFirst())
          formik.setFieldValue('passportBack', passportBack)

        imagesCodes['passportBackCode'] = data.digitalWaiver.passportBack?.code
        if (Object.keys(imagesCodes).length > 0) {
          formik.setFieldValue('imagesCodes', imagesCodes)
        }
      }
    }
  }, [auth, formik, staticUrls.private, ticket])

  useOnChange(isOpen, () => {
    if (isOpen) getCustomerByCode()
  })

  const handleCloseDrawer = useCallback(() => {
    formik.resetForm()
    handleClose?.()
  }, [formik, handleClose])

  useOnChange(isOpen, () => {
    if (isOpen && auth?.getUser()) {
      formik.setFieldValue('name', auth.getUser().name)
      formik.setFieldValue('email', auth.getUser().email)
    }
  })

  return (
    <TicketSubmitWaiverForm
      isLoading={isLoading}
      formik={formik}
      isOpen={isOpen}
      countries={countries}
      ticket={ticket}
      isQuickWaiver={isQuickWaiver}
    />
  )
}

export default TicketSubmitWaiverFormSign

export interface imagesCodesProps {
  [key: string]: string | undefined
}
