import 'react-phone-number-input/style.css'
import {ReactNode, useCallback, useMemo, useState} from 'react'
import {InputLabel} from './InputLabel'
import PhoneInput from 'react-phone-number-input/input'
import {Country, getCountries, getCountryCallingCode} from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import clsx from 'clsx'
import {useOnChange} from '../hooks/useOnChange'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export interface MobileNumberInputWithSelectProps
  extends Omit<InputProps, 'value' | 'onChange' | 'ref'> {
  value: string
  onChange: (value: string) => void
  onChangePhoneCode?: (value: string) => void
  inputClassName?: string
  label?: string | ReactNode
  errorMessage?: string
  helperMessage?: string
  isTouched?: boolean
  noMargin?: boolean
  labelClassName?: string
  errorMessageClassName?: string
}

export const MobileNumberInputWithSelect = ({
  onChange,
  onChangePhoneCode,
  value,
  id,
  label,
  errorMessage,
  helperMessage,
  isTouched,
  className,
  onFocus,
  onBlur,
  inputClassName,
  labelClassName,
  errorMessageClassName,
  noMargin,
  ...inputProps
}: MobileNumberInputWithSelectProps) => {
  const [country, setCountry] = useState<Country | undefined>('AE')

  useOnChange(country, () => {
    if (country && onChangePhoneCode) onChangePhoneCode(`+${getCountryCallingCode(country)}`)
  })

  const handleChange = useCallback(
    (phoneNumber: string = '') => {
      onChange(phoneNumber)
    },
    [onChange]
  )

  const validationClassName = useMemo(() => {
    if (!isTouched) {
      return null
    }
    return errorMessage ? 'is-invalid' : 'is-valid'
  }, [errorMessage, isTouched])

  const footer = useMemo(() => {
    return (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          {isTouched && errorMessage && (
            <span className={errorMessageClassName} role='alert'>
              {errorMessage}
            </span>
          )}
        </div>
        <div className='fv-help-block'>
          {helperMessage && <span role='alert'>{helperMessage}</span>}
        </div>
      </div>
    )
  }, [errorMessage, errorMessageClassName, helperMessage, isTouched])

  return (
    <div className={clsx({'mb-5': !noMargin}, className)}>
      {label && (
        <InputLabel className={labelClassName} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <div className='d-flex gap-2'>
        <div
          style={{
            width: '210px',
          }}
        >
          <select
            className='form-select form-select-solid form-select-sm  h-100'
            value={country}
            onChange={(event) => {
              setCountry((event.target.value as Country) || undefined)
            }}
          >
            <option value='AE'> {`${en['AE']} +${getCountryCallingCode('AE')}`}</option>
            {getCountries().map((country) => {
              return (
                <option key={country} value={`${country}`}>
                  {`${en[country]} +${getCountryCallingCode(country)}`}
                </option>
              )
            })}
          </select>
        </div>
        <div className='flex-grow-1'>
          <PhoneInput
            className={clsx('form-control form-control-solid', validationClassName, inputClassName)}
            country={country}
            smartCaret={false}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
            onChange={handleChange}
            {...inputProps}
          />
        </div>
      </div>
      {footer}
    </div>
  )
}
