import {useMemo} from 'react'
import {BookingDetailModel} from '../../../../models/customer-portal/BookingDetailModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {TicketModel} from '../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {TicketsSeatManifest} from '../../components/SeatTicketManifest/TicketsSeatManifest'
import {
  CustomerProductBookingDetailTable,
  FromProp,
} from '../../components/tables/BookingProductTable/CustomerProductBookingDetailTable'
import {TicketsPageByProductAndEvent} from './TicketsPageByProductAndEvent'
import {VouchersPage} from './VouchersPage'

interface EventPortalDetailInnerRoutesProps {
  match: any
  event?: EventModel | null
  currentTicket?: TicketModel
  bookingCode?: string
  bookingDetails?: GlobalSearchModel<BookingDetailModel>
  bookingProductCode?: string
  locationCode?: string
  productCode?: string
  onFilterProductHandler: (filter: FilterModel) => void
  refreshCurrentDetails: () => void
  refreshTable: () => void
  customerCode?: string
  toPath: FromProp
}

export const EventPortalDetailInnerRoutes = ({
  match,
  event,
  bookingCode,
  bookingDetails,
  bookingProductCode,
  locationCode,
  productCode,
  onFilterProductHandler,
  refreshCurrentDetails,
  refreshTable,
  customerCode,
  toPath,
  currentTicket,
}: EventPortalDetailInnerRoutesProps) => {
  const bookingDetailsTable = useMemo(() => {
    return isBookingDetailShow(match) && bookingDetails ? (
      <CustomerProductBookingDetailTable
        toPath={toPath}
        data={bookingDetails}
        onFilter={onFilterProductHandler}
        bookingCode={bookingCode}
        customerCode={customerCode}
        onRefresh={refreshCurrentDetails}
        onRefreshCallback={refreshTable}
        cornersClassName='table-cut-corner-polygon rs-gradient'
        paginationClassName='px-10 pb-10'
        removedColumns={removedColumns}
      />
    ) : null
  }, [
    bookingCode,
    bookingDetails,
    customerCode,
    match,
    onFilterProductHandler,
    refreshCurrentDetails,
    refreshTable,
    toPath,
  ])

  const ticketTable = useMemo(() => {
    return isTicketTableShow(match) && event ? (
      <TicketsPageByProductAndEvent
        bookingProductCode={bookingProductCode}
        event={event}
        toPath={toPath}
        cornersClassName='table-cut-corner-polygon rs-gradient'
        paginationClassName='px-10 pb-10'
      />
    ) : null
  }, [bookingProductCode, event, match, toPath])

  const seats = useMemo(() => {
    return isSeatShow(match) ? (
      <TicketsSeatManifest
        currentTicket={currentTicket}
        locationCode={locationCode}
        productCode={productCode}
        eventCode={event?.code}
      />
    ) : null
  }, [currentTicket, event?.code, locationCode, match, productCode])

  const voucherTable = useMemo(() => {
    return event && isVoucherShow(match) ? (
      <VouchersPage event={event} bookingProductCode={bookingProductCode} />
    ) : null
  }, [bookingProductCode, event, match])

  return (
    <>
      {bookingDetailsTable}
      {ticketTable}
      {seats}
      {voucherTable}
    </>
  )
}

export const isTicketShow = (match: any): boolean => {
  return match.path.includes('/:bookingProductCode/ticket')
}

export const isTicketTableShow = (match: any): boolean => {
  return match.path.includes('/:bookingProductCode/ticket') && !match.path.includes('/seat')
}

export const isSeatShow = (match: any): boolean => {
  return match.path.includes('/seat')
}

export const isBookingDetailShow = (match: any): boolean => {
  return match.path.includes('/:bookingCode/booking-detail')
}

export const isVoucherShow = (match: any): boolean => {
  return match.path.includes('/:bookingProductCode/voucher')
}

export const removedColumns = ['isSeated', 'isConsumable', 'isTimeslot']
