import {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getUrlData} from '../../../../config/env'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useBooleanState} from '../../../components/hooks/useBooleanState'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {useQueryParams} from '../../../components/hooks/useQueryParams'
import {TextInput} from '../../../components/inputs'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {MetronicIconButton} from '../../../components/inputs/MetronicIconButton'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {LocationModel} from '../../../models/acs/LocationModel'
import {OutletModel, OutletOrderType} from '../../../models/fnb/OutletModel'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {OrderList} from '../components/OrderList'
import {OutletCard} from '../components/OutletCard/OutletCard'
import {Tabs} from '../components/Tabs/Tabs'
import {useOrders} from '../hooks/useOrders'
import {usePageNavigation} from '../hooks/usePageNavigation'
import {
  GetLocationByCode,
  GetLocationByTicketCode,
  SearchDigitalMenu,
  SearchDigitalMenuTypes,
} from '../redux/DigitalMenuCRUD'

interface PathParams {
  locationCode?: string
  ticketCode?: string
}

export const OutletsPage = () => {
  const query = useQueryParams()
  const [tab, setTab] = useState(query.get('tab') || 'delivery')
  const [outlets, setOutlets] = useState<OutletModel[]>([])
  const [location, setLocation] = useState<LocationModel>()
  const searchDebounce = useDebounce(500)
  const {
    goToLocationSelect,
    goToOutletMenu,
    goToOrderByCode,
    goToMainMenu,
    goToOutletTableReservation,
  } = usePageNavigation()
  const {locationCode, ticketCode} = useParams<PathParams>()
  const {mobileNumber, setMobileNumber, orders, searchOrders} = useOrders()
  const {push} = useAlerts()
  const [search, setSearch] = useState('')
  const [orderTypes, setOrderTypes] = useState<OutletOrderType[]>([])
  const {staticUrls} = useAppConfig()

  const eventCode = useMemo(() => {
    return getUrlData().eventCode
  }, [])

  const {
    enableState: setIsSearching,
    state: isSearching,
    disableState: setIsNotSearching,
  } = useBooleanState(false)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const handleSearchButtonClick = useCallback(() => {
    setIsSearching()
  }, [setIsSearching])

  const getSelectedOrderType = useCallback(() => {
    return tab !== 'orders' ? (tab as OutletOrderType) : undefined
  }, [tab])

  const getOutletClickHandler = useCallback(
    (outletCode: string) => () => {
      goToOutletMenu(outletCode, {
        orderType: getSelectedOrderType(),
      })
    },
    [getSelectedOrderType, goToOutletMenu]
  )

  const handleOrderClick = useCallback(
    (orderCode: string) => {
      goToOrderByCode(orderCode, {locationCode, ticketCode})
    },
    [goToOrderByCode, locationCode, ticketCode]
  )

  const getReserveTableHandlder = useCallback(
    (outlet: OutletModel) => {
      if (outlet.orderType.includes('dine-in')) {
        return () => {
          goToOutletTableReservation(outlet.code)
        }
      }
    },
    [goToOutletTableReservation]
  )

  const outletCards = useMemo(() => {
    const outletNodes: ReactNode[] = []
    outlets.forEach((outlet) => {
      if (outlet.file && outlet.orderType.includes(tab as OutletOrderType)) {
        outletNodes.push(
          <OutletCard
            key={outlet.code}
            onClick={getOutletClickHandler(outlet.code)}
            description={outlet.description}
            image={new ImageInputValue(staticUrls.public, outlet.file)}
            name={outlet.name}
            preparingTime={outlet.preparingTime}
            onReserveTable={getReserveTableHandlder(outlet)}
          />
        )
      }
    })
    return outletNodes
  }, [outlets, tab, getOutletClickHandler, staticUrls.public, getReserveTableHandlder])

  const orderList = useMemo(() => {
    return (
      <OrderList
        className='mt-8'
        mobileNumber={mobileNumber}
        onMobileNumberChange={setMobileNumber}
        orders={orders}
        onMobileNumberSearch={searchOrders}
        onOrderClick={handleOrderClick}
      />
    )
  }, [mobileNumber, setMobileNumber, orders, searchOrders, handleOrderClick])

  const resetOrderTypesList = useCallback(
    async (search?: string) => {
      try {
        const {data} = await SearchDigitalMenuTypes({
          locationCode,
          ticketCode,
          eventCode,
          filters: {search},
        })
        setOrderTypes(data)
      } catch (e) {
        goToMainMenu()
        push({message: 'Invalid code', variant: 'danger', timeout: 5000})
      }
    },
    [eventCode, goToMainMenu, locationCode, push, ticketCode]
  )

  const resetOutletList = useCallback(
    async (search?: string) => {
      try {
        const {data} = await SearchDigitalMenu({
          locationCode,
          ticketCode,
          eventCode,
          orderType: getSelectedOrderType(),
          filters: {search},
        })
        setOutlets(data)
      } catch (e) {
        goToMainMenu()
        push({message: 'Invalid code', variant: 'danger', timeout: 5000})
      }
    },
    [eventCode, getSelectedOrderType, goToMainMenu, locationCode, push, ticketCode]
  )

  const resetLists = useCallback(
    (search?: string) => {
      resetOutletList(search)
      resetOrderTypesList(search)
    },
    [resetOrderTypesList, resetOutletList]
  )

  const searchOutletsHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      searchDebounce(() => {
        resetLists(value)
      })
      setSearch(value)
    },
    [resetLists, searchDebounce]
  )

  const handleGoToLocationsSelect = useCallback(() => {
    goToLocationSelect()
  }, [goToLocationSelect])

  const handleTabChange = useCallback((tab: string) => {
    setTab(tab)
    setOutlets([])
  }, [])

  useOnChange(orderTypes, () => {
    const selectedOrderType = getSelectedOrderType()
    if (selectedOrderType && !orderTypes.includes(selectedOrderType)) {
      setTab(orderTypes[0] || tab)
    }
  })

  useOnChange(tab, () => {
    resetLists()
  })

  useOnChange(isSearching, () => {
    if (isSearching) {
      searchInputRef.current?.focus()
    }
  })

  useEffect(() => {
    if (ticketCode) {
      GetLocationByTicketCode(ticketCode).then(({data}) => {
        setLocation(data)
      })
    } else if (locationCode) {
      GetLocationByCode(locationCode).then(({data}) => {
        setLocation(data)
      })
    }
  }, [locationCode, ticketCode])

  const availableOrderTypes = useMemo((): SelectInputItem[] => {
    const tabs: SelectInputItem[] = []
    ORDER_TYPE_ITEMS.forEach((item) => {
      if (orderTypes.includes(item.value as OutletOrderType)) {
        tabs.push(item)
      }
    })
    tabs.push({label: 'Orders', value: 'orders'})
    return tabs
  }, [orderTypes])

  return (
    <div className='outlets-page-container'>
      <div className='outlets-navbar'>
        <div className='d-flex justify-content-between align-items-center p-5'>
          <button className='btn btn-icon' onClick={handleGoToLocationsSelect}>
            <MetronicIcon
              className='svg-icon-success m-0'
              iconType='Navigation'
              iconName='Angle-left'
              size='lg'
            />
          </button>
          {isSearching ? (
            <TextInput
              className='flex-grow-1'
              noMargin
              value={search}
              ref={searchInputRef}
              onBlur={setIsNotSearching}
              onChange={searchOutletsHandler}
            />
          ) : (
            <h1 className='flex-grow-1 ff-f1-regular m-0'>{location?.name}</h1>
          )}
          <MetronicIconButton
            className='rounded-circle ms-1'
            iconType='General'
            iconName='Search'
            size='md'
            onClick={handleSearchButtonClick}
          />
        </div>
        <Tabs
          className='flex-shrink-0'
          inactiveVariant='text'
          tabs={availableOrderTypes}
          value={tab}
          onClick={handleTabChange}
        />
      </div>
      <div className='outlets-content'>{tab === 'orders' ? orderList : outletCards}</div>
    </div>
  )
}

const ORDER_TYPE_ITEMS: SelectInputItem[] = [
  {
    value: 'delivery',
    label: 'Delivery',
  },
  {
    value: 'pickup',
    label: 'Pickup',
  },
  {
    value: 'dine-in',
    label: 'Dine-in',
  },
  {
    value: 'takeaway',
    label: 'Takeaway',
  },
]
