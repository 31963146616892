import {Ref, useCallback, useImperativeHandle, useMemo} from 'react'
import clsx from 'clsx'
import {v4 as uuidv4} from 'uuid'
import {ListCountInputItem, ListCountInputItemValue} from './ListCountInputItem'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {SelectInputItem} from '../SelectInput'
import {useOnChange} from '../../hooks/useOnChange'

export interface ListCountInputRefValue {
  addEmptyItem: () => void
  hasBlankItem: boolean
}

export interface ListCountInputProps<T> {
  className?: string
  searchResult?: GlobalSearchModel<T>
  onSearch: (filter: FilterModel) => void
  values: Array<ListCountInputItemValue<T>>
  onChange: (newValues: ListCountInputItemValue<T>[]) => void
  label: string
  disabled?: boolean
  itemMapper: (data: T) => SelectInputItem
  selectPlaceholder: string
  listRef?: Ref<ListCountInputRefValue> | null
  isInitialValue?: boolean
  isNew?: boolean
  isMinCount?: boolean
  isShowRemaining?: boolean
}

export const ListCountInput = <T,>({
  className,
  onChange,
  searchResult,
  onSearch,
  values,
  label,
  disabled,
  itemMapper,
  selectPlaceholder,
  listRef,
  isInitialValue,
  isNew,
  isMinCount,
  isShowRemaining,
}: ListCountInputProps<T>) => {
  const getInputId = useCallback(() => {
    return uuidv4()
  }, [])

  const handleOnAdd = useCallback(() => {
    const newValues: ListCountInputItemValue<T>[] = [
      ...values,
      {count: 0, data: null, id: getInputId(), isNew},
    ]
    onChange(newValues)
  }, [values, getInputId, isNew, onChange])

  useOnChange(isInitialValue, () => {
    if ((isInitialValue && values.length === 0) || (isInitialValue && isNew)) {
      handleOnAdd()
    }
  })

  const handleInputChange = useCallback(
    (changed: ListCountInputItemValue<T>) => {
      const inputIndex = values.findIndex((item) => item.id === changed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        const modifiedItem = newValues[inputIndex]
        modifiedItem.count = changed.count
        modifiedItem.type = changed.type
        modifiedItem.data = changed.data
        if (modifiedItem.data && modifiedItem.count === 0) {
          modifiedItem.count = 1
        }
        onChange(newValues)
      }
    },
    [values, onChange]
  )

  const handleRemove = useCallback(
    (removed: ListCountInputItemValue<T>) => {
      const inputIndex = values.findIndex((item) => item?.id === removed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        newValues.splice(inputIndex, 1)
        onChange(newValues)
      }
    },
    [onChange, values]
  )

  const inputNodes = useMemo(() => {
    const nodes = values
      .filter((item) => (isNew ? item.isNew : true))
      .map((item, i) => {
        return (
          <ListCountInputItem
            disabled={disabled}
            key={`${item.id}${i}`}
            onSearch={onSearch}
            placeholder={selectPlaceholder}
            onChange={handleInputChange}
            selectedItems={values}
            searchResult={searchResult}
            label={`${label} ${i + 1}`}
            onRemove={handleRemove}
            itemMapper={itemMapper}
            value={item}
            isMinCount={isMinCount}
            isShowRemaining={isShowRemaining}
          />
        )
      })
    return nodes
  }, [
    values,
    isNew,
    disabled,
    onSearch,
    selectPlaceholder,
    handleInputChange,
    searchResult,
    label,
    handleRemove,
    itemMapper,
    isMinCount,
    isShowRemaining,
  ])

  const hasBlankItem = useMemo(() => {
    return values.some((some) => some.data === null) || values.length === searchResult?.data.length
  }, [searchResult?.data.length, values])

  useImperativeHandle(listRef, () => ({hasBlankItem, addEmptyItem: handleOnAdd}), [
    handleOnAdd,
    hasBlankItem,
  ])

  return (
    <div className={clsx('', className)}>
      <div>{inputNodes}</div>
    </div>
  )
}
