import clsx from 'clsx'
import {
  SeatMapEditorInput,
  SeatMapEditorInputProps,
} from '../../../../components/inputs/SeatMapInput/SeatMapEditorInput'

export interface SeatMapSelectionPortalInputProps extends SeatMapEditorInputProps {
  classNameSeatMApEditor?: string
}

export const SeatMapSelectionPortalInput = ({
  className,
  classNameSeatMApEditor = 'h-50',
  disabled,
  selected,
  shared,
  occupied,
  ...props
}: SeatMapSelectionPortalInputProps) => {
  return (
    <>
      <div className={clsx('seat-map-selection-input', className)}>
        <SeatMapEditorInput
          className={classNameSeatMApEditor}
          selected={selected}
          disabled={disabled}
          occupied={occupied}
          shared={shared}
          disableRowClick
          isPortal={true}
          {...props}
        />
      </div>
    </>
  )
}
