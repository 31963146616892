import clsx from 'clsx'
import {useMemo} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {TableSelectioncheckbox} from '../../../../components/tables/TableSelectionCheckbox'
import {Paper} from '../../../../components/utils/Paper'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import {PortalShareStatusColumn} from '../tables/PortalShareStatusColumn'
// import {dayColor} from '../TicketCard/TicketCard'

export interface VoucherCardProps {
  data: VoucherModel
  className?: string
  tabCode?: string
  voucherTabCode?: string
  loading?: boolean
  onSelectHandler: (code: string) => void
  selected: string[]
}

export const VoucherCard = ({
  data,
  className,
  voucherTabCode,
  tabCode,
  onSelectHandler,
  selected,
}: VoucherCardProps) => {
  const history = useHistory()
  const linkTo = useMemo(() => {
    return `/voucher-tab/${voucherTabCode}/${tabCode}/voucher/${data.code}`
  }, [data.code, tabCode, voucherTabCode])

  const isSeleted = useMemo(() => {
    if (selected && selected.length)
      return selected.find((item) => item === data.code) ? true : false
    return false
  }, [data.code, selected])

  return (
    <Paper
      className={clsx(
        'p-5 d-flex flex-column justify-content-between position-relative',
        className
      )}
      rounded
    >
      <div className='card-circles'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>
      <div
        className=' border p-3'
        style={{
          borderColor: 'gray',
          borderWidth: '0.5px',
          borderRadius: '5px',
        }}
      >
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column'>
            <div className='fs-2 ff-admm-bold text-white text-uppercase d-flex gap-3'>
              <div className='d-flex flex-column justify-content-center'>
                {onSelectHandler && (
                  <TableSelectioncheckbox
                    disabled={false}
                    checked={isSeleted}
                    onChange={() => onSelectHandler(data.code)}
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                )}
              </div>
              <div>{data.name}</div>
            </div>
          </div>
        </div>
        {/* 
        <div
          className='fs-1 fw-bolder mt-2 text-uppercase'
          style={{
            color: dayColor(day),
          }}
        >
          {day}
        </div> */}

        {tabCode === 'shared' && (
          <>
            {data.share?.type && (
              <>
                <div className='fs-5 mt-2 text-uppercase text-white'>{`Shared With: ${data.share.name}`}</div>
                <div className='fs-5 mt-1 text-uppercase d-flex gap-1 text-white'>
                  <div>By:</div>
                  {data.share.type === 'whatsapp' ? (
                    <div className='d-flex align-items-center gap-1'>
                      <MetronicIconButton
                        variant='text'
                        color='white'
                        disabled
                        size='sm'
                        iconType='Communication'
                        iconName='Call'
                        className='h-auto w-auto'
                      />

                      <div className='fs-5 text-uppercase'>{`${
                        data.share?.mobile ? data.share.mobile : ''
                      }`}</div>
                    </div>
                  ) : data.share.type === 'email' ? (
                    <div className='d-flex align-items-center gap-1'>
                      <MetronicIconButton
                        variant='text'
                        color='white'
                        size='sm'
                        disabled
                        iconType='Communication'
                        iconName='Mail'
                        className='h-auto w-auto'
                      />

                      <div className='fs-5 text-uppercase'>{`${
                        data.share?.email ? data.share.email : ''
                      }`}</div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        )}
        <div className='mt-2 d-flex justify-content-between'>
          <PortalShareStatusColumn className='fs-5' data={data} />
          <Link className='text-white fs-5' to={linkTo}>
            {data.booking?.code || data.code}
          </Link>
        </div>
        <div>
          <Button
            fullWidth
            className='ff-admm-bold mt-3 rounded-0 py-2 btn-select-all-cp'
            onClick={() => history.push(linkTo)}
            style={{
              fontSize: '15px',
            }}
          >
            Click to show more
          </Button>
        </div>
      </div>
    </Paper>
  )
}
