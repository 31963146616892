import clsx from 'clsx'
import {ColorVariant} from '../inputs/Button'
import {MetronicIcon} from '../inputs/MetronicIcon'

export interface ActivityTimelineItemAttributes {
  label: string
  icon?: {
    name: string
    type: string
  }
}

export interface ActivityTimelineItemProps extends ActivityTimelineItemAttributes {
  active: boolean
  isLastItem: boolean
  className?: string
  width?: string
  variant: ColorVariant
}

export const ActivityTimelineItem = ({
  label,
  icon,
  active,
  className,
  variant,
}: ActivityTimelineItemProps) => {
  return (
    <div
      className={clsx(
        'activity-timeline-item',
        {
          'opacity-25': !active,
        },
        className
      )}
    >
      <MetronicIcon
        className={clsx('activity-timeline-item-dot', {[`svg-icon-${variant}`]: active})}
        iconType='Design'
        iconName='Circle'
      />
      {icon && (
        <MetronicIcon
          className={clsx({[`svg-icon-${variant}`]: active})}
          iconType={icon.type}
          iconName={icon.name}
          size='md'
        />
      )}
      <p className='activity-timeline-item__text'>{label}</p>
    </div>
  )
}
