import {OnResultFunction, useQrReader} from './useQrReader'

export interface QrReaderPropsVideo {
  constraints: MediaTrackConstraints
  onResult: OnResultFunction
  scanDelay?: number
  className?: string
  disabled?: boolean
}

export const QrReaderVideo = ({
  constraints,
  className,
  onResult,
  scanDelay,
  disabled,
}: QrReaderPropsVideo) => {
  const {videoRef} = useQrReader({
    constraints,
    scanDelay,
    onResult,
    disabled,
  })

  return <video playsInline autoPlay className={className} ref={videoRef} muted />
}
