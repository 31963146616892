import {FormikContextType} from 'formik'
import {useCallback, useMemo} from 'react'
import {ProductInput} from '../../../../../components/inputs/ProductInput/ProductInput'
import {
  ProductInputItemAttributes,
  ProductInputItemValue,
} from '../../../../../components/inputs/ProductInput/ProductInputItem'
import {ProductModel} from '../../../../../models/ems/ProductModel'

export interface NewRegistrationProductStepValues {
  products: ProductInputItemValue[]
}

export interface NewRegistrationProductStepProps<T extends NewRegistrationProductStepValues> {
  formik: FormikContextType<T>
  products: ProductModel[]
}
export const NewRegistrationProductStep = <T extends NewRegistrationProductStepValues>({
  formik,
  products,
}: NewRegistrationProductStepProps<T>) => {
  const hasProductsAvailable = useMemo(() => {
    return products.length > 0
  }, [products.length])

  const handleOnChangeProduct = useCallback(
    (newValues: ProductInputItemValue[]) => {
      formik.setFieldValue('products', newValues)
    },
    [formik]
  )
  const productInputItems = useMemo((): ProductInputItemAttributes[] => {
    return products.map((product) => {
      return {
        label: product.name,
        value: product.code,
        price: product.price,
        type: 'product',
      }
    })
  }, [products])

  return (
    <>
      <ProductInput
        disabled={!hasProductsAvailable}
        items={productInputItems}
        label='Product'
        onChange={handleOnChangeProduct}
        values={formik.values.products}
      />
    </>
  )
}

export const STEP_PRODUCT_KEYS: Array<keyof NewRegistrationProductStepValues> = ['products']
