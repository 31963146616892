import {forwardRef, useCallback, useImperativeHandle} from 'react'
import QRCode from 'react-qr-code'
import {useId} from '../hooks/useId'

export interface QrCodeProps {
  value: string
  className?: string
  size?: number
}

export interface DownloadOptions {
  textColor?: string
  borderColor?: string
  text?: string
}

export interface QrCodeRefValue {
  download: (options?: DownloadOptions) => void
}

export const QrCode = forwardRef<QrCodeRefValue, QrCodeProps>(
  ({value, className, size = 256}, ref) => {
    const id = useId()

    const download = useCallback(
      ({borderColor = 'white', text, textColor = 'black'}: DownloadOptions = {}) => {
        const svg = document.getElementById(id)
        if (svg) {
          const svgData = new XMLSerializer().serializeToString(svg)
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const img = new Image()
          if (ctx) {
            img.onload = function () {
              const fontSize = size / 15
              const innerPadding = size / 20
              const outerPadding = size / 20
              const padding = innerPadding + outerPadding
              canvas.width = img.width + padding * 2
              canvas.height = img.height + padding * 2

              if (text) {
                canvas.height += fontSize
              }
              ctx.fillStyle = borderColor
              ctx.fillRect(0, 0, canvas.width, canvas.height)

              ctx.fillStyle = 'white'
              roundedRect(
                ctx,
                outerPadding,
                outerPadding,
                canvas.width - outerPadding * 2,
                canvas.height - outerPadding * 2 - (text ? fontSize : 0),
                padding / 2
              )
              ctx.fill()

              ctx.drawImage(img, padding, padding)

              if (text) {
                ctx.font = `${fontSize}px Arial`
                ctx.textAlign = 'center'
                ctx.fillStyle = textColor
                ctx.fillText(text, canvas.width / 2, canvas.height - fontSize / 2)
              }

              const pngFile = canvas.toDataURL('image/png')
              const downloadLink = document.createElement('a')
              downloadLink.download = 'qrcode'
              downloadLink.href = `${pngFile}`
              downloadLink.click()
            }
            img.src = 'data:image/svg+xml;base64,' + btoa(svgData)
          }
        }
      },
      [id, size]
    )

    useImperativeHandle(ref, () => ({download}), [download])

    return <QRCode className={className} size={size} id={id} value={value} />
  }
)

function roundedRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
}
