import {useMemo} from 'react'
import {useQueryParams} from './useQueryParams'

export const useQueryParam = (query: string) => {
  const queryParam = useQueryParams()

  const value = useMemo(() => {
    return queryParam.get(query)
  }, [query, queryParam])

  return {value}
}
