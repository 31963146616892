import {forwardRef, ReactNode, DetailedHTMLProps, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useId} from '../hooks/useId'

export type ColorVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'warning'
  | 'success'
  | 'info'
  | 'light'
  | 'dark'
  | 'white'

export type ButtonVariant = 'default' | 'link' | 'text' | ColorVariant

export type ButtonSize = 'sm' | 'md' | 'lg' | 'flush'

type ButtonElementProps = Omit<
  DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
>

export interface ButtonProps extends ButtonElementProps {
  className?: string
  children?: ReactNode
  disabled?: boolean
  variant?: ButtonVariant
  tooltip?: string
  color?: ColorVariant
  uppercase?: boolean
  size?: ButtonSize
  activeColor?: ColorVariant
  light?: boolean
  fullWidth?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'default',
      className,
      tooltip,
      color,
      size = 'md',
      uppercase = true,
      activeColor,
      light = true,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const randomId = useId()

    const sizeClassName = useMemo(() => {
      switch (size) {
        case 'flush':
        case 'lg':
        case 'sm':
          return `btn-${size}`
        default:
          return ''
      }
    }, [size])

    const tooltipProps = useMemo(() => {
      if (tooltip) {
        return {
          'data-toggle': 'tooltip',
          'data-placement': 'top',
          title: tooltip,
        }
      }
    }, [tooltip])

    const getActiveColor = useCallback(() => {
      if (activeColor) {
        return `btn-active-color-${activeColor}`
      }
    }, [activeColor])

    const getVariant = useCallback(() => {
      if (variant === 'default') {
        if (light) {
          return 'btn-bg-light'
        }
        return 'btn-bg'
      }
      if (light) {
        return `btn-light-${variant}`
      }
      return `btn-${variant}`
    }, [light, variant])

    const getColorVariant = useCallback(() => {
      if (color) {
        return `btn-color-${color}`
      }
    }, [color])

    const button = (
      <button
        ref={ref}
        className={clsx(
          'btn',
          sizeClassName,
          {'text-uppercase': uppercase, 'w-100': fullWidth},
          getVariant(),
          getActiveColor(),
          getColorVariant(),
          className
        )}
        {...tooltipProps}
        {...props}
      >
        {children}
      </button>
    )
    if (tooltip) {
      return (
        <OverlayTrigger
          placement='auto'
          overlay={<Tooltip id={`${tooltip}${randomId}`}>{tooltip}</Tooltip>}
        >
          {button}
        </OverlayTrigger>
      )
    }
    return button
  }
)
