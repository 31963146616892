import {MutableRefObject, useRef} from 'react'

type InputRef<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null | undefined

export const useRefFork = <T>(initialValue: T, refsToShare: Array<InputRef<T>>) => {
  const innerRef = useRef<T>(initialValue)

  function sharingRef(value: T) {
    innerRef.current = value
    refsToShare.forEach((resolvableRef) => {
      if (typeof resolvableRef === 'function') {
        resolvableRef(value)
      } else {
        if (resolvableRef) {
          resolvableRef.current = value
        }
      }
    })
  }

  /**
   * We want ref we return to work using .current, but it has to be function in order
   * to share value with other refs.
   *
   * Let's add custom get property called 'current' that will return
   * fresh value of innerRef.current
   */
  if (!(sharingRef as any).current) {
    Object.defineProperty(sharingRef, 'current', {
      get() {
        return innerRef.current
      },
    })
  }

  return sharingRef as typeof sharingRef & {current: T}
}
