import {ReactNode} from 'react'
import {Variant} from 'react-bootstrap/esm/types'

export interface TableColumnBadgeProps {
  children?: ReactNode
  variant?: TableColumnBadgeVariant
}

export type TableColumnBadgeVariant = Variant

export const TableColumnBadge = ({children, variant = 'text'}: TableColumnBadgeProps) => {
  return <span className={`badge badge-light-${variant} text-uppercase`}>{children}</span>
}
