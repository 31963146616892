import {useCallback, useMemo, useState} from 'react'
import {EventModel} from '../../../../models/ems/EventModel'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {FilterModel} from '../../../../models/FilterModel'
import {actions} from '../../redux/CustomerPortalRedux'
import {GetReservationByCode, SearchReservationItems} from '../../redux/CustomerPortalCRUD'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import PortalHeader, {LinkType} from '../../components/Headers/PortalHeader'
import {ReservationPortalModel} from '../../../../models/ems/ReservationMedel'
import {ReservationDetailsHeader} from '../../components/ReservationDetailsHeader'
import {ReservationTable} from '../../components/tables/ReservationTable/ReservationTable'
import {EventPortalDetailReservationCreate} from './EventPortalDetailReservationCreate'
import {CustomerReservationTable} from '../../../default/ems/components/CustomerReservationTable'
import {ReservationItemModel} from '../../../../models/ems/ReservationModel'
import {useAlerts} from '../../../../components/alerts/useAlerts'

export interface EventPortalDetailReservationProps {
  event: EventModel | null
}

interface RouteMatch {
  reservationCode?: string
  customerCode?: string
  eventCode?: string
}
const EventPortalDetailReservation = ({event}: EventPortalDetailReservationProps) => {
  const match = useRouteMatch<RouteMatch>()
  const [reservationFilters, setReservationFilter] = useState<FilterModel>()
  const [reservationData, setReservationData] = useState<GlobalSearchModel<ReservationItemModel>>()
  const {reservationCode, customerCode} = match.params
  const history = useHistory()
  const breakpoints = useBreakpoint()
  const isMobile = useMemo(() => breakpoints.down('md'), [breakpoints])
  const reservations = useRootStateSelector((state) => state.customerPortal.reservations)
  const [currentReservation, setCurrentReservation] = useState<ReservationPortalModel>()
  const searchDebounce = useDebounce(400)
  const {pushError} = useAlerts()
  const {setFilter} = useEntityFilter('customer-portal-reservation')

  const dispatch = useDispatch()

  interface LinksTypes {
    [key: string]: LinkType[]
  }

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active', 'released'],
      },
      groupBy: '',
    }
    if (event && filters.filters) {
      filters.filters.event = event.code
    }

    return filters
  }, [event])

  const links: LinksTypes = useMemo(() => {
    let reservation = [
      {
        title: 'Sub-Account Details',
        to: `/event/${event?.code}/reservation/customer/${customerCode}/reservation-details/${reservationCode}`,
      },
    ]
    return {
      reservation,
    }
  }, [customerCode, event?.code, reservationCode])

  const refreshCurrentReservationDetails = useCallback(async () => {
    if (reservationCode) {
      const {data} = await GetReservationByCode(reservationCode)
      setCurrentReservation(data)
    }
  }, [reservationCode])

  const refreshReservationTable = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.reservations.search())
    })
  }, [dispatch, searchDebounce])

  useOnChange(reservationCode, () => {
    if (reservationCode) {
      refreshCurrentReservationDetails()
    }
  })

  const handleFilterReservationTable = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      refreshReservationTable()
    },
    [setFilter, refreshReservationTable]
  )

  const handleSplitPaneClose = useCallback(() => {
    if (event) history.push(`/event/${event.code}/reservation`)
  }, [event, history])

  const handleCreateRecord = useCallback(() => {
    if(event) history.push(`/event/${event.code}/reservation/new`)
  }, [event, history])

  //NEW TABLE
  const refreshReservationList = useCallback(async () => {
    try {
      const {data} = await SearchReservationItems(reservationFilters)
      setReservationData(data)
    } catch (e: any) {
      pushError(e)
    }
  }, [reservationFilters, pushError])

  const onFilterReservationHandler = useCallback(
    async (filter: FilterModel) => {
      if (reservationCode) {
        try {
          const filters = {
            ...filter,
            filters: {...filter.filters, reservation: reservationCode},
          }
          setReservationFilter(filters)
          const {data} = await SearchReservationItems(filters)
          setReservationData(data)
        } catch (e: any) {
          pushError(e)
        }
      }
    },
    [reservationCode, pushError]
  )

  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/new/:reservationCode`}>
          <EventPortalDetailReservationCreate event={event} step={3} />
        </Route>
        <Route exact path={`${match.path}/new`}>
          <EventPortalDetailReservationCreate event={event} />
        </Route>
        <Route path={`${match.path}`}>
          <PaneContainer className='flex-grow-1' direction='horizontal'>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '0%' : '35%'}>
              {event && (
                <>
                  <div className='d-none d-md-block'>
                    <ReservationTable
                      onFilter={handleFilterReservationTable}
                      data={reservations}
                      onRefresh={refreshReservationTable}
                      onRefreshCallback={refreshCurrentReservationDetails}
                      event={event}
                      initialFilters={initialFilters}
                      onNewReservationClick={handleCreateRecord}
                      className='d-none d-md-block'
                      cornersClassName='table-cut-corner-polygon rs-gradient'
                      paginationClassName='px-10 pb-10'
                    />
                  </div>
                </>
              )}
            </Pane>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '100%' : '65%'}>
              <MetronicIconButton
                size='sm'
                iconType='Navigation'
                iconName='Arrow-from-left'
                onClick={handleSplitPaneClose}
                variant='primary'
                tooltip='Close Pane'
                className='mb-1'
              />
              <>
                <ReservationDetailsHeader
                  customer={currentReservation?.customer}
                  reservation={currentReservation}
                  onRefresh={refreshCurrentReservationDetails}
                  onRefreshCallback={refreshReservationTable}
                />
                <PortalHeader links={links.reservation} />
                <CustomerReservationTable
                  data={reservationData}
                  onFilter={onFilterReservationHandler}
                  onRefresh={refreshReservationList}
                  cornersClassName='table-cut-corner-polygon rs-gradient'
                  paginationClassName='px-10 pb-10'
                />
              </>
            </Pane>
          </PaneContainer>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export default EventPortalDetailReservation
