import {FormikContextType} from 'formik'
import {TextInput} from '../../../../components/inputs'

import * as Yup from 'yup'
import {getWaiverStatusVariant} from '../DigitalWaiverCard/DigitalWaiverCard'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
interface DelegatedFormProps {
  formik: FormikContextType<InitialValuesProps>
  data: TicketWaiverModel
  tabCode?: string
}

export interface InitialValuesProps {
  email: string
  name: string
}

export const initialValues: InitialValuesProps = {
  email: '',
  name: '',
}

export const DelegatedForm = ({formik, data, tabCode}: DelegatedFormProps) => {
  return (
    <div className=''>
      <div className='flex-grow-1 text-start d-flex flex-column justify-content-center mb-4'>
        <p className='text-white mb-0 fw-bolder'>
          Please enter the full name and emailof the person you are sharing this experience with
        </p>
      </div>

      <TextInput
        type='name'
        placeholder='Enter Name'
        inputClassName='rounded-pill fs-6 text-white delegated-form'
        {...formik.getFieldProps('name')}
        errorMessage={formik.errors.name}
        isShowErrorMessage={false}
        isTouched={formik.touched.name}
        inputStyle={{
          backgroundColor: `${getWaiverStatusVariant({data, tabCode, transparent: '40'})}`,
          border: `1px solid ${getWaiverStatusVariant({data, tabCode})}`,
        }}
      />
      <TextInput
        type='email'
        placeholder='Enter Email Address'
        inputClassName='rounded-pill fs-6 text-white delegated-form'
        {...formik.getFieldProps('email')}
        errorMessage={formik.errors.email}
        isShowErrorMessage={false}
        isTouched={formik.touched.email}
        inputStyle={{
          backgroundColor: `${getWaiverStatusVariant({data, tabCode, transparent: '40'})}`,
          border: `1px solid ${getWaiverStatusVariant({data, tabCode})}`,
        }}
      />
    </div>
  )
}

export const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})
