import {useMemo, useCallback} from 'react'
import Cookies from 'js-cookie'
export interface CookiesProps {
  name: string
  value: string
  path?: string
  domain?: string
}

export const useCookies = () => {
  const setCookie = useCallback((options: CookiesProps[], exdays: number) => {
    // const d = new Date()
    // d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    // const expires = d.toUTCString()
    // options.forEach((item) => {
    //   Cookies.set(item.name, item.value, {
    //     sameSite: 'none',
    //     secure: true,
    //     expires: parseInt(expires),
    //     domain: item.domain ? item.domain : '/',
    //   })
    // })
    Cookies.set('cookies', 'true', {
      sameSite: 'none',
      secure: true,
    })
  }, [])

  const clearCookies = useCallback((cookies: CookiesProps[]) => {
    cookies.forEach((item) => {
      Cookies.remove(item.name, {sameSite: 'none', path: item.domain})
    })
    Cookies.remove('cookies')
  }, [])
  return useMemo(
    () => ({
      setCookie,
      clearCookies,
      //   getCookie
    }),
    [setCookie, clearCookies]
  )
}
