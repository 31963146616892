import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {FileType} from '../FileInput/FileInput'
import {BaseFileInputValue} from '../FileInput/BaseFileInputValue'
import {FileInputValueCollection} from '../FileInput/FileInputValueCollection'
import {ImageInputValue} from '../FileInput/ImageInputValue'
import {FileInputValue} from '../FileInput/FileInputValue'
import {DragDropFileInputProps} from '../FileInput/DragDropFIleInput/DragDropFileInput'

export type UseFormikFileInputHelpersProps<T extends BaseFileInputValue> = Pick<
  DragDropFileInputProps<T>,
  'onChange' | 'value' | 'fileFactory'
>

export const useFormikFileInputHelpers = (formik: FormikContextType<any>, fileType?: FileType) => {
  const fileFactory = useCallback(
    (file: File) => {
      if (fileType === FileType.IMAGE) {
        return new ImageInputValue(file)
      } else {
        return new FileInputValue(file)
      }
    },
    [fileType]
  )

  const getProps = useCallback(
    (name: string): UseFormikFileInputHelpersProps<BaseFileInputValue> => {
      return {
        onChange: (value: FileInputValueCollection<BaseFileInputValue>) => {
          formik.setFieldValue(name, value)
        },
        value: formik.values[name],
        fileFactory,
      }
    },
    [fileFactory, formik]
  )

  return {
    getProps,
  }
}
