import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {Drawer} from '../../../../../components/Drawer/Drawer'
import {useDrawerRef} from '../../../../../components/Drawer/useDrawerRef'
import {useSafeStateUpdate} from '../../../../../components/hooks/useSafeStateUpdate'
import {
  EMPTY_INITIAL_VALUES,
  getPayload,
  validationSchema,
  ReservationFormValues,
  ReservationForm,
  mapDataToFormValues,
} from './ReservationForm'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {ReservationModel} from '../../../../../models/outlet/ReservationModel'
import {UpdateTableReservation} from '../../../redux/OutletCRUD'
import {actions} from '../../../redux/OutletRedux'

export interface ReservationEditDrawerProps {
  drawerRef?: (drawer: Drawer | null) => void
  reservation?: ReservationModel | null
  onClose?: () => void
}

export const ReservationEditDrawer = ({
  drawerRef,
  reservation,
  onClose,
}: ReservationEditDrawerProps) => {
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [drawer, setDrawer] = useDrawerRef()
  const {pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()
  const dispatch = useDispatch()

  const handleDrawerRef = useCallback(
    (drawer: Drawer) => {
      setDrawer(drawer)
      drawerRef && drawerRef(drawer)
    },
    [setDrawer, drawerRef]
  )

  const updateOutlet = useCallback(
    async (values: ReservationFormValues) => {
      if (reservation) {
        const payload = getPayload(values)
        try {
          setIsFormLoading(true)
          await UpdateTableReservation(reservation.code, payload)
          dispatch(actions.reservations.updateSuccess())
          drawer?.hide()
        } catch (e) {
          pushError(e)
          dispatch(actions.reservations.updateFailed())
        } finally {
          safeUpdate(() => {
            setIsFormLoading(false)
          })
        }
      }
    },
    [pushError, dispatch, drawer, safeUpdate, reservation]
  )

  const formValues = useMemo((): ReservationFormValues => {
    if (reservation) {
      return mapDataToFormValues(reservation)
    }
    return EMPTY_INITIAL_VALUES
  }, [reservation])

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: updateOutlet,
    validationSchema: validationSchema,
    validateOnMount: true,
  })

  const drawerCloseCleanup = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  useOnChange(formValues, () => {
    formik.setValues(formValues)
  })

  return (
    <Drawer ref={handleDrawerRef} onClose={drawerCloseCleanup}>
      <ReservationForm
        isLoading={isFormLoading}
        formik={formik}
        title='Edit Table Reservation'
        onCloseButtonClick={drawer?.hide}
        onSubmit={formik.handleSubmit}
      />
    </Drawer>
  )
}
