import {ReactNode} from 'react'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export interface TextInputWithButtonProps extends InputProps {
  label?: ReactNode
  buttonLabel: string
  onButtonClick: () => void
}

export const TextInputWithButton = ({
  label,
  buttonLabel,
  onButtonClick,
  disabled,
  ...inputProps
}: TextInputWithButtonProps) => {
  return (
    <div>
      <label className='form-label d-flex align-items-center'>{label}</label>
      <div className='d-flex'>
        <input
          className='form-control form-control-solid rounded-0'
          disabled={disabled}
          {...inputProps}
        />
        <button
          disabled={disabled}
          className='btn btn-success border border-success bg-light-success rounded-0'
          onClick={onButtonClick}
          type='button'
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  )
}
