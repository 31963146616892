import {useCallback, useMemo} from 'react'
import {ResponsiveModal} from '../../modals/ResponsiveModal'
import {Button} from '../Button'
import {MetronicIconButton} from '../MetronicIconButton'
import {SeatMapSpacing} from '../SeatMapInput/SeatMapSpacing'
import {SeatMapValue} from '../SeatMapInput/SeatMapValue'
import {SVGSeatMapInput} from '../SeatMapInput/SVGSeatMapInput/SVGSeatMapInput'

export interface SeatMapsLocationProps {
  open: boolean
  isRightToLeft?: boolean
  isBottomToTop?: boolean
  hidden?: SeatMapValue
  selected?: SeatMapValue
  occupied?: SeatMapValue
  disabled?: SeatMapValue
  showHidden?: boolean
  rows: number
  columns: number
  spacingX?: SeatMapSpacing
  onClose: () => void
  onSelectedChange?: (value: SeatMapValue) => void
  onSubmit?: () => void
  isViewOnly?: boolean
  maxSelected?: number
  isCustom?: boolean
  maxQty?: number
  locationName?: string
}

export const SeatMapsLocationModal = ({
  isBottomToTop,
  isRightToLeft,
  hidden,
  selected,
  rows,
  columns,
  occupied,
  spacingX,
  onSelectedChange,
  open,
  onClose,
  onSubmit,
  disabled,
  isViewOnly = false,
  showHidden,
  maxSelected,
  isCustom = false,
  maxQty,
  locationName
}: SeatMapsLocationProps) => {
  const clearSelection = useCallback(() => {
    onSelectedChange && onSelectedChange(new SeatMapValue())
  }, [onSelectedChange])

  const selectAll = useCallback(() => {
    if (onSelectedChange) {
      const allSeats = new SeatMapValue(rows, columns)
      if (showHidden) {
        onSelectedChange(allSeats)
      } else {
        const visibleSeats = hidden ? allSeats.difference(hidden) : allSeats
        onSelectedChange(visibleSeats)
      }
    }
  }, [columns, hidden, onSelectedChange, rows, showHidden])

  const setSelectedSeats = useCallback(
    (value: SeatMapValue) => {
      onSelectedChange && onSelectedChange(value)
    },
    [onSelectedChange]
  )

  const clearSelectionButton = useMemo(() => {
    if (onSelectedChange && selected) {
      if (selected.hasAnyValues()) {
        return (
          <MetronicIconButton
            size='sm'
            iconType='Code'
            iconName='Stop'
            type='button'
            onClick={clearSelection}
            variant='info'
            tooltip='Clear Selection'
          />
        )
      }
    }
    return null
  }, [clearSelection, selected, onSelectedChange])

  const selectAllButtons = useMemo(() => {
    // if (!maxSelected && onSelectedChange && hidden) {
    const totalSeats = rows * columns
    // const hiddenTotal = hidden.getValueCount()
    let shouldShow = totalSeats > 0
    // if (!showHidden) {
    //   shouldShow = totalSeats - hiddenTotal > 0
    // }
    if (shouldShow) {
      return (
        <MetronicIconButton
          size='sm'
          iconType='Design'
          iconName='Select'
          type='button'
          onClick={selectAll}
          variant='info'
          tooltip='Select All'
        />
      )
    }
    // }
    return null
  }, [rows, columns, selectAll])

  const remaining = useMemo(() => {
    const selectCount = selected?.getValueCount() || 0
    if (maxQty) {
      return maxQty - selectCount
    }
  }, [selected, maxQty])

  const title = `Please select seats: ${remaining} remaining.`

  return (
    <ResponsiveModal noShowAnimation noPadding size='big' onClose={onClose} open={open}>
      <div className='swal2-corners'></div>
      <div className='h-100 p-5 d-flex flex-column position-relative px-20'>
        <div className='d-flex'>
          <div className='px-3 pt-5 flex-grow-1'>
            <h3 className='text-start'>
              {isViewOnly ? `Reserved seats in ${locationName}:`  : !isViewOnly && maxQty ? title : 'Select seats'}
            </h3>
          </div>
          <MetronicIconButton
            variant='text'
            size='md'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onClose}
          />
        </div>

        <div className='flex-grow-1 seat-map-editor'>
          {!isViewOnly && (
            <div className='seat-map-editor-controls'>
              {selectAllButtons}
              {clearSelectionButton}
            </div>
          )}

          <SVGSeatMapInput
            isBottomToTop={isBottomToTop}
            isRightToLeft={isRightToLeft}
            hidden={hidden}
            value={selected}
            columns={columns}
            rows={rows}
            onChange={setSelectedSeats}
            occupied={occupied}
            spacingX={spacingX}
            disabled={disabled}
            isCustom={isCustom}
          />
        </div>
        {isViewOnly ? (
          <div className='pt-2 d-flex flex-row-reverse'>
            <Button type='button' variant='text' onClick={onClose}>
              Close
            </Button>
          </div>
        ) : (
          <div className='pt-5 d-flex flex-row-reverse'>
            <div className='d-flex'>
              <Button
                className='btn-primary position-relative btn-cut-conrner pe-20'
                type='button'
                disabled={!selected}
                onClick={onSubmit}
              >
                Confirm
              </Button>
            </div>
            <div className='px-5'>
              <Button type='button' variant='text' onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </ResponsiveModal>
  )
}
