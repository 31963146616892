import clsx from 'clsx'
import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {Button} from '../../inputs/Button'
import {Paper, PaperBackgroundVariant} from '../../utils/Paper'
import {OrderCardItem, OrderCardItemAttributes} from './OrderCardItem'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {OrderModelStatus} from '../../../models/fnb/OrderModel'
import {useOrderClock} from '../../../modules/outlet/hooks/useOrderTimer'
import {LoadingSpinner} from '../../utils/LoadingSpinner'

export interface OrderCardAttributes {
  orderNo: string
  notes?: string
  items: OrderCardItemAttributes[]
  status: OrderModelStatus
  createdAt: Date
}

export interface OrderCardProps {
  origin?: 'foh' | 'dm'
  order: OrderCardAttributes
  createdBy?: string
  className?: string
  onClose?: (order: OrderCardAttributes) => void
  paperVariant?: PaperBackgroundVariant
  onClick?: (order: OrderCardAttributes) => void
  buttonLabel?: string
  loading?: boolean
}

export const OrderCard = ({
  className,
  paperVariant,
  order,
  onClose,
  onClick,
  buttonLabel = 'Confirm',
  loading,
  origin,
  createdBy,
}: OrderCardProps) => {
  const {checkStatus, stop, time} = useOrderClock()

  const [timeFromNow, setTimeFromNow] = useState(moment(order.createdAt).fromNow())

  const handleClose = useCallback(() => {
    onClose && onClose(order)
  }, [order, onClose])

  const handleConfirm = useCallback(() => {
    onClick && onClick(order)
  }, [onClick, order])

  const variant = useMemo(() => {
    if (order.status === 'completed') {
      return 'success'
    } else if (checkStatus(order.createdAt) === 'late') {
      stop()
      return 'danger'
    } else if (checkStatus(order.createdAt) === 'warning') {
      return 'warning'
    }
    return 'primary'
  }, [checkStatus, stop, order])

  const orderItems = useMemo(() => {
    return order.items.map((item, i) => (
      <OrderCardItem key={item.name + i} item={item} variant={variant} />
    ))
  }, [order, variant])

  useEffect(() => {
    setTimeFromNow(moment(order.createdAt).fromNow(true))
  }, [order.createdAt, time])

  return (
    <Paper variant={paperVariant} className={clsx('order-card', className)}>
      <Paper className='order-card__header' variant={variant}>
        <div className='d-flex justify-content-between'>
          <p>
            <span className='fw-bolder fs-6'>ORDER # </span>
            <span>{order.orderNo}</span>
          </p>
          <p className='fw-bolder ms-5 text-nowrap'>{timeFromNow}</p>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='flex-grow-1'>
            <p>
              <span className='fw-bolder fs-6 text-uppercase'>{createdBy}</span>
            </p>
            {order.notes && <p>{order.notes}</p>}
          </div>
          {origin && (
            <p>
              <span className='fw-bolder fs-6 text-uppercase'>
                {origin === 'dm' ? 'Digital menu' : 'Front of house'}
              </span>
            </p>
          )}
        </div>
      </Paper>
      <ul className='order-card-item-list'>{orderItems}</ul>
      <div className='order-card-actions'>
        {onClick && (
          <Button
            className='order-card-actions__button order-card-actions__button--confirm'
            variant={variant}
            onClick={handleConfirm}
            disabled={loading}
          >
            <LoadingSpinner loading={loading || false}>
              <span className={clsx({'text-success': order.status === 'preparing'})}>
                {buttonLabel}
              </span>
            </LoadingSpinner>
          </Button>
        )}
        {onClose && (
          <MetronicIconButton
            disabled={loading}
            onClick={handleClose}
            variant='dark'
            className='order-card-actions__button order-card-actions__button--close'
            tooltip='Close'
            iconType='Navigation'
            iconName='Close'
          />
        )}
      </div>
    </Paper>
  )
}
