import {
  SeatMapSelectionModalFormValues,
  getPayload,
} from '../../default/svc/components/tables/CustomerProductBookingDetailTable/modals/SeatMapSelectionModalForm'
import {useCallback} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {useTableModalActions} from '../../../components/hooks/useTableModalActions'
import {DateUtil} from '../../../utils/DateUtil'
import {DateRange} from '../../../utils/DateRange'
import {
  AssignBoatToBookingProductPortal,
  FulfillNonSeated,
  FulfillTicket,
} from '../redux/CustomerPortalCRUD'
import {
  BookingDetailModel,
  CreateCustomerBoatPortalParams,
} from '../../../models/customer-portal/BookingDetailModel'
import {LocationModel} from '../../../models/acs/LocationModel'

interface useProductBookingDetailsTableHelpersProps {
  onRefresh?: () => void
}

export const useProductBookingDetailsTableHelpers = ({
  onRefresh,
}: useProductBookingDetailsTableHelpersProps) => {
  const {isKeyLoading, setIsLoading, isLoading} = useLoadingState()
  const {clearModalData, getModalProps, modalData, setModalData} = useTableModalActions<
    BookingDetailModel | BookingDetailModel[],
    ModalType
  >()
  const {push, pushError} = useAlerts()

  const handleFulfillTimeslotSubmit = useCallback(
    async (dates: DateRange[]) => {
      if (modalData.data && !Array.isArray(modalData.data)) {
        const doneLoading = setIsLoading(modalData.data.code)
        try {
          await FulfillNonSeated(
            modalData.data.bookingProductCode,
            dates.map((dateRange) => ({
              startedAt: DateUtil.convertDateToApiString(dateRange.getStartOrFail()),
              endedAt: DateUtil.convertDateToApiString(dateRange.getEndOrFail()),
            }))
          )
          push({
            message: 'Successfully fulfilled ticket.',
            timeout: 5000,
            variant: 'success',
          })
          clearModalData()
          onRefresh && onRefresh()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [clearModalData, modalData.data, onRefresh, push, pushError, setIsLoading]
  )

  const assignBoat = useCallback(
    async (data: BookingDetailModel, boat: LocationModel) => {
      const doneLoading = setIsLoading(data.code)
      try {
        const codes = [data.bookingProductCode]
        const payload: CreateCustomerBoatPortalParams = {
          codes: codes,
          boatCode: boat.code,
          eventCode: data.eventCode,
        }
        await AssignBoatToBookingProductPortal(payload)
        push({
          message: 'Successfully assigned a boat.',
          timeout: 5000,
          variant: 'success',
        })
        onRefresh && onRefresh()
        clearModalData()
      } catch (e: any) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [clearModalData, onRefresh, push, pushError, setIsLoading]
  )

  const fulfillNonSeatedProduct = useCallback(
    async (data: BookingDetailModel) => {
      const doneLoading = setIsLoading(data.code)
      try {
        if (data.isTimeslot) {
          setModalData({
            data,
            modalType: 'assign-non-seated-timeslots',
          })
        } else {
          await FulfillNonSeated(data.bookingProductCode)
          push({
            message: 'Successfully fulfilled ticket.',
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
        }
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [onRefresh, push, pushError, setIsLoading, setModalData]
  )

  const fulfillTicket = useCallback(
    async (values: SeatMapSelectionModalFormValues) => {
      if (modalData.data && 'bookingProductCode' in modalData.data) {
        const payload = getPayload(values, modalData.data.bookingProductCode)
        const doneLoading = setIsLoading(payload.productCode)
        try {
          await FulfillTicket({
            locationCode: values.locationCode,
            seats: values.selected.getSeatMapObject(),
            productCode: modalData.data.bookingProductCode,
            startedAt:
              values.dateRange && modalData.data.isTimeslot
                ? DateUtil.convertDateToApiString(values.dateRange.getStartOrFail())
                : undefined,
            endedAt:
              values.dateRange && modalData.data.isTimeslot
                ? DateUtil.convertDateToApiString(values.dateRange.getEndOrFail())
                : undefined,
          })
          push({
            message: 'Successfully fulfilled ticket.',
            timeout: 5000,
            variant: 'success',
          })
          clearModalData()
          onRefresh && onRefresh()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [clearModalData, modalData.data, onRefresh, push, pushError, setIsLoading]
  )

  const isItemLoading = useCallback(
    (item: BookingDetailModel) => {
      return isKeyLoading(item.code)
    },
    [isKeyLoading]
  )

  return {
    getModalProps,
    modalData,
    setModalData,
    fulfillTicket,
    fulfillNonSeatedProduct,
    isItemLoading,
    isLoading,
    clearModalData,
    handleFulfillTimeslotSubmit,
    assignBoat,
  }
}

type ModalType = 'assign-seated' | 'assign-non-seated-timeslots' | 'assign-boat'
