import {OrderModel} from '../../../../models/fnb/OrderModel'

export interface FohOrderListTableRowExpansionProps {
  data: OrderModel
}

export const FohOrderListTableRowExpansion = ({data}: FohOrderListTableRowExpansionProps) => {
  return (
    <div className='d-flex'>
      <div className='flex-grow-1 py-4'>
        <p>
          <span className='fw-bold me-1'>Code:</span>
          <span>{data.code}</span>
        </p>
        {data.name && (
          <p>
            <span className='fw-bold me-1'>Customer:</span>
            <span>{data.name}</span>
          </p>
        )}
        {data.mobile && (
          <p>
            <span className='fw-bold me-1'>Mobile:</span>
            <span>{data.mobile}</span>
          </p>
        )}
        {data.location && (
          <p>
            <span className='fw-bold me-1'>Location:</span>
            <span>{data.location.name}</span>
          </p>
        )}
      </div>
      <div className='flex-grow-1'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>
                <span className='fw-bolder fs-4'>Product Name</span>
              </th>
              <th scope='col'>
                <span className='fw-bolder fs-4'>Quantity</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.orderItems?.map((item) => (
              <tr key={item.code}>
                <td>{item.product?.name}</td>
                <td>{item.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
