import moment from 'moment'
import {useCallback, useMemo, useState} from 'react'
import {Collapse} from 'react-bootstrap'
import {useColorScale} from '../../../../../../components/charts/hooks/useColorScale'
import {
  colorPalette,
  LineChart,
  RenderTooltipProps,
} from '../../../../../../components/charts/LineChart/LineChart'
import {useBooleanState} from '../../../../../../components/hooks/useBooleanState'
import {useBreakpoint} from '../../../../../../components/hooks/useBreakpoint'
import {MetronicIconButton} from '../../../../../../components/inputs/MetronicIconButton'
import {HorizontalDivider} from '../../../../../../components/utils/HorizontalDivider'
import {GateActivityTimelineWidgetModel} from '../../../../../../models/ems/WidgetModel'
import {ScannerTimelineChartTooltip} from './ScannerTimelineChartTooltip'

export interface ScannerTimelineChartProps {
  data?: Record<string, GateActivityTimelineWidgetModel[]>
  selectedDate?: Date | null
}

export const ScannerTimelineChart = ({data, selectedDate}: ScannerTimelineChartProps) => {
  const [hiddenItems, setHiddenItems] = useState<string[]>(['total'])
  const {state: is24HoursShown, toggleState: toggle24Hours} = useBooleanState(false)
  const {state: isExpanded, toggleState: toggleExpand} = useBooleanState(false)
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => down('sm'), [down])

  const chartData = useMemo(() => {
    const chartData: [string, ScannerTimelineChartData[]][] = []
    const isToday = !selectedDate || moment().isSame(selectedDate, 'd')
    const end = isToday ? moment() : moment(selectedDate).endOf('day')

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        const values: ScannerTimelineChartData[] = []
        chartData.push([key, values])
        const start = is24HoursShown
          ? end.clone().startOf('day')
          : end.clone().startOf('day').add(9, 'hours')
        for (let date = start.clone(); date.isBefore(end); date.add(1, 'hour')) {
          values.push({
            date: date.toDate(),
            total: 0,
          })
        }
        values.push({
          date: end.toDate(),
          total: 0,
        })
        value.forEach((datum, index, array) => {
          const isLast = array.length - 1 === index
          const chartDatumIndex = values.findIndex((chartDatum) => {
            return (
              moment(chartDatum.date).format('HH:mm:ss') === moment(datum.time).format('HH:mm:ss')
            )
          })
          const chartDatum = values[chartDatumIndex]
          if (chartDatum) {
            chartDatum.total += Number(datum.total)
          }
          if (isToday && isLast) {
            for (let i = chartDatumIndex + 1; i < values.length; i++) {
              const nextChartDatum = values[i]
              if (moment(datum.time).isBefore(nextChartDatum.date)) {
                nextChartDatum.total += Number(datum.total)
              }
            }
          }
        }, [])
      })
    }

    return chartData
  }, [data, is24HoursShown, selectedDate])

  const {colorArray} = useColorScale(data ? Object.values(data).length : 0, colorPalette)

  const handleCheckbox = useCallback(
    (key: string) => () => {
      setHiddenItems((previousValues) => {
        const index = previousValues.indexOf(key)
        const newValues = [...previousValues]
        if (index >= 0) {
          newValues.splice(index, 1)
        } else {
          newValues.push(key)
        }
        return newValues
      })
    },
    []
  )

  const renderTooltip = useCallback(
    (props: RenderTooltipProps<ScannerTimelineChartData>) => {
      return <ScannerTimelineChartTooltip {...props} colors={colorArray} data={chartData} />
    },
    [chartData, colorArray]
  )

  return (
    <div className='h-100 w-100'>
      <div className='d-flex h-100 flex-column overflow-hidden'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='fs-1 fw-bolder text-white text-uppercase text-center flex-grow-1'>
            Entrance Activity
          </div>
          <MetronicIconButton
            variant='text'
            className='border-primary border me-3'
            color='primary'
            iconType={isExpanded ? 'Navigation' : 'Text'}
            iconName={isExpanded ? 'Close' : 'Filter'}
            onClick={toggleExpand}
          />
        </div>
        <div className='flex-grow-1 d-flex h-100 overflow-hidden'>
          <div className='flex-grow-1 overflow-hidden pb-10'>
            <LineChart
              yTicks={10}
              xTicks={isMobile ? 6 : 24}
              data={chartData}
              xAccessor={getDate}
              yAccessor={getCount}
              renderTooltip={renderTooltip}
              hidden={hiddenItems}
            />
          </div>
          <Collapse in={isExpanded} dimension='width'>
            <div style={{overflowY: 'auto'}} className='h-100 pt-1 flex-shrink-0'>
              <div className='form-check mb-3'>
                <input
                  checked={is24HoursShown}
                  className='form-check-input'
                  type='checkbox'
                  onChange={toggle24Hours}
                />
                <label className='form-check-label text-nowrap'>Show 24 hours</label>
              </div>
              <HorizontalDivider />
              <p className='fw-bold'>Filters</p>
              {chartData &&
                chartData.map(([key], index) => {
                  const checked = !hiddenItems.includes(key)
                  const color = colorArray[index]
                  return (
                    <div key={key} className='form-check mb-3'>
                      <input
                        style={{backgroundColor: checked ? color : undefined, borderColor: color}}
                        checked={!hiddenItems.includes(key)}
                        className='form-check-input'
                        type='checkbox'
                        onChange={handleCheckbox(key)}
                      />
                      <label className='form-check-label text-nowrap text-white'>{key}</label>
                    </div>
                  )
                })}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export interface ScannerTimelineChartData {
  date: Date
  total: number
}

const getDate = (datum: ScannerTimelineChartData) => {
  const date = moment(datum.date).format('HH:mm')
  return date
}
const getCount = (datum: ScannerTimelineChartData) => datum.total
