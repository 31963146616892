import {FormikContextType} from 'formik'
import {pick} from 'lodash'
import {useCallback} from 'react'
import {NumberStepperProps} from '../../layouts/Stepper/NumberStepper'

export interface UseWizardFormikHelpersOptions {
  formik: FormikContextType<any>
  currentStep: number
  noErrors?: boolean
}

export const useWizardFormikHelpers = ({
  currentStep,
  formik,
  noErrors,
}: UseWizardFormikHelpersOptions) => {
  const fieldsHasBeentouched = useCallback(
    (fields: string[]) => {
      const fieldErrors = pick(formik.touched, fields)
      return Object.values(fieldErrors).length > 0
    },
    [formik.touched]
  )

  const fieldsHasErrors = useCallback(
    (fields: string[]) => {
      if (noErrors) return false
      const fieldErrors = pick(formik.errors, fields)
      return Object.values(fieldErrors).length > 0
    },
    [formik.errors, noErrors]
  )

  const fieldsHasValues = useCallback(
    (fields: string[]) => {
      const fieldErrors = pick(formik.values, fields)
      return Object.values(fieldErrors).some((value) => Boolean(value))
    },
    [formik.values]
  )

  const getStepState = useCallback(
    (step: number, fields: string[]): NumberStepperProps['state'] => {
      if (fieldsHasBeentouched(fields)) {
        if (fieldsHasErrors(fields)) {
          if (step === currentStep) {
            return 'warning'
          }
          return 'danger'
        }
        return 'completed'
      }

      if (step < currentStep && !fieldsHasErrors(fields)) {
        return 'completed'
      }
    },
    [currentStep, fieldsHasBeentouched, fieldsHasErrors]
  )

  return {fieldsHasBeentouched, fieldsHasErrors, fieldsHasValues, getStepState}
}
