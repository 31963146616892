import {useState, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {AddExistedCustomer, PostPortalCustomer} from '../../redux/CustomerPortalCRUD'
import {useFormik} from 'formik'
import * as RxCustPort from '../../redux/CustomerPortalRedux'
import {CustomerFormValues, roleFormValidationSchema} from './CustomerFields'

import {useAlerts} from '../../../../components/alerts/useAlerts'
import {
  useFormDrawerCustomerData,
  initialValues,
  getPortalCustomerPayload,
} from '../../hooks/useFormDrawerCustomerData'
import {CustomerModel} from '../../../../models/CustomerModel'
import {CustomerFields} from './CustomerFields'
import {Button} from 'react-bootstrap'
import {Spinner} from '../../../../components/utils/Spinner'
import {useHistory} from 'react-router-dom'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'

const CustomerFormCreate = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const {pushError} = useAlerts()
  const history = useHistory()
  const formDrawer = useFormDrawerCustomerData()
  const [isExistedCustomer, setIsExistedCustomer] = useState<CustomerModel>()
  const createNewCustomer = useCallback(
    async (values: CustomerFormValues) => {
      try {
        if (isExistedCustomer) {
          await AddExistedCustomer({
            customerCode: isExistedCustomer.code,
          })
        } else {
          const payload = getPortalCustomerPayload({values})
          await PostPortalCustomer(payload)
        }
        dispatch(RxCustPort.actions.customers.createSuccess())
        history.replace('/customer')
      } catch (e) {
        dispatch(RxCustPort.actions.customers.createFailed())
        throw e
      }
    },
    [dispatch, history, isExistedCustomer]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: roleFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true)
      try {
        await createNewCustomer(values)
      } catch (e: any) {
        pushError(e)
      } finally {
        setIsLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='position-relative rs-table-bg'>
      <div className='table-cut-corner-polygon rs-gradient'></div>

      <form onSubmit={formik.handleSubmit} className='p-10 rs-top position-relative'>
        <div className='d-flex gap-2 align-items-center position-relative rs-top rs-stepper mb-10'>
          <div className='bg-primary p-2'>
            <MetronicIcon iconName='User' iconType='General' size='md' className='svg-icon' />
          </div>
          <h4 className='text-white'>Create Customer</h4>
        </div>
        <CustomerFields
          countries={formDrawer.countries}
          formik={formik}
          setIsExistedCustomer={setIsExistedCustomer}
          isExistedCustomer={isExistedCustomer}
        />

        <div className='container-fluid px-0 '>
          <div className='mt-5 d-flex justify-content-end'>
            <Button
              className='btn btn-ligh-primary align-items-center position-relative'
              variant='text'
              type='button'
              onClick={() => history.replace('/customer')}
            >
              Cancel
            </Button>
            <Button
              className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-auto'
              variant='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              type='submit'
            >
              <Spinner hide={!isLoading} /> Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CustomerFormCreate
