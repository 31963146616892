import {useCallback, useMemo, useState} from 'react'
import {Body} from '../../../../../_metronic/layout/foh/Body'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {OrderCard, OrderCardAttributes} from '../../../../components/cards/OrderCard/OrderCard'
import {useLoadingState} from '../../../../components/hooks/useLoadingState'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {Paper} from '../../../../components/utils/Paper'
import {BatchOrderModel} from '../../../../models/fnb/BatchOrderModel'
import {DateUtil} from '../../../../utils/DateUtil'
import {SwalUtils} from '../../../../utils/SwalUtils'
import {TabList} from '../../components/TabList'
import {useKdsDashboardData} from '../../hooks/useKdsDashboardData'
import {KdsWebSocketData, useKdsWebSockets} from '../../hooks/useKdsWebSockets'

export const KdsDashboardPage = () => {
  const [tab, setTab] = useState('all')
  const {completeOrder, orders, prepareOrder, setOrders, refresh} = useKdsDashboardData()
  const {pushError} = useAlerts()
  const {isKeyLoading, setIsLoading} = useLoadingState()
  const handleWebSockets = useCallback(
    (data: KdsWebSocketData) => {
      setOrders(data.data)
    },
    [setOrders]
  )
  useKdsWebSockets(handleWebSockets)

  const getCardClickHandler = useCallback(
    (order: BatchOrderModel) => {
      if (order.batchStatus !== 'completed') {
        return async () => {
          const doneLoading = setIsLoading(order.batchCode)
          try {
            if (order.batchStatus === 'confirmed') {
              await prepareOrder(order)
            } else if (order.batchStatus === 'preparing') {
              await SwalUtils.asyncConfirm(
                async () => {
                  await completeOrder(order)
                },
                {
                  confirmButtonText: 'Yes, Complete the order!',
                }
              )
            }
          } catch (e) {
            pushError(e)
          } finally {
            refresh()
            doneLoading()
          }
        }
      }
    },
    [completeOrder, prepareOrder, pushError, refresh, setIsLoading]
  )

  const dashboardItems = useMemo(() => {
    return orders.filter((order) => {
      let correctTab = tab === 'all'
      if (tab === 'dig') {
        correctTab = order.isDigitalMenu
      } else if (tab === 'foh') {
        correctTab = !order.isDigitalMenu
      } else if (tab === 'incomplete') {
        correctTab = order.batchStatus !== 'completed'
      } else if (tab === 'completed') {
        correctTab = order.batchStatus === 'completed'
      }
      return correctTab
    })
  }, [orders, tab])

  const orderGrid = useMemo(() => {
    const cards = dashboardItems.map((order) => {
      const createdAt = DateUtil.getDateFromApiString(order.createdAt)
      const data: OrderCardAttributes = {
        createdAt,
        items:
          order.orderItems?.map((item) => ({
            category: item.product?.fnbProductCategory?.name || '',
            count: item.qty,
            name: item.product?.name || '',
            cancelled: item.status === 'cancelled',
          })) || [],
        orderNo: order.code,
        status: order.batchStatus,
      }
      let buttonLabel = 'Prepare'
      if (order.batchStatus === 'preparing') {
        buttonLabel = 'Complete'
      }
      return (
        <div key={order.batchCode} className='col-4'>
          <OrderCard
            createdBy={order.createdByUser?.name}
            origin={order.isDigitalMenu ? 'dm' : 'foh'}
            loading={isKeyLoading(order.batchCode)}
            onClick={getCardClickHandler(order)}
            paperVariant='light'
            order={data}
            buttonLabel={buttonLabel}
          />
        </div>
      )
    })

    return <div className='row g-5'>{cards}</div>
  }, [dashboardItems, isKeyLoading, getCardClickHandler])

  return (
    <>
      <Body
        header={
          <Paper className='foh-header page-title' variant='light'>
            <h1 className='m-0'>Orders</h1>
            <div className='d-flex w-100 h-100 justify-content-center'>
              <TabList
                className='h-100 align-self-end'
                value={tab}
                onClick={setTab}
                tabs={tabList}
              />
            </div>
          </Paper>
        }
      >
        {orderGrid}
      </Body>
    </>
  )
}

const tabList: SelectInputItem[] = [
  {label: 'ALL', value: 'all'},
  {label: 'FOH', value: 'foh'},
  {label: 'DIGITAL MENU', value: 'dig'},
  {label: 'INCOMPLETE', value: 'incomplete'},
  {label: 'COMPLETED', value: 'completed'},
]
