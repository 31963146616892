import clsx from 'clsx'
import {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {NavigationSectionMenuItem} from '../../../../../config/drawer'
import {ImageConfig, MainDrawerMenuHeaderLogo} from '../../../../../config/logos'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {useLayoutPortalData} from '../../DefaultLayout/LayoutPortalProvider'
import {NavHeader} from '../../DefaultLayout/NavHeader/NavHeader'

interface HeaderProps {
  logo?: ImageConfig
  logoMaxWidth?: string
  menus?: NavigationSectionMenuItem[]
}
export const Header = ({logo, logoMaxWidth, menus}: HeaderProps) => {
  const {pathname} = useLocation()
  const {setHeaderControls, setToolbar} = useLayoutPortalData()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <>
      <div id='kt_header' className={clsx('header', 'align-items-stretch')}>
        <div
          className={clsx(
            'd-flex px-3 w-100 align-items-stretch justify-content-between flex-shrink-0'
          )}
        >
          <div className='d-flex align-items-center'>
            <Link style={{display: 'contents'}} to='/'>
              <img
                alt='Home'
                style={{maxWidth: logoMaxWidth ? logoMaxWidth : '15rem'}}
                src={logo ? logo.src : MainDrawerMenuHeaderLogo.src}
                className='w-100 p-3'
              />
            </Link>
          </div>
          {menus && (
            <div className='flex-grow-1 d-none d-lg-block overflow-hidden'>
              <NavHeader menus={menus} />
            </div>
          )}

          <div className='d-flex align-items-stretch justify-content-between'>
            <div className='d-flex align-items-stretch flex-shrink-0 pe-5'>
              <div ref={setHeaderControls}></div>
            </div>
          </div>
        </div>
      </div>
      <div ref={setToolbar}></div>
    </>
  )
}
