import {useCallback, useState, useEffect, useRef} from 'react'
import {
  PollAnswerModel,
  PollRatingModel,
  PollQuestionsRatingModel,
} from '../../../models/eva/PollRatingModel'
import {POLL_SOCKET_NAME} from '../../../../config/env'
import {PollModel} from '../../../models/eva/PollModel'
import {useWebSocket} from '../../../components/utils/WebSocketProvider'

export interface UsePollsOptions {
  onPollsReceive?: (data: PollModel) => void
}

export const usePolls = ({onPollsReceive}: UsePollsOptions) => {
  const [pollsData, setPollsData] = useState<PollModel[]>([])
  const {socket, isConnected} = useWebSocket()
  const pollsReceiveFuncRef = useRef(onPollsReceive)

  const appendPollsData = useCallback((data: PollModel[]) => {
    setPollsData((previousData) => {
      return [...previousData, ...data]
    })
  }, [])

  const handleReceivePolls = useCallback(
    (polls: PollModel) => {
      appendPollsData([polls])
      pollsReceiveFuncRef.current?.(polls)
    },
    [appendPollsData]
  )

  useEffect(() => {
    if (POLL_SOCKET_NAME) {
      const eventName = `${POLL_SOCKET_NAME}`
      socket?.on(eventName, handleReceivePolls)
      return () => {
        socket?.off(eventName, handleReceivePolls)
      }
    }
  }, [handleReceivePolls, socket])

  useEffect(() => {
    pollsReceiveFuncRef.current = onPollsReceive
  }, [onPollsReceive])

  return {pollsData, appendPollsData, isConnected}
}

export const getIndex = (array: PollRatingModel[] | PollAnswerModel[], code: string) => {
  return array.findIndex((item: PollRatingModel | PollAnswerModel, index: number) => {
    if (item.code === code) {
      return true
    }
    return false
  })
}

export const getAnswerValueAndDisability = (
  questionId: string,
  answerId: string,
  result: PollQuestionsRatingModel[]
): [boolean, boolean] => {
  let answerIndex: number = -1
  const oldState = [...result]
  const questionIndex: number = getIndex(oldState, questionId)
  if (questionIndex !== -1) {
    answerIndex = getIndex(oldState[questionIndex].pollOptions, answerId)
  }
  if (questionIndex !== -1 && answerIndex !== -1) {
    const value = Boolean(oldState[questionIndex].pollOptions[answerIndex].value)
    const dislable = oldState[questionIndex].pollOptions[answerIndex].disable || false
    return [value, dislable]
  } else return [false, false]
}
