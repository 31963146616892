import {ReactNode} from 'react'
import {HasCode} from '../../utils/idExtractor'
import {Link} from './Link'

export interface BookingLinkProps {
  booking?: HasCode
  customer?: HasCode
  children?: ReactNode
}

export const CustomerBookingPortalLink = ({booking, customer, children}: BookingLinkProps) => {
  if (booking && customer) {
    return <Link to={`/customer/${customer.code}/booking-detail/${booking.code}`}>{children}</Link>
  }
  return null
}
