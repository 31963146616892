import {SweetAlert} from '../../../../components/modals/SweetAlert'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'
import {TicketSubmitWaiverFormSign} from '../waiverForms'
import {ARABIC_CONTENT, ENGLISH_CONTENT} from '../../content/WaiverFormDescription'
import {WaiverFormHeader} from './WaiverFormHeader'

interface WaiverSignModalFormProps {
  open: boolean
  handleClose: () => void
  selectedTicket?: TicketWaiverModel
  onRefresh?: () => void
}

export const WaiverSignModalForm = ({
  open,
  handleClose,
  selectedTicket,
  onRefresh,
}: WaiverSignModalFormProps) => {
  return (
    <SweetAlert
      containerClassName='p-0'
      customClass={{
        popup: 'bg-black vw-100 vh-100 p-5',
        container: 'p-0 overflow-hidden',
        htmlContainer: 'm-0 m-md-5',
      }}
      open={open}
      onClose={handleClose}
      showConfirmButton={false}
    >
      <div className='w-100 h-100 text-start d-flex flex-column gap-5 digital-waiver-container px-4'>
        <WaiverFormHeader
          arabicTextContent={ARABIC_CONTENT}
          englishTextContent={ENGLISH_CONTENT}
          selectedTicket={selectedTicket}
          handleClose={handleClose}
        />
        <div className='mt-5'>
          <TicketSubmitWaiverFormSign
            handleClose={handleClose}
            isOpen={open}
            ticket={selectedTicket}
            onRefresh={onRefresh}
          />
        </div>
      </div>
    </SweetAlert>
  )
}
