import {useMemo} from 'react'
import {RowAction} from '../../../../components/tables/actions/RowAction'
import {ReservationItemModel} from '../../../../models/ems/ReservationModel'

export interface CustomerReservationLocationTableActionsProps {
  onViewSeats?: (data: ReservationItemModel) => void
  data: ReservationItemModel
  loading?: boolean
}

export const CustomerReservationLocationTableActions = ({
  data,
  onViewSeats,
  loading,
}: CustomerReservationLocationTableActionsProps) => {
  const assignSeats = useMemo(() => {
    if (data.details) {
      return (
        <RowAction
          onClick={onViewSeats}
          data={data}
          loading={loading}
          iconType='Home'
          iconName='Armchair'
          tooltip='View seats'
          variant='info'
        />
      )
    }
  }, [data, loading, onViewSeats])

  return <>{assignSeats}</>
}
