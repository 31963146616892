import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import {CartObject} from '../../../utils/Cart'
import {ActionWithPayload} from '../../default/auth'

export interface IDigitalMenuState {
  order: Record<
    string,
    {
      cart: Record<string, CartObject>
    }
  >
  profile: {
    mobileNumber: string
    fullName: string
    email: string
  }
}

const initalDigitalMenuState: IDigitalMenuState = {
  order: {},
  profile: {
    mobileNumber: '',
    fullName: '',
    email: '',
  },
}

export const reducer = persistReducer(
  {storage, key: 'digital-menu', whitelist: ['order', 'profile']},
  (state: IDigitalMenuState = initalDigitalMenuState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.cart.setCart: {
        if (action.payload) {
          const {outletCode, cart} = action.payload
          return {
            ...state,
            order: {
              ...state.order,
              [outletCode]: {
                cart,
              },
            },
          }
        }
        return state
      }
      case actionTypes.profile.setMobileNumber: {
        const mobileNumber = action.payload
        return {
          ...state,
          profile: {
            ...state.profile,
            mobileNumber,
          },
        }
      }
      case actionTypes.profile.setCustomerName: {
        const fullName = action.payload
        return {
          ...state,
          profile: {
            ...state.profile,
            fullName: fullName,
          },
        }
      }
      case actionTypes.profile.setCustomerEmail: {
        const email = action.payload
        return {
          ...state,
          profile: {
            ...state.profile,
            email: email,
          },
        }
      }
      default:
        return state
    }
  }
)

export const actions = {
  cart: {
    setCart: (outletCode: string, cart: Record<string, CartObject>) => ({
      type: actionTypes.cart.setCart,
      payload: {
        outletCode,
        cart,
      },
    }),
  },
  profile: {
    setMobileNumber: (mobileNumber: string) => ({
      type: actionTypes.profile.setMobileNumber,
      payload: mobileNumber,
    }),
    setCustomerName: (customerName: string) => ({
      type: actionTypes.profile.setCustomerName,
      payload: customerName,
    }),
    setCustomerEmail: (email: string) => ({
      type: actionTypes.profile.setCustomerEmail,
      payload: email,
    }),
  },
}

const actionTypes = {
  cart: {
    setCart: '[Digital Menu] SET CART',
  },
  profile: {
    setMobileNumber: '[Digital Menu] SET MOBILE NUMBER',
    setCustomerName: '[Digital Menu] SET CUSTOMER NAME',
    setCustomerEmail: '[Digital Menu] SET CUSTOMER EMAIL',
  },
}
