export interface DelimitedStringBuilderOptions {
  value?: string
  delimiter?: string
}

export class DelimitedStringBuilder {
  private value: string
  private delimiter: string

  constructor(options: DelimitedStringBuilderOptions = {}) {
    this.value = options.value || ''
    this.delimiter = options.delimiter || DEFAULT_DELIMITER
  }

  public parseValue = <T extends string[] = string[]>(): T => {
    return this.value.split(DEFAULT_DELIMITER) as T
  }

  public append = (value: string) => {
    if (this.value) {
      this.value += this.delimiter
    }
    this.value += value
    return this
  }

  public getValue = () => {
    return this.value
  }
}

const DEFAULT_DELIMITER = ':'
