import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {Plural} from '../../../utils/Plural'

export interface PreparingTimeTextProps {
  preparingTime: number
}

export const PreparingTimeText = ({preparingTime}: PreparingTimeTextProps) => {
  if (!preparingTime) {
    return null
  }
  return (
    <span className='fs-6 text-muted d-flex align-items-center'>
      <MetronicIcon className='svg-icon-danger' iconType='Home' size='md' iconName='Clock' />
      <span className='text-muted'>
        Preparing time {preparingTime}
        {Plural.pluralize(preparingTime, 'min', 'mins')}
      </span>
    </span>
  )
}
