import {useEffect, useMemo, useRef} from 'react'

export const useResizeObserver = (
  el: HTMLElement | null,
  callback: (entry: ResizeObserverEntry) => void
) => {
  const resizeHandler = useRef(callback)
  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        resizeHandler.current(entries[0])
      }),
    []
  )

  useEffect(() => {
    resizeHandler.current = callback
  }, [callback])

  useEffect(() => {
    if (el) {
      observer.observe(el)
      return () => {
        observer.unobserve(el)
      }
    }
  }, [el, observer])
}
