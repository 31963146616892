import {ReactNode} from 'react'
import {Header} from './Header'

export interface ConfirmPageLayoutProps {
  headerLabel: string
  children?: ReactNode
  onButtonClick?: () => void
  onBackClick?: () => void
  buttonLabel?: string
  disableButton?: boolean
}

export const ConfirmPageLayout = ({
  headerLabel,
  buttonLabel,
  children,
  onButtonClick,
  onBackClick,
  disableButton,
}: ConfirmPageLayoutProps) => {
  return (
    <div className='digital-menu-basket-page'>
      <Header className='ff-f1-regular page-header' onBackClick={onBackClick} title={headerLabel} />
      <div className='page-content'>{children}</div>
      {onButtonClick && (
        <button
          className='btn btn-success fixed-bottom w-100 rounded-0 min-h-75px fs-1'
          disabled={disableButton}
          onClick={onButtonClick}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  )
}
